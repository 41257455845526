import React, { useReducer, useState, useCallback, useEffect } from 'react';
import { Input, Button, Row, Col, Typography, Form, Alert } from 'antd';
import { ProjectAddModel } from '../Style';
const { Title, Text } = Typography;
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DataService } from "../../config/dataService/dataService";
import { useDispatch } from 'react-redux';
import { verifyUser } from '../../redux/authentication/actionCreator';
import { Link } from 'react-router-dom';
import useNotificationController from '../../utility/useNotificationController';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

const AddNewProject = ({ createProject }) => {
  const {
    contextHolder,
    openError
  } = useNotificationController();
  
  const is_amazon = localStorage.getItem('is_amazon') === 'true';
  const organizationalSlug = localStorage.getItem('organizationalSlug')
  const [form] = Form.useForm();
  const [projectName, setProjectName] = useState('');
  const [loading, setLoading] = useState(false);
  const [savedProjectName, setSavedProjectName] = useState('');
  const [hasProjectLicenses, setHasProjectLicenses] = useState(true);
  const [hasProjectAccess, setHasProjectAccess] = useState(true);

  const initialState = {
    error: false,
    messages: ""
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'VERIFY_USER_ERR':
        return { ...state, error: true, messages: action.err };
      case 'CONVERT_TO_TRIAL_ERR':
        return { ...state, error: true, messages: action.err };
      case 'RESET':
        return state;
      default:
        return state;
    }
  };

  const [apiError, apiResponseCallback] = useReducer(reducer, initialState);

  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    apiResponseCallback({ type: 'RESET' });
    dispatch(
      verifyUser({}, (response) => {
        if (response.status === 200) {
          if (parseInt(response.data.remainingProjects) > 0) {
            setHasProjectLicenses(true);
          }
          else {
            setHasProjectLicenses(false);
          }
          if (parseInt(response.data.userType) > 0 && parseInt(response.data.userType) < 3) {
            setHasProjectAccess(true);
          }
          else 
          {
            if (parseInt(response.data.userType) > 0 && parseInt(response.data.userType) <= 3 && is_amazon) {
              setHasProjectAccess(true);
            }
            else
            {
              setHasProjectAccess(false);
            }
          }
        }
        else {
          openError('topLeft', 'Error', response.err);
          apiResponseCallback(response);
        }
      }),
    );
  }, [dispatch, hasProjectLicenses]);

  useEffect(() => {
    const retrieveData = async () => {
      await fetchData();
    };
    retrieveData().catch(console.error);
  }, []);

  // Handle input change
  const handleChange = async (e) => {
    setProjectName(e.target.value);
  };

  const projectCreateAndSelect = async () => {
    setLoading(true)
    const response = await DataService.post(
      "/project/create-project",
      { projectName: savedProjectName }
    );
    if (response.status == 200) {
      setLoading(false)
      createProject(response.data.project, false)
    }
    else {
      setLoading(false)
      openError("topLeft", "Create Project Error", response.data.message)
    }
  }

  const projectCreateAndAddAudience = async () => {
    const response = await DataService.post(
      "/project/create-project",
      { projectName: savedProjectName }
    );
    if (response.status == 200) {
      setLoading(false)
      createProject(response.data.project, false)
    }
    else {
      setLoading(false)
      openError("topLeft", "Create Project Error", response.data.message)
    }
  }

  // Handle button click to save project name
  const handleSave = async () => {
    const response = await DataService.post(
      "/project/check-project-exits",
      { projectName: projectName }
    );
    if (response.status == 200) {
      if (response.data.exists) {
        openError('topLeft', 'Training', "Project with this name already exists in your account");
      }
      else {
        setSavedProjectName(projectName);
      }
    }

  };

  const isPaidAccount = localStorage.getItem('is_paid') === 'true';

  return (
    <>
      {contextHolder}
      <ProjectAddModel>
        <div className="main-class" style={{ position: "relative" }}>
          <div style={{ marginTop: '15px', marginRight: '50px', width: "62%" }}>
            <Title level={3} style={{ lineHeight: '15px' }}>
              Name Your New Project
            </Title>
            <Text>Create your Project with 2 easy steps.</Text>
            {
              savedProjectName == '' &&
              <>
                <div className="project-name-box">
                  <span className="step-number">1</span>
                  <span className="step-text">
                    <Input type="text" placeholder="<enter project name>" value={projectName} onChange={handleChange} style={{ padding: "8px" }} disabled={!hasProjectLicenses || !hasProjectAccess} />
                    <Button type={'ocm-default'} onClick={handleSave} disabled={!hasProjectLicenses || !hasProjectAccess}>Save</Button>
                  </span>
                </div>
                {hasProjectLicenses && !hasProjectAccess && (
                  <Alert
                    message={<Title level={4} type='danger'>You do not have permission to create a project</Title>}
                    description={
                      <Title level={5}>To create a project, please contact your Account Owner or Admin</Title>
                    }
                    type="error"
                    showIcon
                  />
                )}
                {!hasProjectLicenses && !hasProjectAccess && (
                  <Alert
                    message={<Title level={4} type='danger'>You do not have permission to create a project</Title>}
                    description={
                      <Title level={5}>To create a project, please contact your Account Owner or Admin</Title>
                    }
                    type="error"
                    showIcon
                  />
                )}
                {!hasProjectLicenses && hasProjectAccess && (
                  <Alert
                    message={<Title level={4} type='danger'>You have consumed all your project licenses.</Title>}
                    description={
                      <Title level={5}>To acquire additional Project Licenses, <a href={`/${organizationalSlug}/${isPaidAccount ? 'subscription/update-subscription' : 'subscribe-now'}`}>click here</a> to add them to your account</Title>
                    }
                    type="error"
                    showIcon
                  />
                )}
              </>
            }
            {
              savedProjectName != '' &&
              <div className="project-name-box">
                <span className="step-number back-white">
                  <FontAwesomeIcon icon={regular("check")} style={{ color: `#EA3323`, fontWeight: "bold", fontSize: "29px" }} />
                </span>
                <span className="step-text" style={{ width: "470px", marginLeft: "20px", color: "#475364", fontWeight: "bold" }}>
                  {savedProjectName}
                </span>
              </div>

            }
            <div className='line-second-step'></div>
            {
              savedProjectName != '' &&
              <div className="project-name-box back-other">
                <span className="step-number">
                  2
                </span>
                <span style={{ width: "470px", marginLeft: "20px", color: "white", fontWeight: "bold" }}>
                  Add your audience (Individuals/Groups)
                </span>
              </div>

            }
            <div className='line-second-step'></div>

            {
              savedProjectName != '' &&
              <div className='create-audience-button' style={{ padding: "40px" }}>
                <Row gutter={30} style={{ justifyContent: 'center' }}>
                  <Col span={12} style={{ textAlign: "center", justifyContent: 'center' }}>
                    <Row style={{ textAlign: "center" }}>
                      <Col span={24}>
                        <Button
                          type={'ocm-default-project'}
                          loading={loading}
                          onClick={projectCreateAndSelect}
                        >
                          Create Project
                        </Button>
                      </Col>
                      {/* <Col span={24}><span style={{ color: "#7F7F7F", textAlign: "center", fontSize: "12px" }}>(Add audience later)</span></Col> */}
                    </Row>
                  </Col>
                  {/* <Col span={12} style={{textAlign:"center"}}>
                    <Button 
                      type={'ocm-default-project'}
                      onClick={projectCreateAndAddAudience}
                      style={{
                        fontSize: "16px",
                        padding: "4px 15px"
                      }}
                    >
                        Create Project and Add Your Audience
                    </Button>
                  </Col> */}
                </Row>
              </div>
            }

          </div>
          <div style={{ position: "absolute", top: 0, right: 0 }}>
            <img src={require('../../static/images/rocket.png')} style={{ width: '384px' }} />
          </div>
        </div>
      </ProjectAddModel>
    </>
  );
};

export default AddNewProject;