import { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Radio } from 'antd';
import { duotone, regular } from '@fortawesome/fontawesome-svg-core/import.macro';

const StarRadioGrop = ({ numberOfStar, title, value, onChange }) => {
  const [starRating, setStarRating] = useState();

  const handleChange = (e) => {
    onChange(e.target.value);

  };
  return (
    <Container>
      <Title>{title}</Title>
      <RadioGroup onChange={handleChange} value={value}>
        {Array.from({ length: numberOfStar }, (_, index) => index + 1).map((s) => (
          <Radio className="star_icon" value={s} id={s}>
            {value && value >= s ? (
              <FontAwesomeIcon icon={duotone("star")} size="3x" color="#5DD3FE" />
            ) : (
              <FontAwesomeIcon icon={regular("star")} size="3x" color="#5DD3FE" />
            )}
          </Radio>
        ))}
      </RadioGroup>
    </Container>
  );
};

export default StarRadioGrop;

const Container = styled.div`
  width: fit-content;
`;

const RadioGroup = styled(Radio.Group)`
  .star_icon {
    border: 1.5px solid black;
    border-right-width: 0.5px;
    margin: 0;
    padding: 5px;
    &:last-child {
      border-right-width: 1.5px;
    }
    .ant-radio.ant-wave-target {
      display: none;
    }
  }
`;

const Title = styled.p`
  font-size: 18px;
  text-align: center;
  margin: 0;
  border: 1.5px solid black;
  border-bottom-width: 0;
  padding: 3px;
  margin-top: 20px;
  background: #d9e2f1;
`;
