import React from "react";
import { FreeOCMTrainingContentWrapper } from "./FreeLibrarySection.styles";
import FreeSearchAndOCMTrainingContainer from "./FreeSearchAndOCMTrainingContainer";

const FreeOCMTrainingContent = ({category}) => {
  return (
    <FreeOCMTrainingContentWrapper>
      <FreeSearchAndOCMTrainingContainer category={category} />
    </FreeOCMTrainingContentWrapper>
  );
};

export default FreeOCMTrainingContent;