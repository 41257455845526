import React, { Suspense, lazy } from 'react';
import { Row, Col, Skeleton } from 'antd';

import { Main } from '../styled';

import { Cards } from '../../components/cards/frame/cards-frame';

const ToolPhaseList = lazy(() => import('../dashboard/overview/mainDashboard/ToolPhaseList'));

function ToolsHome(props) {
  localStorage.removeItem('unSaveChanges');
  const is_amazon = localStorage.getItem('is_amazon') === 'true';
  let excludeTools = ['ocm-strategic-playbook'];
  if (!is_amazon)
  {
    excludeTools.push('change-plan');
  }
  
  return (
    <>
      <Main>
        <Row gutter={25}>
          <Col xxl={24} xs={24}>
            <Suspense
              fallback={
                <Cards headless>
                  <Skeleton active />
                </Cards>
              }
            >
              <ToolPhaseList {...props} />
            </Suspense>
          </Col>
        </Row>
      </Main>
    </>
  );
}

export default ToolsHome;
