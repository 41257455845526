import { ConfigProvider, Spin } from 'antd';
import 'antd/dist/reset.css';
import React, { useEffect, useState, lazy } from 'react';
import { Provider, useSelector } from 'react-redux';
import { isLoaded, ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { BrowserRouter as Router, Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';
import ProtectedRoute from './components/utilities/protectedRoute';
import config from './config/config';
import store, { rrfProps } from './redux/store';

import MainDashboard from './routes/mainDashboard';
import Auth from './routes/auth';
import './static/css/style.css';
import PublicSurveyPage from './container/surveys/PublicSurveyPage';
import TargetAudienceProvider from './container/tools/context/targetAudience';
import NotificationsProvider from './container/tools/context/notifaction';

const NotFound = lazy(() => import('./container/pages/404'));

const AuthRoot = () => {
  const navigate = useNavigate();
  const organizationalSlug = localStorage.getItem('organizationalSlug');
  useEffect(() => navigate(`/${organizationalSlug}/main-dashboard`));
};

const { themeColor } = config;

function ProviderConfig() {
  const userTheme = JSON.parse(localStorage.getItem('userTheme'));
  const { rtl, isLoggedIn, topMenu, mainContent, auth } = useSelector((state) => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      mainContent: state.ChangeLayoutMode.mode,
      isLoggedIn: state.auth.login,
      auth: state.fb.auth,
    };
  });

  const [path, setPath] = useState(window.location.pathname.substr(1, window.location.pathname.length));

  useEffect(() => {
    const pathsToCheck = ["change-management-library", 'ocm-training', 'content', 'public'];
    const paths = path.split('/');
    
    if (path.indexOf('page') !== -1)
    {
      localStorage.setItem('redirectTo', path.replace('page','content'));  
    }
    let found = false;
    for(var ind in paths)
    {
      if (pathsToCheck.includes(paths[ind]))
      {
        found = true;
      }
    }
    if (!found)
    {
      localStorage.setItem('currentPath', "");  
    }
    if (isLoggedIn)
    {
      const organizationalSlug = localStorage.getItem('organizationalSlug');
      if (paths.length === 2)
      {
        const currentOrganizationalSlug = paths[0];
        if (currentOrganizationalSlug !== organizationalSlug)
        {
          window.location.href = `/${organizationalSlug}/${paths[1]}`;
        }
      }
      else
      {
        const currentOrganizationalSlug = paths[0];
        if (currentOrganizationalSlug !== 'public' && currentOrganizationalSlug !== organizationalSlug)
        {
          window.location.href = `/${organizationalSlug}/main-dashboard`;
        }
      }
    }
    if (paths.length === 2 && isLoggedIn) 
    {
      if (
        [
          'all-in-checkout',
          'free-checkout',
          'free-training-checkout',
          'free-trial-two',
          'free-trial-amazon',
          'free-10-days-trial',
          'free-trial-checkout',
          'reset-password',
          'forgotPassword',
        ].includes(paths[1])
      ) {
        window.location.href = `/${localStorage.getItem('organizationalSlug')}/main-dashboard`;
      }
      else
      {
        if (paths.includes('content') && isLoggedIn && !paths.includes(localStorage.getItem('organizationalSlug'))) 
        {  
          const tmpPath = [];
          const ind = paths.indexOf('content');
          for(var i=ind; i<paths.length; i++)
          {
            tmpPath.push(paths[i]);
          }
          let path = tmpPath.join("/");

          window.location.href = `/${localStorage.getItem('organizationalSlug')}/${path}`;
        }
        else if (paths.includes('page') && isLoggedIn && !paths.includes(localStorage.getItem('organizationalSlug'))) 
        {  
          const tmpPath = [];
          const ind = paths.indexOf('page');
          for(var i=ind; i<paths.length; i++)
          {
            if (paths[i] === "page")
            {
              paths[i] = "content";
            }
            tmpPath.push(paths[i]);
          }
          let path = tmpPath.join("/");

          window.location.href = `/${localStorage.getItem('organizationalSlug')}/${path}`;
        }
      }
    }
    else if (paths.includes('content') && isLoggedIn && !paths.includes(localStorage.getItem('organizationalSlug'))) 
    {  
      const tmpPath = [];
      const ind = paths.indexOf('content');
      for(var i=ind; i<paths.length; i++)
      {
        tmpPath.push(paths[i]);
      }
      let path = tmpPath.join("/");
      window.location.href = `/${localStorage.getItem('organizationalSlug')}/${path}`;
    }
    else if (paths.includes('page') && isLoggedIn && !paths.includes(localStorage.getItem('organizationalSlug'))) 
    {  
      const tmpPath = [];
      const ind = paths.indexOf('page');
      for(var i=ind; i<paths.length; i++)
      {
        if (paths[i] === "page")
        {
          paths[i] = "content";
        }
        tmpPath.push(paths[i]);
      }
      let path = tmpPath.join("/");

      window.location.href = `/${localStorage.getItem('organizationalSlug')}/${path}`;
    }
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true);
  }, [setPath]);

  return (
    <NotificationsProvider>

    <TargetAudienceProvider>
      <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
        <ThemeProvider theme={{ ...themeColor, rtl, topMenu, mainContent }}>
          <ReactReduxFirebaseProvider {...rrfProps}>
            {!isLoaded(auth) ? (
              <div className="spin">
                <Spin />
              </div>
            ) : (
              <Router basename={process.env.PUBLIC_URL}>
                {!isLoggedIn ? (
                  <Routes>
                    <Route path="/*" element={<Auth />} />
                  </Routes>
                ) : (
                  <Routes>
                    <Route path={'public/survey/:surveyId'} element={<PublicSurveyPage />} />

                    <Route
                      path="/*"
                      element={
                        <ProtectedRoute
                          path={`/${localStorage.getItem('organizationalSlug')}/*`}
                          Component={MainDashboard}
                        />
                      }
                    />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                )}
                {isLoggedIn && (path === process.env.PUBLIC_URL || path === `${process.env.PUBLIC_URL}/`) ? (
                  <Routes>
                    <Route
                      path="/"
                      element={<Navigate to={`/${localStorage.getItem('organizationalSlug')}/main-dashboard`} />}
                    />
                  </Routes>
                ) : (
                  isLoggedIn && (
                    <Routes>
                      <Route
                        path={`/${localStorage.getItem('organizationalSlug')}`}
                        element={<Navigate to={`/${localStorage.getItem('organizationalSlug')}/main-dashboard`} />}
                      />
                    </Routes>
                  )
                )}
              </Router>
            )}
          </ReactReduxFirebaseProvider>
        </ThemeProvider>
      </ConfigProvider>
    </TargetAudienceProvider>
    </NotificationsProvider>
  );
}

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default App;
