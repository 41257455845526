import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownZA, faArrowDownAZ } from '@fortawesome/pro-duotone-svg-icons';
import { FreeStyledIcon } from './FreeResources.styles';

const FreeResourceSortIcon = ({category, setSort}) => {
    const [sortAscending, setSortAscending] = useState(true);

    const handleClick = () => {
        setSortAscending(!sortAscending);
        setSort({
            field: "name",
            order: sortAscending === true ? "DESC" : "ASC"
        });
    };

    useEffect(() => {
        if (Object.entries(category).length !== 0)
        {
            //Reset Sort
            setSortAscending(true);
        }
      }, [category]);

    return (
        <FreeStyledIcon
          icon={sortAscending ? faArrowDownAZ : faArrowDownZA}
          onClick={handleClick}
        />
    );
}
  
  
export default FreeResourceSortIcon;