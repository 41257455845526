import styled from 'styled-components';
import DefaultLogo from '../../assets/default-logo.svg';
import AmazonLogo from '../../../../static/images/Amazon_logo.svg.webp';

const SurveyCard = ({ title }) => {
  let is_amazon = localStorage.getItem('is_amazon') === 'true';

  return (
    <Container>
      <Header>
        <Logo>
        <img src={is_amazon ? AmazonLogo : DefaultLogo} alt="Logo" />
        </Logo>
        <Title>{title}</Title>
      </Header>
      <Content>
        <div>
          <p>
            Please Provide your perspective and details on the ______________________ project. this will greatly help
            the change management team as it learns about project and its impacts on the orgnization so we can
            facilitate successful user adoption. thak you!
          </p>
          <p>Email address: ________________________________ </p>
          <p>Your department/group: ________________________ </p>
        </div>
      </Content>
      <TitleContainer>
        <p>{title}</p>
      </TitleContainer>
      
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 250px;
  overflow: hidden;
  border-radius: 15px;
  cursor: pointer;
  margin: auto;
`;

const TitleContainer = styled.div`
  color: #adaeb0;
  p {
    width: 90%;
    margin: auto;
    font-size: 17px;
    text-align: center;
  }
`;

const Header = styled.div`
  background-color: #39aaff;
  padding: 13px 10px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Content = styled.div`
  padding: 13px 10px;
  background-color: #fff;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  p {
    font-size: 5px;
    line-height: 9px;
  }
`;

const Logo = styled.div`
  img {
    height: 40px;
    object-fit: contain;
    width: 40px;
  }
`;

const Title = styled.h5`
  margin: 0;
  color: #fff;
  font-weight: bold;
`;

export default SurveyCard;
