import { firebaseReducer } from 'react-redux-firebase';
import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import AuthReducer from './authentication/reducers';
import toolsReducer from './tools/reducers';
import { projectReducer } from './project/reducers';
import ChangeLayoutMode from './themeLayout/reducers';

const rootReducers = combineReducers({
  fb: firebaseReducer,
  fs: firestoreReducer,
  auth: AuthReducer,
  tool: toolsReducer,
  projects: projectReducer,
  ChangeLayoutMode
});

export default rootReducers;
