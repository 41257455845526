import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';

export const SmallRowBox = ({ d_id, color, mainTitle, secondTitle, iconColor }) => {
  return (
    <div
      id={d_id}
      style={{
        border: `4px solid ${color}`,
        borderRadius: '6px',
        height: '5.0rem',
        display: 'flex',
        flexDirection: 'row',
        margin: '0.8rem',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexBasis: '62%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ color, fontWeight: 600, fontSize: '1.3rem', marginLeft: '1rem' }}>{mainTitle}</div>
        <div style={{ color, fontWeight: 500, fontSize: '1.1rem', marginLeft: '1rem' }}>{secondTitle}</div>
      </div>
      <div
        style={{
          height: '100%',
          flexBasis: '40%',
          padding: '5px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderLeft: `9px solid ${color}`,
          borderBottom: `7px solid ${color}`,
          borderRight: `7px solid ${color}`,
          borderTop: `7px solid ${color}`,
          borderTop: `7px solid ${color}`,
          borderRadius: '3px',
        }}
      >
        <FontAwesomeIcon
          icon={duotone('users')}
          style={{
            '--fa-primary-color': `${color}`,
            '--fa-secondary-color': `${color}`,
            width: '50px',
            height: 'auto',
          }}
        />
      </div>
    </div>
  );
};
