import { faTrafficCone } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

function NoSurvey({ expirationMessage }) {
  return (
    <Container>
      <FontAwesomeIcon icon={faTrafficCone} />
      <h1>{expirationMessage || `This from is currently not accepting responses`}.</h1>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  h1 {
    font-size: 45px;
    margin-top: 10px;
  }
  svg {
    font-size: 30rem;
    color: gray;
  }
`;

export default NoSurvey;
