import React, { useCallback, useState, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Layout, Row, Col, Alert } from 'antd';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SubscriptionHeader from '../../components/subscription/SubscriptionHeader';
import AuthInfo from '../../components/utilities/auth-info/info';
import { getUserProjects } from '../../redux/project/actionCreator';

import ProjectDropdown from '../widgets/ProjectDropdown';
import HeaderSearch from '../widgets/HeaderSearch';
import './style.css';
import { duotone, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import useNotificationController from '../../utility/useNotificationController';

function MainHeader({ MenueItemsFor, isCollapsed, setSidebarData, projectDropdownChanged, handleProjectDropdownChanged }) {
  const {
    contextHolder,
    openSuccess,
  } = useNotificationController();

  var isAmazon = localStorage.getItem('is_amazon') === 'true';
  var accountLogo = localStorage.getItem('accountLogo') === "null" ? "" : localStorage.getItem('accountLogo');
  
  const organizationalName = localStorage.getItem('organizationalName');
  const { Header } = Layout;

  const dispatch = useDispatch();

  const [UserInfo, setUserProjects] = useState({
    loaded: false,
    hasData: false,
    organizationalSlug: localStorage.getItem('organizationalSlug'),
    accountData: null,
    userProjects: [],
    hideMenu: false,
    collapsed: false,
    isError: false,
    successType: '',
    errorMessage: '',
    isSuccess: false,
    successMessage: '',
    userStatus: "",
    remainingDays: ""
  });

  const onShowHide = (state) => {
    setUserProjects({...state, hideMenu: !UserInfo.hideMenu})
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'USER_PROJECT_ERR':
        return { ...state, error: true, userProjectMessage: action.err };
      case 'RESET':
        return state;
      default:
        return state;
    }
  };

  const userProjectResponse = {
    error: false,
    userProjectMessage: '',
  };

  const [userProjectError, userProjectResponses] = useReducer(reducer, userProjectResponse);

  const fetchData = useCallback(async () => {
    userProjectResponses({ type: 'RESET' });
    dispatch(
      getUserProjects((response) => {
        if (response.status === 200) {
          const user_data_object = response.data._doc;
          const account_data = JSON.parse(user_data_object.userData);
          const sidebar_data = JSON.parse(response.data.sidebar_data);
          const isFreeAccount = response.data.isFreeAccount;
          let projects = [];
          let found = false;
          for (var userInd in account_data.account_users) {
            const accountProjectAccess = account_data.account_users[userInd].account_user_projects;
            for(var i in accountProjectAccess)
            {
              projects.push(accountProjectAccess[i]);
            }
          }
          setUserProjects(
            {
              loaded: true,
              hasData: true,
              organizationalSlug: localStorage.getItem('organizationalSlug'),
              accountData: account_data,
              userProjects: projects,
              hideMenu: false,
              collapsed: false,
              isError: false,
              successType: '',
              errorMessage: '',
              isSuccess: false,
              successMessage: '',
              userStatus: account_data.userStatus,
              remainingDays: account_data.trialDays
            });
            
            setSidebarData(sidebar_data, UserInfo.organizationalSlug, isFreeAccount);
        } else {
          userProjectResponses(response);
        }
      }),
    );
  }, [dispatch, UserInfo]);

  useEffect(() => {
    if (projectDropdownChanged)
    {
      handleProjectDropdownChanged();
      setUserProjects({
        loaded: false,
        hasData: false,
        organizationalSlug: localStorage.getItem('organizationalSlug'),
        accountData: null,
        userProjects: [],
        hideMenu: false,
        collapsed: false,
        isError: false,
        successType: '',
        errorMessage: '',
        isSuccess: false,
        successMessage: '',
        userStatus: "",
        remainingDays: ""
      });
    }
    const retrieveData = async () => {
      fetchData();
    }

    if (!UserInfo.loaded)
    {
      retrieveData()
      .catch(console.error);
    }
  }, [projectDropdownChanged]);

  const userTheme = JSON.parse(localStorage.getItem('userTheme'));
  
  return (
    <>
      <Header
        style={{
          position: 'fixed',
          backgroundColor: `${userTheme.TopNavigationBar}`,
          width: '100%',
          top: 0,
          left: 0,
        }}
      >
        {contextHolder}
        <Row>
          {!UserInfo.loaded ? (
            <div className="loader" style={{display: 'flex', alignItems: 'center', justifyContent: "center", marginBottom: "40px"}}>
              <FontAwesomeIcon icon={duotone("spinner-third")} spin size="2xl" style={{"--fa-primary-color": "#3A3540", "--fa-secondary-color": "#3A3540", "padding": "20px 10px"}} />
            </div>
          ) : (
            <>
            <Col md={24} sm={24} xs={24}>
              <div className="ocmsolution-header-content d-flex">
                <Col xxl={6} xl={6} lg={6} md={4} sm={0} xs={0} style={{maxHeight: "70px"}}>
                  <div className="ocmsolution-header-content__left">
                    <div className="navbar-brand align-cener-v">
                      <Link
                        className={MenueItemsFor && window.innerWidth > 991 ? 'ocmsolution-logo top-menu' : 'ocmsolution-logo'}
                        to={`/${UserInfo.organizationalSlug}/main-dashboard`}
                      >
                        {accountLogo !== "" ? (
                          <img src={`${process.env.REACT_APP_API_ENDPOINT}/images/account_images/${accountLogo}`} alt={`${organizationalName}`} style={{height: "65px", maxHeight: "65px", paddingTop: "15px"}} />
                        ) : (
                          isAmazon ? (
                            <img className='svg-class-name' style={{marginRight: "20px", marginTop: "5px"}}
                              src={
                                require(`../../static/images/amazon_logo_white.svg`).default
                              }
                              alt="OCM Solution"
                            />
                          ) : (
                            <img className='svg-class-name'
                            src={
                              require(`../../static/logo/ocms-logo-white-text.svg`).default
                            }
                            alt="OCM Solution"
                          />
                          )
                        )}
                      
                      </Link>

                      {window.innerWidth > 991 && (
                          <div className="ocmsolution-header-content__right d-flex">
                            <div className="ocmsolution-navbar-menu d-flex align-center-v">
                                <HeaderSearch
                                    UserInfo={UserInfo}
                                />
                            </div>
                          </div>
                      )}

                      {!(!MenueItemsFor || window.innerWidth > 991) ? (
                        <Button type="link" onClick={isCollapsed}>
                          <img
                            src={require(`../../static/img/icon/${
                              UserInfo.collapsed ? 'Hamburger_icon_white.svg' : 'Hamburger_icon_white.svg'
                            }`)}
                            alt="menu"
                          />
                        </Button>
                      ) : null}
                    </div>
                  </div>
                </Col>
                <Col xxl={0} xl={0} lg={0} md={0} sm={16} xs={16}>
                  <div className="ocmsolution-header-content__left">
                    <div className="navbar-brand align-cener-v">
                      <Link
                        className={MenueItemsFor && window.innerWidth > 991 ? 'ocmsolution-logo top-menu' : 'ocmsolution-logo'}
                        to={`/${UserInfo.organizationalSlug}/main-dashboard`}
                      >
                        <img className='svg-class-name'
                          src={
                            require(`../../static/logo/ocms-logo-white-text.svg`).default
                          }
                          alt="OCM Solution"
                        />
                      </Link>

                      {window.innerWidth > 991 && (
                          <div className="ocmsolution-header-content__right d-flex">
                            <div className="ocmsolution-navbar-menu d-flex align-center-v">
                                <HeaderSearch
                                    UserInfo={UserInfo}
                                />
                            </div>
                          </div>
                      )}

                      {!(!MenueItemsFor || window.innerWidth > 991) ? (
                        <Button type="link" onClick={isCollapsed}>
                          <img
                            src={require(`../../static/img/icon/${
                              UserInfo.collapsed ? 'Hamburger_icon_white.svg' : 'Hamburger_icon_white.svg'
                            }`)}
                            alt="menu"
                          />
                        </Button>
                      ) : null}

                      <div className="ocmsolution-info-panel">
                          {UserInfo.hasData === false && (
                            <div className="loader">
                              <FontAwesomeIcon 
                                icon={regular("circle-notch")} 
                                spin 
                                size="2xl" 
                                style={{ color: `${(userTheme.iconColor !== undefined && userTheme.iconColor !== "") ? userTheme.iconColor : "#FFFFFF"}` }}
                              />
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={8} sm={0} xs={0}>
                  <div className="ocmsolution-info-panel">
                    {UserInfo.hasData === false ? (
                      <div className="loader">
                        <FontAwesomeIcon 
                          icon={regular("circle-notch")} 
                          spin
                          size="2xl"
                          style={{ color: `${(userTheme.iconColor !== undefined && userTheme.iconColor !== "") ? userTheme.iconColor : "#FFFFFF"}` }}
                        />
                      </div>
                    ) : (
                      <ProjectDropdown
                        className="ocm-projects-dropdown"
                        UserInfo={UserInfo}
                        axiosCallback={
                          function callback(newState) {
                            openSuccess("bottomRight", newState.successType, newState.successMessage)
                          }
                        }
                      />
                    )}
                  </div>
                </Col>
                <Col xxl={12} xl={12} lg={12} md={12} sm={0} xs={0}>
                  <div className="ocmsolution-nav-actions" style={{height: "100%", display: "flex", justifyContent: "end"}}>
                    {MenueItemsFor && window.innerWidth > 991 ? (
                      <TopMenuSearch>
                        <div className="top-right-wrap d-flex">
                          {
                            UserInfo.hasData && (
                              <>
                              <SubscriptionHeader 
                                UserInfo={UserInfo} 
                              />
                                <AuthInfo
                                  UserInfo={UserInfo}
                                  axiosCallback={
                                      function callback(newState) {
                                
                                      }
                                  }
                                />
                              </>
                          )};
                        </div>
                      </TopMenuSearch>
                    ) : (
                      <>
                        {window.innerWidth <= 991 ? (
                            <>
                              {
                                UserInfo.hasData && (
                                  <>
                                    <SubscriptionHeader 
                                      UserInfo={UserInfo} 
                                    />
                                    <AuthInfo
                                      UserInfo={UserInfo}
                                      axiosCallback={
                                          function callback(newState) {
                                    
                                          }
                                      }
                                    />
                                  </>
                              )};
                            </>
                        ) : (
                          <>
                            {
                              UserInfo.hasData && (
                                <>
                                <SubscriptionHeader 
                                  UserInfo={UserInfo} 
                                />
                                <AuthInfo
                                    UserInfo={UserInfo}
                                    axiosCallback={
                                        function callback(newState) {
                                  
                                        }
                                    }
                                />
                                </>
                            )};
                          </>
                        )};
                      </>
                      
                    )}
                  </div>
                </Col>
              </div>
            </Col>
            </>
          )}
        </Row>
      </Header>
      {UserInfo.isError && 
        <Alert message="Error" description={UserInfo.errorMessage} closable={false} type="error" showIcon style={{top: "50px", zIndex: "989"}} />
      }
    </>
  );
}

export default MainHeader;
