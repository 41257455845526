import { notificationsReducerTypes, NotificationType } from './constants';


export const notificationsReducer = (state, action) => {
  switch (action.type) {
    case notificationsReducerTypes.SUCCESS:
      return {
        ...state,
        type: NotificationType.SUCCESS,
        ...action.payload
      };

    case notificationsReducerTypes.ERROR:
      return {
        ...state,
        type: NotificationType.ERROR,
        ...action.payload
      };

    case notificationsReducerTypes.INFO:
      return {
        ...state,
        type: NotificationType.INFO,
        ...action.payload
      };
    case notificationsReducerTypes.WARNING:
      return {
        ...state,
        type: NotificationType.WARNING,
        ...action.payload
      };
    case notificationsReducerTypes.HIDE:
      return {
        ...state,
        type: null,
        message: null,
        description: null
      };
    default:
      return state;
  }
};