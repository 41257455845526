import PropTypes from 'prop-types';
import { HeaderWrapper, PageHeaderStyle } from './style';

function PageHeader(props) {
  const { title, subTitle, routes, buttons, ghost, bgColor, className } = props;
  
  return (
    <HeaderWrapper bgColor={bgColor} style={{textAlign: "center", display: "flex", justifyContent: "center"}}>
      <PageHeaderStyle
        className={className}
        title={title}
        subTitle={subTitle}
        routes={routes}
        extra={buttons}
        ghost={ghost}
      />
    </HeaderWrapper>
  );
}

export { PageHeader };
