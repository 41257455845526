import React, { lazy, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import AuthLayout from '../container/profile/authentication/Index';
import PublicSurveyPage from '../container/surveys/PublicSurveyPage';
import { useSelector } from 'react-redux';
import CMSPageContent from '../container/cmsLibrary/CMSPageContent';
import CMSPublicPageContent from '../container/cmsLibrary/CMSPublicPageContent';

const OCMForgotPass = lazy(() => import('../container/profile/authentication/overview/OcmForgotPass'));
const OCMLogin = lazy(() => import('../container/profile/authentication/overview/OcmLogin'));
const OCMResetPassword = lazy(() => import('../container/profile/authentication/overview/OcmResetPassword'));
const TrialSignUp = lazy(() => import('../container/profile/authentication/overview/TrialSignUp'));
const PaidCheckout = lazy(() => import('../container/profile/authentication/overview/PaidCheckout'));
const NotFound = lazy(() => import('../container/pages/404'));

const AuthRoot = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => {
    return {
      isLoggedIn: state.auth.login,
    };
  });

  useEffect(() => {
    if (isLoggedIn) {
      navigate(`/${localStorage.getItem('organizationalSlug')}/main-dashboard`);
    } else {
      navigate('/');
    }
  }, [isLoggedIn]);
};

const FrontendRoutes = React.memo(() => {
  return (
    <Routes>
      <Route index element={<OCMLogin />} />
      <Route path="forgotPassword" exact element={<OCMForgotPass />} />
      <Route exact path="reset-password" element={<OCMLogin />} />
      <Route exact path="reset-password/:token" element={<OCMResetPassword />} />
      <Route exact path="page/:slug" element={<CMSPublicPageContent />} />
      <Route
        path="free-trial-checkout/*"
        element={<TrialSignUp noCaptcha={true} trial_key={`free-trial-registration`} />}
      />
      <Route
        exact
        path="free-10-days-trial"
        element={<TrialSignUp noCaptcha={true} trial_key={`free-trial-registration-10-days`} />}
      />
      <Route
        exact
        path="free-trial-amazon"
        element={<TrialSignUp noCaptcha={false} trial_key={`free-trial-registration-90-days`} />}
      />
      <Route exact path="free-trial-two" element={<TrialSignUp noCaptcha={false} trial_key={`free-trial-two-day`} />} />
      <Route exact path="free-checkout" element={<TrialSignUp noCaptcha={true} trial_key={`free-checkout`} />} />
      <Route exact path="free-training-checkout" element={<TrialSignUp noCaptcha={true} trial_key={`free-training-checkout`} />} />
      <Route exact path="all-in-checkout" element={<PaidCheckout noCaptcha={false} trial_key={`allincheckout`} />} />
      <Route path={`public/survey/:surveyId`} element={<PublicSurveyPage />} />

      {/* <Route path="*" element={<NotFound />} /> */}
      <Route path="*" element={<AuthRoot />} />
    </Routes>
  );
});

export default AuthLayout(FrontendRoutes);
