const actions = {
  LOGIN_BEGIN: 'LOGIN_BEGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERR: 'LOGIN_ERR',

  FORGOT_PASS_BEGIN: 'FORGOT_PASS_BEGIN',
  FORGOT_PASS_SUCCESS: 'FORGOT_PASS_SUCCESS',
  FORGOT_PASS_ERR: 'FORGOT_PASS_ERR',

  RESET_PASSWORD_BEGIN: 'RESET_PASSWORD_BEGIN',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERR: 'RESET_PASSWORD_ERR',

  LOGOUT_BEGIN: 'LOGOUT_BEGIN',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERR: 'LOGOUT_ERR',

  VERIFY_USER_BEGIN: 'VERIFY_USER_BEGIN',
  VERIFY_USER_SUCCESS: 'VERIFY_USER_SUCCESS',
  VERIFY_USER_ERR: 'VERIFY_USER_ERR',

  VALIDATE_PRICING_BEGIN: 'VALIDATE_PRICING_BEGIN',
  VALIDATE_PRICING_SUCCESS: 'VALIDATE_PRICING_SUCCESS',
  VALIDATE_PRICING_ERR: 'VALIDATE_PRICING_ERR',

  CREATE_CUSTOMER_BEGIN: 'CREATE_CUSTOMER_BEGIN',
  CREATE_CUSTOMER_SUCCESS: 'CREATE_CUSTOMER_SUCCESS',
  CREATE_CUSTOMER_ERR: 'CREATE_CUSTOMER_ERR',

  UPDATE_CUSTOMER_BEGIN: 'UPDATE_CUSTOMER_BEGIN',
  UPDATE_CUSTOMER_SUCCESS: 'UPDATE_CUSTOMER_SUCCESS',
  UPDATE_CUSTOMER_ERR: 'UPDATE_CUSTOMER_ERR',

  UPDATE_CUSTOMER_SUBSCRIPTION_BEGIN: 'UPDATE_CUSTOMER_SUBSCRIPTION_BEGIN',
  UPDATE_CUSTOMER_SUBSCRIPTION_SUCCESS: 'UPDATE_CUSTOMER_SUBSCRIPTION_SUCCESS',
  UPDATE_CUSTOMER_SUBSCRIPTION_ERR: 'UPDATE_CUSTOMER_SUBSCRIPTION_ERR',

  GET_CUSTOMER_PAYMENT_METHOD_BEGIN: 'GET_CUSTOMER_PAYMENT_METHOD_BEGIN',
  GET_CUSTOMER_PAYMENT_METHOD_SUCCESS: 'GET_CUSTOMER_PAYMENT_METHOD_SUCCESS',
  GET_CUSTOMER_PAYMENT_METHOD_ERR: 'GET_CUSTOMER_PAYMENT_METHOD_ERR',

  ADD_CUSTOMER_PAYMENT_METHOD_BEGIN: 'ADD_CUSTOMER_PAYMENT_METHOD_BEGIN',
  ADD_CUSTOMER_PAYMENT_METHOD_SUCCESS: 'ADD_CUSTOMER_PAYMENT_METHOD_SUCCESS',
  ADD_CUSTOMER_PAYMENT_METHOD_ERR: 'ADD_CUSTOMER_PAYMENT_METHOD_ERR',

  SETUP_CUSTOMER_PAYMENT_BEGIN: 'SETUP_CUSTOMER_PAYMENT_BEGIN',
  SETUP_CUSTOMER_PAYMENT_SUCCESS: 'SETUP_CUSTOMER_PAYMENT_SUCCESS',
  SETUP_CUSTOMER_PAYMENT_ERR: 'SETUP_CUSTOMER_PAYMENT_ERR',

  RE_SETUP_CUSTOMER_PAYMENT_BEGIN: 'RE_SETUP_CUSTOMER_PAYMENT_BEGIN',
  RE_SETUP_CUSTOMER_PAYMENT_SUCCESS: 'RE_SETUP_CUSTOMER_PAYMENT_SUCCESS',
  RE_SETUP_CUSTOMER_PAYMENT_ERR: 'RE_SETUP_CUSTOMER_PAYMENT_ERR',

  SET_CARD_AS_PRIMARY_BEGIN: 'SET_CARD_AS_PRIMARY_BEGIN',
  SET_CARD_AS_PRIMARY_SUCCESS: 'SET_CARD_AS_PRIMARY_SUCCESS',
  SET_CARD_AS_PRIMARY_ERR: 'SET_CARD_AS_PRIMARY_ERR',

  DELETE_CUSTOMER_CARD_BEGIN: 'DELETE_CUSTOMER_CARD_BEGIN',
  DELETE_CUSTOMER_CARD_SUCCESS: 'DELETE_CUSTOMER_CARD_SUCCESS',
  DELETE_CUSTOMER_CARD_ERR: 'DELETE_CUSTOMER_CARD_ERR',

  CANCEL_CUSTOMER_SUBSCRIPTION_BEGIN: 'CANCEL_CUSTOMER_SUBSCRIPTION_BEGIN',
  CANCEL_CUSTOMER_SUBSCRIPTION_SUCCESS: 'CANCEL_CUSTOMER_SUBSCRIPTION_SUCCESS',
  CANCEL_CUSTOMER_SUBSCRIPTION_ERR: 'CANCEL_CUSTOMER_SUBSCRIPTION_ERR',

  CHANGE_SUBSCRIPTION_BILLING_CYCLE_BEGIN: 'CHANGE_SUBSCRIPTION_BILLING_CYCLE_BEGIN',
  CHANGE_SUBSCRIPTION_BILLING_CYCLE_SUCCESS: 'CHANGE_SUBSCRIPTION_BILLING_CYCLE_SUCCESS',
  CHANGE_SUBSCRIPTION_BILLING_CYCLE_ERR: 'CHANGE_SUBSCRIPTION_BILLING_CYCLE_ERR',

  PAUSE_CUSTOMER_SUBSCRIPTION_BEGIN: 'PAUSE_CUSTOMER_SUBSCRIPTION_BEGIN',
  PAUSE_CUSTOMER_SUBSCRIPTION_SUCCESS: 'PAUSE_CUSTOMER_SUBSCRIPTION_SUCCESS',
  PAUSE_CUSTOMER_SUBSCRIPTION_ERR: 'PAUSE_CUSTOMER_SUBSCRIPTION_ERR',

  STOP_SUBSCRIPTION_CANCELLATION_BEGIN: 'STOP_SUBSCRIPTION_CANCELLATION_BEGIN',
  STOP_SUBSCRIPTION_CANCELLATION_SUCCESS: 'STOP_SUBSCRIPTION_CANCELLATION_SUCCESS',
  STOP_SUBSCRIPTION_CANCELLATION_ERR: 'STOP_SUBSCRIPTION_CANCELLATION_ERR',

  RESUME_CUSTOMER_SUBSCRIPTION_BEGIN: 'RESUME_CUSTOMER_SUBSCRIPTION_BEGIN',
  RESUME_CUSTOMER_SUBSCRIPTION_SUCCESS: 'RESUME_CUSTOMER_SUBSCRIPTION_SUCCESS',
  RESUME_CUSTOMER_SUBSCRIPTION_ERR: 'RESUME_CUSTOMER_SUBSCRIPTION_ERR',

  DELETE_ACCOUNT_SUBSCRIPTION_BEGIN: 'DELETE_ACCOUNT_SUBSCRIPTION_BEGIN',
  DELETE_ACCOUNT_SUBSCRIPTION_SUCCESS: 'DELETE_ACCOUNT_SUBSCRIPTION_SUCCESS',
  DELETE_ACCOUNT_SUBSCRIPTION_ERR: 'DELETE_ACCOUNT_SUBSCRIPTION_ERR',

  RESTART_CUSTOMER_SUBSCRIPTION_AGAIN_BEGIN: 'RESTART_CUSTOMER_SUBSCRIPTION_AGAIN_BEGIN',
  RESTART_CUSTOMER_SUBSCRIPTION_AGAIN_SUCCESS: 'RESTART_CUSTOMER_SUBSCRIPTION_AGAIN_SUCCESS',
  RESTART_CUSTOMER_SUBSCRIPTION_AGAIN_ERR: 'RESTART_CUSTOMER_SUBSCRIPTION_AGAIN_ERR',

  CANCEL_DELETION_OF_CUSTOMER_ACCOUNT_BEGIN: 'CANCEL_DELETION_OF_CUSTOMER_ACCOUNT_BEGIN',
  CANCEL_DELETION_OF_CUSTOMER_ACCOUNT_SUCCESS: 'CANCEL_DELETION_OF_CUSTOMER_ACCOUNT_SUCCESS',
  CANCEL_DELETION_OF_CUSTOMER_ACCOUNT_ERR: 'CANCEL_DELETION_OF_CUSTOMER_ACCOUNT_ERR',

  CONVERT_TO_TRIAL_BEGIN: 'CONVERT_TO_TRIAL_BEGIN',
  CONVERT_TO_TRIAL_SUCCESS: 'CONVERT_TO_TRIAL_SUCCESS',
  CONVERT_TO_TRIAL_ERR: 'CONVERT_TO_TRIAL_ERR',

  loginBegin: () => {
    return {
      type: actions.LOGIN_BEGIN,
    };
  },

  loginSuccess: (data) => {    
    return {
      type: actions.LOGIN_SUCCESS,
      data,
    };
  },

  loginErr: (err) => {
    return {
      type: actions.LOGIN_ERR,
      err,
    };
  },

  forgotPassBegin: () => {
    return {
      type: actions.FORGOT_PASS_BEGIN,
    };
  },

  forgotPassSuccess: (data) => {    
    return {
      type: actions.FORGOT_PASS_SUCCESS,
      data,
    };
  },

  forgotPassErr: (err) => {
    return {
      type: actions.FORGOT_PASS_ERR,
      err,
    };
  },

  verifyUserBegin: () => {
    return {
      type: actions.VERIFY_USER_BEGIN,
    };
  },

  verifyUserSuccess: (data) => {    
    return {
      type: actions.VERIFY_USER_SUCCESS,
      data,
    };
  },

  verifyUserErr: (err) => {
    return {
      type: actions.VERIFY_USER_ERR,
      err,
    };
  },

  validatePricingBegin: () => {
    return {
      type: actions.VALIDATE_PRICING_BEGIN,
    };
  },

  validatePricingSuccess: (data) => {    
    return {
      type: actions.VALIDATE_PRICING_SUCCESS,
      data,
    };
  },

  validatePricingErr: (err) => {
    return {
      type: actions.VALIDATE_PRICING_ERR,
      err,
    };
  },

  createCustomerBegin: () => {
    return {
      type: actions.CREATE_CUSTOMER_BEGIN,
    };
  },

  createCustomerSuccess: (data) => {    
    return {
      type: actions.CREATE_CUSTOMER_SUCCESS,
      data,
    };
  },

  createCustomerErr: (err) => {
    return {
      type: actions.CREATE_CUSTOMER_ERR,
      err,
    };
  },

  updateCustomerBegin: () => {
    return {
      type: actions.UPDATE_CUSTOMER_BEGIN,
    };
  },

  updateCustomerSuccess: (data) => {    
    return {
      type: actions.UPDATE_CUSTOMER_SUCCESS,
      data,
    };
  },

  updateCustomerErr: (err) => {
    return {
      type: actions.UPDATE_CUSTOMER_ERR,
      err,
    };
  },

  updateCustomerSubscriptionBegin: () => {
    return {
      type: actions.UPDATE_CUSTOMER_SUBSCRIPTION_BEGIN,
    };
  },

  updateCustomerSubscriptionSuccess: (data) => {    
    return {
      type: actions.UPDATE_CUSTOMER_SUBSCRIPTION_SUCCESS,
      data,
    };
  },

  updateCustomerSubscriptionErr: (err) => {
    return {
      type: actions.UPDATE_CUSTOMER_SUBSCRIPTION_ERR,
      err,
    };
  },

  getCustomerPaymentMethodsBegin: () => {
    return {
      type: actions.GET_CUSTOMER_PAYMENT_METHOD_BEGIN,
    };
  },

  getCustomerPaymentMethodsSuccess: (data) => {    
    return {
      type: actions.GET_CUSTOMER_PAYMENT_METHOD_SUCCESS,
      data,
    };
  },

  getCustomerPaymentMethodsErr: (err) => {
    return {
      type: actions.GET_CUSTOMER_PAYMENT_METHOD_ERR,
      err,
    };
  },

  addCustomerPaymentMethodBegin: () => {
    return {
      type: actions.ADD_CUSTOMER_PAYMENT_METHOD_BEGIN,
    };
  },

  addCustomerPaymentMethodSuccess: (data) => {    
    return {
      type: actions.ADD_CUSTOMER_PAYMENT_METHOD_SUCCESS,
      data,
    };
  },

  addCustomerPaymentMethodErr: (err) => {
    return {
      type: actions.ADD_CUSTOMER_PAYMENT_METHOD_ERR,
      err,
    };
  },

  setupCustomerPaymentBegin: () => {
    return {
      type: actions.SETUP_CUSTOMER_PAYMENT_BEGIN,
    };
  },

  setupCustomerPaymentSuccess: (data) => {    
    return {
      type: actions.SETUP_CUSTOMER_PAYMENT_SUCCESS,
      data,
    };
  },

  setupCustomerPaymentErr: (err) => {
    return {
      type: actions.SETUP_CUSTOMER_PAYMENT_ERR,
      err,
    };
  },

  reSetupCustomerPaymentBegin: () => {
    return {
      type: actions.RE_SETUP_CUSTOMER_PAYMENT_BEGIN,
    };
  },

  reSetupCustomerPaymentSuccess: (data) => {    
    return {
      type: actions.RE_SETUP_CUSTOMER_PAYMENT_SUCCESS,
      data,
    };
  },

  reSetupCustomerPaymentErr: (err) => {
    return {
      type: actions.RE_SETUP_CUSTOMER_PAYMENT_ERR,
      err,
    };
  },

  setCardasPrimaryBegin: () => {
    return {
      type: actions.SET_CARD_AS_PRIMARY_BEGIN,
    };
  },

  setCardasPrimarySuccess: (data) => {    
    return {
      type: actions.SET_CARD_AS_PRIMARY_SUCCESS,
      data,
    };
  },

  setCardasPrimaryErr: (err) => {
    return {
      type: actions.SET_CARD_AS_PRIMARY_ERR,
      err,
    };
  },

  deleteCustomerCardBegin: () => {
    return {
      type: actions.DELETE_CUSTOMER_CARD_BEGIN,
    };
  },

  deleteCustomerCardSuccess: (data) => {    
    return {
      type: actions.DELETE_CUSTOMER_CARD_SUCCESS,
      data,
    };
  },

  deleteCustomerCardErr: (err) => {
    return {
      type: actions.DELETE_CUSTOMER_CARD_ERR,
      err,
    };
  },

  cancelCustomerSubscriptionBegin: () => {
    return {
      type: actions.CANCEL_CUSTOMER_SUBSCRIPTION_BEGIN,
    };
  },

  cancelCustomerSubscriptionSuccess: (data) => {    
    return {
      type: actions.CANCEL_CUSTOMER_SUBSCRIPTION_SUCCESS,
      data,
    };
  },

  cancelCustomerSubscriptionErr: (err) => {
    return {
      type: actions.CANCEL_CUSTOMER_SUBSCRIPTION_ERR,
      err,
    };
  },

  changeSubscriptionBillingCycleBegin: () => {
    return {
      type: actions.CHANGE_SUBSCRIPTION_BILLING_CYCLE_BEGIN,
    };
  },

  changeSubscriptionBillingCycleSuccess: (data) => {    
    return {
      type: actions.CHANGE_SUBSCRIPTION_BILLING_CYCLE_SUCCESS,
      data,
    };
  },

  changeSubscriptionBillingCycleErr: (err) => {
    return {
      type: actions.CHANGE_SUBSCRIPTION_BILLING_CYCLE_ERR,
      err,
    };
  },

  pauseCustomerSubscriptionBegin: () => {
    return {
      type: actions.PAUSE_CUSTOMER_SUBSCRIPTION_BEGIN,
    };
  },

  pauseCustomerSubscriptionSuccess: (data) => {    
    return {
      type: actions.PAUSE_CUSTOMER_SUBSCRIPTION_SUCCESS,
      data,
    };
  },

  pauseCustomerSubscriptionErr: (err) => {
    return {
      type: actions.PAUSE_CUSTOMER_SUBSCRIPTION_ERR,
      err,
    };
  },

  stopSubscriptionCancellationBegin: () => {
    return {
      type: actions.STOP_SUBSCRIPTION_CANCELLATION_BEGIN,
    };
  },

  stopSubscriptionCancellationSuccess: (data) => {    
    return {
      type: actions.STOP_SUBSCRIPTION_CANCELLATION_SUCCESS,
      data,
    };
  },

  stopSubscriptionCancellationErr: (err) => {
    return {
      type: actions.STOP_SUBSCRIPTION_CANCELLATION_ERR,
      err,
    };
  },

  resumeCustomerSubscriptionBegin: () => {
    return {
      type: actions.RESUME_CUSTOMER_SUBSCRIPTION_BEGIN,
    };
  },

  resumeCustomerSubscriptionSuccess: (data) => {    
    return {
      type: actions.RESUME_CUSTOMER_SUBSCRIPTION_SUCCESS,
      data,
    };
  },

  resumeCustomerSubscriptionErr: (err) => {
    return {
      type: actions.RESUME_CUSTOMER_SUBSCRIPTION_ERR,
      err,
    };
  },

  deleteAccountSubscriptionBegin: () => {
    return {
      type: actions.DELETE_ACCOUNT_SUBSCRIPTION_BEGIN,
    };
  },

  deleteAccountSubscriptionSuccess: (data) => {    
    return {
      type: actions.DELETE_ACCOUNT_SUBSCRIPTION_SUCCESS,
      data,
    };
  },

  deleteAccountSubscriptionErr: (err) => {
    return {
      type: actions.DELETE_ACCOUNT_SUBSCRIPTION_ERR,
      err,
    };
  },

  restartCustomerSubscriptionAgainBegin: () => {
    return {
      type: actions.RESTART_CUSTOMER_SUBSCRIPTION_AGAIN_BEGIN,
    };
  },

  restartCustomerSubscriptionAgainSuccess: (data) => {    
    return {
      type: actions.RESTART_CUSTOMER_SUBSCRIPTION_AGAIN_SUCCESS,
      data,
    };
  },

  restartCustomerSubscriptionAgainErr: (err) => {
    return {
      type: actions.RESTART_CUSTOMER_SUBSCRIPTION_AGAIN_ERR,
      err,
    };
  },

  cancelDeletionOfCustomerAccountBegin: () => {
    return {
      type: actions.CANCEL_DELETION_OF_CUSTOMER_ACCOUNT_BEGIN,
    };
  },

  cancelDeletionOfCustomerAccountSuccess: (data) => {    
    return {
      type: actions.CANCEL_DELETION_OF_CUSTOMER_ACCOUNT_SUCCESS,
      data,
    };
  },

  cancelDeletionOfCustomerAccountErr: (err) => {
    return {
      type: actions.CANCEL_DELETION_OF_CUSTOMER_ACCOUNT_ERR,
      err,
    };
  },

  convertToTrialBegin: () => {
    return {
      type: actions.CONVERT_TO_TRIAL_BEGIN,
    };
  },

  convertToTrialSuccess: (data) => {    
    return {
      type: actions.CONVERT_TO_TRIAL_SUCCESS,
      data,
    };
  },

  convertToTrialErr: (err) => {
    return {
      type: actions.CONVERT_TO_TRIAL_ERR,
      err,
    };
  },

  resetPasswordBegin: () => {
    return {
      type: actions.RESET_PASSWORD_BEGIN,
    };
  },

  resetPasswordSuccess: (data) => {    
    return {
      type: actions.RESET_PASSWORD_SUCCESS,
      data,
    };
  },

  resetPasswordErr: (err) => {
    return {
      type: actions.RESET_PASSWORD_ERR,
      err,
    };
  },

  logoutBegin: () => {
    return {
      type: actions.LOGOUT_BEGIN,
    };
  },

  logoutSuccess: (data) => {
    return {
      type: actions.LOGOUT_SUCCESS,
      data,
    };
  },

  logoutErr: (err) => {
    return {
      type: actions.LOGOUT_ERR,
      err,
    };
  },
};

export default actions;