import React, { Suspense, lazy, useEffect, useReducer, useCallback } from 'react';
import { Row, Col, Skeleton, Tabs, Typography, List, Card } from 'antd';
const {Title, Text} = Typography;
import { FreeUserToolStyle, ToolsDashboardPage } from './Style';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Main } from '../styled';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Cookies from "js-cookie";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import * as ProIcons from '@fortawesome/pro-duotone-svg-icons';

import { useDispatch } from 'react-redux';
import { convertToTrial, verifyUser } from '../../redux/authentication/actionCreator';
import { DataService } from '../../config/dataService/dataService';
import { PhaseCardStyle } from '../../components/cards/Style';
import { saveLoginUserCookie } from '../../utility/utility';
import useNotificationController from '../../utility/useNotificationController';

const { theme } = require('../../config/theme/themeVariables');

let toolsTab = [];

const PhaseToolsCard = lazy(() => import('../../components/cards/PhaseToolsCard'));

function ToolsDashboard(props) {
  const {
    contextHolder,
    openError,
    openSuccess,
  } = useNotificationController();

  const [downloading, setDownloading] = useState({});
  const [downloadingTitle, setDownloadingTitle] = useState({});
  const [loading, setLoading] = useState(false);
  const [moreIcon, setMoreIcon] = useState('circle-arrow-right');
  const userTheme = JSON.parse(localStorage.getItem('userTheme'));
  const [currentSlug, setCurrentSlug] = useState(localStorage.getItem('organizationalSlug'));
  theme['user-theme'] = userTheme;


  let icon = 'faSpinnerThird';
  library.add(ProIcons[icon]);

  icon = 'faCircleArrowRight';
  library.add(ProIcons[icon]);

  icon = 'faCircleArrowLeft';
  library.add(ProIcons[icon]);

  let is_amazon = localStorage.getItem('is_amazon') === 'true';
  const organizationalSlug = localStorage.getItem('organizationalSlug');
  const navigate = useNavigate();
  const { pathname } = useLocation();
  let changeByTab = false;
  let paths = pathname.split('/');

  const [isFreeAccount, setIsFreeAccount] = useState(false);
  const [isCancelledAccount, setIsCancelledAccount] = useState(false);
  const [cancellationDate, setCancellationDate] = useState("");
  const [resourcesCount, setResourcesCount] = useState(0);
  const [resourcesShowDefault, setResourcesShowDefault] = useState(7);
  const [resourcesShow, setResourcesShow] = useState(7);
  const [resources, setResources] = useState([]);
  const [resourcesCategory, setResourcesCategory] = useState([]);
  const [allResourcesCategory, setAllResourcesCategory] = useState([]);
  const [oneSelected, setOneSelected] = useState(false);
  const [oneSelectedColor, setOneSelectedColor] = useState("");
  const [oneSelectedIconInfo, setOneSelectedIconInfo] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const [selectedKey, setSelectedKey] = useState("");
  const [defaultTabSet, setDefaultTabSet] = useState(false);
  const [unselectLabel, setUnselectLabel] = useState(3);
  const [selectLabel, setSelectLabel] = useState(2);
  
  let current_tools = "ocms-tools";
  if (paths.length == 4)
  {
    if (paths[paths.length-1] === "free-tools")
    {
      current_tools = "free-tools";
    }
  }
  
  const [defaultActiveKey, setDefaultActiveKey] = useState(current_tools);
  
  let currentPath = current_tools;
  if (currentPath !== defaultActiveKey && !changeByTab)
  {
    setDefaultActiveKey(current_tools);    
  }

  const initialState = {
      error: false,
      messages: ""
  };

  const reducer = (state, action) => {
      switch (action.type) {
        case 'VERIFY_USER_ERR':
          return { ...state, error: true,messages: action.err };
        case 'CONVERT_TO_TRIAL_ERR':
          return { ...state, error: true,messages: action.err };
        case 'RESET':
          return state;
        default:
          return state;
      }
  };

  const [apiError, apiResponseCallback] = useReducer(reducer, initialState);

  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
      apiResponseCallback({ type: 'RESET' });
      dispatch(
          verifyUser({}, async (response) => {
            if (response.status === 200) {
              let organizationalSlug = response.data.organizationalSlug;
              let cancelledAccount = response.data.isCancelled;
              let cancellationDate = response.data.cancellationDate;
              setCurrentSlug(organizationalSlug);
              setIsCancelledAccount(cancelledAccount);
              setCancellationDate(cancellationDate);
              if (!response.data.isFreeAccount)
              {
                if (is_amazon)
                {
                  toolsTab = [
                    {
                      tab_name: "CMI Tools",
                      tab_key: "ocms-tools",
                      tab_index: "1",
                      loadTools: "paid",
                    },
                    {
                      tab_name: "Free Tools",
                      tab_key: "free-tools",
                      tab_index: "2",
                      loadTools: "free",
                    }
                  ];
                }
                else
                {
                  toolsTab = [
                    {
                      tab_name: "Paid OCMS Tools",
                      tab_key: "ocms-tools",
                      tab_index: "1",
                      loadTools: "paid",
                    },
                    {
                      tab_name: "Free Tools",
                      tab_key: "free-tools",
                      tab_index: "2",
                      loadTools: "free",
                    }
                  ];
                }
                let current_tools = "ocms-tools";
                if (paths.length == 4)
                {
                  if (paths[paths.length-1] === "free-tools")
                  {
                    current_tools = "free-tools";
                  }
                }
                setDefaultActiveKey(current_tools);
                setDefaultTabSet(true);  
              }  
              else
              {
                  setIsFreeAccount(true);
                  setDefaultTabSet(true);  
              }
            } 
            else 
            {
              openError('topLeft', 'Error', response.err);
              apiResponseCallback(response);
              //dispatch(logOut(() => navigate('/')));
            }
          }),
        );
  }, [dispatch, 
      paths, 
      defaultActiveKey, 
      defaultTabSet, 
      isFreeAccount,
      isCancelledAccount,
      cancellationDate,
      resources,
      currentSlug,
      resourcesCategory]);

  const convertToTrialAccount = useCallback(async () => {
      const rememberMe = Cookies.get('rememberMe');
      apiResponseCallback({ type: 'RESET' });
      dispatch(
          convertToTrial({rememberMe: rememberMe},(response) => {
          if (response.status === 200) {
            localStorage.clear();
            saveLoginUserCookie(response);
            openSuccess('bottomRight', "Convert to Free user to Trial User",  `Your Account is successfully converted to Trial Account`);
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          } else {
              openError("topLeft", "Convert to Free user to Trial User", response.err)
              apiResponseCallback(response);
          }
        }),
      );
  }, [dispatch]);

  const convertTrial = () => {
      convertToTrialAccount();
  }

  const setItemCategory = (item) => {
    let all_resources = resources;
    let resource_categories = resourcesCategory.map(item => item.link_key);
    let resourceCategoryData = [];
    let resourceCategoryFiles = [];
    for(var key in all_resources)
    {
      let found = false;
      if (all_resources[key].link_key == item.link_key)
      {
        found = true;
        if (item.selected)
        {
          setOneSelected(false);
          setSelectedFiles([]);
          setSelectedName("");
          setSelectedKey("");
          setOneSelectedColor("");
          setOneSelectedIconInfo("");
          found = false;
        }
      }
      if (all_resources[key].faicon !== undefined) {
        const tmp = {};
        tmp.name = key;
        tmp.link_key = all_resources[key].link_key;
        tmp.icon_type = all_resources[key].icon_type;
        tmp.icon = all_resources[key].icon;
        tmp.primary_color = all_resources[key].primary_color;
        tmp.secondary_color = all_resources[key].secondary_color;
        tmp.box_color = all_resources[key].box_color;
        tmp.selected = found ? true : false;
        if (resource_categories.includes(tmp.link_key))
        {
          resourceCategoryData.push(tmp);
        }

        if (found)
        {
          const category_files = all_resources[key].files;
          let filekey = "";
          let itemIsDownloading = {};
          for(var k in category_files)
          {
            const files = {};
            let file = category_files[k];
            files.file_key = file['file-key'];
            filekey = file['file-key'];
            itemIsDownloading[filekey] = false;
            files.filename = file['filename'];
            files.filename_ext = file['filename_ext'];
            files.filesize = file['filesize'];
            files.file_type = file['file_type'];
            let icon = file.faicon;
            library.add(ProIcons[icon]);
            files.icon = file['icon'];
            files.faicon = file['faicon'];
            files.icon_type = file['icon_type'];
            files.primary_color = file['primary_color'];
            files.secondary_color = file['secondary_color'];
            resourceCategoryFiles.push(files);
          }
          setDownloading(itemIsDownloading);
          setDownloadingTitle(itemIsDownloading);
          setSelectedFiles(resourceCategoryFiles);
          setSelectedName(key);
          setSelectedKey(tmp.link_key);
          setOneSelected(true);
          setOneSelectedColor(all_resources[key].box_color);
          const icon = {};
          icon.icon_type = all_resources[key].icon_type;
          icon.icon = all_resources[key].icon;
          icon.primary_color = all_resources[key].primary_color;
          icon.secondary_color = all_resources[key].secondary_color;
          setOneSelectedIconInfo(icon);
          setTimeout(() => {
            window.scrollTo({
                behavior: 'smooth',
                top:
                  document.querySelector(`#resource-selected`).getBoundingClientRect().top -
                  document.body.getBoundingClientRect().top - 200,
            });
          }, 100);
        }
      }
    }
    const tmpData = {};
    tmpData.link_key = "load-More";
    resourceCategoryData.push(tmpData);
    setResourcesCategory(resourceCategoryData);
  }

  const handleDownload = async (item, selectedName, iconClick) => {
    if (iconClick)
    {
      setDownloading({...downloading, [item.file_key]: true});
    }
    else
    {
      setDownloadingTitle({...downloading, [item.file_key]: true});
    }
    const fileName = item.filename_ext;
    const url = `${process.env.REACT_APP_API_ENDPOINT}/file/download/resources/${selectedName}/${fileName}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`, // Include the token in the headers
      },
    });
    if ( response.status === 200 )
    {
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          a.remove();
          if (iconClick)
          {
            setDownloading({...downloading, [item.file_key]: false});
          }
          else
          {
            setDownloadingTitle({...downloading, [item.file_key]: false});
          }
        });

    }
    else
    {
      if (iconClick)
      {
        setDownloading({...downloading, [item.file_key]: false});
      }
      else
      {
        setDownloadingTitle({...downloading, [item.file_key]: false});
      }
      openError('topLeft', 'Error', response.statusText);
    }
  }

  const downloadFile = async (selectedName, fileName) => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/file/download/resources/${selectedName}/${fileName}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`, // Include the token in the headers
      },
    });
    if ( response.status === 200 )
    {
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          a.remove();
        });

    }
    else
    {
      openError('topLeft', 'Error', response.statusText);
    }
  }

  const copyDownload = async (item, selectedName) => {
    const fileName = item.filename_ext;
    const url = `${process.env.REACT_APP_BASE_URL}${organizationalSlug}/file/getfile/resources/${selectedName}/${fileName}`;
    await navigator.clipboard.writeText(url);
  }

  useEffect(() => {
    const retrieveData = async () => {
      await fetchData();
    }
    retrieveData().catch(console.error);
    if (currentPath === "target-audience")
    {
      props.setOpenKeys(['tools-dashboard-audience']);
    }
    else if (currentPath === "free-tools")
    {
      props.setOpenKeys(['tools-dashboard-free-tools']);
    }
    else
    {
      props.setOpenKeys(['tools-dashboard-ocm-tools']);
    }
  }, []);

  const onLoadMore = () => {
    setLoading(true);
    let tmpResource = resourcesCategory;
    tmpResource.pop();
    for(var i=resourcesShow; i<parseInt(resourcesShow) + parseInt(resourcesShowDefault); i++)
    {
      if (allResourcesCategory[i] !== undefined)
      {
        tmpResource.push(allResourcesCategory[i]);
      }
    }
    const tmpData = {};
    tmpData.link_key = "load-More";
    tmpResource.push(tmpData);
    setResourcesCategory(tmpResource);
    setResourcesShow(parseInt(resourcesShow) + parseInt(resourcesShowDefault));
    setLoading(false);
  };

  const onLoadLess = () => {
    setLoading(true);
    let tmpResource = resourcesCategory;
    for(var i=tmpResource.length-1; i>parseInt(resourcesShow) - parseInt(resourcesShowDefault); i--)
    {
      if (tmpResource[i].link_key === selectedKey)
      {
        setOneSelected(false);
      }
      tmpResource.pop();
    }
    tmpResource.pop();
    const tmpData = {};
    tmpData.link_key = "load-More";
    tmpResource.push(tmpData);
    setResourcesCategory(tmpResource);
    setResourcesShow(parseInt(resourcesShow) - parseInt(resourcesShowDefault));
    setLoading(false);
  };

  return (
    <>
      {contextHolder}
      <Main>
        <ToolsDashboardPage>
          <Row justify="center" gutter={25}>
            <Col xxl={24} xl={24} md={24}>
              {!defaultTabSet ? (
                <div>
                  <FontAwesomeIcon icon={duotone("spinner-third")} spin size="2xl" style={{"--fa-primary-color": `${userTheme.mainHighlightColor}`, "--fa-secondary-color": `${userTheme.mainHighlightColor}`, "padding": "20px 10px"}} />
                </div>
              ) : (
                <Suspense
                  fallback={
                    <Cards headless>
                      <Skeleton active />
                    </Cards>
                  }
                >
                  {isFreeAccount ? (
                    <FreeUserToolStyle>
                      <Row justify={'center'} gutter={[40,25]} style={{width: "100%"}}>
                        <Col span={24} style={{textAlign: "center"}}>
                          <Title level={2} style={{margin: "25px 0"}}>Free Tools</Title>
                          <PhaseToolsCard toolType={'free'} currentSlug={currentSlug} load={true} numbers={false} />
                        </Col>
                      </Row>
                      <Row justify={'center'} gutter={[40,25]} style={{width: "100%"}}>
                        <Col span={24} style={{textAlign: "center"}}>
                          {isFreeAccount ? (
                            isCancelledAccount ? (
                              <div className='overlay-free'>
                                <div style={{background: `${is_amazon ? 'rgb(255, 164, 141)' : '#00C4FA'}`, margin: "100px auto", width: "50%", maxHeight: "300px", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "23px", boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.75)"}}>
                                    <div style={{width: "80%", textAlign: "center", color: `${is_amazon ? 'rgb(218, 77, 41)' : '#FFFFFF'}`, padding: "50px", lineHeight: "40px"}}>
                                      <div style={{color: `${is_amazon ? 'rgb(218, 77, 41)' : '#FFFFFF'}`, fontSize: "25px"}}>Your subscription was cancelled on <strong>{cancellationDate}</strong>. Click here to. 
                                        <Title level={3} style={{color: `${is_amazon ? 'rgb(218, 77, 41)' : '#FFFFFF'}`}} underline italic>
                                          <Link to={`/${organizationalSlug}/subscription/restart-subscription`} style={{color: `${is_amazon ? 'rgb(218, 77, 41)' : '#FFFFFF'}`}}>
                                              Restart your subscription again
                                          </Link>
                                        </Title>
                                      </div>
                                    </div>
                                </div>
                              </div>
                            ) : (
                              !isFreeAccount ? '' :
                              <div className='overlay-free'>
                                <div style={{background: `${is_amazon ? 'rgb(255, 164, 141)' : '#00C4FA'}`, margin: "100px auto", width: "50%", maxHeight: "300px", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "23px", boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.75)"}}>
                                    <div style={{width: "70%", textAlign: "center", color: `${is_amazon ? 'rgb(218, 77, 41)' : '#FFFFFF'}`, padding: "50px"}}>
                                      <div style={{color: `${is_amazon ? 'rgb(218, 77, 41)' : '#FFFFFF'}`, fontSize: "25px"}}>You're not a subscribed user. <Title level={3} style={{color: `${is_amazon ? 'rgb(218, 77, 41)' : '#FFFFFF'}`}} underline italic>
                                        <Link to={`/${organizationalSlug}/subscribe-now`} style={{color: `${is_amazon ? 'rgb(218, 77, 41)' : '#FFFFFF'}`}}>
                                            Please subscribe to access paid tools
                                        </Link>
                                      </Title></div>
                                      <Title level={2} style={{fontSize: "45px",color: `${is_amazon ? 'rgb(218, 77, 41)' : '#FFFFFF'}`, marginTop: 0}}>or</Title>
                                      <Title level={3} style={{color: `${is_amazon ? 'rgb(218, 77, 41)' : '#FFFFFF'}`, cursor: "pointer"}} underline onClick={convertTrial}>Start your free trial today!</Title>
                                    </div>
                                </div>
                              </div>
                            )
                          ) : ''}
                          <Title level={2} style={{margin: "25px 0"}}>Paid OCMS Tools</Title>
                          <PhaseToolsCard currentSlug={currentSlug} load={true} toolType={'paid'} numbers={false} />
                        </Col>
                      </Row>
                    </FreeUserToolStyle>
                  ) : (
                    <Tabs
                      activeKey={defaultActiveKey}
                      className='toolTabStyle'
                      centered
                      size={'large'}
                      tabBarGutter={25}
                      items={toolsTab.map((item, i) => {
                            return {
                              label: (<div className='title-label'>
                                        <Title level={item.tab_key === defaultActiveKey ? selectLabel : unselectLabel} style={{margin: "25px 0"}}>{item.tab_name}</Title>
                                        <div className="active-meter active-meter-top"></div>
                                        <div className="active-meter active-meter-bottom"></div>
                                      </div>
                              ),
                              key: item.tab_key,
                              children: (
                                <Suspense
                                  fallback={
                                    <Cards headless>
                                      <Skeleton active />
                                    </Cards>
                                  }
                                >
                                  <PhaseToolsCard currentSlug={currentSlug} load={true} toolType={item.loadTools} numbers={false} />
                                </Suspense>
                              ),
                            };
                        })}
                        onChange={(key) => {
                          setDefaultActiveKey(key)
                          //openKeys();
                          if (key === "ocms-tools")
                          {
                            props.setOpenKeys(['tools-dashboard-ocm-tools']);
                            navigate(`/${organizationalSlug}/tools-dashboard`);
                          }
                          else if (key === "free-tools")
                          {
                            props.setOpenKeys(['tools-dashboard-free-tools']);
                            navigate(`/${organizationalSlug}/tools-dashboard/${key}`);
                          }
                        }}
                    />
                  )}
                  
                </Suspense>
              )}
              
            </Col>
          </Row>
        </ToolsDashboardPage>
      </Main>
    </>
  );
}

export default ToolsDashboard;
