import { faChartMixed } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const NoResponse = ({id, title, message, width= "100%"}) => {
  return (
    <Container id={id}  width={width}>
      <p>{title}</p>
      <FontAwesomeIcon icon={faChartMixed} size='9x' color='#DAE3F2' />
      <div>
       <span>{message}</span>
      </div>
    </Container>
  )

}


const Container = styled.div`
  display: flex;
  border-radius: 7px;
  border: 1px solid rgb(216, 225, 242);
  padding: 17px;
  height: 28rem;
  flex-direction: column;
  position: relative;
  width: 100%;
  background: #fff;
  box-shadow: 0px 4px 4px 0px #00000026;
  p{
    font-size: 18px;
  }
  svg{
    height: 250px;
    width: 250px;
    margin: auto;
  }
  div{
    position: absolute;
    margin: auto;
    font-size: 35px;
    top: 0px;
    left: 0px;
    width: 100%;
    margin: auto;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    z-index: 111;
    span{
      width: 70%;
      color: #3863AE;
      ${'' /* text-transform: capitalize; */}
      text-align: center;
    }
  }

  @media screen and (min-width: 1024px) {
    width: ${({width}) => width };
}
`



export default NoResponse