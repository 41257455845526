import { Form, Input, Modal, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import AddNewOrganization from './AddNewOrganization';
import { ChampionModalBodyStyle } from '../../../Style';

const { Title, Text } = Typography;

function AudienceOranizations(props) {
  const [form] = Form.useForm();

  const showHelp = () => {
    form.resetFields()
    event.stopPropagation();
    const model = Modal.info();

    const closeModal = () => model.destroy();

    model.update({
      icon: (
        <FontAwesomeIcon size='2xl' icon={duotone("circle-xmark")} className='ant-modal-close' onClick={closeModal} />
      ),
      key: 'modal-edit-group',
      centered: true,
      closeIcon: (
        <FontAwesomeIcon size='2xl' icon={duotone("circle-xmark")} className='ant-modal-close' onClick={closeModal} />
      ),
      onCancel: { closeModal },
      wrapClassName: 'ocm-modal',
      okButtonProps: {
        style: { display: "none" },
      },
      width: 1200,
      content: (
        <ChampionModalBodyStyle>
          <div className='body-top-panel'>
            <img src={require(`../../../../../static/images/organizational_levels.png`)} alt="" style={{ width: "100%" }} />
          </div>
        </ChampionModalBodyStyle>
      ),
    });
  }

  return (
    <>
      <div className='title-input'>
        <Title level={2}>Let's add your audience groups!</Title>
      </div>
      <div className='title-group-start'>
        <Text level={4} className='text-group-start'>What does the company structure look like?</Text>
        <div className='organizationalForm'>
          {Object.keys(props.currentGroupNames).filter(key=> !props.currentGroupNames[key].new).map(key => (
            <Form.Item
              size="large"
              label=""
              name={`group_levels_${key}`}
              initialValue={`${props.currentGroupNames[key]}`}
              style={{ marginBottom: 10 }}
              extra={(
                <div className='group-area'>
                  <div className='icon-area'>

                    {key == 0 && <FontAwesomeIcon size='8x' icon={duotone("city")} style={{ "--fa-primary-color": "#0077ce", "--fa-secondary-color": "#20a0ff", }} />}
                    {key == 1 && <FontAwesomeIcon size='5x' icon={duotone("buildings")} style={{ "--fa-primary-color": "#0077ce", "--fa-secondary-color": "#20a0ff", }} />}
                    {key > 1 && <FontAwesomeIcon size='4x' icon={duotone("building")} style={{ "--fa-primary-color": "#0077ce", "--fa-secondary-color": "#20a0ff", }} />}

                  </div>
                  <Title
                    level={4}
                    editable={{
                      icon: <FontAwesomeIcon icon={duotone("pencil")} style={{"--fa-primary-color": "#0077ce", "--fa-secondary-color": "#20a0ff", marginLeft: "10px"}} />,
                      triggerType: ['icon', 'text'],
                      tooltip: 'click to edit initial group name',
                      onChange: (value) => {
                        props.setCurrentGroupNames({ ...props.currentGroupNames, [key]: { name: value } });
                      },
                    }}
                    className='editable-text-field'>{props.currentGroupNames[key].name}
                  </Title>
                  <Text type='secondary'>(e.g., Division, Worksteam)</Text>
                </div>
              )
              }
            >
              <Input type={'hidden'} placeholder='Group Level Name' />
            </Form.Item>
          ))}

          <AddNewOrganization {...props}
            showHelp={showHelp}
            setCurrentGroup={props.setCurrentGroup}
            currentGroup={props.currentGroup}
            currentGroupNames={props.currentGroupNames}
            setCurrentGroupNames={props.setCurrentGroupNames}
            form={form}
            listKey="group_level_key"
            fieldKey={'groupLevelName'}
            fieldName="org_group_levels"
            fieldLabel="Group Level"
          />
        </div>
      </div>
    </>
  )
}

export default AudienceOranizations;
