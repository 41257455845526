import propTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

function ProtectedRoute({ Component, path }) {
  const isLoggedIn = useSelector((state) => state.auth.login);

  return isLoggedIn ? (
    <Routes>
      {' '}
      <Route element={<Component />} path={path} />{' '}
    </Routes>
  ) : (
    <Routes>
      {' '}
      <Route element={<Navigate to="/" />} />
    </Routes>
  );
}

export default ProtectedRoute;
