import { useEffect, useState } from 'react';
import { Button, Col, Row, Typography, Upload, Modal, Steps } from 'antd';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { useTargetAudienceDispatch, useTargetAudienceState } from '../../context/targetAudience';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as XLSX from 'xlsx';
import { theme } from '../../../../config/theme/themeVariables';
import useTargetAudience from '../../hook/useTargetAudience';
import { faInbox } from '@fortawesome/pro-duotone-svg-icons';
import MapAudienceModal from './MapAudienceModal';
import { reducerTypes } from '../../context/targetAudience/constants';
import { useNotificationsDispatch } from '../../context/notifaction';
import { notificationsReducerTypes } from '../../context/notifaction/constants';

const { Title } = Typography;

function ImportAudience({ loading, setLoading, back }) {
  const {
    columns,
    models: { hasUnsavedData },
  } = useTargetAudienceState();
  const [currentView, setCurrentView] = useState('');
  const defaultColumns = columns?.map((i) => i.title);
  const groups = columns.filter((i) => i.typeKey === 'group');
  const locations = columns.filter((i) => i.typeKey === 'location');
  const userTheme = JSON.parse(localStorage.getItem('userTheme'));
  theme['user-theme'] = userTheme;
  const notificationDispatch = useNotificationsDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [audienceColumns, setAudienceColumns] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [defaultHeaderIndex, setDefaultHeaderIndex] = useState(0);
  const [defaultHeader, setDefaultHeader] = useState(0);
  const [disabledFields, setDisabledFields] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showing, setShowing] = useState([]);
  const [firstRowHeader, setFirstRowHeader] = useState([]);
  const [currentlyShowing, setCurrentlyShowing] = useState([]);
  const [defaultHeaderRow, setDefaultHeaderRow] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [fileData, setFileData] = useState(null);
  const [selectedValuesInd, setSelectedValuesInd] = useState();
  const { saveAudience } = useTargetAudience();
  const dispatch = useTargetAudienceDispatch();

  const handleFileUpload = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      // Assuming the first sheet is used for mapping
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];

      // Convert the worksheet to an array of objects
      const mappedData = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: true });

      function isValidDate(dateString) {
        // Try parsing the string as a Date object
        const date = new Date(dateString);
        // Check if the parsed date is a valid date and not NaN
        return !isNaN(date.getTime());
      }

      const date_time_format = localStorage.getItem('date_time_format');
      const dateFormatPattern = /^(DD|MM|YYYY)([-/\\])(DD|MM|YYYY)([-/\\])(DD|MM|YYYY)$/;
      const isValidFormat = date_time_format && dateFormatPattern.test(date_time_format);
      const formattedFormat = isValidFormat ? date_time_format : 'MM-DD-YYYY';
      for (let i = 0; i < mappedData.length; i++) {
        for (let j = 0; j < mappedData[i].length; j++) {
          if (isValidDate(mappedData[i][j])) {
            const temp = XLSX.SSF.format(formattedFormat, mappedData[i][j]);
            if (temp) {
              mappedData[i][j] = temp;
            } 
          }
          // const column = columns.find((col) => (col.title === mappedData[0][j] || (col.format === 'date' && isValidDate(mappedData[i][j]))));
          // if (isValidDate(mappedData[i][j]) && column?.format === 'date') {
          //   mappedData[i][j] = XLSX.SSF.format(formattedFormat, mappedData[i][j]);
          // }
        }
      }
      const allElementsExist = mappedData[0]?.every((element) => defaultColumns.includes(element));

      dispatch({
        type: reducerTypes.HAS_UNSAVED_DATA,
        payload: { data: Array.isArray(mappedData) && mappedData.length > 1 },
      });

      if (currentView === 'download' && !allElementsExist) {
        setShowErrorModal(true);
        return;
        1;
      } else {
        setDisabledFields(false);
      }
      setDefaultHeaderIndex(0);
      setDefaultHeader(mappedData[0]);
      setFirstRowHeader(mappedData[1]);

      if (mappedData[1] === undefined) {
        return;
      }

      let showingData = [];
      mappedData[0]?.map((dt) => showingData[false]);
      setShowing(showingData);
      setFileData(mappedData);
      setCurrentStep(2);
      setIsModalVisible(true);

      let currentValue = 0;
      setDefaultHeaderIndex(currentValue);
      setDefaultHeader(mappedData[parseInt(currentValue)]);
      setFirstRowHeader(mappedData[parseInt(currentValue) + 1]);
      let selectedDatas = [];
      let selectedDatavalues = [];
      let showDatavalues = [];
      let selectedIndex = [];
      audienceColumns?.map((item, o) => {
        let [val, ind] = getDefaultValue(item, mappedData[parseInt(currentValue)]);
        if (val.trim() === '') {
          showDatavalues.push('');
          selectedDatavalues.push(false);
          selectedIndex.push(-1);
        } else {
          showDatavalues?.push(mappedData[parseInt(currentValue) + 1][ind]);
          selectedDatavalues.push(true);
          selectedIndex?.push(0);
        }
        selectedDatas.push(val);
      });
      setCurrentlyShowing(showDatavalues);
      setSelectedValues(selectedDatas);
      setDefaultHeaderRow(true);
      setShowing(selectedDatavalues);
      setSelectedValuesInd(selectedIndex);
    };

    reader.readAsArrayBuffer(file);
  };

  function levenshteinDistance(a, b) {
    const matrix = Array.from({ length: a?.length + 1 }, () => new Array(b?.length + 1));

    for (let i = 0; i <= a?.length; i++) matrix[i][0] = i;
    for (let j = 0; j <= b?.length; j++) matrix[0][j] = j;

    for (let i = 1; i <= a?.length; i++) {
      for (let j = 1; j <= b?.length; j++) {
        const cost = a[i - 1] === b[j - 1] ? 0 : 1;
        matrix[i][j] = Math.min(
          matrix[i - 1][j] + 1, // Deletion
          matrix[i][j - 1] + 1, // Insertion
          matrix[i - 1][j - 1] + cost, // Substitution
        );
      }
    }
    return matrix[a?.length][b?.length];
  }

  function similarity(str1, str2) {
    const distance = levenshteinDistance(str1, str2);
    const length = Math.max(str1?.length, str2?.length);
    return ((length - distance) / length) * 100;
  }

  const getDefaultValue = (header, defaultHeaderData) => {
    let selectedValue = '';
    let found = false;
    let defaultIndex = -1;
    defaultHeaderData?.map((field, fieldIndex) => {
      if (similarity(header, field) >= 100) {
        found = true;
        selectedValue = field;
        defaultIndex = fieldIndex;
      }
    });
    if (!found) {
      defaultHeaderData?.map((field, fieldIndex) => {
        if (similarity(header, field) >= 80) {
          found = true;
          selectedValue = field;
          defaultIndex = fieldIndex;
        }
      });
    }

    if (selectedValue == '' && !found) {
      defaultHeaderData?.map((field, fieldIndex) => {
        if (similarity(header, field) >= 60) {
          selectedValue = field;
          defaultIndex = fieldIndex;
        }
      });
    }

    return [selectedValue, defaultIndex];
  };

  const groupsNames = groups?.map((i) => i.title);
  const locationNames = locations?.map((i) => i.title);

  const saveAudiencesData = async () => {
    let audienceData = [];
    for (let i in fileData) {
      if (parseInt(i) !== parseInt(defaultHeaderIndex)) {
        let current_data = fileData[i];
        let tmp = {};
        audienceColumns?.map((item, j) => {
          let selectedValue = selectedValues[j];
          let currentValue = '';
          for (let k in defaultHeader) {
            if (defaultHeader[k] === selectedValue) {
              currentValue = current_data[k];
              break;
            }
          }

          const columnKey = columns.find((i) => i.title === item).dataIndex;

          tmp[columnKey] = currentValue;
        });
        audienceData.push(tmp);
      }
    }
    setLoading(true);
    await saveAudience({
      groups: groupsNames,
      locations: locationNames,
      target_audiences: audienceData,
    });
    setLoading(false);
    notificationDispatch({
      type: notificationsReducerTypes.SUCCESS,
      payload: {
        message: 'Add Target Audience',
        description: 'Target Audience added successfully.',
      },
    });
    back();
  };

  const handleDownload = async () => {
    setCurrentView('download');
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Target Audience List');

      // Styling for header row
      const headerRow = worksheet.addRow(defaultColumns);
      headerRow.font = { bold: true, color: { argb: '000000' } };
      headerRow.alignment = { horizontal: 'center' };
      headerRow.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BDE3FF' } }; // Blue background color

      worksheet.getRow(1).height = 20;
      // Set column widths
      worksheet.columns.forEach((column) => {
        column.width = 40;
      });

      // Generate Excel file
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const fileName = 'Target Audiences.xlsx';
      saveAs(blob, fileName);
    } catch (error) {
      console.error('Error during download:', error);
    }
  };

  useEffect(() => {
    const columns = defaultColumns?.map((col) => {
      if (!col?.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col?.editable,
          dataIndex: col?.dataIndex,
          title: col?.title,
          handleSave,
        }),
      };
    });
    setAudienceColumns(columns);
  }, []);

  const closeModal = () => {
    if (hasUnsavedData.data) {
      setIsModalVisible(true);
      dispatch({
        type: reducerTypes.HAS_UNSAVED_DATA,
        payload: { show: true },
      });
    } else {
      setIsModalVisible(false);
    }
  };
  useEffect(() => {
    if (!hasUnsavedData.data && !hasUnsavedData.show) {
      setIsModalVisible(false);
    }
  }, [hasUnsavedData.data]);

  return (
    <>
      <Row gutter={[25, 25]}>
        <Col span={24}>
          <div className="title-input">
            <Title level={2}>Bulk upload</Title>
          </div>
          <div className="title-group-start" style={{ textAlign: 'center', paddingTop: '20px' }}>
            <Title level={3} style={{ marginBottom: '0px' }}>
              Use our template or map your own spreadsheet.
            </Title>
            <div className="audienceInputForm" style={{ paddingTop: '40px' }}>
              <Row style={{ margin: '40px 0px 60px 0px' }}>
                <Col xxl={12} xl={12} lg={12} md={12}>
                  <Button
                    className="btn-create"
                    htmlType="submit"
                    type={currentView === 'download' ? 'ocm-default-big' : 'default-big'}
                    size="large"
                    onClick={async () => {
                      handleDownload();
                    }}
                  >
                    Download our template
                  </Button>
                </Col>
                <Col xxl={12} xl={12} lg={12} md={12}>
                  <Upload
                    showUploadList={false}
                    beforeUpload={(file) => {
                      handleFileUpload(file);
                      return false; // Prevent default upload behavior
                    }}
                  >
                    <Button
                      className="btn-create"
                      htmlType="submit"
                      type={currentView === 'ownSheet' ? 'ocm-default-big' : 'default-big'}
                      size="large"
                      onClick={async () => {
                        setCurrentView('ownSheet');
                      }}
                    >
                      Map your own spreadsheet
                    </Button>
                  </Upload>
                </Col>
              </Row>
              {currentView === 'download' && (
                <>
                  <Row>
                    <Col
                      xxl={14}
                      xl={14}
                      md={14}
                      style={{
                        paddingTop: '45px',
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: '50px',
                      }}
                    >
                      <Steps
                        direction="vertical"
                        current={currentStep}
                        size=""
                        items={[
                          {
                            title: (
                              <Title level={4} type="secondary">
                                Save the downloaded template
                              </Title>
                            ),
                            description: <p style={{ height: 40 }}></p>,
                          },
                          {
                            title: <Title level={4}>Transfer your data to the template</Title>,
                            description: <p style={{ height: 40 }}></p>,
                          },
                          {
                            title: (
                              <Title level={5} type={currentStep === 2 ? 'success' : 'secondary'}>
                                Upload the completed template here:
                              </Title>
                            ),
                            description: <p style={{ height: 40 }}></p>,
                          },
                        ]}
                      />
                    </Col>
                    <Col xxl={10} xl={10} md={10}>
                      <div style={{ paddingTop: 170, cursor: 'pointer', width: 'fit-content', margin: 'auto' }}>
                        <Upload
                          name={'file'}
                          showUploadList={false}
                          multiple={false}
                          accept=".xls,.xlsx,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          beforeUpload={(file) => {
                            handleFileUpload(file);
                            return false;
                          }}
                        >
                          <FontAwesomeIcon color="gray" icon={faInbox} size="6x" />
                          <p>Upload</p>
                        </Upload>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </div>
        </Col>
      </Row>

      <MapAudienceModal
        open={isModalVisible}
        saveAudiencesData={saveAudiencesData}
        loading={loading}
        defaultHeaderRow={defaultHeaderRow}
        setDefaultHeaderRow={setDefaultHeaderRow}
        setDefaultHeader={setDefaultHeader}
        currentView={currentView}
        audienceColumns={audienceColumns}
        disabledFields={disabledFields}
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
        defaultHeader={defaultHeader}
        showing={showing}
        setShowing={setShowing}
        currentlyShowing={currentlyShowing}
        setCurrentlyShowing={setCurrentlyShowing}
        close={closeModal}
        setSelectedValuesInd={setSelectedValuesInd}
        selectedValuesInd={selectedValuesInd}
        setDefaultHeaderIndex={setDefaultHeaderIndex}
        setFirstRowHeader={setFirstRowHeader}
        firstRowHeader={firstRowHeader}
      />

      <Modal
        open={!!showErrorModal}
        title="Upload Error"
        width={'400px'}
        onCancel={() => setShowErrorModal(false)}
        okButtonProps={{
          style: { display: 'none' },
        }}
        cancelButtonProps={{
          style: { display: 'none' },
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <p>You are trying to upload a file that is not the OCMS Portal Excel Template.</p>
          <p>
            Kindly make sure you are uploading the OCMS Portal Excel Template or click "Map your own spreadsheet" to
            start the mapping process.
          </p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              htmlType="button"
              size="middle"
              shape="round"
              style={{ borderColor: '#0066b3' }}
              onClick={() => setShowErrorModal(false)}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        open={firstRowHeader === undefined || defaultHeader === undefined}
        title="Upload Error"
        width={'400px'}
        onCancel={() => {
          setFirstRowHeader([])
          setDefaultHeader([])
        }}
        okButtonProps={{
          style: { display: 'none' },
        }}
        cancelButtonProps={{
          style: { display: 'none' },
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <p>
            {!defaultHeader ? 'Your template contains no data. Kindly first make sure that your spreadsheet contains data then click upload' : 'Your template contains no data. Kindly transfer your data first to the OCMS Portal template then click upload.'}

          </p>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              htmlType="button"
              size="middle"
              shape="round"
              style={{ borderColor: '#0066b3' }}
              onClick={() => {
                setFirstRowHeader([])
                setDefaultHeader([])
              }}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ImportAudience;
