import { GanttDimensions, GanttUnitOfTimes } from '../enums';
import { GanttConsts } from './GanttConsts';

export const GanttDimensionsSettings = {
  [GanttDimensions.HOUR]: {
    value: 0,
    hours: 1,
    label: '1 hour',
    unitOfTime: GanttUnitOfTimes.DAY,
    itemsCount: 11,
    stepWidth: GanttConsts.SCALE_STEP_DEFAULT_WIDTH,
    scaleStepItems: GanttConsts.HOURS_IN_DAY / 1,
    secondsInPixel: (GanttConsts.SECONDS_IN_HOUR * 1) / GanttConsts.SCALE_STEP_DEFAULT_WIDTH,
  },
  // [GanttDimensions.TWO_HOURS]: {
  //   value: 1,
  //   hours: 2,
  //   label: '2 hours',
  //   unitOfTime: GanttUnitOfTimes.DAY,
  //   itemsCount: 11 * 2,
  //   stepWidth: GanttConsts.SCALE_STEP_DEFAULT_WIDTH * GanttConsts.SCALE_STEP_RATIO,
  //   scaleStepItems: GanttConsts.HOURS_IN_DAY / 2,
  //   secondsInPixel:
  //     (GanttConsts.SECONDS_IN_HOUR * 2) /
  //     (GanttConsts.SCALE_STEP_DEFAULT_WIDTH * GanttConsts.SCALE_STEP_RATIO),
  // },
  [GanttDimensions.DAY]: {
    value: 1,
    hours: 24,
    label: '1 Day',
    unitOfTime: GanttUnitOfTimes.MONTH,
    itemsCount: 11,
    stepWidth: GanttConsts.SCALE_STEP_DEFAULT_WIDTH,
    scaleStepItems: GanttConsts.HOURS_IN_DAY / 24,
    secondsInPixel: ((GanttConsts.SECONDS_IN_HOUR * 24) / GanttConsts.SCALE_STEP_DEFAULT_WIDTH),
  },
  [GanttDimensions.SEVENDAYS]: {
    value: 2,
    hours: 24,
    label: '1 Week',
    unitOfTime: GanttUnitOfTimes.WEEk,
    itemsCount: 50,
    stepWidth: GanttConsts.SCALE_STEP_DEFAULT_WIDTH/2,
    scaleStepItems: GanttConsts.HOURS_IN_DAY / 24/2,
    secondsInPixel: (GanttConsts.SECONDS_IN_HOUR * 24) / (GanttConsts.SCALE_STEP_DEFAULT_WIDTH/2),
  },
  
  [GanttDimensions.MONTH]: {
    value: 3,
    hours: 24,
    label: '1 Month',
    unitOfTime: GanttUnitOfTimes.MONTH,
    itemsCount: 50,
    stepWidth: GanttConsts.SCALE_STEP_DEFAULT_WIDTH/5,
    scaleStepItems: GanttConsts.HOURS_IN_DAY / 24 / 5,
    secondsInPixel: ((GanttConsts.SECONDS_IN_HOUR * 24) / (GanttConsts.SCALE_STEP_DEFAULT_WIDTH/5)),
  },
  
};
