import React from 'react';
import BarChart from '../../../components/charts/BarChart';
// Import utilities

function BarChartData({ data, yAxisTitle, backgroundColors }) {
  const chartData = {
    datasets: [
      // Light blue bars
      {
        data: data,
        // backgroundColor: 'blue',
        hoverBackgroundColor: '#01B0EF',
        // barPercentage: 0.66,
        barPercentage: 1,

         borderRadius: 5,
        //  backgroundColor: ['#003359', '#004D86', '#0066B2', '#38AAFF', '#7AC6FF', '#134265'],
        maxBarThickness: 24,
        datalabels: {
          align: 'start',
          anchor: 'end',
        },
        backgroundColor: backgroundColors || generateLighterColors('#003359', data?.length),
      },
      // Blue bars
    ],
  };

  return <BarChart yAxisTitle={yAxisTitle} data={chartData} />;
}

function generateLighterColors(baseColor, count) {
  // Convert hex to RGB
  let r = parseInt(baseColor.slice(1, 3), 16);
  let g = parseInt(baseColor.slice(3, 5), 16);
  let b = parseInt(baseColor.slice(5, 7), 16);

  const colors = [];

  // Generate lighter shades
  for (let i = 1; i <= count - 1; i++) {
    // Increase RGB values to make a lighter shade
    r = Math.min(255, r + Math.floor((255 - r) / (count - i + 1)));
    g = Math.min(255, g + Math.floor((255 - g) / (count - i + 1)));
    b = Math.min(255, b + Math.floor((255 - b) / (count - i + 1)));

    // Convert RGB back to hex
    const hexColor = '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    colors.push(hexColor);
  }

  return [baseColor, ...colors];
}

export default BarChartData;
