import React, { useRef, useEffect, useState } from 'react';

import {
  Chart,
  BarController,
  BarElement,
  LinearScale,
  TimeScale,
  Tooltip,
  Legend,
  LineController,
  PointElement,
  LineElement,
  Title,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Import the datalabels plugin

import 'chartjs-adapter-moment';
import { CategoryScale } from 'chart.js';
import 'chart.js/auto';
// Import utilities

Chart.register(
  LineElement,
  ChartDataLabels,
  PointElement,
  LineController,
  CategoryScale,
  BarController,
  BarElement,
  LinearScale,
  TimeScale,
  Tooltip,
  Legend,
);

function LineChartStacked({ yAxisTitle, data, width, height }) {
  const [chart, setChart] = useState(null);
  const canvas3 = useRef(null);

  useEffect(() => {
    const ctx = canvas3.current;

    const newChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: data?.labels,
        datasets: data?.dataset,
      },

      options: {
        transitions: {
          show: {
            animations: {
              x: {
                from: 0,
              },
              y: {
                from: 0,
              },
            },
          },
        },
        elements: {
          line: {
            tension: 5,
          },
        },
        scales: {
          x: {
            stacked: true,

            border: {
              display: true,
            },
            ticks: { color: 'black',beginAtZero:true },
            grid: {
              display: true,
              lineWidth: 3,
              color: 'white',
              // z: 1,
            },
            gridLines: {
              //    drawBorder: true,
            },
          },
          y: {
            stacked: true,
            title: {
              display: yAxisTitle ? true : false,
              text: yAxisTitle ?? '',
              color: '#7F7F7F',
            },
            min:0,
            border: {
              display: false,
            },
            ticks: { color: '#7F7F7F', brginAtZero: true, padding: 10, stepSize: 1 },
            grid: {
              color: '#D9D9D9',

              drawTicks: true,
            },
            gridLines: {
              drawBorder: false,
            },
          },
        },
        autoPadding: true,
        layout: {
          padding: {
            top: 30,
            bottom: 20,
            left: 10,
            right: 10,
          },
        },

        animation: {
          duration: 500,
        },
        //  responsive: true,
        maintainAspectRatio: false,
        resizeDelay: 200,

        plugins: {
          outlabels: false,
          datalabels: {
            anchor: 'start',
            align: 'middle',
            color: 'black',
            // backgroundColor: 'white',
            // borderColor: 'white',
            // padding: { left: 10, right: 10, top: 5, bottom: 5 },
            //  padding: 23,
            font: {
              weight: 'bold',
            },
            formatter: (value) => {
              return value > 0 ? value : '';
            },
          },
          tooltip: {
            enabled: false,
          },
          legend: { display: true, position: 'bottom', labels: { pointStyle: 'dash' } },
        },
      },
    });
    setChart(newChart);
    return () => newChart.destroy();
  }, [data]);

  return (
    <React.Fragment>
      <canvas ref={canvas3}></canvas>
    </React.Fragment>
  );
}

export default LineChartStacked;
