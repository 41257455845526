import React, { useReducer, useState, useCallback, useEffect } from 'react';
import { Button, Typography, Alert, Modal } from 'antd';
const { Title, Text } = Typography;
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch } from 'react-redux';
import { restartCustomerSubscriptionAgain, resumeCustomerSubscription, stopSubscriptionCancellation } from '../../redux/authentication/actionCreator';
import useNotificationController from '../../utility/useNotificationController';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { OCMModal, OCMModalHeader } from '../../container/tools/Style';
import moment from 'moment';
import { DataService } from '../../config/dataService/dataService';

const ResumeSubscription = ({createProject}) => {
  const {
    contextHolder,
    openError,
    openSuccess
  } = useNotificationController();

  const [subscriptionInfo, setSubscriptionInfo] = useState({
    loaded: false,
    subscription: {},
    subscribeId: 0,
    customerId: 0
  });

  const pauseForMonth = parseInt(localStorage.getItem('pauseForMonth'));
  const pause_resume_date = moment(localStorage.getItem('pause_resume_date'));
  
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [subscriptionModalButtonText, setSubscriptionModalButtonText] = useState({
    buttonText: "Resume Subscription",
    submittingFrom: false,
    isDisabled: false
  });

  const initialState = {
      error: false,
      messages: ""
  };

  const reducer = (state, action) => {
      switch (action.type) {
        case 'RESTART_CUSTOMER_SUBSCRIPTION_AGAIN_ERR':
          return { ...state, error: true,messages: action.err };
        case 'RESET':
          return state;
        default:
          return state;
      }
  };

  const [apiError, apiResponseCallback] = useReducer(reducer, initialState);

  const dispatch = useDispatch();
  const organizationalSlug = localStorage.getItem('organizationalSlug');
  useEffect(() => {
    const fetchData = async () => {
      const response = await DataService.get("/defaultData/get-subscription-info");
      if (response.status === 200)
      {
        const userResponse = response.data;
        
        setSubscriptionInfo({
          loaded: true,
          subscription: userResponse,
          subscribeId: userResponse.subscriptionId,
          customerId: userResponse.customerId
        });
        return {
          response,
        };
      }
      else
      {
        openError("topLeft", titlePageText, response.data.message);
        if (response.data.logout)
        {
          dispatch(logOut(() => navigate('/')));
        }
      }
    };
    fetchData();
  }, []);

  const resumeCurrentUserSubscription = useCallback(async (allFormValues) => {
    apiResponseCallback({ type: 'RESET' });
    dispatch(
      resumeCustomerSubscription({allFormValues: allFormValues}, (response) => {          
        if (response.status === 200) 
        {
          const responseData = response.data.userInfo;
          openSuccess('bottomRight', 'Resume Subscription',  (
            <>
            <Title level={5}>Your subscription has been resumed successfully</Title>
            <Text type={'secondary'}>Window will be reloaded in 5 seconds</Text>
            </>
          ));
          localStorage.setItem('pauseForMonth', responseData.pauseForMonth);
          localStorage.setItem('is_paused', responseData.isPaused);
          localStorage.setItem('pause_start_date', responseData.pauseStartDate);
          localStorage.setItem('pause_resume_date', responseData.pauseResumeDate);
          localStorage.setItem('pause_request_date', responseData.pauseRequestDate);

          setTimeout(() => {
            setSubscriptionModalButtonText({
              buttonText: "Resume Subscription",
              submittingFrom: false,
              isDisabled: false
            });
            window.location.href = `/${organizationalSlug}/subscription/update-subscription`;
          }, 5000)
        } 
        else 
        {
          setSubscriptionModalButtonText({
            buttonText: "Resume Subscription",
            submittingFrom: false,
            isDisabled: false
          });
          if (response.data === undefined)
          {
            if (response.message !== undefined)
            {
              openError("topLeft", "Resume Subscription", response.message)
              apiResponseCallback(response);
            }
            else
            {
              openError("topLeft", "Resume Subscription", response.err)
              apiResponseCallback(response);
            }
          }
          else if (response.data !== undefined)
          {
            openError("topLeft", "Resume Subscription", response.data.message)
            apiResponseCallback(response);
          }
          else
          {
            openError("topLeft", "Resume Subscription", response.err)
            apiResponseCallback(response);
          }
        }
      }),
    );
}, [dispatch]);

const resumeCurrentSubscription = async () => {
  setSubscriptionModalButtonText({
    buttonText: "Resuming subscription, please wait....",
    submittingFrom: true,
    isDisabled: true
  });
  const allFormValues = {};
  allFormValues.subscriptionId = subscriptionInfo.subscribeId;
  allFormValues.customerId = subscriptionInfo.customerId;
  await resumeCurrentUserSubscription(allFormValues);
}

  const stopCancellation = (val) => {
    setIsModalVisible(val);
  };

  return (
    <>
      {contextHolder}
      <Alert 
        message={
          <p style={{
            fontSize: "24px",
            marginBottom: 0,
            color: "#AE0002"
          }}>
            This account has been placed on pause {pauseForMonth === -1 ? 'indefinitely' : `for ${pauseForMonth}`}{pauseForMonth === -1 ? '' : ' month'}{parseInt(pauseForMonth) > 1 ? 's ' : pauseForMonth === -1 ? '' : ' '}{pauseForMonth === -1 ? '' : '('}{pauseForMonth === -1 ? '' : pause_resume_date.format("MMM DD, YYYY")}{pauseForMonth === -1 ? '' : ')'}. Click here to:
            <a 
              href={"#"} 
              onClick={(e) => stopCancellation(true)}
              style={{paddingLeft: "10px", textDecoration: "underline"}}
            >Resume Subscription</a>
          </p>
        }
        icon={
          <FontAwesomeIcon size='3x' icon={duotone('circle-exclamation')} style={{"--fa-primary-color": "#ffc000", "--fa-secondary-color": "#ffc000",}} />
        }
        type="error" 
        showIcon 
        style={{
          marginLeft: "20px",
          marginTop: "1px"
        }}
    />
    <OCMModal>
      {isModalVisible && (
        <Modal
          style={{
            margin: '20px 0px',
          }}
          className="add-change"
          title={
            <OCMModalHeader>
              <div className="user-info-header-panel">
                <Title level={1} className="header-title">Resume Subscription</Title>
                <div className="ribon none"></div>
              </div>
            </OCMModalHeader>
          }
          centered
          closeIcon={<FontAwesomeIcon size="2xl" icon={duotone('circle-xmark')} />}
          open={isModalVisible}
          onCancel={() => {
            stopCancellation(false);
          }}
          onOk={() => {
            stopCancellation(false);
          }}
          width={800}
          wrapClassName={'ocm-modal'}
          footer={[
            <Button 
              disabled={subscriptionModalButtonText.isDisabled}
              loading={subscriptionModalButtonText.submittingFrom}
              key="delete" 
              type="ocm-default-stop-cancellation" 
              size="large"
              style={{
                fontSize: '20px', // Increase font size
                padding: '5px 46px 5px 46px', // Increase padding
                lineHeight: '50px', // Center the text vertically
                borderRadius: "15px",
                height: "auto",
              }}
              onClick={async (e) => {
                await resumeCurrentSubscription();
              }}
            >
              {subscriptionModalButtonText.buttonText}
            </Button>
          ]}
        >
          <div style={{padding: "10px 30px", marginBottom: "30px"}}>
            <p style={{fontSize: "24px", lineHeight: "32px"}}>
              Click below to confirm and resume your subscription.
            </p>
          </div>
        </Modal>
      )}
    </OCMModal>
  </>
  );
};

export default ResumeSubscription;
