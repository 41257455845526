
import {createContext, useContext, useReducer } from 'react';
import { targetAudienceReducer } from './reducer'; 
import { targetAudienceInitialState } from './constants'

export const TargetAudienceState = createContext(targetAudienceInitialState);
export const TargetAudienceDispatch = createContext();

export const useTargetAudienceState = () => useContext(TargetAudienceState);
export const useTargetAudienceDispatch = () => useContext(TargetAudienceDispatch);


const TargetAudienceProvider = ({ children }) => {
  const [state, dispatch] = useReducer(targetAudienceReducer, targetAudienceInitialState, () => {
    return targetAudienceInitialState;
  });

  return (
    <TargetAudienceDispatch.Provider value={dispatch}>
      <TargetAudienceState.Provider value={state}>{children}</TargetAudienceState.Provider>
    </TargetAudienceDispatch.Provider>
  );
};

export default TargetAudienceProvider;
