import { useState } from 'react';
import TextEditor from '../../../tools/panel/widgets/TextEditor';
import { Divider, Form, Input, Spin } from 'antd';
import styled from 'styled-components';
import { DataService } from '../../../../config/dataService/dataService';
import { ActionContainer, PopupAction } from '../Popup/Style';
import MultiCascader from '../../../tools/panel/widgets/MultiCascader';
import { encode } from '../../utils/utils';
import useNotificationController from '../../../../utility/useNotificationController';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const getEmailTemplate = (surveyId, fullName, activeProjectName) => `
<p>
  <br></br>
</p>
<p style="text-align: center;">
  <span style="font-size: 14pt; border:1px solid #D1C9E8; padding:10px 25px; border-radius: 8px">
    <strong>Greetings from ${fullName}</strong>
  </span>
</p>
<p>&nbsp;</p>
<p>You have a new survey related to the ${activeProjectName} project. Please fill this out at your earliest convenience.</p>
<p>&nbsp;</p>
<p style="text-align: center;">
  <a id="noneEditableButton" style="background: #39AAFF; text-decoration: none; border-radius: 5px; padding: 8px 15px; border: 1px solid #000; color: #fff; font-size: 20px;" href="${window.location.protocol}//${window.location.host}/public/survey/${surveyId}">
    Begin Survey
  </a>
</p>
<p style="text-align: left;">
  Thank you!
</p>
<br>
<br>
<p>
  <strong>Note:</strong> Please do not reply to this email. If you have questions, please reach out to ${fullName} directly.
</p>
<p>
  <hr style=" border-color: #000; ">
</p>
<p style="text-align: left;">
  <span style="font-size: 10px;">
    The survey owner creates this content. The data you collect will be sent to the survey owner. OCM Solutions to your customers is not responsible for the privacy or security practices, including those of the owner of this survey. See our privacy policy | Terms of Use
  </span>
</p>
`;

const SurveyEmailEditor = ({ surveyData, onClose }) => {
  const {
    contextHolder,
    openError,
    openSuccess,
  } = useNotificationController();
  
  const [form] = Form.useForm();
  const fullName = localStorage.getItem('full_name');
  const activeProjectName = localStorage.getItem('active_project_name');

  const [content, setContent] = useState(getEmailTemplate(encode(surveyData?.id), fullName, activeProjectName));
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleEditorChange = (newContent) => {
    const img = `alt="Image"`;

    const button = `<a id="noneEditableButton"`;

    if (!newContent.includes(img)) {
      setSelectedFile(null);
    }
    if (newContent.includes(button)) {
      setContent(newContent);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setSelectedFile(file);
      const imageTag = `<p><img className="logo" style="max-width: 100%; max-height: 200px; display: block; margin-left: auto; margin-right: auto;"
          src="${URL.createObjectURL(file)}"
          alt="Image"
          style="max-width: 100%; max-height: 200px;"
        />&nbsp;</p>`;

      setContent((pre) => imageTag + pre);
    }
  };

  const sendSurvey = async (data) => {
    setLoading(true);
    const formData = new FormData();

    const emailData = {
      userSurveyId: surveyData.id,
      to: JSON.stringify(data.recepeints),
      subject: data.subject,
      text: 'This is email text',
      html: content,
      sendMe: data.sendMe,
    };

    Object.entries(emailData).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => formData.append(key, item));
      } else {
        formData.append(key, value);
      }
    });

    formData.append('image', selectedFile);

    try {
      const response = await DataService.post('surveys/send-survey-email', formData, {
        'Content-Type': 'multipart/form-data',
      });

      if (response.status === 200) {
        openSuccess('bottomRight', "Email",  `Email is sent successfully!`);
        onClose();
      } else {
        openError('topLeft', 'Email Error', response?.data?.message || 'Something went wrong');
      }
    } catch (err) {
      openError('topLeft', 'Email Error', 'Something went wrong');
    } finally {
      setLoading(false);
    }
  };


  return (
    <Container selectedFile={selectedFile}>
      {contextHolder}
      <Divider />
      <h2>Email</h2>
      <h4>Select recipients from your target audiences</h4>
      <Form scrollToFirstError layout="vertical" form={form} onFinish={sendSurvey}>
        <Form.Item
          label="To"
          name="recepeints"
          rules={[{ required: true, message: 'Select recepients from the list' }]}
        >
          <MultiCascader onSet={(val) => form.setFieldsValue({ recepeints: val })} />
        </Form.Item>

        <Form.Item
          label="Subject"
          name="subject"
          initialValue={`You have a new survey from ${fullName}`}
          rules={[{ required: true, message: 'Subject is required' }]}
        >
          <Input />
        </Form.Item>

        <EditorWarpper>
          <span>Message</span>
          {selectedFile === null && (
            <FileInput htmlFor="fileInput">
              <input type="file" id="fileInput" onChange={handleFileChange} />
              <br></br>
              Upload Image
            </FileInput>
          )}

          <TextEditor
            toolbar={
              'undo bold italic underline strikethrough | fontfamily fontsize blocks forecolor| alignleft aligncenter alignright alignjustify noneditable'
            }
            height={800}
            toolbar_location="top"
            value={content}
            onEditorChange={handleEditorChange}
          />
        </EditorWarpper>

        <CheckBoxContainer>
          <Form.Item name="sendMe" initialValue={false} noStyle>
            <Checkbox type="checkbox" onChange={(e) => form.setFieldsValue({ sendMe: e.target.checked })} />{' '}
            <span>Send me a copy of this email</span>
          </Form.Item>
        </CheckBoxContainer>

        <ActionContainer>
          <PopupAction variant="disable" disabled={loading} onClick={onClose}>
            {loading ? <Spin indicator={<FontAwesomeIcon icon={regular("circle-notch")} spin style={{ color: 'white' }} />} /> : 'Cancel'}
          </PopupAction>
          <PopupAction disabled={loading} type="submit">
            {loading ? <Spin indicator={<FontAwesomeIcon icon={regular("circle-notch")} spin style={{ color: 'white' }} />} /> : 'Send'}
          </PopupAction>
        </ActionContainer>
      </Form>
    </Container>
  );
};
export default SurveyEmailEditor;

const Container = styled.div`
  width: 100%;

  .tox-edit-area {
    padding-top: ${({ selectedFile }) => (selectedFile ? '0px' : '130px')};
  }
`;

const EditorWarpper = styled.div`
  position: relative;
`;
const FileInput = styled.label`
  position: absolute;
  top: 100px;
  left: 35%;
  width: 200px;
  height: 100px;
  border-radius: 10px;
  border: 1px solid rgb(38, 110, 181);
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  user-select: none;
  cursor: pointer;
  text-shadow: rgb(255, 255, 255) 1px 1px;
  font-weight: 700;
  font-size: 10pt;
  background: url('https://www.lifewire.com/thmb/TRGYpWa4KzxUt1Fkgr3FqjOd6VQ=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/cloud-upload-a30f385a928e44e199a62210d578375a.jpg');
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  overflow: hidden;

  input {
    height: 100%;
    z-index: 1111;
    width: 100%;
    position: absolute;
    opacity: 0;
  }

  &:hover {
    border-color: black;
  }

  &:active {
    background: linear-gradient(to bottom, #e3e3e3, #f9f9f9);
  }

  i {
    font-family: 'Font Awesome 5 Free';
    font-size: 24px;
    margin-right: 5px;
  }
`;

const CheckBoxContainer = styled.div`
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

const Checkbox = styled.input`
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--form-background);
  margin: 0;
  font: inherit;
  width: 30px;
  height: 30px;
  border: 0.15em solid #5dd3ff;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  border-radius: 5px;
  margin-right: 10px;

  ${
    '' /* &:focus {
    outline: max(2px, 0.15em) solid currentColor;
    outline-offset: max(2px, 0.15em);
  } */
  }

  &:checked::before {
    transform: scale(1);
  }

  &:disabled {
    color: var(--form-control-disabled);
    cursor: not-allowed;
  }

  &:before {
    content: '';
    width: 20px;
    height: 20px;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    background-color: CanvasText;
  }
`;
