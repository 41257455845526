import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spin } from 'antd';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

const SurveyAction = ({ title, icon, onClick, color, loading, type="button" }) => {
  return (
    <Container>
      <Button type={type} color={color} onClick={onClick} disabled={loading}>
        {loading ? <Spin indicator={<FontAwesomeIcon icon={regular("circle-notch")} spin size="2xl" />} /> : <FontAwesomeIcon icon={icon} size="xl" />}
      </Button>
      <span onClick={onClick}>{title}</span>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-transform: capitalize;
  span{
    cursor: pointer;
  }
`;
const Button = styled.button`
  border: 1px solid #a9adbd;
  border-radius: 100px;
  padding: 3px 10px;
  background-color: #fff;
  color: ${({ color }) => (color ? color : '#39ABFF')};
  cursor: pointer;
`;

export default SurveyAction;