import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay } from "@fortawesome/pro-duotone-svg-icons";
import { CMSImage, CMSItemWrapper, CMSTitle, Separator, StyledLink } from './LibrarySection.styles';

const CMSItem = ({ content, category }) => {
    const slug = localStorage.getItem('organizationalSlug')
    return (
      <StyledLink to={`/${slug}/content/${content.slug}`}>
        <CMSItemWrapper>
          {category.id === 6 ? (
            <div style={{width: "100px", textAlign: "center", border: "1px solid #eee", padding: "10px", borderRadius: "15px"}}>
              <FontAwesomeIcon className={'fontPlay'} icon={faCirclePlay} style={{"--fa-primary-color": `#ffffff`, "--fa-secondary-color": `#0066B2`, fontSize: "45px"}} />
            </div>
          ) : (
            content.categories!== undefined && content.categories.includes(6) ? (
              <div style={{width: "100px", textAlign: "center", border: "1px solid #eee", padding: "10px", borderRadius: "15px"}}>
                <FontAwesomeIcon className={'fontPlay'} icon={faCirclePlay} style={{"--fa-primary-color": `#ffffff`, "--fa-secondary-color": `#0066B2`, fontSize: "45px"}} />
              </div>
            ) : (
              <CMSImage loading="lazy" src={`${process.env.REACT_APP_API_ENDPOINT}/file/cms/post_image/${content.thumbnailImage}`} alt={content.title} />
            )
          )}
          <Separator />
          <CMSTitle>{content.title}</CMSTitle>
        </CMSItemWrapper>
      </StyledLink>
    );
}

export default CMSItem;