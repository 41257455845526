import Styled from 'styled-components';

const AuthenticationWrap = Styled.div`
  background-position: top;
  background-repeat: no-repeat;
`;

const Content = Styled.div`
    padding: 100px;
    @media only screen and (max-width: 1599px){
      padding: 50px;
    }
    @media only screen and (max-width: 991px){
      padding: 20px;
    }
    @media only screen and (max-width: 767px){
      text-align: center;
    }
    .auth-content-figure{
      @media only screen and (max-width: 1199px){
        max-width: 420px;
      }
      @media only screen and (max-width: 991px){
        max-width: 100%;
      }
    }
`;

const AuthFormWrap = Styled.div`
  border-radius: 6px;
  @media only screen and (max-width: 1599px){
  }
  box-shadow: 0 5px 20px rgba(140,144,164,.08);
  background-color: ${({ theme }) => theme['lightMode']['white-background']};
  .ocmsolution-authentication-panel{
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      margin-top: 15%;
  }
  .ocmsolution-authentication-top{
    padding: 20px;
    text-align: center;
    border-bottom: 1px solid ${({ theme }) => theme['lightMode']['border-color-secondary']};
    .ocmsolution-authentication-top__title{
      font-size: 20px;
      font-weight: 600;
      line-height: 1;
      margin-bottom: 0;
      color: ${({ theme }) => theme['lightMode']['dark-text']};
    }
  }
  .ocmsolution-info-panel {
    background-color: #0050b9;
    box-shadow: 15px 2px 11px 1px rgba(135, 135, 135, 0.51);
    -webkit-box-shadow: 15px 2px 11px 1px rgba(135, 135, 135, 0.51);
    -moz-box-shadow: 15px 2px 11px 1px rgba(135, 135, 135, 0.51);
    background: radial-gradient(circle, #004195 0%, #0063E1 100%);
    min-height: 100vh;
  }
  .ocmsolution-logo-center-v {
      text-align: center;
      padding: 40px 0 100px 0;
  }
  .ocmsolution-logo-center-v img{
      width: 30%;
  }
  .font-checked-trial {
      position: absolute;
      right: 20px;
      top: 6px;
      font-weight: bold;
  }
  .loader {
    color: #fff;
    font-size: 32px;
    text-align: center;
    padding-top: 80px;
  }
  .ocmsolution-authentication-content{
    padding: 30px 40px;
    @media only screen and (max-width: 1599px){
      padding: 30px;
    }
    .ant-form-item-label {
      > label{
        font-size: 14px;
        color: ${({ theme }) => theme['lightMode']['dark-text']};
      }
    }
    .ant-form-item{
      margin-bottom: 25px;
    }
    .ant-input:focus, 
    .ant-input-focused{
      box-shadow: 0 5px 20px rgba(251,53,134,.10);
    }
    .ant-input{
      &::placeholder{
        color: ${({ theme }) => theme['lightMode']['extra-light-text']};
      }
    }
    .ant-form-item-explain-error{
      font-size: 13px;
      margin-top: 2px;
    }
    .ocmsolution-auth-extra-links{
      display: flex;
      justify-content: space-between;
      margin-top: -5px;
      .ant-checkbox-wrapper{
        span{
          font-size: 13px;
          color: ${({ theme }) => theme['lightMode']['light-text']};
        }
        .ant-checkbox{
          &+span{
            position: relative;
            top: -2px;
          }
        }
      }
      .forgot-pass-link{
        font-size: 13px;
        color: ${({ theme }) => theme['primary-color']};
      }
    }
    .btn-signin,
    .btn-reset,
    .btn-create{
      font-size: 14px;
      font-weight: 500;
      border-radius: 6px;
      width: 100%;
      min-height: 48px;
      margin-top: 25px;
      white-space: normal;
    }
    .btn-reset{
      margin-top: 0;
    }
    .ocmsolution-no-display{
      display: none;
    }
    .ocmsolution-form-divider{
      font-size: 13px;
      color: ${({ theme }) => theme['lightMode']['border-color-default']};
      text-align: center;
      position: relative;
      margin: 0 -10px 25px -10px;
      &:before{
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        height: 1px;
        background: ${({ theme }) => theme['lightMode']['border-color-secondary']};
      }
      span{
        font-weight: 500;
        padding: 0 15px;
        display: inline-block;
        position: relative;
        z-index: 2;
        background: ${({ theme }) => theme['lightMode']['white-background']};
        color: ${({ theme }) => theme['lightMode']['gray-text']};
      }
    }
    .ant-input-affix-wrapper {
      &.ant-input-password{
        input{
          color: ${({ theme }) => theme['lightMode']['dark-text']};
        }
      }
    }
    .ocmsolution-social-login{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: -6px;
      @media only screen and (max-width: 767px){
        justify-content: center;
      }
      &.signin-social{
        li{
          a{
            background-color: #fff;
          }
        }
      }
      li{
        padding:15px 6px;
        a{
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          height: 48px;
          padding: 0 15px;
          background: ${({ theme }) => theme['lightMode']['general-background']};
          color: ${({ theme }) => theme['lightMode']['gray-text']};
          font-weight: 500;
          transition: background-color 0.3s;
          @media only screen and (max-width: 1399px){
            padding: 0 12px;
          }
          @media only screen and (max-width: 379px){
            height: 44px;
          }
          span:not(.anticon){
            display: inline-block;
            margin-left: 5px;
          }
          svg,
          i{
            width: 20px;
            height: 20px;
          }
          &.google-social{
            background-color: #F0654810;
            color: #F06548;
            &:hover{
              background-color: #F06548;
              svg path{
                fill: #FFFFFF;
              }
            }
            svg path{
              fill: #F06548;
            }
            div{
              height: 20px;
            }
          }
          &.facebook-social{
            background-color: #3A589B10;
            color: #3A589B;
            &:hover{
              background-color: #3A589B;
              color: #FFFFFF;
            }
          }
          &.twitter-social{
            background-color: #03A9F410;
            color: #03A9F4;
            &:hover{
              background-color: #03A9F4;
              color: #FFFFFF;
            }
          }
          &.github-social{
            background-color: #03A9F410;
            color: #090E30;
            &:hover{
              background-color: #03A9F4;
              color: #FFFFFF;
            }
          }
        }
      }
    }
    .ant-input-affix-wrapper{
      padding: 12px 20px;
    }
  }
  .ocmsolution-authentication-bottom{
    text-align: center;
    padding: 25px;
    border-radius: 0 0 6px 6px;
    background-color: ${({ theme }) => theme['lightMode']['dark-background']};
    p{
      font-size: 14px;
      font-weight: 500;
      color: ${({ theme }) => theme['lightMode']['gray-text']};
      margin-bottom: 0;
      a{
        margin-left: 6px;
        ${({ theme }) => theme['primary-color']};
      }
    }
  }
  .auth-contents{
    display: flex;
    align-items: center;
    justify-content: center;
    form{
      width: 420px;
      h1{
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 45px;
        @media only screen and (max-width: 767px){
          margin-bottom: 28px;
        }
        input::placeholder{
          color: ${({ theme }) => theme['extra-light-color']};
        }
      }
      .auth-form-action{
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        @media only screen and (max-width: 379px){
          flex-flow: column;
          .forgot-pass-link{
            margin-top: 15px;
          }
        }
      }
    }
    #forgotPass{
      .forgot-text{
        margin-bottom: 25px;
      }
      .return-text{
        margin-top: 35px;
      }
    }
    
    
  } 
  .auth0-login{
    margin: -6px;
    display: flex;
    flex-wrap: wrap;
  a{
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      height: 48px;
      padding: 0 26px;
      background: ${({ theme }) => theme['bg-color-light']};
      color: ${({ theme }) => theme['text-color']};
      font-weight: 500;
      border: 0 none;
      border-radius: 5px;
      margin: 6px;
      flex: 1;
      @media (max-width:480px){
        flex: none;
        width: 100%;
      }
  }
}
`;

const LoginFormPanelWrap = Styled.div`
  background-color: ${({ theme }) => theme['lightMode']['white-background']};
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .ocmsolution-authentication-content{
    width: 60%;
    padding: 30px 40px;
    @media only screen and (max-width: 1599px){
      padding: 30px;
    }
    .ocm-input-login {
      padding: 16px 16px;
      font-size: 18px;
      line-height: 1.5;
      border-radius: 8px;
      background: #BDE3FF;
      color: #000;
      input {
        background: #BDE3FF;
        color: #000;
        padding-left: 0;
      }
    }
    .ant-btn-ocm-default-dark-blue {
        background: #0066B2 !important;
        color: #fff !important;
        border-color: transparent !important;
        font-weight: 600;
        font-size: 24px !important;
        height: auto;
        width: 50% !important;
        padding: 10px 20px !important;
        margin: 0 auto;
        border-radius: 45px !important;
        // margin-top: 40px !important;
        white-space: normal !important;
    }
    .forgot-pass-link {
        font-style: italic;
        font-size: 16px !important;
        color: #0AA2EA !important;
    }
    .ant-checkbox-wrapper {
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px !important;
        height: 100%;
      }
    }
    .ant-checkbox .ant-checkbox-inner {
      width: 38px;
      height: 35px;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      width: 38px;
      height: 35px;
      background-color: #37B7F4;
      border-color: #37B7F4;
    }
    .ant-checkbox:not(.ant-checkbox-indeterminate) .ant-checkbox-inner::after {
      width: 12.714px;
      height: 25.143px;
      position: absolute;
      top: 40%;
      border: 3px solid #fff;
      border-top: 0;
      border-inline-start: 0;
    }
    .ant-form-item-label {
      > label{
        font-size: 14px;
        color: ${({ theme }) => theme['lightMode']['dark-text']};
      }
    }
    .ant-form-item{
      margin-bottom: 25px;
    }
    .ant-input:focus, 
    .ant-input-focused{
      box-shadow: 0 5px 20px rgba(251,53,134,.10);
    }
    .ant-input{
      &::placeholder{
        color: ${({ theme }) => theme['lightMode']['extra-light-text']};
      }
    }
    .ant-form-item-explain-error{
      font-size: 13px;
      margin-top: 2px;
    }
    .ocmsolution-auth-extra-links{
      display: flex;
      justify-content: space-between;
      margin-top: -5px;
      .ant-checkbox-wrapper{
        span{
          font-size: 13px;
          color: ${({ theme }) => theme['lightMode']['light-text']};
        }
        .ant-checkbox{
          &+span{
            position: relative;
            top: -2px;
          }
        }
      }
      .forgot-pass-link{
        font-size: 13px;
        color: ${({ theme }) => theme['primary-color']};
      }
    }
    .btn-signin,
    .btn-reset,
    .btn-create{
      font-size: 14px;
      font-weight: 500;
      border-radius: 6px;
      width: 100%;
      min-height: 60px;
      margin-top: 25px;
      white-space: normal !important;
    }
    .btn-reset{
      margin-top: 0;
    }
    .ocmsolution-no-display{
      display: none;
    }
    .ocmsolution-form-divider{
      font-size: 16px;
      color: ${({ theme }) => theme['lightMode']['border-color-default']};
      text-align: center;
      position: relative;
      margin: 0 -10px 25px -10px;
      &:before{
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        height: 1px;
        background: ${({ theme }) => theme['lightMode']['border-color-secondary']};
      }
      span{
        font-weight: 500;
        padding: 0 15px;
        display: inline-block;
        position: relative;
        z-index: 2;
        background: ${({ theme }) => theme['lightMode']['white-background']};
        color: ${({ theme }) => theme['lightMode']['gray-text']};
      }
    }
    .ant-input-affix-wrapper {
      &.ant-input-password{
        input{
          color: ${({ theme }) => theme['lightMode']['dark-text']};
        }
      }
    }
    .ocmsolution-social-login{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: -6px;
      @media only screen and (max-width: 767px){
        justify-content: center;
      }
      &.signin-social{
        li{
          a{
            background-color: #fff;
          }
        }
      }
      li{
        padding:15px 6px;
        a{
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          height: 48px;
          padding: 0 15px;
          background: ${({ theme }) => theme['lightMode']['general-background']};
          color: ${({ theme }) => theme['lightMode']['gray-text']};
          font-weight: 500;
          transition: background-color 0.3s;
          @media only screen and (max-width: 1399px){
            padding: 0 12px;
          }
          @media only screen and (max-width: 379px){
            height: 44px;
          }
          span:not(.anticon){
            display: inline-block;
            margin-left: 5px;
          }
          svg,
          i{
            width: 20px;
            height: 20px;
          }
          &.google-social{
            background-color: #F0654810;
            color: #F06548;
            &:hover{
              background-color: #F06548;
              svg path{
                fill: #FFFFFF;
              }
            }
            svg path{
              fill: #F06548;
            }
            div{
              height: 20px;
            }
          }
          &.facebook-social{
            background-color: #3A589B10;
            color: #3A589B;
            &:hover{
              background-color: #3A589B;
              color: #FFFFFF;
            }
          }
          &.twitter-social{
            background-color: #03A9F410;
            color: #03A9F4;
            &:hover{
              background-color: #03A9F4;
              color: #FFFFFF;
            }
          }
          &.github-social{
            background-color: #03A9F410;
            color: #090E30;
            &:hover{
              background-color: #03A9F4;
              color: #FFFFFF;
            }
          }
        }
      }
    }
    .ant-input-affix-wrapper{
      padding: 12px 20px;
    }
  }


  .ocmsolution-authentication-top{
    padding: 20px;
    text-align: center;
    border-bottom: 1px solid ${({ theme }) => theme['lightMode']['border-color-secondary']};
    .ocmsolution-authentication-top__title{
      font-size: 20px;
      font-weight: 600;
      line-height: 1;
      margin-bottom: 0;
      color: ${({ theme }) => theme['lightMode']['dark-text']};
    }
  }
  .ocmsolution-info-panel {
    background-color: #0050b9;
    box-shadow: 15px 2px 11px 1px rgba(135, 135, 135, 0.51);
    -webkit-box-shadow: 15px 2px 11px 1px rgba(135, 135, 135, 0.51);
    -moz-box-shadow: 15px 2px 11px 1px rgba(135, 135, 135, 0.51);
    background: radial-gradient(circle, #004195 0%, #0063E1 100%);
    min-height: 100vh;
  }
  .ocmsolution-logo-center-v {
      text-align: center;
      padding: 40px 0 100px 0;
  }
  .ocmsolution-logo-center-v img{
      width: 30%;
  }
  .font-checked-trial {
      position: absolute;
      right: 20px;
      top: 6px;
      font-weight: bold;
  }
  .loader {
    color: #fff;
    font-size: 32px;
    text-align: center;
    padding-top: 80px;
  }
  
  .ocmsolution-authentication-bottom{
    text-align: center;
    padding: 25px;
    border-radius: 0 0 6px 6px;
    font-size: 20px;
    span {
      i {
        font-size: 20px;
        color: #0066B2;
      }
    }
    a {
      color: #0066B2;
    }
    p{
      font-size: 20px;
      font-weight: 500;
      color: ${({ theme }) => theme['lightMode']['gray-text']};
      margin-bottom: 0;
      a{
        margin-left: 6px;
        ${({ theme }) => theme['primary-color']};
      }
    }
  }
  .auth-contents{
    display: flex;
    align-items: center;
    justify-content: center;
    form{
      width: 420px;
      h1{
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 45px;
        @media only screen and (max-width: 767px){
          margin-bottom: 28px;
        }
        input::placeholder{
          color: ${({ theme }) => theme['extra-light-color']};
        }
      }
      .auth-form-action{
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        @media only screen and (max-width: 379px){
          flex-flow: column;
          .forgot-pass-link{
            margin-top: 15px;
          }
        }
      }
    }
    #forgotPass{
      .forgot-text{
        margin-bottom: 25px;
      }
      .return-text{
        margin-top: 35px;
      }
    }
    
    
  } 
  .auth0-login{
    margin: -6px;
    display: flex;
    flex-wrap: wrap;
  a{
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      height: 48px;
      padding: 0 26px;
      background: ${({ theme }) => theme['bg-color-light']};
      color: ${({ theme }) => theme['text-color']};
      font-weight: 500;
      border: 0 none;
      border-radius: 5px;
      margin: 6px;
      flex: 1;
      @media (max-width:480px){
        flex: none;
        width: 100%;
      }
  }
}
`;

const SignupFormWrap = Styled.div`
  border-radius: 6px;
  background: #ffffff;
  height: 100%;
  .ant-alert {
    margin: 0 !important;
  }
  @media only screen and (max-width: 1599px){
  }
  background-color: ${({ theme }) => theme['lightMode']['white-background']};
  .ocmsolution-authentication-panel{
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      margin-top: 15%;
  }
  .ocmsolution-registration-top{
    padding: 40px;
    text-align: left;
    border-bottom: 1px solid ${({ theme }) => theme['lightMode']['border-color-secondary']};
    .ocmsolution-registration-top__title{
      font-size: 25px;
      font-weight: 600;
      line-height: 1;
      margin-bottom: 0;
      color: ${({ theme }) => theme['lightMode']['dark-text']};
    }
  }
  .ocmsolution-auth-extra-links {
    width: 80%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    line-height: 1.9;
    span {
      line-height: 31px;
      font-size: 16px !important;
    }
  }
  .ocmsolution-info-panel {
    min-height: 100vh;
  }
  .ocmsolution-logo-center-v {
      text-align: center;
      padding: 40px 0 100px 0;
  }
  .ocmsolution-logo-center-v img{
      width: 30%;
  }
  .font-checked-trial {
      position: absolute;
      right: 20px;
      top: 6px;
      font-weight: bold;
  }
  .loader {
    color: rgb(0, 176, 240);
    font-size: 32px;
    text-align: center;
    padding-top: 80px;
  }
  .ocmsolution-registration-content{
    padding: 30px 40px;
    @media only screen and (max-width: 1599px){
      padding: 30px;
    }
    .ant-form-item-label {
      > label{
        font-size: 14px;
        color: ${({ theme }) => theme['lightMode']['dark-text']};
      }
    }
    .ant-form-item{
      margin-bottom: 30px;
    }
    .ant-input:focus, 
    .ant-input-focused{
      box-shadow: 0 5px 20px rgba(251,53,134,.10);
    }
    .ant-input{
      &::placeholder{
        color: ${({ theme }) => theme['lightMode']['extra-light-text']};
      }
    }
    .ant-form-item-explain-error{
      font-size: 13px;
      margin-top: 2px;
    }
    .ocmsolution-auth-extra-links{
      display: flex;
      justify-content: space-between;
      margin-top: -5px;
      .ant-checkbox-wrapper{
        span{
          font-size: 13px;
          color: ${({ theme }) => theme['lightMode']['light-text']};
        }
        .ant-checkbox{
          &+span{
            position: relative;
            top: -2px;
          }
        }
      }
      .forgot-pass-link{
        font-size: 13px;
        color: ${({ theme }) => theme['primary-color']};
      }
    }
    .btn-signin,
    .btn-reset{
      font-size: 14px;
      font-weight: 500;
      border-radius: 6px;
      width: 100%;
      min-height: 48px;
      margin-top: 25px;
      white-space: normal !important;
    }
    .btn-create{
      font-size: 16px;
      font-weight: 500;
      border-radius: 18px;
      width: 20%;
      min-height: 48px;
      margin-top: 25px;
      color: #000;
      white-space: normal !important;
    }
    .btn-reset{
      margin-top: 0;
    }
    .ocmsolution-no-display{
      display: none;
    }
    .ocmsolution-form-divider{
      font-size: 13px;
      color: ${({ theme }) => theme['lightMode']['border-color-default']};
      text-align: center;
      position: relative;
      margin: 0 -10px 25px -10px;
      &:before{
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        height: 1px;
        background: ${({ theme }) => theme['lightMode']['border-color-secondary']};
      }
      span{
        font-weight: 500;
        padding: 0 15px;
        display: inline-block;
        position: relative;
        z-index: 2;
        background: ${({ theme }) => theme['lightMode']['white-background']};
        color: ${({ theme }) => theme['lightMode']['gray-text']};
      }
    }
    .ant-input-affix-wrapper {
      &.ant-input-password{
        input{
          color: ${({ theme }) => theme['lightMode']['dark-text']};
        }
      }
    }
    .ocmsolution-social-login{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: -6px;
      @media only screen and (max-width: 767px){
        justify-content: center;
      }
      &.signin-social{
        li{
          a{
            background-color: #fff;
          }
        }
      }
      li{
        padding:15px 6px;
        a{
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          height: 48px;
          padding: 0 15px;
          background: ${({ theme }) => theme['lightMode']['general-background']};
          color: ${({ theme }) => theme['lightMode']['gray-text']};
          font-weight: 500;
          transition: background-color 0.3s;
          @media only screen and (max-width: 1399px){
            padding: 0 12px;
          }
          @media only screen and (max-width: 379px){
            height: 44px;
          }
          span:not(.anticon){
            display: inline-block;
            margin-left: 5px;
          }
          svg,
          i{
            width: 20px;
            height: 20px;
          }
          &.google-social{
            background-color: #F0654810;
            color: #F06548;
            &:hover{
              background-color: #F06548;
              svg path{
                fill: #FFFFFF;
              }
            }
            svg path{
              fill: #F06548;
            }
            div{
              height: 20px;
            }
          }
          &.facebook-social{
            background-color: #3A589B10;
            color: #3A589B;
            &:hover{
              background-color: #3A589B;
              color: #FFFFFF;
            }
          }
          &.twitter-social{
            background-color: #03A9F410;
            color: #03A9F4;
            &:hover{
              background-color: #03A9F4;
              color: #FFFFFF;
            }
          }
          &.github-social{
            background-color: #03A9F410;
            color: #090E30;
            &:hover{
              background-color: #03A9F4;
              color: #FFFFFF;
            }
          }
        }
      }
    }
    .ant-input-affix-wrapper{
      padding: 12px 20px;
    }
  }
  .g-recaptcha div {
    margin: 0 auto;
  }
  .ocmsolution-authentication-bottom{
    text-align: center;
    padding: 25px;
    p{
      font-size: 14px;
      font-weight: 500;
      color: ${({ theme }) => theme['lightMode']['gray-text']};
      margin-bottom: 0;
      a{
        margin-left: 6px;
        ${({ theme }) => theme['primary-color']};
      }
    }
  }
  .ocmsolution-google-recaptcha{
    text-align: center;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .auth-contents{
    display: flex;
    align-items: center;
    justify-content: center;
    form{
      width: 420px;
      h1{
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 45px;
        @media only screen and (max-width: 767px){
          margin-bottom: 28px;
        }
        input::placeholder{
          color: ${({ theme }) => theme['extra-light-color']};
        }
      }
      .auth-form-action{
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        @media only screen and (max-width: 379px){
          flex-flow: column;
          .forgot-pass-link{
            margin-top: 15px;
          }
        }
      }
    }
    #forgotPass{
      .forgot-text{
        margin-bottom: 25px;
      }
      .return-text{
        margin-top: 35px;
      }
    }
    
    
  } 
  .auth0-login{
    margin: -6px;
    display: flex;
    flex-wrap: wrap;
  a{
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      height: 48px;
      padding: 0 26px;
      background: ${({ theme }) => theme['bg-color-light']};
      color: ${({ theme }) => theme['text-color']};
      font-weight: 500;
      border: 0 none;
      border-radius: 5px;
      margin: 6px;
      flex: 1;
      @media (max-width:480px){
        flex: none;
        width: 100%;
      }
  }
}
`;

const LoginFormWrap = Styled.div`
  margin: 0;
  padding: 0;
  background-image: url('/blue_sky.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh; /* This will make the background cover the entire viewport height */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1.ant-typography {
    color: #fff;
    font-size: 65px;
    margin-top: -55px;
  }
  h2.ant-typography {
    color: #fff;
    font-size: 35px;
  }
  h3.ant-typography {
    color: #fff;
    font-size: 35px;
    margin-top: 0px;
  }
  hr {
    border: 1px solid #ccc;
    width: 100%;
  }
`;

const SignupWrapStyle = Styled.div`
  margin: 0;
  padding: 0;
  background-image: url('/blue_sky.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: start;
  padding-top: 40px;
  flex-direction: column;
  .optional-addon {
    margin: 50px 0 0;
    width: 100%;
    border: 1px solid rgb(75, 166, 234);
    display: flex;
    align-items: center;
    justify-content: center;
    background: #BDE3FF;
    height: 80px;
    border-radius: 12px;
    h3.optional-addon-h3 {
      color: #333 !important;
      margin-bottom: 0px;
    }
  }
  .add-ons-checkout {
    background: #E4F7FF;
    border: 1px solid #ccc;
    width: 100%;
    border-radius: 25px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 40px;
    margin-bottom: 40px;
    .recurring-amount {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      width: 100%;
      padding: 15px 20px;
      .product-name {
        width: 50%;
        display: flex;
        justify-content: flex-start;
      }
      .product-price {
        width: 50%;
        display: flex;
        justify-content: end;
        padding-right: 40px;
      }
    }
    h4.black-text {
      color: #333 !important;    
      font-size: 24px;
    }
    .amount-area {
      text-align: left;
      width: 200px;
    }
    h2.checkout-text {
      color: #333 !important;
    }
  }
  .add-ons-optional-fields {
    background: #E4F7FF;
    border: 1px solid #ccc;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 40px;
    border-radius: 25px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    .ant-form-item-control-input-content {
      display: flex;
    }
    .ocm-discount-code {
        width: 70%;
        height: 60px;
        margin-right: 10px;
    }
    .ant-btn-ocm-default-apply {
      margin-left: 20px;
      width: 20%;
      background: #00B0F0 !important;
      color: rgb(255, 255, 255) !important;
      border-color: transparent !important;
      font-weight: 600;
      font-size: 24px !important;
      height: auto;
      padding: 10px 20px !important;
      border-radius: 15px !important;
      white-space: normal !important;
    }
    .ant-btn-ocm-default-apply-disabled {
      margin-left: 20px;
      width: 20%;
      background: #00B0F055 !important;
      color: rgb(255, 255, 255) !important;
      border-color: transparent !important;
      font-weight: 600;
      font-size: 24px !important;
      height: auto;
      padding: 10px 20px !important;
      border-radius: 15px !important;
      white-space: normal !important;
    }
    .input-boxes-border {
      border-right: 2px solid #A99BD5;
    }
    .ocm-btn-licenses-disabled {
      background: #BDE3FF;
      color: #000;
      width: 35px;
      line-height: 0;
      padding-top: 14px;
      padding-bottom: 17px;
      font-weight: bold;
      font-size: 23px;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: normal !important;
    }
    .ocm-btn-licenses {
      background: #46B2F9;
      color: #FFF;
      width: 35px;
      line-height: 0;
      padding-top: 14px;
      padding-bottom: 17px;
      font-weight: bold;
      font-size: 23px;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: normal !important;
    }
    .ocm-input-licenses {
      width: 35px;
      height: 34px;
      border: 1px solid #46B2F9;
      font-size: 18px;
      line-height: 1.5;
      color: #000;
      input {
        color: #000;
        padding-left: 0;
      }
    }
    .input-boxes {
      width: 50%;
      display: flex;
      .custom_plan_no_style {
          width: 100%;
          li:not(:last-child) {
            .optional-addons-item {
              border-bottom: 1px solid #ccc;
            }
          }
          li {
            .optional-addons-item {
              width: 100%;
              padding: 20px;
              display: flex;
              align-items: center;
              flex-direction: row;
              font-size: 19px !important;
              min-height: 180px;
            }
            padding: 0px 10px;
            h5.linkbox {
              margin-bottom: 0;
              u {
                color: #46B2F9 !important;
                font-size: 19px !important;
                line-height: 30px;
              }
            }
          }
      }
    }
  }
  .add-ons {
    background: #E4F7FF;
    border: 1px solid #ccc;
    width: 45%;
    min-height: 500px;
    margin-top: 25px;
    border-radius: 70px;
    padding: 20px;
    padding-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media only screen and (max-width: 1599px){
      width: 70%;
    }
    .custom_plan {
      li {
        h5 {
          margin-bottom: 0;
        }
      }
    }
    .ant-list {
      width: 100%;
      padding-left: 40px;
      .ant-list-item {
        padding: 0;
        h5{
          padding-bottom: 0;
        }
      }
    }
  }
  .pricing-panel {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 20px;
    h3 {
      color: #000 !important;
    }
  }
  h4.basic_plan {
    color: #000 !important;
    font-size: 28px;
    font-weight: 400;
  }
  .custom_plan {
      width: 100%;
      margin: -10px 30px auto !important;
      li {
        list-style: disc;
        padding: 0px 10px;
        color: #333 !important;
        h5{
          font-size: 22px !important;
          font-weight: 400;
        }
      }
  }
  .individual-switch-relative {
      display: flex;
      align-items: start;
      justify-content: center;
      flex-direction: row;
      .seletedClass {
        color: #0066B2 !important;
      }
      .nonSeletedClass {
        font-weight: normal !important;
        color: rgb(153, 153, 153) !important;
        font-size: 25px;
      }
      h5{
        font-size: 28px;
        padding-bottom: 0;
      }
      .ant-switch {
        margin: 0 25px 0 20px !important;
        background: #FFC163 !important;
        height: 40px;
        display: flex;
        justify-content: center;
        align-item: center;
        min-width: 100px;
        .ant-switch-inner {
            padding-inline-start: 0;
            padding-inline-end: 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                font-size: 18px;
            }
            .ant-switch-inner-unchecked {
                margin-top: 0px;
                width: 85%;
                display: flex;
                align-items: center;
                justify-content: center;
                left: 0px;
                margin-left: 15%;
            }
            .ant-switch-inner-checked {
                margin-top: 0px;
                width: 85%;
                display: flex;
                align-items: center;
                justify-content: center;
                left: 0px;
                margin-right: 15%;
            }
        }
        .ant-switch-handle {
            inset-inline-start: 2px;
            width: 35px;
            height: 35px;
            &:before {
                border-radius: 50%;
            }
        }
        .ant-switch-inner-unchecked {
            display: flex;
        }
        .ant-switch-inner-checked {
            display: none;
        }
    }
    .ant-switch.ant-switch-checked {
        background: #FFC163;
        .ant-switch-handle {
            inset-inline-start: calc(100% - 38px);
            width: 35px;
            height: 35px;
            &:before {
                border-radius: 50%;
            }
        }
        .ant-switch-inner-unchecked {
            display: none;
        }
        .ant-switch-inner-checked {
            display: flex;
        }
    }
  }
  .ant-input-affix-wrapper-disabled {
    background: #eee;
    input {
      color: #333;
    }
  }
  h1.ant-typography {
    color: #fff;
    font-size: 65px;
    margin-top: -55px;
  }
  h2.ant-typography {
    color: #fff;
    font-size: 35px;
  }
  h3.ant-typography {
    color: #fff;
    font-size: 35px;
    margin-top: 0px;
  }
  h4 {
    color: #fff;
    padding-bottom: 5px;
  }
  h5 {
    color: #666;
    padding-bottom: 5px;
    font-size: 28px;
    p {
      margin-bottom: 0px;
    }
  }
  .ant-list-item-meta-avatar {
    width: 8%;
  }
  .ocmsolution-authentication-bottom{
    text-align: center;
    padding: 25px;
    border-radius: 0 0 6px 6px;
    p{
      font-size: 14px;
      font-weight: 500;
      color: ${({ theme }) => theme['lightMode']['gray-text']};
      margin-bottom: 0;
      a{
        margin-left: 6px;
        ${({ theme }) => theme['primary-color']};
      }
    }
  }
  .ant-btn-ocm-default-dark-blue {
      background: #0066B2 !important;
      color: #fff !important;
      border-color: transparent !important;
      font-weight: 600;
      font-size: 24px !important;
      height: auto;
      width: 30% !important;
      padding: 10px 20px !important;
      margin: 0 auto;
      border-radius: 45px !important;
      // margin-top: 40px !important;
      margin-bottom: 40px;
      white-space: normal !important;
  }
  hr {
    border: 1px solid #ccc;
    width: 100%;
  }
  .ocmsolution-registration-content {
    width: 54%;
  }
`;

const SubscribeNowWrapStyle = Styled.div`
  margin: 0;
  padding: 0;
  background: #fff;
  height: 100%;
  min-height: 700px;
  display: flex;
  align-items: center;
  justify-content: start;
  padding-top: 10px;
  flex-direction: column;
  .ant-list {
    margin-top: 0px;
  }
  .ocmsolution-registration-top{
    text-align: left;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .ant-row {
      width: 100%;
      // border-bottom: 1px solid ${({ theme }) => theme['lightMode']['border-color-secondary']};
      margin-bottom: 20px;
    }
    h2, h3 {
      color: ${({ theme }) => theme['user-theme'] === undefined ? "#38AAFF" : theme['user-theme']['subscriptionDefaultCOlor'] === undefined ? "#38AAFF" : theme['user-theme']['subscriptionDefaultCOlor'] } !important;
    }
    .ant-btn-ocm-default-danger {
      background: #FF5D5F !important;
      color: #FFF !important;
      border-color: transparent !important;
      font-weight: 600;
      // font-size: 30px;
      // padding: 15px 50px;
      height: auto;
      min-width: 100px;
      border-radius: 50px;
    }
    .ant-btn-ocm-default-stop-cancellation {
      background: #715ABA !important;
      color: #FFF !important;
      border-color: transparent !important;
      font-weight: 600;
      font-size: 30px;
      padding: 15px 50px;
      height: auto;
      min-width: 100px;
      border-radius: 50px;
    }
    .basic_plan_subscribe {
      background: #F3FCFF;
      padding-left: 50px;
      padding-right: 50px;
      margin-top: 20px;
      width: 100%;
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: start;
      .basic_plan_subscribe-child {
        width: 50%;
        padding-left: 150px;
        .ant-form-item {
          .ant-form-item-row {
            display: flex;
            flex-direction: column;
            border-bottom: none !important;
            margin-bottom: 10px !important;
            .ant-form-item-label {
              text-align: start;
            }
            .ant-form-item-label > label {
              height: 60px !important;
              text-align: start;
            }
            .ant-form-item-explain-error {
              // padding: 6px 10px;
              padding-left: 10px;
            }
            .anticon svg {
              color: inherit !important;
            }
            // .ant-form-item-label {
            //   text-align: start;
            //   margin-bottom: 15px;
            //   height: 50px;
            // }
          }
        }
      }
    }
    .billing_summary_panel {
      background: #FFF;
      padding: 0 30px;
      margin-top: 10px;
      width: 100%;
      border-radius: 10px;
      .billing_summary_boxes {
        background: #F5F5F5;
        padding: 20px;
        .billing_summary_box {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          background: #FFF;
          border: 1px solid #ccc;
          border-radius: 25px;
          min-height: 100px;
          .text-box {
            width: 65%;
            min-height: 100px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            padding: 20px;
            h4{
              margin-top: 0 !important;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 100%;
              color: #444;
              text-align: center;
              font-size: 28px !important;
            }
          }
          .icon-box {
            width: 35%;
            height: 100%;
            height: 100px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
          }
          .black {
            background: #3A3540;
          }
          .green {
            color: #008A45 !important;
          }
          .greenbg {
            background: #E8FFF4 !important;
            width: 70% !important;
            font-size: 24px !important;
            border-radius: 18px;
          }
          .grey {
            background: #F6F9FB;
            border: 1px solid #ccc;
          }
        }
      }
    }
    .ocmsolution-registration-top__title{
      font-size: 25px;
      font-weight: 600;
      line-height: 1;
      margin-bottom: 0;
      color: ${({ theme }) => theme['lightMode']['dark-text']};
    }
  }
  .billing-cycle {
    //background: #F3FCFF;
    margin-top: 30px;
    width: 80%;
    border-radius: 10px;
    .billing-cycle-header {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .number {
        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#38AAFF" : theme['user-theme']['subscriptionDefaultCOlor'] === undefined ? "#38AAFF" : theme['user-theme']['subscriptionDefaultCOlor'] } !important;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 40px;
        h3 {
          margin: 0;
          padding: 0;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff !important;
        }
      }
      .subscription-header {
        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#38AAFF" : theme['user-theme']['subscriptionDefaultCOlor'] === undefined ? "#38AAFF" : theme['user-theme']['subscriptionDefaultCOlor'] } !important;
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 13px 0;
        border-radius: 20px;
        h3 {
          margin: 0;
          padding: 0;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff !important;
        }
        h5 {
          margin: 0;
          padding: 0;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
          color: #fff;
        }
      }
    }
    .individual-switch-relative {
      margin-top: 20px;
      .ant-switch {
        margin: 9px 25px 0px 20px !important;
        background: rgb(255, 193, 99) !important;
        height: 40px;
        display: flex;
        -moz-box-pack: center;
        justify-content: center;
        min-width: 100px;
      }
      .add-ons-optional-fields {
        background: #F3FCFF;
        margin-left: 8%;
        margin-top: 0px;
        border: none;
        width: 89%;
        .optional-addons-item {
          width: 100%;
          display: flex;
          align-items: center;
          height: 100%;
          justify-content: center;
          .ant-row {
            width: 100%;
            display: flex;
            align-items: center;
            height: 100%;
            h4 {
              font-size: 24px;
              color: #333;
              margin: 0;
            }
            h5 {
              margin: 0;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
  .optional-addon {
    margin: 50px 0 0;
    width: 100%;
    border: 1px solid rgb(75, 166, 234);
    display: flex;
    align-items: center;
    justify-content: center;
    background: #BDE3FF;
    height: 80px;
    border-radius: 12px;
    h3.optional-addon-h3 {
      color: #333 !important;
      margin-bottom: 0px;
    }
  }
  .add-ons-checkout {
    background: #E4F7FF;
    border: 1px solid #ccc;
    width: 100%;
    border-radius: 25px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 40px;
    margin-bottom: 40px;
    .recurring-amount {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      width: 100%;
      padding: 15px 20px;
      .product-name {
        width: 50%;
        display: flex;
        justify-content: flex-start;
      }
      .product-price {
        width: 50%;
        display: flex;
        justify-content: end;
        padding-right: 40px;
      }
    }
    h4.black-text {
      color: #333 !important;    
      font-size: 24px;
    }
    .amount-area {
      text-align: left;
      width: 200px;
    }
    h2.checkout-text {
      color: #333 !important;
    }
  }
  .add-ons-optional-fields {
    background: #E4F7FF;
    border: 1px solid #ccc;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 40px;
    border-radius: 25px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    .ant-form-item-control-input-content {
      display: flex;
    }
    .ocm-discount-code {
        width: 70%;
        height: 60px;
        margin-right: 10px;
    }
    .ant-btn-ocm-default-apply {
      margin-left: 20px;
      width: 20%;
      background: #00B0F0 !important;
      color: rgb(255, 255, 255) !important;
      border-color: transparent !important;
      font-weight: 600;
      font-size: 24px !important;
      height: auto;
      padding: 10px 20px !important;
      border-radius: 15px !important;
      white-space: normal !important;
    }
    .ant-btn-ocm-default-apply-disabled {
      margin-left: 20px;
      width: 20%;
      background: #00B0F055 !important;
      color: rgb(255, 255, 255) !important;
      border-color: transparent !important;
      font-weight: 600;
      font-size: 24px !important;
      height: auto;
      padding: 10px 20px !important;
      border-radius: 15px !important;
      white-space: normal !important;
    }
    .input-boxes-border {
      border-right: 2px solid #A99BD5;
    }
    .ocm-btn-licenses-disabled {
      background: #BDE3FF;
      color: #000;
      width: 35px;
      line-height: 0;
      padding-top: 14px;
      padding-bottom: 17px;
      font-weight: bold;
      font-size: 23px;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: normal !important;
    }
    .ocm-btn-licenses {
      background: #46B2F9;
      color: #FFF;
      width: 35px;
      line-height: 0;
      padding-top: 14px;
      padding-bottom: 17px;
      font-weight: bold;
      font-size: 23px;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: normal !important;
    }
    .ocm-input-licenses {
      width: 35px;
      height: 34px;
      border: 1px solid #46B2F9;
      font-size: 18px;
      line-height: 1.5;
      color: #000;
      input {
        color: #000;
        padding-left: 0;
      }
    }
    .input-boxes {
      width: 50%;
      display: flex;
      .custom_plan_no_style {
          width: 100%;
          li:not(:last-child) {
            .optional-addons-item {
              border-bottom: 1px solid #ccc;
            }
          }
          li {
            .optional-addons-item {
              width: 100%;
              padding: 20px;
              display: flex;
              align-items: center;
              flex-direction: row;
              font-size: 19px !important;
              min-height: 180px;
            }
            padding: 0px 10px;
            h5.linkbox {
              margin-bottom: 0;
              u {
                color: #46B2F9 !important;
                font-size: 19px !important;
                line-height: 30px;
              }
            }
          }
      }
    }
  }
  .add-ons {

    background: #E4F7FF;
    border: 1px solid #ccc;
    width: 45%;
    min-height: 500px;
    margin-top: 25px;
    border-radius: 70px;
    padding: 20px;
    padding-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media only screen and (max-width: 1599px){
      width: 70%;
    }
    .custom_plan {
      li {
        h5 {
          margin-bottom: 0;
        }
      }
    }
    .ant-list {
      width: 100%;
      padding-left: 40px;
      .ant-list-item {
        padding: 0;
        h5{
          padding-bottom: 0;
        }
      }
    }
  }
  .pricing-panel {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 20px;
    h3 {
      color: #000 !important;
    }
  }
  h4.basic_plan {
    color: #000 !important;
    font-size: 28px;
    font-weight: 400;
  }
  .custom_plan {
      width: 100%;
      margin: -10px 30px auto !important;
      li {
        list-style: disc;
        padding: 0px 10px;
        color: #333 !important;
        h5{
          font-size: 22px !important;
          font-weight: 400;
        }
      }
  }
  .individual-switch-relative {
      display: flex;
      align-items: start;
      justify-content: center;
      flex-direction: row;
      .seletedClass {
        color: #0066B2 !important;
      }
      .nonSeletedClass {
        font-weight: normal !important;
        color: rgb(153, 153, 153) !important;
        font-size: 25px;
      }
      h5{
        font-size: 28px;
        padding-bottom: 0;
      }
      .ant-switch {
        margin: 0 25px 0 20px !important;
        background: #FFC163 !important;
        height: 40px;
        display: flex;
        justify-content: center;
        align-item: center;
        min-width: 100px;
        .ant-switch-inner {
            padding-inline-start: 0;
            padding-inline-end: 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                font-size: 18px;
            }
            .ant-switch-inner-unchecked {
                margin-top: 0px;
                width: 85%;
                display: flex;
                align-items: center;
                justify-content: center;
                left: 0px;
                margin-left: 15%;
            }
            .ant-switch-inner-checked {
                margin-top: 0px;
                width: 85%;
                display: flex;
                align-items: center;
                justify-content: center;
                left: 0px;
                margin-right: 15%;
            }
        }
        .ant-switch-handle {
            inset-inline-start: 2px;
            width: 35px;
            height: 35px;
            &:before {
                border-radius: 50%;
            }
        }
        .ant-switch-inner-unchecked {
            display: flex;
        }
        .ant-switch-inner-checked {
            display: none;
        }
    }
    .ant-switch.ant-switch-checked {
        background: #FFC163;
        .ant-switch-handle {
            inset-inline-start: calc(100% - 38px);
            width: 35px;
            height: 35px;
            &:before {
                border-radius: 50%;
            }
        }
        .ant-switch-inner-unchecked {
            display: none;
        }
        .ant-switch-inner-checked {
            display: flex;
        }
    }
  }
  .ant-input-affix-wrapper-disabled {
    background: #eee;
    input {
      color: #333;
    }
  }
  h1.ant-typography {
    color: #fff;
    font-size: 65px;
    margin-top: -55px;
  }
  h2.ant-typography {
    color: #fff;
    font-size: 35px;
  }
  h3.ant-typography {
    color: #fff;
    font-size: 35px;
    margin-top: 0px;
  }
  h4 {
    color: #fff;
    padding-bottom: 5px;
  }
  h5 {
    color: #666;
    padding-bottom: 5px;
    font-size: 28px;
    p {
      margin-bottom: 0px;
    }
  }
  .ant-list-item-meta-avatar {
    width: 8%;
  }
  .ocmsolution-authentication-bottom{
    text-align: center;
    padding: 25px;
    border-radius: 0 0 6px 6px;
    p{
      font-size: 14px;
      font-weight: 500;
      color: ${({ theme }) => theme['lightMode']['gray-text']};
      margin-bottom: 0;
      a{
        margin-left: 6px;
        ${({ theme }) => theme['primary-color']};
      }
    }
  }
  .ant-btn-ocm-default-dark-blue {
      background: #0066B2 !important;
      color: #fff !important;
      border-color: transparent !important;
      font-weight: 600;
      font-size: 24px !important;
      height: auto;
      width: 30% !important;
      padding: 10px 20px !important;
      margin: 0 auto;
      border-radius: 45px !important;
      // margin-top: 40px !important;
      margin-bottom: 40px;
      white-space: normal !important;
  }
  hr {
    border: 1px solid #ccc;
    width: 100%;
  }
  .ocmsolution-registration-content {
    width: 50%;
  }
`;

const ExtraCSS = Styled.div`
  text-align: center;
  width: 100%;
  margin-top: 20px;
  h2{
    width: 70%;
    margin: 30px auto;
    font-size: 18px;
  }
  svg{
    color: #1ab394;
    padding-right: 8px;
  }
  a:not(.make-white-spcial){
    color: #1ab394;
    text-decoration: none;
    border-bottom: 1px solid;
    padding-bottom: 1px;
    font-size: 18px;
    font-weight: 400;
  }
  a.make-white-spcial{
    color: #000;
    margin-top: -20px;
    font-size: 16px;
    font-weight: 400;
  }
`;

export { 
  AuthenticationWrap, 
  Content, 
  AuthFormWrap, 
  ExtraCSS, 
  LoginFormWrap,
  SignupWrapStyle,
  LoginFormPanelWrap,
  SignupFormWrap,
  SubscribeNowWrapStyle
};
