import Styled, { css } from 'styled-components';

const VideoTutorialsCSS = (theme) => {
    let styles = '';
    const items = theme['tool-info-theme'];
    if (items.has_video_tutorials) {
        if (!Array.isArray(items.video_tutorials)) {
            styles += `
                .video_tutorials {
                    width: 100%;
                    border-color: ${items.video_tutorials.button_color === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : items.video_tutorials.button_color};
                    .ant-btn-icon {
                        background-color: ${items.video_tutorials.button_color === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : items.video_tutorials.button_color} !important;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 40px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .buttonText {
                        display: flex !important;
                        margin-left: 40px !important;
                        color: ${items.video_tutorials.text_color === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : items.video_tutorials.text_color};
                    }
                }
            `;
        }
        else {
            items.video_tutorials.map((item, i) => {
                styles += `
                    .video_tutorials_${i} {
                        width: 100%;
                        margin-bottom: 10px;
                        border-color: ${item.button_color === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : item.button_color};
                        .ant-btn-icon {
                            position: absolute;
                            top: 0;
                            left: 0;
                            background: ${items.video_tutorials.button_color === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : items.video_tutorials.button_color};
                            width: 40px;
                            height: 40px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        .buttonText {
                            display: flex !important;
                            margin-left: 40px !important;
                            color: ${item.text_color === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : item.text_color};
                        }
                    }
                `;
            });
        }
    }
    return css`${styles}`;
}

const ResourcesCSS = (theme) => {
    let styles = '';
    const items = theme['tool-info-theme'];
    if (items.has_resources) {
        if (!Array.isArray(items.resources)) {
            styles += `
                .resources {
                    width: 100%;
                    border-color: ${items.resources.button_color === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : items.resources.button_color};
                    .ant-btn-icon {
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: ${items.video_tutorials.button_color === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : items.video_tutorials.button_color};
                        width: 40px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .buttonText {
                        display: flex !important;
                        margin-left: 40px !important;
                        color: ${items.resources.text_color === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : items.resources.text_color};
                    }
                }
            `;
        }
        else {
            items.resources.map((item, i) => {
                styles += `
                    .resources_${i} {
                        margin-bottom: 10px;
                        width: 100%;
                        border-color: ${item.button_color === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : item.button_color};
                        .ant-btn-icon {
                            position: absolute;
                            top: 0;
                            left: 0;
                            background: ${items.video_tutorials.button_color === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : items.video_tutorials.button_color};
                            width: 40px;
                            height: 40px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        .buttonText {
                            display: flex !important;
                            margin-left: 40px !important;
                            color: ${item.text_color === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : item.text_color};
                        }
                    }
                `;
            });
        }
    }
    return css`${styles}`;
}

const SurveyCSS = (theme) => {
    let styles = '';
    const items = theme['tool-info-theme'];
    if (items.has_survey) {
        if (!Array.isArray(items.survey)) {
            styles += `
                .survey {
                    width: 100%;
                    border-color: ${items.survey.button_color === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : items.survey.button_color};
                    .ant-btn-icon {
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: ${items.video_tutorials.button_color === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : items.video_tutorials.button_color};
                        width: 40px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .buttonText {
                        display: flex !important;
                        margin-left: 40px !important;
                        color: ${items.survey.text_color === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : items.survey.text_color};
                    }
                }
            `;
        }
        else {
            items.survey.map((item, i) => {
                styles += `
                    .survey_${i} {
                        width: 100%;
                        margin-bottom: 10px;
                        border-color: ${item.button_color === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : item.button_color};
                        .ant-btn-icon {
                            position: absolute;
                            top: 0;
                            left: 0;
                            background: ${items.video_tutorials.button_color === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : items.video_tutorials.button_color};
                            width: 40px;
                            height: 40px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        .buttonText {
                            display: flex !important;
                            margin-left: 40px !important;
                            color: ${item.text_color === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : item.text_color};
                        }
                    }
                `;
            });
        }
    }
    return css`${styles}`;
}

const ToolHomeTabCSS = (theme) => {
    let styles = '';
    const items = theme['tab-theme'];
    items.map((item, i) => {
        if (item._id === undefined) {
            styles += `
                [data-node-key="${item['tools_key']}"] {
                    background-color: ${item['tab_color'] === undefined ? "#0066B2" : item['tab_color']};
                    border: 1px solid ${item['tab_color'] === undefined ? "#0066B2" : item['tab_color']};
                    color: ${item['tab_font_color'] === undefined ? "#333333" : item['tab_font_color']};
                    font-weight: bold;
                    .title-label {
                        // font-size: 16px;
                        color: ${item['tab_font_color'] === undefined ? "#333333" : item['tab_font_color']};
                    }
                }
                .ant-tabs-ink-bar {
                    background-color: ${item['tab_color'] === undefined ? "#0066B2" : item['tab_color']};
                    height: 20px;
                    display: none;
                }
                border-bottom: 1px solid ${theme['tab-border-color'] === undefined ? "#0066B2" : theme['tab-border-color']};
            `
        }
    });
    return css`${styles}`;
}

const ToolsTabContentCSS = (theme, items) => {
    let styles = '';
    items.map((item, i) => {
        if (item._id === undefined) {
            styles += `
                .ant-tabs-tabpane {
                    padding: 10px 20px;
                }
            `
        }
    });
    return css`${styles}`;
}

const ToolsDashboardPage = Styled.div`
    margin-bottom: 30px;
    .toolHomePhaseTabStyle
    {
        .ant-tabs-nav {
            margin: 0;
        }
        .ant-tabs-content-holder {
            margin-top: 30px;
        }
        .ant-tabs-nav-list{
            // width: 100%;
            justify-content: center;
            .ant-tabs-tab {
                white-space: pre-wrap;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
            }
            .ant-tabs-ink-bar {
                height: 5px;
                background: #0066B2;
            }
            .title-label {
                height: 100%;
                min-width: 200px;
                font-size: 0.8rem;
                color: #333;
            }
            .ant-tabs-tab-active {
                .ant-tabs-tab-btn {
                    color: inherit;
                }
                .title-label {
                    height: 100%;
                    font-size: 0.8rem;
                    font-weight: 600;
                    .active-meter {
                        display: flex;
                    }
                }
            }
        }
    }
`;

const ToolsHomePage = Styled.div`
    margin-bottom: 30px;
    .ant-tabs-nav {
        margin: 0;
    }
    .ant-tabs-nav-list{
        // width: 100%;
        .ant-tabs-tab {
            white-space: pre-wrap;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
        .ant-tabs-ink-bar {
            height: 5px;
            background: #0066B2;
        }
        
        .ant-tabs-tab-active {
            .ant-tabs-tab-btn {
                color: inherit;
            }
            .title-label {
                height: 100%;
                width: 100%;
                font-size: 0.8rem;
                font-weight: 600;
                .active-meter {
                    display: flex;
                }
            }
        }
    }
`;

const ToolsHomeTabStyle = Styled.div`
    margin-top: 40px;
    margin-bottom: 30px;
    .ant-tabs-nav {
        margin: 0;
    }
    .toolHomePhaseTabStyle .ant-tabs-content-holder {
        margin-top: -30px;
        border: 2px solid ${({ theme }) => theme['tab-border-color'] === undefined ? "#0066B2" : theme['tab-border-color']};
        border-top: 30px solid ${({ theme }) => theme['tab-border-color'] === undefined ? "#0066B2" : theme['tab-border-color']};
        border-bottom-left-radius: ${({ theme }) => theme['tab-border-global-radius'] === undefined ? "22px" : theme['tab-border-global-radius']} !important;
        border-bottom-right-radius: ${({ theme }) => theme['tab-border-global-radius'] === undefined ? "22px" : theme['tab-border-global-radius']} !important;
    }
    .toolhomeCard {
        .ant-card-body {
            padding: 0px;
            background: #F8F9FB;
        }
    }
    .toolHomePhaseTabStyle {
        .ant-tabs-nav-list{
            //width: 100%;
            .ant-tabs-tab-active {
                .title-label {
                    font-size: 18px;
                }
            }
            .ant-tabs-tab:not(.ant-tabs-tab-active) {
                padding-top: 15px;
                padding-bottom: 15px;
                margin-top: 30px;
                .title-label {
                    font-size: 16px;
                    font-weight: 400;
                }
            }
            .ant-tabs-tab {
                height: auto;
                min-width: 170px;
                // border: 1px solid #ccc;
                word-wrap: break-word;
                white-space: pre-wrap;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                border-top-left-radius: ${({ theme }) => theme['tab-border-global-radius'] === undefined ? "22px" : theme['tab-border-global-radius']} !important;            
                border-top-right-radius: ${({ theme }) => theme['tab-border-global-radius'] === undefined ? "22px" : theme['tab-border-global-radius']} !important;
                .ant-tabs-tab-btn {
                    height: 100%;
                    .title-label {
                        width: 100%;
                        height: 100%;
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 5px;
                    }
                }
            }
            
            ${({ theme }) => `
                ${ToolHomeTabCSS(theme)}
            `};
        }
    }
    .tools-card-panel {
        display:flex;
        align-items: center;
        min-height: 600px !important;
        justify-content: center;
        .productTitle {
            font-size: 20px;
            color: #333 !important;
            padding-top: 7px;
            a {
                color: #333 !important;
                text-decoration: underline;
            }
        }
    }
    .ant-tabs-content-holder {
        ${({ theme }) => `
            ${ToolsTabContentCSS(theme, theme['tab-theme'])}
        `};
    }
`;

const ToolsFreeTab = Styled.div`
    margin-bottom: 30px;
    .freePhaseTabStyle {
        .ant-tabs-nav {
            margin: 0;
        }
        .ant-tabs-content-holder {
            border: 2px solid ${({ theme }) => theme['tab-border-color'] === undefined ? "#0066B2" : theme['tab-border-color']};
            border-top: 30px solid ${({ theme }) => theme['tab-border-color'] === undefined ? "#0066B2" : theme['tab-border-color']};
            border-bottom-left-radius: ${({ theme }) => theme['tab-border-global-radius'] === undefined ? "22px" : theme['tab-border-global-radius']} !important;
            border-bottom-right-radius: ${({ theme }) => theme['tab-border-global-radius'] === undefined ? "22px" : theme['tab-border-global-radius']} !important;
        }
        .ant-tabs-nav-list{
            width: 100%;
            // margin-bottom: 65px;
            display:flex;
            align-items: end;
            min-height: 140px !important;
            .ant-tabs-tab {
                min-height: 85px;
                min-width: 170px;
                border: 1px solid #ccc;
                word-wrap: break-word;
                white-space: pre-wrap;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                border-top-left-radius: ${({ theme }) => theme['tab-border-global-radius'] === undefined ? "22px" : theme['tab-border-global-radius']} !important;            
                border-top-right-radius: ${({ theme }) => theme['tab-border-global-radius'] === undefined ? "22px" : theme['tab-border-global-radius']} !important;
            }
            .title-label {
                height: 100%;
                width: 100%;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 500;
            }
            .ant-tabs-tab-active {
                min-height: 110px;
                .ant-tabs-tab-btn {
                    color: inherit;
                }
                .title-label {
                    height: 100%;
                    width: 100%;
                    font-weight: bold;
                    .active-meter {
                        display: flex;
                    }
                }
            }
            ${({ theme }) => `
                ${ToolHomeTabCSS(theme)}
            `};
        }
        .ant-tabs-content-holder {
            ${({ theme }) => `
                ${ToolsTabContentCSS(theme, theme['tab-theme'])}
            `};
        }
    }
`;

const ToolsConfigurationPanel = Styled.div`
    margin-bottom: 30px;
    padding-top: 10px;
    h1 {
        font-size: 24px;
        color: ${({ theme }) => theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']} !important;
    }
    .ant-btn {
        // border-color: ${({ theme }) => theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']};
    }
    .ant-btn-icon {
        position: absolute;
        // top: 0;
        left: 0;
        background: transparent;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .buttonText {
        display: flex !important;
        margin-left: 40px !important;
        color: ${({ theme }) => theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']};
    }
`;

const TaskCheckListContent = Styled.div`
    .ant-collapse-item .ant-collapse-header .ant-collapse-arrow {
        font-size: 20px !important;
        position: relative !important;
        top: 3px !important;
        right: 0px !important;
        
    }
    .ant-collapse-header
    {
        padding: 12px 0px !important;
        padding-inline-start: 0px !important;
    }
    .ant-collapse > .ant-collapse-item:last-child
    {
        background-color: white !important;
    }
    .ant-collapse-content-box .ant-tag
    {
        width:100%;
        background-color: white !important;
        border: none !important;
        color: rgb(176,172,172);
    }
    .ant-collapse-item .ant-collapse-header .ant-collapse-arrow svg
    {
        color: ${({ theme }) => theme['user-theme'] === undefined ? "#0EC1C4" : theme['user-theme']['Phase1Color'] === undefined ? "#0EC1C4" : theme['user-theme']['Phase1Color']} !important;
    }

    .ant-collapse-item:nth-child(2) .ant-collapse-header .ant-collapse-arrow svg
    {
        color: ${({ theme }) => theme['user-theme'] === undefined ? "#0EC1C4" : theme['user-theme']['Phase2Color'] === undefined ? "#0EC1C4" : theme['user-theme']['Phase2Color']} !important;
    }
    .ant-collapse-item:nth-child(3) .ant-collapse-header .ant-collapse-arrow svg
    {
        color: ${({ theme }) => theme['user-theme'] === undefined ? "#0EC1C4" : theme['user-theme']['Phase3Color'] === undefined ? "#0EC1C4" : theme['user-theme']['Phase3Color']} !important;
    }
    .ant-collapse-item:nth-child(4) .ant-collapse-header .ant-collapse-arrow svg
    {
        color: ${({ theme }) => theme['user-theme'] === undefined ? "#0EC1C4" : theme['user-theme']['Phase4Color'] === undefined ? "#0EC1C4" : theme['user-theme']['Phase4Color']} !important;
    }
    .ant-collapse-item:nth-child(5) .ant-collapse-header .ant-collapse-arrow svg
    {
        color: ${({ theme }) => theme['user-theme'] === undefined ? "#0EC1C4" : theme['user-theme']['Phase5Color'] === undefined ? "#0EC1C4" : theme['user-theme']['Phase5Color']} !important;
    }
    
    .ant-collapse-header-text
    {
        font-size: 1.2rem !important;
        color: ${({ theme }) => theme['user-theme'] === undefined ? "#0EC1C4" : theme['user-theme']['Phase1Color'] === undefined ? "#0EC1C4" : theme['user-theme']['Phase1Color']} !important;
    }
    .ant-collapse-item:nth-child(2) .ant-collapse-header-text
    {
        color: ${({ theme }) => theme['user-theme'] === undefined ? "#0EC1C4" : theme['user-theme']['Phase2Color'] === undefined ? "#0EC1C4" : theme['user-theme']['Phase2Color']} !important;
    }
    .ant-collapse-item:nth-child(3) .ant-collapse-header-text
    {
        color: ${({ theme }) => theme['user-theme'] === undefined ? "#0EC1C4" : theme['user-theme']['Phase3Color'] === undefined ? "#0EC1C4" : theme['user-theme']['Phase3Color']} !important;
    }
    .ant-collapse-item:nth-child(4) .ant-collapse-header-text
    {
        color: ${({ theme }) => theme['user-theme'] === undefined ? "#0EC1C4" : theme['user-theme']['Phase4Color'] === undefined ? "#0EC1C4" : theme['user-theme']['Phase4Color']} !important;
    }
    .ant-collapse-item:nth-child(5) .ant-collapse-header-text
    {
        color: ${({ theme }) => theme['user-theme'] === undefined ? "#0EC1C4" : theme['user-theme']['Phase5Color'] === undefined ? "#0EC1C4" : theme['user-theme']['Phase5Color']} !important;
    }
    .ant-collapse-content-box
    {
        padding-block:0px !important;
        padding: 0px !important;
    }
`;

const ToolHeaderStyle = Styled.div`
    margin-bottom: 30px;
    h1 {
        font-size: ${() => window.innerWidth < 1500 ? '20px' : '24px'};
        color: ${({ theme }) => theme['tool-info-theme'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['tool-info-theme']['tag_line_color'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['tool-info-theme']['tag_line_color']} !important;
    }
    .video_tutorials {
        ${({ theme }) => `
            ${VideoTutorialsCSS(theme)}
        `};
    }
    .resources {
        ${({ theme }) => `
            ${ResourcesCSS(theme)}
        `};
    }
    .survey {
        ${({ theme }) => `
            ${SurveyCSS(theme)}
        `};
    }
    .ant-select-item {
        padding: 10px 12px !important;
    }
`;

const AddTrainingStyle = Styled.div`
padding: 20px;
.tag-level-of-impect {
    .ant-tag {
        font-size: 12px;
        padding: 10px 24px;
        background: ${({ theme }) => theme['user-theme'] === undefined ? "#FFFFFF" : theme['user-theme']['tagBackground'] === undefined ? "#FFFFFF" : theme['user-theme']['tagBackground']};
        border: 1px solid ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['tagBorderColorOCM'] === undefined ? "#0066B2" : theme['user-theme']['tagBorderColorOCM']};
        color: ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['tagColorOCM'] === undefined ? "#0066B2" : theme['user-theme']['tagColorOCM']};
    }
    .ant-tag-checkable-checked {
        background: ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['tagCheckedBackground'] === undefined ? "#0066B2" : theme['user-theme']['tagCheckedBackground']};
        color: ${({ theme }) => theme['user-theme'] === undefined ? "#FFFFFF" : theme['user-theme']['tagCheckedColor'] === undefined ? "#FFFFFF" : theme['user-theme']['tagCheckedColor']};
    }
}
.ant-picker
{
    min-width: 190px;
}
.ant-checkbox-group
{ 
    width: 100%;
    padding: 7px !important;
    overflow: scroll;
    border: 1px dotted #A2F8F8;
}
.ant-checkbox-wrapper:nth-child(even)
{
    background: #A2F8F8;
}
.ant-checkbox-wrapper
{
    padding: 5px !important;
    width: 100%;  
    
}
.ant-btn-primary
{
    padding: 10px 50px 40px !important;
    border-radius: 30px !important;
    background: #FFC163 !important;
    font-size: 17px !important;
}
.active_session, .inactive_session
{
    font-size: 16px;
    cursor: pointer;
}
.active_session
{
    color: #00B0F0;
    font-weight:bold;
    text-decoration:underline;
    
}
.inactive_session
{
    color:#C2C2C2;
}
.ant-input, .ant-form-item-control-input .ant-picker
{
    border: 1px solid #A2F8F8 !important;
}
.ant-picker-suffix
{
    color:#00B4F1 !important;
}
.ant-divider .ant-divider-inner-text {
    font-weight: bold;
    font-size: 15px;
}
`;

const LevelOfImpect = Styled.div`
padding: 20px;
.tag-level-of-impect {
    .ant-tag {
        font-size: 12px;
        padding: 6px 11px;
        background: ${({ theme }) => theme['user-theme'] === undefined ? "#FFFFFF" : theme['user-theme']['tagBackground'] === undefined ? "#FFFFFF" : theme['user-theme']['tagBackground']};
        border: 1px solid ${({ theme }) => theme['user-theme'] === undefined ? "#939393" : theme['user-theme']['tagBorderColorOCM'] === undefined ? "#939393" : theme['user-theme']['tagBorderColorOCM']};
        color: ${({ theme }) => theme['user-theme'] === undefined ? "#939393" : theme['user-theme']['tagColorOCM'] === undefined ? "#939393" : theme['user-theme']['tagColorOCM']};
        margin-right: 4px
    }
    .ant-tag-checkable-checked {
        background: ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['tagCheckedBackground'] === undefined ? "#0066B2" : theme['user-theme']['tagCheckedBackground']};
        color: ${({ theme }) => theme['user-theme'] === undefined ? "#FFFFFF" : theme['user-theme']['tagCheckedColor'] === undefined ? "#FFFFFF" : theme['user-theme']['tagCheckedColor']};
    }
}
.ant-picker
{
    min-width: 190px;
}
.ant-checkbox-group
{ 
    width: 100%;
    padding: 7px !important;
    overflow: scroll;
    border: 1px dotted rgb(206,242,255);
}
.ant-checkbox-wrapper:nth-child(odd)
{
    background: rgb(206,242,255)
}
.ant-checkbox-wrapper
{
    padding: 5px !important;
    width: 100%;  
    
}
`;

const ToolInfoStyle = Styled.div`
    margin-bottom: 30px;
    .ant-input-group-addon {
        cursor: pointer;
    }
    .project-picker {
        .ant-picker {
            min-width: 210px;
            .ant-picker-input {
                position: relative;
                padding-left: 10px;
                .ant-picker-suffix {
                    position: absolute;
                    left; 10px;
                }
                input {
                    padding: 10px;
                    padding-left: 40px;
                }
            }
        }
    }
    .icon-panel {
        padding: 10px 15px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        // border: 1px solid ${({ theme }) => theme['tool-info-theme'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['tool-info-theme']['secondary_color'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['tool-info-theme']['secondary_color']} !important;
        border-radius: ${({ theme }) => theme['border-global-radius'] === undefined ? "18px" : theme['border-global-radius']};
    }
    .ant-progress-inner {
        border: 1px solid ${({ theme }) => theme['tool-info-theme'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['tool-info-theme']['secondary_color'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['tool-info-theme']['secondary_color']} !important;
    }
    .ant-progress {
        flex-direction: column;
    }
    .ant-progress-text {
        width: 100%;
        padding-top: 7px;
        font-weight: 500;
        font-size: 20px;
        color: ${({ theme }) => theme['tool-info-theme'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['tool-info-theme']['secondary_color'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['tool-info-theme']['secondary_color']} !important;
    }
    .tool_title {
        .ant-divider {
            .ant-divider-inner-text {
                padding-left: 90px;
                padding-right: 90px;
                color: ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['ToolTitleColor'] === undefined ? "#0066B2" : theme['user-theme']['ToolTitleColor']};
                font-size: 1.8rem;
                font-weight: 600;
                span {
                    font-size: 20px;
                    display: block;
                }
                @media only screen and (max-width: 767px) {
                    padding-left: 1rem;
                    padding-right: 1rem;
                    font-size: 1.5rem;
                    font-weight: 600;
                }
            }
            &:before {
                border-block-start: 2px solid ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['ToolTitleColor'] === undefined ? "#0066B2" : theme['user-theme']['ToolTitleBorderColor']};
            }
            &:after {
                border-block-start: 2px solid ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['ToolTitleColor'] === undefined ? "#0066B2" : theme['user-theme']['ToolTitleBorderColor']};
            }
        }
    }
    .stakeholder_tag {
        padding: 20px;
        .ant-tag {
            font-size: 16px;
            padding: 10px 24px;
            background: ${({ theme }) => theme['user-theme'] === undefined ? "#FFFFFF" : theme['user-theme']['tagBackground'] === undefined ? "#FFFFFF" : theme['user-theme']['tagBackground']};
            border: 1px solid ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['tagBorderColor'] === undefined ? "#0066B2" : theme['user-theme']['tagBorderColor']};
        }
        .ant-tag-checkable-checked {
            background: ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['tagCheckedBackground'] === undefined ? "#0066B2" : theme['user-theme']['tagCheckedBackground']};
            color: ${({ theme }) => theme['user-theme'] === undefined ? "#FFFFFF" : theme['user-theme']['tagCheckedColor'] === undefined ? "#FFFFFF" : theme['user-theme']['tagCheckedColor']};
        }
    }
    .iconAction {
        .action-icon-panel {
            @media only screen and (max-width: 767px) {
                padding-top: 1rem;
                align-items: center;
                justify-content: center;
                margin-bottom: 1rem;
            }
        }
        .ant-segmented {
            background: ${({ theme }) => theme['user-theme'] === undefined ? "#FFFFFF" : theme['user-theme']['segmentBackground'] === undefined ? "#FFFFFF" : theme['user-theme']['segmentBackground']} !important;
        }
        .ant-segmented-item:hover::after {
            background: transparent !important;
        }
        .ant-segmented-item:hover {
            svg{
                --fa-primary-color: #00A3DE !important;
                --fa-secondary-color: #3BCBFF !important;
            }
        }
    }
    .search-panel {
        @media only screen and (max-width: 767px) {
            display: flex;
            padding-top: 1rem;
            align-items: center;
            justify-content: center;
        }
    }
    .footer-button {
        .ant-btn {
            background: ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['buttonColor'] === undefined ? "#0066B2" : theme['user-theme']['buttonColor']} !important;
        }
        .ant-btn-icon {
            background: transparent;
            font-size: 20px;
            font-weight: 600;
            top: 0;
        }
    }
    .button-no-background {
        .ant-btn {
            background: ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['buttonColor'] === undefined ? "#0066B2" : theme['user-theme']['buttonColor']} !important;
        }
        .ant-btn-icon {
            position: unset;
            top: unset;
            left: 0px;
            background: transparent;
            width: unset;
            height: unset;
            display: unset;
            -moz-box-align: unset;
            align-items: unset;
            -moz-box-pack: unset;
            justify-content: center;
            align-items: center;
            display: flex;
            min-width: none;
        }
    }
    .add_more_btn_panel {
        .ant-btn {
            background: ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['buttonColor'] === undefined ? "#0066B2" : theme['user-theme']['buttonColor']} !important;
            border-color: transparent !important;
        }
        .ant-btn-icon {
            position: unset;
            top: unset;
            left: 0px;
            background: transparent;
            width: 30px;
            height: 30px;
            display: unset;
            -moz-box-align: unset;
            align-items: unset;
            -moz-box-pack: unset;
            justify-content: center;
            align-items: center;
            display: flex;
            min-width: none;
        }
    }
    .ant-segmented-item-selected {
        color: ${({ theme }) => theme['user-theme'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['mainHighlightColor'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['mainHighlightColor']} !important;
    }
    .ant-segmented-item {
        color: ${({ theme }) => theme['user-theme'] === undefined ? "#666666" : theme['user-theme']['mainHighlightDeselectColor'] === undefined ? "#666666" : theme['user-theme']['mainHighlightDeselectColor']};
    }
    .header-title-tools {
        display: flex;
        flex-direction: column;
        p {
            margin-bottom: 0;
            color: ${({ theme }) => theme['user-theme'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['SubTitle'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['SubTitle']} !important;
        }
        h1 {
            font-weight: 600;
            font-size: 22px;
            color: ${({ theme }) => theme['user-theme'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['Title'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['Title']} !important;
        }
    }
    .header-title {
        display: flex;
        align-items: center;
        h1 {
            font-weight: 600;
            font-size: 34px;
            color: ${({ theme }) => theme['user-theme'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['Title'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['Title']} !important;
        }
    }
    .header-title-checklist {
        h1 {
            font-weight: 600;
            font-size: 28px;
            color: ${({ theme }) => theme['user-theme'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['Title'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['Title']} !important;
        }
    }
    .clear-filter {
        display: flex;
        align-items: center;
        padding-left: 20px;
    }
    .button-panel {
        display: flex;
        align-items: center;
        justify-content: center;
        .ant-btn-ocm-default {
            background: ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['buttonColor'] === undefined ? "#0066B2" : theme['user-theme']['buttonColor']} !important;
            color: ${({ theme }) => theme['user-theme'] === undefined ? "#FFFFFF" : theme['user-theme']['buttonTextColor'] === undefined ? "#FFFFFF" : theme['user-theme']['buttonTextColor']} !important;
            border-radius: ${({ theme }) => theme['user-theme'] === undefined ? "8px" : theme['user-theme']['borderRadius'] === undefined ? "8px" : theme['user-theme']['borderRadius']} !important;
            font-size: 24px;
            height: auto;
            border-color: transparent !important;
            padding: 10px 40px;
        }
        .ant-btn-ocm-default[disabled=""] {
            opacity: 0.5;
        }
    }
    .checklist-text {
        display: flex;
        align-items: center;
        label {
            padding-left: 20px;
            font-size: 24px;
        }
    }
    .tool_icon {
        font-size: 50px;
    }
    h1 {
        font-size: 24px;
        color: ${({ theme }) => theme['tool-info-theme'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['tool-info-theme']['tag_line_color'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['tool-info-theme']['tag_line_color']} !important;
    }
    .ant-select-item {
        padding: 10px 12px !important;
    }
`;

const StakeholderSegmentContentStyle = Styled.div`
    margin-bottom: 30px;
    padding: 20px;
    border: 1px solid #eeeeee;
    border-bottom-left-radius: ${({ theme }) => theme['tab-border-global-radius'] === undefined ? "22px" : theme['tab-border-global-radius']} !important;
    border-bottom-right-radius: ${({ theme }) => theme['tab-border-global-radius'] === undefined ? "22px" : theme['tab-border-global-radius']} !important;
    label {
        font-size: 20px !important;
    }
    .ant-select.ant-select-multiple .ant-select-selection-item {
        height: 24px;
        -moz-box-align: center;
        align-items: center;
        color: rgb(64, 64, 64);
        padding: 12px 12px 14px 8px;
        background-color: ${({ theme }) => theme['user-theme'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedBackground'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedBackground']};
        color: ${({ theme }) => theme['user-theme'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedColor'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedColor']};
        font-weight: 600;
        border-color: rgb(227, 230, 239);
        .ant-select-selection-item-remove {
            svg{
                color: ${({ theme }) => theme['user-theme'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedColor'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedColor']};    
                font-weight: bold;
                margin-left: 1px;
            }
        }
    }
    .add_more_btn_panel .ant-btn {
        color: ${({ theme }) => theme['user-theme'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedColor'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedColor']};
        font-weight: 600;
    }
    .ant-btn-ocm-default {
        background: ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['buttonColor'] === undefined ? "#0066B2" : theme['user-theme']['buttonColor']} !important;
        color: ${({ theme }) => theme['user-theme'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedColor'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedColor']} !important;
        border-color: transparent !important;
        font-weight: 600;
        font-size: 20px;
        height: auto;
    }
    .ant-btn-ocm-default[disabled=""] {
        opacity: 0.5;
    }
`;

const StakeholderTypeModifyStyle = Styled.div`
    width: 100%;
    margin-bottom: 10px;
    padding: 20px;
    border: 1px solid #ddd;
    label {
        font-size: 20px !important;
    }
    .ant-space {
        width: 100%;
    }
    .ant-space-item {
        width: 23%;
        gap: 44;
        .ant-tag {
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: normal;
            border: 2px solid ${({ theme }) => theme['user-theme'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagBorderColor'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagBorderColor']};
            padding: 0px;       
            .remove-stakeholder-type {
                position: absolute;
                top: -10px;
                right: -10px;
                font-size: 20px;
                .fa-secondary {
                    opacity: 1 !important;
                }
            }
            .ant-typography {
                padding: 7px;
            }
            .ant-typography-edit-content {
                margin-bottom: 0;
                display: flex;
                align-items: center;
                width: 100%;
                height: 100%;
                inset-inline-start: 0;
                margin-top: 0;
                padding: 0px;
                textarea {
                    width: 100%;
                    height: 32px;
                }
            }
            .anticon-edit{
                position: absolute;
                right: 0;
                display: flex;
                align-items: center;
                top: 36%;
            }
        }
        margin-right: 1%;
        margin-bottom: 10px;
    }
`;

const StakeholderSegmentContentChampionStyle = Styled.div`
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    label {
        font-size: 20px !important;
    }
    .ant-select.ant-select-multiple .ant-select-selection-item {
        height: 24px;
        -moz-box-align: center;
        align-items: center;
        color: rgb(64, 64, 64);
        padding: 12px 12px 14px 8px;
        background-color: ${({ theme }) => theme['user-theme'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedBackground'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedBackground']};
        border: 1px solid ${({ theme }) => theme['user-theme'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedColor'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedColor']};
        color: ${({ theme }) => theme['user-theme'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedColor'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedColor']};
        font-weight: 600;
        border-color: rgb(227, 230, 239);
        .ant-select-selection-item-remove {
            svg{
                color: ${({ theme }) => theme['user-theme'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedColor'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedColor']};
                font-weight: bold;
                margin-left: 1px;
            }
        }
    }
    .add_more_btn_panel .ant-btn {
        color: ${({ theme }) => theme['user-theme'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedColor'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedColor']};
        font-weight: 600;
    }
    .ant-btn-ocm-default {
        background: ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['buttonColor'] === undefined ? "#0066B2" : theme['user-theme']['buttonColor']} !important;
        color: ${({ theme }) => theme['user-theme'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedColor'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedColor']} !important;
        border-color: transparent !important;
        font-weight: 600;
        font-size: 20px;
        height: auto;
    }
    .ant-btn-ocm-default[disabled=""] {
        opacity: 0.5;
    }
    .ant-tag-checkable-checked {
        background-color: #1677ff;
    }
    .ant-tag-checkable {
        border: 1px solid ${({ theme }) => theme['user-theme'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagBorderColor'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagBorderColor']};
        padding: 5px 15px;
    }
`;

const AudienceRiskPanelStyle = Styled.div`
    margin-bottom: 30px;
    //border: 1px solid #eeeeee;
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1 1 auto;
    .search-panel {
        @media only screen and (max-width: 767px) {
            padding-top: 1rem;
            align-items: center;
            justify-content: center;
            margin-bottom: 2rem;
        }
    }
    .audience-panel {
        display: flex;
        flex-direction: column;
        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#EBFAFF" : theme['user-theme']['AudienceRiskPanelColor'] === undefined ? "#EBFAFF" : theme['user-theme']['AudienceRiskPanelColor']};
        // border-color: 1px solid ${({ theme }) => theme['user-theme'] === undefined ? "#1270BE" : theme['user-theme']['borderColor'] === undefined ? "#1270BE" : theme['user-theme']['borderColor']};
        border-radius: ${({ theme }) => theme['tab-border-global-radius'] === undefined ? "22px" : theme['tab-border-global-radius']} !important;
        padding-bottom: 20px;
        .audience-avatar {
            padding-left: 0.7rem;
            padding-top: 1rem;
        }
        .audience-info {
            padding-top: 10px;
            h2 {
                color: #000 !important;
                font-weight: 600;
                margin-bottom: 0.1em;
            }
            p {
                margin-bottom: 0.2em;
            }
        }
        .stakeholder-types {
            width: 100%;
            .ant-space-item {
                margin-bottom: 0.5rem;
                .ant-tag {
                    min-width: 90%;
                    max-width: 90%;
                    text-align: center;
                    font-size: 0.6rem;
                    padding: 4px 8px;
                    margin: 0;
                    white-space: pre-line;
                    background: ${({ theme }) => theme['user-theme'] === undefined ? "#FFFFFF" : theme['user-theme']['tagBackground'] === undefined ? "#FFFFFF" : theme['user-theme']['tagBackground']};
                    border: 1px solid ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['tagBorderColor'] === undefined ? "#0066B2" : theme['user-theme']['tagBorderColor']};
                }
                .ant-tag-checkable-checked {
                    background: ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['tagCheckedBackground'] === undefined ? "#0066B2" : theme['user-theme']['tagCheckedBackground']};
                    color: ${({ theme }) => theme['user-theme'] === undefined ? "#FFFFFF" : theme['user-theme']['tagCheckedColor'] === undefined ? "#FFFFFF" : theme['user-theme']['tagCheckedColor']};
                }
            }
        }
    }
    .audience-action-panel {
        display: flex;
        flex-direction: column;
        align-items: center;
        @media only screen and (max-width: 767px) {
            padding-top: 1rem;
            .audience-risk-panel {
                margin-right: auto;
            }
        }
        .risk-status {
            padding-left: 0.3rem;
            padding-right: 0.3rem;
            @media only screen and (min-width: 1200px) and (max-width: 1318px){
                padding-left: 0rem;
                padding-right: 0rem;
            }
            text-align: center;
            font-size: 0.8rem;
            font-weight: 500;
            .ant-radio-group {
                padding-left: 0.7rem;
                @media only screen and (max-width: 878px){
                    padding-left: 0.1rem;
                }
                @media only screen and (min-width: 1200px) and (max-width: 1461px){
                    padding-left: 0.1rem;
                }
                label.ant-radio-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    span.ant-radio-inner {
                        margin-bottom: 10px;
                    }
                    span.ant-radio-checked {
                        margin-bottom: 10px;
                    }
                    .ant-radio-inner {
                        width: 2rem;
                        height: 2rem;
                        background-color: #eee;
                        border: 1px solid #ccc;
                        @media only screen and (max-width: 1200px){
                            width: 1.70rem;
                            height: 1.70rem;
                        }
                        @media only screen and (max-width: 1062px){
                            width: 1.45rem;
                            height: 1.45rem;
                        }
                        @media only screen and (max-width: 931px){
                            width: 1.25rem;
                            height: 1.25rem;
                        }
                        @media only screen and (max-width: 878px){
                            width: 1.05rem;
                            height: 1.05rem;
                        }
                        @media only screen and (min-width: 1200px) and (max-width: 1758px){
                            width: 1.90rem;
                            height: 1.90rem;
                        }
                        @media only screen and (min-width: 1200px) and (max-width: 1718px){
                            width: 1.70rem;
                            height: 1.70rem;
                        }
                        @media only screen and (min-width: 1200px) and (max-width: 1632px){
                            width: 1.50rem;
                            height: 1.50rem;
                        }
                        @media only screen and (min-width: 1200px) and (max-width: 1545px){
                            width: 1.30rem;
                            height: 1.30rem;
                        }
                        @media only screen and (min-width: 1200px) and (max-width: 1461px){
                            width: 1.10rem;
                            height: 1.10rem;
                        }
                        @media only screen and (min-width: 1200px) and (max-width: 1409px){
                            width: 1.00rem;
                            height: 1.00rem;
                        }
                    }
                    .ant-radio-checked {
                        width: 2rem;
                        height: 2rem;
                        @media only screen and (max-width: 1200px){
                            width: 1.70rem;
                            height: 1.70rem;
                        }
                        @media only screen and (max-width: 1062px){
                            width: 1.45rem;
                            height: 1.45rem;
                        }
                        @media only screen and (max-width: 931px){
                            width: 1.25rem;
                            height: 1.25rem;
                        }
                        @media only screen and (max-width: 878px){
                            width: 1.05rem;
                            height: 1.05rem;
                        }
                        @media only screen and (min-width: 1200px) and (max-width: 1758px){
                            width: 1.90rem;
                            height: 1.90rem;
                        }
                        @media only screen and (min-width: 1200px) and (max-width: 1718px){
                            width: 1.70rem;
                            height: 1.70rem;
                        }
                        @media only screen and (min-width: 1200px) and (max-width: 1632px){
                            width: 1.50rem;
                            height: 1.50rem;
                        }
                        @media only screen and (min-width: 1200px) and (max-width: 1545px){
                            width: 1.30rem;
                            height: 1.30rem;
                        }
                        @media only screen and (min-width: 1200px) and (max-width: 1461px){
                            width: 1.10rem;
                            height: 1.10rem;
                        }
                        @media only screen and (min-width: 1200px) and (max-width: 1409px){
                            width: 1.00rem;
                            height: 1.00rem;
                        }
                        
                        .ant-radio-input[datavalues="Low-Negative"] + .ant-radio-inner {
                            background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative']} !important;
                        }
                        .ant-radio-input[datavalues="Skeptical-Negative"] + .ant-radio-inner {
                            background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative']} !important;
                        }
                        .ant-radio-input[datavalues="Disagree-Negative"] + .ant-radio-inner {
                            background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FF5D5F" : theme['user-theme']['DisagreeNegative'] === undefined ? "#FF5D5F" : theme['user-theme']['DisagreeNegative']} !important;
                        }
                        .ant-radio-input[datavalues="Disagree-Positive"] + .ant-radio-inner {
                            background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#00B85C" : theme['user-theme']['DisagreePositive'] === undefined ? "#00B85C" : theme['user-theme']['DisagreePositive']} !important;
                        }
                        .ant-radio-input[datavalues="Optimistic-Positive"] + .ant-radio-inner {
                            background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#00B85C" : theme['user-theme']['DisagreePositive'] === undefined ? "#00B85C" : theme['user-theme']['DisagreePositive']} !important;
                        }
                        .ant-radio-input[datavalues="Critical"] + .ant-radio-inner {
                            background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative']} !important;
                        }
                        .ant-radio-input[datavalues="Low-Positive"] + .ant-radio-inner {
                            background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#00B85C" : theme['user-theme']['LowPositive'] === undefined ? "#00B85C" : theme['user-theme']['LowPositive']} !important;
                        }
                        .ant-radio-input[datavalues="Mid"] + .ant-radio-inner {
                            background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FFC533" : theme['user-theme']['MidColor'] === undefined ? "#FFC533" : theme['user-theme']['MidColor']} !important;
                        }
                        .ant-radio-input[datavalues="Neutral"] + .ant-radio-inner {
                            background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FFC533" : theme['user-theme']['NeutralColor'] === undefined ? "#FFC533" : theme['user-theme']['NeutralColor']} !important;
                        }
                        .ant-radio-input[datavalues="Primary"] + .ant-radio-inner {
                            background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FFC533" : theme['user-theme']['MidColor'] === undefined ? "#FFC533" : theme['user-theme']['MidColor']} !important;
                        }
                        .ant-radio-input[datavalues="High-Negative"] + .ant-radio-inner {
                            background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FF5D5F" : theme['user-theme']['HighNegative'] === undefined ? "#FF5D5F" : theme['user-theme']['HighNegative']} !important;
                        }
                        .ant-radio-input[datavalues="High-Positive"] + .ant-radio-inner {
                            background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#00B85C" : theme['user-theme']['HighPositive'] === undefined ? "#00B85C" : theme['user-theme']['HighPositive']} !important;
                        }
                        .ant-radio-input[datavalues="Secondary"] + .ant-radio-inner {
                            background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#00B85C" : theme['user-theme']['HighPositive'] === undefined ? "#00B85C" : theme['user-theme']['HighPositive']} !important;
                        }
                        .ant-radio-input[datavalues="Agree-Negative"] + .ant-radio-inner {
                            background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FF5D5F" : theme['user-theme']['AgreeNegative'] === undefined ? "#FF5D5F" : theme['user-theme']['AgreeNegative']} !important;
                        }
                        .ant-radio-input[datavalues="Agree-Positive"] + .ant-radio-inner {
                            background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#00B85C" : theme['user-theme']['AgreePositive'] === undefined ? "#00B85C" : theme['user-theme']['AgreePositive']} !important;
                        }
                    }
                }
            }
        }
    }
    .audience-risk-panel {
        padding-top: 10px;
        padding-left: 0.5rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        p {
            padding-top: 10px;
            font-size: 0.7rem;
            color: ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['buttonColor'] === undefined ? "#0066B2" : theme['user-theme']['buttonColor']} !important;
            svg {
                cursor: pointer;
            }
        }
    }
`;

const AudienceResistancePanelStyle = Styled.div`
    margin-bottom: 30px;
    //border: 1px solid #eeeeee;
    width: 100%;
    .search-panel {
        @media only screen and (max-width: 767px) {
            padding-top: 1rem;
            align-items: center;
            justify-content: center;
            margin-bottom: 2rem;
        }
    }
    .audience-panel {
        display: flex;
        flex-direction: column;
        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#BDE3FF" : theme['user-theme']['AudienceResistancePanelRadioGroupColor'] === undefined ? "#b2e7ff" : theme['user-theme']['AudienceResistancePanelRadioGroupColor']};
        border-radius: ${({ theme }) => theme['tab-border-global-radius'] === undefined ? "22px" : theme['tab-border-global-radius']} !important;
        // border: 1px solid ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['AudienceResistancePanelBorderColor'] === undefined ? "#0066B2" : theme['user-theme']['AudienceResistancePanelBorderColor']};
        .resistance-key-reason {
            background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#b2e7ff" : theme['user-theme']['AudienceResistancePanelColor'] === undefined ? "#b2e7ff" : theme['user-theme']['AudienceResistancePanelColor']};
            padding: 20px;
            .ant-checkbox-group-item {
                width: 20%;
                margin-bottom: 20px;
            }
        }
        .audience-info-panel {
            padding-top: 20px;
            background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#b2e7ff" : theme['user-theme']['AudienceResistancePanelColor'] === undefined ? "#b2e7ff" : theme['user-theme']['AudienceResistancePanelColor']};
            // border: 1px solid ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['AudienceResistancePanelBorderColor'] === undefined ? "#0066B2" : theme['user-theme']['AudienceResistancePanelBorderColor']};
            border-top-left-radius: ${({ theme }) => theme['tab-border-global-radius'] === undefined ? "22px" : theme['tab-border-global-radius']} !important;
            border-top-right-radius: ${({ theme }) => theme['tab-border-global-radius'] === undefined ? "22px" : theme['tab-border-global-radius']} !important;
            p.link_panel {
                font-size: 20px;
                cursor: pointer;
                color: ${({ theme }) => theme['user-theme'] === undefined ? "#b2e7ff" : theme['user-theme']['themeIconColor'] === undefined ? "#b2e7ff" : theme['user-theme']['themeIconColor']};
                svg {
                    cursor: pointer;
                }                
            }
            .audience-avatar {
                padding-left: 0.7rem;
                padding-top: 1rem;
            }
            .audience-info {
                padding-top: 10px;
                h2 {
                    color: #000 !important;
                    font-weight: 600;
                    margin-bottom: 0.1em;
                }
                p {
                    margin-bottom: 0.2em;
                }
            }
            .stakeholder-types {
                width: 100%;
                .ant-space-item {
                    margin-bottom: 0.5rem;
                    .ant-tag {
                        min-width: 90%;
                        max-width: 90%;
                        text-align: center;
                        font-size: 0.6rem;
                        padding: 4px 8px;
                        margin: 0;
                        white-space: pre-line;
                        background: ${({ theme }) => theme['user-theme'] === undefined ? "#FFFFFF" : theme['user-theme']['tagBackground'] === undefined ? "#FFFFFF" : theme['user-theme']['tagBackground']};
                        border: 1px solid ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['tagBorderColor'] === undefined ? "#0066B2" : theme['user-theme']['tagBorderColor']};
                    }
                    .ant-tag-checkable-checked {
                        background: ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['tagCheckedBackground'] === undefined ? "#0066B2" : theme['user-theme']['tagCheckedBackground']};
                        color: ${({ theme }) => theme['user-theme'] === undefined ? "#FFFFFF" : theme['user-theme']['tagCheckedColor'] === undefined ? "#FFFFFF" : theme['user-theme']['tagCheckedColor']};
                    }
                }
            }
        }
        .audience-info-panel-bottom {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 20px;
            background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#b2e7ff" : theme['user-theme']['AudienceResistancePanelColor'] === undefined ? "#b2e7ff" : theme['user-theme']['AudienceResistancePanelColor']};
            p.link_panel {
                font-size: 20px;
                cursor: pointer;
                color: ${({ theme }) => theme['user-theme'] === undefined ? "#b2e7ff" : theme['user-theme']['sidebarColor'] === undefined ? "#b2e7ff" : theme['user-theme']['sidebarColor']};
                svg {
                    cursor: pointer;
                }
            }
            .audience-avatar {
                padding-left: 0.7rem;
                padding-top: 1rem;
            }
            .audience-info {
                padding-top: 10px;
                h2 {
                    color: #000 !important;
                    font-weight: 600;
                    margin-bottom: 0.1em;
                }
                p {
                    margin-bottom: 0.2em;
                }
            }
            .stakeholder-types {
                width: 100%;
                .ant-space-item {
                    margin-bottom: 0.5rem;
                    .ant-tag {
                        min-width: 90%;
                        max-width: 90%;
                        text-align: center;
                        font-size: 0.6rem;
                        padding: 4px 8px;
                        margin: 0;
                        white-space: pre-line;
                        background: ${({ theme }) => theme['user-theme'] === undefined ? "#FFFFFF" : theme['user-theme']['tagBackground'] === undefined ? "#FFFFFF" : theme['user-theme']['tagBackground']};
                        border: 1px solid ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['tagBorderColor'] === undefined ? "#0066B2" : theme['user-theme']['tagBorderColor']};
                    }
                    .ant-tag-checkable-checked {
                        background: ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['tagCheckedBackground'] === undefined ? "#0066B2" : theme['user-theme']['tagCheckedBackground']};
                        color: ${({ theme }) => theme['user-theme'] === undefined ? "#FFFFFF" : theme['user-theme']['tagCheckedColor'] === undefined ? "#FFFFFF" : theme['user-theme']['tagCheckedColor']};
                    }
                }
            }
        }
    }
    .action-radio-group {
        padding: 20px;
    }
    .audience-action-panel {
        padding: ${(window.innerWidth >= 1200 && window.innerWidth > 1585) ? "1rem" : "0"};
        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#b2e7ff" : theme['user-theme']['AudienceResistancePanelRadioGroupColor'] === undefined ? "#b2e7ff" : theme['user-theme']['AudienceResistancePanelRadioGroupColor']};
        border-top-right-radius: ${({ theme }) => theme['tab-border-global-radius'] === undefined ? "22px" : theme['tab-border-global-radius']} !important;
        @media only screen and (max-width: 767px){
            padding-top: 1rem;
            .audience-risk-panel {
                margin-right: auto;
            }
        }
        .risk-status {
            text-align: center;
            font-size: 0.8rem;
            font-weight: 500;
            padding-left: 0.3rem;
            padding-right: 0.3rem;
            @media only screen and (min-width: 1200px) and (max-width: 1318px){
                padding-left: 0rem;
                padding-right: 0rem;
            }
            .ant-radio-group {
                padding-left: 0.7rem;
                @media only screen and (max-width: 878px){
                    padding-left: 0.1rem;
                }
                @media only screen and (min-width: 1200px) and (max-width: 1461px){
                    padding-left: 0.1rem;
                }
                label.ant-radio-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    span.ant-radio-inner {
                        margin-bottom: 10px;
                    }
                    span.ant-radio-checked {
                        margin-bottom: 10px;
                    }
                    .ant-radio-inner {
                        width: 2rem;
                        height: 2rem;
                        @media only screen and (max-width: 1200px){
                            width: 1.70rem;
                            height: 1.70rem;
                        }
                        @media only screen and (max-width: 1062px){
                            width: 1.45rem;
                            height: 1.45rem;
                        }
                        @media only screen and (max-width: 931px){
                            width: 1.25rem;
                            height: 1.25rem;
                        }
                        @media only screen and (max-width: 878px){
                            width: 1.05rem;
                            height: 1.05rem;
                        }
                        @media only screen and (min-width: 1200px) and (max-width: 1758px){
                            width: 1.90rem;
                            height: 1.90rem;
                        }
                        @media only screen and (min-width: 1200px) and (max-width: 1718px){
                            width: 1.70rem;
                            height: 1.70rem;
                        }
                        @media only screen and (min-width: 1200px) and (max-width: 1632px){
                            width: 1.50rem;
                            height: 1.50rem;
                        }
                        @media only screen and (min-width: 1200px) and (max-width: 1545px){
                            width: 1.30rem;
                            height: 1.30rem;
                        }
                        @media only screen and (min-width: 1200px) and (max-width: 1461px){
                            width: 1.10rem;
                            height: 1.10rem;
                        }
                        @media only screen and (min-width: 1200px) and (max-width: 1409px){
                            width: 1.00rem;
                            height: 1.00rem;
                        }
                        
                        background-color: #eee;
                        border: 1px solid #ccc;  
                    }
                    .ant-radio-checked {
                        width: 2rem;
                        height: 2rem;
                        @media only screen and (max-width: 1200px){
                            width: 1.70rem;
                            height: 1.70rem;
                        }
                        @media only screen and (max-width: 1062px){
                            width: 1.45rem;
                            height: 1.45rem;
                        }
                        @media only screen and (max-width: 931px){
                            width: 1.25rem;
                            height: 1.25rem;
                        }
                        @media only screen and (max-width: 878px){
                            width: 1.05rem;
                            height: 1.05rem;
                        }
                        @media only screen and (min-width: 1200px) and (max-width: 1758px){
                            width: 1.90rem;
                            height: 1.90rem;
                        }
                        @media only screen and (min-width: 1200px) and (max-width: 1718px){
                            width: 1.70rem;
                            height: 1.70rem;
                        }
                        @media only screen and (min-width: 1200px) and (max-width: 1632px){
                            width: 1.50rem;
                            height: 1.50rem;
                        }
                        @media only screen and (min-width: 1200px) and (max-width: 1545px){
                            width: 1.30rem;
                            height: 1.30rem;
                        }
                        @media only screen and (min-width: 1200px) and (max-width: 1461px){
                            width: 1.10rem;
                            height: 1.10rem;
                        }
                        @media only screen and (min-width: 1200px) and (max-width: 1409px){
                            width: 1.00rem;
                            height: 1.00rem;
                        }
                        
                        .ant-radio-input[datavalues="Low-Negative"] + .ant-radio-inner {
                            background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative']} !important;
                        }
                        .ant-radio-input[datavalues="Skeptical-Negative"] + .ant-radio-inner {
                            background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative']} !important;
                        }
                        .ant-radio-input[datavalues="Disagree-Negative"] + .ant-radio-inner {
                            background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FF5D5F" : theme['user-theme']['DisagreeNegative'] === undefined ? "#FF5D5F" : theme['user-theme']['DisagreeNegative']} !important;
                        }
                        .ant-radio-input[datavalues="Disagree-Positive"] + .ant-radio-inner {
                            background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#00B85C" : theme['user-theme']['DisagreePositive'] === undefined ? "#00B85C" : theme['user-theme']['DisagreePositive']} !important;
                        }
                        .ant-radio-input[datavalues="Optimistic-Positive"] + .ant-radio-inner {
                            background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#00B85C" : theme['user-theme']['DisagreePositive'] === undefined ? "#00B85C" : theme['user-theme']['DisagreePositive']} !important;
                        }
                        .ant-radio-input[datavalues="Critical"] + .ant-radio-inner {
                            background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative']} !important;
                        }
                        .ant-radio-input[datavalues="Low-Positive"] + .ant-radio-inner {
                            background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#00B85C" : theme['user-theme']['LowPositive'] === undefined ? "#00B85C" : theme['user-theme']['LowPositive']} !important;
                        }
                        .ant-radio-input[datavalues="Mid"] + .ant-radio-inner {
                            background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FFC533" : theme['user-theme']['MidColor'] === undefined ? "#FFC533" : theme['user-theme']['MidColor']} !important;
                        }
                        .ant-radio-input[datavalues="Neutral"] + .ant-radio-inner {
                            background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FFC533" : theme['user-theme']['NeutralColor'] === undefined ? "#FFC533" : theme['user-theme']['NeutralColor']} !important;
                        }
                        .ant-radio-input[datavalues="Primary"] + .ant-radio-inner {
                            background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FFC533" : theme['user-theme']['MidColor'] === undefined ? "#FFC533" : theme['user-theme']['MidColor']} !important;
                        }
                        .ant-radio-input[datavalues="High-Negative"] + .ant-radio-inner {
                            background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FF5D5F" : theme['user-theme']['HighNegative'] === undefined ? "#FF5D5F" : theme['user-theme']['HighNegative']} !important;
                        }
                        .ant-radio-input[datavalues="High-Positive"] + .ant-radio-inner {
                            background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#00B85C" : theme['user-theme']['HighPositive'] === undefined ? "#00B85C" : theme['user-theme']['HighPositive']} !important;
                        }
                        .ant-radio-input[datavalues="Secondary"] + .ant-radio-inner {
                            background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#00B85C" : theme['user-theme']['HighPositive'] === undefined ? "#00B85C" : theme['user-theme']['HighPositive']} !important;
                        }
                        .ant-radio-input[datavalues="Agree-Negative"] + .ant-radio-inner {
                            background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FF5D5F" : theme['user-theme']['AgreeNegative'] === undefined ? "#FF5D5F" : theme['user-theme']['AgreeNegative']} !important;
                        }
                        .ant-radio-input[datavalues="Agree-Positive"] + .ant-radio-inner {
                            background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#00B85C" : theme['user-theme']['AgreePositive'] === undefined ? "#00B85C" : theme['user-theme']['AgreePositive']} !important;
                        }
                    }
                }
            }
        }
    }
    .audience-risk-panel {
        padding-top: 30px;
        text-align: center;
        p {
            padding-top: 10px;
            font-size: 0.7rem;
            color: ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['buttonColor'] === undefined ? "#0066B2" : theme['user-theme']['buttonColor']} !important;
            svg {
                cursor: pointer;
            }
        }
    }
`;

const CommunicationStyle = Styled.div`
.space-button-group
{
    padding: 15px;
    text-align:center;
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner
{
    width: 40px;
    height: 40px;
    background-color: #70FFB8;
    border-color: #70FFB8;
} 
.ant-radio-wrapper .ant-radio-inner
{
    width: 40px;
    height: 40px;
} 
.action-communication svg
{
    margin-right: 4px;
}
.status_communication p
{
    line-height: 10px;
    text-align: center;
    font-size: 11px;
    background: red;
    padding: 4px;
    color: white !important;
    margin-bottom: 0px;
    // width: 97%;
    width: 150px;
    // margin: 0 auto;
    border-radius: 5px 5px 0px 0px;
}

.ready .ant-select-selector
{
    background-color:#9AFFCC !important;
}
.sent {
    .anticon svg {
        color: #fff !important;
    }
}
.sent .ant-select-selector
{
    color: #fff !important;
    text-align: center;
    background: ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['buttonColor'] === undefined ? "#0066B2" : theme['user-theme']['buttonColor']} !important;
    &:after {
        color: #fff !important;
    }
}
.approval .ant-select-selector
{
    background-color:#FFD04B !important;
}
.draft .ant-select-selector
{
    background-color:#DAD9D9 !important;
    color:#fff !important;
}
.draft .ant-select-selector .ant-select-selection-item
{
    color: white !important;
}


.ant-btn
{
    padding:4px 35px;
}
.ant-btn-primary
{
    background:#00B0F0;
}
.ant-table-thead > tr > th
{
    background-color: #fff;
    border-color: #fff;
    color: #01B0F0;
}
// .ant-table-cell:has(.delivery_date),.ant-table-cell:has(.delivery_date):hover
// {
//     background:#01CC66 !important;
//     color:#fff !important;
//     text-align:center !important;
// }
.ant-table-tbody > tr > td
{
    background-color: #fff;
    border-color: #fff;
    padding: 10px !important;
    color:#00B0F0;
}
.communication_name h2
{
    font-size: 15px;
    font-weight: bold;
    line-height: 17px;
}
.communication_name
{
    color:#C7C7C7;
}
.communication_name span
{
    color:#00B0F0;
}
.ant-select
{
    width: 150px;
    
}
.avg_score div
{
    width: 100px;
    text-align:center;
    padding: 10px 0px;
    margin: 0 auto;
}
.avg_score p
{
    line-height: 1px;
    text-align:center;
}
.avg_score .high
{
    background-color:#33FF99 !important;
    color:#000 !important;

}
.avg_score .mid 
{
    background-color:#FFD04B !important;
    color:#000 !important;
}
.avg_score .low
{
    background-color:#FF626E !important;
    color:#fff !important;
}
.avg_score .pending
{
    background-color:#F2F2F2 !important;
    color:#C4C4C4;
}
.pending .ant-select .ant-select-selector .ant-select-selection-item
{
    color:#C4C4C4;
}
.low .ant-select-selector .ant-select-selection-item
{
    color: white !important;
}
`

const ImpectLevelStyle = Styled.div`
    .ant-radio-wrapper::after {
        display: none !important;
        width: 0 !important;
        overflow: hidden !important;
        content: "" !important;
    }
    .ant-card
    {
        box-shadow: none !important;
        
        border: 1px solid ${({ theme }) => theme['tool-info-theme']['primary_color']} !important;
        border-radius: 10px !important;
    }
    .ant-card-head
    {
        border-radius: 10px 10px 0px 0px;
        min-height: 20px !important;
        border-bottom: none !important;
        background: ${({ theme }) => theme['tool-info-theme']['primary_color']} !important;
    }
    .ant-card-head-title
    {
        padding: 8px 0px !important;
        margin: 0 auto !important;
        text-align: center !important;
        color: white !important;
    }
    .ant-card-body
    {
        padding: 20px !important;
    }
    .box-impact
    {
        margin: 0 auto;
        padding: 10px;
        font-size: 12px;
        border-radius: 10px;
        text-align:center;
        border: 1px solid ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['ToolTitleColor'] === undefined ? "#0066B2" : theme['user-theme']['ToolTitleColor']}
    }
    .text-blue
    {
        color:${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['ToolTitleColor'] === undefined ? "#0066B2" : theme['user-theme']['ToolTitleColor']}
    }
`

const RiskStyleDiv = Styled.div`
    margin-top: 20px;
    .ant-table-wrapper .ant-table-column-sorter-up.active svg,.ant-table-wrapper .ant-table-column-sorter-down.active svg
    {
        color: white !important;
        font-weight:bold;
    }
    .toolRiskPhaseTabStyle{
        .ant-tabs-nav-list
        {
            width: 100%;
            margin-top: 20px;
            margin-bottom: 30px;
        }
        .ant-tabs-nav-list .ant-tabs-tab
        {
            text-align:center !important;
            margin: 0 auto  !important;
            min-height: 32px !important;
            min-width: 32% !important;
            border: 1px solid rgb(204, 204, 204);
            overflow-wrap: break-word;
            white-space: pre-wrap;
            display: flex;
            -moz-box-pack: center;
            justify-content: center;
            -moz-box-align: center;
            align-items: center;
            text-align: center;
            border-top-left-radius: 22px !important;
            border-top-right-radius: 22px !important;
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
        .ant-tabs-nav-list .ant-tabs-ink-bar
        {
            height: 2px !important;
        }
        .ant-tabs-tab-active
        {
            background: ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['ToolTitleColor'] === undefined ? "#0066B2" : theme['user-theme']['ToolTitleColor']};!important;
            color: white !important;
        }
        .ant-tabs-content-holder .ant-tabs-tabpane
        {
            padding: 0px 0px
        }
        .ant-tabs-nav-list
        {
            border-bottom: none !important;
        }
        .ant-tabs-content-holder
        {
            border: none;
            border-top:2px solid${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['ToolTitleColor'] === undefined ? "#0066B2" : theme['user-theme']['ToolTitleColor']};
        }
    }
    .ant-table-column-sorter-up svg,.ant-table-column-sorter-down svg
    {
        color: #A1A1A1 !important
    }

    td.ant-table-cell:has(.red-box),td.ant-table-cell:has(.red-box):hover
    {
        background:#ED6C72 !important;
        color: white !important;
        text-align:center;
        font-weight:bold;
    }

    td.ant-table-cell:has(.green-box), td.ant-table-cell:has(.green-box):hover
    {
        background:#5CC971 !important;
        color: white !important;
        text-align:center;
        font-weight:bold;
    }

    td.ant-table-cell:has(.yellow-box), td.ant-table-cell:has(.yellow-box):hover
    {
        background:#F5C342 !important;
        color: white !important;
        font-weight:bold;
        text-align:center;
    }

    td.ant-table-cell:has(.pending-box), td.ant-table-cell:has(.pending-box):hover
    {
        background:#E7E6E6 !important;
        color: #999999 !important;
        font-weight:bold;
        text-align:center;
    }
    tr td:first-child
    {
        max-width:20%;
    }
    .ant-table-thead > tr > th
    {
        border: 1px solid white !important;
        color: white !important;
        background: ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['ToolTitleColor'] === undefined ? "#0066B2" : theme['user-theme']['ToolTitleColor']} !important;
    }
    th
    {
        border: 1px solid white;
        padding: 10px 3px;
        background: ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['ToolTitleColor'] === undefined ? "#0066B2" : theme['user-theme']['ToolTitleColor']};
    }
    th
    {
        color:white;
        border: 1px solid white;
        padding: 10px;
    }
    td
    {
        border: 1px solid ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['ToolTitleColor'] === undefined ? "#0066B2" : theme['user-theme']['ToolTitleColor']};
        padding: 10px;
        
    }
    .box-yes
    {
        padding: 3px 0px;
        background: green;
        width: 42px;
        text-align: center;
        color: white;
        margin-left: -15px;
    }
    .box-no
    {
        padding: 3px 0px;
        background: red;
        width: 42px;
        text-align: center;
        color: white;
        margin-left: -15px;
    }
    .Mid {
        background-color: #FFC000 !important;
    }
    .Primary {
        background-color: #FFC000 !important;
    }
    .HighPositive {
        background-color: #00CC66 !important;
    }
    .Secondary {
        background-color: #00CC66 !important;
    }
    .LowPositive {
        background-color: #00CC66 !important;
    }
    .Critical {
        background-color: #FF626E !important;
    }
    .HighNegative {
        background-color: #FF626E !important;
    }
    .LowNegative {
        background-color: #FF626E !important;
    }
    .Pending {
        background-color: #E7E6E6 !important;
    }
    .MasterScore {
        display: flex;
        flex-direction: column;
        flex-direction: revert;
        align-items: center;
        width: 100%;
        padding: 0.1rem;
    }
    .scoreTitle {
        background-color: #FFFFFF;
        padding: 0.5rem;
        font-size: 1.2rem;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        flex-direction: revert;
        align-items: center;
        width: 70%;
    }
    .typeTitle {
        background-color: transparent;
        padding: 0.5rem;
        font-size: 1.1rem;
        color: #FFFFFF;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        flex-direction: revert;
        align-items: center;
        justify-content: center;
        width: 30%;
    }

    .ant-alert .MasterScore .typeTitle
    {
        font-size: 15px !important;
        white-space: nowrap;
    }
    .ant-alert .MasterScore .scoreTitle
    {
        white-space: nowrap;
    }
    .ant-tag-success {
        color: #52c41a !important;
        border-color: #b7eb8f !important;
    }
    .ant-tag-warning {
        color: #faad14 !important;
        background: #fffbe6 !important;
        border-color: #ffe58f !important;
    }
    .ant-tag-error {
        color: #ff4d4f !important;
        background: #fff2f0 !important;
        border-color: #ffccc7 !important;
    }
`

const TrainingDiv = Styled.div`
    .ant-btn-ocm-default
    {
        background: ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['buttonColor'] === undefined ? "#0066B2" : theme['user-theme']['buttonColor']} !important;
    }
    .ant-btn-ocm-default[disabled=""] {
        opacity: 0.5;
    }
    .color-training-blue, .ant-card-body .color-training-blue
    {
        color:#00B0F0 !important;
    }
    .toolTrainingPhaseTabStyle {
        .ant-tabs-nav-list
        {
            width: 100%;
            margin-top: 20px;
            margin-bottom: 30px;
        }
        .ant-tabs-nav-list .ant-tabs-tab
        {
            text-align:center !important;
            margin: 0 auto  !important;
            min-height: 32px !important;
            min-width: 49% !important;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            display: flex;
            -moz-box-pack: center;
            justify-content: center;
            -moz-box-align: center;
            align-items: center;
            text-align: center;
            border-top-left-radius: 22px !important;
            border-top-right-radius: 22px !important;
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
        .ant-tabs-nav-list .ant-tabs-ink-bar
        {
            height: 2px !important;
        }
        .ant-tabs-tab-active
        {
            background: ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['ToolTitleColor'] === undefined ? "#0066B2" : theme['user-theme']['ToolTitleColor']};!important;
            color: white !important;
        }
        .ant-tabs-content-holder .ant-tabs-tabpane
        {
            padding: 0px 0px
        }
        .ant-tabs-nav-list
        {
            border-bottom: none !important;
        }
        .ant-tabs-content-holder
        {
            border: none;
        }
    }

    .circle-button .ant-btn-icon
    {
        bottom: 0px !important;
    }
    .infinite-scroll-component__outerdiv
    {
        width: 100%;
    }
    .loader {
        border: 8px solid #f3f3f3;
        border-top: 8px solid #3498db;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        animation: spin 1s linear infinite;
    }
  
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
    .date-box
    {
        width: 100%;
        height: 33px;
        margin-bottom: 4px;
        border-radius: 5px;
        vertical-align: middle;
        text-align: center;
        background: rgb(147, 226, 255);
        padding-top: 5px;
        white-space: nowrap;
    }
    .count-box
    {
        width: 100%;
        height: 50px;
        border-radius: 5px;
        vertical-align: middle;
        text-align:center;
        color:white;
        padding-top:5px;

    }
    .number-count
    {
        font-size: 17px;
        line-height: 22px;
        text-decoration: underline;
    }
    .number-text
    {
        font-size: 10px;
        line-height: 16px;
        white-space:nowrap;
    }
    .yellow-back
    {
        background:#FFC111;
    }
    .default-back
    {
        background:#83A1D7;
    }
    .green-back
    {
        background:#00CC66;
    }
    .red-back
    {
        background:#FF626E;
    }
    .ant-btn-icon {
        background: transparent;
        font-size: 20px;
        font-weight: 600;
    }
    .iconAction {
        .action-icon-panel {
            @media only screen and (max-width: 767px) {
                padding-top: 1rem;
                align-items: center;
                justify-content: center;
                margin-bottom: 1rem;
            }
        }
        .ant-segmented {
            background: ${({ theme }) => theme['user-theme'] === undefined ? "#FFFFFF" : theme['user-theme']['segmentBackground'] === undefined ? "#FFFFFF" : theme['user-theme']['segmentBackground']} !important;
        }
        .ant-segmented-item:hover::after {
            background: transparent !important;
        }
        .ant-segmented-item:hover {
            svg{
                --fa-primary-color: #00A3DE !important;
                --fa-secondary-color: #3BCBFF !important;
            }
        }
    }

    .font-size-small
    {
        font-size: 11px;
    }
    .ant-popover-inner
    {
        background: white !important;
    }
    .text-align-center
    {
        text-align:center;
    }
    .course-rating-box
    {
        background: #E8DAF6;
        border-radius: 22px;
        margin-top: 7px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        // overflow:scroll;
    }
    .training-rating-box
    {
        padding: 0px 15px !important;
    }
    .training-name-box
    {
        background: rgb(143, 74, 211);
        border-radius: 22px;
        padding: 9px;
        white-space: normal;
        text-overflow: clip;
        color: white;
        text-align: center;
    }
}
    .stakeholder-types {
        width: 100%;
        .ant-space-item {
            margin-bottom: 0.5rem;
            .ant-tag {
                min-width: 90%;
                max-width: 90%;
                text-align: center;
                font-size: 0.6rem;
                padding: 4px 8px;
                margin: 0;
                white-space: pre-line;
                background: ${({ theme }) => theme['user-theme'] === undefined ? "#FFFFFF" : theme['user-theme']['tagBackground'] === undefined ? "#FFFFFF" : theme['user-theme']['tagBackground']};
                border: 1px solid ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['tagBorderColor'] === undefined ? "#0066B2" : theme['user-theme']['tagBorderColor']};
            }
            .ant-tag-checkable-checked {
                background: ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['tagCheckedBackground'] === undefined ? "#0066B2" : theme['user-theme']['tagCheckedBackground']};
                color: ${({ theme }) => theme['user-theme'] === undefined ? "#FFFFFF" : theme['user-theme']['tagCheckedColor'] === undefined ? "#FFFFFF" : theme['user-theme']['tagCheckedColor']};
            }
        }
    }

    .box-training
    {
        background-color: rgb(235, 250, 255);
        border: 1px solid rgb(204, 204, 204);
        padding: 20px;
        border-radius: 22px !important;
        min-height: 383px;
        margin-top:10px;
    }
    th
    {
        border: 1px solid white;
        padding: 10px 3px;
        background: ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['ToolTitleColor'] === undefined ? "#0066B2" : theme['user-theme']['ToolTitleColor']};
    }
    th
    {
        color:white;
        border: 1px solid white;
        padding: 10px;
    }
    td
    {
        border: 1px solid ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['ToolTitleColor'] === undefined ? "#0066B2" : theme['user-theme']['ToolTitleColor']};
        padding: 10px;
        
    }
    .box-yes
    {
        padding: 3px 0px;
        background: green;
        width: 42px;
        text-align: center;
        color: white;
        margin-left: -15px;
    }
    .box-no
    {
        padding: 3px 0px;
        background: red;
        width: 42px;
        text-align: center;
        color: white;
        margin-left: -15px;
    }
    .Mid {
        background-color: #FFC000 !important;
    }
    .Primary {
        background-color: #FFC000 !important;
    }
    .HighPositive {
        background-color: #00CC66 !important;
    }
    .Secondary {
        background-color: #00CC66 !important;
    }
    .LowPositive {
        background-color: #00CC66 !important;
    }
    .Critical {
        background-color: #FF626E !important;
    }
    .HighNegative {
        background-color: #FF626E !important;
    }
    .LowNegative {
        background-color: #FF626E !important;
    }
    .Pending {
        background-color: #E7E6E6 !important;
    }
    .MasterScore {
        display: flex;
        flex-direction: column;
        flex-direction: revert;
        align-items: center;
        width: 100%;
        padding: 0.1rem;
    }
    .scoreTitle {
        background-color: #FFFFFF;
        padding: 0.5rem;
        font-size: 1.2rem;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        flex-direction: revert;
        align-items: center;
        width: 70%;
    }
    .typeTitle {
        background-color: transparent;
        padding: 0.5rem;
        font-size: 1.1rem;
        color: #FFFFFF;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        flex-direction: revert;
        align-items: center;
        justify-content: center;
        width: 30%;
    }
    .ant-alert .MasterScore .typeTitle
    {
        font-size: 15px !important;
        white-space: nowrap;
    }
    .ant-alert .MasterScore .scoreTitle
    {
        white-space: nowrap;
    }
    .ant-tag-success {
        color: #52c41a !important;
        border-color: #b7eb8f !important;
    }
    .ant-tag-warning {
        color: #faad14 !important;
        background: #fffbe6 !important;
        border-color: #ffe58f !important;
    }
    .ant-tag-error {
        color: #ff4d4f !important;
        background: #fff2f0 !important;
        border-color: #ffccc7 !important;
    }

`

const RadioPanelStyleImpect = Styled.div`
    .risk-status {
        padding-right: 0.3rem;
        text-align: center;
        font-size: 0.8rem;
        font-weight: 500;
        .ant-radio-group {
            label.ant-radio-wrapper {
                display: inline-block;
                .ant-radio-inner {
                    width: 1.6rem;
                    height: 1.6rem;
                    background-color: #eee;
                    border: 1px solid #eee;
                    @media only screen and (max-width: 1280px){
                        width: 1.3rem;
                        height: 1.3rem;
                    }
                    @media only screen (max-width: 1920px){
                        width: 1.7rem;
                        height: 1.7rem;
                    }   
                    
                }
                .ant-radio-checked {
                    width: 1.6rem;
                    height: 1.6rem;
                    @media only screen and (max-width: 1300px){
                        width: 1.3rem;
                        height: 1.3rem;
                    }
                   
                    @media only screen (max-width: 1920px){
                        width: 1.7rem;
                        height: 1.7rem;
                    }
                    .ant-radio-input[datavalues="Low-Negative"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative']} !important;
                        border: 1px solid ${({ theme }) => theme['user-theme'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative']} !important;
                    }
                    .ant-radio-input[datavalues="Skeptical-Negative"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative']} !important;
                    }
                    .ant-radio-input[datavalues="Low-Positive"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#00B85C" : theme['user-theme']['HighPositive'] === undefined ? "#00B85C" : theme['user-theme']['HighPositive']} !important;
                        border: 1px solid ${({ theme }) => theme['user-theme'] === undefined ? "#00B85C" : theme['user-theme']['HighPositive'] === undefined ? "#00B85C" : theme['user-theme']['HighPositive']} !important;
                    }
                    .ant-radio-input[datavalues="Optimistic-Positive"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#00B85C" : theme['user-theme']['DisagreePositive'] === undefined ? "#00B85C" : theme['user-theme']['DisagreePositive']} !important;
                    }
                    .ant-radio-input[datavalues="Mid"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FFC533" : theme['user-theme']['MidColor'] === undefined ? "#FFC533" : theme['user-theme']['MidColor']} !important;
                        border: 1px solid ${({ theme }) => theme['user-theme'] === undefined ? "#FFC533" : theme['user-theme']['MidColor'] === undefined ? "#FFC533" : theme['user-theme']['MidColor']} !important;
                    }
                    .ant-radio-input[datavalues="High-Positive"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#00B85C" : theme['user-theme']['HighPositive'] === undefined ? "#00B85C" : theme['user-theme']['HighPositive']} !important;
                        border: 1px solid ${({ theme }) => theme['user-theme'] === undefined ? "#00B85C" : theme['user-theme']['HighPositive'] === undefined ? "#00B85C" : theme['user-theme']['HighPositive']} !important;
                    }
                    .ant-radio-input[datavalues="High-Negative"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative']} !important;
                        border: 1px solid ${({ theme }) => theme['user-theme'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative']} !important;
                    }
                }
            }
        }
    }
`;

const RadioPanelStyleTraining = Styled.div`
.ant-radio-wrapper
{
    margin-inline-end: 0px !important;
}
.ant-radio-wrapper .ant-radio-inner
{
    width: 28px !important;
    height: 28px;
    background-color: #eee;
    border: 1px solid #ccc;
}
.radio-group-div
{ 
    width: 33%;
    float:left;
}
.ant-radio-checked
{
    width: 28px;
    height: 28px;

    .ant-radio-input[datavalues="Pending"] + .ant-radio-inner {
        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FFC533" : theme['user-theme']['MidColor'] === undefined ? "#FFC533" : theme['user-theme']['MidColor']} !important;
    }
    .ant-radio-input[datavalues="Failed-Negative"] + .ant-radio-inner {
        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FF5D5F" : theme['user-theme']['HighNegative'] === undefined ? "#FF5D5F" : theme['user-theme']['HighNegative']} !important;
    }
    .ant-radio-input[datavalues="Passed-Positive"] + .ant-radio-inner {
        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#00B85C" : theme['user-theme']['LowPositive'] === undefined ? "#00B85C" : theme['user-theme']['LowPositive']} !important;
    }
    .ant-radio-input[datavalues="Low-Negative"] + .ant-radio-inner {
        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative']} !important;
        border: 1px solid #ccc;
    }
    .ant-radio-input[datavalues="Optimistic-Positive"] + .ant-radio-inner {
        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#00B85C" : theme['user-theme']['DisagreePositive'] === undefined ? "#00B85C" : theme['user-theme']['DisagreePositive']} !important;
    }
    .ant-radio-input[datavalues="Skeptical-Negative"] + .ant-radio-inner {
        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative']} !important;
    }
    .ant-radio-input[datavalues="Critical"] + .ant-radio-inner {
        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative']} !important;
    }
    .ant-radio-input[datavalues="Low-Positive"] + .ant-radio-inner {
        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#00B85C" : theme['user-theme']['LowPositive'] === undefined ? "#00B85C" : theme['user-theme']['LowPositive']} !important;
    }
    .ant-radio-input[datavalues="Mid"] + .ant-radio-inner {
        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FFC533" : theme['user-theme']['MidColor'] === undefined ? "#FFC533" : theme['user-theme']['MidColor']} !important;
    }
    .ant-radio-input[datavalues="Primary"] + .ant-radio-inner {
        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FFC533" : theme['user-theme']['MidColor'] === undefined ? "#FFC533" : theme['user-theme']['MidColor']} !important;
    }
    .ant-radio-input[datavalues="High-Negative"] + .ant-radio-inner {
        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FF5D5F" : theme['user-theme']['HighNegative'] === undefined ? "#FF5D5F" : theme['user-theme']['HighNegative']} !important;
    }
    .ant-radio-input[datavalues="High-Positive"] + .ant-radio-inner {
        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#00B85C" : theme['user-theme']['HighPositive'] === undefined ? "#00B85C" : theme['user-theme']['HighPositive']} !important;
    }
    .ant-radio-input[datavalues="Secondary"] + .ant-radio-inner {
        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#00B85C" : theme['user-theme']['HighPositive'] === undefined ? "#00B85C" : theme['user-theme']['HighPositive']} !important;
    }

}
`;

const RadioPanelStyle = Styled.div`
    width: 100%;
    .risk-status {
        .ant-radio-group {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            label {
                width: 33%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                // white-space: nowrap;
                // overflow: hidden;
                // text-overflow: ellipsis;
            }
        }
        padding-right: 0.3rem;
        text-align: center;
        font-size: 0.8rem;
        font-weight: 500;
        .ant-radio-group {
            label.ant-radio-wrapper {
                display: inline-block;
                .ant-radio-inner {
                    width: 2rem;
                    height: 2rem;
                    background-color: #eee;
                    border: 1px solid #ccc;
                    @media only screen and (max-width: 1200px){
                        width: 1.70rem;
                        height: 1.70rem;
                    }
                    @media only screen and (max-width: 1062px){
                        width: 1.45rem;
                        height: 1.45rem;
                    }
                    @media only screen and (max-width: 931px){
                        width: 1.25rem;
                        height: 1.25rem;
                    }
                    @media only screen and (max-width: 878px){
                        width: 1.05rem;
                        height: 1.05rem;
                    }
                    @media only screen and (min-width: 1200px) and (max-width: 1758px){
                        width: 1.90rem;
                        height: 1.90rem;
                    }
                    @media only screen and (min-width: 1200px) and (max-width: 1718px){
                        width: 1.70rem;
                        height: 1.70rem;
                    }
                    @media only screen and (min-width: 1200px) and (max-width: 1632px){
                        width: 1.50rem;
                        height: 1.50rem;
                    }
                    @media only screen and (min-width: 1200px) and (max-width: 1545px){
                        width: 1.30rem;
                        height: 1.30rem;
                    }
                    @media only screen and (min-width: 1200px) and (max-width: 1461px){
                        width: 1.10rem;
                        height: 1.10rem;
                    }
                    @media only screen and (min-width: 1200px) and (max-width: 1409px){
                        width: 1.00rem;
                        height: 1.00rem;
                    }
                    
                }
                .ant-radio-checked {
                    width: 2rem;
                    height: 2rem;
                    @media only screen and (max-width: 1200px){
                        width: 1.70rem;
                        height: 1.70rem;
                    }
                    @media only screen and (max-width: 1062px){
                        width: 1.45rem;
                        height: 1.45rem;
                    }
                    @media only screen and (max-width: 931px){
                        width: 1.25rem;
                        height: 1.25rem;
                    }
                    @media only screen and (max-width: 878px){
                        width: 1.05rem;
                        height: 1.05rem;
                    }
                    @media only screen and (min-width: 1200px) and (max-width: 1758px){
                        width: 1.90rem;
                        height: 1.90rem;
                    }
                    @media only screen and (min-width: 1200px) and (max-width: 1718px){
                        width: 1.70rem;
                        height: 1.70rem;
                    }
                    @media only screen and (min-width: 1200px) and (max-width: 1632px){
                        width: 1.50rem;
                        height: 1.50rem;
                    }
                    @media only screen and (min-width: 1200px) and (max-width: 1545px){
                        width: 1.30rem;
                        height: 1.30rem;
                    }
                    @media only screen and (min-width: 1200px) and (max-width: 1461px){
                        width: 1.10rem;
                        height: 1.10rem;
                    }
                    @media only screen and (min-width: 1200px) and (max-width: 1409px){
                        width: 1.00rem;
                        height: 1.00rem;
                    }
                    .ant-radio-input[datavalues="Low-Negative"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative']} !important;
                        border: 1px solid #ccc;
                    }
                    .ant-radio-input[datavalues="Skeptical-Negative"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative']} !important;
                    }
                    .ant-radio-input[datavalues="Disagree-Negative"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FF5D5F" : theme['user-theme']['DisagreeNegative'] === undefined ? "#FF5D5F" : theme['user-theme']['DisagreeNegative']} !important;
                    }
                    .ant-radio-input[datavalues="Disagree-Positive"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#00B85C" : theme['user-theme']['DisagreePositive'] === undefined ? "#00B85C" : theme['user-theme']['DisagreePositive']} !important;
                    }
                    .ant-radio-input[datavalues="Optimistic-Positive"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#00B85C" : theme['user-theme']['DisagreePositive'] === undefined ? "#00B85C" : theme['user-theme']['DisagreePositive']} !important;
                    }
                    .ant-radio-input[datavalues="Critical"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative']} !important;
                    }
                    .ant-radio-input[datavalues="Low-Positive"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#00B85C" : theme['user-theme']['LowPositive'] === undefined ? "#00B85C" : theme['user-theme']['LowPositive']} !important;
                    }
                    .ant-radio-input[datavalues="Mid"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FFC533" : theme['user-theme']['MidColor'] === undefined ? "#FFC533" : theme['user-theme']['MidColor']} !important;
                    }
                    .ant-radio-input[datavalues="Neutral"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FFC533" : theme['user-theme']['NeutralColor'] === undefined ? "#FFC533" : theme['user-theme']['NeutralColor']} !important;
                    }
                    .ant-radio-input[datavalues="Primary"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FFC533" : theme['user-theme']['MidColor'] === undefined ? "#FFC533" : theme['user-theme']['MidColor']} !important;
                    }
                    .ant-radio-input[datavalues="High-Negative"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FF5D5F" : theme['user-theme']['HighNegative'] === undefined ? "#FF5D5F" : theme['user-theme']['HighNegative']} !important;
                    }
                    .ant-radio-input[datavalues="High-Positive"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#00B85C" : theme['user-theme']['HighPositive'] === undefined ? "#00B85C" : theme['user-theme']['HighPositive']} !important;
                    }
                    .ant-radio-input[datavalues="Secondary"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#00B85C" : theme['user-theme']['HighPositive'] === undefined ? "#00B85C" : theme['user-theme']['HighPositive']} !important;
                    }
                    .ant-radio-input[datavalues="Agree-Negative"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FF5D5F" : theme['user-theme']['AgreeNegative'] === undefined ? "#FF5D5F" : theme['user-theme']['AgreeNegative']} !important;
                    }
                    .ant-radio-input[datavalues="Agree-Positive"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#00B85C" : theme['user-theme']['AgreePositive'] === undefined ? "#00B85C" : theme['user-theme']['AgreePositive']} !important;
                    }
                }
            }
        }
    }
    .two-column {
        .ant-radio-group {
            label {
                width: 24% !important;
            }
        }
    }
`;

const ReadinessRadioPanelStyle = Styled.div`
    width: 100%;
    .radio-title {
        font-size: 0.8rem;
        font-weight: 500;
        padding-right: 0.7rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .risk-status {
        display: flex;
        justify-content: left;
        .ant-radio-group {
            label.ant-radio-wrapper {
                display: inline-block;
                .ant-radio-inner {
                    width: 1.5rem;
                    height: 1.5rem;
                    background-color: #eee;
                    border: 1px solid #ccc;
                    @media only screen and (max-width: 1200px){
                        width: 1.70rem;
                        height: 1.70rem;
                    }
                    @media only screen and (max-width: 1062px){
                        width: 1.45rem;
                        height: 1.45rem;
                    }
                    @media only screen and (max-width: 931px){
                        width: 1.25rem;
                        height: 1.25rem;
                    }
                    @media only screen and (max-width: 878px){
                        width: 1.05rem;
                        height: 1.05rem;
                    }
                    @media only screen and (min-width: 1200px) and (max-width: 1758px){
                        width: 1.90rem;
                        height: 1.90rem;
                    }
                    @media only screen and (min-width: 1200px) and (max-width: 1718px){
                        width: 1.70rem;
                        height: 1.70rem;
                    }
                    @media only screen and (min-width: 1200px) and (max-width: 1632px){
                        width: 1.50rem;
                        height: 1.50rem;
                    }
                    @media only screen and (min-width: 1200px) and (max-width: 1545px){
                        width: 1.30rem;
                        height: 1.30rem;
                    }
                    @media only screen and (min-width: 1200px) and (max-width: 1461px){
                        width: 1.10rem;
                        height: 1.10rem;
                    }
                    @media only screen and (min-width: 1200px) and (max-width: 1409px){
                        width: 1.00rem;
                        height: 1.00rem;
                    }
                    
                }
                .ant-radio-checked {
                    width: 1.5rem;
                    height: 1.5rem;
                    @media only screen and (max-width: 1200px){
                        width: 1.70rem;
                        height: 1.70rem;
                    }
                    @media only screen and (max-width: 1062px){
                        width: 1.45rem;
                        height: 1.45rem;
                    }
                    @media only screen and (max-width: 931px){
                        width: 1.25rem;
                        height: 1.25rem;
                    }
                    @media only screen and (max-width: 878px){
                        width: 1.05rem;
                        height: 1.05rem;
                    }
                    @media only screen and (min-width: 1200px) and (max-width: 1758px){
                        width: 1.90rem;
                        height: 1.90rem;
                    }
                    @media only screen and (min-width: 1200px) and (max-width: 1718px){
                        width: 1.70rem;
                        height: 1.70rem;
                    }
                    @media only screen and (min-width: 1200px) and (max-width: 1632px){
                        width: 1.50rem;
                        height: 1.50rem;
                    }
                    @media only screen and (min-width: 1200px) and (max-width: 1545px){
                        width: 1.30rem;
                        height: 1.30rem;
                    }
                    @media only screen and (min-width: 1200px) and (max-width: 1461px){
                        width: 1.10rem;
                        height: 1.10rem;
                    }
                    @media only screen and (min-width: 1200px) and (max-width: 1409px){
                        width: 1.00rem;
                        height: 1.00rem;
                    }
                    .ant-radio-input[datavalues="Low-Negative"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative']} !important;
                        border: 1px solid #ccc;
                    }
                    .ant-radio-input[datavalues="Skeptical-Negative"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative']} !important;
                    }
                    .ant-radio-input[datavalues="Disagree-Negative"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FF5D5F" : theme['user-theme']['DisagreeNegative'] === undefined ? "#FF5D5F" : theme['user-theme']['DisagreeNegative']} !important;
                    }
                    .ant-radio-input[datavalues="Disagree-Positive"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#00B85C" : theme['user-theme']['DisagreePositive'] === undefined ? "#00B85C" : theme['user-theme']['DisagreePositive']} !important;
                    }
                    .ant-radio-input[datavalues="Optimistic-Positive"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#00B85C" : theme['user-theme']['DisagreePositive'] === undefined ? "#00B85C" : theme['user-theme']['DisagreePositive']} !important;
                    }
                    .ant-radio-input[datavalues="Critical"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative']} !important;
                    }
                    .ant-radio-input[datavalues="Low-Positive"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#00B85C" : theme['user-theme']['LowPositive'] === undefined ? "#00B85C" : theme['user-theme']['LowPositive']} !important;
                    }
                    .ant-radio-input[datavalues="Mid"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FFC533" : theme['user-theme']['MidColor'] === undefined ? "#FFC533" : theme['user-theme']['MidColor']} !important;
                    }
                    .ant-radio-input[datavalues="Neutral"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FFC533" : theme['user-theme']['NeutralColor'] === undefined ? "#FFC533" : theme['user-theme']['NeutralColor']} !important;
                    }
                    .ant-radio-input[datavalues="Primary"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FFC533" : theme['user-theme']['MidColor'] === undefined ? "#FFC533" : theme['user-theme']['MidColor']} !important;
                    }
                    .ant-radio-input[datavalues="High-Negative"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FF5D5F" : theme['user-theme']['HighNegative'] === undefined ? "#FF5D5F" : theme['user-theme']['HighNegative']} !important;
                    }
                    .ant-radio-input[datavalues="High-Positive"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#00B85C" : theme['user-theme']['HighPositive'] === undefined ? "#00B85C" : theme['user-theme']['HighPositive']} !important;
                    }
                    .ant-radio-input[datavalues="Secondary"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#00B85C" : theme['user-theme']['HighPositive'] === undefined ? "#00B85C" : theme['user-theme']['HighPositive']} !important;
                    }
                    .ant-radio-input[datavalues="Agree-Negative"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FF5D5F" : theme['user-theme']['AgreeNegative'] === undefined ? "#FF5D5F" : theme['user-theme']['AgreeNegative']} !important;
                    }
                    .ant-radio-input[datavalues="Agree-Positive"] + .ant-radio-inner {
                        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#00B85C" : theme['user-theme']['AgreePositive'] === undefined ? "#00B85C" : theme['user-theme']['AgreePositive']} !important;
                    }
                }
            }
        }
    }
`;

const ReadinessCascaderStyle = Styled.div`
    margin-bottom: 30px;
    border-bottom-left-radius: ${({ theme }) => theme['tab-border-global-radius'] === undefined ? "22px" : theme['tab-border-global-radius']} !important;
    border-bottom-right-radius: ${({ theme }) => theme['tab-border-global-radius'] === undefined ? "22px" : theme['tab-border-global-radius']} !important;
    label {
        font-size: 20px !important;
    }
    .ant-select.ant-select-multiple {
        .ant-select-selection-overflow {
            padding-left: 10px;
        }
        .ant-select-selection-item {
            height: 24px;
            -moz-box-align: center;
            align-items: center;
            color: rgb(64, 64, 64);
            padding: 12px 12px 14px 8px;
            background-color: ${({ theme }) => theme['user-theme'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedBackground'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedBackground']};
            color: ${({ theme }) => theme['user-theme'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedColor'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedColor']};
            font-weight: 600;
            border-color: rgb(227, 230, 239);
            .ant-select-selection-item-remove {
                svg{
                    color: ${({ theme }) => theme['user-theme'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedColor'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedColor']};    
                    font-weight: bold;
                    margin-left: 2px;
                }
            }
        }
    }
    .add_more_btn_panel .ant-btn {
        color: ${({ theme }) => theme['user-theme'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedColor'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedColor']};
        font-weight: 600;
    }
`;

const AdoptionCascaderStyle = Styled.div`
    margin-bottom: 30px;
    border-bottom-left-radius: ${({ theme }) => theme['tab-border-global-radius'] === undefined ? "22px" : theme['tab-border-global-radius']} !important;
    border-bottom-right-radius: ${({ theme }) => theme['tab-border-global-radius'] === undefined ? "22px" : theme['tab-border-global-radius']} !important;
    label {
        font-size: 20px !important;
    }
    .ant-select.ant-select-multiple {
        .ant-select-selection-overflow {
            padding-left: 10px;
        }
        .ant-select-selection-item {
            height: 24px;
            -moz-box-align: center;
            align-items: center;
            color: rgb(64, 64, 64);
            padding: 12px 12px 14px 8px;
            background-color: ${({ theme }) => theme['user-theme'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedBackground'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedBackground']};
            color: ${({ theme }) => theme['user-theme'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedColor'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedColor']};
            font-weight: 600;
            border-color: rgb(227, 230, 239);
            .ant-select-selection-item-remove {
                svg{
                    color: ${({ theme }) => theme['user-theme'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedColor'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedColor']};    
                    font-weight: bold;
                    margin-left: 2px;
                }
            }
        }
    }
    .add_more_btn_panel .ant-btn {
        color: ${({ theme }) => theme['user-theme'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedColor'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['user-theme']['tagCheckedColor']};
        font-weight: 600;
    }
`;

const EngagementPlanHeader = Styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    .user-info-header-panel {
        width: 70%;
        padding-top: 20px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        h1.header-title {
            font-weight: 500;
            font-size: 32px;
            margin-bottom: 0;
            color: ${({ theme }) => theme['user-theme'] === undefined ? "#004D86" : theme['user-theme']['EngagementPlanColor'] === undefined ? "#004D86" : theme['user-theme']['EngagementPlanColor']} !important;
        }
        h1.header-audience-name-title {
            font-weight: 500;
            font-size: 40px;
            margin-bottom: 0;
            color: ${({ theme }) => theme['user-theme'] === undefined ? "#004D86" : theme['user-theme']['EngagementPlanColor'] === undefined ? "#004D86" : theme['user-theme']['EngagementPlanColor']} !important;
        }
        h3.header-audience-info {
            font-weight: 500;
            font-size: 20px;
            margin-bottom: 0;
            color: ${({ theme }) => theme['user-theme'] === undefined ? "#004D86" : theme['user-theme']['EngagementPlanColor'] === undefined ? "#004D86" : theme['user-theme']['EngagementPlanColor']} !important;
        }
    }
`;

const OCMModal = Styled.div`
    padding: 0;
`;

const OCMModalHeader = Styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    .user-info-header-panel {
        background: ${({ theme }) => theme['user-theme'] === undefined ? "#004D86" : theme['user-theme']['sidebarColor'] === undefined ? "#004D86" : theme['user-theme']['sidebarColor']} !important;
        margin-top: 40px;
        margin-left: 12px;
        width: 99%;
        padding: 20px;
        display: flex;
        justify-content: left;
        flex-direction: column;
        align-items: left;
        position: relative;
        margin-right: -10px;
        .edit-pencil {
            position: absolute;
            right: 30px;
            top: 30%;
            svg {
                cursor: pointer;
            }
        }
        h1.header-title {
            font-weight: 500;
            font-size: 32px;
            padding-left: 20px;
            margin-bottom: 0;
            color: ${({ theme }) => theme['user-theme'] === undefined ? "#FFFFFF" : theme['user-theme']['sidebarTextColor'] === undefined ? "#FFFFFF" : theme['user-theme']['sidebarTextColor']} !important;
        }
        h1.header-audience-name-title {
            font-weight: 500;
            font-size: 40px;
            padding-left: 20px;
            margin-bottom: 0;
            color: ${({ theme }) => theme['user-theme'] === undefined ? "#004D86" : theme['user-theme']['EngagementPlanColor'] === undefined ? "#004D86" : theme['user-theme']['EngagementPlanColor']} !important;
        }
        h3.header-audience-info {
            font-weight: 500;
            font-size: 20px;
            margin-bottom: 0;
            color: ${({ theme }) => theme['user-theme'] === undefined ? "#004D86" : theme['user-theme']['EngagementPlanColor'] === undefined ? "#004D86" : theme['user-theme']['EngagementPlanColor']} !important;
        }
    }
`;

const EngagementPlanBody = Styled.div`
    .body-top-panel {
        width: 100%;
        padding: 20px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
        .body-assessment-results {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #ccc;
            width: 100%;
            flex-direction: column;
            padding: 20px;
            margin-bottom: 30px;
            border-radius: ${({ theme }) => theme['border-global-radius'] === undefined ? "18px" : theme['border-global-radius']};
            .body-assessment-results-title {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                h2 {
                    font-weight: 500;
                    font-size: 24px;
                    margin-bottom: 0;
                    color: ${({ theme }) => theme['user-theme'] === undefined ? "#004D86" : theme['user-theme']['EngagementPlanColor'] === undefined ? "#004D86" : theme['user-theme']['EngagementPlanColor']} !important;
                }
            }
            .body-assessment-results-checkbox {
                width: 100%;
                text-align: center;
                .resistance-key-reason {
                    width: 100%;
                    margin-top: 10px;
                    border: 1px solid ${({ theme }) => theme['user-theme'] === undefined ? "#b2e7ff" : theme['user-theme']['AudienceResistancePanelColor'] === undefined ? "#b2e7ff" : theme['user-theme']['AudienceResistancePanelColor']};
                    padding: 20px;
                    .ant-checkbox-group {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .ant-checkbox-group-item {
                            width: 25%;
                            margin-bottom: 20px;
                        }
                    }
                }
            }
            .body-assessment-results-radio {
                width: 100%;
                padding: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                h2 {
                    font-weight: 500;
                    font-size: 24px;
                    margin-bottom: 0;
                    color: ${({ theme }) => theme['user-theme'] === undefined ? "#004D86" : theme['user-theme']['EngagementPlanColor'] === undefined ? "#004D86" : theme['user-theme']['EngagementPlanColor']} !important;
                }
            }
        }

        .stakeholder_types {
            font-weight: 600;
            font-size: 0.8rem;
            margin-bottom: 0.5rem;
        }

        .body-assessment-results-content {
            .stakeholder_types {
                font-weight: 600;
                font-size: 0.8rem;
                margin-bottom: 0.5rem;
            }
        }
        
        h1.header-audience-name-title {
            font-weight: 500;
            font-size: 40px;
            margin-bottom: 0;
            color: ${({ theme }) => theme['user-theme'] === undefined ? "#004D86" : theme['user-theme']['EngagementPlanColor'] === undefined ? "#004D86" : theme['user-theme']['EngagementPlanColor']} !important;
        }
        h3.header-audience-info {
            font-weight: 500;
            font-size: 20px;
            margin-bottom: 0;
            color: ${({ theme }) => theme['user-theme'] === undefined ? "#004D86" : theme['user-theme']['EngagementPlanColor'] === undefined ? "#004D86" : theme['user-theme']['EngagementPlanColor']} !important;
        }
    }
`;

const ChampionModalBodyStyle = Styled.div`
    .body-top-panel {
        width: 100%;
        padding: 20px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
        .group-audience {
            padding: 20px;
            position: relative;
            border-radius: ${({ theme }) => theme['border-global-radius'] === undefined ? "18px" : theme['border-global-radius']};
            border: 1px solid ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['sidebarColor'] === undefined ? "#0066B2" : theme['user-theme']['sidebarColor']} !important;
            display: flex;
            flex-direction: row;
            align-items: start;
            justify-content: center;
            min-height: 100px;
            p {
                font-weight: bold;
                font-size: 18px;
            }
            .audience-panel {
                position: absolute;
                bottom: -24px;
                width: auto;
                background: #fff;
                text-align: center;
                .ant-avatar {
                    background: ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['sidebarColor'] === undefined ? "#0066B2" : theme['user-theme']['sidebarColor']} !important;        
                }
            }
        }
        .body-assessment-results {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #ccc;
            width: 100%;
            flex-direction: column;
            padding: 20px;
            border-radius: ${({ theme }) => theme['border-global-radius'] === undefined ? "18px" : theme['border-global-radius']};
            .body-assessment-results-title {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                h2 {
                    font-weight: 500;
                    font-size: 24px;
                    margin-bottom: 0;
                    color: ${({ theme }) => theme['user-theme'] === undefined ? "#004D86" : theme['user-theme']['EngagementPlanColor'] === undefined ? "#004D86" : theme['user-theme']['EngagementPlanColor']} !important;
                }
            }
            .body-assessment-results-checkbox {
                width: 100%;
                text-align: center;
                .resistance-key-reason {
                    width: 100%;
                    margin-top: 10px;
                    border: 1px solid ${({ theme }) => theme['user-theme'] === undefined ? "#b2e7ff" : theme['user-theme']['AudienceResistancePanelColor'] === undefined ? "#b2e7ff" : theme['user-theme']['AudienceResistancePanelColor']};
                    padding: 20px;
                    .ant-checkbox-group {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .ant-checkbox-group-item {
                            width: 25%;
                            margin-bottom: 20px;
                        }
                    }
                }
            }
            .body-assessment-results-radio {
                width: 100%;
                padding: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                h2 {
                    font-weight: 500;
                    font-size: 24px;
                    margin-bottom: 0;
                    color: ${({ theme }) => theme['user-theme'] === undefined ? "#004D86" : theme['user-theme']['EngagementPlanColor'] === undefined ? "#004D86" : theme['user-theme']['EngagementPlanColor']} !important;
                }
            }
        }

        .stakeholder_types {
            font-weight: 600;
            font-size: 0.8rem;
            margin-bottom: 0.5rem;
        }

        .body-assessment-results-content {
            .stakeholder_types {
                font-weight: 600;
                font-size: 0.8rem;
                margin-bottom: 0.5rem;
            }
        }
        
        h1.header-audience-name-title {
            font-weight: 500;
            font-size: 40px;
            margin-bottom: 0;
            color: ${({ theme }) => theme['user-theme'] === undefined ? "#004D86" : theme['user-theme']['EngagementPlanColor'] === undefined ? "#004D86" : theme['user-theme']['EngagementPlanColor']} !important;
        }
        h3.header-audience-info {
            font-weight: 500;
            font-size: 20px;
            margin-bottom: 0;
            color: ${({ theme }) => theme['user-theme'] === undefined ? "#004D86" : theme['user-theme']['EngagementPlanColor'] === undefined ? "#004D86" : theme['user-theme']['EngagementPlanColor']} !important;
        }
    }
`;

const SuggestedChampionStyle = Styled.div`
    .close-panel {
        position: absolute;
        right: -12px;
        top: -12px;
        z-index: 999;
        background: #493682;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        box-shadow: 0 5px 20px ${({ theme }) => theme['dark-color']}15;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
            color: #fff;
        }
    }
    .form-items {
        .ant-form-item-label {
            width: 100%;
            label {
                justify-content: center;
                display: flex;
                width: 100%;
                padding-bottom: 20px;
            }
        }
    }
    .recommendation {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        b{
            padding-bottom: 10px;
        }
        .calculation-panel {
            display: flex;
            justify-content: center;
            background: #fff;
            color: #000;
            align-items: center;
            font-weight: 700;
            min-width: 120px;
            min-height: 90px;
            margin-bottom: 10px;
            border: 1px solid ${({ theme }) => theme['tool-info-theme'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['tool-info-theme']['secondary_color'] === undefined ? (theme['tab-color'] === undefined ? "#0066B2" : theme['tab-color']) : theme['tool-info-theme']['secondary_color']} !important;
            border-radius: ${({ theme }) => theme['border-global-radius'] === undefined ? "18px" : theme['border-global-radius']};
            flex-direction: column;
            .number {
                font-size: 36px;
            }
            .text {
                font-size: 16px;
                color: #888;
            }
        }
    }
`;

const TagBoxCssImpect = Styled.div`
    .tag-boxes .tag-box-div
    {
        margin-top: 12px;
    }
    .tag-boxes .tag-box-div:first-child
    {
        margin-top: 0px;
    }
`

const DefaultDataPopUp = Styled.div`
    background: ${({ theme }) => theme['user-theme'] === undefined ? "#38AAFF" : theme['user-theme']['defaultPopupColor'] === undefined ? "#38AAFF" : theme['user-theme']['defaultPopupColor']} !important;
`;

const ListViewContainerStyle = Styled.div`
    .ant-table-body {
        max-height: 541px !important;
        // max-height: unset !important;
        // height: auto;
    }
    thead {
        th:not(.score):not(.sl){
            background: ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['sidebarColor'] === undefined ? "#0066B2" : theme['user-theme']['sidebarColor']} !important;
            color: ${({ theme }) => theme['user-theme'] === undefined ? "#FFFFFF" : theme['user-theme']['sidebarColor'] === undefined ? "#FFFFFF" : theme['user-theme']['sidebarIconPrimaryColor']} !important;
        }
        th.score:not(.sl){
            background: ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['MidColor'] === undefined ? "#0066B2" : theme['user-theme']['MidColor']} !important;
            color: #333 !important;
            font-weight: 600;
        }
        .ant-table-column-sorters svg{
            color: #fff;
            font-size: 14px;
        }
        .anticon-filter svg{
            color: #fff;
            font-size: 14px;
        }
    }    
    .resetTable{
        background: ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['MidColor'] === undefined ? "#0066B2" : theme['user-theme']['MidColor']} !important;
        color: #333 !important;
        font-weight: 600;
        cursor: pointer;
    }
    tbody {
        td.scores {
            text-align: center;
        }
        td.HighNegative {
            span {
                color: #FFFFFF !important;
                font-weight: 600 !important;
            }
        }
        td.LowNegative {
            span {
                color: #FFFFFF !important;
                font-weight: 600 !important;
            }
        }
    }
`;

const AudienceGroupView = Styled.div`
    width: 100%;
`;

const AudienceAccordianGroupView = Styled.div`
    width: 100%;
    .ant-collapse-item {
        background: #F7FCFF;
        border: 1px solid rgba(189, 227, 255) !important;
        padding: 0;
        margin-bottom: 40px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        .ant-collapse-arrow {
            font-size: 22px !important;
        }
    }
    .ant-collapse-item-active {
        background: rgba(189, 227, 255, .49);
        padding: 0;
        margin-bottom: 40px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        .ant-collapse-arrow {
            font-size: 22px !important;
        }
    }
    .ant-collapse-expand-icon {
        height: 38px !important;
    }
    svg{
        font-size: 30px !important;
    }
`;

const CalenDerTraining = Styled.div`
    .ant-picker-cell-inner:has(.date-back-color)
    {
        color:white !important;
    }
`
const ProjectsDiv = Styled.div`
    .project-box div.box
    {
        padding: 30px 15px;
        border-radius: 10px;
        margin-top: 20px;
        font-size: 17px;
    }
    .ant-select-single
    {
        width: 100px;
    }
    .ant-select.active .ant-select-selector
    {
        background: ${({ theme }) => theme['user-theme'] === undefined ? "#BBF1FF" : theme['user-theme']['TableHeaderColor'] === undefined ? "#BBF1FF" : theme['user-theme']['TableHeaderColor']} !important;
        color: #000 !important;
    }
    .ant-select.active
    {
        .anticon svg {
            color: #fff !important;
        }
    }
    .ant-select.active.ant-select-open .ant-select-selection-item {
        color: #fff !important;
    }
    .ant-select.close .ant-select-selector
    {
        background-color:#BFBFBF !important;
    }
    .project-box
    {
        margin-left: 20px;
        margin-top: 30px;
        color:white;
        
    }
    .ant-table-thead > tr > th
    {
        //background-color:#BBF1FF !important;
        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#BBF1FF" : theme['user-theme']['TableHeaderColor'] === undefined ? "#BBF1FF" : theme['user-theme']['TableHeaderColor']} !important;
        color: black !important;
    }
    
    .ant-table-thead .anticon.active svg
    {
        color: #FF0000;
    }
    .ant-table-thead .anticon svg
    {
        color: #00B0F0;
    }
    .ant-btn.ant-btn-lg {
        height: auto;
        border-radius: 25px;
        width: 200px;
        padding: 15px 20px;
    }
    .box-back-blue
    {
        // background:#05A7E4;
        background:#38AAFF;
    }
    .box-back-orange
    {
        // background:#ECB200;
        background:#FFE7AB;
        color:black;
    }
    .box-back-gray
    {
        // background:#929292;
        background:#C8CDD7;
        color:black;
    }
    .underline
    {
        text-decoration:underline;
    }
    .border-rounded
    {
        border: 1px solid #D1D1D1;
        border-radius: 10px;
        height: 460px;
        padding: 20px;
    }
`;
const CommunicationStyleAdd = Styled.div`
padding: 20px;
.tag-level-of-impect {
    .ant-tag {
        font-size: 12px;
        padding: 10px 24px;
        background: ${({ theme }) => theme['user-theme'] === undefined ? "#FFFFFF" : theme['user-theme']['tagBackground'] === undefined ? "#FFFFFF" : theme['user-theme']['tagBackground']};
        border: 1px solid ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['tagBorderColorOCM'] === undefined ? "#0066B2" : theme['user-theme']['tagBorderColorOCM']};
        color: ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['tagColorOCM'] === undefined ? "#0066B2" : theme['user-theme']['tagColorOCM']};
    }
    .ant-tag-checkable-checked {
        background: ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['tagCheckedBackground'] === undefined ? "#0066B2" : theme['user-theme']['tagCheckedBackground']};
        color: ${({ theme }) => theme['user-theme'] === undefined ? "#FFFFFF" : theme['user-theme']['tagCheckedColor'] === undefined ? "#FFFFFF" : theme['user-theme']['tagCheckedColor']} !important;
    }
}
// .ant-form-item-label {
//     label {
//         font-size: 16px;
//     }
// }
.ant-picker
{
    min-width: 70px;
}
.ant-checkbox-group
{ 
    width: 100%;
    padding: 7px !important;
    overflow: scroll;
    border: 1px dotted rgb(206,242,255);
}  
.ant-btn-primary
{
    padding: 10px 50px 40px !important;
    border-radius: 30px !important;
    font-size: 17px !important;
}
`;

const ChangeRiskStyleDiv = Styled.div`
    .ant-table-wrapper .ant-table-column-sorter-up.active svg,.ant-table-wrapper .ant-table-column-sorter-down.active svg
    {
        color: white !important;
        font-weight:bold;

    }
    .stage-name
    {
        text-align: center;
        padding: 10px;
        border-radius: 20px;
        background: #5FAEF0;
        color: white;
        font-size: 15px;
    }
    .response-div{
        text-align: center;
        text-align: center;
        margin: -10px auto;
        font-size: 17px;
        color: white !important;
    }
    .response-div p{
        text-align: center;
        font-size: 16px;
        color: white !important;
    }
    .response-div .response-number
    {
        font-size: 35px;
        line-height: 20px;
    }
    .answers
    {
        text-align:center;
        margin: 0 auto;
    }
    .answer-div
    {
        margin-top: -20px;
        display: flex;
        justify-content: space-between;
        width: 85%;
        text-align: center;
        padding: 38px 0px 0px 0px;
        background: #FFF5E8;
    }
    .submit-answer
    {
        width: 50px;
        height: 50px;
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid black;
        text-align:center;
        margin: 0 auto;
        cursor:pointer;
    }
    .submit-answer-div
    {
        display: flex;
        justify-content: space-between;
        width: 85%;
        text-align: center;
        padding: 20px 0px;
        background: #FFF5E8;
        border-radius: 0px 0px 0px 20px;
    }
    .toolRiskListPhaseTabStyle {
        .ant-tabs-nav-list
        {
            width: 100%;
            margin-top: 20px;
            margin-bottom: 30px;
        }
        .ant-tabs-nav-list .ant-tabs-tab
        {
            text-align:center !important;
            margin: 0 auto  !important;
            min-height: 32px !important;
            min-width: 32% !important;
            border: 1px solid rgb(204, 204, 204);
            overflow-wrap: break-word;
            white-space: pre-wrap;
            display: flex;
            -moz-box-pack: center;
            justify-content: center;
            -moz-box-align: center;
            align-items: center;
            text-align: center;
            border-top-left-radius: 22px !important;
            border-top-right-radius: 22px !important;
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
        .ant-tabs-nav-list .ant-tabs-ink-bar
        {
            height: 2px !important;
        }
        .ant-tabs-tab-active
        {
            background: ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['ToolTitleColor'] === undefined ? "#0066B2" : theme['user-theme']['ToolTitleColor']};!important;
            color: white !important;
        }
        .ant-tabs-content-holder .ant-tabs-tabpane
        {
            padding: 0px 0px
        }
        .ant-tabs-nav-list
        {
            border-bottom: none !important;
        }
        .ant-tabs-content-holder
        {
            border: none;
            border-top:2px solid${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['ToolTitleColor'] === undefined ? "#0066B2" : theme['user-theme']['ToolTitleColor']};
        }
    }
    .ant-table-column-sorter-up svg,.ant-table-column-sorter-down svg
    {
        color: #A1A1A1 !important
    }
    td.ant-table-cell:has(.red-box),td.ant-table-cell:has(.red-box):hover
    {
        background:#ED6C72 !important;
        color: white !important;
        text-align:center;
        font-weight:bold;
    }
    td.ant-table-cell:has(.green-box), td.ant-table-cell:has(.green-box):hover
    {
        background:#5CC971 !important;
        color: white !important;
        text-align:center;
        font-weight:bold;
    }
    td.ant-table-cell:has(.yellow-box), td.ant-table-cell:has(.yellow-box):hover
    {
        background:#F5C342 !important;
        color: white !important;
        font-weight:bold;
        text-align:center;
    }
    td.ant-table-cell:has(.pending-box), td.ant-table-cell:has(.pending-box):hover
    {
        background:#E7E6E6 !important;
        color: #999999 !important;
        font-weight:bold;
        text-align:center;
    }
    .stage-3
    {
        text-align:center;
        padding: 30px 0px;
    }
    tr td:first-child
    {
        max-width:20%;
    }
    .ant-table-thead > tr > th
    {
        border: 1px solid white !important;
        color: white !important;
        background: ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['ToolTitleColor'] === undefined ? "#0066B2" : theme['user-theme']['ToolTitleColor']} !important;
    }
    th
    {
        border: 1px solid white;
        padding: 10px 3px;
        background: ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['ToolTitleColor'] === undefined ? "#0066B2" : theme['user-theme']['ToolTitleColor']};
    }
    th
    {
        color:white;
        border: 1px solid white;
        padding: 10px;
    }
    td
    {
        border: 1px solid ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['ToolTitleColor'] === undefined ? "#0066B2" : theme['user-theme']['ToolTitleColor']};
        padding: 10px;
        
    }
    .box-yes
    {
        padding: 3px 0px;
        background: green;
        width: 42px;
        text-align: center;
        color: white;
        margin-left: -15px;
    }
    .box-no
    {
        padding: 3px 0px;
        background: red;
        width: 42px;
        text-align: center;
        color: white;
        margin-left: -15px;
    }
    .Mid {
        background-color: #FFC000 !important;
    }
    .Primary {
        background-color: #FFC000 !important;
    }
    .HighPositive {
        background-color: #00CC66 !important;
    }
    .Secondary {
        background-color: #00CC66 !important;
    }
    .LowPositive {
        background-color: #00CC66 !important;
    }
    .Critical {
        background-color: #FF626E !important;
    }
    .HighNegative {
        background-color: #FF626E !important;
    }
    .LowNegative {
        background-color: #FF626E !important;
    }
    .Pending {
        background-color: #E7E6E6 !important;
    }
    .MasterScore {
        display: flex;
        flex-direction: column;
        flex-direction: revert;
        align-items: center;
        width: 100%;
        padding: 0.1rem;
    }
    .scoreTitle {
        background-color: #FFFFFF;
        padding: 0.5rem;
        font-size: 1.2rem;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        flex-direction: revert;
        align-items: center;
        width: 70%;
    }
    .typeTitle {
        background-color: transparent;
        padding: 0.5rem;
        font-size: 1.1rem;
        color: #FFFFFF;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        flex-direction: revert;
        align-items: center;
        justify-content: center;
        width: 30%;
    }
    .ant-alert .MasterScore .typeTitle
    {
        font-size: 15px !important;
        white-space: nowrap;
    }
    .ant-alert .MasterScore .scoreTitle
    {
        white-space: nowrap;
    }
    .ant-tag-success {
        color: #52c41a !important;
        border-color: #b7eb8f !important;
    }
    .ant-tag-warning {
        color: #faad14 !important;
        background: #fffbe6 !important;
        border-color: #ffe58f !important;
    }
    .ant-tag-error {
        color: #ff4d4f !important;
        background: #fff2f0 !important;
        border-color: #ffccc7 !important;
    }
`

const ProjectAssessmentStyle = Styled.div`
    width: 100%;
    label {
        font-size: 0.9rem !important;
    }
    label.ant-radio-wrapper {
        display: inline-block;
        padding-right: 40px;
        .ant-radio-inner {
            width: 2.5rem !important;
            height: 2.5rem !important;
            @media only screen and (max-width: 1200px){
                width: 1.70rem;
                height: 1.70rem;
            }
            @media only screen and (max-width: 1062px){
                width: 1.45rem;
                height: 1.45rem;
            }
            @media only screen and (max-width: 931px){
                width: 1.25rem;
                height: 1.25rem;
            }
            @media only screen and (max-width: 878px){
                width: 1.05rem;
                height: 1.05rem;
            }
            @media only screen and (min-width: 1200px) and (max-width: 1758px){
                width: 1.90rem;
                height: 1.90rem;
            }
            @media only screen and (min-width: 1200px) and (max-width: 1718px){
                width: 1.70rem;
                height: 1.70rem;
            }
            @media only screen and (min-width: 1200px) and (max-width: 1632px){
                width: 1.50rem;
                height: 1.50rem;
            }
            @media only screen and (min-width: 1200px) and (max-width: 1545px){
                width: 1.30rem;
                height: 1.30rem;
            }
            @media only screen and (min-width: 1200px) and (max-width: 1461px){
                width: 1.10rem;
                height: 1.10rem;
            }
            @media only screen and (min-width: 1200px) and (max-width: 1409px){
                width: 1.00rem;
                height: 1.00rem;
            }
        }
        .ant-radio-checked {
            width: 2.5rem !important;
            height: 2.5rem !important;
            @media only screen and (max-width: 1200px){
                width: 1.70rem;
                height: 1.70rem;
            }
            @media only screen and (max-width: 1062px){
                width: 1.45rem;
                height: 1.45rem;
            }
            @media only screen and (max-width: 931px){
                width: 1.25rem;
                height: 1.25rem;
            }
            @media only screen and (max-width: 878px){
                width: 1.05rem;
                height: 1.05rem;
            }
            @media only screen and (min-width: 1200px) and (max-width: 1758px){
                width: 1.90rem;
                height: 1.90rem;
            }
            @media only screen and (min-width: 1200px) and (max-width: 1718px){
                width: 1.70rem;
                height: 1.70rem;
            }
            @media only screen and (min-width: 1200px) and (max-width: 1632px){
                width: 1.50rem;
                height: 1.50rem;
            }
            @media only screen and (min-width: 1200px) and (max-width: 1545px){
                width: 1.30rem;
                height: 1.30rem;
            }
            @media only screen and (min-width: 1200px) and (max-width: 1461px){
                width: 1.10rem;
                height: 1.10rem;
            }
            @media only screen and (min-width: 1200px) and (max-width: 1409px){
                width: 1.00rem;
                height: 1.00rem;
            }
            
            .ant-radio-input[datavalues="Mid to High"] + .ant-radio-inner {
                background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative'] === undefined ? "#FF5D5F" : theme['user-theme']['LowNegative']} !important;
                border: 4px solid rgb(255, 162, 164) !important;
            }
            .ant-radio-input[datavalues="Not Impacted"] + .ant-radio-inner {
                background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#00B85C" : theme['user-theme']['DisagreePositive'] === undefined ? "#00B85C" : theme['user-theme']['DisagreePositive']} !important;
                border: 4px solid rgb(132, 255, 194) !important;
            }
            .ant-radio-input[datavalues="Very Low to Low"] + .ant-radio-inner {
                background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#FFC533" : theme['user-theme']['MidColor'] === undefined ? "#FFC533" : theme['user-theme']['MidColor']} !important;
                border: 4px solid rgb(233, 198, 110) !important;
            }
        }
    }
    .impacted_project_group {
        margin-left: 40px; 
        width: 100%;
        .ant-space-item {
            width: 100%;
            .horizontal-form {
                width: 100%;
                display: flex;
                flex-direction: row;
                .ant-form-item-row {
                    flex-direction: row;
                    display: flex;
                    width: 80%;
                }
                .ant-form-item-label {
                    width: 70%;
                    label {
                        font-size: 20px;
                    }
                }
                .ant-form-item-control {
                    width: 30%;
                    input {
                        text-align: center;
                        padding: 15px;
                        background: #eee;
                    }
                }
            }
        }
    }
`
const TargetAudienceGridUserStyle = Styled.div`
    margin-left: 10px;
    margin-right: 20px;
    padding-right: 20px;
    margin-top: 0;
    width: 100%;
    .AudiencePanel {
        width: 100%;
        border: 1px solid #ccc;
        box-shadow: 0 5px 20px ${({ theme }) => theme['dark-color']}15;
        display: flex;
        align-items: center;
        flex-direction: row;
        .ant-row {
            width: 100%;
            padding: 20px 10px;
            align-items: center;
            .audience-avatar {
                width: 60px;
                height: 60px;
                font-size: 20px;
                line-height: 58px;
            }
        }
    }
    
`
const TargetAudienceStyle = Styled.div`
    margin-left: 10px;
    margin-right: 20px;
    padding-right: 20px;
    margin-top: 0;
    width: 100%;
    .title-input {
        padding: 20px 10px;
        border-bottom: 1px solid #ccc;
    }
    .counter {
        margin-top: 60px;
        .counter-child {
            padding-top: 10px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            margin-right: 20px;
            background: rgba(189, 227, 255, .49);
            border-radius: 20px;
            h2 {
                margin-bottom: 5px;
            }
            h5 {
                margin-top: 2px;
            }
        }
    }
    .individual-switch-relative{
        .ant-switch {
            background: ${({ theme }) => theme['user-theme'] === undefined ? "#b2e7ff" : theme['user-theme']['sidebarHoverColor'] === undefined ? "#b2e7ff" : theme['user-theme']['sidebarHoverColor']} !important;
            height: 40px;
            display: flex;
            justify-content: center;
            align-item: center;
            min-width: 184px;
            .ant-switch-inner {
                padding-inline-start: 0;
                padding-inline-end: 0;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                span {
                    font-size: 18px;
                }
                .ant-switch-inner-unchecked {
                    margin-top: 0px;
                    width: 85%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    left: 0px;
                    margin-left: 15%;
                }
                .ant-switch-inner-checked {
                    margin-top: 0px;
                    width: 85%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    left: 0px;
                    margin-right: 15%;
                }
            }
            .ant-switch-handle {
                inset-inline-start: 2px;
                width: 35px;
                height: 35px;
                &:before {
                    border-radius: 50%;
                }
            }
            .ant-switch-inner-unchecked {
                display: flex;
            }
            .ant-switch-inner-checked {
                display: none;
            }
        }
        .ant-switch.ant-switch-checked {
            background: ${({ theme }) => theme['user-theme'] === undefined ? "#b2e7ff" : theme['user-theme']['sidebarColor'] === undefined ? "#b2e7ff" : theme['user-theme']['sidebarColor']} !important;
            // background: #5FAEF0;
            .ant-switch-handle {
                inset-inline-start: calc(100% - 38px);
                width: 35px;
                height: 35px;
                &:before {
                    border-radius: 50%;
                }
            }
            .ant-switch-inner-unchecked {
                display: none;
            }
            .ant-switch-inner-checked {
                display: flex;
            }
        }
    }
    .individual-switch{
        position: absolute;
        top: 20px;
        right: 10px;
        .ant-switch {
            background: ${({ theme }) => theme['user-theme'] === undefined ? "#b2e7ff" : theme['user-theme']['sidebarHoverColor'] === undefined ? "#b2e7ff" : theme['user-theme']['sidebarHoverColor']} !important;
            height: 40px;
            display: flex;
            justify-content: center;
            align-item: center;
            min-width: 184px;
            .ant-switch-inner {
                padding-inline-start: 0;
                padding-inline-end: 0;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                span {
                    font-size: 18px;
                }
                .ant-switch-inner-unchecked {
                    margin-top: 0px;
                    width: 85%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    left: 0px;
                    margin-left: 15%;
                }
                .ant-switch-inner-checked {
                    margin-top: 0px;
                    width: 85%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    left: 0px;
                    margin-right: 15%;
                }
            }
            .ant-switch-handle {
                inset-inline-start: 2px;
                width: 35px;
                height: 35px;
                &:before {
                    border-radius: 50%;
                }
            }
            .ant-switch-inner-unchecked {
                display: flex;
            }
            .ant-switch-inner-checked {
                display: none;
            }
        }
        .ant-switch.ant-switch-checked {
            background: ${({ theme }) => theme['user-theme'] === undefined ? "#b2e7ff" : theme['user-theme']['sidebarColor'] === undefined ? "#b2e7ff" : theme['user-theme']['sidebarColor']} !important;
            // background: #5FAEF0;
            .ant-switch-handle {
                inset-inline-start: calc(100% - 38px);
                width: 35px;
                height: 35px;
                &:before {
                    border-radius: 50%;
                }
            }
            .ant-switch-inner-unchecked {
                display: none;
            }
            .ant-switch-inner-checked {
                display: flex;
            }
        }
    }
    .text-group-start {
        font-size: 20px;
        padding: 24px;
        display: flex;
    }
    .organizationalForm {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        max-height: 600px;
        min-height: 600px;
        padding-bottom: 50px;
        overflow-y: auto;
        .ant-form-item {
            margin-left: 20px;
        }
        .ant-form-item-control-input {
            display: none;
        }
    }
    .footer-audience {
        background: #fff;
        border-top: 4px solid ${({ theme }) => theme['user-theme'] === undefined ? "#b2e7ff" : theme['user-theme']['sidebarColor'] === undefined ? "#b2e7ff" : theme['user-theme']['sidebarColor']};
        width: 100%;
        display: flex;
        align-items: center;
        padding-top: 10px;
        .ant-row {
            width: 100%;
        }
        .ocm-color {
            color: ${({ theme }) => theme['user-theme'] === undefined ? "#0066B2" : theme['user-theme']['sidebarColor'] === undefined ? "#0066B2" : theme['user-theme']['sidebarColor']};    
        }
    }
    .group-area {
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        // min-height: 400px;
        margin-top: 20px;
        flex-direction: column;
        .icon-area {
            height: 160px;
            display: flex;
            align-items: end;
            padding-bottom: 5px;
        }
        .editable-text-field {
            padding: 10px 20px;
            border: 1px solid ${({ theme }) => theme['user-theme'] === undefined ? "#b2e7ff" : theme['user-theme']['sidebarColor'] === undefined ? "#b2e7ff" : theme['user-theme']['sidebarColor']};;
            margin-top: 10px;
            border-radius: 16px;
            .ant-typography-edit {
                margin-left: 10px;
            }
        }
    }
    .Image-grid {
        display: flex;
        align-item: center;
        justify-content: center;
        min-height: 800px;
        background: #BDE3FF;
        div.image-people {
            display: flex;
            align-items: center;
        }
    }
`
const Overview4p = Styled.div`
.circleAddButton {
    background-color: #1270BE !important;
    border: none !important;
    margin: 10px;
}
.hide-checkbox .ant-checkbox-wrapper {
    display: none !important;
  }  
.circleAddButton .ant-btn-icon svg {
    color: white !important;
}
.circleAddButton .ant-btn-icon
{
    background: transparent !important;
    top: 0;
}
Button .ant-btn-icon
{
    background: transparent !important;
    top: 0;
}
.ant-picker {
    min-width: 180px;
    border-color:#1270BE !important;
}
.ant-picker-input
{
    padding: 0px 10px;
    
}
.ant-picker .ant-picker-suffix svg {
    color:#1270BE !important;
}
.ant-btn-primary
{
    background:#1270BE !important;
}

.ant-input,.ant-input-affix-wrapper
{
    height: 37px;
}
.ant-input
{
    border-color: #1270BE !important;
}
.ant-picker-input
{
    border-color: #1270BE !important;
}
.ant-form-item-control-input-content textarea
{
    height: 100px;
}
`
const ActionReview = Styled.div`
.circleAddButton {
    background-color: #1270BE !important;
    border: none !important;
    margin: 10px;
}
.circleAddButton .ant-btn-icon svg {
    color: white !important;
}
.circleAddButton .ant-btn-icon
{
    background: transparent !important;
    top: 0;
}
Button .ant-btn-icon
{
    background: transparent !important;
}
.ant-picker {
    min-width: 180px;
    border-color:#1270BE !important;
}
.ant-picker-input
{
    padding: 0px 10px;
    
}
.ant-picker .ant-picker-suffix svg {
    color:#1270BE !important;
}
.ant-btn-primary
{
    background:#1270BE !important;
}

.ant-input,.ant-input-affix-wrapper
{
    height: 37px;
}
.ant-input
{
    border-color: #1270BE !important;
}
.ant-picker-input
{
    border-color: #1270BE !important;
}
.ant-form-item-control-input-content textarea
{
    height: 100px;
}
.ant-form-item-label label
{
    width: 100%;
}
`

const SuccessMetricScore = Styled.div`
.Mid {
    background-color: #FFC000 !important;
}
.Primary {
    background-color: #FFC000 !important;
}
.HighPositive {
    background-color: #00CC66 !important;
}
.Secondary {
    background-color: #00CC66 !important;
}
.LowPositive {
    background-color: #FF626E !important;
}
.Critical {
    background-color: #FF626E !important;
}
.HighNegative {
    background-color: #00CC66 !important;
}
.LowNegative {
    background-color: #00CC66 !important;
}
.Pending {
    background-color: #E7E6E6 !important;
}
.MasterScore {
    display: flex;
    flex-direction: column;
    flex-direction: revert;
    align-items: center;
    width: 100%;
    padding: 0.1rem;
}
.scoreTitle {
    background-color: #FFFFFF;
    padding: 0.5rem;
    font-size: 1.2rem;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    flex-direction: revert;
    align-items: center;
    width: 70%;
}
.typeTitle {
    background-color: transparent;
    padding: 0.5rem;
    font-size: 1.1rem;
    color: #FFFFFF;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    flex-direction: revert;
    align-items: center;
    justify-content: center;
    width: 30%;
}
.ant-alert .MasterScore .typeTitle
{
    font-size: 15px !important;
    white-space: nowrap;
}
.ant-alert .MasterScore .scoreTitle
{
    white-space: nowrap;
}
.ant-tag-success {
    color: #52c41a !important;
    border-color: #b7eb8f !important;
}
.ant-tag-warning {
    color: #faad14 !important;
    background: #fffbe6 !important;
    border-color: #ffe58f !important;
}
.ant-tag-error {
    color: #ff4d4f !important;
    background: #fff2f0 !important;
    border-color: #ffccc7 !important;
}
`
const OCMPlanStyle = Styled.div`
    ul, ol {
        li {
            font-size: 20px;
            color: rgb(52, 151, 196);
            a {
                font-size: 20px;
                color: rgb(52, 151, 196);
            }
        }
    }
    ol {
        li {
            padding-top: 10px;
        }
    }
    .tableOfContent {
        width: 100%;
        margin: 0 auto;
        li {
            cursor: pointer;
            list-style: disc; padding: 5px; margin-left: 20px;
        }
    }
    .ant-collapse-header {
        page-break-before: always; /* Forces a page break before this element */
        page-break-inside: avoid;
        min-height: 60px;
        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#b2e7ff" : theme['user-theme']['sidebarColor'] === undefined ? "#b2e7ff" : theme['user-theme']['sidebarColor']};
        display: flex;
        color: ${({ theme }) => theme['user-theme'] === undefined ? "#b2e7ff" : theme['user-theme']['CollapseTextColor'] === undefined ? "#b2e7ff" : theme['user-theme']['CollapseTextColor']};
        align-items: center;
        justify-content: center;
        .ant-collapse-expand-icon {
            svg {
                font-size: 26px !important;
                top: 3px !important;
            }
        }
        .ant-collapse-header-text {
            font-size: 20px;
            font-weight: bold;
            text-align: center;
            color: ${({ theme }) => theme['user-theme'] === undefined ? "#b2e7ff" : theme['user-theme']['CollapseTextColor'] === undefined ? "#b2e7ff" : theme['user-theme']['CollapseTextColor']};
        }
    }
`
const OCMPlanMainStyle = Styled.div`
    ul, ol {
        li {
            font-size: 17px;
            a {
                font-size: 17px;
                color: rgb(52, 151, 196);
            }
        }
    }
    ol {
        li {
            padding-top: 10px;
        }
    }
    .tableOfContent {
        width: 100%;
        margin: 0 auto;
        li {
            cursor: pointer;
            list-style: disc; padding: 5px; margin-left: 20px;
        }
    }
    .ant-collapse-content p
    {
        color: black !important;
    }
    a
    {
        color:#487CB8 !important;
        font-weight:600;
    }
    ul li
    {
        line-height: 30px;
    }
    .ant-collapse-header {
        min-height: 60px;
        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#b2e7ff" : theme['user-theme']['sidebarColor'] === undefined ? "#b2e7ff" : theme['user-theme']['sidebarColor']};
        color: ${({ theme }) => theme['user-theme'] === undefined ? "#b2e7ff" : theme['user-theme']['CollapseTextColor'] === undefined ? "#b2e7ff" : theme['user-theme']['CollapseTextColor']};
        display: flex;
        align-items: center;
        justify-content: center;
        .ant-collapse-expand-icon {
            svg {
                font-size: 26px !important;
                top: 3px !important;
            }
        }
        .ant-collapse-header-text {
            font-size: 20px;
            font-weight: bold;
            text-align: center;
            color: ${({ theme }) => theme['user-theme'] === undefined ? "#b2e7ff" : theme['user-theme']['CollapseTextColor'] === undefined ? "#b2e7ff" : theme['user-theme']['CollapseTextColor']};
        }
    }
    .popover {
        position: relative;
        display: inline-block;
        cursor: pointer;
    }

    /* Popover content styles */
    .popover-content {
        display: none;
        position: absolute;
        background-color: #ffffff;
        border: 1px solid #ccc;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        padding: 10px;
        border-radius: 4px;
        z-index: 1;
        min-width: 200px;
    }

    /* Popover arrow styles */
    .popover-arrow {
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px;
        border-color: transparent transparent #ffffff transparent;
        top: -16px;
        left: 50%;
        transform: translateX(-50%);
    }

    /* Show popover content on hover */
    .popover:hover .popover-content {
        display: block;
    }
`

const AmazonPlanMainStyle = Styled.div`
    ul, ol {
        li {
            font-size: 17px;
            a {
                font-size: 17px;
                color: rgb(52, 151, 196);
            }
        }
    }
    ol {
        li {
            padding-top: 10px;
        }
    }
    .tableOfContent {
        width: 100%;
        margin: 0 auto;
        li {
            cursor: pointer;
            list-style: disc; padding: 5px; margin-left: 20px;
        }
    }
    .ant-collapse-content p
    {
        color: black !important;
    }
    a
    {
        color:#487CB8 !important;
        font-weight:600;
    }
    ul li
    {
        line-height: 30px;
    }
    .ant-collapse-header {
        min-height: 60px;
        background-color: ${({ theme }) => theme['user-theme'] === undefined ? "#b2e7ff" : theme['user-theme']['sidebarColor'] === undefined ? "#b2e7ff" : theme['user-theme']['sidebarColor']};
        color: ${({ theme }) => theme['user-theme'] === undefined ? "#b2e7ff" : theme['user-theme']['CollapseTextColor'] === undefined ? "#b2e7ff" : theme['user-theme']['CollapseTextColor']};
        display: flex;
        align-items: center;
        justify-content: center;
        .ant-collapse-expand-icon {
            svg {
                font-size: 26px !important;
                top: 3px !important;
            }
        }
        .ant-collapse-header-text {
            font-size: 20px;
            font-weight: bold;
            text-align: center;
            color: ${({ theme }) => theme['user-theme'] === undefined ? "#b2e7ff" : theme['user-theme']['CollapseTextColor'] === undefined ? "#b2e7ff" : theme['user-theme']['CollapseTextColor']};
        }
    }
    .popover {
        position: relative;
        display: inline-block;
        cursor: pointer;
    }

    /* Popover content styles */
    .popover-content {
        display: none;
        position: absolute;
        background-color: #ffffff;
        border: 1px solid #ccc;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        padding: 10px;
        border-radius: 4px;
        z-index: 1;
        min-width: 200px;
    }

    /* Popover arrow styles */
    .popover-arrow {
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px;
        border-color: transparent transparent #ffffff transparent;
        top: -16px;
        left: 50%;
        transform: translateX(-50%);
    }

    /* Show popover content on hover */
    .popover:hover .popover-content {
        display: block;
    }
`

const PostImplementation = Styled.div`
    .circleAddButton {
        background-color: #1270BE !important;
        border: none !important;
        margin: 10px;
    }
    .circleAddButton .ant-btn-icon svg {
        color: white !important;
    }
    
    .ant-progress-inner {
        border: 1px solid #1270BE !important;
    }
    .ant-progress-text
    {
        color: #1270BE !important;
    }
    .circleAddButton .ant-btn-icon
    {
        background: transparent !important;
        top: 0;
    }
    Button .ant-btn-icon
    {
        background: transparent !important;
        top: 0;
    }
    .ant-picker {
        min-width: 180px;
        border-color:#1270BE !important;
    }
    .ant-picker-input
    {
        padding: 0px 10px;
        
    }
    .ant-picker .ant-picker-suffix svg {
        color:#1270BE !important;
    }
    .ant-btn-primary
    {
        background:#1270BE !important;
    }

    .ant-input,.ant-input-affix-wrapper
    {
        height: 37px;
    }
    .ant-input
    {
        border-color: #1270BE !important;
    }
    .ant-picker-input
    {
        border-color: #1270BE !important;
    }
    .ant-form-item-control-input-content textarea
    {
        height: 100px;
    }
`

const AddDataMetricStyle = Styled.div`
    width: 100%;
    .ant-radio-wrapper .ant-radio
    {
        margin-bottom: 10px;
    }
    .ant-radio-wrapper .ant-radio .ant-radio-inner
    {
        width: 24px;
        height: 24px;
    }
    .risk-status .ant-radio-wrapper .ant-radio
    {
        margin-bottom: 0;
    }
    .risk-status .ant-radio-wrapper .ant-radio .ant-radio-inner
    {
        width: 30px;
        height: 30px;
    }
    .ant-slider-step, .ant-slider-rail, .ant-slider-track {
        height: 14px;
        border-radius: 50px;
    }
`

const AdoptionRiskTableStyle = Styled.div`
    width: 100%;
    .ant-table-thead > tr > th
    {
        background-color: #232F3E !important;
        border-color: #FFF !important;
        color: #FFFFFF;
        font-size: 20px;
    }
    .table-row-light {
        background-color: #ffffff !important;
    }
    .table-row-dark td{
        background-color: #DFEEFC !important;
    }
    .ant-table-row[data-row-key="1"] {
        td {
            &:first-child[rowspan] {
                background: ${({ theme }) => theme['user-theme'] === undefined ? "#0EC1C4" : theme['user-theme']['Phase1Color'] === undefined ? "#0EC1C4" : theme['user-theme']['Phase1Color']} !important;
                color: #FFFFFF;
                font-size: 20px;
            }
        }
    }
    .ant-table-row[data-row-key="2"] {
        td {
            &:first-child[rowspan] {
                background: ${({ theme }) => theme['user-theme'] === undefined ? "#0EC1C4" : theme['user-theme']['Phase2Color'] === undefined ? "#0EC1C4" : theme['user-theme']['Phase2Color']} !important;
                color: #FFFFFF;
                font-size: 20px;
            }
        }
    }
    .ant-table-row[data-row-key="3"] {
        td {
            &:first-child[rowspan] {
                background: ${({ theme }) => theme['user-theme'] === undefined ? "#0EC1C4" : theme['user-theme']['Phase3Color'] === undefined ? "#0EC1C4" : theme['user-theme']['Phase3Color']} !important;
                color: #FFFFFF;
                font-size: 20px;
            }
        }
    }
    .ant-table-row[data-row-key="4"] {
        td {
            &:first-child[rowspan] {
                background: ${({ theme }) => theme['user-theme'] === undefined ? "#0EC1C4" : theme['user-theme']['Phase4Color'] === undefined ? "#0EC1C4" : theme['user-theme']['Phase4Color']} !important;
                color: #FFFFFF;
                font-size: 20px;
            }
        }
    }
    
`

const FreeUserToolStyle = Styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h1 {
        margin-top: 10px;
    }
`

const VideoTutorialsStyle = Styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .ant-list {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        .ant-spin-nested-loading {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            .ant-spin-container {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                .ant-row {
                    padding: 20px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                }
            }
        }
    }
    .loadmore-list {
        padding: 0px;
        .ant-card {
            border-radius: 18px;
            background-color: #F2F2F2;
            .ant-card-body {
                min-height: auto;
                height: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #ccc;
            }
            .ant-card-head {
                position: relative;
                background-color: #FFFFFF !important;
                border: 1px solid #ccc;
            }
            .ant-card-head-wrapper {
                white-space: nowrap;
            }
            .title-header {
                position: absolute;
                top: 10px;
                right: 10px;
            }
            .ant-card-head-title {
                padding-right: 20px;
                white-space: normal;
                font-size: 12px;
                font-weight: 600;
            }
            .big-icon {
                font-size: 60px;
            }
        }    
    }
`

export {
    FreeUserToolStyle,
    VideoTutorialsStyle,
    AdoptionRiskTableStyle,
    ToolsDashboardPage,
    StakeholderSegmentContentStyle,
    AudienceRiskPanelStyle,
    AudienceResistancePanelStyle,
    ReadinessCascaderStyle,
    AdoptionCascaderStyle,
    RadioPanelStyle,
    ReadinessRadioPanelStyle,
    ToolsHomePage,
    ToolsConfigurationPanel,
    ToolsHomeTabStyle,
    ToolsFreeTab,
    ToolHeaderStyle,
    ToolInfoStyle,
    EngagementPlanHeader,
    OCMModalHeader,
    OCMModal,
    EngagementPlanBody,
    ChampionModalBodyStyle,
    TaskCheckListContent,
    LevelOfImpect,
    ImpectLevelStyle,
    RiskStyleDiv,
    DefaultDataPopUp,
    SuggestedChampionStyle,
    RadioPanelStyleImpect,
    TagBoxCssImpect,
    TrainingDiv,
    AudienceAccordianGroupView,
    AudienceGroupView,
    AddTrainingStyle,
    RadioPanelStyleTraining,
    CalenDerTraining,
    ListViewContainerStyle,
    ProjectsDiv,
    CommunicationStyle,
    CommunicationStyleAdd,
    ChangeRiskStyleDiv,
    ProjectAssessmentStyle,
    TargetAudienceGridUserStyle,
    TargetAudienceStyle,
    Overview4p,
    ActionReview,
    SuccessMetricScore,
    OCMPlanStyle,
    PostImplementation,
    AddDataMetricStyle,
    StakeholderTypeModifyStyle,
    StakeholderSegmentContentChampionStyle,
    OCMPlanMainStyle,
    AmazonPlanMainStyle
};
