import { useEffect, useState } from 'react';
import { Button, Form, Spin } from 'antd';
import styled from 'styled-components';
import { Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-duotone-svg-icons';
import ReportModel from '../../ui-elements/ReportModel';
import { api } from '../../../config/dataService/useApi';
import { useReportsDispatch, useReportsState } from '../context/reports';
import { reducerTypes } from '../context/reports/constants';

const { TextArea } = Input;
const DescriptionModel = ({
  title,
  open,
  handleModelClose,
  footer = null,
  description,
  defaultDescription = '',
  reportSection,
  reportKey,
  reportInfoId,
}) => {
  const [form] = Form.useForm();
  const [isChanging, setIsChanging] = useState(description || !defaultDescription);
  const [loading, setLoading] = useState(false);
  const dispatch = useReportsDispatch();
  const { reportsDescriptions } = useReportsState();
  const handleSave = async (e) => {
    setLoading(true);
    let endPoint = '';
    let method = '';
    if (description && reportInfoId) {
      endPoint = `/reports/info/${reportInfoId}`;
      method = 'put';
    } else {
      endPoint = `/reports/info`;
      method = 'post';
    }

    const res = await api(endPoint, method, { reportSection, description: e.description, reportKey });
    setLoading(false);

    if (res.status === 200) {
      let mearged = [];
      if (reportInfoId) {
        mearged = reportsDescriptions[reportSection].map((element) =>
          element.reportKey === reportKey ? res.data.reportInfo : element,
        );
      } else {
        mearged = [...reportsDescriptions?.[reportSection], { ...res.data.reportInfo }];
      }

      dispatch({
        type: reducerTypes.REPORTS_DESCRIPTIONS,
        payload: { ...reportsDescriptions, [reportSection]: mearged },
      });
      handleModelClose();
    }
  };

  useEffect(() => {
    form.setFieldsValue({ description: description || defaultDescription });
  }, [description, defaultDescription]);

  const onValuesChange = (changedValues, allValues) => {
    if (isChanging !== (description === changedValues.description)) {
      setIsChanging(description === changedValues.description);
    }
  };

  const onDelete = async () => {
    setLoading(true);
    const res = await api(`/reports/info/${reportInfoId}`, 'delete');
    setLoading(false);
    if (res.status === 200) {
      let mearged = reportsDescriptions[reportSection].filter((element) => element.id !== reportInfoId);
      dispatch({
        type: reducerTypes.REPORTS_DESCRIPTIONS,
        payload: { ...reportsDescriptions, [reportSection]: mearged },
      });
      handleModelClose();
    }
  };

  return (
    <ReportModel title={title} open={open} close={handleModelClose} footer={footer} width="675px">
      <Container form={form} layout={'vertical'} onFinish={handleSave} onValuesChange={onValuesChange}>
        <div>
          <Form.Item label=" *Description" name={'description'} initialValue={''}>
            <TextArea rows={4} maxLength={210} showCount />
          </Form.Item>
        </div>

        <ButtonContainer>
          {reportInfoId && (
            <div style={{ cursor: 'pointer' }}>
              <FontAwesomeIcon onClick={onDelete} icon={faTrash} size={'xl'} />
            </div>
          )}
          <div
            style={{ display: 'flex', justifyContent: 'center', margin: '0px 40px 0px', gap: '100px', width: '100%' }}
          >
            <Button
              style={{
                backgroundColor: 'transparent',
                color: '#000000',
                border: 'none',
                borderRadius: '0px',
                borderBottom: '1px solid #000000',
                padding: '0px',
                margin: '0px',
                fontSize: '16',
                fontWeight: 500,
                lineHeight: '16px',
              }}
              onClick={handleModelClose}
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: isChanging || loading ? '#7F7F7F' : '#0066B2',
                color: '#FFFFFF',
                borderRadius: '16px',
                width: '100px',
                fontSize: '16',
                fontWeight: 500,
                lineHeight: '16px',
              }}
              htmlType="submit"
              disabled={isChanging}
            >
              {loading ? <Spin /> : 'Save'}
            </Button>
          </div>
        </ButtonContainer>
      </Container>
    </ReportModel>
  );
};

export default DescriptionModel;

const Container = styled(Form)`
  background-color: #fff;
  width: 100%;
  position: relative;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
