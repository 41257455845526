import { useState } from 'react';
import styled from 'styled-components';
import { DataService } from '../../../../config/dataService/dataService';
import TextEditor from '../../../tools/panel/widgets/TextEditor';
import { ActionContainer, PopupAction } from './Style';
import { Spin } from 'antd';
import useNotificationController from '../../../../utility/useNotificationController';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AccountName = localStorage.getItem('full_name');
const getEmailTemplate = (surveyId, surveyName, surveyEndDate) => `
<p>The end date for the survey, ${surveyName} has been updated to ${surveyEndDate}.</p>
<br>
<p>If you haven't submitted the survey yet click the "Begin Survey" button below, otherwise, you may ignore this message.</p>
<p>&nbsp;</p>
<p style="text-align: center;">
  <a id="noneEditableButton" style="background: #39AAFF; text-decoration: none; border-radius: 5px; padding: 8px 15px; border: 1px solid #000; color: #fff; font-size: 20px;" href="${window.location.protocol}//${window.location.host}/public/survey/${surveyId}">
    Begin Survey
  </a>
</p>
<p style="text-align: left;">Thank you!</p>
<p><br><br></p>
<p><strong>Note:</strong> Please do not reply to this email. If you have questions, please reach out to ${AccountName} directly.</p>
<p>&nbsp;</p>
<hr style="border-color: #000;">
<p>&nbsp;</p>
<p style="text-align: left;"><span style="font-size: 10px;">&nbsp;The survey owner creates this content. The data you collect will be sent to the survey owner. OCM Solutions to your customers is not responsible for the privacy or security practices, including those of the owner of this survey. See our privacy policy | Terms of Use</span></p>
`; 

const UpdateEmail = ({ onClose, backToSettings, setIsLoaing, loading, surveyData, surveyEndDate }) => {
  const {
    contextHolder,
    openError,
    openSuccess,
  } = useNotificationController();
  
  const [content, setContent] = useState(getEmailTemplate(surveyData.id, surveyData?.surveyName, surveyEndDate));

  const handleEditorChange = (newContent) => {
    const button = `<a id="noneEditableButton"`;

    if (newContent.includes(button)) {
      setContent(newContent);
    }
  };
  const sendEmail = async () => {
    if (content === '') {
      return;
    }
    setIsLoaing(true);
    const data = {
      userSurveyId: surveyData.id,
      html: content,
    };

    try {
      const response = await DataService.post('surveys/send-survey-update-email', data);

      if (response.status === 200) {
        openSuccess('bottomRight', "Email",  `Email is sent successfully!`);
        onClose();
      }
    } catch (err) {
      console.error(err);
      openError('topLeft', 'Survey Error', "Something went wrong");
    } finally {
      setIsLoaing(false);
    }
  };

  return (
    <Container>
      {contextHolder}
      <Heading>Would you like send an update email to the existiong survey recipients?</Heading>

      <TextEditor
        toolbar_location={'top'}
        toolbar={
          'bold italic underline strikethrough forecolor backcolor | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify'
        }
        value={content}
        height={800}
        onEditorChange={(e) => handleEditorChange(e)}
      />

      <ActionContainer>
        <PopupAction variant="success" onClick={backToSettings}>
          Back to Settings
        </PopupAction>
        <PopupAction variant="border" onClick={onClose}>
          Don't Send
        </PopupAction>
        <PopupAction variant={!content.trim() ? 'disable' : 'default'} onClick={sendEmail}>
          {loading ? <Spin indicator={<FontAwesomeIcon icon={regular("circle-notch")} spin style={{ color: 'white' }} />} /> : 'Send'}
        </PopupAction>
      </ActionContainer>
    </Container>
  );
};

const Heading = styled.h3`
  margin-bottom: 10px;
`;

const Container = styled.div`
  padding: 10px 0px;
  width: 100%;
`;

export default UpdateEmail;
