import styled from 'styled-components';

export const PopupAction = styled.button`
  cursor: pointer;
  padding: 12px 30px;
  border-radius: 10px;
  border: ${({ variant }) => (variant === 'border' ? '1px solid black' : 'none')};
  font-size: 18px;
  background: ${({ variant }) => {
    return variant === 'danger'
      ? '#FE5D5F'
      : variant === 'disable'
      ? '#F2F2F2'
      : variant === 'success'
      ? '#39FF9D'
      : variant === 'border'
      ? 'transparent'
      : '#39AAFF';
  }};
  color: ${({ variant }) => {
    return variant === 'danger'
      ? '#fff'
      : variant === 'disable'
      ? '#8A8A8A'
      : variant === 'border' || variant === 'success'
      ? '#000'
      : '#fff';
  }};
`;

export const PopupDescription = styled.p`
  text-align: center;
  font-size: 20px;
  color: #000;
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
  margin-top: 30px;
`;

export const PopupContent = styled.div`
  padding: 30px 50px;
  padding-bottom: 0px;
  min-width: 550px;
`;

export const DownloadWarpper = styled.div`
  padding: 20px;
`;
