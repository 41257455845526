import Popup from './Popup';
import { PopupDescription, PopupAction, ActionContainer, PopupContent } from './Style';

const DeleteSurveyPopup = ({ open, onClose, onSubmit }) => {
  return (
    <Popup
      title={'Delete Survey'}
      open={open}
      close={onClose}
      footer={[
        <ActionContainer>
          <PopupAction variant="disable" onClick={onClose}>
            Cancel
          </PopupAction>
          <PopupAction variant="danger" onClick={onSubmit}>
            Delete
          </PopupAction>
        </ActionContainer>,
      ]}
    >
      <PopupContent>
        <PopupDescription>Are you sure you want to delete this survey?</PopupDescription>
        <PopupDescription>This action cannot be undone, and all survey data will be permanently lost.</PopupDescription>
      </PopupContent>
    </Popup>
  );
};

export default DeleteSurveyPopup;
