import Popup from "../Popup/Popup";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spin } from 'antd';
import { ActionContainer, PopupAction } from "../Popup/Style";
import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";

export default function DownloadSurveyPopup({ open, isDownloadingSurvey, onClose, onDownload }) {
  return (
    <Popup title="Download Survey" open={open} close={onClose} footer={null} >

      <Spin tip="Downloading" size="large" spinning={isDownloadingSurvey}>
        <div style={{ width: 400, textAlign: 'center' }}>
          <button style={{
            height: 100,
            width: 100,
            border: 'none',
            cursor: 'pointer',
            borderRadius: 10,
          }}
            onClick={() => onDownload('pdf')}
          >
            <FontAwesomeIcon icon={duotone("file-pdf")} style={{"--fa-primary-color": "#ff0000", "--fa-secondary-color": "#ff0000", fontSize: '50px'}} />
          </button>
        </div>
      </Spin>

      <ActionContainer>
        <PopupAction variant='disable' disabled={isDownloadingSurvey} onClick={onClose}>
          Cancel
        </PopupAction>
      </ActionContainer>
    </Popup>
  )
}