import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getItem } from '../../../../utility/localStorageControl';
import { Upload } from 'antd';
import { faPenSquare } from '@fortawesome/pro-duotone-svg-icons';
import AmazonLogo from '../../../../static/images/Amazon_logo.svg.webp';
import DefaultLogo from '../../assets/default-logo.svg';
import styled from 'styled-components';
import useNotificationController from '../../../../utility/useNotificationController';

const UploadLogo = ({ isEditing, url, onLogoChange, isAmazon }) => {
  const {
    contextHolder,
    openError,
  } = useNotificationController();

  const API_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}/`;

  const headers = {
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      openError('topLeft', 'Upload Logo Error', 'You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      openError('topLeft', 'Upload Logo Error', 'Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const uploadImage = (info) => {
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      onLogoChange(info.file.response.image);
    }
  };

  let defaultURL = true;
  if (isAmazon) {
    defaultURL = false;
  }


  return (
    <Container hasDefaultUrl={defaultURL}>
      {contextHolder}
      <Upload
        name="image"
        listType="picture-card"
        showUploadList={false}
        action={API_ENDPOINT + 'surveys/upload-survey-image'}
        headers={headers}
        openFileDialogOnClick={isEditing}
        accept='image/jpeg, image/png'
        onChange={uploadImage}
        beforeUpload={beforeUpload}
      >
        {isAmazon ? (
          <div
            style={{
              width: '100%',
              height: '100%',
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundSize: `${url ? 'cover' : 'contain'}`,
              backgroundRepeat: 'no-repeat',
              borderRadius: '8px',
              backgroundImage: `${url
                  ? `url('${process.env.REACT_APP_API_ENDPOINT}/images/survey_images/${encodeURI(url)}')`
                  : `url(${AmazonLogo})`
                }`,
            }}
          >
            {isEditing && (
              <FontAwesomeIcon
                icon={faPenSquare}
                size="2xl"
                style={{
                  float: 'right',
                  margin: '-8px',
                  background: '#000',
                  borderRadius: '5px',
                  height: '24.5px',
                  width: '24.5px',
                }}
                color="#fff"
              />
            )}
          </div>
        ) : (
          <div
            style={{
              width: '100%',
              height: '100%',
              backgroundSize: 'cover',
              borderRadius: '8px',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundImage: `${url
                  ? `url('${process.env.REACT_APP_API_ENDPOINT}/images/survey_images/${encodeURI(url)}')`
                  : `url(${DefaultLogo})`
                }`,
            }}
          >
            {isEditing && (
              <FontAwesomeIcon
                icon={faPenSquare}
                size="2xl"
                style={{
                  float: 'right',
                  margin: '-8px',
                  background: '#000',
                  borderRadius: '5px',
                  height: '24.5px',
                  width: '24.5px',
                }}
                color="#fff"
              />
            )}
          </div>
        )}
      </Upload>
    </Container>
  );
};

const Container = styled.div`
  .ant-upload-wrapper {
    width: auto;
    .ant-upload.ant-upload-select {
      width: 145px;
      margin: 0;
      border: none;
      background-color: ${({ hasDefaultUrl }) => (hasDefaultUrl ? 'transparent' : '#ffffff')};
    }
  }
`;

export default UploadLogo;
