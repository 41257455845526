import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as ProIcons from '@fortawesome/pro-duotone-svg-icons';
import { FreeResourceItemWrapper, FreeResourceItemTitle, FreeResourceSeparator, FreeResourceStyledLink } from './FreeResources.styles';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';

const FreeResourceItem = ({ handleDownload, downloadingTitle, content, category }) => {
    library.add(ProIcons[content.faicon]);

    return (
      <FreeResourceStyledLink onClick={(e) => handleDownload(content, category.name, true)}>
        <FreeResourceItemWrapper>
          <div style={{width: "50px", textAlign: "center", paddingLeft: "15px"}}>
            {downloadingTitle[content.fileKey] ? (
              <FontAwesomeIcon icon={duotone("spinner-third")} spin 
                style={{
                  '--fa-primary-color': `${content.primary_color}`,
                  '--fa-secondary-color': `${content.secondary_color}`,
                  width: "30px",
                  height: "30px",
                  paddingRight: "10px"
                }}
                className="big-icon-tools"
              />
            ) : (
              <FontAwesomeIcon
                  icon={`fa-${content.icon_type} fa-${content.icon}`}
                  style={{
                    '--fa-primary-color': `${content.primary_color}`,
                    '--fa-secondary-color': `${content.secondary_color}`,
                    width: "30px",
                    height: "30px",
                    paddingRight: "10px"
                  }}
              />
            )}
          </div>
          {process.env.REACT_APP_SHOW_RESOURCE_ICON === "true" && (
            <FreeResourceSeparator />
          )}
          <FreeResourceItemTitle style={{textDecoration: process.env.REACT_APP_SHOW_RESOURCE_ICON === "true" ? "underline" : "none"}}>
            {content.name}
          </FreeResourceItemTitle>
        </FreeResourceItemWrapper>
      </FreeResourceStyledLink>
    );
}

export default FreeResourceItem;