import React from 'react';
import { Form, Input, Typography } from 'antd';

const { Title, Text } = Typography;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';

function AddNewLocation(props) {

  const newLocationStartIndex = Object.keys(props.currentLocationNames).filter(key => !props.currentLocationNames[key].new).length;


  return (
    <Form.List name={props.listKey}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <Form.Item
              {...restField}
              key={`${props.listKey}_${props.fieldName}_${key}`}
              size="large"
              name={[name, props.fieldKey]}
              style={{ marginBottom: 10 }}
              extra={(
                <div className='group-area'>
                  <div className='icon-area'>
                    {newLocationStartIndex + name == 0 && <FontAwesomeIcon size='8x' icon={duotone("map-location")} style={{ "--fa-primary-color": "#0077ce", "--fa-secondary-color": "#20a0ff", }} />}
                    {newLocationStartIndex + name == 1 && <FontAwesomeIcon size='5x' icon={duotone("map-location")} style={{ "--fa-primary-color": "#0077ce", "--fa-secondary-color": "#20a0ff", }} />}
                    {newLocationStartIndex + name > 1 && <FontAwesomeIcon size='4x' icon={duotone("map-location")} style={{ "--fa-primary-color": "#0077ce", "--fa-secondary-color": "#20a0ff", }} />}
                  </div>
                  <Title
                    level={4}
                    editable={{
                      icon: <FontAwesomeIcon icon={duotone("pencil")} style={{"--fa-primary-color": "#0077ce", "--fa-secondary-color": "#20a0ff", marginLeft: "10px"}} />,
                      triggerType: ['icon', 'text'],
                      tooltip: 'click to edit initial Location name',
                      onChange: (value) => {
                        props.setCurrentLocationNames({ ...props.currentLocationNames, [newLocationStartIndex + name]: { name: value, new: true } });
                      },
                    }}
                    className='editable-text-field'>
                    {props.currentLocationNames[newLocationStartIndex + name]?.name}
                  </Title>
                  {name === 0 ? (
                    <Text type='secondary'>(e.g., State, District, Province)</Text>
                  ) : (
                    <Text type='secondary'>(e.g.,City, Town)</Text>
                  )}
                  {props.currentGroup !== 0 && newLocationStartIndex !== name && <Text
                    type="danger"
                    underline
                    style={{ cursor: "pointer" }}
                    onClick={() => {

                      let keyCount = 0;
                        const reducedLocationNames = Object.keys(props.currentLocationNames).reduce((acc, key) => {
                          if (Number(key) >= newLocationStartIndex + name) {
                            remove(name)
                            return {
                              ...acc,
                            }
                          }

                          const newKey = keyCount;
                          keyCount += 1;

                          return {
                            ...acc,
                            [newKey]: props.currentLocationNames[key]
                          }
                        }, {})

                      let currentLocation = keyCount - 1
                      props.setCurrentLocation(currentLocation);
                      props.setCurrentLocationNames(reducedLocationNames)
                    }}
                  >Delete
                  </Text> }
                  
                </div>
              )
              }
            >
              <Input type={'hidden'} placeholder={props.fieldLabel} />
            </Form.Item>
          ))}

          <Form.Item
            key={`tooltip-${props.listKey}_${props.fieldName}`}
            extra={(
              <Title level={2} style={{ marginTop: "50px" }}>
                <FontAwesomeIcon icon={duotone("circle-question")} style={{ "--fa-primary-color": "#0077ce", "--fa-secondary-color": "#20a0ff", marginRight: "20px", cursor: "pointer" }} onClick={props.showHelp} />
                Add sub location levels
                <FontAwesomeIcon icon={duotone("circle-plus")} style={{ "--fa-primary-color": "#0077ce", "--fa-secondary-color": "#20a0ff", marginLeft: "20px", cursor: "pointer" }} onClick={() => {
                  let currentLocation = props.currentLocation;
                  currentLocation++;
                  props.setCurrentLocation(currentLocation);
                  props.setCurrentLocationNames({ ...props.currentLocationNames, [props.currentLocation + 1]: { name: `Location Level ${currentLocation + 1}`, new: true } });
                  add();
                }} />
              </Title>
            )}
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
          </Form.Item>
        </>
      )}
    </Form.List>
  );
}

export default AddNewLocation;
