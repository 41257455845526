import React, { useRef, useEffect, useState } from 'react';
import PieChartOutLabels from '@energiency/chartjs-plugin-piechart-outlabels';
import {
  Chart,
  BarController,
  BarElement,
  LinearScale,
  TimeScale,
  Tooltip,
  Legend,
  PieController,
  ArcElement,
} from 'chart.js';
import 'chartjs-adapter-moment';
import { CategoryScale } from 'chart.js';
import 'chart.js/auto';

Chart.register(
  CategoryScale,
  PieController,
  BarController,
  BarElement,
  LinearScale,
  TimeScale,
  Tooltip,
  Legend,
  ArcElement,
  PieChartOutLabels,
);

const PieChart = ({ data, width, height, backgroundColors }) => {
  const [chart, setChart] = useState(null);
  const canvas4 = useRef(null);
  let i = 0;
  useEffect(() => {
    const ctx = canvas4?.current;

    const newChart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: data?.labels,
        datasets: [
          {
            //label: 'My First Dataset',
            data: data?.data,
            backgroundColor: backgroundColors  ||  ['#FFC000', '#FF626E', '#00CC66'],
            hoverOffset: 4,
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },

        autoPadding: true,
        layout: {
          padding: {
            top: 30,
            bottom: 30,
            left: 40,
            right: 30,
          },
        },
        interaction: {
          intersect: false,
          mode: 'nearest',
        },

        animation: {
          duration: 500,
        },
        responsive: true,
        maintainAspectRatio: false,
        resizeDelay: 200,

        plugins: {
          datalabels: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
          legend: { display: false },
          outlabels: {
            text: ' %l, %p',
            color: 'black',
            stretch: 8,
            font: {
              resizable: true,
              minSize: 12,
              maxSize: 18,
            },
          },

          // datalabels: {
          //   formatter: (value, ctx, index) => {
          //     let sum = 0;

          //     let label = ctx?.chart?.data?.labels[ctx?.dataIndex];
          //     i++;
          //     let dataArr = ctx?.chart?.data?.datasets?.[0]?.data;
          //     dataArr?.map((data) => {
          //       sum += data;
          //     });
          //     let percentage = ((value * 100) / sum).toFixed(0) + '% ' + label;
          //     return percentage;
          //   },
          //   color: '#fff',
          // },
        },
      },
    });
    setChart(newChart);

    return () => {
      newChart.destroy();
      // You may want to unregister the plugin if it's not used elsewhere in your app
    };
  }, [data]);

  return (
    <React.Fragment>
      <canvas ref={canvas4}></canvas>
    </React.Fragment>
  );
};

export default PieChart;
