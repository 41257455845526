import React from 'react';
import { FreeOCMTrainingImage, FreeOCMTrainingItemWrapper, FreeOCMTrainingTitle, FreeSeparator, FreeStyledLink } from './FreeLibrarySection.styles';

const FreeOCMTrainingItem = ({ content }) => {
    const slug = localStorage.getItem('organizationalSlug')

    return (
      <FreeStyledLink to={`/${slug}/training/content/${content.slug}`}>
        <FreeOCMTrainingItemWrapper>
          <FreeOCMTrainingImage loading="lazy" src={`${process.env.REACT_APP_API_ENDPOINT}/file/cms/post_image/${content.thumbnailImage}`} alt={content.title} />
          <FreeSeparator />
          <FreeOCMTrainingTitle>{content.title}</FreeOCMTrainingTitle>
        </FreeOCMTrainingItemWrapper>
      </FreeStyledLink>
    );
}

export default FreeOCMTrainingItem;