import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const {
  mainDashboardPhaseBegin,
  mainDashboardPhaseSuccess,
  mainDashboardPhaseErr,

  OCMToolsBegin,
  OCMToolsSuccess,
  OCMToolsErr
} = actions;

const getMainDashboardPhases = (callback) => {
  return async (dispatch) => {
    dispatch(mainDashboardPhaseBegin());
    try {
      const response = await DataService.get('/defaultData/dashboard-phases');
      if (response.status !== 200) {
        const messages = dispatch(mainDashboardPhaseErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(mainDashboardPhaseSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(mainDashboardPhaseErr(err));
      callback(messages);
    }
  };
};

const getOCMTools = (values, callback) => {
  return async (dispatch) => {
    dispatch(OCMToolsBegin());
    try {
      let queryString = "";
      if (Object.entries(values).length > 0)
      {
        queryString = Object.entries(values)
          .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
          .join('&');
        queryString = `?${queryString}`;
      }
      const response = await DataService.get(`/defaultData/tools${queryString}`);
      if (response.status !== 200) {
        const messages = dispatch(OCMToolsErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(OCMToolsSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(OCMToolsErr(err));
      callback(messages);
    }
  };
};


export { 
  getMainDashboardPhases,
  getOCMTools,
};
