export const reportsInitialState = {
  reload: '',
  filter: {
    audiences: [],
    projects: [],
    surveyReports: { surveyStartDate: null, surveySentDate: null, surveyEndDate: null },
    groups: [],
    locations: [],
    jobs: [],
    stakeholders: [],
    changeTypes: [],
  },
  options: {
    projects: [],
    jobs: [],
    groups: [],
    locations: [],
    stakeholders: [],
    changeTypes: [],
  },
  reportsDescriptions: {
    overview: [],
  },
  reportsPDFExport: false,
};

export const reducerTypes = {
  INIT: 'INIT',
  FILTER: 'FILTER',
  OPTIONS: 'OPTIONS',
  RELOAD: 'RELOAD',
  REPORTS_DESCRIPTIONS: 'REPORTS_DESCRIPTIONS',
  REPORT_PDF_EXPORT: 'REPORT_PDF_EXPORT',
};

export const reportDesciptions = {
  no_of_Changes_per_Project_by_Serverity:
    'This report shows the number of changes and the average severity of those impacts for each project. The higher the severity, the higher the risk.',
  no_of_Individuals_per_Project:
    'This report shows the number of individuals per project, which are a count of individuals in the Target Audience. The more stakeholders being impacted, the higher the risk.',
  severity_of_Stakeholder_Impact_by_Project:
    'This report shows the stakeholder groups being impacted by each project and the impact severity. A higher number of impacts and higher severity mean higher risk.',
  Readiness_Levels_per_Project:
    'This report shows the overall readiness level of stakeholders for each project. The lower the readiness, the higher the risk. Readiness is based on: Awareness, Acceptance, Knowledge & Proficiency.',
  Stakeholder_Receptiveness_per_Project:
    'This report shows the receptiveness level of stakeholders for each project. The lower the receptiveness, the higher the risk.',
  Project_Timelines:
    'This report shows the the timelines for each project, based on the start and end dates. This helps identify where project overlaps are happening within the organization.',
  Custom_Metric_from_Go_Live_Assessment_Percentage:
    '(Whatever they type in the description when creating the metric on that tool.)',
  Custom_Metric_from_Go_Live_Assessment:
    '(Whatever they type in the description when creating the metric on that tool.)',
};
