import React, { useState, useEffect } from "react";
import { LicenseContainer } from "./LibrarySection.styles";
import { Divider } from "antd";
import LicenseDetails from "./LicenseDetails";
import LicenseInfo from "./LicenseInfo";


const ChangeManagementLicense = ({userType = 1, organizationalSlug = '', isPaidAccount = false}) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);

    useEffect(() => {
        const handleResize = () => {
        setIsMobile(window.innerWidth <= 991);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    const style = {
        height: isMobile ? 'auto' : '95%',
        width: isMobile ? '100%' : 'auto',
        marginLeft: isMobile ? '0px' : '-2px',
        marginTop: isMobile ? '0px' : '2%',
        alignItems: 'start',
        display: 'flex',
        justifyContent: 'center',
    };

    return (
        <>
            <LicenseContainer>
                <LicenseInfo 
                    userType={userType} 
                    organizationalSlug={organizationalSlug}
                    isPaidAccount={isPaidAccount}
                />
                <Divider className="divider" type={window.innerWidth >= 991 ? 'vertical' : 'horizontal'} 
                    style={style} 
                />
                <LicenseDetails />
            </LicenseContainer>
        </>
    );
}


export default ChangeManagementLicense;