import { Layout } from 'antd';
import Styled from 'styled-components';

const { Footer } = Layout;

const LayoutContainer = Styled.div`
    .ant-layout {
        background-color: transparent;
        .ant-layout-header{
            padding: ${({ theme }) => (!theme.rtl ? '0 5px 0 0' : '0 0 0 5px')};
            height: 72px;
            @media only screen and (max-width: 991px){
                padding: 0 10px;
            }
        }
    }
    .ant-layout.layout {
        background-color: ${({ theme }) => theme[theme.mainContent]['main-background']};
    }

    .ocmsolution-navbar-menu {
        width: 100%;
    }

    /* ocmsolution Header Style */
    .ocmsolution-header-content{
        height: 100%;
        line-height: 0 !important;
        .ocmsolution-info-panel {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .ant-dropdown-trigger{
                font-size: 0.8rem;
                padding-right: 20px;
            }
        }
        .ocmsolution-header-content__left{
            min-width: 280px;
            padding: 0 20px 0 30px;
            background-color: transparent;
            @media only screen and (max-width: 1499px){
                min-width: 220px;
            }
            @media only screen and (max-width: 767px){
                padding: 0 20px 0 8px;
                min-width: auto;
                margin-right: 0;
            }
            .navbar-brand{
                display: flex;
                justify-content: space-between;
                align-items: center;
                button{
                    padding: 0;
                    line-height: 0;
                    margin-top: 4px;
                    color: ${({ theme }) => theme[theme.mainContent]['extra-light-bar']};
                    @media only screen and (max-width: 875px){
                        padding: ${({ theme }) => (theme.rtl ? '0 10px 0 20px' : '0 20px 0 10px')};
                    }
                    @media only screen and (max-width: 767px){
                        order: -1;
                        padding: ${({ theme }) => (theme.rtl ? '0 0 0 15px' : '0 15px 0 0')};
                    }
                }
                img {
                    width: 16px;
                }
            }
            .ocmsolution-logo{
                margin-right: 10px;
                @media only screen and (max-width: 875px){
                    ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 4px;
                }
                @media only screen and (max-width: 767px){
                    ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 0;
                }
                img{
                    max-width: ${({ theme }) => (theme.topMenu ? '220px' : '180px')};
                    width: 100%;
                    @media only screen and (max-width: 475px){
                        max-width: ${({ theme }) => (theme.topMenu ? '100px' : '100px')};
                    }
                }
            }
        }
        .ocmsolution-header-content__right{
            flex: auto;
            height: 100%;
            align-items: center;
            width: 100%;
            .ocmsolution-nav-actions{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                flex: auto;
                padding-right: 12px;
                @media only screen and (max-width: 767px){
                    display: none;
                }
                .ocmsolution-nav-actions__language,
                .ocmsolution-nav-actions__author{
                    line-height: 1;
                }
                .ocmsolution-nav-actions__author{
                    margin: 0 3px;
                    .ocmsolution-nav-action-link{
                        display: flex;
                        align-items: center;
                        i,
                        svg,
                        img {
                            width: 16px;
                            height: 16px;
                            color: ${({ theme }) => theme[theme.mainContent]['light-text']}};
                        }
                        i,
                        svg{
                            position: relative;
                            top: 2px;
                        }
                        .ant-avatar-image{
                            img{
                                min-width: 32px;
                                max-width: 32px;
                                min-height: 32px;
                            }
                        }
                    }
                }
                .ocmsolution-nav-actions__author--name{
                    font-size: 14px;
                    display: inline-block;
                    font-weight: 500;
                    margin: ${({ theme }) => (theme.rtl ? '0 10px 0 6px' : '0 6px 0 10px')};
                    color: ${({ theme }) => theme[theme.mainContent]['gray-text']};
                    @media only screen and (max-width: 991px){
                        display: none;
                    }
                }
            }      
        }
        .ocmsolution-header-content__mobile{
            display: none;
            @media only screen and (max-width: 767px){
                display: block;
            }
            .ocmsolution-mobile-action{
                position: absolute;
                ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 20px;
                top: 50%;
                transform: translateY(-50%);
                display: inline-flex;
                align-items: center;
                @media only screen and (max-width: 767px){
                    ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 15px;
                }
                a,
                .btn-search{
                    display: inline-flex;
                    flex-direction: row;
                    color: ${({ theme }) => theme['light-color']};
                    &.btn-search{
                        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 18px;
                        @media only screen and (max-width: 475px){
                            ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 10px;
                        }
                    }
                    svg{
                        width: 18px;
                        height: 18px;
                    }
                }
            }
        }
    }
    .ocmsolution-header-more{
        .ocmsolution-nav-actions__author{
            .ocmsolution-nav-actions__author--name{
                display: none;
            }
            .ocmsolution-nav-action-link{
                display: flex;
                align-items: center;
                .ant-avatar-image{
                    ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 5px;
                }
                svg{
                    width: 20px;
                    height: 20px;
                    color: ${({ theme }) => theme[theme.mainContent]['light-text']}};
                }
            }
        }
        .ocmsolution-nav-actions__message,
        .ocmsolution-nav-actions__notification,
        .ocmsolution-nav-actions__settings{
            position: relative;
            top: 4px;
        }
        .ocmsolution-nav-actions__message{
            .ant-badge-dot{
                background-color: ${({ theme }) => theme['success-color']}};
            }
        }
    }
    header{
        box-shadow: 0 5px 20px ${({ theme }) => theme['extra-light-color']}05;
        z-index: 998;
        background-color: ${({ theme }) => theme[theme.mainContent]['white-background']};
        @media print {
            display: none;
        }
        .ant-menu-sub.ant-menu-vertical{
            .ant-menu-item{
                a{
                    color: ${({ theme }) => theme[theme.mainContent]['gray-text']};
                }
            }
        }
        .ant-menu.ant-menu-horizontal{
            display: flex;
            align-items: center;
            margin: 0 -16px;
            li.ant-menu-submenu{
                margin: 0 16px;
            }
            .ant-menu-submenu{
                &.ant-menu-submenu-active,
                &.ant-menu-submenu-selected,
                &.ant-menu-submenu-open{
                    background: ${({ theme }) => (theme['user-theme'] === undefined) ? "#493682" : (theme['user-theme']['sidebarHoverColor'] === undefined) ? "#493682" : theme['user-theme']['sidebarHoverColor']};
                    .ant-menu-submenu-title{
                        color: ${({ theme }) => theme[theme.mainContent]['dark-text']};
                        svg,
                        i{
                            color: ${({ theme }) => theme[theme.mainContent]['dark-text']};
                        }
                    }
                }
                .ant-menu-submenu-title{
                    font-size: 14px;
                    font-weight: 500;
                    color: ${({ theme }) => theme[theme.mainContent]['dark-text']};
                    svg,
                    i{
                        color: ${({ theme }) => theme[theme.mainContent]['dark-text']};
                    }
                    .ant-menu-submenu-arrow{
                        display: none;
                        font-family: "FontAwesome";
                        font-style: normal;
                        ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 6px;
                        &:after{
                            color: ${({ theme }) => theme[theme.mainContent]['dark-text']};
                            content: '\f107';
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1150px){
        .ant-layout-sider.ant-layout-sider-collapsed{
            ${({ theme }) => (!theme.rtl ? 'left' : 'right')}: -80px !important;
        }

    }

    .atbd-main-layout{
        ${({ theme }) => (!theme.rtl ? 'margin-left' : 'margin-right')}: ${({ theme }) =>
  theme.topMenu ? 0 : '180px'};
        margin-top: 74px;
        transition: 0.3s ease;
        @media only screen and (max-width: 1200px){
            ${({ theme }) => (!theme.rtl ? 'margin-left' : 'margin-right')}: 80px !important;
        }
        @media only screen and (max-width: 1150px){
            ${({ theme }) => (!theme.rtl ? 'margin-left' : 'margin-right')}: auto !important;
        }
        @media print {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
    }
    .admin-footer{
        background-color: ${({ theme }) => theme[theme.mainContent]['white-background']};
        @media print {
            display: none;
        }
        .admin-footer__copyright{
            display: inline-block;
            width: 100%;
            font-weight: 500;
            color: ${({ theme }) => theme[theme.mainContent]['gray-text']};
            @media only screen and (max-width: 767px){
                text-align: center;
                margin-bottom: 10px;
            }
            a{
                display: inline-block;
                margin-left: 4px;
                font-weight: 500;
                color: ${({ theme }) => theme['primary-color']};
            }
        }
        
    }
    /* Common Styles */
    .ant-radio-button-wrapper-checked {
        &:not(.ant-radio-button-wrapper-disabled){
            background: ${({ theme }) => theme[theme.mainContent].white};
            border-color: ${({ theme }) => theme[theme.mainContent]['menu-active']};
            color: ${({ theme }) => theme[theme.mainContent]['white-text']};
            &:hover{
                border-color: ${({ theme }) => theme[theme.mainContent]['menu-active']};
            }
        }
    }
    .ocmsolution-shade{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255,255,255,0);
        content: "";
        z-index: -1;
        &.show{
            z-index: 101;
        }
    }
`;

const SidebarContainer = Styled.div`
    /* Sidebar styles */
    .ant-layout-sider {
        border: 1px solid #ff0;
        box-shadow: 0 0 20px ${({ theme }) => theme['extra-light-color']}05;
        @media (max-width: 991px){
            box-shadow: 0 0 10px #00000020;
            display: none;
        }
        @media (max-width: 1200px){
            box-shadow: 0 0 10px #00000020;
            width: 80px;
        }
        @media print {
            display: none;
        }

        .loader {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #0066B2;
            height: 100%;
            svg {
                font-size: 80px;
            }
        }

        .custom-scrollbar{
            .ocmsolution-track-vertical{
                position: absolute;
                width: 6px;
                transition: opacity 200ms ease 0s;
                opacity: 0;
                ${({ theme }) => (!theme.rtl ? 'right' : 'left')}: 0;
                bottom: 2px;
                top: 2px;
                border-radius: 3px;
                >div{
                    background-color: ${({ theme }) => theme[theme.mainContent]['scroll-bg']}!important;
                }
            }
        }

        .ant-menu{
            background-color: ${({ theme }) => (theme['user-theme'] === undefined) ? "#0066B2" : (theme['user-theme']['sidebarColor'] === undefined) ? "#0066B2" : theme['user-theme']['sidebarColor']};
        }

        .ant-menu-inline .ant-menu-item,
        .ant-menu-vertical .ant-menu-item,
        .ant-menu-inline .ant-menu-submenu-title,
        .ant-menu-vertical .ant-menu-submenu-title {
            width:calc(100%) !important;
        }

        &.ant-layout-sider-collapsed{
            padding: 50px 0px 55px !important;
            .ant-layout-sider-children{
                .ocmsolution-sidebar-nav-title{
                    display: none;
                }
            }
            & + .atbd-main-layout{
                ${({ theme }) => (!theme.rtl ? 'margin-left' : 'margin-right')}: 80px;

            }
            .ant-menu-item-group{
                display: none;
            }
            .ant-menu-item{
                color: ${({ theme }) => (theme['user-theme'] === undefined) ? "#FFFFFF" : (theme['user-theme']['sidebarTextColor'] === undefined) ? "#FFFFFF" : theme['user-theme']['sidebarTextColor']};
                .badge{
                    display: none;
                }
            }
            .ant-layout-sider-children{
                .ant-menu .ant-menu-submenu, 
                .ant-layout-sider-children .ant-menu .ant-menu-item{
                    ${({ theme }) => (!theme.rtl ? 'padding-right' : 'padding-left')}: 0;
                }
            }
        }

        &.ant-layout-sider-dark{
            background: ${({ theme }) => theme[theme.mainContent]['white-background']};
            .ant-layout-sider-children{
                .ant-menu{
                    .ant-menu-submenu-inline{
                        > .ant-menu-submenu-title{
                            padding: ${(window.innerWidth > 1490) ? "0 20px" : "15px 20px"} !important;
                        }
                    }
                    .ant-menu-item{
                        padding: ${(window.innerWidth > 1490) ? "0 20px" : "15px 20px"} !important;
                    }
                }
            }
        }
        
        .ant-layout-sider-children{
            padding-bottom: 10px;
            
            .ocmsolution-sidebar-nav-title {
                display: flex;
                font-size: 12px;
                font-weight: 500;
                text-transform: uppercase;
                letter-spacing: 1px;
                color: ${({ theme }) => theme[theme.mainContent]['light-text']};
                padding: 0 ${({ theme }) => (theme.rtl ? '20px' : '15px')} 0 0;
                margin: 20px 0 0 0;
            }

            .ocmsolution-sidebar-nav-title{
                &.ocmsolution-sidebar-nav-title-top{
                    margin: 8px 0 0;
                }
            }

            .ant-menu{
                font-size: 14px;
                overflow-x: hidden;
                ${({ theme }) => (theme.rtl ? 'border-left' : 'border-right')}: 0 none;
                &.ant-menu-dark, &.ant-menu-dark .ant-menu-sub, &.ant-menu-dark .ant-menu-inline.ant-menu-sub {
                    background-color: ${({ theme }) => theme[theme.mainContent]['light-background']};
                    
                }
                .ant-menu-sub.ant-menu-inline{
                    background-color: ${({ theme }) => (theme['user-theme'] === undefined) ? "#0066B2" : (theme['user-theme']['sidebarColor'] === undefined) ? "#0066B2" : theme['user-theme']['sidebarColor']};
                }
                
                .ant-menu-submenu-selected{
                    background: ${({ theme }) => (theme['user-theme'] === undefined) ? "#493682" : (theme['user-theme']['sidebarHoverColor'] === undefined) ? "#493682" : theme['user-theme']['sidebarHoverColor']};
                    color: ${({ theme }) => (theme['user-theme'] === undefined) ? "#FFFFFF" : (theme['user-theme']['sidebarTextactiveColor'] === undefined) ? "#FFFFFF" : theme['user-theme']['sidebarTextactiveColor']};
                }
                .ant-menu-submenu, 
                .ant-menu-item{
                    // ${({ theme }) => (!theme.rtl ? 'padding-right' : 'padding-left')}: 5px;
                    color: ${({ theme }) => (theme['user-theme'] === undefined) ? "#FFFFFF" : (theme['user-theme']['sidebarTextColor'] === undefined) ? "#FFFFFF" : theme['user-theme']['sidebarTextColor']};
                    &.ant-menu-item-selected{
                        &:after{
                            content: none;
                        }
                        a{
                            color: ${({ theme }) => (theme['user-theme'] === undefined) ? "#FFFFFF" : (theme['user-theme']['sidebarTextHoverColor'] === undefined) ? "#FFFFFF" : theme['user-theme']['sidebarTextHoverColor']};
                        }
                    }
                    &.ant-menu-submenu-active{
                        >svg,
                        >.ant-menu-submenu-title .ant-menu-title-content{
                            // color: ${({ theme }) => (theme['user-theme'] === undefined) ? "#FFFFFF" : (theme['user-theme']['sidebarTextHoverColor'] === undefined) ? "#FFFFFF" : theme['user-theme']['sidebarTextHoverColor']};
                            color: #FFF;
                        }

                        >.ant-menu-submenu-title{
                            .ant-menu-submenu-arrow:before,
                            .ant-menu-submenu-arrow:after{
                                background-color: ${({ theme }) => theme[theme.mainContent]['menu-active']};
                            }
                            svg{
                                color: ${({ theme }) => theme[theme.mainContent]['menu-active']};
                            }
                        }
                    }
                    &.ant-menu-item-active{
                        .ant-menu-item-icon{
                            svg{
                                color: ${({ theme }) => theme[theme.mainContent]['menu-active']};
                            }
                        }
                        svg{
                            // color: ${({ theme }) => theme[theme.mainContent]['menu-active']};
                        }
                        .ant-menu-title-content{
                            a{
                                // color: ${({ theme }) => (theme['user-theme'] === undefined) ? "#FFFFFF" : (theme['user-theme']['sidebarTextactiveColor'] === undefined) ? "#FFFFFF" : theme['user-theme']['sidebarTextactiveColor']};
                                color: #FFF;
                            }
                        }
                    }
                    .ant-menu-item-icon{
                        svg{
                            transition: color 0.3s;
                        }
                    }
                    svg,
                    img{
                        // width: 18px;
                        font-size: ${(window.innerWidth > 1490) ? "44px" : "28px"};
                        // color: ${({ theme }) => theme[theme.mainContent]['menu-icon-color']};
                    }
                    span{
                        display: inline-block;
                        transition: 0.3s ease;
                    }
                    .ant-menu-title-content{
                        text-align: center;
                        font-weight: 700;
                        color: #fff;
                        // ${({ theme }) => (!theme.rtl ? 'padding-left' : 'padding-right')}: 16px;
                    }
                }
                .ant-menu-item{
                    .menuItem-iocn{
                        width: auto;
                    }
                    &:not(:last-child){
                        margin-bottom: 0;
                    }
                    a{
                        color: ${({ theme }) => (theme['user-theme'] === undefined) ? "#FFFFFF" : (theme['user-theme']['sidebarTextColor'] === undefined) ? "#FFFFFF" : theme['user-theme']['sidebarTextColor']};
                    }
                }
                .ant-menu-submenu{
                    &.ant-menu-submenu-open{
                        >.ant-menu-submenu-title{
                            display: flex;
                            align-items: center;
                            color: ${({ theme }) => (theme['user-theme'] === undefined) ? "#FFFFFF" : (theme['user-theme']['sidebarTextColor'] === undefined) ? "#FFFFFF" : theme['user-theme']['sidebarTextColor']};    
                            .title{
                                padding-left: 0;
                            }
                            .badge{
                                ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 45px;
                            }
                            span{
                                font-weight: 500;
                                color: ${({ theme }) => (theme['user-theme'] === undefined) ? "#FFFFFF" : (theme['user-theme']['sidebarTextactiveColor'] === undefined) ? "#FFFFFF" : theme['user-theme']['sidebarTextactiveColor']};
                            }
                            // svg,
                            i,
                            .ant-menu-submenu-arrow{
                                display: none;
                                color: ${({ theme }) => (theme['user-theme'] === undefined) ? "#FFFFFF" : (theme['user-theme']['sidebarTextactiveColor'] === undefined) ? "#FFFFFF" : theme['user-theme']['sidebarTextactiveColor']};
                                &:after,
                                &:before{
                                    background: ${({ theme }) => (theme['user-theme'] === undefined) ? "#FFFFFF" : (theme['user-theme']['sidebarTextactiveColor'] === undefined) ? "#FFFFFF" : theme['user-theme']['sidebarTextactiveColor']};
                                }
                            }
                        }
                        .ant-menu-sub{
                            border-bottom-left-radius: ${({ theme }) => theme['border-global-radius']};
                            border-bottom-right-radius: ${({ theme }) => theme['border-global-radius']};
                            .ant-menu-item{
                                &:last-child {
                                    padding-bottom: 15px !important;
                                    border-bottom-left-radius: 25px;
                                    border-bottom-right-radius: 25px;
                                }
                                background: ${({ theme }) => (theme['user-theme'] === undefined) ? "#8DC7F1" : (theme['user-theme']['sidebarSubmenuColor'] === undefined) ? "#8DC7F1" : theme['user-theme']['sidebarSubmenuColor']};
                                border-radius: 0px;
                                padding: 7px 0px !important;
                                justify-content: start;
                                span {
                                    text-align: left;
                                    // padding-left: 18px !important;
                                }
                                a{
                                    padding-left: 7px !important;
                                    padding-bottom: 8px;
                                    display: inline !imporant;
                                    justify-content: start;
                                }
                                &.ant-menu-item-selected{
                                    a{
                                        border-bottom: 2px solid #fff;
                                        font-weight: 700;
                                        color: ${({ theme }) => (theme['user-theme'] === undefined) ? "#FFFFFF" : (theme['user-theme']['sidebarTextactiveColor'] === undefined) ? "#FFFFFF" : theme['user-theme']['sidebarTextactiveColor']};
                                    }
                                }
                            }
                        }
                    }
                    .ant-menu-submenu-title{
                        // .ant-menu-title-content{
                        //     font-weight: 500;
                        //     color: ${({ theme }) => theme[theme.mainContent]['gray-text']};
                        // }
                    }
                }
                
                .ant-menu-item,
                .ant-menu-submenu-title{
                    margin: 0 !important;
                    &:active{
                        background-color: transparent;
                    }
                    a{
                        font-size: 16px;
                        font-weight: 500;
                        color: ${({ theme }) => (theme['user-theme'] === undefined) ? "#FFFFFF" : (theme['user-theme']['sidebarTextColor'] === undefined) ? "#FFFFFF" : theme['user-theme']['sidebarTextColor']};
                        position: relative;
                        justify-content: center;
                    }
                    >span{
                        width: 100%;
                        margin-left: 0;
                        .pl-0{
                            ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 0px;
                        }
                    }
                    .badge{
                        position: absolute;                        
                        ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 30px;
                        top: 50%;
                        transform: translateY(-50%);
                        display: inline-block;
                        height: auto;
                        font-size: 10px;
                        border-radius: 3px;
                        padding: 3px 4px 4px;
                        line-height: 1;
                        letter-spacing: 1px;
                        color: #fff;
                        &.badge-primary{
                            background-color: ${({ theme }) => theme['primary-color']};
                        }
                        &.badge-success{
                            background-color: ${({ theme }) => theme['success-color']};
                        }
                    }
                }
                
                .ant-menu-submenu-inline{
                    border-radius: ${(window.innerWidth > 1490) ? ({ theme }) => theme['border-global-radius'] : "0px"};
                    &:hover {
                        background: ${({ theme }) => (theme['user-theme'] === undefined) ? "#493682" : (theme['user-theme']['sidebarHoverColor'] === undefined) ? "#493682" : theme['user-theme']['sidebarHoverColor']};
                    }
                    > .ant-menu-submenu-title{
                        display: flex;
                        align-items: center;
                        padding: 0 15px !important;
                        margin: 0;
                        height: auto;
                        padding: 20px 0px !important;
                        flex-direction: column;
                        // svg,
                        // img{
                        //     width: 16px;
                        //     height: 16px;
                        // }
                        span {
                            color: #fff;
                        }                        
                        .ant-menu-submenu-arrow{
                            display: none;
                            right: auto;
                            ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 15px;
                            &:after,
                            &:before{
                                width: 6px;
                                background: rgb(2, 9, 34);
                                height: 1.2px;
                            }
                            &:before{
                                transform: rotate(45deg) ${({ theme }) =>
                                  !theme.rtl ? 'translateY(-3px)' : 'translateY(3px)'};
                            }
                            &:after{
                                transform: rotate(-45deg) ${({ theme }) =>
                                  theme.rtl ? 'translateY(-3px)' : 'translateY(3px)'};
                            }
                        }
                    }
                    &.ant-menu-submenu-open{
                        > .ant-menu-submenu-title{
                            .ant-menu-submenu-arrow{
                                display: none;
                                transform: translateY(2px);
                                &:before{
                                    transform: rotate(45deg) translateX(-3.3px);
                                }
                                &:after{
                                    transform: rotate(-45deg) translateX(3.3px);
                                }
                            }
                        }
                    }
                    .ant-menu-item{
                        ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 0 !important;
                        transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
                        a{
                            ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 16px !important;
                        }
                        padding: 0px !important;
                        margin-bottom: 20px !important;
                    }
                    .ant-menu-item-only-child{
                        margin-bottom: 0px !important;
                        .ant-menu-title-content {
                            padding-left: 0 !important; 
                            a {
                                padding: 0 !important;    
                            }
                        }
                    }
                }
                .ant-menu-item{
                    display: flex;
                    -moz-box-align: center;
                    align-items: center;
                    flex-direction: column;
                    height: auto;
                    padding: 0px !important;
                    // padding: 20px 0px !important;
                    margin-bottom: 20px !important;
                    border-radius: ${(window.innerWidth > 1490) ? ({ theme }) => theme['border-global-radius'] : "0px"};
                    &:hover {
                        background: ${({ theme }) => (theme['user-theme'] === undefined) ? "#493682" : (theme['user-theme']['sidebarHoverColor'] === undefined) ? "#493682" : theme['user-theme']['sidebarHoverColor']};
                    }
                    .ant-menu-title-content {
                        a {
                            display: block;
                            padding: 0 0 20px 0 !important;    
                        }
                    }
                    a{
                        width: 100%;
                        // display: flex;
                        align-items: center;
                        text-transform: capitalize;
                        padding: 4px 4px;
                        padding-top: 25px;
                        .feather{
                            width: 16px;
                            color: ${({ theme }) => theme['extra-light-color']};
                        }
                        span{
                            ${({ theme }) => (!theme.rtl ? 'padding-left' : 'padding-right')}: 20px;
                            display: inline-block;
                            color: ${({ theme }) => (theme['user-theme'] === undefined) ? "#FFFFFF" : (theme['user-theme']['sidebarTextColor'] === undefined) ? "#FFFFFF" : theme['user-theme']['sidebarTextColor']};
                        }
                    }
                    &.ant-menu-item-selected{
                        background: ${({ theme }) => (theme['user-theme'] === undefined) ? "#493682" : (theme['user-theme']['sidebarHoverColor'] === undefined) ? "#493682" : theme['user-theme']['sidebarHoverColor']};
                        span a{
                            font-weight: bold;
                        }
                        /*span a::after{
                            content: ">";
                            font-weight: 700;
                            position: absolute;
                            right: 0;
                            top: -1px;
                            font-size: 16px;
                            color: #fff;
                            text-shadow: 2px 2px 2px #CE5937;
                        }*/
                    }
                }
                
                
                &.ant-menu-inline-collapsed{
                    .ant-menu-submenu{
                        text-align: ${({ theme }) => (!theme.rtl ? 'left' : 'right')};                        
                        .ant-menu-submenu-title{
                            padding: ${(window.innerWidth > 1490) ? "0 20px" : "0"} !important;
                            justify-content: center;
                        }
                    }
                    .ant-menu-submenu, .ant-menu-item{
                        padding: ${(window.innerWidth > 1490) ? "0 20px" : "10px"} !important;
                        justify-content: center;
                    }
                    .ant-menu-submenu, .ant-menu-item{
                        span{
                            display: none;
                        }
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 1150px){
        .ant-layout-sider.ant-layout-sider-collapsed{
            ${({ theme }) => (!theme.rtl ? 'left' : 'right')}: -80px !important;
        }

    }

`;

const SmallScreenAuthInfo = Styled.div`
    background-color: ${({ theme }) => theme[theme.mainContent]['white-background']};
    width: 100%;
    position: fixed;
    margin-top: ${({ hide }) => (hide ? '0px' : '72px')};
    top: 0;
    ${({ theme }) => (!theme.rtl ? 'left' : 'right')}: 0;
    transition: .3s;
    opacity: ${({ hide }) => (hide ? 0 : 1)};
    z-index: ${({ hide }) => (hide ? -1 : 1)};
    box-shadow: 0 2px 30px #9299b810;
    padding: 10px 0;
    @media only screen and (max-width: 767px){
        padding: 10px 15px;
    }
`;

const TopMenuSearch = Styled.div`
    .top-right-wrap{
        position: relative;
        float: ${({ theme }) => (theme.rtl ? 'left' : 'right')};
    }
    .search-toggle{
        display: flex;
        align-items: center;
        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 10px;
        color: ${({ theme }) => theme[theme.mainContent]['gray-text']};
        .feather-x{
            display: none;
        }
        .feather-search{
            display: flex;
        }
        &.active{
            .feather-search{
                display: none;
            }
            .feather-x{
                display: flex;
            }
        }
        svg,
        img{
            width: 20px;
        }
    }
    .topMenu-search-form{
        position: absolute;
        ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 100%;
        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 15px;
        top: 12px;
        background-color: #fff;
        border: 1px solid ${({ theme }) => theme['border-color-normal']};
        border-radius: 6px;
        height: 40px;
        width: 280px;
        display: none;
        &.show{
            display: block;
        }
        .search-icon{
            width: fit-content;
            line-height: 1;
            position: absolute;
            left: 15px;
            ${({ theme }) => (theme.rtl ? 'right' : 'left')}: 15px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 9999;
        }
        i,
        svg{
            width: 18px;
            background-color: ${({ theme }) => theme[theme.mainContent]['gray-light-text']};
        }
        form{
            height: auto;
            display: flex;
            align-items: center;
        }
        input{
            position: relative;
            border-radius: 6px;
            width: 100%;
            border: 0 none;
            height: 38px;
            padding-left: 40px;
            z-index: 999;
            ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 40px;
            &:focus{
                border: 0 none;
                box-shadow: 0 0;
                outline: none;
            }
        }
    }
`;

const FooterStyle = Styled(Footer)`
    padding: 20px 30px 18px;    
    font-size: 14px;
    background-color: ${({ theme }) => theme[theme.mainContent]['light-background']};
    width: 100%;
    box-shadow: 0 -5px 10px rgba(146,153,184, 0.05);   
    
    .admin-footer__links{
        margin: 0 -9px;
        text-align: ${({ theme }) => (theme.rtl ? 'left' : 'right')};
        @media only screen and (max-width: 767px){
            text-align: center;
        }
        a {
            margin: 0 9px;
            color: ${({ theme }) => theme[theme.mainContent]['gray-text']};
            &:hover{
                color: ${({ theme }) => theme['primary-color']};
            }
            &:not(:last-child) {
                ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 15px;
            }

            
        }
    }
    
`;

const ProjectAddModel = Styled.div`
.main-class
{
    padding: 20px;
    display: flex; 
    min-height: 400px;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #fff;
    border-color: #fff;
    opacity: 0.7;
}

.project-name-box
    {
        margin-top: 15px;
        background: #DADDE0;
        color: white;
        padding: 7px;
        font-size: 17px;
        display: flex;
        align-items: center;
    }
    .step-text {
        display: flex;
        align-items: center;
      }
      .step-text input
      {
        width: 370px;
        margin-left: 10px;
      }
      .step-text button
      {
        width: 100px;
        background: #9FCE63;
        color: white;
        font-weight: bold;
      }
      
      /* If you also want to add some spacing between the Input and Button */
      .step-text > * {
        margin-right: 10px;
      }
      .step-number
      {
        border: 5px solid #BFBFBF;
        padding: 4px 12px;
        background: #475364;
        margin-left: -10px;
        margin-top: -10px;
      }
      .back-white
      {
        padding: 2px 5px;
        background: white !important;
      }
      .line-second-step
      {
        width: 6px;
        background:#BFBFBF;
        height: 30px;
        margin-left: 14px;
      }
      .back-other
      {
        background:#475364 !important;
        margin-top: 3px !important;
      }
      .create-audience-button button
        {
            width: 200px;
            height: 60px;
            border-radius: 20px;
            color: white;
            background: #4FADEA;
            font-weight: 700;
            white-space: break-spaces;
        }
      
`;

const ProjectMenu = Styled.div`
    height: 80%;
    display: flex;
    align-items: center;
    border-radius: 6px;
    min-width: 240px;
    background: ${({ theme }) => (theme['user-theme']['DropdownColor'] === undefined) ? "#cdcdcd" : theme['user-theme']['DropdownColor']} !important;
    .ant-dropdown-trigger {
        width: 100%;
        position: relative;
        height: 90%;
    }
    a {
        display: flex;
        width: 100%;
        padding: 8px 20px;
        justify-content: center;
    }
    ul {
        li {
            font-size: 0.7rem !important;
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            color: rgba(0, 0, 0, 0.88);
            line-height: 1.5714285714285714;
            list-style: none;
            font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
            border-block-start: 1px solid rgba(5, 5, 5, 0.06);
            span {
                font-size: 0.7rem !important;
                color: rgba(0, 0, 0, 0.88);
                padding: 5px 2px;
            }
        }
        li.ant-dropdown-menu-item-selected {
            color: #1677ff !important;
            background-color: #75b8ed !important;
            font-weight: bold !important;
        }
        li:hover {
            background: ${({ theme }) => (theme['user-theme']['backgroundColor'] === undefined) ? "#cdcdcd" : theme['user-theme']['backgroundColor']} !important;
            color: ${({ theme }) => (theme['user-theme']['Color'] === undefined) ? "#FFF" : theme['user-theme']['Color']} !important;
            span {
                color: ${({ theme }) => (theme['user-theme']['Color'] === undefined) ? "#FFF" : theme['user-theme']['Color']} !important;
            }
        }
    }
    
    .buttonAddNew {
        div {
            width: 100% !important;
        }
    }
    button {
        background: ${({ theme }) => (theme['user-theme']['backgroundColor'] === undefined) ? "#cdcdcd" : theme['user-theme']['backgroundColor']} !important;
        border: 1px solid ${({ theme }) => (theme['user-theme']['DropdownButtonBorder'] === undefined) ? "#cdcdcd" : theme['user-theme']['DropdownButtonBorder']} !important;
        width: 100% !important;
        height: auto;
        span {
            color: ${({ theme }) => (theme['user-theme']['Color'] === undefined) ? "#FFF" : theme['user-theme']['Color']};
            font-size: 0.8rem !important;
            font-weight: 700;
        }
    }
    button:hover {
        background: ${({ theme }) => (theme['user-theme']['DropdownButtonHover'] === undefined) ? "#cdcdcd" : theme['user-theme']['DropdownButtonHover']} !important;
        border: 1px solid ${({ theme }) => (theme['user-theme']['DropdownButtonBorder'] === undefined) ? "#cdcdcd" : theme['user-theme']['DropdownButtonBorder']} !important;
        width: 100% !important;
        height: auto;
        span {
            color: ${({ theme }) => (theme['user-theme']['DropdownButtonHoverColor'] === undefined) ? "#FFF" : theme['user-theme']['DropdownButtonHoverColor']};
            font-size: 0.8rem !important;
            font-weight: 700;
        }
    }
    
`;

const SearchBox = Styled.div`
    width: 100%;
    height: 100%;
    align-item: center;
    .ant-form-item {
        margin-bottom: 0;
        border-radius: 80px;
        .ant-input-affix-wrapper {
            border-radius: 80px;
        }
    }
    .ant-input-prefix {
        svg {
            color: rgb(112, 111, 111);
        }
    }
`;

const SubscriptionHeaderStyle = Styled.div`
    width: 100%;
    height: 100%;
    align-item: center;
    display: ${(window.innerWidth > 1180) ? "block" : "flex"};
    justify-content: center;
    flex-direction: column;
    .ant-btn {
        a {
            &:hover {
                color: #000 !important;
            }
        }
    }
    p{
        color: ${({ theme }) => (theme['user-theme']['Color'] === undefined) ? "#FFF" : theme['user-theme']['Color']};
        font-size: ${(window.innerWidth > 1180) ? "0.8rem" : "0.6rem"} !important;
        font-weight: 400;
        align-items: center;
        justify-content: center;
        display: flex;
        height: ${(window.innerWidth > 1180) ? "100%" : "auto"};
        button.subscribe_now {
            margin-left: 20px;
            background: #99FFCC !important;
            font-weight: 700;
            font-size: 0.8rem;
            height: auto;
            border-radius: 80px;
            color: ${({ theme }) => (theme['user-theme']['backgroundColor'] === undefined) ? "#CDCDCD" : theme['user-theme']['backgroundColor']};
        }
        .trialdays {
            border: 1px solid #fff;
            padding: 15px 20px 17px;
            text-align: center;
            margin-left: 20px;
            border-radius: 12px;
            font-weight: 700;
            color: ${({ theme }) => (theme['user-theme']['backgroundColor'] === undefined) ? "#CDCDCD" : (theme['user-theme']['backgroundColor'] !== undefined && parseInt(theme['trialDays']) < 5) ? "#F00" : theme['user-theme']['backgroundColor']};
            font-size: 0.8rem !important;
            background: ${({ theme }) => (theme['user-theme']['Color'] === undefined) ? "#fff" : theme['user-theme']['Color']} !important;
            label {
                animation: ${({ theme }) => (theme['trialDays'] !== undefined && parseInt(theme['trialDays']) > 3) ? "none" : "blink-animation 1s steps(5, start) infinite"};
                -webkit-animation: ${({ theme }) => (theme['trialDays'] !== undefined && parseInt(theme['trialDays']) > 3) ? "none" : "blink-animation 1s steps(5, start) infinite"};
            }
            @keyframes blink-animation {
                to {
                    visibility: hidden;
                }
            }
            @-webkit-keyframes blink-animation {
                to {
                    visibility: hidden;
                }
            }
        }
    }
    button.subscribe_now {
        background: #99FFCC !important;
        font-weight: 700;
        font-size: 0.8rem;
        height: auto;
        border-radius: 80px;
        color: ${({ theme }) => (theme['user-theme']['backgroundColor'] === undefined) ? "#CDCDCD" : theme['user-theme']['backgroundColor']};
    }
    
`;

export {
  FooterStyle,
  LayoutContainer,
  SmallScreenAuthInfo,
  TopMenuSearch,
  ProjectMenu,
  SearchBox,
  SidebarContainer,
  SubscriptionHeaderStyle,
  ProjectAddModel
};
