import { useEffect } from 'react';
import { useTargetAudienceDispatch, useTargetAudienceState } from '../context/targetAudience';
import { reducerTypes } from '../context/targetAudience/constants';
import useTargetAudience from './useTargetAudience';
import { reFactor } from './utils';
import { sortBy } from 'lodash'
import useApi from '../../../config/dataService/useApi';

const defaultPagination = {
  pagination: {
    page: 1,
    limit: 700,
  },
};

const useTargetAudienceData = () => {
  const {
    getGroupsLocationsLevels,
    getGroupsByLevels,
    getLocationsByLevels,
    getJobRole,
    getStakeholders,
    getActiveProject,
    getGroups,
    getLocations,
  } = useTargetAudience();
  const dispatch = useTargetAudienceDispatch();
  const { activeProject, locations, groups, jobs, columns, reload, stakeholders, levels, customFieldOptions, reloadStats } =
    useTargetAudienceState();
  const { data: targetAudienceCount, fetchData } = useApi('getTargetAudienceStats');
  const init = async (loadString) => {
    // Dispatch reload action
    dispatch({
      type: reducerTypes.RELOAD,
      payload: null,
    });

    // Define an object to hold data fetching functions
    const fetchData = {
      levels:
        loadString.includes('group') || loadString.includes('location') || loadString.includes('all')
          ? getGroupsLocationsLevels()
          : { data: null },
      groups:
        loadString.includes('group') || loadString.includes('all')
          ? getGroupsByLevels({ ...defaultPagination, fetchAll: true })
          : { data: null },
      locations:
        loadString.includes('location') || loadString.includes('all')
          ? getLocationsByLevels({ ...defaultPagination, fetchAll: true })
          : { data: null },
      jobs: loadString.includes('job') || loadString.includes('all') ? getJobRole(defaultPagination) : { data: null },
      stakeholders:
        loadString.includes('stakeholder') || loadString.includes('all')
          ? getStakeholders(defaultPagination)
          : { data: null },
      activeProject: loadString.includes('project') || loadString.includes('all') ? getActiveProject() : { data: null },
    };
    // Fetch all data concurrently
    const [
      { data: levelsData },
      { data: groupsData },
      { data: locationsData },
      { data: jobRoles },
      { data: stakeholdersData },
      { data: projectData },
    ] = await Promise.all(Object.values(fetchData));
    // Dispatch init action with updated payload

    dispatch({
      type: reducerTypes.INIT,
      payload: {
        levels: levelsData || levels,
        jobs: jobRoles?.rows || jobs,
        groups: sortBy(groupsData?.rows || groups, ['groupName']),
        stakeholders: stakeholdersData?.rows || stakeholders,
        locations: locationsData?.rows || locations,
        activeProject: projectData || activeProject,
        setView: loadString.includes('all') && !loadString.includes('view')
      },
    });
  };

  useEffect(() => {
    if (reload) {
      init(reload);
    }
  }, [reload]);

  useEffect(() => {
    if (targetAudienceCount) {
      dispatch({
        type: reducerTypes.SET_STATS,
        payload: targetAudienceCount
      })
    }
  }, [targetAudienceCount])


  useEffect(() => {
    if (activeProject?.project?.id) {
      dispatch({
        type: reducerTypes.COLUMNS,
        payload: reFactor(
          columns.length !== 0 ? columns : activeProject.project?.targetAudienceColumns,
          getGroups,
          getLocations,
          jobs || [],
        ),
      });
    }
  }, [locations, groups, jobs, activeProject, customFieldOptions]);

  useEffect(() => {
    if (reloadStats) {
      fetchData();
      dispatch({
        type: reducerTypes.RELOAD_STATS,
        payload: false,
      });
    }
  }, [reloadStats]);

  return {
    init,
  };
};

export default useTargetAudienceData;
