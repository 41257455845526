import React, { useState, useEffect } from 'react';
import { Tag, Popover, Modal, Button, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import Cookies from "js-cookie";
import { ThemeProvider } from 'styled-components';
import { InfoWraper, PopoverStyle, UserDropDown } from './auth-info-style';

const { theme } = require('../../../config/theme/themeVariables');

import Heading from '../../heading/heading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone, regular } from '@fortawesome/fontawesome-svg-core/import.macro'; // <-- import styles to be used
import Feedback from './Feedback';

import './style.css';
import UserProfileAvatar from '../../../container/tools/panel/widgets/UserProfileAvatar';
import { logOut } from '../../../redux/authentication/actionCreator';

const AuthInfo = ({UserInfo, axiosCallback}) => {
  const [isFeedBackShow, setIsFeedBackShow] = useState(false);
  const [logoutUser, setLogoutUser] = useState(false);
  const [popVisible, setPopVisible] = useState(false);
  const [showFeedbackPopUp, setShowFeedbackPopUp] = useState(true);
  const [showFeedback, setShowFeedback] = useState(true);
  const marked_for_delete = localStorage.getItem('marked_for_delete') === 'true';

  useEffect(() => {
    let userId = localStorage.getItem('user_id');
    const isShowFeedbackPopup = Cookies.get(`feedbackProvided${userId}`);
    const isShowFeedback = Cookies.get(`showFeedback${userId}`);
    setShowFeedbackPopUp(isShowFeedbackPopup);
    setShowFeedback(isShowFeedback !== undefined && isShowFeedback.toString().toLowerCase() === "yes");
  }, []);
  
  if (UserInfo === undefined) {
    return (
      <>
        <FontAwesomeIcon
          icon={duotone('spinner-third')}
          spin
          size="2xl"
          style={{ '--fa-primary-color': '#FFFFFF', '--fa-secondary-color': '#c4c4c4', padding: '20px 10px' }}
        />
      </>
    );
  }
  const userTheme = JSON.parse(localStorage.getItem('userTheme'));

  theme['user-theme'] = userTheme;
  const dispatch = useDispatch();
  const organizationalSlug = localStorage.getItem('organizationalSlug');
  const [state, setState] = useState({
    flag: 'en',
  });
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { flag } = state;

  const SignOut = (e) => {
    e.preventDefault();
    setPopVisible(false);
    setIsFeedBackShow(true);
    setLogoutUser(true);
    if (showFeedbackPopUp)
    {
      dispatch(logOut(() => navigate('/')));
    }
    else if (showFeedback)
    {
      dispatch(logOut(() => navigate('/')));
    }
    //
  };

  const is_amazon = localStorage.getItem('is_amazon') === 'true';
  const is_trial = localStorage.getItem('is_trial') === 'true';
  const is_free = localStorage.getItem('is_free') === 'true';
  const is_paid = localStorage.getItem('is_paid') === 'true';
  let is_offline = localStorage.getItem('offline_account') === 'true';

  const userContent = (
    <UserDropDown>
      <div className="user-dropdown">
        <div className="user-info-panel">
          <UserProfileAvatar />
          <Heading as="h5">
            {localStorage.getItem('full_name')}
            <p>{localStorage.getItem('email')}</p>
          </Heading>
          <Tag color="default">
            {parseInt(localStorage.getItem('user_type')) == 1
              ? 'Account Owner'
              : parseInt(localStorage.getItem('user_type')) == 2
              ? 'Admin'
              : parseInt(localStorage.getItem('user_type')) == 3
              ? 'Member'
              : 'Guest'}
          </Tag>
        </div>
        <ul className="user-dropdown__links">
          <li>
            <Link to={`/${organizationalSlug}/profile-settings`}>
              <FontAwesomeIcon icon={duotone("user")} /> Profile Settings
            </Link>
          </li>
          <li>
            <Link to={`/${organizationalSlug}/account-settings`}>
              <FontAwesomeIcon icon={regular("gear")} /> Account Settings
            </Link>
          </li>
          {localStorage.getItem('user_type') < 2 && is_paid && !is_amazon && !is_offline && (
              <li>
                <Link to={`/${organizationalSlug}/subscription/update-subscription`}>
                  <FontAwesomeIcon icon={regular("dollar-sign")} /> Subscription
                </Link>
              </li>
          )}
          {localStorage.getItem('user_type') < 2 && !is_amazon && is_offline && (
              <li>
                <Link to={`/${organizationalSlug}/subscription/delete-account`}>
                  <FontAwesomeIcon icon={regular("dollar-sign")} /> Delete Account
                </Link>
              </li>
          )}
          {localStorage.getItem('user_type') < 2 && !is_paid && !is_amazon && !is_offline && (
            marked_for_delete ? (
              <li>
                <Link to={`/${organizationalSlug}/subscription/delete-account`}>
                  <FontAwesomeIcon icon={regular("dollar-sign")} /> Subscription
                </Link>
              </li>
            ) : (
              !is_offline && (
                <li>
                  <Link to={`/${organizationalSlug}/subscribe-now`}>
                    <FontAwesomeIcon icon={regular("dollar-sign")} /> Subscription
                  </Link>
                </li>
              )
            )
          )}
          {localStorage.getItem('user_type') < 3 && (
            <li>
              <Link to={`/${organizationalSlug}/user-management`}>
                <FontAwesomeIcon icon={regular("users")} /> User Management
              </Link>
            </li>
          )}
        </ul>
        <Link className="user-dropdown__bottomAction" onClick={SignOut} to="#">
          <FontAwesomeIcon icon={regular("right-from-bracket")} /> Sign Out
        </Link>
      </div>
    </UserDropDown>
  );

  const onFlagChangeHandle = (value, e) => {
    e.preventDefault();
    setState({
      ...state,
      flag: value,
    });
    i18n.changeLanguage(value);
  };

  const handlePopVisibleChange = (visibleStatus) => {
    setPopVisible(visibleStatus);
  };

  return (
    <InfoWraper>
      {/* <Search /> */}
      <ul>
        <li style={{ padding: '0px 3px 8px 3px', marginRight:"200px" }}>
          {logoutUser && showFeedbackPopUp && showFeedback ? (
            <></>
          ) : (
            logoutUser && showFeedback ? (
              <></>
            ) : (
              <Feedback 
                showModal={isFeedBackShow} 
                logoutThisUser={logoutUser} 
                removeToShowAgain={() => {
                  setIsFeedBackShow(false);
                  setLogoutUser(false);
                }}
              />
            )
          )}
        </li>
        <li>
          <Link
            to={`/${organizationalSlug}/main-dashboard`}
            className="ocmsolution-nav-action-link"
            style={{ padding: '0px' }}
          >
            <FontAwesomeIcon
              icon={duotone('house')}
              size="2xl"
              style={{ '--fa-primary-color': '#FFFFFF', '--fa-secondary-color': '#c4c4c4' }}
            />
          </Link>
        </li>
        {window.innerWidth > 990 && (
          <li style={{ display: 'none' }}>
            <FontAwesomeIcon
              icon={duotone('arrow-down-to-bracket')}
              size="2xl"
              style={{ '--fa-primary-color': '#FFFFFF', '--fa-secondary-color': '#c4c4c4' }}
            />
          </li>
        )}
        <li style={{ display: 'none' }}>
          <FontAwesomeIcon
            icon={duotone('book-open-cover')}
            size="2xl"
            style={{ '--fa-primary-color': '#ffffff', '--fa-secondary-color': '#c4c4c4' }}
          />
        </li>
        {window.innerWidth > 990 && (
          <>
            <li style={{ display: 'none' }}>
              <FontAwesomeIcon
                icon={duotone('circle-phone')}
                size="2xl"
                style={{ '--fa-primary-color': '#ffffff', '--fa-secondary-color': '#c4c4c4' }}
              />
            </li>
            <li style={{ display: 'none' }}>
              <FontAwesomeIcon
                icon={duotone('calendar-lines-pen')}
                size="2xl"
                style={{ '--fa-primary-color': '#ffffff', '--fa-secondary-color': '#c4c4c4', paddingRight: '0px' }}
              />
            </li>
          </>
        )}
        
        
        

        <li style={{ paddingTop: '12px' }}>
          <ThemeProvider theme={theme}>
            <PopoverStyle>
              <div className="ocmsolution-nav-actions__item ocmsolution-nav-actions__author">
                <Popover 
                  open={popVisible} 
                  onOpenChange={handlePopVisibleChange} 
                  placement="bottomRight" 
                  content={userContent} 
                  action="click" 
                  align={{ offset: [0, 10] }}
                  overlayClassName={'profile_default'}
                >
                  <Link to="#" className="ocmsolution-nav-action-link" style={{ paddingBottom: '25px' }}>
                    <UserProfileAvatar />
                  </Link>
                </Popover>
              </div>
            </PopoverStyle>
          </ThemeProvider>
        </li>
      </ul>
    </InfoWraper>
  );
};

export default AuthInfo;
