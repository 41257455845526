import React, { Suspense, useEffect, useReducer, useCallback } from 'react';
import { Row, Col, Skeleton, Typography, List, Card, Image } from 'antd';
const {Title} = Typography;
import { FreeUserToolStyle, ToolsDashboardPage } from './Style';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Main } from '../styled';
import { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import * as ProIcons from '@fortawesome/pro-duotone-svg-icons';

import { useDispatch } from 'react-redux';
import { verifyUser } from '../../redux/authentication/actionCreator';
import { DataService } from '../../config/dataService/dataService';
import { PhaseCardStyle } from '../../components/cards/Style';
import useNotificationController from '../../utility/useNotificationController';

const { theme } = require('../../config/theme/themeVariables');

function GuideHandbooks(props) {
  const {
    contextHolder,
    openError,
  } = useNotificationController();

  const [downloadingPDF, setDownloadingPDF] = useState({});
  const [downloadingPPTX, setDownloadingPPTX] = useState({});
  const userTheme = JSON.parse(localStorage.getItem('userTheme'));
  theme['user-theme'] = userTheme;


  let icon = 'faSpinnerThird';
  library.add(ProIcons[icon]);

  icon = 'faCircleArrowRight';
  library.add(ProIcons[icon]);

  icon = 'faCircleArrowLeft';
  library.add(ProIcons[icon]);

  let is_amazon = localStorage.getItem('is_amazon') === 'true';
  const foldername = is_amazon ? "amazon" : "ocm";
  
  const [isFreeAccount, setIsFreeAccount] = useState(false);
  const [guides, setGuides] = useState([]);
  
  const initialState = {
      error: false,
      messages: ""
  };

  const reducer = (state, action) => {
      switch (action.type) {
        case 'VERIFY_USER_ERR':
          return { ...state, error: true,messages: action.err };
        case 'CONVERT_TO_TRIAL_ERR':
          return { ...state, error: true,messages: action.err };
        case 'RESET':
          return state;
        default:
          return state;
      }
  };

  const [apiError, apiResponseCallback] = useReducer(reducer, initialState);

  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
      apiResponseCallback({ type: 'RESET' });
      dispatch(
          verifyUser({}, async (response) => {
            if (response.status === 200) {
              if (!response.data.isFreeAccount)
              {
                setIsFreeAccount(false);
              }  
              else
              {
                  setIsFreeAccount(true);
              }
              const resp = await DataService.get("/defaultData/ocm-guides");
              if (resp)
              {
                if (resp.status === 200)
                {
                  let items = [];
                  let itemIsDownloadingPDF = {};
                  let itemIsDownloadingPPTX = {};
                  resp.data.map(item => {
                    itemIsDownloadingPDF[item.file_key] = false;
                    itemIsDownloadingPPTX[item.file_key] = false;
                    items.push(item);
                  });
                  setDownloadingPDF(itemIsDownloadingPDF);
                  setDownloadingPPTX(itemIsDownloadingPPTX);
                  setGuides(items);
                }
              }
              else
              {
                openError('topLeft', 'Error', response.err);
                apiResponseCallback(response);
              }
            } 
            else 
            {
              openError('topLeft', 'Error', response.err);
              apiResponseCallback(response);
            }
          }),
        );
  }, [dispatch, 
      isFreeAccount,
      guides]);

  const handleDownload = async (item, typeToDownload, index) => {
    if (typeToDownload === "pdf")
    {
      setDownloadingPDF({...downloadingPDF, [item.file_key]: true});
    }
    else
    {
      setDownloadingPPTX({...downloadingPDF, [item.file_key]: true});
    }
  
    const fileName = `${item.file_key}.${typeToDownload}`;
    const url = `${process.env.REACT_APP_API_ENDPOINT}/file/download/guides/${foldername}/${typeToDownload}<->${fileName}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`, // Include the token in the headers
      },
    })
    if ( response.status === 200 )
    {
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          a.remove();
          if (typeToDownload === "pdf")
          {
            setDownloadingPDF({...downloadingPDF, [item.file_key]: false});
          }
          else
          {
            setDownloadingPPTX({...downloadingPDF, [item.file_key]: false});
          }
        });

    }
    else
    {
        if (typeToDownload === "pdf")
        {
          setDownloadingPDF({...downloadingPDF, [item.file_key]: false});
        }
        else
        {
          setDownloadingPPTX({...downloadingPDF, [item.file_key]: false});
        }
        openError('topLeft', 'Error', response.statusText);
    }
  }

  useEffect(() => {
    const retrieveData = async () => {
      await fetchData();
    }
    retrieveData().catch(console.error);
  }, []);

  return (
    <>
      {contextHolder}
      <Main>
        <ToolsDashboardPage>
          <Row justify="center" gutter={25}>
            <Col xxl={24} xl={24} md={24}>
              {!guides.length === 0 ? (
                <div>
                  <FontAwesomeIcon icon={duotone("spinner-third")} spin size="2xl" style={{"--fa-primary-color": `${userTheme.mainHighlightColor}`, "--fa-secondary-color": `${userTheme.mainHighlightColor}`, "padding": "20px 10px"}} />
                </div>
              ) : (
                <Suspense
                  fallback={
                    <Cards headless>
                      <Skeleton active />
                    </Cards>
                  }
                >
                  <FreeUserToolStyle>
                      <Row justify={'center'} gutter={[40,25]} style={{width: "100%"}}>
                        <Col span={24} style={{textAlign: "center"}}>
                        <Title level={1}>{is_amazon ? 'Amazon' : "OCMS"} Tool Handbooks</Title>
                          <PhaseCardStyle>
                            <Card className="loadmoretool-list">
                            <List
                              grid={{
                                gutter: [60, 40],
                                column: 4,
                              }}
                              className="guide-list"
                              itemLayout="horizontal"
                              dataSource={guides}
                              renderItem={(item, index) => (
                                <List.Item>
                                    <div className='guides-box'>  
                                      <div className='guide-name'>
                                        <Title level={4} style={{color: item.selected ? "#FFF" : "#000"}}>{item.name}</Title>
                                      </div>
                                      <Image
                                        preview={false}
                                        src={`${process.env.REACT_APP_API_ENDPOINT}/guides/${is_amazon ? 'amazon' : 'ocm'}/thumbs/${item.filename_ext}`}
                                      />
                                      <div className='guides-box-bottom'>
                                        <Row>
                                          <Col span={8} style={{flexDirection: "column", display: "flex"}}>
                                            {item.has_pdf && parseInt(item.no_of_file) === 2 && (
                                              <>
                                              {downloadingPDF[item.file_key] ? (
                                                <FontAwesomeIcon icon={duotone("spinner-third")} spin size="3x" style={{"--fa-primary-color": `#B30B00`, "--fa-secondary-color": `#B30B00`}} />
                                              ) : (
                                                <FontAwesomeIcon icon={duotone("file-pdf")} style={{"--fa-primary-color": "#B30B00", "--fa-secondary-color": "#B30B00",}} size='3x' onClick={(e) => handleDownload(item, 'pdf', index)}/>
                                              )}
                                              <Title level={5} style={{color:"#B30B00", paddingTop: "5px"}} onClick={(e) => handleDownload(item, 'pdf', index)}>Download</Title>
                                              </>
                                            )}
                                          </Col>
                                          <Col span={8}>
                                            {item.has_pdf && parseInt(item.no_of_file) < 2 && (
                                              <>
                                              {downloadingPDF[item.file_key] ? (
                                                <FontAwesomeIcon icon={duotone("spinner-third")} spin size="3x" style={{"--fa-primary-color": `#B30B00`, "--fa-secondary-color": `#B30B00`}} />
                                              ) : (
                                                <FontAwesomeIcon icon={duotone("file-pdf")} style={{"--fa-primary-color": "#B30B00", "--fa-secondary-color": "#B30B00",}} size='3x' onClick={(e) => handleDownload(item, 'pdf', index)}/>
                                              )}
                                              <Title level={5} style={{color:"#B30B00", paddingTop: "5px"}} onClick={(e) => handleDownload(item, 'pdf', index)}>Download</Title>
                                              </>
                                            )}
                                            {item.has_pptx && parseInt(item.no_of_file) < 2 && (
                                              <>
                                              {downloadingPPTX[item.file_key] ? (
                                                <FontAwesomeIcon icon={duotone("spinner-third")} spin size="3x" style={{"--fa-primary-color": `#FF9318`, "--fa-secondary-color": `#FF9318`}} />
                                              ) : (
                                                <FontAwesomeIcon icon={duotone("file-powerpoint")} style={{"--fa-primary-color": "#FF9318", "--fa-secondary-color": "#FF9318",}} size='3x' onClick={(e) => handleDownload(item, 'pptx', index)} />
                                              )}
                                              <Title level={5} style={{color:"#FF9318", paddingTop: "5px"}} onClick={(e) => handleDownload(item, 'pptx', index)}>Download</Title>
                                              </>
                                            )}
                                          </Col>
                                          <Col span={8}>
                                            {item.has_pptx && parseInt(item.no_of_file) === 2 && (
                                              <>
                                              {downloadingPPTX[item.file_key] ? (
                                                <FontAwesomeIcon icon={duotone("spinner-third")} spin size="3x" style={{"--fa-primary-color": `#FF9318`, "--fa-secondary-color": `#FF9318`}} />
                                              ) : (
                                                <FontAwesomeIcon icon={duotone("file-powerpoint")} style={{"--fa-primary-color": "#FF9318", "--fa-secondary-color": "#FF9318",}} size='3x' onClick={(e) => handleDownload(item, 'pptx', index)} />
                                              )}
                                              <Title level={5} style={{color:"#FF9318", paddingTop: "5px"}} onClick={(e) => handleDownload(item, 'pptx', index)}>Download</Title>
                                              </>
                                            )}
                                          </Col>
                                        </Row>
                                      </div>
                                    </div>
                                  </List.Item>
                              )}
                            />
                            </Card>
                          </PhaseCardStyle>
                        </Col>
                      </Row>
                  </FreeUserToolStyle>
                </Suspense>
              )}
              
            </Col>
          </Row>
        </ToolsDashboardPage>
      </Main>
    </>
  );
}

export default GuideHandbooks;
