export const ReportsIcon = (props) => {
  return (
    <div
      style={{
        width: '6rem',
        height: 'auto',
        padding: '10px',
        padding: '10px',

        background: 'white',
        ...props.style,
      }}
    >
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fad"
        data-icon="chart-mixed"
        className="svg-inline--fa fa-chart-mixed fa-2xl"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        style={{
          width: '100%',
          height: '100%',
          '--fa-primary-color': '#004D86',
          '--fa-secondary-color': '#99B8CF',
        }}
      >
        <g className="fa-duotone-group">
          <path
            className="fa-secondary"
            fill="currentColor"
            d="M192 224c-17.7 0-32 14.3-32 32V448c0 17.7 14.3 32 32 32s32-14.3 32-32V256c0-17.7-14.3-32-32-32zM64 320c-17.7 0-32 14.3-32 32v96c0 17.7 14.3 32 32 32s32-14.3 32-32V352c0-17.7-14.3-32-32-32zm224 0V448c0 17.7 14.3 32 32 32s32-14.3 32-32V320c0-17.7-14.3-32-32-32s-32 14.3-32 32zm160-96c-17.7 0-32 14.3-32 32V448c0 17.7 14.3 32 32 32s32-14.3 32-32V256c0-17.7-14.3-32-32-32z"
          ></path>
          <path
            className="fa-primary"
            fill="currentColor"
            d="M505 44c11 13.8 8.8 33.9-5 45L340 217c-11.4 9.1-27.5 9.4-39.2 .6L192.6 136.5 52 249c-13.8 11-33.9 8.8-45-5s-8.8-33.9 5-45L172 71c11.4-9.1 27.5-9.4 39.2-.6l108.2 81.1L460 39c13.8-11 33.9-8.8 45 5z"
          ></path>
        </g>
      </svg>
    </div>
  );
};
