import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { Row, Col, Alert } from 'antd';
import { Main } from '../styled';
import { useDispatch } from 'react-redux';
import useNotificationController from '../../utility/useNotificationController';
import { 
  FreeResourceBackgroundImage, 
  FreeResourceContentAreaWrapper, 
  FreeResourceContainer, 
  FreeResourceImageWrapper, 
  FreeResourceTitle, 
  FreeResourceOverlay } 
from './FreeResources.styles';
import FreeResourceContentArea from './FreeResourceContentArea';
import { verifyUser } from '../../redux/authentication/actionCreator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useLocation, useNavigate } from 'react-router-dom';

const FreeResources = ({setOpenKeys}) => {
  const {
    contextHolder,
    openError,
  } = useNotificationController();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  let paths = pathname.split('/');

  const [loaded, setLoaded] = useState(false);
  const [loadedWithError, setLoadedWithError] = useState(false);
  const [defaultImage, setDefaultImage] = useState('ocm-resources.png');

  const initialState = {
      error: false,
      messages: ""
  };
  const reducer = (state, action) => {
      switch (action.type) {
        case 'VERIFY_USER_ERR':
          return { ...state, error: true,messages: action.err };
        case 'RESET':
          return state;
        default:
          return state;
      }
  };
  const [apiError, apiResponseCallback] = useReducer(reducer, initialState);
  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    apiResponseCallback({ type: 'RESET' });
      dispatch(
          verifyUser({checkLibrary: true, typeOfLibrary: 'resources'},(response) => {
            if (response.status === 200) {
              setDefaultImage(response.data.featuredImage);
              setLoaded(true);
            } 
            else 
            {
              const organizationalSlug = localStorage.getItem('organizationalSlug');
              navigate(`/${organizationalSlug}/main-dashboard`);
              apiResponseCallback(response);
            }
          }),
        );
  }, [
    dispatch, 
    defaultImage,
    loaded,
    loadedWithError
  ]);

  const downloadFile = async (selectedName, fileName) => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/file/download/resources/${selectedName}/${fileName}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`, // Include the token in the headers
      },
    });
    if ( response.status === 200 )
    {
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          a.remove();
        });

    }
    else
    {
      openError('topLeft', 'Error', response.statusText);
    }
  }

  useEffect(() => {
    const retrieveData = async () => {
      await fetchData();
    };
    if (!loaded)
    {
      retrieveData().catch(console.error);
    }
    
    if (typeof setOpenKeys === 'function')
    {
      setOpenKeys(['free-resources']);
    }
    if (paths.length === 7)
    {
        if (paths[2] === "file" && paths[3] === "getfile" && paths[4] === "resources")
        {
          downloadFile(paths[5], paths[6]);
        }
    }
  }, []);

  const setFeaturedImage = (img) => {
    setDefaultImage(img);
  }

  const userTheme = JSON.parse(localStorage.getItem('userTheme'));
  
  return (
    <Main>
      {contextHolder}
      {!loaded ? (
        <div className="loader" key={0} style={{ padding: "10px", display: "flex", height: "200px", alignItems: "center", justifyContent: "center" }}>
          <FontAwesomeIcon icon={duotone("spinner-third")} spin size="2xl" style={{ "--fa-primary-color": `${userTheme.mainHighlightColor}`, "--fa-secondary-color": `${userTheme.mainHighlightColor}`, marginRight: "20px", fontSize: "100px" }} />
          <span style={{fontSize: "40px", color: `${userTheme.mainHighlightColor}`}}>Loading OCMS Resources...</span>
        </div>
      ) : (
        <Row gutter={[16, 16]} style={{marginRight: "0px"}}>
          <Col span={24} style={{paddingLeft: "0px"}}>
            <FreeResourceContainer>
              <FreeResourceImageWrapper>
                <FreeResourceBackgroundImage src={`${process.env.REACT_APP_API_ENDPOINT}/file/cms/category_images/${defaultImage}`} alt="OCMS Resources Background"/>
                <FreeResourceOverlay />
                <FreeResourceTitle>Free OCMS Resources</FreeResourceTitle>
              </FreeResourceImageWrapper>
              <FreeResourceContentAreaWrapper>
                  <Row gutter={[16, 16]} style={{marginRight: "0px", marginLeft: 0}}>
                    <Col span={24}>
                      <FreeResourceContentArea setFeaturedImage={setFeaturedImage} />
                    </Col>
                  </Row>
              </FreeResourceContentAreaWrapper>
            </FreeResourceContainer>
          </Col>
        </Row>
      )}
    </Main>
  );
}

export default FreeResources;
