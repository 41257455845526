import React from 'react';
import { Form, Input } from 'antd';

import { ThemeProvider } from 'styled-components';
import { SearchBox } from '../Style';
import { useSelector } from 'react-redux';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

const { theme } = require('../../config/theme/themeVariables');
//const { useToken } = theme;


const HeaderSearch = ({UserInfo}) => {
    const { userTheme } = useSelector((state) => {
        return {
            userTheme: state.userTheme
        };
    });
    theme['user-theme'] = userTheme;

    const organizationalSlug = localStorage.getItem('organizationalSlug');
    
    return (
        <ThemeProvider theme={theme}>
            {/* <SearchBox>
                <Form
                    name="site-search"
                    className="search-form"
                    onFinish={onFinish}
                    >
                    <Form.Item
                        name="search"
                        rules={[{ required: true, message: 'Please input something to search!' }]}
                    >
                        <Input prefix={<FontAwesomeIcon icon={regular("magnifying-glass")} className="site-form-item-icon" />} placeholder="Search" />
                    </Form.Item>
                </Form>
            </SearchBox> */}
        </ThemeProvider>
    );
};

export default HeaderSearch;