import React, { useState, useReducer, useEffect, useCallback, useRef } from 'react';
import { FreeResourceDivider, FreeResourceListTitle, FreeResourceListWrapper, FreeResourceEmptyMessage, FreeResourceScrollableListContainer } from './FreeResources.styles';
import useNotificationController from '../../utility/useNotificationController';
import { useDispatch } from 'react-redux';
import { fetchResourcePages } from '../../redux/cms/actionCreator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as ProIcons from '@fortawesome/pro-duotone-svg-icons';
import { List } from 'antd';
import FreeResourceItem from './FreeResourceItem';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';

const FreeResourceList = ({category, sort, filter}) => {
    const {
      contextHolder,
      openError,
    } = useNotificationController();

    const scrollContainerRef = useRef(null);

    const [loaded, setLoaded] = useState(false);
    const [downloading, setDownloading] = useState({});
    const [downloadingTitle, setDownloadingTitle] = useState({});
    const [resourcePages, setResourcePages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [initLoading, setInitLoading] = useState(false);
    const [sortOrder, setSortOrder] = useState();
    const [filterString, setFilterString] = useState("");

    const initialState = {
        error: false,
        messages: ""
    };

    const reducer = (state, action) => {
      switch (action.type) {
        case 'FETCH_RESOURCE_PAGES_ERR':
          return { ...state, error: true,messages: action.err };
        case 'RESET':
          return state;
        default:
          return state;
      }
    };

    const [apiError, apiResponseCallback] = useReducer(reducer, initialState);
    const dispatch = useDispatch();

    const fetchPages = useCallback(async (category, sortField, filterData) => {
      setLoading(true);
      setInitLoading(true);
      if (sortField === undefined)
      {
        sortField = sortOrder;
      }
      if (filterData === undefined)
      {
        filterData = filterString;
      }
      apiResponseCallback({ type: 'RESET' });
        dispatch(
            fetchResourcePages({categoryId: category.id, sortOrder: sortField, filter: filterData},(response) => {
              if (response.status === 200) {
                setLoading(false);
                setInitLoading(false);
                setSortOrder(sortField);
                setFilterString(filterData);
                let pages = response.data.pages;
                const tmpPages = [];
                pages.map((item, index) => {
                  tmpPages.push({
                    id: item.id,
                    name: item.name,
                    filename_ext: item.filename_ext,
                    filesize: item.filesize,
                    icon: item.icon,
                    icon_type: item.icon_type,
                    faicon: item.faicon,
                    primary_color: item.primary_color,
                    secondary_color: item.secondary_color,
                    fileKey: item.fileKey
                  })
                });
                setResourcePages(tmpPages);
                setLoaded(true);
              } 
              else 
              {
                setLoaded(false);
                openError('topLeft', 'Error', response.err);
                apiResponseCallback(response);
              }
            }),
        );
    }, [
      dispatch, 
      resourcePages, 
      loading, 
      loaded, 
      sortOrder,
      initLoading,
      filterString
    ]);
    
    useEffect(() => {
      setLoaded(false);
      const retrieveData = async (category, sort) => {
        await fetchPages(category, sort);
      };
      if (Object.entries(category).length !== 0)
      {
        library.add(ProIcons[category.faicon]);
        setResourcePages([]);
        setSortOrder(sort);
        retrieveData(category, sort).catch(console.error);
      }
    }, [category]);

    useEffect(() => {
      setLoaded(false);
      const retrieveData = async (category, sort) => {
        await fetchPages(category, sort);
      };
      if (Object.entries(category).length !== 0)
      {
        library.add(ProIcons[category.faicon]);
        setResourcePages([]);
        setSortOrder(sort);
        retrieveData(category, sort).catch(console.error);
      }
    }, [sort]);

    useEffect(() => {
      setLoaded(false);
      const retrieveData = async (category, sort, filter) => {
        await fetchPages(category, sort, filter);
      };
      if (Object.entries(category).length !== 0)
      {
        library.add(ProIcons[category.faicon]);
        setResourcePages([]);
        setFilterString(filter);
        retrieveData(category, sortOrder, filter).catch(console.error);
      }
    }, [filter]);

    const handleDownload = async (item, selectedName, iconClick) => {
      if (iconClick)
      {
        setDownloading({...downloading, [item.fileKey]: true});
      }
      else
      {
        setDownloadingTitle({...downloading, [item.fileKey]: true});
      }
      const fileName = item.filename_ext;
      const url = `${process.env.REACT_APP_API_ENDPOINT}/file/download/resources/${selectedName}/${fileName}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`, // Include the token in the headers
        },
      });
      if ( response.status === 200 )
      {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
            if (iconClick)
            {
              setDownloading({...downloading, [item.fileKey]: false});
            }
            else
            {
              setDownloadingTitle({...downloading, [item.fileKey]: false});
            }
          });
  
      }
      else
      {
        if (iconClick)
        {
          setDownloading({...downloading, [item.fileKey]: false});
        }
        else
        {
          setDownloadingTitle({...downloading, [item.fileKey]: false});
        }
        openError('topLeft', 'Error', response.statusText);
      }
    }

    const copyDownload = async (item, selectedName) => {
      const fileName = item.filename_ext;
      const url = `${process.env.REACT_APP_BASE_URL}${localStorage.getItem('organizationalSlug')}/file/getfile/resources/${selectedName}/${fileName}`;
      await navigator.clipboard.writeText(url);
    }

    const userTheme = JSON.parse(localStorage.getItem('userTheme'));

    return (
      <FreeResourceListWrapper>
        {filterString.toString().trim() === "" ? (
          <FreeResourceListTitle style={{
            paddingBottom: process.env.REACT_APP_SHOW_RESOURCE_ICON === "true" ? "20px" : "0px",
            borderBottom: process.env.REACT_APP_SHOW_RESOURCE_ICON === "true" ? `1px solid ${category.secondaryColor}` : `0px solid ${category.secondaryColor}`,
            borderLeft: process.env.REACT_APP_SHOW_RESOURCE_ICON === "true" ? `0px solid ${category.secondaryColor}` : `6px solid ${category.secondaryColor}`,
            paddingLeft: process.env.REACT_APP_SHOW_RESOURCE_ICON === "true" ? `0px` : `10px`
          }}>
            {process.env.REACT_APP_SHOW_RESOURCE_ICON === "true" && (
              <FontAwesomeIcon
                icon={`fa-${category.icon_type} fa-${category.icon}`}
                style={{
                  '--fa-primary-color': `${category.primaryColor}`,
                  '--fa-secondary-color': `${category.secondaryColor}`,
                  width: "45px",
                  height: "45px",
                  paddingRight: "10px",
                  fontSize: "30px"
                }}
              />
            )}
            {category.name}
          </FreeResourceListTitle>
        ) : (
          <FreeResourceListTitle>{`Search for "${filterString}"`}</FreeResourceListTitle>
        )}
        {!loaded ? (
          <div
            className="loader"
            style={{ display: 'flex', alignItems: 'start', justifyContent: 'center', marginTop: "20px" }}
          >
            <FontAwesomeIcon
              icon={duotone('spinner-third')}
              spin
              style={{ '--fa-primary-color': `${userTheme.mainHighlightColor}`, '--fa-secondary-color': `${userTheme.mainHighlightColor}`, width: "30px", height: "30px", marginRight: "10px", marginLeft: "2px" }}
            /> <span style={{display: "flex", alignItems: "start"}}>Loading Resources</span>
          </div>
        ) : (
          <>
            <FreeResourceScrollableListContainer ref={scrollContainerRef} id="scrollableDiv">
              <List
                dataSource={resourcePages}
                renderItem={(resourcePage, index) => (
                  <List.Item 
                    key={index}
                    className={process.env.REACT_APP_SHOW_RESOURCE_ICON === "false" ? "no-border" : "borderList"}
                    actions={[
                      <FontAwesomeIcon 
                        icon={duotone("copy")} 
                        style={{"--fa-primary-color": "#00b85c", "--fa-secondary-color": "#00b85c", fontSize: "20px", paddingRight: "10px", cursor: "pointer"}} 
                        onClick={(e) => copyDownload(resourcePage, category.name)}
                      />,
                      <>
                      {downloading[resourcePage.file_key] ? (
                        <FontAwesomeIcon icon={duotone("spinner-third")} spin 
                          style={{"--fa-primary-color": "#37A9FF", "--fa-secondary-color": "#37A9FF", fontSize: "20px", marginRight: "10px"}}  
                        />
                      ) : (
                        <FontAwesomeIcon 
                          icon={duotone("download")} 
                          style={{"--fa-primary-color": "#37A9FF", "--fa-secondary-color": "#37A9FF", fontSize: "20px", paddingRight: "10px", cursor: "pointer"}} 
                          onClick={(e) => handleDownload(resourcePage, category.name, true)}
                        />
                      )}
                      </>,
                    ]}
                  >
                    <FreeResourceItem handleDownload={handleDownload} downloadingTitle={downloadingTitle} content={resourcePage} category={category} />
                    {index < resourcePages.length - 1 && <FreeResourceDivider />}
                  </List.Item>
                )}
                split={true}
                locale={{
                  emptyText: <FreeResourceEmptyMessage>No resources found for this category</FreeResourceEmptyMessage>
                }}
              />
            </FreeResourceScrollableListContainer>
          </>
        )}
      </FreeResourceListWrapper>
    );
}
    
export default FreeResourceList;