import { Tabs, Row, Divider, Button } from 'antd';
import Popup from './Popup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLink } from '@fortawesome/pro-duotone-svg-icons';
import styled from 'styled-components';
import SurveyEmailEditor from '../SurveyEmailEditor';
import { ActionContainer, PopupAction } from './Style';
import { encode } from '../../utils/utils';
import useNotificationController from '../../../../utility/useNotificationController';

const SendSurvey = ({ surveyData, open, onClose }) => {
  const {
    contextHolder,
    openSuccess,
  } = useNotificationController();

  const surveyShareableLink = `${window.location.protocol}/${window.location.host}/public/survey/${encode(surveyData?.id)}`
  
  return (
    <>
    {contextHolder}
    <Popup title={'Send Survey'} open={open} close={onClose} footer={null}>
      <Container>
        <h2>Send Via</h2>

        <Tabs
          type="card"
          size="large"
          items={[
            {
              key: '1',
              label: (
                <>
                  <FontAwesomeIcon size='xl' icon={faEnvelope} color="#FEC002" />
                </>
              ),
              children: <SurveyEmailEditor surveyData={surveyData} onClose={onClose} />,
            },
            {
              key: '2',
              label: (
                <>
                  <FontAwesomeIcon size='xl' icon={faLink} color="#39AAFF" />
                </>
              ),
              children: (
                <>
                  <Divider />
                  <h2>Link</h2>

                  <Row align={'middle'}>
                    <LinkText>
                      {surveyShareableLink}
                    </LinkText>
                    <Button type="primary" shape="round" onClick={async () => {
                      await navigator.clipboard.writeText(surveyShareableLink);
                      openSuccess('bottomRight', "Survey link",  `Survey link copied!`);
                    }}>
                      Copy Link
                    </Button>
                  </Row>
                  <Row>
                    <ActionContainer>
                      <PopupAction onClick={onClose}>Done</PopupAction>
                    </ActionContainer>
                  </Row>
                </>
              ),
            },
          ]}
        />
      </Container>
    </Popup>
    </>
  );
};

const Container = styled.div`
  > h2 {
    margin-top: 10px;
    margin-bottom: 0;
  }
  min-width: 700px;
  max-width: 1000px;
  width: 100%;
  position: relative;
  .ant-tabs-nav {
    position: absolute;
    top: 8px;
    left: 100px;
    .ant-tabs-nav-list {
      padding-bottom: 7px;
      .ant-tabs-tab {
        border-radius: 100px;
        margin-right: 10px;
        padding: 6px 20px;
      }
      .ant-tabs-tab.ant-tabs-tab-active {
        border-bottom: 1px solid #f0f0f0 !important;
        display: flex;
        flex-direction: column;
       &::after{
        content: '';
        border-bottom: 2px solid #05AFF0;
        height: 2px;
        width: 100%;
        display: flex;
        position: absolute;
    bottom: -7px;
       }
      }
    }
   
  }
`;

const LinkText = styled.code`
  padding: 5px 10px;
  font-size: 18px;
  font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
  width: calc(100% - 110px);
  margin-right: 10px;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
  background-color: #f5f8fa;
  margin-bottom: 0;
`;

export default SendSurvey;
