import { Radio } from 'antd';
import styled from 'styled-components';

const RadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    &:hover {
      background-color: #5dd3ff;
      border-color: #5dd3ff;
      color: white;
    }

    &.ant-radio-button-wrapper-checked {
      background-color: #5dd3ff;
      border-color: #5dd3ff;
      color: white;

      &:hover {
        background-color: #5dd3ff;
        border-color: #5dd3ff;
        color: white;
      }

      &:first-child.ant-radio-button-wrapper-checked {
        border-color: #5dd3ff;
      }

      &:not(.ant-radio-button-wrapper-disabled):first-child {
        border-right-color: #5dd3ff;
      }
    }
  }
`;

export default RadioGroup;
