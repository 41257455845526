import React from 'react';
import { Button, Col, Form, Modal, Row, Typography } from 'antd';

const {Title} = Typography;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';

function AudienceLocations(props) {
    const [form] = Form.useForm();

    const showHelp = () => {
        form.resetFields() 
        event.stopPropagation();
        const model = Modal.info();
  
        const closeModal = () => model.destroy();
        
        model.update({
          icon: (
            <FontAwesomeIcon size='2xl' icon={duotone("circle-xmark")} className='ant-modal-close' onClick={closeModal} />
          ),
          key: 'modal-edit-location',
          centered: true,
          closeIcon: (
            <FontAwesomeIcon size='2xl' icon={duotone("circle-xmark")} className='ant-modal-close' onClick={closeModal} />
          ),
          onCancel:{closeModal},
          wrapClassName:'ocm-modal',
          okButtonProps: {
            style: {display: "none"},
          },
          width: 1200,
          content: (
            <ChampionModalBodyStyle>
              <div className='body-top-panel'>
                <img src={require(`../../../../../static/images/location_level.png`)} alt="" style={{width: "100%"}} />
              </div>
            </ChampionModalBodyStyle>
          ),
        });
    }

    return (
        <>
        <div className='title-input'>
            <Title level={2}>Let's add your audiences</Title>
        </div>
        <div className='title-group-start' style={{textAlign: "center", paddingTop: "20px"}}>
            <Title level={1} style={{marginBottom: "0px"}}>Next, add your audiences.</Title>
            <Title level={4}>(Note: You can add more in later)</Title>
            <div className='audienceInputForm' style={{paddingTop: "40px"}}>
                <div className="image-people">
                    <img 
                        className='' 
                        src={require('../../../../../static/images/people-new.svg').default} 
                    />
                </div>
                <Row style={{marginTop: '40px'}}>
                    <Col xxl={12} xl={12} lg={12} md={12}>
                    <Button
                        className="btn-create"
                        htmlType="submit"
                        type="ocm-purple-big"
                        size="large"
                        onClick={async () => {
                            await props.createColumns();
                            props.setIsEditMode(true)
                            await props.setEditType('Manual');
                        }}
                    >
                        Input manually
                    </Button>
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12}>
                        <Button
                            className="btn-create"
                            htmlType="submit"
                            type="ocm-default-big"
                            size="large"
                            onClick={async () => {
                                await props.createColumns();
                                props.setIsEditMode(true)
                                await props.setEditType('Import Excel');
                            }}
                        >
                            Import a spreadsheet
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
        </>
    )
}

export default AudienceLocations;
