/* eslint-disable react/prop-types */
import { Col, Layout, Row } from 'antd';
import propTypes from 'prop-types';
import { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import MenuItems from './MenuItems';
import { FooterStyle, LayoutContainer, SmallScreenAuthInfo, SidebarContainer } from './Style';
import MainHeader from './common/MainHeader';
import AuthInfo from '../components/utilities/auth-info/info';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import StopSubscriptionCancellation from './widgets/StopSubscriptionCancellation';
import RestartSubscription from './widgets/RestartSubscription';
import ResumeSubscription from './widgets/ResumeSubscription';
import DeleteUserAccount from './widgets/DeleteUserAccount';

const { theme } = require('../config/theme/themeVariables');

const { Header, Sider, Content } = Layout;

const ThemeLayout = (WrappedComponent) => {
  class LayoutComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        top: 0,
        collapsed: false,
        hide: true,
        sidebarDataLoad: false,
        isFreeAccount: false,
        sidebarData:null,
        organizationalSlug: "",
        openKeys: [],
        openSubKeys: [],
        projectDropdownChanged: false,
        isModalVisible: false
      };
      this.updateDimensions = this.updateDimensions.bind(this);
      this.handleUpdate = this.handleUpdate.bind(this);
      this.renderView = this.renderView.bind(this);
    }

    handleUpdate(values) {
        const { top } = values;
        // this.setState({ top });
    }

    renderView({ style, ...props }) {
        const { top } = this.state;
        const viewStyle = {
            padding: 15,
            // backgroundColor: `rgb(${Math.round(255 - (top * 255))}, ${Math.round(top * 255)}, ${Math.round(255)})`,
            color: `rgb(${Math.round(255 - (top * 255))}, ${Math.round(255 - (top * 255))}, ${Math.round(255 - (top * 255))})`
        };
        return (
            <div
                className="box"
                style={{ ...style, ...viewStyle }}
                {...props}/>
        );
    }

    componentDidMount() {
      //window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions();
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions() {
      this.setState({
        collapsed: window.innerWidth <= 1200 && true,
      });
    }

    render() {
      const { collapsed, hide, sidebarDataLoad, isFreeAccount, sidebarData, organizationalSlug, SideBarStyle, openKeys, openSubKeys, projectDropdownChanged, isModalVisible } = this.state;
      const { layoutMode, rtl, topMenu, userTheme } = this.props;
      const left = !rtl ? 'left' : 'right';
      const is_marked_as_cancel = localStorage.getItem('is_marked_as_cancel') === 'true';
      const is_cancelled = localStorage.getItem('is_cancelled') === 'true';
      const is_paused = localStorage.getItem('is_paused') === 'true';
      const marked_for_delete = localStorage.getItem('marked_for_delete') === 'true';

      const toggleCollapsed = () => {
        this.setState({
          collapsed: !collapsed,
        });
      };

      const rearrangeProject = () => {
        this.setState({
          projectDropdownChanged: true,
        });
      };

      const setOpenKeys = (key) => {
        let currentKey = key[0];
        let itemKey = key[0];
        let keyFound = false;
        let pathName = window.location.pathname;
        let pathArray = pathName ? pathName.split('/' + organizationalSlug) : [];
        let mainPath = pathArray.length > 1 ? pathArray[1] : '';
        let mainPathSplit = mainPath.split('/');
        let firstChildrenExecute = false;
        let firstChildrenRoutes = "";
        if (sidebarData !== null)
        {
          sidebarData.sidebar.forEach(sidebar => {
            if (sidebar.key === currentKey && sidebar.childrens.length > 0)
            {
              keyFound = true; 
              let k = 0;
              sidebar.childrens.forEach(children => {
                let currentPath = mainPathSplit.length == 2 ? mainPathSplit[1] : '';
                if (k === 0)
                {
                  currentKey = children.key;
                  currentPath = children.key;
                  if (sidebar.key === "reports")
                  {
                    firstChildrenRoutes = `${sidebar.key}/${children.routes}`;
                  }
                  else
                  {
                    firstChildrenRoutes = children.routes;
                  }
                  firstChildrenExecute = true;
                }
                k++;
                if (currentPath !== currentKey)
                {
                  if (children.params === "")
                  {
                    currentKey = sidebar.key;
                    itemKey = sidebar.key;
                  }
                }
                if (currentPath === currentKey)
                {
                  if (children.params === "")
                  {
                      currentKey = children.key;
                      itemKey = sidebar.key;
                  }
                }
                return ;
              });
            }
          });
          if (!keyFound)
          {
            sidebarData.sidebar.forEach(sidebar => {
              if (sidebar.childrens.length > 0)
              {
                keyFound = true; 
                sidebar.childrens.forEach(children => {
                  if (children.key === currentKey)
                  {
                    itemKey = sidebar.key;
                  }
                  return ;
                });
              }
            });
          }
        }
        // if (firstChildrenExecute)
        // {
        //     window.location.href = `/${organizationalSlug}/${firstChildrenRoutes}`;
        //     //window.navigator && window.navigator.navigate(`/${organizationalSlug}/${firstChildrenRoutes}`);
        // }
        let keys = [
                    'Subscribe Now', 'Update Subscription', 'Transaction History', 
                    'Billing Cycle', 'Payment Method', 'Pause Subscription', 
                    'Resume Subscription', 'Cancel Subscription', 'Stop Cancellation', 
                    'Restart Subscription', 'delete-account'
                   ];
        if (keys.includes(currentKey))
        {
          itemKey = 'subscription';
        }
        this.setState({
          openKeys: [currentKey],
          openSubKeys: [itemKey],
        });

        return [firstChildrenExecute, firstChildrenRoutes]
      };

      const setSidebarData = (sidebar_data, organizationalSlug, isFreeAccount) => {
        const userTheme = JSON.parse(localStorage.getItem('userTheme'));
        const customSideBarStyle = {style: {
            margin: '23px 0 0 0',
            backgroundColor: `${JSON.parse(localStorage.getItem('userTheme')).sidebarColor}`,
            padding: `${!rtl ? '50px 0 85px 0' : '50px 0 55px 20px'}`,
            overflowY: 'auto',
            height: '110vh',
            position: 'fixed',
            [left]: 0,
            zIndex: 988
          }
        };
        
        sidebar_data = Object.assign(sidebar_data, customSideBarStyle);
        theme['user-theme'] = userTheme;

        let pathName = window.location.pathname;
        let pathArray = pathName ? pathName.split('/' + organizationalSlug) : [];
        let mainPath = pathArray.length > 1 ? pathArray[1] : '';
        let mainPathSplit = mainPath.split('/');
        
        let currentOpenKeys = !topMenu ? [`${mainPathSplit.length == 3 ? mainPathSplit[mainPathSplit.length-2] + `-` + mainPathSplit[mainPathSplit.length-1] : mainPathSplit.length == 2 ? mainPathSplit[1] : 'dashboard'}`] : [];
        let currentOpenSubKeys = !topMenu ? [`${mainPathSplit.length == 3 ? mainPathSplit[mainPathSplit.length-2] : mainPathSplit.length == 2 ? mainPathSplit[1] : 'dashboard'}`] : [];
        if (currentOpenSubKeys[0] === "tools-home" && currentOpenKeys[0] !== "tools-home-target-audience")
        {
            currentOpenKeys = ['tools-dashboard-ocm-tools'];
            currentOpenSubKeys = ['tools-dashboard'];
        }
        else if (currentOpenSubKeys[0] === "tools-home" && currentOpenKeys[0] === "tools-home-target-audience")
        {
            currentOpenKeys = ['tools-dashboard-audience'];
            currentOpenSubKeys = ['tools-dashboard-audience'];
        }
        sidebar_data.sidebar.forEach(sidebar => {
          if (sidebar.key === currentOpenKeys[0] && sidebar.childrens.length > 0)
          {
            sidebar.childrens.forEach(children => {
              let currentPath = mainPathSplit[mainPathSplit.length-1];
              if (currentPath !== currentOpenKeys[0])
              {
                if (children.params === "")
                {
                  currentOpenKeys = [sidebar.key];
                  currentOpenSubKeys = [sidebar.key];
                }
              }
              if (currentPath === currentOpenKeys[0])
              {
                if (children.params === "")
                {
                  currentOpenKeys = [children.key];
                  currentOpenSubKeys = [sidebar.key];
                }
              }
              return ;
            });
          }
        });
        
        this.setState({
          sidebarDataLoad: true,
          isFreeAccount: isFreeAccount,
          sidebarData: sidebar_data,
          organizationalSlug: organizationalSlug,
          sideBarStyle: customSideBarStyle,
          openKeys: currentOpenKeys,
          openSubKeys: currentOpenSubKeys
        });
      };

      const toggleCollapsedMobile = () => {
        if (window.innerWidth <= 990) {
          this.setState({
            collapsed: !collapsed,
          });
        }
      };

      // const onShowHide = () => {
      //   this.setState({
      //     hide: !hide,
      //   });
      // };

      const renderView = ({ style }) => {
        const customStyle = {
          marginRight: 'auto',
          [rtl ? 'marginLeft' : 'marginRight']: '0px',
        };
        return <div style={{ ...style, ...customStyle }} />;
      };
      
      return (
        <LayoutContainer>
          <Layout className="layout">
            <MainHeader 
              MenueItemsFor={topMenu} 
              isCollapsed={function collapsedThis() {
                toggleCollapsed();
              }} 
              setSidebarData={function sidebarData(sidebar_data, organizationalSlug, isFreeAccount) {
                setSidebarData(sidebar_data, organizationalSlug, isFreeAccount);
              }}
              projectDropdownChanged={projectDropdownChanged}
              handleProjectDropdownChanged={() => {
                this.setState({
                  projectDropdownChanged: false,
                });
              }}
            />
            <div className="ocmsolution-header-more">
              <Row>
                <Col md={0} sm={24} xs={24}>
                  <div className="ocmsolution-header-more-inner">
                    <SmallScreenAuthInfo hide={hide}>
                      <AuthInfo />
                    </SmallScreenAuthInfo>
                  </div>
                </Col>
              </Row>
            </div>
            <Layout>
              {!topMenu || window.innerWidth <= 991 ? (
                  <>
                    {sidebarDataLoad === false ? (
                      <div className="loader">
                        <FontAwesomeIcon icon={duotone("spinner-third")} spin size="2xl" style={{"--fa-primary-color": "#FFFFFF", "--fa-secondary-color": "#FFFFFF", "padding": "20px 10px"}} />
                      </div>
                    ) : (
                      <>
                        <ThemeProvider theme={theme}>
                          <SidebarContainer>
                            <Sider
                              width={200}
                              style={sidebarData.style}
                              collapsed={collapsed}
                              theme={layoutMode === 'lightMode' ? 'light' : 'dark'}
                            >
                              <Scrollbars
                                  renderView={this.renderView}
                                  renderTrackHorizontal={({ style, ...props }) => {
                                    const trackStyle = {
                                      height: '8px',
                                      right: '2px', 
                                      bottom: '2px',
                                      left: '2px',
                                      borderRadius: '3px'
                                    };
                                    return (
                                        <div
                                            style={{ ...style, ...trackStyle }}
                                            {...props}/>
                                    );
                                  }}
                                  renderTrackVertical={({ style, ...props }) => {
                                    const trackStyle = {
                                      width: '10px',
                                      right: '2px', 
                                      bottom: '2px',
                                      top: '2px',
                                      borderRadius: '3px'
                                    };
                                    return (
                                        <div
                                            style={{ ...style, ...trackStyle }}
                                            {...props}/>
                                    );
                                  }}
                                  renderThumbHorizontal={({ style, ...props }) => {
                                    const thumbStyle = {
                                      backgroundColor: `${JSON.parse(localStorage.getItem('userTheme')).sidebarHoverColor}`
                                    };
                                    return (
                                        <div
                                            style={{ ...style, ...thumbStyle }}
                                            {...props}/>
                                    );
                                  }}
                                  renderThumbVertical={({ style, ...props }) => {
                                    const thumbStyle = {
                                      backgroundColor: `${JSON.parse(localStorage.getItem('userTheme')).sidebarHoverColor}`
                                    };
                                    return (
                                        <div
                                            style={{ ...style, ...thumbStyle }}
                                            {...props}/>
                                    );
                                  }}
                                  onUpdate={this.handleUpdate}
                                  >
                                <MenuItems 
                                  userTheme={JSON.parse(localStorage.getItem('userTheme'))}
                                  sidebarData={sidebarData}
                                  isFreeAccount={isFreeAccount}
                                  organizationalSlug={organizationalSlug}
                                  topMenu={topMenu} 
                                  openKeys={openKeys} 
                                  openSubKeys={openSubKeys} 
                                  setOpenKeys={setOpenKeys} 
                                  toggleCollapsed={toggleCollapsedMobile} 
                                />
                              </Scrollbars>
                            </Sider>
                          </SidebarContainer>
                        </ThemeProvider>
                      </>
                    )}
                  </>
              ) : null}
              <Layout className="atbd-main-layout">
                <Content>
                  {marked_for_delete && (
                     <DeleteUserAccount />
                  )}
                  {is_marked_as_cancel && !marked_for_delete && (
                     <StopSubscriptionCancellation />
                  )}
                  {is_cancelled && !marked_for_delete && !is_marked_as_cancel && (
                     <RestartSubscription />
                  )}
                  {is_paused && !is_cancelled && !is_marked_as_cancel && !marked_for_delete && (
                     <ResumeSubscription />
                  )}
                  <WrappedComponent {...this.props} setOpenKeys={setOpenKeys} rearrangeProject={rearrangeProject} />
                  <FooterStyle className="admin-footer">
                    <Row>
                      <Col md={12} xs={24}>
                        {/* <span className="admin-footer__copyright">
                          © 2023<Link to="#">SovWare</Link>
                        </span> */}
                      </Col>
                      <Col md={12} xs={24}>
                        {/* <div className="admin-footer__links">
                          <NavLink to="#">About</NavLink>
                          <NavLink to="#">Team</NavLink>
                          <NavLink to="#">Contact</NavLink>
                        </div> */}
                      </Col>
                    </Row>
                  </FooterStyle>
                </Content>
              </Layout>
            </Layout>
          </Layout>
          {window.innerWidth <= 991 ? (
            <span className={collapsed ? 'ocmsolution-shade' : 'ocmsolution-shade show'} onClick={toggleCollapsed} />
          ) : (
            ''
          )}
        </LayoutContainer>
      );
    }
  }

  const mapStateToProps = (state) => {
    return {
      layoutMode: state.ChangeLayoutMode.mode,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      userTheme: state.userTheme
    };
  };

  LayoutComponent.propTypes = {
    layoutMode: propTypes.string,
    rtl: propTypes.bool,
    topMenu: propTypes.bool,
    userTheme: propTypes.object
  };

  return connect(mapStateToProps)(LayoutComponent);
};
export default ThemeLayout;
