import React, { useEffect, useState, useReducer, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CMSPublicPageContentWrapper, MainPublicContentWrapper, PageContentWrapper } from "./LibrarySection.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useDispatch } from "react-redux";
import { fetchPublicCMSPageContent } from "../../redux/cms/actionCreator";
import useNotificationController from "../../utility/useNotificationController";
import { Helmet } from 'react-helmet';
import { Alert, Col, Row } from "antd";
import CMSPageHeader from "./CMSPageHeader";
import '@fortawesome/fontawesome-pro/css/all.min.css';
import PublicSignUpHeader from "./PublicSignUpHeader";
import BackToListButtonPublic from "./BackToListButtonPublic";

const CMSPublicPageContent = () => {
  const {
    contextHolder,
    openError,
  } = useNotificationController();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [pageContent, setPageContent] = useState({});
  const [linkContent, setLinkContent] = useState("");
  
  const initialState = {
      error: false,
      messages: ""
  };
  const reducer = (state, action) => {
      switch (action.type) {
        case 'VERIFY_USER_ERR':
          return { ...state, error: true,messages: action.err };
        case 'RESET':
          return state;
        default:
          return state;
      }
  };
  const [apiError, apiResponseCallback] = useReducer(reducer, initialState);
  const dispatch = useDispatch();

  const fetchContent = useCallback(async (id, slug) => {
    setLoading(true);
    apiResponseCallback({ type: 'RESET' });
      dispatch(
        fetchPublicCMSPageContent({id: id, slug: slug},(response) => {
            if (response.status === 200) {
              const redirect = response.data.redirect;
              if (redirect !== null && redirect !== undefined)
              {
                navigate(`/`);
              }
              if (response.data.page === null)
              {
                navigate(`/`);
              }
              else if (response.data.page === undefined)
              {
                navigate(`/`);
              }
              else
              {
                setLoading(false);
                setPageContent(response.data.page);
                setLoaded(true);
              }
            } 
            else 
            {
              navigate(`/`);
              apiResponseCallback(response);
            }
          }),
        );
  }, [
    dispatch, 
    pageContent, 
    loaded,
    linkContent,
    loading
  ]);

  useEffect(() => {
    const retrieveContent = async (id, slug) => {
      await fetchContent(id, slug);
    };
    
    window.scrollTo(0, 0);
    const url = window.location.href;
    const paths = url.split("/");
    let contentSlug = paths[paths.length - 1];
    if (contentSlug.toString().trim() === "")
    {
      contentSlug = paths[paths.length - 2];
    }
    if (contentSlug.indexOf("-") === -1)
    {
      navigate(`/`);
    }
    else
    {
      const slugs = contentSlug.split("-");
      if (slugs.length === 0)
      {
        navigate(`/`);
      }
      else if (slugs.length === 1)
      {
        retrieveContent(0, contentSlug);
      }
      else
      {
        if (contentSlug.indexOf("#") !== -1)
        {
          const slugs = contentSlug.split("#");
          contentSlug = slugs[0];
          setLinkContent(slugs[1]);
        }
        retrieveContent(0, contentSlug);
      }
    }
  }, [pathname]);

  const onContentLoaded = () => {
    if (linkContent !== null && linkContent.toString().trim() !== '')
    {
      window.scrollTo({
        behavior: 'smooth',
        top:
          document.querySelector(`#${linkContent}`).getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          100,
      });
    }
    // Add your logic here
  };

  // Use useEffect to trigger the function when data is loaded
  useEffect(() => {
    if (!loading && pageContent !== null) {
      onContentLoaded();
    }
  }, [loading, pageContent]);

  return (
    <>
    <PublicSignUpHeader />
    <MainPublicContentWrapper>
      {contextHolder}
      {!loaded ? (
        <div className="loader" key={0} style={{ padding: "10px", display: "flex", height: "200px", alignItems: "center", justifyContent: "center" }}>
          <FontAwesomeIcon icon={duotone("spinner-third")} spin size="2xl" style={{ "--fa-primary-color": `${'#7AC6FF'}`, "--fa-secondary-color": `${'#7AC6FF'}`, marginRight: "20px", fontSize: "50px" }} />
          <span style={{fontSize: "20px", color: `${'#7AC6FF'}`}}>Loading, please wait...</span>
        </div>
      ) : (
          <Row gutter={[16, 16]} style={{marginRight: "0px"}}>
            <Col span={24} style={{paddingLeft: "50px", paddingRight: "50px"}}>
              <CMSPublicPageContentWrapper>
                <CMSPageHeader contentTitle={pageContent.title} />
                <PageContentWrapper>
                  {pageContent.goToListTag !== null && pageContent.goToListTag.toString().trim() !== '' && (
                    <BackToListButtonPublic backtoButton={pageContent.goToListTag} />
                  )}
                  <Helmet>
                    {pageContent.metaTitle !== null && pageContent.metaTitle.toString().trim() !== '' && (
                      <title>{pageContent.metaTitle}</title>
                    )}
                    {pageContent.metaDescription !== null && pageContent.metaDescription.toString().trim() !== '' && (
                      <meta name="description" content={pageContent.metaDescription} />
                    )}
                    {pageContent.metaKeyword !== null && pageContent.metaKeyword.toString().trim() !== '' && (
                      <meta name="keywords" content={pageContent.metaKeyword} />
                    )}
                    {pageContent.metaTitle !== null && pageContent.metaTitle.toString().trim() !== '' && (
                      <meta property="og:title" content={pageContent.metaTitle} />
                    )}
                    {pageContent.metaDescription !== null && pageContent.metaDescription.toString().trim() !== '' && (
                      <meta property="og:description" content={pageContent.metaDescription} />
                    )}
                    <meta property="og:type" content="website" />
                  </Helmet>
                  <div dangerouslySetInnerHTML={{ __html: `${pageContent.content}` }} />
                </PageContentWrapper>
              </CMSPublicPageContentWrapper>
            </Col>
          </Row>
      )}
    </MainPublicContentWrapper>
    </>
  );
}

export default CMSPublicPageContent;