import React, { useRef, useEffect, useState } from 'react';

import { Chart, BarController, BarElement, LinearScale, TimeScale, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-moment';
import { CategoryScale } from 'chart.js';
// Import utilities
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Import the datalabels plugin
import 'chart.js/auto';
Chart.register(ChartDataLabels, CategoryScale, BarController, BarElement, LinearScale, TimeScale, Tooltip, Legend);
function VerticalBarChart({ xAxisTitle, yAxisTitle, data, width, height, legends, positionLegend='top', alignLegend='end', legendTitle = '', legendsColors, valuePercentage= false }) {
  let highMidLow = ['Low', 'Mid', 'High'];
  let colors = ['#FF5D5F', '#FFC125', '#00B85C'];
  let fontColors = ['#fff', '#404040', '#fff']
  const canvas2 = useRef(null);

  if (legends === null)
    {
      highMidLow = [];
    } else if (legends !== undefined)
          {
            highMidLow = legends;
          } 

  if (legendsColors !== undefined)
    {
      colors = legendsColors;
    }
  
  useEffect(() => {
    const ctx = canvas2.current;

    const newChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: data?.data?.labels,
        datasets: data?.data?.data?.map((element, index) => ({
          label: highMidLow[index],
          maxBarThickness: 25,
          data: element,
          backgroundColor: colors[index],
          hoverBackgroundColor: colors[index],
        })).reverse(),
      },
      options: {
        indexAxis: 'x',
        elements: {
          bar: {
            borderRadius: 5,
          },
        },
        scales: {
          x: {
            stacked: true,
            maxBarThickness: 50,
            border: {
              display: false,
            },
            title: {
              display: xAxisTitle ? true : false,
              text: xAxisTitle ?? '',
              color: '#7F7F7F',
            },
            ticks: { stepSize: 1, color: '#7F7F7F', maxRotation: 40, minRotation: 40 },
            grid: {
              display: false
            },
            gridLines: {
              drawBorder: false,
            },
          },
          y: {
            stacked: true,
            title: {
              display: yAxisTitle ? true : false,
              text: yAxisTitle ?? '',
              color: '#7F7F7F',
            },
            border: {
              display: false,
            },
            ticks: { stepSize: 1, color: '#7F7F7F', brginAtZero: true },
            grid: {
              color: '#D9D9D9',
              drawTicks: true,
            },
            gridLines: {
              drawBorder: true,
            },
          },
        },
        autoPadding: true,
        layout: {
          padding: {
            top: 50,
            bottom: 40,
            left: 20,
          },
        },
        interaction: {
          intersect: false,
          mode: 'nearest',
        },
        animation: {
          duration: 500,
        },
        responsive: true,
        maintainAspectRatio: false,
        resizeDelay: 200,

        plugins: {
          outlabels: false,
          legend: {
            display: false,
          },
          datalabels: {
            anchor: 'center',
            align: 'center',
            color: function (context) {
              const isMid = context.dataset.label === 'Mid';
              const color = isMid ? '#404040' : '#fff';
              return color;
            },
            font: {
              weight: 'normal',
              size: 10
            },
            formatter: function (value, context) {
              return Math?.round(value) > 0 ? valuePercentage ? `${Math?.round(value)}%` : Math?.round(value) : '';
            },
          },
        },
      },
      plugins: [{
        id: 'custom-legend',
        afterDraw: (chart) => {
          const ctx = chart.ctx;
          ctx.save();
          const fontSize = 12;
          const legendBoxWidth = 40; // Fixed width for the legend boxes
          const legendBoxHeight = 20; // Reduced height for the legend boxes
          const padding = 10; // Padding between legend boxes
          const titlePadding = 4;
          ctx.font = `${fontSize}px Inter`;
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
      
          let startX;
          let startY;
      
          // Determine the starting point for the legend based on position and align
          switch(positionLegend) {
            case 'top':
              startY = (chart.chartArea.top - (legendBoxHeight)) - 20;
              if (alignLegend === 'start') {
                startX = chart.chartArea.left;
              } else if (alignLegend === 'center') {
                startX = chart.chartArea.left + (chart.chartArea.width / 2) - ((legendBoxWidth + padding) * highMidLow.length / 2);
              } else { // 'end'
                startX = chart.chartArea.right - ((legendBoxWidth + padding) * highMidLow.length);
              }
              break;
            case 'bottom':
              startY = chart.chartArea.bottom ;
              if (alignLegend === 'start') {
                startX = chart.chartArea.left;
              } else if (alignLegend === 'center') {
                startX = chart.chartArea.left + (chart.chartArea.width / 2) - ((legendBoxWidth + padding) * highMidLow.length / 2);
              } else { // 'end'
                startX = chart.chartArea.right - ((legendBoxWidth + padding) * highMidLow.length);
              }
              break;
            default:
              startX = chart.chartArea.left;
              startY = chart.chartArea.top - (legendBoxHeight);
              break;
          }

          if (legendTitle) {
            ctx.fillStyle = '#7F7F7F';
            ctx.font = '10px Arial, sans-serif';
            ctx.fillText(legendTitle, startX + ((legendBoxWidth + padding) * highMidLow.length / 2), startY - (titlePadding / 2));
            startY += 5;
            startY += 6;
          }
      
          highMidLow.forEach((label, index) => {
            const color = colors[index];
            const x = startX + (legendBoxWidth + padding) * index;
            const y = startY + (legendBoxHeight / 2); // Adjusted y position
      
            // Draw the legend box
            ctx.fillStyle = color;
            ctx.fillRect(x, y - (legendBoxHeight / 2), legendBoxWidth, legendBoxHeight);
            ctx.font = '10px Arial, sans-serif';
            ctx.weight = 400;
      
            // Draw the label inside the legend box with margin
            const fontColor = fontColors[index]
            ctx.fillStyle = fontColor; // Set text color
            ctx.fillText(label, x + legendBoxWidth / 2, y);
          });
      
          ctx.restore();
        }
      }],
      
      
    });
    return () => newChart.destroy();
  }, [data]);

  return (
    <React.Fragment>
      <canvas ref={canvas2}></canvas>
    </React.Fragment>
  );
}

export default VerticalBarChart;
