import Styled, { css } from 'styled-components';

const UserStyle = Styled.div`
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    .ant-tabs-nav {
        margin: 0;
    }
    .ant-tabs-content-holder {
        margin-top: 30px;
    }
    .box-all
    {
        border:1px solid #B0B5C3;
        border-radius:10px;
        
    }
    .container-create-button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
      }
    .box-icon
    {
        border-right:1px solid #B0B5C3;
        border-radius:10px;
        padding: 40px 0px;
        text-align:center;
        background:#F7F9FB;
    }
    .ant-page-header-heading-title{
        border-bottom: 4px solid rgb(0, 176, 240);
        font-size: 26px !important;
        padding-bottom: 5px;
    }
    .ant-tabs-ink-bar-animated {
        display: none !important;
    }
    .countDiv
    {
        text-align:center;
        font-size: 27px;
        font-weight:bold;
        padding: 42px 0px;

    }
    .anticon-filter.anticon svg
    {
        color:inherit !important;
    }
    .ant-tabs-nav-list{
        // width: 100%;
        justify-content: center;
        .ant-tabs-tab {
            white-space: pre-wrap;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
        .ant-tabs-ink-bar {
            height: 5px;
            background: #0066B2;
        }
        .title-label {
            min-width: 200px;
            font-size: 0.8rem;
            color: #333;
        }
        .ant-tabs-tab-active {
            .ant-tabs-tab-btn {
                color: inherit;
            }
            .title-label {
                font-size: 0.8rem;
                font-weight: 600;
                .active-meter {
                    display: flex;
                }
            }
        }
    }
`;
export {
    UserStyle
}