import React, { useCallback, useEffect, useReducer } from 'react';
import { Row, Col, Skeleton, Tabs, Card, Space } from 'antd';

import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { PageHeader } from '../../components/page-headers/page-headers';
import { getOCMTools } from "../../redux/common/actionCreator";
import { ToolsHomePage, ToolsFreeTab } from './Style';
import { Main } from '../styled';
import ToolInfo from './widgets/ToolInfo';
import { DataService } from '../../config/dataService/dataService';
import AddAudience from './panel/target-audience/AddAudience';
const { theme } = require('../../config/theme/themeVariables');

function ToolsFreeHome(props) {
  const { pathname } = useLocation();
  const hasTargetAudience = localStorage.getItem('has_target_audience').toString().trim() === "true";
  let changeByTab = false;
  let paths = pathname.split('/');
  const [defaultActiveKey, setDefaultActiveKey] = useState(
    paths.length != 0 ? paths[paths.length - 1] : "tasks-checklist"
  );
  let currentPath = paths.length != 0 ? paths[paths.length - 1] : "tasks-checklist";
  if (currentPath !== defaultActiveKey && !changeByTab) {
    setDefaultActiveKey(
      paths.length != 0 ? paths[paths.length - 1] : "tasks-checklist"
    );
  }

  const [toolsData, setToolsData] = useState({
    loaded: false,
    tools: [],
    toolsName: "",
    tabBorderColor: "",
    PageRoutes: []
  });

  const reducer = (state, action) => {
    switch (action.type) {
      case 'OCM_TOOLS_ERR':
        return { ...state, error: true, ocmToolsMessage: action.err };
      case 'RESET':
        return state;
      default:
        return state;
    }
  };

  const ocmToolsResponse = {
    error: false,
    ocmToolsMessage: '',
  };

  const [userToolsError, ocmToolsResponses] = useReducer(reducer, ocmToolsResponse);
  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    ocmToolsResponses({ type: 'RESET' });
    dispatch(
      getOCMTools({ toolType: 'free' }, (response) => {
        if (response.status === 200) {
          const tools_data = response.data[0].toolInfo;
          let PageRoutes = [];
          let tools_name = "";
          const userTheme = JSON.parse(localStorage.getItem('userTheme'))
          let tools_border_color = userTheme.sidebarColor;
          tools_data.tools.map((item, i) => {
            if (item.tools_key === defaultActiveKey) {
              PageRoutes.push({
                path: item.tools_key,
                breadcrumbName: item.display_name,
              });
              tools_border_color = item.tab_color;
              tools_name = item.display_name;
              return item;
            }
          });
          theme['tab-theme'] = tools_data.tools;
          theme['tab-border-color'] = tools_border_color;
          theme['tab-color'] = tools_border_color;
          setToolsData({
            loaded: true,
            tools: tools_data.tools,
            toolsName: tools_name,
            tabBorderColor: tools_border_color,
            PageRoutes: PageRoutes
          });
        } else {
          ocmToolsResponses(response);
        }
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    setToolsData({
      loaded: false,
      tools: [],
      toolsName: "",
      tabBorderColor: "",
      PageRoutes: []
    });
    if (props.setOpenKeys !== undefined && typeof props.setOpenKeys === 'function') {
      if (defaultActiveKey === "target-audience") {
        props.setOpenKeys(['tools-dashboard-audience']);
      }
      else {
        props.setOpenKeys(['tools-dashboard-free-tools']);
      }
    }
    const retrieveData = async () => {
      fetchData();
    }
    retrieveData()
      .catch(console.error);
  }, []);

  return (
    <>
      <Main>
        <ToolsHomePage>
          {!toolsData.loaded ? (
            <Row gutter={25}>
              <Col xxl={24} xl={24} md={24} className={`item-tools`}>
                <Card className='loadmoretool-list' style={{ minHeight: "600px" }}>
                  <Row align={'middle'} justify={'center'} gutter={[25, 25]} style={{ marginTop: "20px" }}>
                    <Col xxl={8}>
                      <Skeleton.Input loading active size={'large'} block={Card} />
                    </Col>
                  </Row>
                  <Row gutter={[25, 25]} style={{ marginTop: "20px" }}>
                    {(() => {
                      let skeletons = [];
                      for (let i = 1; i <= 8; i++) {
                        skeletons.push(
                          <Col key={`skeletons-${i}`} xxl={3} md={6} className={`item-tools`}>
                            <Card className='loadmoretool-list'>
                              <Skeleton active loading round>
                                <Card className='tools-card'></Card>
                              </Skeleton>
                            </Card>
                          </Col>
                        );
                      }
                      return skeletons;
                    })()}
                  </Row>
                  <Row gutter={[25, 25]}>
                    <Col xxl={24} xl={24} md={24} className={`item-tools`} style={{ marginTop: "10px" }}>
                      <Card className='loadmoretool-list'>
                        <Space>
                          <Skeleton.Button loading active size={'small'} shape={'square'} block={Card} />
                          <Skeleton.Avatar loading active size={'small'} shape={'circle'} />
                          <Skeleton.Input loading active size={'small'} />
                        </Space>
                        <br />
                        <br />
                        <Skeleton.Button loading active size={'small'} shape={'square'} block={Card} />
                        <br />
                        <br />
                        <Skeleton.Input loading active size={'small'} block={Card} />
                      </Card>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          ) : (
            <ToolsFreeTab theme={theme}>
              <Row gutter={[25, 25]}>
                <Col xxl={24} xl={24} md={24}>
                  <Card className='loadmoretool-list' style={{ minHeight: "600px" }}>
                    <PageHeader className="ocmsolution-page-header-main" ghost title={`${toolsData.toolsName}`} routes={toolsData.PageRoutes} />
                    <Tabs
                      defaultActiveKey={defaultActiveKey}
                      className='freePhaseTabStyle'
                      type={'card'}
                      size={'large'}
                      tabBarStyle={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                      items={toolsData.tools.map((item, i) => {
                        return {
                          label: (<div className='title-label'>
                            {item.tools_name}
                            <div className="active-meter active-meter-top"></div>
                            <div className="active-meter active-meter-bottom"></div>
                          </div>
                          ),
                          key: item.tools_key,
                          children: (
                            <ToolInfo tool={item} tabColor={toolsData.tabBorderColor} {...props} />
                          ),
                        };
                      })}
                      onChange={(activeKey) => {
                        let PageRoutes = [];
                        let tools_name = "";
                        let tools_border_color = "0066B2";
                        toolsData.tools.map((item, i) => {
                          setDefaultActiveKey(activeKey);
                          if (item.tools_key === activeKey) {
                            PageRoutes.push({
                              path: item.tools_key,
                              breadcrumbName: item.display_name,
                            });
                            window.history.replaceState(null, item.display_name, `/${localStorage.getItem('organizationalSlug')}/free-tools/${item.tools_key}`)
                            if (props.setOpenKeys !== undefined && typeof props.setOpenKeys === 'function') {
                              if (item.tools_key === "target-audience") {
                                props.setOpenKeys(['tools-dashboard-audience']);
                              }
                              else {
                                props.setOpenKeys(['tools-dashboard-free-tools']);
                              }
                            }
                            const url = window.location.href;
                            DataService.post("/users/save-visits", { url });
                            //navigate();
                            tools_border_color = item.tab_color;
                            tools_name = item.display_name;
                            return item;
                          }
                        });
                        theme['tab-border-color'] = tools_border_color;
                        theme['tab-color'] = tools_border_color;
                        setToolsData({
                          ...toolsData,
                          toolsName: tools_name,
                          tabBorderColor: tools_border_color,
                          PageRoutes: PageRoutes
                        });
                      }}
                    />
                  </Card>
                </Col>
              </Row>
              {!hasTargetAudience && (
                <AddAudience />
              )}
            </ToolsFreeTab>
          )}
        </ToolsHomePage>
      </Main>
    </>
  );
}

export default ToolsFreeHome;
