const actions = {
  GET_STAKEHOLDER_TYPE_BEGIN: 'GET_STAKEHOLDER_TYPE_BEGIN',
  GET_STAKEHOLDER_TYPE_SUCCESS: 'GET_STAKEHOLDER_TYPE_SUCCESS',
  GET_STAKEHOLDER_TYPE_ERR: 'GET_STAKEHOLDER_TYPE_ERR',

  SAVE_STAKEHOLDER_TYPE_BEGIN: 'SAVE_STAKEHOLDER_TYPE_BEGIN',
  SAVE_STAKEHOLDER_TYPE_SUCCESS: 'SAVE_STAKEHOLDER_TYPE_SUCCESS',
  SAVE_STAKEHOLDER_TYPE_ERR: 'SAVE_STAKEHOLDER_TYPE_ERR',

  DELETE_STAKEHOLDER_TYPE_BEGIN: 'DELETE_STAKEHOLDER_TYPE_BEGIN',
  DELETE_STAKEHOLDER_TYPE_SUCCESS: 'DELETE_STAKEHOLDER_TYPE_SUCCESS',
  DELETE_STAKEHOLDER_TYPE_ERR: 'DELETE_STAKEHOLDER_TYPE_ERR',

  UPDATE_STAKEHOLDER_TYPE_BEGIN: 'UPDATE_STAKEHOLDER_TYPE_BEGIN',
  UPDATE_STAKEHOLDER_TYPE_SUCCESS: 'UPDATE_STAKEHOLDER_TYPE_SUCCESS',
  UPDATE_STAKEHOLDER_TYPE_ERR: 'UPDATE_STAKEHOLDER_TYPE_ERR',

  SAVE_AUDIENCE_STAKEHOLDER_BEGIN: 'SAVE_AUDIENCE_STAKEHOLDER_BEGIN',
  SAVE_AUDIENCE_STAKEHOLDER_SUCCESS: 'SAVE_AUDIENCE_STAKEHOLDER_SUCCESS',
  SAVE_AUDIENCE_STAKEHOLDER_ERR: 'SAVE_AUDIENCE_STAKEHOLDER_ERR',

  SAVE_AUDIENCE_BEGIN: 'SAVE_AUDIENCE_BEGIN',
  SAVE_AUDIENCE_SUCCESS: 'SAVE_AUDIENCE_SUCCESS',
  SAVE_AUDIENCE_ERR: 'SAVE_AUDIENCE_ERR',

  UPDATE_GROUP_NAME_BEGIN: 'UPDATE_GROUP_NAME_BEGIN',
  UPDATE_GROUP_NAME_SUCCESS: 'UPDATE_GROUP_NAME_SUCCESS',
  UPDATE_GROUP_NAME_ERR: 'UPDATE_GROUP_NAME_ERR',

  SAVE_GROUP_NAME_BEGIN: 'SAVE_GROUP_NAME_BEGIN',
  SAVE_GROUP_NAME_SUCCESS: 'SAVE_GROUP_NAME_SUCCESS',
  SAVE_GROUP_NAME_ERR: 'SAVE_GROUP_NAME_ERR',

  GET_GROUP_LEVELS_BEGIN: 'GET_GROUP_LEVELS_BEGIN',
  GET_GROUP_LEVELS_SUCCESS: 'GET_GROUP_LEVELS_SUCCESS',
  GET_GROUP_LEVELS_ERR: 'GET_GROUP_LEVELS_ERR',

  GET_GROUP_LEVELS_ONLY_BEGIN: 'GET_GROUP_LEVELS_ONLY_BEGIN',
  GET_GROUP_LEVELS_ONLY_SUCCESS: 'GET_GROUP_LEVELS_ONLY_SUCCESS',
  GET_GROUP_LEVELS_ONLY_ERR: 'GET_GROUP_LEVELS_ONLY_ERR',

  GET_ALL_JOB_ROLE_BEGIN: 'GET_ALL_JOB_ROLE_BEGIN',
  GET_ALL_JOB_ROLE_SUCCESS: 'GET_ALL_JOB_ROLE_SUCCESS',
  GET_ALL_JOB_ROLE_ERR: 'GET_ALL_JOB_ROLE_ERR',

  GET_GROUP_NAME_BY_LEVEL_BEGIN: 'GET_GROUP_NAME_BY_LEVEL_BEGIN',
  GET_GROUP_NAME_BY_LEVEL_SUCCESS: 'GET_GROUP_NAME_BY_LEVEL_SUCCESS',
  GET_GROUP_NAME_BY_LEVEL_ERR: 'GET_GROUP_NAME_BY_LEVEL_ERR',

  GET_GROUP_NAME_BY_LEVEL_ID_BEGIN: 'GET_GROUP_NAME_BY_LEVEL_ID_BEGIN',
  GET_GROUP_NAME_BY_LEVEL_ID_SUCCESS: 'GET_GROUP_NAME_BY_LEVEL_ID_SUCCESS',
  GET_GROUP_NAME_BY_LEVEL_ID_ERR: 'GET_GROUP_NAME_BY_LEVEL_ID_ERR',

  GET_TARGET_AUDIENCE_STAT_BEGIN: 'GET_TARGET_AUDIENCE_STAT_BEGIN',
  GET_TARGET_AUDIENCE_STAT_SUCCESS: 'GET_TARGET_AUDIENCE_STAT_SUCCESS',
  GET_TARGET_AUDIENCE_STAT_ERR: 'GET_TARGET_AUDIENCE_STAT_ERR',

  GET_LOCATION_LEVELS_BEGIN: 'GET_LOCATION_LEVELS_BEGIN',
  GET_LOCATION_LEVELS_SUCCESS: 'GET_LOCATION_LEVELS_SUCCESS',
  GET_LOCATION_LEVELS_ERR: 'GET_LOCATION_LEVELS_ERR',

  GET_LOCATION_LEVELS_ONLY_BEGIN: 'GET_LOCATION_LEVELS_ONLY_BEGIN',
  GET_LOCATION_LEVELS_ONLY_SUCCESS: 'GET_LOCATION_LEVELS_ONLY_SUCCESS',
  GET_LOCATION_LEVELS_ONLY_ERR: 'GET_LOCATION_LEVELS_ONLY_ERR',

  GET_LOCATION_NAME_BY_LEVEL_BEGIN: 'GET_LOCATION_NAME_BY_LEVEL_BEGIN',
  GET_LOCATION_NAME_BY_LEVEL_SUCCESS: 'GET_LOCATION_NAME_BY_LEVEL_SUCCESS',
  GET_LOCATION_NAME_BY_LEVEL_ERR: 'GET_LOCATION_NAME_BY_LEVEL_ERR',

  GET_TYPE_OF_CHANGES_BEGIN: 'GET_TYPE_OF_CHANGES_BEGIN',
  GET_TYPE_OF_CHANGES_SUCCESS: 'GET_TYPE_OF_CHANGES_SUCCESS',
  GET_TYPE_OF_CHANGES_ERR: 'GET_TYPE_OF_CHANGES_ERR',

  GET_AUDIENCE_LIST_BEGIN: 'GET_AUDIENCE_LIST_BEGIN',
  GET_AUDIENCE_LIST_SUCCESS: 'GET_AUDIENCE_LIST_SUCCESS',
  GET_AUDIENCE_LIST_ERR: 'GET_AUDIENCE_LIST_ERR',

  GET_AUDIENCE_DATA_BEGIN: 'GET_AUDIENCE_DATA_BEGIN',
  GET_AUDIENCE_DATA_SUCCESS: 'GET_AUDIENCE_DATA_SUCCESS',
  GET_AUDIENCE_DATA_ERR: 'GET_AUDIENCE_DATA_ERR',

  GET_ACCOUNT_USER_LIST_BEGIN: 'GET_ACCOUNT_USER_LIST_BEGIN',
  GET_ACCOUNT_USER_LIST_SUCCESS: 'GET_ACCOUNT_USER_LIST_SUCCESS',
  GET_ACCOUNT_USER_LIST_ERR: 'GET_ACCOUNT_USER_LIST_ERR',
  
  TOOLS_INSTALLER_BEGIN: 'TOOLS_INSTALLER_BEGIN',
  TOOLS_INSTALLER_SUCCESS: 'TOOLS_INSTALLER_SUCCESS',
  TOOLS_INSTALLER_ERR: 'TOOLS_INSTALLER_ERR',
  
  TOOLS_COPY_PROJECT_BEGIN: 'TOOLS_COPY_PROJECT_BEGIN',
  TOOLS_COPY_PROJECT_SUCCESS: 'TOOLS_COPY_PROJECT_SUCCESS',
  TOOLS_COPY_PROJECT_ERR: 'TOOLS_COPY_PROJECT_ERR',

  SAVE_CHANGE_TYPE_BEGIN: 'SAVE_CHANGE_TYPE_BEGIN',
  SAVE_CHANGE_TYPE_SUCCESS: 'SAVE_CHANGE_TYPE_SUCCESS',
  SAVE_CHANGE_TYPE_ERR: 'SAVE_CHANGE_TYPE_ERR',

  SAVE_CHANNEL_BEGIN: 'SAVE_CHANNEL_BEGIN',
  SAVE_CHANNEL_SUCCESS: 'SAVE_CHANNEL_SUCCESS',
  SAVE_CHANNEL_ERR: 'SAVE_CHANNEL_ERR',

  SAVE_OBJECTIVE_BEGIN: 'SAVE_OBJECTIVE_BEGIN',
  SAVE_OBJECTIVE_SUCCESS: 'SAVE_OBJECTIVE_SUCCESS',
  SAVE_OBJECTIVE_ERR: 'SAVE_OBJECTIVE_ERR',

  SAVE_SUCCESS_METRIC_BEGIN: 'SAVE_SUCCESS_METRIC_BEGIN',
  SAVE_SUCCESS_METRIC_SUCCESS: 'SAVE_SUCCESS_METRIC_SUCCESS',
  SAVE_SUCCESS_METRIC_ERR: 'SAVE_SUCCESS_METRIC_ERR',

  SAVE_COMMUNICATION_TYPE_BEGIN: 'SAVE_COMMUNICATION_TYPE_BEGIN',
  SAVE_COMMUNICATION_TYPE_SUCCESS: 'SAVE_COMMUNICATION_TYPE_SUCCESS',
  SAVE_COMMUNICATION_TYPE_ERR: 'SAVE_COMMUNICATION_TYPE_ERR',

  DELETE_ALL_AUDIENCES_BEGIN: 'DELETE_ALL_AUDIENCES_BEGIN',
  DELETE_ALL_AUDIENCES_SUCCESS: 'DELETE_ALL_AUDIENCES_SUCCESS',
  DELETE_ALL_AUDIENCES_ERR: 'DELETE_ALL_AUDIENCES_ERR',

  DELETE_GROUP_BEGIN: 'DELETE_GROUP_BEGIN',
  DELETE_GROUP_SUCCESS: 'DELETE_GROUP_SUCCESS',
  DELETE_GROUP_ERR: 'DELETE_GROUP_ERR',

  GET_PROFILE_USER_INFO_BEGIN: 'GET_PROFILE_USER_INFO_BEGIN',
  GET_PROFILE_USER_INFO_SUCCESS: 'GET_PROFILE_USER_INFO_SUCCESS',
  GET_PROFILE_USER_INFO_ERR: 'GET_PROFILE_USER_INFO_ERR',

  GET_LOCATION_GROUPS_LEVELS_BEGIN: 'GET_LOCATION_GROUP_LEVELS_BEGIN',
  GET_LOCATION_GROUP_LEVELS_SUCCESS: 'GET_LOCATION_GROUP_LEVELS_SUCCESS',
  GET_LOCATION_GROUP_LEVELS_ERR: 'GET_LOCATION_GROUP_LEVELS_ERR',

  ADD_GROUP_BEGIN: 'ADD_GROUP_BEGIN',
  ADD_GROUP_SUCCESS: 'ADD_GROUP_SUCCESS',
  ADD_GROUP_ERR: 'ADD_GROUP_ERR',

  saveCommunicationTypeBegin: () => {
    return {
      type: actions.SAVE_COMMUNICATION_TYPE_BEGIN,
    };
  },

  saveCommunicationTypeSuccess: (data) => {
    return {
      type: actions.SAVE_COMMUNICATION_TYPE_SUCCESS,
      data,
    };
  },

  saveCommunicationTypeErr: (err) => {
    return {
      type: actions.SAVE_COMMUNICATION_TYPE_ERR,
      err,
    };
  },

  getProfileUserInfoBegin: () => {
    return {
      type: actions.GET_PROFILE_USER_INFO_BEGIN,
    };
  },

  getProfileUserInfoSuccess: (data) => {
    return {
      type: actions.GET_PROFILE_USER_INFO_SUCCESS,
      data,
    };
  },

  getProfileUserInfoErr: (err) => {
    return {
      type: actions.GET_PROFILE_USER_INFO_ERR,
      err,
    };
  },

  deleteAllAudienceBegin: () => {
    return {
      type: actions.DELETE_ALL_AUDIENCES_BEGIN,
    };
  },

  deleteAllAudienceSuccess: (data) => {
    return {
      type: actions.DELETE_ALL_AUDIENCES_SUCCESS,
      data,
    };
  },

  deleteAllAudienceErr: (err) => {
    return {
      type: actions.DELETE_ALL_AUDIENCES_ERR,
      err,
    };
  },

  deleteGroupBegin: () => {
    return {
      type: actions.DELETE_GROUP_BEGIN,
    };
  },

  deleteGroupSuccess: (data) => {
    return {
      type: actions.DELETE_GROUP_SUCCESS,
      data,
    };
  },

  deleteGroupErr: (err) => {
    return {
      type: actions.DELETE_GROUP_ERR,
      err,
    };
  },

  saveSuccessMetricBegin: () => {
    return {
      type: actions.SAVE_SUCCESS_METRIC_BEGIN,
    };
  },

  saveSuccessMetricSuccess: (data) => {
    return {
      type: actions.SAVE_SUCCESS_METRIC_SUCCESS,
      data,
    };
  },

  saveSuccessMetricErr: (err) => {
    return {
      type: actions.SAVE_SUCCESS_METRIC_ERR,
      err,
    };
  },

  saveObjectiveBegin: () => {
    return {
      type: actions.SAVE_OBJECTIVE_BEGIN,
    };
  },

  saveObjectiveSuccess: (data) => {
    return {
      type: actions.SAVE_OBJECTIVE_SUCCESS,
      data,
    };
  },

  saveObjectiveErr: (err) => {
    return {
      type: actions.SAVE_OBJECTIVE_ERR,
      err,
    };
  },

  saveChannelBegin: () => {
    return {
      type: actions.SAVE_CHANNEL_BEGIN,
    };
  },

  saveChannelSuccess: (data) => {
    return {
      type: actions.SAVE_CHANNEL_SUCCESS,
      data,
    };
  },

  saveChannelErr: (err) => {
    return {
      type: actions.SAVE_CHANNEL_ERR,
      err,
    };
  },


  saveChangeTypeBegin: () => {
    return {
      type: actions.SAVE_CHANGE_TYPE_BEGIN,
    };
  },

  saveChangeTypeSuccess: (data) => {
    return {
      type: actions.SAVE_CHANGE_TYPE_SUCCESS,
      data,
    };
  },

  saveChangeTypeErr: (err) => {
    return {
      type: actions.SAVE_CHANGE_TYPE_ERR,
      err,
    };
  },

  getStakeholderTypeBegin: () => {
    return {
      type: actions.GET_STAKEHOLDER_TYPE_BEGIN,
    };
  },

  getStakeholderTypeSuccess: (data) => {
    return {
      type: actions.GET_STAKEHOLDER_TYPE_SUCCESS,
      data,
    };
  },

  getStakeholderTypeErr: (err) => {
    return {
      type: actions.GET_STAKEHOLDER_TYPE_ERR,
      err,
    };
  },

  getGroupLevelsBegin: () => {
    return {
      type: actions.GET_GROUP_LEVELS_BEGIN,
    };
  },

  getGroupLevelsSuccess: (data) => {
    return {
      type: actions.GET_GROUP_LEVELS_SUCCESS,
      data,
    };
  },

  getGroupLevelsErr: (err) => {
    return {
      type: actions.GET_GROUP_LEVELS_ERR,
      err,
    };
  },

  getGroupLevelsOnlyBegin: () => {
    return {
      type: actions.GET_GROUP_LEVELS_ONLY_BEGIN,
    };
  },

  getGroupLevelsOnlySuccess: (data) => {
    return {
      type: actions.GET_GROUP_LEVELS_ONLY_SUCCESS,
      data,
    };
  },

  getGroupLevelsOnlyErr: (err) => {
    return {
      type: actions.GET_GROUP_LEVELS_ONLY_ERR,
      err,
    };
  },

  getAllJobRolesBegin: () => {
    return {
      type: actions.GET_ALL_JOB_ROLE_BEGIN,
    };
  },

  getAllJobRolesSuccess: (data) => {
    return {
      type: actions.GET_ALL_JOB_ROLE_SUCCESS,
      data,
    };
  },

  getAllJobRolesErr: (err) => {
    return {
      type: actions.GET_ALL_JOB_ROLE_ERR,
      err,
    };
  },

  getGroupNamesByLevelBegin: () => {
    return {
      type: actions.GET_GROUP_NAME_BY_LEVEL_BEGIN,
    };
  },

  getGroupNamesByLevelSuccess: (data) => {
    return {
      type: actions.GET_GROUP_NAME_BY_LEVEL_SUCCESS,
      data,
    };
  },

  getGroupNamesByLevelErr: (err) => {
    return {
      type: actions.GET_GROUP_NAME_BY_LEVEL_ERR,
      err,
    };
  },

  getGroupNamesByLevelIdBegin: () => {
    return {
      type: actions.GET_GROUP_NAME_BY_LEVEL_ID_BEGIN,
    };
  },

  getGroupNamesByLevelIdSuccess: (data) => {
    return {
      type: actions.GET_GROUP_NAME_BY_LEVEL_ID_SUCCESS,
      data,
    };
  },

  getGroupNamesByLevelIdErr: (err) => {
    return {
      type: actions.GET_GROUP_NAME_BY_LEVEL_ID_ERR,
      err,
    };
  },

  getTargetAudienceStatBegin: () => {
    return {
      type: actions.GET_TARGET_AUDIENCE_STAT_BEGIN,
    };
  },

  getTargetAudienceStatSuccess: (data) => {
    return {
      type: actions.GET_TARGET_AUDIENCE_STAT_SUCCESS,
      data,
    };
  },

  getTargetAudienceStatErr: (err) => {
    return {
      type: actions.GET_TARGET_AUDIENCE_STAT_ERR,
      err,
    };
  },

  getLocationLevelsBegin: () => {
    return {
      type: actions.GET_LOCATION_LEVELS_BEGIN,
    };
  },

  getLocationLevelsSuccess: (data) => {
    return {
      type: actions.GET_LOCATION_LEVELS_SUCCESS,
      data,
    };
  },

  getLocationLevelsErr: (err) => {
    return {
      type: actions.GET_LOCATION_LEVELS_ERR,
      err,
    };
  },

  getLocationLevelsOnlyBegin: () => {
    return {
      type: actions.GET_LOCATION_LEVELS_ONLY_BEGIN,
    };
  },

  getLocationLevelsOnlySuccess: (data) => {
    return {
      type: actions.GET_LOCATION_LEVELS_ONLY_SUCCESS,
      data,
    };
  },

  getLocationLevelsOnlyErr: (err) => {
    return {
      type: actions.GET_LOCATION_LEVELS_ONLY_ERR,
      err,
    };
  },

  getLocationNamesByLevelBegin: () => {
    return {
      type: actions.GET_LOCATION_NAME_BY_LEVEL_BEGIN,
    };
  },

  getLocationNamesByLevelSuccess: (data) => {
    return {
      type: actions.GET_LOCATION_NAME_BY_LEVEL_SUCCESS,
      data,
    };
  },

  getLocationNamesByLevelErr: (err) => {
    return {
      type: actions.GET_LOCATION_NAME_BY_LEVEL_ERR,
      err,
    };
  },

  getTypeofChangesBegin: () => {
    return {
      type: actions.GET_TYPE_OF_CHANGES_BEGIN,
    };
  },

  getTypeofChangesSuccess: (data) => {
    return {
      type: actions.GET_TYPE_OF_CHANGES_SUCCESS,
      data,
    };
  },

  getTypeofChangesErr: (err) => {
    return {
      type: actions.GET_TYPE_OF_CHANGES_ERR,
      err,
    };
  },

  saveStakeholderTypeBegin: () => {
    return {
      type: actions.SAVE_STAKEHOLDER_TYPE_BEGIN,
    };
  },

  saveStakeholderTypeSuccess: (data) => {
    return {
      type: actions.SAVE_STAKEHOLDER_TYPE_SUCCESS,
      data,
    };
  },

  saveStakeholderTypeErr: (err) => {
    return {
      type: actions.SAVE_STAKEHOLDER_TYPE_ERR,
      err,
    };
  },

  deleteStakeholderTypeBegin: () => {
    return {
      type: actions.DELETE_STAKEHOLDER_TYPE_BEGIN,
    };
  },

  deleteStakeholderTypeSuccess: (data) => {
    return {
      type: actions.DELETE_STAKEHOLDER_TYPE_SUCCESS,
      data,
    };
  },

  deleteStakeholderTypeErr: (err) => {
    return {
      type: actions.DELETE_STAKEHOLDER_TYPE_ERR,
      err,
    };
  },

  updateStakeholderTypeBegin: () => {
    return {
      type: actions.UPDATE_STAKEHOLDER_TYPE_BEGIN,
    };
  },

  updateStakeholderTypeSuccess: (data) => {
    return {
      type: actions.UPDATE_STAKEHOLDER_TYPE_SUCCESS,
      data,
    };
  },

  updateStakeholderTypeErr: (err) => {
    return {
      type: actions.UPDATE_STAKEHOLDER_TYPE_ERR,
      err,
    };
  },

  saveAudienceStakeholderBegin: () => {
    return {
      type: actions.SAVE_AUDIENCE_STAKEHOLDER_BEGIN,
    };
  },

  saveAudienceStakeholderSuccess: (data) => {
    return {
      type: actions.SAVE_AUDIENCE_STAKEHOLDER_SUCCESS,
      data,
    };
  },

  saveAudienceStakeholderErr: (err) => {
    return {
      type: actions.SAVE_AUDIENCE_STAKEHOLDER_ERR,
      err,
    };
  },

  saveAudienceBegin: () => {
    return {
      type: actions.SAVE_AUDIENCE_BEGIN,
    };
  },

  saveAudienceSuccess: (data) => {
    return {
      type: actions.SAVE_AUDIENCE_SUCCESS,
      data,
    };
  },

  saveAudienceErr: (err) => {
    return {
      type: actions.SAVE_AUDIENCE_ERR,
      err,
    };
  },

  updateGroupNameBegin: () => {
    return {
      type: actions.UPDATE_GROUP_NAME_BEGIN,
    };
  },

  updateGroupNameSuccess: (data) => {
    return {
      type: actions.UPDATE_GROUP_NAME_SUCCESS,
      data,
    };
  },

  updateGroupNameErr: (err) => {
    return {
      type: actions.UPDATE_GROUP_NAME_ERR,
      err,
    };
  },

  getAudienceListBegin: () => {
    return {
      type: actions.GET_AUDIENCE_LIST_BEGIN,
    };
  },

  getAudienceListSuccess: (data) => {
    return {
      type: actions.GET_AUDIENCE_LIST_SUCCESS,
      data,
    };
  },

  getAudienceListErr: (err) => {
    return {
      type: actions.GET_AUDIENCE_LIST_ERR,
      err,
    };
  },

  getAudienceDataBegin: () => {
    return {
      type: actions.GET_AUDIENCE_DATA_BEGIN,
    };
  },

  getAudienceDataSuccess: (data) => {
    return {
      type: actions.GET_AUDIENCE_DATA_SUCCESS,
      data,
    };
  },

  getAudienceDataErr: (err) => {
    return {
      type: actions.GET_AUDIENCE_DATA_ERR,
      err,
    };
  },

  getAccountUserListBegin: () => {
    return {
      type: actions.GET_ACCOUNT_USER_LIST_BEGIN,
    };
  },

  getAccountUserListSuccess: (data) => {
    return {
      type: actions.GET_ACCOUNT_USER_LIST_SUCCESS,
      data,
    };
  },

  getAccountUserListErr: (err) => {
    return {
      type: actions.GET_ACCOUNT_USER_LIST_ERR,
      err,
    };
  },

  ToolsInstallerBegin: () => {
    return {
      type: actions.TOOLS_INSTALLER_BEGIN,
    };
  },

  ToolsInstallerSuccess: (data) => {
    return {
      type: actions.TOOLS_INSTALLER_SUCCESS,
      data,
    };
  },

  ToolsInstallerErr: (err) => {
    return {
      type: actions.TOOLS_INSTALLER_ERR,
      err,
    };
  },

  ToolsCopyProjectBegin: () => {
    return {
      type: actions.TOOLS_COPY_PROJECT_BEGIN,
    };
  },

  ToolsCopyProjectSuccess: (data) => {
    return {
      type: actions.TOOLS_COPY_PROJECT_SUCCESS,
      data,
    };
  },

  ToolsCopyProjectErr: (err) => {
    return {
      type: actions.TOOLS_COPY_PROJECT_ERR,
      err,
    };
  },

  getLocationAndGroupLevelsBegin: () => {
    return {
      type: actions.GET_LOCATION_GROUPS_LEVELS_BEGIN,
    };
  },

  getLocationAndGroupLevelsSuccess: (data) => {
    return {
      type: actions.GET_LOCATION_GROUP_LEVELS_SUCCESS,
      data,
    };
  },

  getLocationAndGroupLevelsErr: (err) => {
    return {
      type: actions.GET_LOCATION_GROUP_LEVELS_ERR,
      err,
    };
  },

  addGroupBegin: (err) => {
    return {
      type: actions.ADD_GROUP_BEGIN,
      err,
    };
  },
  addGroupSuccess: (err) => {
    return {
      type: actions.ADD_GROUP_SUCCESS,
      err,
    };
  },
  addGroupErr: (err) => {
    return {
      type: actions.ADD_GROUP_ERR,
      err,
    };
  },

};

export default actions;
