import React, { useState } from 'react';
import { Avatar, Tooltip } from 'antd';


const UserProfileAvatar = (props) => {
    const profileImage = localStorage.getItem('profileImage');
    return (
      <>
          {
            (profileImage !== undefined && profileImage !== null && profileImage.trim() !== "") ? (
              <Tooltip title={localStorage.getItem('full_name')} placement="top">
                <Avatar src={`${process.env.REACT_APP_API_ENDPOINT}/images/user_images/${profileImage}`} style={{verticalAlign: 'middle', marginRight: props.margin || "7px", border: props.border || "none" }} size={props.size || 'large'} gap={6} />
              </Tooltip>
            ) : (
              <Tooltip title={localStorage.getItem('full_name')} placement="top">
                <Avatar style={{ backgroundColor: `${localStorage.getItem('initialColor') || "#2197c2"}`, verticalAlign: 'middle', marginRight: props.margin || "2px", border: props.border || "none" }} size={props.size || 'large'} gap={6}>
                  {localStorage.getItem('initialName')}
                </Avatar>
              </Tooltip>
          )}
      </>
    );
};

export default UserProfileAvatar;