import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { CardFrame } from './style';
import Heading from '../../heading/heading';
import { Dropdown } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

function Cards(props) {
  const {
    title,
    children,
    more,
    moreText,
    size,
    headless,
    caption,
    isbutton,
    bodyStyle,
    headStyle,
    border = false,
    bodypadding,
    className,
  } = props;
  return (
    <>
      {!headless ? (
        <CardFrame
          size={size}
          title={title}
          styles={{
            body: bodyStyle && bodyStyle,
            header: headStyle && headStyle
          }}
          bordered={border}
          className={className}
          bodypadding={bodypadding && bodypadding}
          extra={
            <>
              {more && (
                <Dropdown content={more} placement="bottom">
                  <Link onClick={(e) => e.preventDefault()} to="#">
                    {!moreText ? <FontAwesomeIcon style={{fontSize: "20px"}} icon={regular("ellipsis")} /> : 'More'}
                  </Link>
                </Dropdown>
              )}

              {isbutton && isbutton}
            </>
          }
          style={{ width: '100%' }}
        >
          {children}
        </CardFrame>
      ) : (
        <CardFrame
          bodypadding={bodypadding && bodypadding}
          size={size}
          style={{ width: '100%' }}
          bordered={border}
          className={className}
          styles={{
            body: bodyStyle && bodyStyle
          }}
        >
          {title && <Heading as="h4">{title}</Heading>}
          {caption && <p>{caption}</p>}
          {children}
        </CardFrame>
      )}
    </>
  );
}


export { Cards };
