import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { Row, Col, Alert } from 'antd';
import { Main } from '../styled';
import { useDispatch } from 'react-redux';
import useNotificationController from '../../utility/useNotificationController';
import { 
  BackgroundImage, 
  ContentAreaWrapper, 
  LibraryContainer, 
  LibraryImageWrapper, 
  LibraryTitle, 
  Overlay } 
from './LibrarySection.styles';
import ContentArea from './ContentArea';
import ChangeManagementLicense from './ChangeManagementLicense';
import { verifyUser } from '../../redux/authentication/actionCreator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';

const LibrarySection = ({setOpenKeys}) => {
  const {
    contextHolder,
    openError,
  } = useNotificationController();

  const [loaded, setLoaded] = useState(false);
  const [loadedWithError, setLoadedWithError] = useState(false);
  const [isFreeAccount, setIsFreeAccount] = useState(false);
  const [currentSlug, setCurrentSlug] = useState(localStorage.getItem('organizationalSlug'));
  const [isPaidAccount, setIsPaidAccount] = useState(false);
  const [isTrialAccount, setIsTrialAccount] = useState(false);
  const [hasLibraryLicense, setHasLibraryLicense] = useState(false);
  const [userType, setUserType] = useState(1);
  const [defaultImage, setDefaultImage] = useState('cover-change-management-library.png');

  const is_amazon = localStorage.getItem('is_amazon') === 'true';

  const initialState = {
      error: false,
      messages: ""
  };

  const reducer = (state, action) => {
      switch (action.type) {
        case 'VERIFY_USER_ERR':
          return { ...state, error: true,messages: action.err };
        case 'RESET':
          return state;
        default:
          return state;
      }
  };
  const [apiError, apiResponseCallback] = useReducer(reducer, initialState);
  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    apiResponseCallback({ type: 'RESET' });
      dispatch(
          verifyUser({checkLibrary: true},(response) => {
            if (response.status === 200) {
              let organizationalSlug = response.data.organizationalSlug;
              setCurrentSlug(organizationalSlug);
              setUserType(response.data.userType);
              setDefaultImage(response.data.featuredImage);
              if (response.data.hasLibraryLicense) 
              {
                setHasLibraryLicense(response.data.hasLibraryLicense);
              }
              else
              {
                setHasLibraryLicense(false);
              }
              if (response.data.isPaidAccount)
              {
                setIsPaidAccount(true);
              }
              else if (response.data.isTrialAccount && parseInt(response.data.remainingDays) > 0)
              {
                setHasLibraryLicense(true);
                setIsTrialAccount(true);
              }
              else if (response.data.isFreeAccount)
              {
                setHasLibraryLicense(false);
                setIsFreeAccount(true);
              }
              if (is_amazon)
              {
                setHasLibraryLicense(true);
              }
              setLoaded(true);
              setLoadedWithError(false);
            } 
            else 
            {
              setLoaded(true);
              setLoadedWithError(true);
              openError('topLeft', 'Error', response.err);
              apiResponseCallback(response);
            }
          }),
        );
  }, [
    dispatch, 
    isFreeAccount, 
    isPaidAccount, 
    isTrialAccount, 
    hasLibraryLicense, 
    currentSlug, 
    defaultImage,
    loaded,
    loadedWithError,
    userType
  ]);

  useEffect(() => {
    const retrieveData = async () => {
      await fetchData();
    };
    if (!loaded)
    {
      retrieveData().catch(console.error);
    }
    if (typeof setOpenKeys === 'function')
    {
      setOpenKeys(['change-management-library']);
    }
    const currentPath = localStorage.getItem("currentPath");
    if (currentPath !== "change-management-library")
    {
      localStorage.removeItem('currentCategory');
    }
    if (window.location.pathname.indexOf("change-management-library"))
    {
      localStorage.setItem('currentPath', "change-management-library");
    }
  }, []);

  const setFeaturedImage = (img) => {
    setDefaultImage(img);
  }

  const userTheme = JSON.parse(localStorage.getItem('userTheme'));
  
  return (
    <Main>
      {contextHolder}
      {!loaded ? (
        <div className="loader" key={0} style={{ padding: "10px", display: "flex", height: "200px", alignItems: "center", justifyContent: "center" }}>
          <FontAwesomeIcon icon={duotone("spinner-third")} spin size="2xl" style={{ "--fa-primary-color": `${userTheme.mainHighlightColor}`, "--fa-secondary-color": `${userTheme.mainHighlightColor}`, marginRight: "20px", fontSize: "100px" }} />
          <span style={{fontSize: "40px", color: `${userTheme.mainHighlightColor}`}}>Loading Change Management Library...</span>
        </div>
      ) : (
        loadedWithError ? (
          <Row gutter={[16, 16]} style={{marginRight: "0px"}}>
            <Col span={24} style={{padding: "20px"}}>
              <Alert
                message="Error Loading Change Management Library"
                description="There is an error occur while loading Change Management Library"
                type="error"
                closable={false}
              />
            </Col>
          </Row>
        ) : (
          <Row gutter={[16, 16]} style={{marginRight: "0px"}}>
            <Col span={24} style={{paddingLeft: "0px"}}>
              <LibraryContainer>
                <LibraryImageWrapper>
                  <BackgroundImage src={`${process.env.REACT_APP_API_ENDPOINT}/file/cms/category_images/${defaultImage}`} alt="Change Management Library Background"/>
                  <Overlay />
                  <LibraryTitle>Change Management Library</LibraryTitle>
                </LibraryImageWrapper>
                <ContentAreaWrapper>
                  {!hasLibraryLicense ? (
                    <ChangeManagementLicense userType={userType} organizationalSlug={currentSlug} isPaidAccount={isPaidAccount} />
                  ) : (
                    <Row gutter={[16, 16]} style={{marginRight: "0px", marginLeft: 0}}>
                      <Col span={24}>
                        <ContentArea setFeaturedImage={setFeaturedImage} />
                      </Col>
                    </Row>
                  )}
                  
                </ContentAreaWrapper>
              </LibraryContainer>
            </Col>
          </Row>
        )
      )}
    </Main>
  );
}

export default LibrarySection;
