import React, {useState} from 'react';
import { Button, Col, Form, Row, Typography } from 'antd';

const {Title, Text} = Typography;

import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

function TargetAudienceExcel(props) {
    const [form] = Form.useForm();

    const handleDownload = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Target Audience List');
        
        // Styling for header row
        const headerRow = worksheet.addRow(props.dataExcelDataColumn);
        headerRow.font = { bold: true, color: { argb: '000000' } };
        headerRow.alignment = { horizontal: 'center' };
        headerRow.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BDE3FF' } }; // Blue background color
        
        worksheet.getRow(1).height = 20;
        // Set column widths
        worksheet.columns.forEach(column => {
            column.width = 40;
        });

        // Generate Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileName = 'Target Audiences.xlsx';
        saveAs(blob, fileName);
    };

    return (
        <>
        <div className='title-input'>
            <Title level={2}>Import a spreadsheet</Title>
        </div>
        <div className='title-group-start' style={{textAlign: "center", paddingTop: "20px"}}>
            <Title level={3} style={{marginBottom: "0px"}}>Use our template or map your own spreadsheet.</Title>
            <div className='audienceInputForm' style={{paddingTop: "40px"}}>
                <Row style={{marginTop: '40px'}}>
                    <Col xxl={12} xl={12} lg={12} md={12}>
                    <Button
                        className="btn-create"
                        htmlType="submit"
                        type="default-big"
                        size="large"
                        onClick={async () => {
                            await props.createColumns();
                            handleDownload();
                            await props.createColumns();
                            props.setIsEditMode(true)
                            await props.setEditType('Import Excel Map');
                        }}
                    >
                        Download our template
                    </Button>
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12}>
                        <Button
                            className="btn-create"
                            htmlType="submit"
                            type="default-big"
                            size="large"
                            onClick={async () => {
                                await props.createColumns();
                                props.setIsEditMode(true)
                                await props.setEditType('Import Excel Map');
                            }}
                        >
                            Map your own spreadsheet
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
        </>
    )
}

export default TargetAudienceExcel;
