import { DataService } from "../config/dataService/dataService";
import Cookies from 'js-cookie';

const makeActive = async (item = {}, openSuccess = () => {}, openError = () => {}, callback = async () => {}) => {
    let currentSelectedProject = item.key;
    const data = {
        projectId: currentSelectedProject,
    };

    if (item.userId !== undefined)
    {
      data.userId = item.userId;
    }

    const response = await DataService.post("/users/setActiveProject/account_users", data);

    if (response.status === 200)
    {
      openSuccess("bottomRight", "Active Project Change", "Change Project successfully");
      Cookies.remove('access_token');
      Cookies.set('access_token', response.data.access_token);
      const projectInfo = {
        projectId: response.data.projectId,
        projectName: response.data.projectName
      }
      localStorage.setItem("activeProjectInfo", JSON.stringify(projectInfo));
      await callback(response);
    }
    else
    {
      openError('topLeft', 'Active Project Change', response.message);
    }
}

const convertFreeToTrial = async (openSuccess = () => {}, openError = () => {}) => {
  const response = await DataService.post("/auth/convert-to-trial/portal");
  if (response.status === 200) 
  {
    openSuccess('bottomRight', "Convert to Free user to Trial User", `Your Account is successfully converted to Trial Account`);
    setTimeout(() => {
      navigate(0);
    }, 100);
  }
  else
  {
    openError("topLeft", "Convert to Free user to Trial User", response.data.message);
  }
}

export {
  makeActive,
  convertFreeToTrial
};