import { useEffect, memo, useState } from 'react';
import BarChartData from '../charts/BarChartData';
import styled from 'styled-components';
import { StatBox } from '../../../components/statBox/statBox';
import HorizontalBarChart from '../../../components/charts/HorizontalBarChart';
import VerticalBarChart from '../../../components/charts/VerticalBarChart';
import useOverview from '../hooks/useOverview';
import ReportChart from '../components/ReportChart';
import NoResponse from '../components/NoResponse/NoResponse';
import { useReportsDispatch, useReportsState } from '../context/reports';
import { reducerTypes, reportDesciptions } from '../context/reports/constants';
import { api } from '../../../config/dataService/useApi';
import TimelineChart from '../../../components/charts/TimelineChart';
import { Checkbox } from 'antd';
import LoadingSpinner from '../../ui-elements/LoadingSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/pro-duotone-svg-icons';
import ScoreCalculationPopup from '../components/ScoreCalculationPopup';

function transformData(data) {
  const { audiences, projectsList } = data;

  // Create a map of project IDs to project names
  const projectMap = projectsList?.reduce((map, project) => {
    map[project.id] = project.projectName;
    return map;
  }, {});

  // Transform the audiences data
  const result = audiences?.map((audience) => {
    return {
      x: projectMap[audience.projectId],
      y: audience.count,
    };
  });

  return result;
}

export const Overview = ({ pdfCheckBoxData }) => {
  const dispatch = useReportsDispatch();
  let isAmazon = localStorage.getItem('is_amazon') === 'true' ? true : false;
  const {
    filter: { projects: selectedProject },
    reportsDescriptions,
  } = useReportsState();
  const [isOpen, setIsOpen] = useState(false);
  const { overview } = reportsDescriptions;
  const {
    noOfProjects,
    changeProjects,
    changeImpactProjects,
    projectRediness,
    adoptionRisk,
    stakeholderSupportByProject,
    stats,
    adoptionBooleanMetrics,
    adoptionPercentageMetrics,
    noOfIndividualsPerProject,
    getListNoOfIndividualsPerProject,
    exportListAudiencesPerProject,
    projectTimelineData,
  } = useOverview();

  useEffect(() => {
    (async () => {
      const response = await api('/reports/info/overview', 'get');
      dispatch({
        type: reducerTypes.REPORTS_DESCRIPTIONS,
        payload: { ...reportsDescriptions, overview: response.data.reportInfos },
      });
    })();
  }, []);

  const getReportInfo = (reportKey) => {
    return overview?.find((description) => description?.reportKey === reportKey);
  };

  const ChangesInProjectChart = memo(({ changeProjectsData, width }) => {
    const noResponseMessage = 'To view this report, please add data to your # of changes';
    const reportId = '#_of_Changes_per_Project_by_Severity';
    return changeProjectsData?.data?.tabledata?.length !== 0 ? (
      <Warpper flexDirection={'column'} justifyContent width={width} gap={'0px'}>
        <ReportChart
          id={reportId}
          title={'# of Changes per Project by Severity'}
          width={'100%'}
          data={changeProjectsData.data.tabledata}
          description={getReportInfo(reportId)?.description}
          defaultDescription={reportDesciptions.no_of_Changes_per_Project_by_Serverity}
          reportSection={'overview'}
          reportInfoId={getReportInfo(reportId)?.id}
        >
          <VerticalBarChart
            yAxisTitle="# of changes"
            data={changeProjectsData?.data}
            legends={['High', 'Mid', 'Low']}
            legendsColors={['#FF5D5F', '#FFC125', '#00B85C']}
            legendTitle={'Severity of Impact'}
          />
        </ReportChart>
      </Warpper>
    ) : (
      <NoResponse title={'Projects by # of Changes'} message={noResponseMessage} width={width} />
    );
  });
  const ProjectRedinessChart = memo(({ projectRedinessData, width }) => {
    const noResponseMessage = 'To view this report, please add data to your readiness tool';
    const reportId = 'Readiness_Levels_per_Project';
    return projectRedinessData?.data?.tabledata?.length !== 0 ? (
      <Warpper flexDirection={'column'} justifyContent width={width} gap={'0px'}>
        <ReportChart
          id={reportId}
          title={'Readiness Levels per Project'}
          width={'100%'}
          data={projectRedinessData?.data.tabledata}
          tableColumns={projectRedinessData?.data?.columns}
          description={getReportInfo(reportId)?.description}
          defaultDescription={reportDesciptions.Readiness_Levels_per_Project}
          reportSection={'overview'}
          reportInfoId={getReportInfo(reportId)?.id}
        >
          <HorizontalBarChart
            xAxisTitle="# of Assessed Audiences"
            data={projectRedinessData}
            legendTitle={'Readiness Level'}
          />
        </ReportChart>
      </Warpper>
    ) : (
      <NoResponse
        id="Readiness_Levels_per_Project"
        title={'Readiness Levels per Project'}
        message={noResponseMessage}
        width={width}
      />
    );
  });

  const StakeholderSupportByProjectChart = memo(({ stakeholderSupportByProjectData, width }) => {
    const noResponseMessage = 'To view this report, please add data to your stakeholder tool';
    const reportId = 'Stakeholder_Receptiveness_per_Project';
    return stakeholderSupportByProjectData?.data?.tableData?.length !== 0 ? (
      <Warpper flexDirection={'column'} justifyContent width={width} gap={'0px'}>
        <ReportChart
          id={reportId}
          title={'Stakeholder Receptiveness per Project'}
          width={'100%'}
          data={stakeholderSupportByProjectData?.data?.tableData}
          tableColumns={stakeholderSupportByProjectData?.data?.columns}
          description={getReportInfo(reportId)?.description}
          defaultDescription={reportDesciptions.Stakeholder_Receptiveness_per_Project}
          reportSection={'overview'}
          reportInfoId={getReportInfo(reportId)?.id}
        >
          <HorizontalBarChart
            xAxisTitle="# of Assessed Stakeholders"
            data={stakeholderSupportByProjectData?.data}
            legendTitle={'Receptiveness Level'}
          />
        </ReportChart>
      </Warpper>
    ) : (
      <NoResponse id={reportId} title={'Avg. Stakeholder Receptiveness by Project'} message={noResponseMessage} width={width} />
    );
  });

  const IndividualsPerProject = memo(({ individualData, width }) => {
    const noResponseMessage = 'To view this report, please add individuals to your Target Audiences';
    const reportId = '#_of_Individuals_per_Project';
    return individualData?.data?.audiences?.length !== 0 ? (
      <Warpper flexDirection={'column'} justifyContent width={width} gap={'0px'}>
        <ReportChart
          id={reportId}
          title={'# of Individuals per Project'}
          width={'100%'}
          description={getReportInfo(reportId)?.description}
          defaultDescription={reportDesciptions.no_of_Individuals_per_Project}
          reportSection={'overview'}
          reportInfoId={getReportInfo(reportId)?.id}
          getListData={getListNoOfIndividualsPerProject}
          exportListData={exportListAudiencesPerProject}
        >
          <BarChartData yAxisTitle="# of Individuals" data={transformData(individualData.data)} />
        </ReportChart>
      </Warpper>
    ) : (
      <NoResponse title={'# of Individuals per Project'} message={noResponseMessage} width={width} />
    );
  });

  const AdoptionPercentageMetricsChart = memo(({ adoptionPercentageMetrics }) => {
    return adoptionPercentageMetrics?.data.length
      ? adoptionPercentageMetrics?.data.map((item, index) => {
        const reportTitle = item?.title.replace(/\s+/g, '_');
        const reportId = `${reportTitle} - Percentage`
        pdfCheckBoxData.overview.push(reportId);

        return (
          <Warpper flexDirection={'column'} justifyContent width={'48%'} gap={'0px'}>
            <ReportChart
              id={reportId}
              reportInfoId={getReportInfo(reportId)?.id}
              description={getReportInfo(reportId)?.description}
              defaultDescription={item?.tableData?.[0]?.["Metric Description"]}
              reportSection={'overview'}
              width={'100%'}
              title={`${item.title} - Percentage`}
              data={item.tableData}
            >
              <HorizontalBarChart
                xAxisTitle="Average Percentage of Progress"
                data={{ data: item }}
                legends={null}
                legendsColors={['#0066B2', '#7AC6FF']}
                valuePercentage={true}
                xAxisStepSize={10}
              />
            </ReportChart>
          </Warpper>
        );
      })
      : null;
  });

  const AdoptionBooleanMetricsChart = memo(({ adoptionBooleanMetrics }) => {
    return adoptionBooleanMetrics?.data?.length !== 0
      ? adoptionBooleanMetrics?.data.map((item) => {
        const reportTitle = item?.title.replace(/\s+/g, '_');
        const reportId = `${reportTitle} - Yes/No`
        pdfCheckBoxData.overview.push(reportId);
        return (
          <Warpper flexDirection={'column'} justifyContent width={'48%'} gap={'0px'}>
            <ReportChart
              id={reportId}
              reportInfoId={getReportInfo(reportId)?.id}
              description={getReportInfo(reportId)?.description}
              defaultDescription={item?.tableData?.[0]?.["Metric Description"]}
              reportSection={'overview'}
              width={'100%'}
              title={`${item.title} - Yes/No`}
              data={item.tableData}
            >
              <HorizontalBarChart
                xAxisTitle="# of Assessed Stakeholders"
                data={{ data: item }}
                legends={['Yes', 'No']}
                legendsColors={['#0066B2', '#7AC6FF']}
              />
            </ReportChart>
          </Warpper>
        );
      })
      : null;
  });

  const Severity_Of_Stakeholder_Impact_by_Project = memo(({ changeImpactProjectsData, width }) => {
    const reportId = 'Severity_of_Stakeholder_Impact_by_Project';
    return changeImpactProjectsData?.data?.data?.length !== 0 ? (
      <ReportChart
        id={reportId}
        height="100%"
        title={'Severity of Stakeholder Impact by Project'}
        width={'100%'}
        data={changeImpactProjectsData?.data?.tabledata}
        description={getReportInfo(reportId)?.description}
        defaultDescription={reportDesciptions.severity_of_Stakeholder_Impact_by_Project}
        reportSection={'overview'}
        reportInfoId={getReportInfo(reportId)?.id}
      >
        <CustomBox>
          {changeImpactProjectsData?.data?.data?.map((data) => (
            <div className="box">
              <p
                style={{
                  fontFamily: 'Arial, sans-serif',
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '17.5px',
                  textAlign: 'center',
                  color: '#7F7F7F'
                }}>{data.data.title}</p>
              <VerticalBarChart
                yAxisTitle="# of changes"
                data={data}
                legends={['High', 'Mid', 'Low']}
                legendTitle="Severity of Impact"
              />
            </div>
          ))}
        </CustomBox>
      </ReportChart>
    ) : null;
  });

  const ProjectTimelineChart = memo(({ projectTimelineData }) => {    
    const noResponseMessage = 'To view this report, please add data to your communication, training or risk assessment tool';
    const [variantType, setVariantType] = useState({ weekly: false, monthly: true });
    const [filterType, setFilterType] = useState({ communications: false, training: false, projectRisk: false });
    const reportId = 'Project_Timelines';
    return projectTimelineData?.data?.tableData?.length !== 0 ?(
      <ReportChart
        id={reportId}
        height="100%"
        title={'Project Timelines'}
        width={'100%'}
        description={getReportInfo(reportId)?.description}
        defaultDescription={reportDesciptions.Project_Timelines}
        reportSection={'overview'}
        reportInfoId={getReportInfo(reportId)?.id}
        data={projectTimelineData?.data?.tableData}>
        <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', marginBottom: '24px' }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <StyledCheckbox
              name="communications"
              checked={filterType.communications}
              onChange={() => { setFilterType({ ...filterType, communications: !filterType.communications, }) }}
              style={{ marginRight: '8px' }}
            >
              Communications
            </StyledCheckbox>
            <StyledCheckbox
              name="training"
              checked={filterType.training}
              onChange={() => { setFilterType({ ...filterType, training: !filterType.training, }) }}
              style={{ marginRight: '8px' }}
            >
              Training
            </StyledCheckbox>
            <StyledCheckbox
              name="projectRisk"
              checked={filterType.projectRisk}
              onChange={() => { setFilterType({ ...filterType, projectRisk: !filterType.projectRisk }) }}
            >
              Project Risk
            </StyledCheckbox>
            {!projectTimelineData?.data?.amazonProject && <FontAwesomeIcon color="#7AC6FF" size="lg" style={{ cursor: 'pointer', marginRight: '8px' }} icon={faCircleQuestion} onClick={() => setIsOpen(true)} />}

          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderLeft: '2px solid #BFBFBF' }}>
            <span style={{ fontSize: '12px', fontWeight: 400, lineHeight: '14.52px', margin: '0px 16px' }}>View by:</span>
            <RadioButton selected={variantType.weekly} marginRigt={'4px'}
              onClick={() => setVariantType({ weekly: true, monthly: false })} />
            <span style={{ fontSize: '12px', fontWeight: 400, lineHeight: '14.52px', marginRight: '8px' }}>Week</span>
            <RadioButton selected={variantType.monthly} marginRigt={'4px'}
              onClick={() => setVariantType({ weekly: false, monthly: true })} />
            <span style={{ fontSize: '12px', fontWeight: 400, lineHeight: '14.52px', marginRight: '8px' }}>Month</span>
            {/* <RadioButton selected={variantType.yearly} marginRigt={'4px'}
              onClick={() => setVariantType({ weekly: false, monthly: false, yearly: true })} />
            <span style={{ fontSize: '12px', fontWeight: 400, lineHeight: '14.52px' }}>Year</span> */}
          </div>
        </div>
        {projectTimelineData?.loading ? <LoadingSpinner /> :
          <TimelineChart
            variant={variantType.monthly ? 'month' : variantType.weekly ? 'week' : 'year'}
            data={projectTimelineData.data}
            filterType={filterType}
          />
        }
      </ReportChart>
    ) : (
      <NoResponse title={'Project Timelines'} message={noResponseMessage} width={'100%'} />
    );
  });

  return (
    <div id="overview_id" style={{ paddingLeft: '1rem', flexWrap: 'wrap', paddingTop: '2rem', flexBasis: '94%' }}>
      <ReportChart
        data={stats.data?.tableData}
        chartBoxStyle={{ height: '130px', minHeight: '130px' }}
        background=""
        height="180px"
        showDescription={false}
        modelTitle={'Project Impacts Overview'}
      >
        <div
          id="Stats"
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            width: '100%',
            minHeight: '130px',
            height: '100%'
          }}
        >
          <div style={{ width: isAmazon ? '40%' : '50%', height: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
            {isAmazon && <StatBox
              color="#0066B2"
              loading={noOfProjects.loading}
              mainTitle={selectedProject?.length ? selectedProject?.length : noOfProjects.data}
              secondTitle={selectedProject?.length === 1 ? 'Project' : 'Projects'}
              width={'160px'}
            />}
            {isAmazon && <StatBox
              color="#38AAFF"
              loading={adoptionRisk.loading}
              mainTitle={adoptionRisk?.data == 'N/A' ? '--' : adoptionRisk?.data}
              secondTitle={'Adoption Risk Level'}
              width={'160px'}
            />}
            {!isAmazon && <StatBox
              color="#0066B2"
              loading={noOfProjects.loading}
              mainTitle={selectedProject?.length ? selectedProject?.length : noOfProjects.data}
              secondTitle={selectedProject?.length ? ( selectedProject?.length <= 1 ? 'Project' : 'Projects' ) : ( noOfProjects.data <= 1 ? 'Project' : 'Projects' )}
              width={'160px'}
            />}
            {!isAmazon && <StatBox
              color="#38AAFF"
              loading={adoptionRisk.loading}
              mainTitle={`${adoptionRisk?.data?.percentageOfOrganization}%`}
              secondTitle={'of Organizations Impacted'}
              width={'160px'}
            />}
            {!isAmazon && <StatBox
              color="#7AC6FF"
              loading={adoptionRisk.loading}
              mainTitle={adoptionRisk?.data?.impactingCustomers}
              secondTitle={'Projects Impacting Customers'}
              width={'160px'}
            />}
          </div>
          < div style={{ width: isAmazon ? '60%' : '50%', height: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
            <StatBox
              loading={stats.loading}
              color="#FF5D5F"
              mainTitle={stats.data?.stats?.high}
              secondTitle={`High ${isAmazon ? 'Effort' : 'Risk'} ${stats.data?.stats?.high <= 1 ? 'Project' : 'Projects'}`}
              width={'160px'}
            />
            <StatBox
              loading={stats.loading}
              color="#FFC125"
              mainTitle={stats.data?.stats?.mid}
              secondTitle={`Mid ${isAmazon ? 'Effort' : 'Risk'} ${stats.data?.stats?.mid <= 1 ? 'Project' : 'Projects'}`}
              width={'160px'}
              fontColor="#404040"
            />
            <StatBox
              loading={stats.loading}
              color="#00B85C"
              mainTitle={stats.data?.stats?.low}
              secondTitle={`Low ${isAmazon ? 'Effort' : 'Risk'} ${stats.data?.stats?.low <= 1 ? 'Project' : 'Projects'}`}
              width={'160px'}
            />
          </div>
        </div>
      </ReportChart>
      <Container>
        <ChangesInProjectChart changeProjectsData={changeProjects} width="48%" />
        <IndividualsPerProject individualData={noOfIndividualsPerProject} width="48%" />
        <Severity_Of_Stakeholder_Impact_by_Project changeImpactProjectsData={changeImpactProjects} width="48%" />
        <ProjectRedinessChart projectRedinessData={projectRediness} width="48%" />
        <StakeholderSupportByProjectChart stakeholderSupportByProjectData={stakeholderSupportByProject} width="48%" />
        <ProjectTimelineChart projectTimelineData={projectTimelineData} />
        <AdoptionPercentageMetricsChart adoptionPercentageMetrics={adoptionPercentageMetrics} />
        <AdoptionBooleanMetricsChart adoptionBooleanMetrics={adoptionBooleanMetrics} />
      </Container>
      <ScoreCalculationPopup open={isOpen} close={() => setIsOpen(false)} />

    </div>
  );
};

const Warpper = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${(p) => (p.width ? p.width : '100%')};
  gap: ${(p) => (p.gap ? p.gap : '60px')};
  box-sizing: border-box;
  flex-wrap: wrap !important;
  * {
    box-sizing: border-box;
  }
  @media screen and (min-width: 1024px) {
    flex-wrap: nowrap;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 60px;
`;

const CustomBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 60px;
  padding: 20px 0px;
  .box {
    width: 48%;
    height: 24rem;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    padding: 10px;
  }
  .p {
    font-size: 14px;
    font-weight: 400;
    line-height: 17.5px;
    color: #7f7f7f;
    text-align: center;
  }
`;

const RadioButton = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: #bfbfbf;
  background-color: ${(p) => (p.selected ? '#38AAFF' : '#EEEEF3')};
  margin-right: ${(p) => (p.marginRigt ? p.marginRigt : '10px')};
`;

const StyledCheckbox = styled(Checkbox)`
  font-size: 12px;
  font-weight: 400;
  line-height: 14.5px;
  .ant-checkbox-inner {
    width: 15px;
    height: 15px;
  }
  .ant-checkbox-input:checked + .ant-checkbox-inner {
    background-color: #38aaff;
    border-color: #38aaff;
  }
  .ant-checkbox-inner::after {
    left: 2px;
    top: 6px;
    width: 5px;
    height: 10px;
  }
`;
