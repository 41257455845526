import Cookies from 'js-cookie';
import actions from './actions';

const { 
  LOGIN_BEGIN, 
  LOGIN_SUCCESS, 
  LOGIN_ERR, 
  
  VERIFY_USER_BEGIN, 
  VERIFY_USER_SUCCESS, 
  VERIFY_USER_ERR,
  
  VALIDATE_PRICING_BEGIN, 
  VALIDATE_PRICING_SUCCESS, 
  VALIDATE_PRICING_ERR,
  
  CREATE_CUSTOMER_BEGIN, 
  CREATE_CUSTOMER_SUCCESS, 
  CREATE_CUSTOMER_ERR,
  
  UPDATE_CUSTOMER_BEGIN, 
  UPDATE_CUSTOMER_SUCCESS, 
  UPDATE_CUSTOMER_ERR,
  
  UPDATE_CUSTOMER_SUBSCRIPTION_BEGIN, 
  UPDATE_CUSTOMER_SUBSCRIPTION_SUCCESS, 
  UPDATE_CUSTOMER_SUBSCRIPTION_ERR,
  
  GET_CUSTOMER_PAYMENT_METHOD_BEGIN, 
  GET_CUSTOMER_PAYMENT_METHOD_SUCCESS, 
  GET_CUSTOMER_PAYMENT_METHOD_ERR,
  
  ADD_CUSTOMER_PAYMENT_METHOD_BEGIN, 
  ADD_CUSTOMER_PAYMENT_METHOD_SUCCESS, 
  ADD_CUSTOMER_PAYMENT_METHOD_ERR,
  
  SETUP_CUSTOMER_PAYMENT_BEGIN, 
  SETUP_CUSTOMER_PAYMENT_SUCCESS, 
  SETUP_CUSTOMER_PAYMENT_ERR,
  
  RE_SETUP_CUSTOMER_PAYMENT_BEGIN, 
  RE_SETUP_CUSTOMER_PAYMENT_SUCCESS, 
  RE_SETUP_CUSTOMER_PAYMENT_ERR,
  
  SET_CARD_AS_PRIMARY_BEGIN, 
  SET_CARD_AS_PRIMARY_SUCCESS, 
  SET_CARD_AS_PRIMARY_ERR,
  
  DELETE_CUSTOMER_CARD_BEGIN, 
  DELETE_CUSTOMER_CARD_SUCCESS, 
  DELETE_CUSTOMER_CARD_ERR,
  
  CANCEL_CUSTOMER_SUBSCRIPTION_BEGIN, 
  CANCEL_CUSTOMER_SUBSCRIPTION_SUCCESS, 
  CANCEL_CUSTOMER_SUBSCRIPTION_ERR,
  
  CHANGE_SUBSCRIPTION_BILLING_CYCLE_BEGIN, 
  CHANGE_SUBSCRIPTION_BILLING_CYCLE_SUCCESS, 
  CHANGE_SUBSCRIPTION_BILLING_CYCLE_ERR,
  
  PAUSE_CUSTOMER_SUBSCRIPTION_BEGIN, 
  PAUSE_CUSTOMER_SUBSCRIPTION_SUCCESS, 
  PAUSE_CUSTOMER_SUBSCRIPTION_ERR,
  
  STOP_SUBSCRIPTION_CANCELLATION_BEGIN, 
  STOP_SUBSCRIPTION_CANCELLATION_SUCCESS, 
  STOP_SUBSCRIPTION_CANCELLATION_ERR,
  
  RESUME_CUSTOMER_SUBSCRIPTION_BEGIN, 
  RESUME_CUSTOMER_SUBSCRIPTION_SUCCESS, 
  RESUME_CUSTOMER_SUBSCRIPTION_ERR,
  
  DELETE_ACCOUNT_SUBSCRIPTION_BEGIN, 
  DELETE_ACCOUNT_SUBSCRIPTION_SUCCESS, 
  DELETE_ACCOUNT_SUBSCRIPTION_ERR,
  
  RESTART_CUSTOMER_SUBSCRIPTION_AGAIN_BEGIN, 
  RESTART_CUSTOMER_SUBSCRIPTION_AGAIN_SUCCESS, 
  RESTART_CUSTOMER_SUBSCRIPTION_AGAIN_ERR,
  
  CANCEL_DELETION_OF_CUSTOMER_ACCOUNT_BEGIN, 
  CANCEL_DELETION_OF_CUSTOMER_ACCOUNT_SUCCESS, 
  CANCEL_DELETION_OF_CUSTOMER_ACCOUNT_ERR,
  
  CONVERT_TO_TRIAL_BEGIN, 
  CONVERT_TO_TRIAL_SUCCESS, 
  CONVERT_TO_TRIAL_ERR,
  
  LOGOUT_BEGIN, 
  LOGOUT_SUCCESS, 
  LOGOUT_ERR 
} = actions;

const initState = {
  login: Cookies.get('logedIn'),
  loading: false,
  error: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const AuthReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case LOGIN_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VERIFY_USER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VERIFY_USER_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case VERIFY_USER_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VALIDATE_PRICING_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VALIDATE_PRICING_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case VALIDATE_PRICING_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case CREATE_CUSTOMER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case CREATE_CUSTOMER_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_CUSTOMER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case UPDATE_CUSTOMER_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_CUSTOMER_SUBSCRIPTION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_CUSTOMER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case UPDATE_CUSTOMER_SUBSCRIPTION_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_CUSTOMER_PAYMENT_METHOD_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_CUSTOMER_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case GET_CUSTOMER_PAYMENT_METHOD_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_CUSTOMER_PAYMENT_METHOD_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_CUSTOMER_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case ADD_CUSTOMER_PAYMENT_METHOD_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case SETUP_CUSTOMER_PAYMENT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case SETUP_CUSTOMER_PAYMENT_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case SETUP_CUSTOMER_PAYMENT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case RE_SETUP_CUSTOMER_PAYMENT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case RE_SETUP_CUSTOMER_PAYMENT_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case RE_SETUP_CUSTOMER_PAYMENT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case SET_CARD_AS_PRIMARY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case SET_CARD_AS_PRIMARY_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case SET_CARD_AS_PRIMARY_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_CUSTOMER_CARD_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_CUSTOMER_CARD_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case DELETE_CUSTOMER_CARD_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case CANCEL_CUSTOMER_SUBSCRIPTION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CANCEL_CUSTOMER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case CANCEL_CUSTOMER_SUBSCRIPTION_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case CHANGE_SUBSCRIPTION_BILLING_CYCLE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CHANGE_SUBSCRIPTION_BILLING_CYCLE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case CHANGE_SUBSCRIPTION_BILLING_CYCLE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case PAUSE_CUSTOMER_SUBSCRIPTION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case PAUSE_CUSTOMER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case PAUSE_CUSTOMER_SUBSCRIPTION_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case STOP_SUBSCRIPTION_CANCELLATION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case STOP_SUBSCRIPTION_CANCELLATION_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case STOP_SUBSCRIPTION_CANCELLATION_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case RESUME_CUSTOMER_SUBSCRIPTION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case RESUME_CUSTOMER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case RESUME_CUSTOMER_SUBSCRIPTION_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_ACCOUNT_SUBSCRIPTION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ACCOUNT_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case DELETE_ACCOUNT_SUBSCRIPTION_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case RESTART_CUSTOMER_SUBSCRIPTION_AGAIN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case RESTART_CUSTOMER_SUBSCRIPTION_AGAIN_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case RESTART_CUSTOMER_SUBSCRIPTION_AGAIN_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case CANCEL_DELETION_OF_CUSTOMER_ACCOUNT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CANCEL_DELETION_OF_CUSTOMER_ACCOUNT_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case CANCEL_DELETION_OF_CUSTOMER_ACCOUNT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case CONVERT_TO_TRIAL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CONVERT_TO_TRIAL_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case CONVERT_TO_TRIAL_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case LOGOUT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case LOGOUT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};


export default AuthReducer;