import axios from 'axios';
import { getItem } from '../../utility/localStorageControl';
import Cookies from 'js-cookie';

const API_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}/`;

const authHeader = () => ({
  Authorization: `Bearer ${localStorage.getItem('access_token')}`,
});

const authResetHeader = () => ({
  Authorization: `Bearer ${getItem('reset_password_token')}`,
});


const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    'Content-Type': 'application/json',
  },
  //adapter: cache.adapter, // Use the cache adapter
});

class DataService {
  static get(path = '') {
    return client({
      method: 'GET',
      url: path,
      headers: { ...authHeader() },
    }).then((response) => {
      return response;
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  static post(path = '', data = {}, optionalHeader = {}) {
    return client({
      method: 'POST',
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader },
    }).then(async (response) => {
      return response;
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  static postResetPassword(path = '', data = {}, optionalHeader = {}) {
    return client({
      method: 'POST',
      url: path,
      data,
      headers: { ...authResetHeader(), ...optionalHeader },
    });
  }

  static patch(path = '', data = {}) {
    return client({
      method: 'PATCH',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static put(path = '', data = {}) {
    return client({
      method: 'PUT',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static delete(path = '') {
    return client({
      method: 'DELETE',
      url: path,
      headers: { ...authHeader() },
    });
  }

  static getBlob(path = '') {
    return client({
      url: path,
      method: 'GET',
      responseType: 'blob',
      headers: { ...authHeader() },
    }).then(response =>
      window.URL.createObjectURL(
        new Blob([response.data])
      )
    )
  }

  static postBlob(path = '', data) {
    return client({
      url: path,
      method: 'POST',
      data,
      responseType: 'blob',
      headers: { ...authHeader() },
    }).then(response =>
      window.URL.createObjectURL(
        new Blob([response.data])
      )
    )
  }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use((config) => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  const requestConfig = config;
  const { headers } = config;
  requestConfig.headers = { ...headers, Authorization: `Bearer ${localStorage.getItem('access_token')}` };

  return requestConfig;
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    const { response } = error;
    error.config.status = response.status;
    error.config.data = response.data;
    const originalRequest = error.config;
    if (response) {
      if (response.status === 500) {
        // do something here
        return error;
      }
      else if (response.status === 400 && response.data.code === 403) {
        // do something here
        //Remove ALL Cookies
        localStorage.clear();
        Cookies.remove('logedIn');
        window.location.href = '/';
        //navigate(`/`)
      }
      else if (response.status === 403) {
        // do something here
        //Remove ALL Cookies
        localStorage.clear();
        Cookies.remove('logedIn');
        window.location.href = '/';
        //navigate(`/`)
      }
      return originalRequest;
    }
    return Promise.reject(error);
  },
);
export { DataService };