import { memo, useState } from 'react';
import BarChartData from '../charts/BarChartData';
import { StatBox } from '../../../components/statBox/statBox';
import HorizontalBarChart from '../../../components/charts/HorizontalBarChart';
import { SmallRowBox } from '../../../components/rowBox/smallRowBox';
import RangeBarChart from '../../../components/charts/RangeBarChart';
import PieChart from '../../../components/charts/PieChart';
import LineChartStacked from '../../../components/charts/LineChartStacked';
import ReactSpeedometer from 'react-d3-speedometer';
import useMetricsData from '../hooks/useMetricsData';
import ReportChart from '../components/ReportChart';
import NoResponse from '../components/NoResponse/NoResponse';
import ScoreCalculationPopup from '../components/ScoreCalculationPopup';
import styled from 'styled-components'
import { useReportsState } from '../context/reports';

export const Metrics = () => {
  const { filter: { projects: selectedProject } } = useReportsState();
  return (
    <div id="metrices_id" style={{ paddingLeft: '2rem', flexWrap: 'wrap', paddingTop: '1rem', flexBasis: '94%' }}>
      {selectedProject.length == 1 ? <SingleProeject /> : <MultiProject />}
    </div>
  );
};

const colorsArray = [
  '#00CD66',
  '#FEC000',
  '#FF616E', // 0:Green 1:Yellow 2:Red
];

const getColors = (data, colorMapping) => {
  return data.map((value) => {
    return colorsArray[colorMapping[value]];
  });
};



const ImapactAudienceByNoOfChangesChart = memo(({ impactAudienceChanges, width }) => {
  const noResponseMessage = 'To view this report, please add data to your Impacted Audience';
  return impactAudienceChanges?.data?.impactAudience?.length !== 0 ? (
    <Warpper flexDirection={'column'} justifyContent width={width} gap={'0px'}>
      <ReportChart
        id="Impacted_Audience_by_#_of_Changes_(Change_Saturation)"
        title={'Impacted Audience by # of Changes (Change Saturation)'}
        width={'100%'}
        data={impactAudienceChanges.data?.tabledata}
        showDescription={false}
      >
        <BarChartData yAxisTitle="# of changes" data={impactAudienceChanges?.data?.impactAudience} />
      </ReportChart>
    </Warpper >
  ) : (
    <NoResponse
      title={'Impacted Audience by # of Changes (Change Saturation)'}
      message={noResponseMessage}
      width={width}
    />
  );
});

const ReadinessLevelPerAudienceChart = memo(({ readinessLevelPerAudience }) => {
  const noResponseMessage = 'To view this report, please add data to your Readiness';
  return readinessLevelPerAudience.data.labels?.length !== 0 ? (
    <Warpper flexDirection={'column'} justifyContent width={'48%'} gap={'0px'}>
      <ReportChart
        id="Readiness_Level_per_Audience"
        title={'Readiness Level per Audience'} width={'100%'} data={readinessLevelPerAudience.data.tabledata}
        showDescription={false}
      >
        < HorizontalBarChart
          xAxisTitle="# of People Impacted"
          data={{ data: readinessLevelPerAudience.data }}
          legendTitle={'Readiness Level'} />
      </ReportChart>
    </Warpper >
  ) : (
    <NoResponse id="Readiness_Level_per_Audience" title={'Readiness Level per Audience'} message={noResponseMessage} width="48%" />
  );
});

const TrainingTimelineChart = memo(({ trainingTimeline }) => {
  const selectedStyle = {
    cursor: 'pointer',
    fontWeight: 700,
    fontSize: '1rem',
    color: '#76DCFA',
    underlined: true,
  };
  const normalStyle = {
    cursor: 'pointer',
    fontWeight: 600,
    fontSize: '1rem',
    color: '#CBDFFF',
  };
  const [weeklyOrMonthly, setWeeklyOrMonthly] = useState({ weekly: true, monthly: false });
  const noResponseMessage = 'To view this report, please add data to your Training';
  return trainingTimeline?.trainingTimelineData?.labels?.length !== 0 ? (
    <Warpper flexDirection={'column'} justifyContent width={'66%'}>
      <ReportChart
        id="Training_Timeline"
        title="Training Timeline" data={trainingTimeline?.tabledata || []}
        showDescription={false}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px',
            marginRight: '10px',
            position: 'absolute',
            top: '17px',
            right: '17px',
          }}
        >
          <span
            onClick={() => setWeeklyOrMonthly({ weekly: true, monthly: false })}
            style={weeklyOrMonthly.weekly ? selectedStyle : normalStyle}
          >
            Weekly
          </span>
          <span
            onClick={() => setWeeklyOrMonthly({ weekly: false, monthly: true })}
            style={weeklyOrMonthly.monthly ? selectedStyle : normalStyle}
          >
            Monthly
          </span>
        </div>
        <RangeBarChart
          variant={weeklyOrMonthly.monthly ? 'month' : 'week'}
          data={trainingTimeline?.trainingTimelineData || {}}
        />
      </ReportChart>
    </Warpper >
  ) : (
    <NoResponse ReportChart width="66%" title="Training Timeline" message={noResponseMessage} />
  );
});

const NoOfCommunicationByTypeChart = memo(({ noOfCommunicationByType, width }) => {
  const noResponseMessage = 'To view this report, please add data to your # of Communication';
  return noOfCommunicationByType.data?.chartData?.length !== 0 ? (
    <Warpper flexDirection={'column'} justifyContent width={width} gap={'0px'}>
      <ReportChart
        id="#_of_Communications_by_Type"
        title="# of Communications by Type"
        data={noOfCommunicationByType.data.tableData} width={'100%'} showDescription={false}
      >
        {!noOfCommunicationByType.loading ? (
          <BarChartData yAxisTitle="# of communications" data={noOfCommunicationByType.data.chartData} />
        ) : (
          <></>
        )}
      </ReportChart>
    </Warpper >
  ) : (
    <NoResponse width={width} title="# of Communications by Type" message={noResponseMessage} />
  );
});

const OverallReadinessLevelChart = memo(({ overallReadinessLevel, width }) => {
  const noResponseMessage = 'To view this report, please add data to your Readiness';
  return overallReadinessLevel.data.labels?.length !== 0 ? (
    <Warpper flexDirection={'column'} justifyContent width={width} gap={'0px'}>
      <ReportChart
        id="Overall_Rediness_Level"
        title="Overall Readiness Level"
        width={'100%'} data={overallReadinessLevel.data.tabledata} showDescription={false}
      >
        <LineChartStacked yAxisTitle="# of Individuals" data={overallReadinessLevel.data} />
      </ReportChart>
    </Warpper >
  ) : (
    <NoResponse width={width} title="Overall Readiness Level" message={noResponseMessage} />
  );
});

const TrainingCompletion = memo(({ trainingCompletion, width }) => {
  const noResponseMessage = 'To view this report, please add data to your Training';

  return trainingCompletion.data.tabledata?.length !== 0 ? (
    <Warpper flexDirection={'column'} justifyContent width={width} gap={'0px'}>
      <ReportChart
        id="Training_Completion"
        title="Training Completion"
        width={'100%'} data={trainingCompletion?.data?.tabledata} showDescription={false}
      >
        <PieChart data={trainingCompletion?.data} backgroundColors={['#FF626E', '#00CC66', '#FFC000']} />
      </ReportChart>
    </Warpper >
  ) : (
    <NoResponse id="Training_Completion" width={width} title="Training Completion" message={noResponseMessage} />
  );
});

const AdoptionScorePerReadinessChart = memo(({ adoptionScorePerRediness, width }) => {
  const noResponseMessage = 'To view this report, please add data to your Adoption Score';

  return adoptionScorePerRediness.data.labels?.length !== 0 ? (
    <Warpper flexDirection={'column'} justifyContent width={width} gap={'0px'}>
      <ReportChart
        showDescription={false}
        id="Adoption_Score_per_Audience" width={'100%'} title="Adoption Score per Audience" data={adoptionScorePerRediness.data.tableData}>
        {!adoptionScorePerRediness.loading ? (
          <HorizontalBarChart data={{ data: adoptionScorePerRediness?.data }} />
        ) : (
          <></>
        )}
      </ReportChart>
    </Warpper >
  ) : (
    <NoResponse width={width} title="Adoption Score per Audience" message={noResponseMessage} />
  );
});

const SponsorEnthusiasmChart = memo(({ sponsorEnthusiasm, width }) => {
  const noResponseMessage = 'To view this report, please add data to your Sponsor Entusiasm';
  return sponsorEnthusiasm?.data.labels?.length !== 0 ? (
    <Warpper flexDirection={'column'} justifyContent width={width} gap={'0px'}>
      <ReportChart showDescription={false}
        id="Sponsor_Role_by_Level_of_Enthusiasm" width={'100%'} title="Sponsor Role by Level of Enthusiasm" data={sponsorEnthusiasm?.data.tableData} tableColumns={sponsorEnthusiasm?.data.columns}>
        {!sponsorEnthusiasm.loading ? (
          <>
            <HorizontalBarChart xAxisTitle="# of Sponsors" data={{ data: sponsorEnthusiasm?.data }} legendTitle={'Sponsor Role Level'} />
          </>
        ) : (
          <></>
        )}
      </ReportChart>
    </Warpper >
  ) : (
    <NoResponse id="Sponsor_Role_by_Level_of_Enthusiasm" width={width} title="Sponsor Role by Level of Enthusiasm" message={noResponseMessage} />
  );
});

const CoachingEffortsNeededChart = memo(({ coachingEffortsNeeded, width }) => {
  const noResponseMessage = 'To view this report, please add data to your Coaching Tool.';
  return coachingEffortsNeeded?.data.labels?.length !== 0 ? (
    <Warpper flexDirection={'column'} justifyContent width={width} gap={'0px'}>
      <ReportChart
        showDescription={false}
        id="Level_of_Coaching_Effort_Needed" width={'100%'} title="Level of Coaching Effort Needed" data={coachingEffortsNeeded.data.tabledata}>
        {!coachingEffortsNeeded.loading ? (
          <>
            <PieChart
              data={coachingEffortsNeeded?.data}
              backgroundColors={getColors(coachingEffortsNeeded?.data.labels, { High: 2, Mid: 1, Low: 0 })}
            />
          </>
        ) : (
          <></>
        )}
      </ReportChart>
    </Warpper>
  ) : (
    <NoResponse id="Level_of_Coaching_Effort_Needed" width={width} title="Level of Coaching Effort Needed" message={noResponseMessage} />
  );
});

const SingleProeject = () => {
  const {
    sponsorEnthusiasm,
    adoptionRisk,
    stats,
    impactAudienceChanges,
    stakeholderReceptiveness,
    trainingTimeline,
    trainingCompletion,
    noOfCommunicationByType,
    readinessLevelPerAudience,
    adoptionScorePerRediness,
    overallReadinessLevel,
    coachingEffortsNeeded,
  } = useMetricsData();

  const [isOpen, setIsOpen] = useState(false);
  const isAmazon = localStorage.getItem('is_amazon') === 'true';

  return (
    <Container>
      <ScoreCalculationPopup open={isOpen} close={() => setIsOpen(false)} />
      <div style={{marginRight: 'auto' , width: '48%'}}>
        <ReportChart showDescription={false}
          id="Level_of_Project_Risk/Effort"
          title="Level of Project Risk/Effort"
          width="100%"
          data={isAmazon ? [] : adoptionRisk?.data?.tabledata || []}
          hideDetailedPopup
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              flexDirection: 'column',
              position: 'relative',
            }}
          >
            <ReactSpeedometer
              width={500}
              needleHeightRatio={0.7}
              value={adoptionRisk?.data?.riskLevel}
              customSegmentStops={[0, 250, 750, 1000]}
              segmentColors={['#00CC66', '#FFC000', '#FF626E']}
              currentValueText=" "
              customSegmentLabels={[
                {
                  text: 'Low',
                  position: 'INSIDE',
                  color: '#d8dee9',
                },
                {
                  text: 'Mid',
                  position: 'INSIDE',
                  color: '#d8dee9',
                },
                {
                  text: 'High',
                  position: 'INSIDE',
                  color: '#d8dee9',
                },
              ]}
              ringWidth={70}
              needleTransitionDuration={3333}
              needleTransition="easeElastic"
              needleColor={'black'}
              textColor={'black'}
            />
            {isAmazon ?
              <p style={{ position: 'absolute', bottom: 0, fontSize: '20px', fontWeight: 'bold' }}>Risk/Effort Level</p> :
              <p
                onClick={() => setIsOpen(true)}
                style={{ position: 'absolute', bottom: 0, fontSize: '20px', fontWeight: 'bold', cursor: 'pointer' }}
              >
                Score Calculation
              </p>}

          </div>
        </ReportChart>
      </div>

      <StatBoxContainer id="Stats"  >
        <StatBox
          d_id="Impacted_Groups"
          loading={stats.loading}
          color="#0066B2"
          mainTitle={stats.data.impactedGroups}
          secondTitle={'Impacted Groups'}
          width={'28%'}
        />
        <StatBox
          d_id="Impacted_Individuals"
          loading={stats.loading}
          color="#38AAFF"
          mainTitle={stats.data.impactIndividuals}
          secondTitle={'Impacted Individuals'}
          width={'28%'}
        />
        <StatBox
          d_id="#_of_Change_Champions"
          loading={stats.loading}
          color="#7AC6FF"
          mainTitle={stats.data.changeChampions}
          secondTitle={'# of Change Champions'}
          width={'28%'}
        />

        <StatBox
          d_id="High_Impact_Changes"
          color="#FF5D5F"
          loading={stats.loading}
          mainTitle={stats.data.highImpactChanges}
          secondTitle={'High Impact Changes'}
          width={'28%'}
        />
        <StatBox
          d_id="Mid_Impact_Changes"
          loading={stats.loading}
          color="#FFC125"
          mainTitle={stats.data.midImpactChanges}
          secondTitle={'Mid Impact Changes'}
          width={'28%'}
        />
        <StatBox
          d_id="Low_Impact_Changes"
          loading={stats.loading}
          color="#00B85C"
          mainTitle={stats.data.lowImpactChanges}
          secondTitle={'Low Impact Changes'}
          width={'28%'}
        />
      </StatBoxContainer>

      <ImapactAudienceByNoOfChangesChart impactAudienceChanges={impactAudienceChanges} width="48%" />
      <ReadinessLevelPerAudienceChart readinessLevelPerAudience={readinessLevelPerAudience} />

      <Warpper flexDirection={'column'} justifyContent width={'30%'} gap={'0px'}>
        <ReportChart
          id="Stakeholder_Receptiveness" title="Stakeholder Receptiveness" data={stakeholderReceptiveness.data?.tabledata} showDescription={false}>
          <SmallRowBox
            d_id="High"
            iconColor="#99EBC2"
            mainTitle={stakeholderReceptiveness.data?.stakeholderReceptiveness?.High ?? 0}
            secondTitle={'High'}
            color="#00CC66"
          ></SmallRowBox>
          <SmallRowBox
            d_id="Mid"
            iconColor="#FFE699"
            mainTitle={stakeholderReceptiveness.data?.stakeholderReceptiveness?.Mid ?? 0}
            secondTitle={'Mid'}
            color="#FFC000"
          ></SmallRowBox>
          <SmallRowBox
            d_id="Low"
            iconColor="#FF626E"
            mainTitle={stakeholderReceptiveness.data?.stakeholderReceptiveness?.Low ?? 0}
            secondTitle={'Low'}
            color="#FF626E"
          ></SmallRowBox>
        </ReportChart>
      </Warpper>

      <TrainingTimelineChart trainingTimeline={trainingTimeline?.data} />


      <OverallReadinessLevelChart overallReadinessLevel={overallReadinessLevel} width="48%" />
      <TrainingCompletion trainingCompletion={trainingCompletion} width="48%" />

      <NoOfCommunicationByTypeChart noOfCommunicationByType={noOfCommunicationByType} width="48%" />
      <AdoptionScorePerReadinessChart adoptionScorePerRediness={adoptionScorePerRediness} width="48%" />

      <SponsorEnthusiasmChart width="48%" sponsorEnthusiasm={sponsorEnthusiasm} />
      <CoachingEffortsNeededChart width="48%" coachingEffortsNeeded={coachingEffortsNeeded} />
    </Container>
  );
};

const MultiProject = () => {
  const {
    sponsorEnthusiasm,
    stats,
    impactAudienceChanges,
    stakeholderReceptiveness,
    trainingTimeline,
    trainingCompletion,
    noOfCommunicationByType,
    coachingEffortsNeeded,
  } = useMetricsData();

  const defaultMessage = 'This report can only be viewed for one project at a time. To view, filter by one project.';

  return (
    <Container id='metrices_id'>
      <ImapactAudienceByNoOfChangesChart impactAudienceChanges={impactAudienceChanges} width="48%" />

      <StatBoxContainer id="Stats"  >
        <StatBox
          d_id="Impacted_Groups"
          loading={stats.loading}
          color="#0066B2"
          mainTitle={stats.data.impactedGroups}
          secondTitle={'Impacted Groups'}
          width={'30%'}
        />
        <StatBox
          d_id="Impacted_Individuals"
          loading={stats.loading}
          color="#38AAFF"
          mainTitle={stats.data.impactIndividuals}
          secondTitle={'Impacted Individuals'}
          width={'30%'}
        />
        <StatBox
          d_id="#_of_Change_Champions"
          loading={stats.loading}
          color="#7AC6FF"
          mainTitle={stats.data.changeChampions}
          secondTitle={'# of Change Champions'}
          width={'30%'}
        />
        <StatBox
          d_id="High_Impact_Changes"
          color="#FF5D5F"
          loading={stats.loading}
          mainTitle={stats.data.highImpactChanges}
          secondTitle={'High Impact Changes'}
          width={'30%'}
        />
        <StatBox
          d_id="Mid_Impact_Changes"
          loading={stats.loading}
          color="#FFC125"
          mainTitle={stats.data.midImpactChanges}
          secondTitle={'Mid Impact Changes'}
          width={'30%'}
        />
        <StatBox
          d_id="Low_Impact_Changes"
          loading={stats.loading}
          color="#00B85C"
          mainTitle={stats.data.lowImpactChanges}
          secondTitle={'Low Impact Changes'}
          width={'30%'}
        />
      </StatBoxContainer>

      <Warpper flexDirection={'column'} justifyContent width={'30%'} gap={'0px'}>
        <ReportChart showDescription={false} id="Stakeholder_Receptiveness" title="Stakeholder Receptiveness" data={stakeholderReceptiveness.data?.tabledata} tableColumns={stakeholderReceptiveness.data.columns}>
          <SmallRowBox
            d_id="High"
            iconColor="#99EBC2"
            mainTitle={stakeholderReceptiveness.data?.stakeholderReceptiveness?.High ?? 0}
            secondTitle={'High'}
            color="#00CC66"
          ></SmallRowBox>
          <SmallRowBox
            d_id="Mid"
            iconColor="#FFE699"
            mainTitle={stakeholderReceptiveness.data?.stakeholderReceptiveness?.Mid ?? 0}
            secondTitle={'Mid'}
            color="#FFC000"
          ></SmallRowBox>
          <SmallRowBox
            d_id="Low"
            iconColor="#FF626E"
            mainTitle={stakeholderReceptiveness.data?.stakeholderReceptiveness?.Low ?? 0}
            secondTitle={'Low'}
            color="#FF626E"
          ></SmallRowBox>
        </ReportChart>
      </Warpper>
      <TrainingTimelineChart trainingTimeline={trainingTimeline?.data} />


      <NoOfCommunicationByTypeChart noOfCommunicationByType={noOfCommunicationByType} width="48%" />
      <TrainingCompletion trainingCompletion={trainingCompletion} width="48%" />

      <SponsorEnthusiasmChart sponsorEnthusiasm={sponsorEnthusiasm} width="48%" />
      <CoachingEffortsNeededChart coachingEffortsNeeded={coachingEffortsNeeded} width="48%" />

      <NoResponse
        d_id="Level_of_Project_Risk/Effort"
        height="22rem"
        width="48%"
        title="Level of Project Risk/Effort"
        message={defaultMessage}
      />
      <NoResponse
        d_id="Readiness_Level_per_Audience"
        height="22rem"
        width="48%"
        title="Readiness Level per Audience"
        message={defaultMessage}
      />

      <NoResponse d_id="Overall_Rediness_Level" height="22rem" width="48%" title="Overall Rediness Level" message={defaultMessage}
      />
      <NoResponse
        d_id="Adoption_Score_per_Audience"
        height="22rem"
        width="48%"
        title="Adoption Score per Audience"
        message={defaultMessage}

      />
    </Container>
  );
};

const Container = styled.div`
      display: flex;
    justify-content: space-between;
    width:  100%;
    flex-wrap: wrap;
    row-gap:  60px;
    box-sizing: border-box;
    *{
      box-sizing: border-box;
    }
`
const StatBoxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 48%;
  flex-wrap: wrap;
  align-items: center;
`




const Warpper = styled.div`
  display: flex;
  justify-content: ${(p) => (p.justifyContent ? p.justifyContent : 'space-between')};
  flex-direction: ${(p) => (p.flexDirection ? p.flexDirection : '')};
  width: ${(p) => (p.width ? p.width : '100%')};
  gap: ${(p) => (p.gap ? p.gap : '60px')};

`