export const GanttConsts = {
  ROW_HEIGHT: 40,
  HEADER_HEIGHT: 50,
  TREE_WIDTH: 300,
  LEAF_TITLE_PADDING_LEFT: 50,
  LEAF_CHILDREN_PADDING_LEFT: 5,
  SCALE_STEP_DEFAULT_WIDTH: 50,
  SECONDS_IN_HOUR: 3600,
  SECONDS_IN_DAY: 86400,
  HOURS_IN_DAY: 24,
  MIN_SCROLL_OFFSET: 0,
  SCALE_STEP_RATIO: 1.6,
};
