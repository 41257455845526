import { useEffect, useState } from "react"
import { verifyUser } from "../../../redux/authentication/actionCreator";
import { useDispatch } from "react-redux";

const useVerifyUser = () => {
  const freeSrveys = ['Coaching_Assessment_Survey', 'Sponsor_Assessment_Survey']

  const [isFreeAccount, setIsFreeAccount] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    verifyUserAccountType()
  }, [])

  const verifyUserAccountType = () => {
    dispatch(
      verifyUser({}, (response) => {
        if (response.status === 200) {
          if (response.data.isFreeAccount && !response.data.isTrialAccount) {
            setIsFreeAccount(true);
          }
        }
      }),
    );
  }

  return {
    isFreeAccount,
    freeSrveys,
    verifyUserAccountType,
  }
}

export default useVerifyUser;