import React, { useState, useReducer, useEffect, useCallback } from "react";
import { List } from "antd";
import { FreeCategoryContainer, FreeCategoryListWrapper, FreeMainContentWrapper } from "./FreeLibrarySection.styles";
import FreeCategoryHeader from "./FreeCategoryHeader";
import FreeCategoryItem from "./FreeCategoryItem";
import { useDispatch } from "react-redux";
import { fetchTrainingCategories } from "../../redux/cms/actionCreator";
import useNotificationController from "../../utility/useNotificationController";
import FreeOCMTrainingContent from "./FreeOCMTrainingContent";

const FreeContentArea = ({setFeaturedImage}) => {
  const {
    contextHolder,
    openError,
  } = useNotificationController();
  const [categories, setCategories] = useState([])
  const [category, setCategory] = useState({});

  const initialState = {
      error: false,
      messages: ""
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'FETCH_TRAINING_CATEGORIES_ERR':
        return { ...state, error: true,messages: action.err };
      case 'RESET':
        return state;
      default:
        return state;
    }
  };

  const [apiError, apiResponseCallback] = useReducer(reducer, initialState);
  const dispatch = useDispatch();

  const fetchCategories = useCallback(async () => {
    apiResponseCallback({ type: 'RESET' });
      dispatch(
          fetchTrainingCategories({},(response) => {
            if (response.status === 200) {
              let categories = response.data.categories;
              const tmpCategories = [];
              const currentTrainingCategoryId = localStorage.getItem("currentTrainingCategory");
              categories.map((item, index) => {
                let isActive = false;
                if (currentTrainingCategoryId === null && index === 0)
                {
                  isActive = true;
                }
                else if (parseInt(currentTrainingCategoryId) === item.id)
                {
                  isActive = true;
                }
                tmpCategories.push({
                  id: item.id,
                  name: item.name,
                  featuredImage: item.featuredImage,
                  isActive: isActive
                });
                if (currentTrainingCategoryId === null && index === 0)
                {
                  setCategory({
                    id: item.id,
                    name: item.name,
                    featuredImage: item.featuredImage
                  });
                }
                else if (parseInt(currentTrainingCategoryId) === item.id)
                {
                  setCategory({
                    id: item.id,
                    name: item.name,
                    featuredImage: item.featuredImage
                  });
                }
              });
              setCategories(tmpCategories);
            } 
            else 
            {
              openError('topLeft', 'Error', response.err);
              apiResponseCallback(response);
            }
          }),
        );
  }, [dispatch, categories, category]);

  useEffect(() => {
    const retrieveData = async () => {
      await fetchCategories();
    };
    retrieveData().catch(console.error);
  }, []);

  const handleCategoryClick = (id) => {
    setCategories(prevCategories => 
      prevCategories.map(category => 
        category.id === id
          ? { ...category, isActive: true }
          : { ...category, isActive: false }
      )
    );
    categories.map((item, index) => {
      if (item.id === id)
      {
        localStorage.setItem("currentTrainingCategory", item.id);
        setCategory(item);
        if (item.featuredImage === null && item.featuredImage === undefined)
        {
          //setFeaturedImage('cover-change-management-library.png');
        }
        else
        {
          //setFeaturedImage(item.featuredImage);
        }
        return ;
      }
    });
  };
  
  return (
    <FreeMainContentWrapper>
      {contextHolder}
      <FreeCategoryContainer>
        <FreeCategoryHeader />
        <FreeCategoryListWrapper>
          <List
            dataSource={categories}
            renderItem={(category, index) => (
              <List.Item 
                className={category.isActive ? "active" : ""}
                onClick={() => handleCategoryClick(category.id)}
              >
                <FreeCategoryItem 
                  key={`${category.id}-${index}`} 
                  title={category.name} 
                  isActive={category.isActive} 
                />
              </List.Item>
            )}
          />
        </FreeCategoryListWrapper>
      </FreeCategoryContainer>
      <FreeOCMTrainingContent category={category} />
    </FreeMainContentWrapper>
  );
};

export default FreeContentArea;