import React, { useCallback, useContext, useMemo, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import Button from './Button';
import Select from './Select';
import { LeftOutlined, RightOutlined, StepOutlined } from '../../assets';
import { GanttContext } from '../Gantt/GanttContext';
import { useGanttCalculate } from '../../hooks';
import { DragStepSizes, GanttDimensions } from '../../enums';
import { DragStepOptions, GanttDimensionsSettings } from '../../constants';
import './Controls.css';

interface ControlsProps {
  className?: string;
}

const Controls: React.FC<ControlsProps> = ({ className }) => {
  const { calculate } = useGanttCalculate();
  const { settings, setSettings, currentDate } = useContext(GanttContext);
  const [dimensionValue, setDimensionValue] = useState(GanttDimensions.DAY);
  const [dimensionIndex, setDimensionIndex] = useState(2);
  const [calculateDone, setCalculateDone] = useState(false);

  const dimensionsOptions = useMemo(() => {
    let items:any = []
    let i = 0
    for(let key in GanttDimensionsSettings)
    {
      if(GanttDimensionsSettings[key as GanttDimensions].value > 0)
      {
        items.push(
          {
            label: GanttDimensionsSettings[key as GanttDimensions].label,
            value: key as GanttDimensions,
            i,
          }
        )
        i = i+1;
      }
    }
    return items;
  }, []);

  const onDimensionChange = useCallback(
    (value: GanttDimensions) => {
      const index = dimensionsOptions.findIndex((item:any) => item.value === value);

      setDimensionValue(value);
      setDimensionIndex(index);
      calculate(value);
    },
    [calculate, dimensionsOptions]
  );

  const onNextDimension = useCallback(() => {
    if (dimensionIndex < dimensionsOptions.length) {
      const value = Object.keys(GanttDimensionsSettings)[dimensionIndex + 1] as GanttDimensions;

      setDimensionValue(value);
      setDimensionIndex(dimensionIndex + 1);
      calculate(value);
    }
  }, [calculate, dimensionsOptions.length, dimensionIndex]);

  const onPrevDimension = useCallback(() => {
    if (dimensionIndex > 1) {
      const value = Object.keys(GanttDimensionsSettings)[dimensionIndex - 1] as GanttDimensions;

      setDimensionValue(value);
      setDimensionIndex(dimensionIndex - 1);
      calculate(value);
    }
  }, [calculate, dimensionIndex]);

  const dragStepOptions = useMemo(() => {
    return Object.keys(DragStepOptions).map((key) => {
      return {
        label: DragStepOptions[key as DragStepSizes].label,
        value: key as DragStepSizes,
      };
    });
  }, []);

  const onDragStepChange = useCallback(
    (value: DragStepSizes) => {
      setSettings(() => {
        const newSettings = { ...settings };

        newSettings.dragStepSize = value;
        newSettings.gridSize =
          DragStepOptions[value].seconds /
          GanttDimensionsSettings[newSettings.dimension].secondsInPixel;

        return newSettings;
      });
    },
    [setSettings, settings]
  );
  useEffect(()=>{
    if(calculateDone == false)
    {
      calculate(dimensionValue);
      setCalculateDone(true)
    }
  })

  let grantControls = `gantt-controls-wrap ${className}`;

  return (
    <div className={grantControls}>
      <Button icon={<LeftOutlined />} onClick={onPrevDimension} disabled={dimensionIndex <= 1} />
      <Select options={dimensionsOptions} onChange={onDimensionChange} value={dimensionValue} />
      <Button
        icon={<RightOutlined />}
        onClick={onNextDimension}
        disabled={dimensionIndex >= dimensionsOptions.length}
      />
      <div className="gantt-controls-current-date">
        {dayjs.unix(currentDate).format('ddd, ll')}
      </div>
      {/*<Select
        options={dragStepOptions}
        onChange={onDragStepChange}
        value={settings.dragStepSize}
        suffixIcon={<StepOutlined />}
      /> */}
    </div>
  );
};

export default Controls;
