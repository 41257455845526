import React, { useEffect, useState } from 'react';
import { Button, Typography, Row, Col, Input, Checkbox, Popover, Modal } from 'antd';

import Cookies from "js-cookie";
import { FeedBackStyle } from './auth-info-style';
import { duotone, regular } from '@fortawesome/fontawesome-svg-core/import.macro'; // <-- import styles to be used
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataService } from '../../../config/dataService/dataService';
const {Title, Text} = Typography;
import "./feedbackpopover.css"
import { useDispatch } from 'react-redux';
import { logOut } from '../../../redux/authentication/actionCreator';
import { useNavigate } from 'react-router-dom';
import useNotificationController from '../../../utility/useNotificationController';

const Feedback = React.memo((props) => {
  const {
    contextHolder,
    openError
  } = useNotificationController();
  
  const [feedback, setFeedback] = useState(0);
  const [thanks, setThanks] = useState(0);
  const [anonymous, setAnonymous] = useState(0);
  const [comment, setComment] = useState('');
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sendFeedback = async () => {
    if (feedback == 0) {
      openError('topLeft', 'Feedback Error', "No feedback given");
    } else {
      setLoading(true);
      const response = await DataService.post('/users/send-feedback', {
        feedback: feedback,
        comment: comment,
        anonymous: anonymous,
      });
      if (response.status == 200) {
        setLoading(false);
        setThanks(1);
        if (props.logoutThisUser)
        {
            setTimeout(()=>{
              let userId = localStorage.getItem('user_id');
              Cookies.set(`feedbackProvided${userId}`, true, { expires: 36500 });
              setVisible(false);
              dispatch(logOut(() => navigate('/')));
            },1000)
        }
        else
        {
          setTimeout(()=>{
            setVisible(false);
          },6000)
        }
        
      }
    }
  };

  const handleVisibleChange = (visibleStatus) => {
    setVisible(visibleStatus);
    if (!visibleStatus)
    {
      if (props.logoutThisUser)
      {
          dispatch(logOut(() => navigate('/')));
      }
    }
  };
  
  const handleChange = (e) => {
    setAnonymous(e.target.checked ? 1 : 0);
  };

  const resetFeedBack = (show) => {
    setOpenFeedback(show);
  };

  useEffect(() => {
    setOpenFeedback(props.showModal);
    setShowFeedback(props.showModal);
  }, [props]);

  const content =
    thanks == 1 ? (
      <FeedBackStyle>
        <div style={{ padding: '30px' }}>
          <Row>
            <Col span={16} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
              <Title level={3} style={{ color: '#63BFB4' }}>
                Thanks for your feedback
              </Title>
            </Col>
            <Col span={8}>
              <img src="/feedbackthanks.png" style={{ width: '120px' }} />
            </Col>
          </Row>
        </div>
      </FeedBackStyle>
    ) : (
      <FeedBackStyle>
        <Title level={3} style={{ color: '#63BFB4' }}>
          How is your experience using this platform?{' '}
        </Title>
        <Row style={{ marginTop: '20px' }}>
          <Col span={8} style={{ textAlign: 'center', display: "flex", flexDirection: "column" }}>
            <FontAwesomeIcon 
              icon={regular("smile")} 
              onClick={() => {
                setFeedback(1);
              }}
              style={{ cursor: 'pointer' }}
              size="6x"
              color={feedback == 1 ? '#4A8F87' : '#DCF3F1'}
            />
            <Text style={{fontSize: "20px", color: feedback == 1 ? '#4A8F87' : 'rgb(158, 201, 197)'}}>Very Satisfied</Text>
          </Col>
          <Col span={8} style={{ textAlign: 'center', display: "flex", flexDirection: "column" }}>
            <FontAwesomeIcon 
              icon={regular("meh")} 
              onClick={() => {
                setFeedback(2);
              }}
              style={{ cursor: 'pointer' }}
              size="6x"
              color={feedback == 2 ? '#4A8F87' : '#DCF3F1'}
            />
            <Text style={{fontSize: "20px", color: feedback == 2 ? '#4A8F87' : 'rgb(158, 201, 197)'}}>Okay</Text>
          </Col>
          <Col span={8} style={{ textAlign: 'center', display: "flex", flexDirection: "column" }}>
            <FontAwesomeIcon 
              icon={regular("face-frown")} 
              onClick={() => {
                setFeedback(3);
              }}
              style={{ cursor: 'pointer' }}
              size="6x"
              color={feedback == 3 ? '#4A8F87' : '#DCF3F1'}
            />
            <Text style={{fontSize: "20px", color: feedback == 3 ? '#4A8F87' : 'rgb(158, 201, 197)'}}>Unsatisfied</Text>
          </Col>
        </Row>
        <Row style={{ marginTop: '40px', textAlign: 'center' }}>
          <Col span={6}>Comments</Col>
          <Col span={18}>
            <Input value={comment} onChange={(e) => setComment(e.target.value)} />
          </Col>
        </Row>

        <Row style={{ marginTop: '20px' }}>
          <Col span={24}>
            <Checkbox onChange={handleChange}>
              <span style={{ fontSize: '12px', color: '#63BFB4' }}>Make feedback anonymous.</span>
            </Checkbox>
          </Col>
        </Row>

        <Row style={{ marginTop: '10px' }}>
          <Col span={24}>
            <Button
              loading={loading}
              onClick={() => sendFeedback()}
              style={{
                backgroundColor: '#64BFB4',
                borderRadius: '10px',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 auto',
              }}
            >
              Send Feedback
            </Button>
          </Col>
        </Row>
      </FeedBackStyle>
    );

  return (
    <>
    {contextHolder}
    <div className="ocmsolution-nav-actions__item ocmsolution-nav-actions__notification">
      <Popover
        open={visible}
        onOpenChange={handleVisibleChange}
        overlayClassName="custom-popover"
        content={content}
        action="click"
      >
        <Button
          size='large'
          type={'ocm-default-feedback'}
        >
          Feedback
          <img src="/feedback.svg" alt="icon" style={{ marginLeft: '8px', height: '30px', width: '30px' }} />
        </Button>
      </Popover>

      {openFeedback && (
        <Modal
          style={{
            margin: '20px 0px',
          }}
          className="add-change"
          title={<></>}
          centered
          closeIcon={<FontAwesomeIcon size="2xl" icon={duotone('circle-xmark')} />}
          open={showFeedback}
          onCancel={() => {
            setShowFeedback(false);
            props.removeToShowAgain();
            if (props.logoutThisUser)
            {
                dispatch(logOut(() => navigate('/')));
            }
          }}
          onOk={() => {
            setShowFeedback(false);
            props.removeToShowAgain();
            if (props.logoutThisUser)
            {
                dispatch(logOut(() => navigate('/')));
            }
          }}
          width={800}
          wrapClassName={'ocm-modal'}
          footer={[]}
        >
          {content}
        </Modal>
      )}
    </div>
    </>
  );
});

export default Feedback;
