import { notification } from 'antd';

const useNotificationController = () => {
  const [api, contextHolder] = notification.useNotification();

  const openError = (placement, messages, description) => {
    if (placement.trim() === '') {
      api.error({
        message: `${messages}`,
        description: <p>{description}</p>,
      });
    } else {
      api.error({
        message: `${messages}`,
        description: <p>{description}</p>,
        placement: placement,
      });
    }
  };

  const openSuccess = (placement, messages, description) => {
    api.success({
      message: `${messages}`,
      description: <div>{description}</div>,
      placement,
    });
  };

  const openSuccessDelete = (placement, messages, description, small) => {
    if (small !== undefined) {
      description =
        '<p>' +
        description +
        '</p>' +
        "<small><Text type='secondary' style='font-size: 14px; color: #999;'>" +
        small +
        '</Text></small>';
    }
    api.success({
      message: `${messages}`,
      description: <p dangerouslySetInnerHTML={{ __html: description }} />,
      placement,
    });
  };

  return {
    contextHolder,
    openError,
    openSuccess,
    openSuccessDelete
  };
};

export default useNotificationController;
