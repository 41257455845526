const actions = {
  FETCH_CMS_CATEGORIES_BEGIN: 'FETCH_CMS_CATEGORIES_BEGIN',
  FETCH_CMS_CATEGORIES_SUCCESS: 'FETCH_CMS_CATEGORIES_SUCCESS',
  FETCH_CMS_CATEGORIES_ERR: 'FETCH_CMS_CATEGORIES_ERR',

  FETCH_TRAINING_CATEGORIES_BEGIN: 'FETCH_TRAINING_CATEGORIES_BEGIN',
  FETCH_TRAINING_CATEGORIES_SUCCESS: 'FETCH_TRAINING_CATEGORIES_SUCCESS',
  FETCH_TRAINING_CATEGORIES_ERR: 'FETCH_TRAINING_CATEGORIES_ERR',

  FETCH_RESOURCE_CATEGORIES_BEGIN: 'FETCH_RESOURCE_CATEGORIES_BEGIN',
  FETCH_RESOURCE_CATEGORIES_SUCCESS: 'FETCH_RESOURCE_CATEGORIES_SUCCESS',
  FETCH_RESOURCE_CATEGORIES_ERR: 'FETCH_RESOURCE_CATEGORIES_ERR',

  FETCH_CMS_PAGES_BEGIN: 'FETCH_CMS_PAGES_BEGIN',
  FETCH_CMS_PAGES_SUCCESS: 'FETCH_CMS_PAGES_SUCCESS',
  FETCH_CMS_PAGES_ERR: 'FETCH_CMS_PAGES_ERR',

  FETCH_TRAINING_PAGES_BEGIN: 'FETCH_TRAINING_PAGES_BEGIN',
  FETCH_TRAINING_PAGES_SUCCESS: 'FETCH_TRAINING_PAGES_SUCCESS',
  FETCH_TRAINING_PAGES_ERR: 'FETCH_TRAINING_PAGES_ERR',

  FETCH_RESOURCE_PAGES_BEGIN: 'FETCH_RESOURCE_PAGES_BEGIN',
  FETCH_RESOURCE_PAGES_SUCCESS: 'FETCH_RESOURCE_PAGES_SUCCESS',
  FETCH_RESOURCE_PAGES_ERR: 'FETCH_RESOURCE_PAGES_ERR',

  FETCH_CMS_PAGE_CONTENT_BEGIN: 'FETCH_CMS_PAGE_CONTENT_BEGIN',
  FETCH_CMS_PAGE_CONTENT_SUCCESS: 'FETCH_CMS_PAGE_CONTENT_SUCCESS',
  FETCH_CMS_PAGE_CONTENT_ERR: 'FETCH_CMS_PAGE_CONTENT_ERR',

  FETCH_PUBLIC_CMS_PAGE_CONTENT_BEGIN: 'FETCH_PUBLIC_CMS_PAGE_CONTENT_BEGIN',
  FETCH_PUBLIC_CMS_PAGE_CONTENT_SUCCESS: 'FETCH_PUBLIC_CMS_PAGE_CONTENT_SUCCESS',
  FETCH_PUBLIC_CMS_PAGE_CONTENT_ERR: 'FETCH_PUBLIC_CMS_PAGE_CONTENT_ERR',

  fetchCMSCategoriesBegin: () => {
    return {
      type: actions.FETCH_CMS_CATEGORIES_BEGIN,
    };
  },

  fetchCMSCategoriesSuccess: (data) => {
    return {
      type: actions.FETCH_CMS_CATEGORIES_SUCCESS,
      data,
    };
  },

  fetchCMSCategoriesErr: (err) => {
    return {
      type: actions.FETCH_CMS_CATEGORIES_ERR,
      err,
    };
  },

  fetchTrainingCategoriesBegin: () => {
    return {
      type: actions.FETCH_TRAINING_CATEGORIES_BEGIN,
    };
  },

  fetchTrainingCategoriesSuccess: (data) => {
    return {
      type: actions.FETCH_TRAINING_CATEGORIES_SUCCESS,
      data,
    };
  },

  fetchTrainingCategoriesErr: (err) => {
    return {
      type: actions.FETCH_TRAINING_CATEGORIES_ERR,
      err,
    };
  },

  fetchResourceCategoriesBegin: () => {
    return {
      type: actions.FETCH_RESOURCE_CATEGORIES_BEGIN,
    };
  },

  fetchResourceCategoriesSuccess: (data) => {
    return {
      type: actions.FETCH_RESOURCE_CATEGORIES_SUCCESS,
      data,
    };
  },

  fetchResourceCategoriesErr: (err) => {
    return {
      type: actions.FETCH_RESOURCE_CATEGORIES_ERR,
      err,
    };
  },

  fetchCMSPagesBegin: () => {
    return {
      type: actions.FETCH_CMS_PAGES_BEGIN,
    };
  },

  fetchCMSPagesSuccess: (data) => {
    return {
      type: actions.FETCH_CMS_PAGES_SUCCESS,
      data,
    };
  },

  fetchCMSPagesErr: (err) => {
    return {
      type: actions.FETCH_CMS_PAGES_ERR,
      err,
    };
  },

  fetchTrainingPagesBegin: () => {
    return {
      type: actions.FETCH_TRAINING_PAGES_BEGIN,
    };
  },

  fetchTrainingPagesSuccess: (data) => {
    return {
      type: actions.FETCH_TRAINING_PAGES_SUCCESS,
      data,
    };
  },

  fetchTrainingPagesErr: (err) => {
    return {
      type: actions.FETCH_TRAINING_PAGES_ERR,
      err,
    };
  },

  fetchResourcePagesBegin: () => {
    return {
      type: actions.FETCH_RESOURCE_PAGES_BEGIN,
    };
  },

  fetchResourcePagesSuccess: (data) => {
    return {
      type: actions.FETCH_RESOURCE_PAGES_SUCCESS,
      data,
    };
  },

  fetchResourcePagesErr: (err) => {
    return {
      type: actions.FETCH_RESOURCE_PAGES_ERR,
      err,
    };
  },

  fetchCMSPageContentBegin: () => {
    return {
      type: actions.FETCH_CMS_PAGE_CONTENT_BEGIN,
    };
  },

  fetchCMSPageContentSuccess: (data) => {
    return {
      type: actions.FETCH_CMS_PAGE_CONTENT_SUCCESS,
      data,
    };
  },

  fetchCMSPageContentErr: (err) => {
    return {
      type: actions.FETCH_CMS_PAGE_CONTENT_ERR,
      err,
    };
  },

  fetchPublicCMSPageContentBegin: () => {
    return {
      type: actions.FETCH_PUBLIC_CMS_PAGE_CONTENT_BEGIN,
    };
  },

  fetchPublicCMSPageContentSuccess: (data) => {
    return {
      type: actions.FETCH_PUBLIC_CMS_PAGE_CONTENT_SUCCESS,
      data,
    };
  },

  fetchPublicCMSPageContentErr: (err) => {
    return {
      type: actions.FETCH_PUBLIC_CMS_PAGE_CONTENT_ERR,
      err,
    };
  },
};

export default actions;
