import React from "react";
import styled from "styled-components";

function FreeCategoryHeader() {
  return <Header>Categories</Header>;
}

const Header = styled.h1`
  padding-top: 20px;
  margin-bottom: 0px;
  text-align: center;
  align-self: center;
  font: 700 24px Inter, sans-serif;
`;

export default FreeCategoryHeader;