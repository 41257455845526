import { Button, Table } from 'antd';
import dayjs from 'dayjs';
import { faEnvelope, faGear, faClone, faTrash, faPencil } from '@fortawesome/pro-duotone-svg-icons';
import { useState } from 'react';
import styled from 'styled-components';
import DeleteSurveyPopup from '../Popup/DeleteSurveyPopup';
import { DataService } from '../../../../config/dataService/dataService';
import surveyStyles from './survey_styles.json';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as ProIcons from '@fortawesome/pro-duotone-svg-icons';
import SettingsPopup from '../Popup/SettingsPopup';
import SendSurvey from '../Popup/SendSurvey';
import { omit } from 'lodash';
import useNotificationController from '../../../../utility/useNotificationController';

export default function SurveysList({ data, reloadData, loading }) {
  const {
    contextHolder,
    openSuccess,
  } = useNotificationController();
  
  const organizationalSlug = localStorage.getItem('organizationalSlug');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSettingModal, setShowSettingModal] = useState(false);
  const [showSendSurveyModal, setShowSendSurveyModal] = useState(false);
  const [selectedSurvey, setSelectedSurvey] = useState();
  const [isCloning, setCloning] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});

  const deleteSurvey = async () => {
    await DataService.delete(`/surveys/delete-survey/${selectedSurvey.id}`);
    openSuccess('bottomRight', "Delete Survey",  `Survey deleted sucessfully!`);
    setSelectedSurvey(undefined);
    reloadData();
    setShowDeleteModal(false);
  };

  function cloneObjectWithTitle(array, titleToClone) {
    const titleCounts = new Map();

    for (const item of array) {
      const title = item.surveyName;
      if (titleCounts.has(title)) {
        titleCounts.set(title, titleCounts.get(title) + 1);
      } else {
        titleCounts.set(title, 1);
      }
    }

    let newTitle = titleToClone;
    let cloneCount = 0;
    while (true) {
      if (cloneCount > 0) {
        newTitle = `${titleToClone} (${cloneCount})`;
      }
      if (!titleCounts.has(newTitle)) {
        break;
      }
      cloneCount++;
    }

    return newTitle;
  }

  const handleClickClone = async (cloneData) => {
    const { survey_template } = cloneData;
    const title = cloneObjectWithTitle(
      data,
      cloneData.surveyName,
    );

    try {
      setCloning(true);
      await DataService.post('surveys/create-survey', {
        ...omit(data, ['updatedAt', 'setSurveyDuration', 'surveyStartDate', 'surveyEndDate', 'survey_responses', 'surveySent', 'surveySentDate', 'recipients', 'surveyEmailSubject', 'updatedAt']),
        surveyName: title,
        surveyTemplate: { ...survey_template },
      });
      reloadData();
      openSuccess('bottomRight', "Survey",  `Survey duplicated successfully!`);
      setCloning(false);
    } catch (error) {}
  };

  const columns = [
    {
      title: 'Survey',
      dataIndex: 'surveyName',
      key: 'surveyName',
      sorter: (a, b) => a.surveyName.localeCompare(b.survey_template.title),
      sortOrder: sortedInfo.columnKey === 'surveyName' ? sortedInfo.order : null,
      render: (_, { id, survey_template, surveyName }) => {
        const style = surveyStyles[survey_template.key];
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconWarpper
              onClick={() => {
                window.open(`${window.location.origin}/${organizationalSlug}/surveys/edit/${id}`, '_blank');
              }}
            >
              <FontAwesomeIcon
                icon={ProIcons[style.faIcon]}
                size="2xl"
                style={{
                  '--fa-primary-color': `${style.color.primary}`,
                  '--fa-secondary-color': `${style.color.secondary}`,
                  padding: '10px 9px',
                  border: `2px solid ${style.color.primary}`,
                  borderRadius: '30px',
                  cursor: 'pointer',
                }}
              />
            </IconWarpper>
            <Title
              target="_blank"
              href={`${window.location.origin}/${organizationalSlug}/surveys/edit/${id}`}
            >{surveyName}</Title>
          </div>
        );
      },
    },
    {
      title: 'Responses',
      dataIndex: 'survey_responses',
      key: 'survey_responses',
      sorter: (a, b) => a.survey_responses - b.survey_responses,
      sortOrder: sortedInfo.columnKey === 'survey_responses' ? sortedInfo.order : null, // Add this line
      render: (_, { survey_responses }) => {
        return (
          <span
            style={{ textDecoration: 'underline', color: '#E8E8E8', fontSize: '16px', fontWeight: 'bold' }}
          >{`${survey_responses} response${survey_responses > 1 ? 's' : ''}`}</span>
        );
      },
    },
    {
      title: 'Survey End Date',
      dataIndex: 'surveyEndDate',
      key: 'surveyEndDate',
      sorter: (a, b) => {
        const dateA = a.surveyEndDate ? new Date(a.surveyEndDate) : null;
        const dateB = b.surveyEndDate ? new Date(b.surveyEndDate) : null;

        // Check if dateA or dateB is null and sort accordingly
        if (dateA === null && dateB === null) {
          return 0; // Both dates are null, no change in order
        } else if (dateA === null) {
          return 1; // dateA is null, move it to the bottom
        } else if (dateB === null) {
          return -1; // dateB is null, move it to the bottom
        } else {
          // Both dates are available, sort in ascending order
          return dateA - dateB;
        }
      },
      sortOrder: sortedInfo.columnKey === 'surveyEndDate' ? sortedInfo.order : null, // Add this line
      render: (_, { surveyEndDate }) => (surveyEndDate ? dayjs(surveyEndDate).format('DD MMM, YYYY') : null),
    },
    {
      title: 'Modified Date',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: (a, b) => {
        const dateA = new Date(a.updatedAt);
        const dateB = new Date(b.updatedAt);
        return dateA - dateB;
      },
      sortOrder: sortedInfo.columnKey === 'updatedAt' ? sortedInfo.order : null, // Add this line
      render: (_, { updatedAt }) => dayjs(updatedAt).format('DD MMM, YYYY'),
    },
    {
      title: 'Quick Actions',
      render: (_, row) => {
        return (
          <div style={{ display: 'flex', gap: 15 }}>
            <Button
              onClick={() => window.open(`${window.location.origin}/${organizationalSlug}/surveys/edit/${row.id}?global-edit=true`, '_blank')}
              icon={<FontAwesomeIcon icon={faPencil} size="xl" color="#00AFF0" />}
              type="text"
            />
            <Button
              loading={isCloning}
              onClick={() => handleClickClone(row)}
              icon={<FontAwesomeIcon icon={faClone} size="xl" color="#00AFF0" />}
              type="text"
            />
            <Button
              icon={<FontAwesomeIcon icon={faEnvelope} size="xl" color="#FEC002" />}
              type="text"
              onClick={() => {
                setSelectedSurvey(row);
                setShowSendSurveyModal(true);
              }}
            />
            <Button
              icon={<FontAwesomeIcon icon={faGear} size="xl" color="#7F7F7F" />}
              type="text"
              onClick={() => {
                setSelectedSurvey(row);
                setShowSettingModal(true);
              }}
            />
            <Button
              onClick={() => {
                setSelectedSurvey(row);
                setShowDeleteModal(true);
              }}
              icon={<FontAwesomeIcon icon={faTrash} size="xl" color="#FF0308" />}
              type="text"
            />
          </div>
        );
      },
    },
  ];
  return (
    <>
      {contextHolder}
      <Table
        loading={loading}
        columns={columns}
        dataSource={data}
        onChange={(pagination, filters, sorter) => {
          setSortedInfo(sorter);
        }}
      />
      <DeleteSurveyPopup open={showDeleteModal} onClose={() => setShowDeleteModal(false)} onSubmit={deleteSurvey} />
      {selectedSurvey && (
        <>
          {showSettingModal && (
            <SettingsPopup
              open={showSettingModal}
              onClose={() => setShowSettingModal(false)}
              initial={selectedSurvey}
              onSubmit={reloadData}
            />
          )}
          {showSendSurveyModal && (
            <SendSurvey
              surveyData={selectedSurvey}
              open={showSendSurveyModal}
              onClose={() => {
                setSelectedSurvey(null);
                setShowSendSurveyModal(false);
              }}
            />
          )}
        </>
      )}
    </>
  );
}

const Title = styled.a`
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
  color: black;
`;

const IconWarpper = styled.div`
  margin-right: 5px;
  width: 50px;
  height: 50px;

  svg {
    width: 25px;
    height: 25px;
  }
`;
