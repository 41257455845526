export enum DragStepSizes {
  FIVE_MIN = 'FIVE_MIN',
  TEN_MIN = 'TEN_MIN',
  FIFTEEN_MIN = 'FIFTEEN_MIN',
  TWENTY_MIN = 'TWENTY_MIN',
  THIRTY_MIN = 'THIRTY_MIN',
  ONE_HOUR = 'ONE_HOUR',
  EIGHT_HOURS = 'EIGHT_HOURS',
  TWELVE_HOURS = 'TWELVE_HOURS',
  ONE_DAY = 'ONE_DAY',
}
