const actions = {
  MAIN_DASHBOARD_PHASE_BEGIN: 'MAIN_DASHBOARD_PHASE_BEGIN',
  MAIN_DASHBOARD_PHASE_SUCCESS: 'MAIN_DASHBOARD_PHASE_SUCCESS',
  MAIN_DASHBOARD_PHASE_ERR: 'MAIN_DASHBOARD_PHASE_ERR',

  OCM_TOOLS_BEGIN: 'OCM_TOOLS_BEGIN',
  OCM_TOOLS_SUCCESS: 'OCM_TOOLS_SUCCESS',
  OCM_TOOLS_ERR: 'OCM_TOOLS_ERR',

  mainDashboardPhaseBegin: () => {
    return {
      type: actions.MAIN_DASHBOARD_PHASE_BEGIN,
    };
  },

  mainDashboardPhaseSuccess: (data) => {
    return {
      type: actions.MAIN_DASHBOARD_PHASE_SUCCESS,
      data,
    };
  },

  mainDashboardPhaseErr: (err) => {
    return {
      type: actions.MAIN_DASHBOARD_PHASE_ERR,
      err,
    };
  },

  OCMToolsBegin: () => {
    return {
      type: actions.OCM_TOOLS_BEGIN,
    };
  },

  OCMToolsSuccess: (data) => {
    return {
      type: actions.OCM_TOOLS_SUCCESS,
      data,
    };
  },

  OCMToolsErr: (err) => {
    return {
      type: actions.OCM_TOOLS_ERR,
      err,
    };
  },
};

export default actions;
