// Function to encode a string
exports.encode = (data) => {
  const firstEncoded = btoa(data);
  const doubleEncoded = btoa(firstEncoded);
  return doubleEncoded;
}

// Function to decode a string
exports.decode = (encodedData) => {
  const firstDecoded = atob(encodedData);
  const doubleDecoded = atob(firstDecoded);
  return doubleDecoded;
}