import { reducerTypes } from './constants';

export const targetAudienceReducer = (state, action) => {
  switch (action.type) {
    case reducerTypes.INIT:
      return {
        ...state,
        ...action.payload,
      };
    case reducerTypes.AUDIENCE:
      return {
        ...state,
        audience: action.payload,
      };
    case reducerTypes.GROUPS:
      return {
        ...state,
        groups: action.payload,
      };
    case reducerTypes.LOCATIONS:
      return {
        ...state,
        location: action.payload,
      };
    case reducerTypes.JOB_ROLES:
      return {
        ...state,
        jobs: action.payload,
      };
    case reducerTypes.STAKEHOLDER_TYPES:
      return {
        ...state,
        stakeholders: action.payload,
      };
    case reducerTypes.FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      };
    case reducerTypes.DATA:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
      };
    case reducerTypes.START:
      return {
        ...state,
        start: true,
      };
    case reducerTypes.COLUMNS:
      return {
        ...state,
        columns: action.payload,
      };
    case reducerTypes.IS_EDITING:
      return {
        ...state,
        isEditing: action.payload,
      };
    case reducerTypes.SET_REF:
      return {
        ...state,
        columnRef: action.payload,
      };
    case reducerTypes.RELOAD:
      const option = {};
      if (action.payload) {
        option.reloadStats = true;
        option.reloadAudience = true;
      }
      return {
        ...state,
        reload: action.payload,
        ...option,
      };
    case reducerTypes.RELOAD_STATS:
      return {
        ...state,
        reloadStats: action.payload,
      };
    case reducerTypes.RELOAD_AUDIENCE:
      return {
        ...state,
        reloadAudience: action.payload,
        reloadStats: action.payload ? true : state.reloadStats,
        editIndividual: action.payload ? null : state.editIndividual,
      };
    case reducerTypes.COLUMNS_TYPES:
      return {
        ...state,
        audienceColumnsTypes: action.payload,
      };
    case reducerTypes.COLUMNS_TYPES:
      return {
        ...state,
        audienceColumnsTypes: action.payload,
      };
    case reducerTypes.HAS_UNSAVED_DATA:
      return {
        ...state,
        models: {
          ...state.models,
          hasUnsavedData: {
            ...state.models.hasUnsavedData,
            ...action.payload,
          },
        },
      };
    case reducerTypes.TARGETAUDIENCE_MODAL:
      if (action.payload.show) {
        return {
          ...state,
          models: {
            ...state.models,
            targetAudienceModal: {
              ...action.payload,
            },
          },
        };
      }
      return {
        ...state,
        models: {
          ...state.models,
          hasUnsavedData: {
            data: false,
            show: false,
          },
          targetAudienceModal: {
            ...action.payload,
          },
        },
      };
    case reducerTypes.SET_EDIT_MODAL:
      return {
        ...state,
        models: {
          ...state.models,
          editModal: action.payload,
        },
      };
    case reducerTypes.STAKEHOLDERS_EDIT_MODAL:
      return {
        ...state,
        models: {
          ...state.models,
          stakeholdersEditModal: action.payload,
        },
      };
    case reducerTypes.SET_VIEW_MODAL:
      return {
        ...state,
        models: {
          ...state.models,
          viewModal: action.payload,
        },
      };
    case reducerTypes.SET_NEXT_TAB:
      return {
        ...state,
        nextTab: action.payload,
      };
    case reducerTypes.SET_STATS:
      return {
        ...state,
        targetAudienceCount: action.payload,
      };
    case reducerTypes.SET_VIEW:
      return {
        ...state,
        setView: action.payload,
      };
    case reducerTypes.SET_GENERATING_TOOL:
      return {
        ...state,
        generatingTool: action.payload
      }
    case reducerTypes.ACTIVE_PROJECT:
      return {
        ...state,
        activeProject: action.payload
      }
      case reducerTypes.EDIT_INDIVIDUAL:
        return {
          ...state,
          editIndividual: action.payload
        }

    default:
      throw new Error(`Can't get type.`);
  }
};
