import OCMSCustomeModel from '../../../../ui-elements/OCMSCustomeModel';
import styled from 'styled-components';
import { Button, Input, Spin, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faTrashCan } from '@fortawesome/pro-duotone-svg-icons';
import { useTargetAudienceState } from '../../../context/targetAudience';
import { useEffect, useState, useMemo } from 'react';
import useTargetAudience from '../../../hook/useTargetAudience';

const { Title } = Typography;

function EditListModel(props) {
  const {
    title,
    open,
    editListTitle,
    close,
    type,
    footer = null,
    editMode,
    parentName,
    parentId,
    options,
    levelId,
  } = props;
  const state = useTargetAudienceState();
  const [loading, setLoading] = useState(false);
  const { groupsLocationsJobsTemporaryCRUD, groupsLocationsJobsCRUD, saveColumns } = useTargetAudience();
  const [editListData, setEditListData] = useState([]);
  const [addNewField, setAddNewField] = useState({ isFieldEdit: false, isModelClose: true });
  const [errorMessage, setErrorMessage] = useState('');
  const [deletingItems, setDeletingItems] = useState([]);
  const column = state?.columns.find((item) => item.title === editListTitle);
  let preList = options || column?.options;

  useEffect(() => {
    setEditListData(options || column?.options || []);
  }, [state, column]);

  const { editingItems, newItems, listError } = useMemo(() => {
    const editingItems = [];
    const newItems = [];
    let error = '';
    if (editListData.length) {
      editListData.forEach((item) => {
        // Extracting new items
        if (`${item.key}`.startsWith('New-')) {
          if (item.label) {
            newItems.push({
              value: item.label,
            });
          } else {
            error = `The input can't be empty, its required.`;
          }
        }

        // Extracting Edited items
        if (!`${item.key}`.startsWith('New-') && !error) {
          if (!item.label) {
            error = `The input can't be empty, its required.`;
          }
          if (item.label && !preList?.some((i) => i.label === item.label)) {
            editingItems.push({
              id: item.key || item.id,
              value: item.label,
            });
          }
        }
      });
    }

    if (!editingItems?.length && !newItems?.length && !deletingItems?.length && !error) {
      error = `No change detected. Please click cancel if there is no change.`;
    }

    return {
      editingItems,
      newItems,
      listError: error,
    };
  }, [editListData, deletingItems]);

  const handleInputChange = (changeValue, item) => {
    let tempArray = editListData.map((i) => {
      if ((item.id && item.id === i.id) || (item.key && item.key === i.key)) {
        return { ...i, label: changeValue };
      } else {
        return i;
      }
    });
    setEditListData(tempArray);
    setAddNewField({ isFieldEdit: true, isModelClose: false });
  };
  const handleDeletListItem = (item) => {
    let tempArray = [];
    editListData.forEach((i) => {
      if (item?.id !== i?.id) {
        tempArray.push(i);
      }
    });
    if (!`${item.key}`.startsWith('New-')) {
      setDeletingItems([...deletingItems, { id: item.key || item.id, deleted: true }]);
    }
    setEditListData(tempArray);
    setAddNewField({ isFieldEdit: true, isModelClose: false });
  };
  const handleAddListItem = () => {
    setEditListData([
      ...editListData,
      {
        key: `New-${editListData.length}-${Number(Math.random())}`,
        label: '',
      },
    ]);
    setAddNewField({ isFieldEdit: true, isModelClose: false });
  };

  const handleModelClose = () => {
    if (!addNewField.isModelClose || addNewField.isFieldEdit) {
      setErrorMessage('You haven’t saved your data. Please click “Save”  or you’ll lose your changes.');
      setAddNewField({ isFieldEdit: false, isModelClose: true });
    } else {
      close();
    }
  };

  const handleSaveListItem = async () => {
    if (listError) {
      setErrorMessage(listError);
      return;
    }
    setErrorMessage('');

    const body = {
      new: newItems,
      editing: editingItems,
      deleting: deletingItems,
    };
    setLoading(true);
    if (column.typeKey === 'other' && editMode) {
      const updateColumns = state.columns.map((item) => {
        if (item.id === column.id) {
          const options = editListData.map((i) => {
            return {
              id: i?.id,
              label: i.label,
              value: i?.id || i?.label,
            };
          });

          return { ...item, options };
        }
        return item;
      });
      saveColumns(updateColumns);
    } else {
      if (editMode) {
        await groupsLocationsJobsCRUD(body, { type, title, parentId, levelId });
      } else {
        groupsLocationsJobsTemporaryCRUD(body, { type, title: editListTitle });
      }
    }
    setLoading(false);
    setAddNewField({ isFieldEdit: false, isModelClose: true });
    setEditListData([]);
    setDeletingItems([]);
    close();
  };
  return (
    <OCMSCustomeModel title={title} open={open} close={handleModelClose} footer={footer} width="447px">
      {errorMessage && (
        <Title
          style={{
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            margin: '0px',
            textAlign: 'left',
            color: 'red',
          }}
          level={4}
        >
          {errorMessage}
        </Title>
      )}
      <Container>
        <Title
          style={{
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '17px',
            margin: '0px 0px 16px 0px',
            textAlign: 'left',
            color: 'gray',
          }}
          level={4}
        >
          Column name: {editListTitle}
        </Title>
        {parentName && (
          <Title
            style={{
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '17px',
              margin: '0px 0px 16px 0px',
              textAlign: 'left',
              color: 'gray',
            }}
            level={4}
          >
            Parent {type}: {parentName}
          </Title>
        )}
        <Title
          style={{
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '17px',
            margin: '0px 0px 20px 0px',
            textAlign: 'left',
            color: 'gray',
          }}
          level={4}
        >
          List options:
        </Title>
        {editListData &&
          editListData.map((job, index) => (
            <InputContainer key={job?.id || job.key}>
              <Input
                style={{ width: '90%', height: '35px' }}
                key={job?.id || job?.key}
                placeholder={job.label}
                value={job.label}
                required
                onChange={(e) => {
                  handleInputChange(e.target.value, job);
                }}
              />
              
              <FontAwesomeIcon
                icon={faTrashCan}
                style={{ marginLeft: '16px', width: '20px', height: '20px', color: 'red', cursor: 'pointer' }}
                onClick={() => {
                  handleDeletListItem(job);
                }}
              />
            </InputContainer>
          ))}
        <div
          style={{ display: 'flex', alignItems: 'center', marginLeft: '32px', cursor: 'pointer', margin: '15px 4px' }}
          onClick={() => {
            handleAddListItem();
          }}
        >
          <FontAwesomeIcon
            icon={faCirclePlus}
            style={{ marginRight: '8px', width: '20px', height: '20px', color: '#0066B2' }}
          />
          <span>Add</span>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0px 40px' }}>
          <Button
            style={{
              backgroundColor: 'transparent',
              color: '#000000',
              border: 'none',
              borderRadius: '0px',
              borderBottom: '1px solid #000000',
              padding: '0px',
              margin: '0px',
              fontSize: '16',
              fontWeight: 500,
              lineHeight: '16px',
            }}
            onClick={handleModelClose}
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: !addNewField.isFieldEdit ? 'gray' : '#0066B2',
              color: '#FFFFFF',
              borderRadius: '16px',
              width: '100px',
              fontSize: '16',
              fontWeight: 500,
              lineHeight: '16px',
            }}
            onClick={handleSaveListItem}
          >
            Save
          </Button>
        </div>
      </Container>
      {loading && (
        <LoadingContainer>
          <Spin spinning={loading} fullscreen={true} />
        </LoadingContainer>
      )}
    </OCMSCustomeModel>
  );
}

const Container = styled.div`
  background-color: #fff;
  width: 100%;
  position: relative;
`;
const InputContainer = styled.div`
  background-color: #fff;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 8px 0px;
`;

const LoadingContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -24%;
  height: 124%;
  width: 100%;
  background-color: #8080804a;
`;

export default EditListModel;
