import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { Button } from 'antd';

import { ThemeProvider } from 'styled-components';
import { SubscriptionHeaderStyle } from '../../layout/Style';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { verifyUser } from '../../redux/authentication/actionCreator';
import useNotificationController from '../../utility/useNotificationController';

const { theme } = require('../../config/theme/themeVariables');

const SubscriptionHeader = ({UserInfo}) => {
    const {
        contextHolder,
        openError
    } = useNotificationController();
      
    const [isFreeAccount, setIsFreeAccount] = useState(false);
    const [isPaidAccount, setIsPaidAccount] = useState(false);
    const [isTrialAccount, setIsTrialAccount] = useState(false);
    const [userType, setUserType] = useState(false);
    const userTheme = JSON.parse(localStorage.getItem('userTheme'));
    const marked_for_delete = localStorage.getItem('marked_for_delete') === 'true';
    theme['user-theme'] = userTheme;
    theme['trialDays'] = UserInfo.remainingDays;


    const initialState = {
        error: false,
        messages: ""
    };
  
    const reducer = (state, action) => {
        switch (action.type) {
          case 'VERIFY_USER_ERR':
            return { ...state, error: true,messages: action.err };
          case 'CONVERT_TO_TRIAL_ERR':
            return { ...state, error: true,messages: action.err };
          case 'RESET':
            return state;
          default:
            return state;
        }
    };
  
    const [apiError, apiResponseCallback] = useReducer(reducer, initialState);
  
    const dispatch = useDispatch();
  
    const fetchData = useCallback(async () => {
        apiResponseCallback({ type: 'RESET' });
        dispatch(
            verifyUser({page: 'subscribe'}, (response) => {
              if (response.status === 200) {
                setUserType(response.data.userType);
                if (response.data.isPaidAccount)
                {
                  setIsPaidAccount(true);
                }
                else if (response.data.isTrialAccount && parseInt(response.data.remainingDays) > 0)
                {
                  setIsTrialAccount(true);
                }
                else if (response.data.isFreeAccount)
                {
                  setIsFreeAccount(true);
                }  
              } 
              else 
              {
                openError('topLeft', 'Error', response.err);
                apiResponseCallback(response);
              }
            }),
          );
    }, [dispatch, isFreeAccount, isPaidAccount, isTrialAccount, userType, UserInfo]);

    useEffect(() => {
        const retrieveData = async () => {
          await fetchData();
        };
        retrieveData().catch(console.error);
    }, []);

    const organizationalSlug = localStorage.getItem('organizationalSlug');
    
    return (
        <>
         {contextHolder}
         <ThemeProvider theme={theme}>
            <SubscriptionHeaderStyle>
                {!(localStorage.getItem('is_amazon') === 'true') && isTrialAccount && !isFreeAccount && !isPaidAccount && UserInfo.remainingDays > 0 ? (
                    <>
                        {window.innerWidth > 1600 ? (
                            <p><span
                                style={{
                                    width: "200px",
                                    display: "display",
                                    textAlign: "right"
                                }}>Days left in trial</span> <span className='trialdays'><label>{UserInfo.remainingDays}</label></span>
                                {userType === 1 && !marked_for_delete && (
                                    <Button 
                                        className='subscribe_now' 
                                        type="primary" 
                                        size="large"
                                        onClick={(e) => window.location.href=`/${organizationalSlug}/${isPaidAccount ? 'subscription/update-subscription' : 'subscribe-now'}`}
                                    >
                                        <Link to={`/${organizationalSlug}/${isPaidAccount ? 'subscription/update-subscription' : 'subscribe-now'}`}>
                                            Subscribe Now
                                        </Link>
                                    </Button>
                                )}
                            </p>
                        ) : (
                            <>
                            {window.innerWidth > 1490 ? (
                                <p>Remaining <span className='trialdays'><label>{UserInfo.remainingDays}</label></span>
                                    {!marked_for_delete ? (
                                        <Button 
                                            className='subscribe_now' 
                                            type="primary" 
                                            size="middle"
                                            onClick={(e) => window.location.href=`/${organizationalSlug}/${isPaidAccount ? 'subscription/update-subscription' : 'subscribe-now'}`}
                                        >
                                            <Link to={`/${organizationalSlug}/${isPaidAccount ? 'subscription/update-subscription' : 'subscribe-now'}`}>
                                                Subscribe Now
                                            </Link>
                                        </Button>
                                    ) : ``}
                                </p>
                            ) : (
                                <>
                                    {window.innerWidth > 1180 ? (
                                        <p>Trial <span className='trialdays' style={{padding: "12px 10px 12px"}}><label style={{fontSize: "14px"}}>{UserInfo.remainingDays}</label></span>
                                            {!marked_for_delete ? (
                                                <Button 
                                                    className='subscribe_now' 
                                                    type="primary" 
                                                    size="middle" 
                                                    style={{fontSize: "0.6rem"}}
                                                    onClick={(e) => window.location.href=`/${organizationalSlug}/${isPaidAccount ? 'subscription/update-subscription' : 'subscribe-now'}`}
                                                >
                                                    <Link to={`/${organizationalSlug}/${isPaidAccount ? 'subscription/update-subscription' : 'subscribe-now'}`}>
                                                        Subscribe Now
                                                    </Link>
                                                </Button>
                                            ) : ``}
                                        </p>
                                    ) : (
                                        <>
                                            <p>Trial <span className='trialdays' style={{padding: "12px 10px 12px"}}><label style={{fontSize: "14px"}}>{UserInfo.remainingDays}</label></span></p>
                                            {!marked_for_delete ? (
                                                <Button 
                                                    className='subscribe_now' 
                                                    type="primary" 
                                                    size="middle" 
                                                    style={{fontSize: "0.6rem"}}
                                                    onClick={(e) => window.location.href=`/${organizationalSlug}/${isPaidAccount ? 'subscription/update-subscription' : 'subscribe-now'}`}
                                                >
                                                    <Link to={`/${organizationalSlug}/${isPaidAccount ? 'subscription/update-subscription' : 'subscribe-now'}`}>
                                                        Subscribe Now
                                                    </Link>
                                                </Button>
                                            ) : ``}
                                        </>
                                    )}
                                </>
                            )}
                            </>
                        )}
                    </>
                ) : (
                    <>
                    {!(localStorage.getItem('is_amazon') === 'true') && (
                        !isFreeAccount ? '' : (
                            <p>
                                {!marked_for_delete ? (
                                    <Button 
                                        className='subscribe_now' 
                                        type="primary" 
                                        size="large"
                                        onClick={(e) => window.location.href=`/${organizationalSlug}/${isPaidAccount ? 'subscription/update-subscription' : 'subscribe-now'}`}
                                    >
                                        <Link to={`/${organizationalSlug}/subscribe-now`}>
                                            Subscribe Now
                                        </Link>
                                    </Button>
                                ) : ``}
                            </p>
                        )
                    )}
                    </>
                )}
            </SubscriptionHeaderStyle>
        </ThemeProvider>
        </>
    );
};

export default SubscriptionHeader;