
export const NotificationType = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning'
};
const NotificationpPlacement = {
  TOP: 'top',
  TOP_LEFT: 'topLeft',
  TOP_RIGHT: 'topRight',
  BOTTOM: 'bottom',
  BOTTOM_LEFT: 'bottomLeft',
  BOTTOM_RIGHT: 'bottomRight'
};

export const initialNotificationsState = {
  message: '',
  description: '',
  placement: NotificationpPlacement.BOTTOM_RIGHT,
  type: null
};

export const notificationsReducerTypes = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  INFO: 'INFO',
  WARNING: 'WARNING',
  HIDE: 'HIDE'
}