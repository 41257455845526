import React, { RefObject, forwardRef, useCallback, useState, useContext, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { VariableSizeList as List, ListOnItemsRenderedProps, ListOnScrollProps } from 'react-window';
import { GanttContext } from '../../Gantt/GanttContext';
import { useForwardRef } from '../../../hooks';
import { getScaleItems } from '../../../utils';
import { GanttDimensions } from '../../../enums';
import { GanttConsts, GanttDimensionsSettings } from '../../../constants';
import './Scale.css';
import { GanttItemDataType } from '../../../types';

interface ScaleProps {
  width: number;
  wrapRef: RefObject<HTMLDivElement>;
  data: GanttItemDataType[];
}

const Scale = forwardRef<List<number[]>, ScaleProps>(({ width, wrapRef, data }, ref) => {
  const { scaleDates, setScaleDates, setScaleRenderState, settings } = useContext(GanttContext);
  const listRef = useForwardRef<List<number[]>>(ref);
  const outerRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);

  const getItemSize = useCallback(
    (index: number) => {
      const date = dayjs.unix(scaleDates[index]);

      if (settings.dimension === GanttDimensions.DAY) {
        const days = date.daysInMonth();

        return days * settings.stepWidth;
      }
      if (settings.dimension === GanttDimensions.MONTH) {
        const days = date.daysInMonth();

        return days * settings.stepWidth;
      }
      if (settings.dimension === GanttDimensions.SEVENDAYS) {
        const days = 7;

        return days * settings.stepWidth;
      }

      return settings.scaleStepItems * settings.stepWidth;
    },
    [scaleDates, settings.dimension, settings.scaleStepItems, settings.stepWidth],
  );
  const getStartDateEndDate = () => {
    let startDate = dayjs();
    let endDate = dayjs();
    data.map((item) => {
      if (item.data?.startDate && startDate > dayjs(item.data?.startDate)) {
        startDate = dayjs(item.data?.startDate);
      }
      if (item.data?.endDate && endDate < dayjs(item.data?.endDate)) {
        endDate = dayjs(item.data?.endDate);
      }
    });
    startDate = startDate.subtract(1, 'month');
    // endDate = endDate.add(1,"month");
    return [startDate, endDate];
  };

  const onScroll = useCallback(
    ({ scrollOffset }: ListOnScrollProps) => {
      const dates = getStartDateEndDate();
      const unitOfTime = GanttDimensionsSettings[settings.dimension].unitOfTime;
      let newDate = dayjs.unix(scaleDates[0]).subtract(1, unitOfTime);
      if (settings.dimension == GanttDimensions.SEVENDAYS) {
        newDate = dayjs.unix(scaleDates[0]).subtract(7, 'days');
      }
      if (scrollOffset < GanttConsts.MIN_SCROLL_OFFSET) {
        let newItemWidth = settings.scaleStepItems * settings.stepWidth;

        if (settings.dimension === GanttDimensions.DAY) {
          const days = newDate.daysInMonth();

          newItemWidth = days * settings.stepWidth;
        }
        if (settings.dimension === GanttDimensions.MONTH) {
          const days = newDate.daysInMonth();
          newItemWidth = days * settings.stepWidth;
        }
        if (settings.dimension === GanttDimensions.SEVENDAYS) {
          const days = 7;

          newItemWidth = days * settings.stepWidth;
        }
        setScaleDates([newDate.unix(), ...scaleDates]);
        if (dates[1] > newDate && newDate > dates[0]) {
          wrapRef.current?.scrollTo({ left: scrollOffset + newItemWidth });
          listRef?.current?.resetAfterIndex(0);
        }
      }

      if (
        (outerRef.current?.scrollWidth || 0) -
          (outerRef.current?.clientWidth || 0) -
          scrollOffset -
          GanttConsts.TREE_WIDTH <
        GanttConsts.MIN_SCROLL_OFFSET
      ) {
        let dateNow = dayjs.unix(scaleDates[scaleDates.length - 1]).add(1, unitOfTime);
        if (dates[1] > dateNow && dateNow > dates[0]) {
          setScaleDates([
            ...scaleDates,
            dayjs
              .unix(scaleDates[scaleDates.length - 1])
              .add(1, unitOfTime)
              .unix(),
          ]);
          listRef?.current?.resetAfterIndex(0);
        }
      }
    },
    [listRef, scaleDates, setScaleDates, settings.dimension, settings.scaleStepItems, settings.stepWidth, wrapRef],
  );

  const onItemsRendered = useCallback(
    (renderedState: ListOnItemsRenderedProps) => {
      setScaleRenderState(renderedState);
    },
    [setScaleRenderState],
  );

  useEffect(() => {
    listRef?.current?.resetAfterIndex(0);
  }, [listRef, settings.dimension]);

  return (
    <div className="gantt-scale-wrap">
      <List
        className="gantt-scale-list"
        layout="horizontal"
        width={width}
        height={GanttConsts.HEADER_HEIGHT}
        itemCount={scaleDates.length}
        itemSize={getItemSize}
        itemData={scaleDates}
        ref={listRef}
        outerRef={outerRef}
        innerRef={innerRef}
        initialScrollOffset={settings.initialScrollOffset}
        onScroll={onScroll}
        onItemsRendered={onItemsRendered}
      >
        {({ style, index, data }) => {
          return (
            <div className="gantt-scale-item" style={style}>
              <div
                className="gantt-scale-title"
                style={{
                  height:
                    settings.dimension != GanttDimensions.MONTH && settings.dimension != GanttDimensions.SEVENDAYS
                      ? '25px'
                      : '50px',
                }}
              >
                {dayjs
                  .unix(data[index])
                  .format(
                    settings.dimension === GanttDimensions.DAY || settings.dimension === GanttDimensions.MONTH
                      ? 'MMMM, YYYY'
                      : 'D MMM, YY',
                  )}
                {settings.dimension === GanttDimensions.SEVENDAYS
                  ? ' - ' + dayjs.unix(data[index]).add(6, 'days').format('D MMM, YY')
                  : ''}
              </div>
              {settings.dimension != GanttDimensions.MONTH && settings.dimension != GanttDimensions.SEVENDAYS ? (
                <div className="gantt-scale-steps">
                  {getScaleItems(settings.dimension, data[index]).map((item) => (
                    <div className="gantt-scale-step" key={item} style={{ width: settings.stepWidth }}>
                      {item}
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>
          );
        }}
      </List>
    </div>
  );
});

Scale.displayName = 'Scale';

export default Scale;
