import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Form, Input, Switch, Table, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

const {Title, Text} = Typography;

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.error('Save failed:', errInfo);
      handleSave({
        ...record,
      }, true);
    }
  };

  const requiredFields = ['firstName','lastName']; //
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: requiredFields.includes(dataIndex) ? true : false,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
            paddingRight: 24,
            width: "100%",
            height: "40px",
            border: "1px solid #ddd",
            display: 'flex',
            alignItems: "center",
            paddingLeft: "10px",
            textOverflow: "ellipsis",
            overflow: "auto"
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

const TargetAudienceManual = (props) => {
  const [count, setCount] = useState(4);
  const [executeData, setExecuteData] = useState(false);
  const [onlyIndividual, setOnlyIndividual] = useState(true);
  const [audienceColumns, setAudienceColumns] = useState([]);
  
  const handleDelete = (key) => {
    const newData = props.dataSource.filter((item) => item.key !== key);
    props.setDataSource(newData);
  };

  const defaultColumns = props.defaultColumns;

  const handleAdd = () => {
    let tmp = {};
    tmp['key'] = count;
    props.defaultDataKeys.map(item => {
        tmp[item] = '';
    });
    const newData = tmp;
    props.setDataSource([...props.dataSource, newData]);
    setCount(count + 1);
  };

  const handleSave = (row, hasError) => {
    const orginalData = [...props.dataSource];
    const newData = [...props.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    props.setDataSource(newData);
    props.setDoneTheme(!hasError);
  };

  const [tableComponents, setTableComponents] = useState([]);

  const setAudienceColumnsCells = (checked) => {
    let requiredFields = [];
    if (checked)
    {
        requiredFields = ['firstName','lastName'];   
    }
    const columns = [];
    defaultColumns.map((col) => {
      if (!requiredFields.includes(col.dataIndex))
      {
        if (!col.editable) {
          columns.push(col);
        }
        columns.push({
          ...col,
          onCell: (record) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave,
          }),
        });
      }
    });
    setAudienceColumns(columns);
  }
  
  useEffect(() => {
    if (onlyIndividual) {
      const columns = defaultColumns.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave,
          }),
        };
      });
      
      setAudienceColumns(columns);
      setExecuteData(true);
    }
  }, [onlyIndividual]);


  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  let notRequiredFields = ['firstName','lastName'];
  const groupColumns = [];
  defaultColumns.map((col) => {
    if (!notRequiredFields.includes(col.dataIndex))
    {
      if (!col.editable) {
        groupColumns.push(col);
      }
      groupColumns.push({
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave,
        }),
      });
    }
  });

  const components = ({
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  });

  const userTheme = JSON.parse(localStorage.getItem('userTheme'));

  return (
    <div>
        <div className='title-input'>
            <Title level={2}>Add your target audiences</Title>
        </div>  
        <div className='title-group-start' style={{position: "relative"}}>
          <Text level={3} className='text-group-start'>Enter the details of your target audiences. </Text>
          <div className='individual-switch'>
            <Switch 
              className="switch-class" 
              checkedChildren="Individuals" 
              unCheckedChildren="Group" 
              defaultChecked 
              onChange={(checked, e) => {
                  setOnlyIndividual(checked);
                  props.setOnlyIndividual(checked);
                  //setAudienceColumnsCells(checked);
              }}
            />
          </div>
        </div>
        {executeData ? (
            <>
              {onlyIndividual ? (
                <Table
                    tableLayout='fixed'
                    components={components}
                    rowClassName={() => 'editable-row'}
                    bordered
                    pagination={false}
                    dataSource={props.dataSource}
                    columns={columns}
                    scroll={{
                        x: 1500,
                        y: 400,
                    }}
                />
              ) : (
                <Table
                    tableLayout='fixed'
                    components={components}
                    rowClassName={() => 'editable-row'}
                    bordered
                    pagination={false}
                    dataSource={props.dataSource}
                    columns={groupColumns}
                    scroll={{
                        x: 1500,
                        y: 400,
                    }}
                />
              )}
              <Button shape={'circle'} icon={
                  <FontAwesomeIcon icon={regular("plus")} style={{color: `${userTheme.Color}`}} />
                  } size='large' 
                  onClick={handleAdd}
                  type={'ocm-default'}
                  style={{
                      marginLeft: 10,
                      marginTop: 16,
                      marginBottom: 16,
                  }}
              />
            </>
        ) : (
          <div className="loader" style={{display: 'flex', alignItems: 'center', justifyContent: "center", marginBottom: "40px"}}>
            <FontAwesomeIcon icon={duotone("spinner-third")} spin size="2xl" style={{"--fa-primary-color": "#3A3540", "--fa-secondary-color": "#3A3540", "padding": "20px 10px"}} />
          </div>
        )}
    </div>
  );
};
export default TargetAudienceManual;