import React, { useEffect, useState, useReducer, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CMSPageContentWrapper, PageContentWrapper } from "./LibrarySection.styles";
import { DataService } from "../../config/dataService/dataService";
import { Main } from "../styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useDispatch } from "react-redux";
import { fetchCMSPageContent } from "../../redux/cms/actionCreator";
import useNotificationController from "../../utility/useNotificationController";
import { Helmet } from 'react-helmet';
import { Alert, Col, Row } from "antd";
import CMSPageHeader from "./CMSPageHeader";
import '@fortawesome/fontawesome-pro/css/all.min.css';
import BackToListButton from "./BackToListButton";
import CMSbreadcrumb from "./CMSbreadcrumb";

const CMSPageContent = ({type, setOpenKeys}) => {
  const {
    contextHolder,
    openError,
  } = useNotificationController();

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const organizationalSlug = localStorage.getItem('organizationalSlug');

  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [loadedWithError, setLoadedWithError] = useState(false);
  const [pageContent, setPageContent] = useState({});
  const [linkContent, setLinkContent] = useState("");
  
  const initialState = {
      error: false,
      messages: ""
  };
  const reducer = (state, action) => {
      switch (action.type) {
        case 'VERIFY_USER_ERR':
          return { ...state, error: true,messages: action.err };
        case 'RESET':
          return state;
        default:
          return state;
      }
  };
  const [apiError, apiResponseCallback] = useReducer(reducer, initialState);
  const dispatch = useDispatch();

  const fetchContent = useCallback(async (id, slug, isForTraining) => {
    setLoading(true);
    apiResponseCallback({ type: 'RESET' });
      dispatch(
          fetchCMSPageContent({id: id, slug: slug, isForTraining: isForTraining},(response) => {
            if (response.status === 200) {
              const redirect = response.data.redirect;
              const hasLibraryLicense = response.data.hasLibraryLicense;
              if (redirect !== null && redirect !== undefined)
              {
                navigate(`/${organizationalSlug}/main-dashboard`);
              }
              else if (!isForTraining && hasLibraryLicense !== null && hasLibraryLicense !== undefined)
              {
                if (!hasLibraryLicense)
                {
                  navigate(`/${organizationalSlug}/change-management-library`);
                }
              }
              if (response.data.page === null)
              {
                setLoaded(true);
                setLoadedWithError(true);
              }
              else if (response.data.page === undefined)
              {
                setLoaded(true);
                setLoadedWithError(true);
              }
              else
              {
                setLoading(false);
                setPageContent(response.data.page);
                setLoaded(true);
                setLoadedWithError(false);
              }
            } 
            else 
            {
              setLoaded(true);
              setLoadedWithError(true);
              openError('topLeft', 'Error', response.err);
              apiResponseCallback(response);
            }
          }),
        );
  }, [
    dispatch, 
    pageContent, 
    loaded,
    loadedWithError,
    linkContent,
    loading
  ]);

  useEffect(() => {
    const retrieveContent = async (id, slug, isForTraining) => {
      await fetchContent(id, slug, isForTraining);
    };
    
    window.scrollTo(0, 0);
    const url = window.location.href;
    const paths = url.split("/");
    let isForTraining = false;
    if (paths.includes("training"))
    {
      isForTraining = true;
    }
    
    let contentSlug = paths[paths.length - 1];
    if (contentSlug.toString().trim() === "")
    {
      contentSlug = paths[paths.length - 2];
    }
    if (contentSlug.indexOf("-") === -1)
    {
      navigate(`/${organizationalSlug}/main-dashboard`);
    }
    else
    {
      const slugs = contentSlug.split("-");
      if (slugs.length === 0)
      {
        navigate(`/${organizationalSlug}/main-dashboard`);
      }
      else if (slugs.length === 1)
      {
        retrieveContent(0, contentSlug, isForTraining);
      }
      else
      {
        if (contentSlug.indexOf("#") !== -1)
        {
          const slugs = contentSlug.split("#");
          contentSlug = slugs[0];
          setLinkContent(slugs[1]);
        }
        retrieveContent(0, contentSlug, isForTraining);
      }
    }
    if (pathname.indexOf('main-dashboard') === -1)
    {
      DataService.post("/users/save-visits", { url });
    }  
    if (typeof setOpenKeys === 'function')
    {
      const slug = type === "cms" ? 'change-management-library' : 'ocm-training';
      setOpenKeys([slug]);
    }
  }, [pathname]);

  const onContentLoaded = () => {
    if (linkContent !== null && linkContent.toString().trim() !== '')
    {
      window.scrollTo({
        behavior: 'smooth',
        top:
          document.querySelector(`#${linkContent}`).getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          100,
      });
    }
    // Add your logic here
  };

  // Use useEffect to trigger the function when data is loaded
  useEffect(() => {
    if (!loading && pageContent !== null) {
      onContentLoaded();
    }
    if (typeof setOpenKeys === 'function')
    {
      const slug = type === "cms" ? 'change-management-library' : 'ocm-training';
      setOpenKeys([slug]);
    }
  }, [loading, pageContent]);

  const userTheme = JSON.parse(localStorage.getItem('userTheme'));

  return (
    <Main>
      {contextHolder}
      {!loaded ? (
        <div className="loader" key={0} style={{ padding: "10px", display: "flex", height: "200px", alignItems: "center", justifyContent: "center" }}>
          <FontAwesomeIcon icon={duotone("spinner-third")} spin size="2xl" style={{ "--fa-primary-color": `${userTheme.mainHighlightColor}`, "--fa-secondary-color": `${userTheme.mainHighlightColor}`, marginRight: "20px", fontSize: "50px" }} />
          <span style={{fontSize: "20px", color: `${userTheme.mainHighlightColor}`}}>Loading, please wait...</span>
        </div>
      ) : (
        loadedWithError ? (
          <Row gutter={[16, 16]} style={{marginRight: "0px"}}>
            <Col span={24} style={{padding: "20px"}}>
              <Alert
                message="Error Loading Post"
                description="There is an error occur while loading Change Management Library Content"
                type="error"
                closable={false}
              />
            </Col>
          </Row>
        ) : (
          <>
            <CMSbreadcrumb type={type} title={pageContent.title} />
            <Row gutter={[16, 16]} style={{marginRight: "0px"}}>
              <Col span={24} style={{paddingLeft: "50px", paddingRight: "50px"}}>
                <CMSPageContentWrapper>
                  <CMSPageHeader contentTitle={pageContent.title} />
                  <PageContentWrapper>
                    {pageContent.goToListTag !== null && pageContent.goToListTag.toString().trim() !== '' && (
                      <BackToListButton backtoButton={pageContent.goToListTag} />
                    )}
                    <Helmet>
                      {pageContent.metaTitle !== null && pageContent.metaTitle.toString().trim() !== '' && (
                        <title>{pageContent.metaTitle}</title>
                      )}
                      {pageContent.metaDescription !== null && pageContent.metaDescription.toString().trim() !== '' && (
                        <meta name="description" content={pageContent.metaDescription} />
                      )}
                      {pageContent.metaKeyword !== null && pageContent.metaKeyword.toString().trim() !== '' && (
                        <meta name="keywords" content={pageContent.metaKeyword} />
                      )}
                      {pageContent.metaTitle !== null && pageContent.metaTitle.toString().trim() !== '' && (
                        <meta property="og:title" content={pageContent.metaTitle} />
                      )}
                      {pageContent.metaDescription !== null && pageContent.metaDescription.toString().trim() !== '' && (
                        <meta property="og:description" content={pageContent.metaDescription} />
                      )}
                      <meta property="og:type" content="website" />
                    </Helmet>
                    <div dangerouslySetInnerHTML={{ __html: `${pageContent.content}` }} />
                  </PageContentWrapper>
                </CMSPageContentWrapper>
              </Col>
            </Row>
          </>
        )
      )}
    </Main>
  );
}

export default CMSPageContent;