import React, { useRef, useEffect, useState, useLayoutEffect } from 'react';

import { Chart, BarController, BarElement, LinearScale, TimeScale, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-moment';
import 'chart.js/auto';
// Import utilities
import moment from 'moment';
Chart.register(BarController, BarElement, LinearScale, TimeScale, Tooltip, Legend);

const RangeBarChart = ({ data, width, height, variant }) => {

  const [chart, setChart] = useState(null);
  const canvas5 = useRef(null);
  const canvasYAxis = useRef(null);
  const canvasContainerRef = useRef(null)
  const scrollContainerRef = useRef(null)

  const ranges = data?.data ? data?.data?.flat() : []
  let min = Math.min(...ranges) || 0

  if (variant === 'week') {
    const date = moment(min);
    if (date.day() === 0) {
      date.subtract(1, 'week').startOf('week');
    } else {
      date.startOf('week');
    }

    min = date.valueOf()
  } else if (variant === 'month') {
    const date = moment(min);
    const firstDayOfMonth = date.startOf('month');
    min = firstDayOfMonth.valueOf()
  }

  let max = Math.max(...ranges) || 0

  const days = variant === 'week' ? 7 : 30

  if (ranges.length) {
    const minRange = moment(min)
    const temp = minRange.add(days, 'days').valueOf()
    max = max > temp ? max : temp
  }

  const customTicks = [];
  let currentDate = moment(min);

  while (currentDate.isSameOrBefore(moment(max))) {
    customTicks.push({ value: currentDate.valueOf(), label: currentDate.format(variant === 'week' ? 'DD-MM-YYYY' : 'MMM - YYYY') });
    currentDate.add(1, variant);
    currentDate.startOf(variant);
  }

  useLayoutEffect(() => {
    if (scrollContainerRef.current) {
      const parentWidth = scrollContainerRef.current.parentElement.clientWidth;

      const width = parentWidth === 0 ? '100%' : `${parentWidth}px`

      scrollContainerRef.current.style.width = width;
    }

    let totalWidth = '100%'
    if (canvasContainerRef.current) {
      if (customTicks.length > 7) {
        totalWidth = `${customTicks.length * 100}px`;
      }
      canvasContainerRef.current.style.width = totalWidth;
    }
  }, [customTicks.length, scrollContainerRef, canvasContainerRef])

  useEffect(() => {
    const ctx = canvas5.current;

    const chartData = {
      labels: data.labels,
      datasets: [
        {
          backgroundColor: '#00B0F0',
          maxBarThickness: 30,
          borderRadius: 10,
          data: data.data,
          borderSkipped: false,
        },
      ],
    }

    const options = {
      maintainAspectRatio: false,
      indexAxis: 'y',
      layout: {
        padding: {
          bottom: 3.5
        },
      },
      scales: {
        y: {
          ticks: {
            display: false
          },
          grid: {
            drawTicks: false
          }
        },

        x: {
          afterTickToLabelConversion: (ctx) => {
            ctx.ticks = [];
            ctx.ticks = customTicks;
          },
          min,
          max,
          position: 'top',

          border: {
            display: false,
          },
          grid: {
            color: '#C9F1FF',
            drawBorder: true,
          },
        },
      },
      plugins: {
        datalabels: false,
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
          callbacks: {
            title: () => false, // Disable tooltip title
            label: (context) => {
              return `${moment(context.parsed._custom.barStart).format('ddd DD MMM YYYY hh:mm A')} to ${moment(
                context.parsed._custom.barEnd,
              ).format('ddd DD MMM YYYY hh:mm A')}`;
            },
          },
        },
      },
    }

    // eslint-disable-next-line no-unused-vars
    const newChart = new Chart(ctx, {
      type: 'bar',
      data: chartData,
      options,
    });
    setChart(newChart);


    const ctxYAxis = canvasYAxis.current;

    const yAxisData = {
      labels: data.labels,
      datasets: [{
        data: data.data,
      }],
    }

    const yAxisOptions = {
      maintainAspectRatio: false,
      indexAxis: 'y',
      layout: {
        padding: {
          top: 28.5
        },
      },
      scales: {
        x: {
          ticks: {
            dislay: false
          },
          grid: {
            drawTicks: false
          }
        },
        y: {
          beginAtZero: true,
          afterFit: (ctx) => {
            ctx.width = 150;
          }
        }
      },
      plugins: {
        datalabels: false,
        legend: {
          display: false,
        }
      },
    }

    const yAxisChart = new Chart(ctxYAxis, {
      type: 'bar',
      data: yAxisData,
      options: yAxisOptions
    })

    return () => {
      newChart.destroy()
      yAxisChart.destroy()
    };
  }, [data.labels, variant]);

  return <div style={{ width: '100%', height: '100%', display: 'flex' }}>
    <div style={{ width: '145px' }}>
      <canvas ref={canvasYAxis}></canvas>
    </div>
    <div ref={scrollContainerRef} style={{ overflow: 'auto' }}>
      <div style={{ height: '100%', width: '100%' }} ref={canvasContainerRef}>
        <canvas ref={canvas5}></canvas>
      </div>
    </div>

  </div>;
};

export default RangeBarChart;
