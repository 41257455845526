import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Divider, Flex, Form, Select, Spin, Tag, Tooltip, Typography } from 'antd';
import './select.css';
import { DataService } from '../../config/dataService/dataService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';

const { Option, OptGroup } = Select;

const OCMSelectFilter = ({
    type = '',
    label = '',
    size = '',
    className = '',
    placeholder = '',
    filterVariable="",
    mode = '',
    popupClassName = '',
    hasMoreDataValue = '',
    values = [],
    initialOptions = [],
    initialValue = [],
    key = '',
    axiosURL='',
    axiosVariable='',
    optionValue = '',
    maxLength = 0,
    maxTagCount = 2,
    lengthForSearch=2,
    assessmentId=0,
    autoFocus=false,
    required=false,
    disabled=false,
    isAssessment=false,
    hasFeedback=false,
    allowClear=false,
    showSearch=true,
    rules={},
    styles={},
    dropdownStyle={ },
    onChange = () => {},
    setSelectedAllProject = () => {},
    setFilterProject = () => {},
    onFocus = () => {},
    onBlur = () => {},
    onPressEnter = () => {},
    onKeyDown = () => {}
}) => {

    const [selectedItems, setSelectedItems] = useState([]);
    const [currentProjectIds, setCurrentProjectIds] = useState(values.length > 0 ? values : []);
    const [remainingProjectIds, setRemainingProjectIds] = useState(values.length > 0 ? values : []);
    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [hasMoreOptions, setHasMoreOptions] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [loadedOptions, setLoadedOptions] = useState(initialOptions.length > 0 ? true : false);
    const [isFiltering, setIsFiltering] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [isFirstOpen, setIsFirstOpen] = useState(true);

    const [page, setPage] = useState(1);
    const [countOptions, setCountOptions] = useState(0);
    const [options, setOptions] = useState(initialOptions || []);
    const [filters, setFilters] = useState("");
    const [filtersString, setFiltersString] = useState("");
    
    const noFoundContent = (
        <Flex>
          <Flex class="ant-empty ant-empty-normal ant-empty-small" style={{width: "100%"}}>
            <Flex class="ant-empty-image" style={{width: "100%", margin: "auto", textAlign: "center"}}>
              <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg"><title>Simple Empty</title><g transform="translate(0 1)" fill="none" fill-rule="evenodd"><ellipse fill="#f5f5f5" cx="32" cy="33" rx="32" ry="7"></ellipse><g fill-rule="nonzero" stroke="#d9d9d9"><path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#fafafa"></path></g></g></svg>
            </Flex>
            <Flex class="ant-empty-description" style={{textAlign: "center"}}>No {label} data found</Flex>
          </Flex>
        </Flex>
    );

    const loadingProject = (
        <Flex style={{minHeight: 200, alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
            <Spin size="large" />
            <Typography.Text style={{paddingTop: 10, fontSize: 20}}>{isSearching ? 'Searching' : 'Loading'} {label}</Typography.Text>
        </Flex>
    );
    
    const suffixIcon = (
        isSearching ? <Spin size="small" /> : 
            loading ? (dropdownVisible ? <FontAwesomeIcon
                icon={duotone('magnifying-glass')}
                size="2x"
                style={{ '--fa-primary-color': '#0066B2', '--fa-secondary-color': '#0066B2', fontSize: 18 }}
            /> : <FontAwesomeIcon
                    icon={duotone('angle-double-down')}
                    size="2x"
                    style={{ '--fa-primary-color': '#0066B2', '--fa-secondary-color': '#0066B2', fontSize: 18 }}
                />
            ) : <FontAwesomeIcon
                icon={duotone('angle-double-down')}
                size="2x"
                style={{ '--fa-primary-color': '#0066B2', '--fa-secondary-color': '#0066B2', fontSize: 18 }}
            />
    );
    
    const loadingProjectPlaceHolder = (
        loadingData ? <><Spin size="small" style={{marginRight: 10}} /> <Typography.Text style={{paddingTop: 10, fontSize: 16}}>Loading {label}</Typography.Text></> : placeholder
    );

    const loadOptions = async (loaded = false, pageToLoad = 1, filterString = '') => {
        setLoading(true);
        try {
            const request = {currentProjectIds: currentProjectIds, remainingProjectIds: remainingProjectIds, page: pageToLoad, [filterVariable]: filterString}
            const response = await DataService.post(axiosURL, request);
            if (response.status === 200) {
                const optionData = response.data[axiosVariable];
                const hasMoreData = response.data[hasMoreDataValue];
                setHasMoreOptions(hasMoreData);
                setPage(pageToLoad + 1);
                setCountOptions(response.data.count);
                setRemainingProjectIds(response.data.remaining);

                const currentOptions = optionData.map((item) => ({
                    value: item.id,
                    label: item[optionValue],
                }));

                let axiosOptions = [];
                if (filterString.toString().toLowerCase().trim().length > 0)
                {
                    setOptions((prevOptions) => ([
                        ...prevOptions,
                        ...currentOptions
                    ]));
                    if (response.data.currentSelectedIds.length > 0)
                    {
                        setSelectedItems((prevSelectedOptions) => ([
                            ...prevSelectedOptions,
                            ...response.data.currentSelectedIds
                        ]));
                        onChange(response.data.currentSelectedIds);
                        setLoadingData(false);
                    }
                    axiosOptions = currentOptions;
                    setIsSearching(false);
                }
                else
                {
                    if (isFiltering && filterString.toString().toLowerCase().trim().length === 0)
                    {
                        setOptions((prevOptions) => ([
                            ...prevOptions,
                            ...currentOptions
                        ]));
                        if (response.data.currentSelectedIds.length > 0)
                        {
                            setSelectedItems((prevSelectedOptions) => ([
                                ...prevSelectedOptions,
                                ...response.data.currentSelectedIds
                            ]));
                            onChange(response.data.currentSelectedIds);
                            setLoadingData(false);
                        }
                        axiosOptions = currentOptions;
                        setIsSearching(false);
                    }
                    else
                    {
                        if (!loadedOptions && loaded)
                        {
                            if (currentOptions.length === 0 && optionData.length !== 0)
                            {
                                for(var ind in optionData) 
                                {
                                    const item = optionData[ind];
                                    currentOptions.push({
                                        value: item.id,
                                        label: item[optionValue],
                                    });
                                }
                            }
                            setOptions(currentOptions);
                            setIsSearching(false);
                            axiosOptions = currentOptions;
                            if (response.data.currentSelectedIds.length > 0)
                            {
                                setSelectedItems((prevSelectedOptions) => ([
                                    ...prevSelectedOptions,
                                    ...response.data.currentSelectedIds
                                ]));
                                onChange(response.data.currentSelectedIds);
                                setLoadingData(false);
                            }
                        }
                        else
                        {
                            setOptions((prevOptions) => ([
                                ...prevOptions,
                                ...currentOptions
                            ]));
                            if (response.data.currentSelectedIds.length > 0)
                            {
                                setSelectedItems((prevSelectedOptions) => ([
                                    ...prevSelectedOptions,
                                    ...response.data.currentSelectedIds
                                ]));
                                onChange(response.data.currentSelectedIds);
                                setLoadingData(false);
                            }
                            setIsSearching(false);
                            axiosOptions = [...options, ...currentOptions];
                        }
                    }
                }
    
                setLoadedOptions(loaded);
    
                if (filterString.toString().toLowerCase().trim().length > 0)
                {
                    setIsFiltering(true);
                    setFilters(filterString);
                }
                else
                {
                    setIsFiltering(false);
                    setFilters('');
                }
                if (selectAll[axiosVariable])
                {
                    //Already selected so all variables much auto selected
                    const allValues = axiosOptions.map(option => option.value);
                    setSelectedItems(allValues);
                    onChange(allValues);
                }
                //setDataFromFilter(optionData, dataForFilter);
          }
        } catch (error) {
          console.error('Error loading options:', error);
        }
        setLoadingMore(false);
        setLoading(false);
    };

    const handleScroll = async (event) => {
        if (!hasMoreOptions) 
        {
          event.preventDefault();
          event.stopPropagation();
        }
        else if (hasMoreOptions)
        {
            const { target } = event;
            const scrollThreshold = 20; // Define a threshold for triggering load
          
            if ( target.scrollTop + target.offsetHeight >= target.scrollHeight - scrollThreshold && !loading) 
            {
                if (!loading)
                {
                    setLoadingMore(true);
                    await loadOptions(true, page, filters); // Load more data based on the current page
                }
            }
        }
    };

    useEffect(() => {
        const retrieveData = async () => {
          if (!loading && currentProjectIds.length > 0)
          {
            setLoadingData(true);
            await loadOptions(true, 1, '');
          }
        };
        retrieveData().catch(console.error);
    }, []);

    useEffect(() => {
        if (dropdownVisible && isFirstOpen && loadedOptions) {
            setIsFirstOpen(false);
            const dropdown = document.querySelector(".ant-select-dropdown .rc-virtual-list");
            if (dropdown) {
                dropdown.scrollTop = 0;
            }
        }
    }, [dropdownVisible, isFirstOpen, loadedOptions]);

    const onInputKeyDown = (event) => {
        if (event.key === "Enter")
        {
            event.preventDefault();
            event.stopPropagation();
            searchData(event.target.value);
        }
        else if (event.key === "Escape")
        {
            event.preventDefault();
            event.stopPropagation();
            setDropdownVisible(false); 
        }
    }

    const searchData = (value) => {
        if (!loading)
        {
            setRemainingProjectIds(currentProjectIds);
            setIsSearching(true);
            setTimeout(async() => {
                setOptions([]);
                setSelectedItems([]);
                await loadOptions(true, 1, value.toString().toLowerCase().trim());
            }, 300);
            setFilterProject(value.toString().toLowerCase().trim());
            setFiltersString(value.toString().toLowerCase().trim());
            setDropdownVisible(true);
        }
    }

    const handleSearch = (value) => {
        if (!loading)
        {
            setRemainingProjectIds(currentProjectIds);
            setIsSearching(true);
            setTimeout(async() => {
                if (value.toString().toLowerCase().trim().length > 0 && value.toString().toLowerCase().trim().length % 3 === 0)
                {
                    setOptions([]);
                    setSelectedItems([]);
                    await loadOptions(true, 1, value.toString().toLowerCase().trim());
                }
                else if (value.toString().toLowerCase().trim().length === 0)
                {
                    setOptions([]);
                    setSelectedItems([]);
                    await loadOptions(true, 1);
                }
            }, 300);
            console.log(selectedItems)
            setFilterProject(value.toString().toLowerCase().trim());
            setFiltersString(value.toString().toLowerCase().trim());
            setDropdownVisible(true);
        }
    }

    const handleSelectAll = () => {
        const allValues = options.map(option => option.value);
        setSelectedItems(allValues);
        onChange(allValues);
        setSelectAll(true);
        setSelectedAllProject(true);
    };

    const handleClear = () => {
        onChange([]);
        setSelectAll(false);
        setSelectedAllProject(false);
        setSelectedItems([]);
    };

    const handleCheckboxChange = (optionValue, checkedValue) => {
        setSelectAll(false);
        const newValue = checkedValue ? [...selectedItems, optionValue] : selectedItems.filter(v => v !== optionValue);
        setSelectedItems(newValue);
        onChange(newValue);
    };

    const checkedProject = (optionValue) => {
        const index = selectedItems.findIndex((projectId) => projectId === optionValue);
        let checkedValue = false;
        if (index === -1)
        {
            checkedValue = true;
        }
        setSelectAll(false);
        const newValue = checkedValue ? [...selectedItems, optionValue] : selectedItems.filter(v => v !== optionValue);
        setSelectedItems(newValue);
        onChange(newValue);
    };

    const tagRender = ({label, value, closable, onClose}) => {
        if (!label)
        {
            return <></>;
        }
        const handleTagClose = (e) => {
            const updatedSelectedItems = selectedItems.filter((item) => item !== value);
            setSelectedItems(updatedSelectedItems);
            onClose(); 
            setDropdownVisible(true);
        };

        const onPreventMouseDown = (event) => {
            event.preventDefault();
            event.stopPropagation();
        };
        if (typeof value === 'string' && value.indexOf('filtersString') !== -1 && !dropdownVisible)
        {
            return <Typography.Text style={{paddingLeft: 10, fontSize: 16}}>{value.replace("filtersString", "")}</Typography.Text>;
        }
        else if (typeof value === 'string' && value.indexOf('filtersString') !== -1 && dropdownVisible)
        {
            return <Typography.Text style={{paddingLeft: 10, fontSize: 16}}></Typography.Text>;
        }
        return (
            <Tag
                color="blue"
                closable={closable}
                onMouseDown={onPreventMouseDown}
                onClose={handleTagClose}
                style={{ marginRight: 5 }}
            >
                {typeof label === "object" && label.length === 2 ? label[1] : label}
            </Tag>
        );
    };

    return (
        <Form.Item 
            label={label} 
            size={size}
            placeholder={placeholder}
            rules={rules}
            className={className || ''}
            required={required}
            disabled={disabled}
            hasFeedback={hasFeedback}
            colon={false}
        >
            <Select
                mode={mode}
                size={size}
                required={required}
                placeholder={loadingProjectPlaceHolder}
                value={[...selectedItems, ...(filtersString ? [`filtersString${filtersString}`] : [])]}
                onChange={onChange}
                style={styles}
                showSearch={showSearch}
                dropdownStyle={{ zIndex: 1050, ...dropdownStyle }}
                popupClassName={`${popupClassName} selectWithCheckbox` || 'selectWithCheckbox'}
                maxTagCount={maxTagCount}
                searchValue={filtersString}
                onPopupScroll={handleScroll}
                open={dropdownVisible}
                filterOption={false}
                allowClear={allowClear}
                onDropdownVisibleChange={async (open) => {
                    setDropdownVisible(open);
                    if (!loading && open && options.length === 0) {
                      await loadOptions(true, page, filters);
                    }
                    else if (!loading && open && !loadedOptions) 
                    {
                      await loadOptions(true, 1, "");
                    }
                }}
                dropdownRender={(menu) => (
                    <div
                        onMouseDown={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    >
                      {options.length > 0 && mode !== 'single' && (
                        <>
                          <Flex style={{ justifyContent: 'space-between', padding: '8px 12px', backgroundColor: "#F7F7F7" }}>
                            <Button type="link" size="small" style={{fontSize: "12px", textDecoration: "underline", color: "#666"}} onClick={handleSelectAll}>
                              Select All
                            </Button>
                            <Button type="link" size="small" style={{fontSize: "12px", textDecoration: "underline", color: "#666"}} onClick={handleClear}>
                              Clear
                            </Button>
                          </Flex>
                          <Divider style={{ margin: 0 }} />
                        </>
                      )}
                      {menu}
                    </div>
                )}
                suffixIcon={suffixIcon}
                {...(showSearch ? { tagRender: tagRender } : {})}
                maxTagPlaceholder={(omittedValues) => (
                    selectedItems.length - 3 > 0 && (
                        <Tooltip
                            overlayStyle={{
                                pointerEvents: 'none',
                            }}
                            title={omittedValues.map(({ label }) => label[1].props === undefined ? label[1] : label[1].props.children).join(', ')} 
                        >
                            <span>
                                {selectAll ? (
                                    <>+{countOptions - selectedItems.length} more {type.toLowerCase()}{omittedValues.length > 1 ? 's' : ''}</>
                                ) : (
                                    <>+{selectedItems.length - 3} more {type.toLowerCase()}{omittedValues.length > 1 ? 's' : ''}</>
                                )}
                                
                            </span>
                        </Tooltip>
                    )
                )}
                {...(showSearch ? { onSearch: handleSearch } : {})}
                onInputKeyDown={onInputKeyDown}
                notFoundContent={loading ? loadingProject : noFoundContent}
            >
                {!loadedOptions ? (
                    <Option key={0} value={'loading'}>
                        <Spin size="small" style={{marginRight: "5px"}} /> Loading {label}....
                    </Option>
                ) : options.map((option) => (
                    <Option key={option.value} value={option.value} label={option.label}>
                        {mode !== 'single' && (
                            <Checkbox 
                                checked={selectedItems.includes(option.value)}
                                onClick={(e) => {
                                    e.stopPropagation(); // Prevent dropdown from closing
                                    handleCheckboxChange(option.value, e.target.checked);
                                }}
                                style={{marginRight: "10px"}}
                            />
                        )}
                        <Typography.Text onClick={() => checkedProject(option.value)}>{option.label}</Typography.Text>
                    </Option>
                ))}
                {loadingMore && (
                    <Option key={`loading-1`} value={'loading'} style={{backgroundColor: "#0066B2", pointerEvents: "none", display: "flex", height: 50, alignItems: "center"}}>
                        <Spin indicator={
                            <FontAwesomeIcon
                                icon={duotone('spinner-third')}
                                spin
                                size="2x"
                                style={{ '--fa-primary-color': '#FFFFFF', '--fa-secondary-color': '#FFFFFF', fontSize: 18 }}
                            />
                        } size="small" style={{marginRight: "5px", color: "#FFFFFF"}} /> <span style={{color: "#FFFFFF", fontSize: 18}}>Loading more {label}....</span>
                    </Option>
                )}
            </Select>
            {/* <a type="danger" onClick={onClear}></a> */}
        </Form.Item>
    );
};

export default OCMSelectFilter;