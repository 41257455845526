import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'antd';
import styled from 'styled-components';

const ReportModel = ({ title, children, close, open, footer, width = 'auto' }) => {
  const handleOk = () => {
    close();
  };

  const handleCancel = () => {
    close();
  };

  return (
    <StyledModal
      closeIcon={<FontAwesomeIcon size="2xl" icon={faXmark} />}
      centered
      footer={footer}
      okType=""
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      width={width}
      title={
        <OCMModalHeader>
          <div className="user-info-header-panel">
            <h1 className="header-title">{title}</h1>
            <div className="ribon none"></div>
          </div>
        </OCMModalHeader>
      }
      wrapClassName="ocm-modal"
    >
      <PopupContainer>{children}</PopupContainer>
    </StyledModal>
  );
};

const PopupContainer = styled.div`
  background-color: #fff;
  width: auto;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 30px ;
  overflow: auto;
  height: 100%;
`;

const StyledModal = styled(Modal)`
.ant-modal-content{
  padding: 0;
}
.ant-modal-close{
  right: 15px;
  top: 15px;
  background: transparent;
  color: rgba(0, 0, 0, 0.45);
  width: auto;
  height: auto;
  &:hover{
    background-color: transparent !important;
      color: rgba(0, 0, 0, 0.45);
  }
}
`;

const OCMModalHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  .user-info-header-panel {
    background: ${({ theme }) =>
    theme['user-theme'] === undefined
      ? '#E0F2FF'
      : theme['user-theme']['sidebarColor'] === undefined
        ? '#E0F2FF'
        : theme['user-theme']['sidebarColor']} !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    padding: 30px 20px;
    display: flex;
    justify-content: left;
    flex-direction: column;
    align-items: left;
    position: relative;
    .edit-pencil {
      position: absolute;
      right: 30px;
      svg {
        cursor: pointer;
      }
    }
    h1.header-title {
      font-weight: 400;
      font-size: 28px;
      line-hieght: 33.89px;
      margin-bottom: 0;
      color: ${({ theme }) =>
    theme['user-theme'] === undefined
      ? '#404040'
      : theme['user-theme']['sidebarTextColor'] === undefined
        ? '#404040'
        : theme['user-theme']['sidebarTextColor']} !important;
    }
 
  
  }
`;


export default ReportModel;
