import React, { useState, useReducer, useEffect, useCallback } from "react";
import { List } from "antd";
import { FreeResourceCategoryContainer, FreeResourceCategoryListWrapper, FreeResourceMainContentWrapper } from "./FreeResources.styles";
import FreeResourceCategoryHeader from "./FreeResourceCategoryHeader";
import FreeResourceCategoryItem from "./FreeResourceCategoryItem";
import { useDispatch } from "react-redux";
import { fetchResourceCategories } from "../../redux/cms/actionCreator";
import useNotificationController from "../../utility/useNotificationController";
import FreeResourceContent from "./FreeResourceContent";

const FreeResourceContentArea = ({setFeaturedImage}) => {
  const {
    contextHolder,
    openError,
  } = useNotificationController();
  const [categories, setCategories] = useState([])
  const [category, setCategory] = useState({});

  const initialState = {
      error: false,
      messages: ""
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'FETCH_RESOURCE_CATEGORIES_ERR':
        return { ...state, error: true,messages: action.err };
      case 'RESET':
        return state;
      default:
        return state;
    }
  };

  const [apiError, apiResponseCallback] = useReducer(reducer, initialState);
  const dispatch = useDispatch();

  const fetchCategories = useCallback(async () => {
    apiResponseCallback({ type: 'RESET' });
      dispatch(
          fetchResourceCategories({},(response) => {
            if (response.status === 200) {
              let categories = response.data.categories;
              const tmpCategories = [];
              const currentResourceCategoryId = localStorage.getItem("currentResourceCategory");
              categories.map((item, index) => {
                let isActive = false;
                if (currentResourceCategoryId === null && index === 0)
                {
                  isActive = true;
                }
                else if (currentResourceCategoryId === item.id)
                {
                  isActive = true;
                }
                tmpCategories.push({
                  id: item.id,
                  name: item.name,
                  icon: item.icon,
                  icon_type: item.icon_type,
                  faicon: item.faicon,
                  primaryColor: item.primaryColor,
                  secondaryColor: item.secondaryColor,
                  featuredImage: item.featuredImage,
                  isActive: isActive
                });
                if (currentResourceCategoryId === null && index === 0)
                {
                  setCategory({
                    id: item.id,
                    name: item.name,
                    icon: item.icon,
                    icon_type: item.icon_type,
                    faicon: item.faicon,
                    primaryColor: item.primaryColor,
                    secondaryColor: item.secondaryColor,
                    featuredImage: item.featuredImage
                  });
                }
                else if (currentResourceCategoryId === item.id)
                {
                  setCategory({
                    id: item.id,
                    name: item.name,
                    icon: item.icon,
                    icon_type: item.icon_type,
                    faicon: item.faicon,
                    primaryColor: item.primaryColor,
                    secondaryColor: item.secondaryColor,
                    featuredImage: item.featuredImage
                  });
                }
              });
              setCategories(tmpCategories);
            } 
            else 
            {
              openError('topLeft', 'Error', response.err);
              apiResponseCallback(response);
            }
          }),
        );
  }, [dispatch, categories, category]);

  useEffect(() => {
    const retrieveData = async () => {
      await fetchCategories();
    };
    retrieveData().catch(console.error);
  }, []);

  const handleCategoryClick = (id) => {
    setCategories(prevCategories => 
      prevCategories.map(category => 
        category.id === id
          ? { ...category, isActive: true }
          : { ...category, isActive: false }
      )
    );
    categories.map((item, index) => {
      if (item.id === id)
      {
        localStorage.setItem("currentResourceCategory", item.id);
        setCategory(item);
        if (item.featuredImage === null && item.featuredImage === undefined)
        {
          //setFeaturedImage('cover-change-management-library.png');
        }
        else
        {
          //setFeaturedImage(item.featuredImage);
        }
        return ;
      }
    });
  };
  
  return (
    <FreeResourceMainContentWrapper>
      {contextHolder}
      <FreeResourceCategoryContainer>
        <FreeResourceCategoryHeader />
        <FreeResourceCategoryListWrapper>
          <List
            dataSource={categories}
            renderItem={(category, index) => (
              <List.Item 
                className={category.isActive ? "active" : ""}
                onClick={() => handleCategoryClick(category.id)}
              >
                <FreeResourceCategoryItem 
                  key={`${category.id}-${index}`} 
                  title={category.name} 
                  isActive={category.isActive} 
                  icon={category.icon} 
                  icon_type={category.icon_type} 
                  faIcon={category.faicon} 
                  primaryColor={category.primaryColor} 
                  secondaryColor={category.secondaryColor} 
                />
              </List.Item>
            )}
          />
        </FreeResourceCategoryListWrapper>
      </FreeResourceCategoryContainer>
      <FreeResourceContent category={category} />
    </FreeResourceMainContentWrapper>
  );
};

export default FreeResourceContentArea;