import React, { useReducer, useState, useCallback, useEffect } from 'react';
import { Button, Typography, Alert, Modal } from 'antd';
const { Title, Text } = Typography;
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch } from 'react-redux';
import { restartCustomerSubscriptionAgain } from '../../redux/authentication/actionCreator';
import useNotificationController from '../../utility/useNotificationController';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { OCMModal, OCMModalHeader } from '../../container/tools/Style';
import moment from 'moment';
import { DataService } from '../../config/dataService/dataService';

const RestartSubscription = ({createProject}) => {
  const {
    contextHolder,
    openError,
    openSuccess
  } = useNotificationController();

  const is_marked_as_cancel = localStorage.getItem('is_marked_as_cancel') === 'true';
  const is_cancelled = localStorage.getItem('is_cancelled') === 'true';
  const cancellation_date = moment(localStorage.getItem('cancellation_date'));
  const cancellation_delete_data_date = moment(localStorage.getItem('cancellation_delete_data_date'));
  const current_date  = moment().utc();
  const [subscriptionInfo, setSubscriptionInfo] = useState({
    loaded: false,
    subscription: {},
    subscribeId: 0,
    customerId: 0
  });
  const [subscriptionModalButtonText, setSubscriptionModalButtonText] = useState({
    buttonText: "Restart Subscription",
    submittingFrom: false,
    isDisabled: false
  });

  if (current_date < cancellation_date && !is_cancelled)
  {
    if (!is_marked_as_cancel)
    {
      is_marked_as_cancel = true;
    }
  }

  if (current_date < cancellation_delete_data_date && !is_marked_as_cancel)
  {
    if (!is_cancelled)
    {
      is_cancelled = true;
    }
  }
  const organizationalSlug = localStorage.getItem('organizationalSlug');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const initialState = {
      error: false,
      messages: ""
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await DataService.get("/defaultData/get-subscription-info");
      if (response.status === 200)
      {
        const userResponse = response.data;
        
        setSubscriptionInfo({
          loaded: true,
          subscription: userResponse,
          subscribeId: userResponse.subscriptionId,
          customerId: userResponse.customerId
        });
        return {
          response,
        };
      }
      else
      {
        openError("topLeft", titlePageText, response.data.message);
        if (response.data.logout)
        {
          dispatch(logOut(() => navigate('/')));
        }
      }
    };
    fetchData();
  }, []);

  const reducer = (state, action) => {
      switch (action.type) {
        case 'RESTART_CUSTOMER_SUBSCRIPTION_AGAIN_ERR':
          return { ...state, error: true,messages: action.err };
        case 'RESET':
          return state;
        default:
          return state;
      }
  };

  const [apiError, apiResponseCallback] = useReducer(reducer, initialState);

  const dispatch = useDispatch();

  const restartCurrentSubscription = useCallback(async () => {
      apiResponseCallback({ type: 'RESET' });
      dispatch(
        restartCustomerSubscriptionAgain((response) => {          
          if (response.status === 200) 
          {
            const responseData = response.data.userInfo;
            openSuccess('bottomRight', 'Restart Subscription',  (
              <>
              <Title level={5}>Your subscription has been restarted successfully</Title>
              <Text type={'secondary'}>Window will be reloaded in 5 seconds</Text>
              </>
            ));
            localStorage.setItem('is_marked_as_cancel', responseData.isMarkedAsCancel);
            localStorage.setItem('is_cancelled', responseData.isCancelled);
            localStorage.setItem('cancellation_date', responseData.cancellationDate);

            localStorage.setItem('pauseForMonth', responseData.pauseForMonth);
            localStorage.setItem('is_paused', responseData.isPaused);
            localStorage.setItem('pause_start_date', responseData.pauseStartDate);
            localStorage.setItem('pause_resume_date', responseData.pauseResumeDate);
            localStorage.setItem('pause_request_date', responseData.pauseRequestDate);
            setTimeout(() => {
              setSubscriptionModalButtonText({
                buttonText: "Restart Subscription",
                submittingFrom: false,
                isDisabled: false
              });
              window.location.href = `/${organizationalSlug}/subscription/update-subscription`;
            }, 5000)
          } 
          else 
          {
            setSubscriptionModalButtonText({
              buttonText: "Restart Subscription",
              submittingFrom: false,
              isDisabled: false
            });
            if (response.data === undefined)
            {
              if (response.message !== undefined)
              {
                openError("topLeft", "Restart Subscription", response.message)
                apiResponseCallback(response);
              }
              else
              {
                openError("topLeft", "Restart Subscription", response.err)
                apiResponseCallback(response);
              }
            }
            else if (response.data !== undefined)
            {
              openError("topLeft", "Restart Subscription", response.data.message)
              apiResponseCallback(response);
            }
            else
            {
              openError("topLeft", "Restart Subscription", response.err)
              apiResponseCallback(response);
            }
          }
        }),
      );
  }, [dispatch]);

  const restartCustomerSubscription = async () => {
    setSubscriptionModalButtonText({
      buttonText: "Restarting subscription, please wait....",
      submittingFrom: true,
      isDisabled: true
    });
    await restartCurrentSubscription();
  }

  const stopCancellation = (val) => {
    setIsModalVisible(val);
  };

  return (
    <>
      {contextHolder}
      <Alert 
        message={
          <p style={{
            fontSize: "24px",
            marginBottom: 0,
            color: "#AE0002"
          }}>
            This subscription was cancelled on {cancellation_date.format("MMM DD, YYYY")}. Click here to:  
            <a 
              href={"#"} 
              onClick={(e) => stopCancellation(true)}
              style={{paddingLeft: "10px", textDecoration: "underline"}}
            >Restart Subscription</a>
          </p>
        }
        icon={
          <FontAwesomeIcon size='3x' icon={duotone('circle-exclamation')} style={{"--fa-primary-color": "#ffc000", "--fa-secondary-color": "#ffc000",}} />
        }
        type="error" 
        showIcon 
        style={{
          marginLeft: "20px",
          marginTop: "1px"
        }}
    />
    <OCMModal>
      {isModalVisible && (
        <Modal
          style={{
            margin: '20px 0px',
          }}
          className="add-change"
          title={
            <OCMModalHeader>
              <div className="user-info-header-panel">
                <Title level={1} className="header-title">Restart Subscription</Title>
                <div className="ribon none"></div>
              </div>
            </OCMModalHeader>
          }
          centered
          closeIcon={<FontAwesomeIcon size="2xl" icon={duotone('circle-xmark')} />}
          open={isModalVisible}
          onCancel={() => {
            stopCancellation(false);
          }}
          onOk={() => {
            stopCancellation(false);
          }}
          width={800}
          wrapClassName={'ocm-modal'}
          footer={[
            <Button 
              disabled={subscriptionModalButtonText.isDisabled}
              loading={subscriptionModalButtonText.submittingFrom}
              key="delete" 
              type="ocm-default-stop-cancellation" 
              size="large"
              style={{
                fontSize: '20px', // Increase font size
                padding: '5px 46px 5px 46px', // Increase padding
                lineHeight: '50px', // Center the text vertically
                borderRadius: "15px",
                height: "auto",
              }}
              onClick={async (e) => {
                await restartCustomerSubscription();
              }}
            >
              {subscriptionModalButtonText.buttonText}
            </Button>
          ]}
        >
          <div style={{padding: "10px 30px", marginBottom: "30px"}}>
            <p style={{fontSize: "24px", lineHeight: "32px"}}>
              {subscriptionInfo.loaded && (
                <Title level={2}>Subscription Total: ${subscriptionInfo.subscription.newSubscriptionAmount.toLocaleString('en-US', {minimumFractionDigits: subscriptionInfo.subscription.newSubscriptionAmount % 1 === 0 ? 2 : 2, maximumFractionDigits: 2})} per {subscriptionInfo.subscription.subscriptionType.toLowerCase() === "monthly" ? "month" : "year"}</Title>
              )}
              Click below to confirm and restart your subscription.
            </p>
          </div>
        </Modal>
      )}
    </OCMModal>
  </>
  );
};

export default RestartSubscription;
