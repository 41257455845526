import { useState } from 'react';
import styled from 'styled-components';
import Popup from './Popup';
import { ActionContainer, PopupAction } from './Style';
import { Row, Col, Form, Input, DatePicker, Spin } from 'antd';
import { DataService } from '../../../../config/dataService/dataService';
import dayjs from 'dayjs';
import { toUpper } from 'lodash';
import UpdateEmail from './UpdateEmail';
import useNotificationController from '../../../../utility/useNotificationController';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const date_time_format = localStorage.getItem('date_time_format');
const dateFormatPattern = /^(DD|MM|YYYY)([-/\\])(DD|MM|YYYY)([-/\\])(DD|MM|YYYY)$/;
const isValidFormat = date_time_format && dateFormatPattern.test(date_time_format);
const formattedFormat = isValidFormat ? date_time_format : 'MM-DD-YYYY';

const dateFormat = toUpper(formattedFormat);

const SettingsPopup = ({ open, onClose, initial, onSubmit }) => {
  const {
    contextHolder,
    openSuccess,
  } = useNotificationController();
  
  const [form] = Form.useForm();
  const [isSettingUpdated, setIsSettingUpdated] = useState(false);
  const [isLoading, setIsLoaing] = useState(false);
  const setSurveyDuration = Form.useWatch('setSurveyDuration', form);
  const setShowSurveyEmail = Form.useWatch('setShowSurveyEmail', form);
  const setShowSurveyDepartment = Form.useWatch('setShowSurveyDepartment', form);
  const surveyStartDate = Form.useWatch('surveyStartDate', form);
  const surveyEndDate = Form.useWatch('surveyEndDate', form);

  const disabledDate = (current) => {
    return current && current < surveyStartDate;
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const handleSubmit = async (values) => {
    const initialSurveyEndDate = dayjs(initial?.surveyEndDate);
    setIsLoaing(true);
    const data = {
      ...values,
      ...(values.surveyStartDate ? { surveyStartDate: values.surveyStartDate.format() } : {}),
      ...(values.surveyEndDate ? { surveyEndDate: values.surveyEndDate.format() } : {}),
    };
    await DataService.put(`surveys/update-survey/${initial.id}`, data);

    if (initial.surveySent) {
      if (!initialSurveyEndDate.isSame(surveyEndDate)) {
        setIsSettingUpdated(true);
      } 
    } else {
      handleClose();
    }
    setIsLoaing(false);
    openSuccess('bottomRight', "Survey Settings",  `Survey settings Saved`);
    onSubmit();
  };

  const startDateHandler = () => {
    if (dayjs(surveyStartDate).isAfter(surveyEndDate)) {
      form.setFieldValue({ surveyEndDate: null });
    }
  };

  const startDateDisable = (current) => {
    const today = dayjs();

    return current.isBefore(today, 'day');
  };

  const backToSettingsHandler = () => {
    setIsSettingUpdated(false);
  };
  const dontSendHandler = () => {
    onClose();
  };

  return (
    <>
    {contextHolder}
    <Popup title={'Settings'} open={open} close={handleClose} footer={null}>
      {isSettingUpdated ? (
        <>
          <UpdateEmail
            backToSettings={backToSettingsHandler}
            onClose={dontSendHandler}
            surveyData={initial}
            setIsLoaing={setIsLoaing}
            loading={isLoading}
            surveyEndDate={dayjs(surveyEndDate).format(dateFormat)}
          />
        </>
      ) : (
        <Container>
          <Form form={form} onFinish={handleSubmit}>
            <Row>
              <Col>
                <Form.Item name="setSurveyDuration" initialValue={initial.setSurveyDuration} valuePropName="checked">
                  <Checkbox type="checkbox" />
                </Form.Item>
              </Col>
              <Col style={{ fontSize: '18px' }}>Set Survey Duration</Col>
            </Row>
            {setSurveyDuration && (
              <Row style={{ gap: '5px', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                <Col xs={10}>
                  <span style={{ marginRight: '10px' }}>
                    Start Date<span style={{ color: 'red' }}>*</span>
                  </span>
                  <Form.Item
                    name="surveyStartDate"
                    initialValue={initial?.surveyStartDate ? dayjs(initial.surveyStartDate) : null}
                    rules={[
                      {
                        required: true,
                        message: 'Please select a start date',
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          const startDate = value;
                          const endDate = getFieldValue('surveyEndDate');
                          if (
                            startDate &&
                            endDate &&
                            dayjs(startDate).startOf('day').isAfter(dayjs(endDate).startOf('day'))
                          ) {
                            return Promise.reject(new Error('Start date must be less than end date'));
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <DatePicker onChange={startDateHandler} format={dateFormat} disabledDate={startDateDisable} />
                  </Form.Item>
                </Col>
                <p style={{ margin: '0px 20px' }}>to</p>
                <Col xs={10}>
                  <span style={{ marginRight: '10px' }}>End Date</span>
                  <Form.Item
                    name="surveyEndDate"
                    initialValue={initial.surveyEndDate ? dayjs(initial.surveyEndDate) : null}
                  >
                    <DatePicker
                      value={surveyEndDate}
                      format={dateFormat}
                      disabledDate={disabledDate}
                      disabled={!surveyStartDate}
                    />
                  </Form.Item>
                </Col>
                {surveyEndDate && (
                  <Col xs={24}>
                    <Row>
                      <Col xs={24} style={{ fontSize: '18px', marginBottom: '10px' }}>
                        Survey Expiration Message<span style={{ color: 'red' }}>*</span>
                      </Col>
                      <Col xs={24}>
                        <Form.Item
                          name="expirationMessage"
                          rules={[{ required: true }]}
                          initialValue={initial.expirationMessage}
                        >
                          <Input.TextArea style={{ height: '150px', fontSize: '18px' }} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            )}
            <Row style={{ margin: '15px 0px' }}>
              <Col>
                <Form.Item
                  name="getDailyResponseEmail"
                  initialValue={initial.getDailyResponseEmail}
                  valuePropName="checked"
                >
                  <Checkbox type="checkbox" />
                </Form.Item>
              </Col>
              <Col style={{ fontSize: '18px' }}>Get a daily notification on survey responses</Col>
            </Row>
            <Row>
              <Col>
                <Form.Item name="setShowSurveyEmail" initialValue={initial.setShowSurveyEmail} valuePropName="checked">
                  <Checkbox type="checkbox" />
                </Form.Item>
              </Col>
              <Col style={{ fontSize: '18px' }}>Show email field</Col>
            </Row>
            <Row>
              <Col>
                <Form.Item name="setShowSurveyDepartment" initialValue={initial.setShowSurveyDepartment} valuePropName="checked">
                  <Checkbox type="checkbox" />
                </Form.Item>
              </Col>
              <Col style={{ fontSize: '18px' }}>Show department/group field</Col>
            </Row>
            <Row>
              <Col xs={24} style={{ fontSize: '18px', marginBottom: '10px' }}>
                Thank You Message<span style={{ color: 'red' }}>*</span>
              </Col>
              <Col xs={24}>
                <Form.Item name="thankYouMessage" rules={[{ required: true }]} initialValue={initial.thankYouMessage}>
                  <Input.TextArea style={{ height: '150px', fontSize: '18px' }} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <ActionContainer>
                  <PopupAction type="submit">
                    {isLoading ? <Spin indicator={<FontAwesomeIcon icon={regular("circle-notch")} spin style={{ color: 'white' }} />} /> : 'Done'}
                  </PopupAction>
                </ActionContainer>
              </Col>
            </Row>
          </Form>
        </Container>
      )}
    </Popup>
    </>
  );
};

const Container = styled.div`
  width: 600px;
  padding-top: 20px;
`;

const Checkbox = styled.input`
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--form-background);
  margin: 0;
  font: inherit;
  width: 30px;
  height: 30px;
  border: 0.15em solid #5dd3ff;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  border-radius: 5px;
  margin-right: 10px;

  &:checked::before {
    transform: scale(1);
  }

  &:disabled {
    color: var(--form-control-disabled);
    cursor: not-allowed;
  }

  &:before {
    content: '';
    width: 20px;
    height: 20px;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    background-color: CanvasText;
  }
`;

export default SettingsPopup;
