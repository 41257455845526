import React from 'react';
import { SkeletonLoader } from '../SkeletonLoader/SkeletonLoader';

export const StatBox = ({ d_id, mainTitle, secondTitle, width, color, fontColor = 'white', loading, skipLoading, height = '7rem' }) => {
  return (
    <>
      {' '}
      {loading == false || skipLoading ? (
        <div
          id={d_id}
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '5px',
            padding: '20px',
            width: width,
            height: height,
            backgroundColor: color,
            borderRadius: '10px',
          }}
        >
          <span style={{ color: fontColor, fontWeight: 400, fontSize: '42px' }}>{mainTitle}</span>
          <span style={{ color: fontColor, fontWeight: 400, fontSize: '12px', textAlign: 'center' }}>{secondTitle}</span>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '5px',
            padding: '20px',
            flexBasis: `calc(${width})`,
            height: height,
          }}
        >
          <SkeletonLoader size={'small'} number={1} />
          <SkeletonLoader size={'small'} number={1} />
        </div>
      )}
    </>
  );
};
