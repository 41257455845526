import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import * as ProIcons from '@fortawesome/pro-duotone-svg-icons';

const FreeResourceCategoryItem = ({ 
  key = '', 
  title = '', 
  isActive = false, 
  icon = '', 
  icon_type = '', 
  faIcon = '', 
  primaryColor = '', 
  secondaryColor = '' 
}) => {
    library.add(ProIcons[faIcon]);

    if (isActive) 
    {
      return (
        <Item key={key}>
          <span className="activeTitle">
            {process.env.REACT_APP_SHOW_RESOURCE_ICON === "true" && (
              <FontAwesomeIcon
                icon={`fa-${icon_type} fa-${icon}`}
                style={{
                  '--fa-primary-color': `${primaryColor}`,
                  '--fa-secondary-color': `${secondaryColor}`,
                  width: "35px",
                  height: "20px",
                  paddingRight: "10px"
                }}
              />
            )}
            {title}
          </span>
          <FontAwesomeIcon icon={faChevronRight} color="#BFBFBF" height={21} width={20} />
        </Item>
      );
    }
  
    return (
      <Item key={key}>
        {process.env.REACT_APP_SHOW_RESOURCE_ICON === "true" && (
          <FontAwesomeIcon
            icon={`fa-${icon_type} fa-${icon}`}
            size="2xl"
            style={{
              '--fa-primary-color': `${primaryColor}`,
              '--fa-secondary-color': `${secondaryColor}`,
              width: "35px",
              height: "20px",
              paddingRight: "10px"
            }}
          />
        )}
        {title}
      </Item>
    );
}

const Item = styled.button`
    display: flex;
    align-items: center;
    font-family: Inter, sans-serif;
    background: none;
    border: none;
    text-align: left;
    cursor: pointer;
    padding: 10px 0;
    margin-top: 27px;
    font-size: 18px;
    color: inherit;
    &:first-child {
        margin-top: 0;
    }
`;

export default FreeResourceCategoryItem;