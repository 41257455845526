const actions = {
  TOOLS_CONFIGURATION_BEGIN: 'TOOLS_CONFIGURATION_BEGIN',
  TOOLS_CONFIGURATION_SUCCESS: 'TOOLS_CONFIGURATION_SUCCESS',
  TOOLS_CONFIGURATION_ERR: 'TOOLS_CONFIGURATION_ERR',
  
  TOOLS_INSTALLER_BEGIN: 'TOOLS_INSTALLER_BEGIN',
  TOOLS_INSTALLER_SUCCESS: 'TOOLS_INSTALLER_SUCCESS',
  TOOLS_INSTALLER_ERR: 'TOOLS_INSTALLER_ERR',
  
  GET_TOOLS_LIST_DATA_BEGIN: 'GET_TOOLS_LIST_DATA_BEGIN',
  GET_TOOLS_LIST_DATA_SUCCESS: 'GET_TOOLS_LIST_DATA_SUCCESS',
  GET_TOOLS_LIST_DATA_ERR: 'GET_TOOLS_LIST_DATA_ERR',
  
  GET_SINGLE_IMPACT_BEGIN: 'GET_SINGLE_IMPACT_BEGIN',
  GET_SINGLE_IMPACT_SUCCESS: 'GET_SINGLE_IMPACT_SUCCESS',
  GET_SINGLE_IMPACT_ERR: 'GET_SINGLE_IMPACT_ERR',
  
  GENERATE_AUDIENCE_TOOLS_BEGIN: 'GENERATE_AUDIENCE_TOOLS_BEGIN',
  GENERATE_AUDIENCE_TOOLS_SUCCESS: 'GENERATE_AUDIENCE_TOOLS_SUCCESS',
  GENERATE_AUDIENCE_TOOLS_ERR: 'GENERATE_AUDIENCE_TOOLS_ERR',
  
  GENERATE_OCM_PLAN_TOOLS_BEGIN: 'GENERATE_OCM_PLAN_TOOLS_BEGIN',
  GENERATE_OCM_PLAN_TOOLS_SUCCESS: 'GENERATE_OCM_PLAN_TOOLS_SUCCESS',
  GENERATE_OCM_PLAN_TOOLS_ERR: 'GENERATE_OCM_PLAN_TOOLS_ERR',
  
  GENERATE_CHAMPION_AUDIENCE_TOOLS_BEGIN: 'GENERATE_CHAMPION_AUDIENCE_TOOLS_BEGIN',
  GENERATE_CHAMPION_AUDIENCE_TOOLS_SUCCESS: 'GENERATE_CHAMPION_AUDIENCE_TOOLS_SUCCESS',
  GENERATE_CHAMPION_AUDIENCE_TOOLS_ERR: 'GENERATE_CHAMPION_AUDIENCE_TOOLS_ERR',
  
  GENERATE_RESISTANCE_RISK_PLAN_BEGIN: 'GENERATE_RESISTANCE_RISK_PLAN_BEGIN',
  GENERATE_RESISTANCE_RISK_PLAN_SUCCESS: 'GENERATE_RESISTANCE_RISK_PLAN_SUCCESS',
  GENERATE_RESISTANCE_RISK_PLAN_ERR: 'GENERATE_RESISTANCE_RISK_PLAN_ERR',
  
  GET_TOOL_GENERATED_DATA_BEGIN: 'GET_TOOL_GENERATED_DATA_BEGIN',
  GET_TOOL_GENERATED_DATA_SUCCESS: 'GET_TOOL_GENERATED_DATA_SUCCESS',
  GET_TOOL_GENERATED_DATA_ERR: 'GET_TOOL_GENERATED_DATA_ERR',
  
  GET_TOOLS_AUDIENCES_DATA_BEGIN: 'GET_TOOLS_AUDIENCES_DATA_BEGIN',
  GET_TOOLS_AUDIENCES_DATA_SUCCESS: 'GET_TOOLS_AUDIENCES_DATA_SUCCESS',
  GET_TOOLS_AUDIENCES_DATA_ERR: 'GET_TOOLS_AUDIENCES_DATA_ERR',
  
  GET_TOOLS_EXPORT_COLUMNS_DATA_BEGIN: 'GET_TOOLS_EXPORT_COLUMNS_DATA_BEGIN',
  GET_TOOLS_EXPORT_COLUMNS_DATA_SUCCESS: 'GET_TOOLS_EXPORT_COLUMNS_DATA_SUCCESS',
  GET_TOOLS_EXPORT_COLUMNS_DATA_ERR: 'GET_TOOLS_EXPORT_COLUMNS_DATA_ERR',
  
  GET_TOOLS_DEFAULT_COLUMNS_DATA_BEGIN: 'GET_TOOLS_DEFAULT_COLUMNS_DATA_BEGIN',
  GET_TOOLS_DEFAULT_COLUMNS_DATA_SUCCESS: 'GET_TOOLS_DEFAULT_COLUMNS_DATA_SUCCESS',
  GET_TOOLS_DEFAULT_COLUMNS_DATA_ERR: 'GET_TOOLS_DEFAULT_COLUMNS_DATA_ERR',
  
  IMPORT_DATA_FOR_TOOLS_BEGIN: 'IMPORT_DATA_FOR_TOOLS_BEGIN',
  IMPORT_DATA_FOR_TOOLS_SUCCESS: 'IMPORT_DATA_FOR_TOOLS_SUCCESS',
  IMPORT_DATA_FOR_TOOLS_ERR: 'IMPORT_DATA_FOR_TOOLS_ERR',
  
  EXPORT_DATA_FOR_TOOLS_BEGIN: 'EXPORT_DATA_FOR_TOOLS_BEGIN',
  EXPORT_DATA_FOR_TOOLS_SUCCESS: 'EXPORT_DATA_FOR_TOOLS_SUCCESS',
  EXPORT_DATA_FOR_TOOLS_ERR: 'EXPORT_DATA_FOR_TOOLS_ERR',
  
  GET_RESISTANCE_KEY_REASON_BEGIN: 'GET_RESISTANCE_KEY_REASON_BEGIN',
  GET_RESISTANCE_KEY_REASON_SUCCESS: 'GET_RESISTANCE_KEY_REASON_SUCCESS',
  GET_RESISTANCE_KEY_REASON_ERR: 'GET_RESISTANCE_KEY_REASON_ERR',
  
  SAVE_RESISTANCE_KEY_REASON_BEGIN: 'SAVE_RESISTANCE_KEY_REASON_BEGIN',
  SAVE_RESISTANCE_KEY_REASON_SUCCESS: 'SAVE_RESISTANCE_KEY_REASON_SUCCESS',
  SAVE_RESISTANCE_KEY_REASON_ERR: 'SAVE_RESISTANCE_KEY_REASON_ERR',
  
  SAVE_TOOL_RISK_AUDIENCES_DATA_BEGIN: 'SAVE_TOOL_RISK_AUDIENCES_DATA_BEGIN',
  SAVE_TOOL_RISK_AUDIENCES_DATA_SUCCESS: 'SAVE_TOOL_RISK_AUDIENCES_DATA_SUCCESS',
  SAVE_TOOL_RISK_AUDIENCES_DATA_ERR: 'SAVE_TOOL_RISK_AUDIENCES_DATA_ERR',
  
  SAVE_TOOL_ASSESSMENT_NAME_BEGIN: 'SAVE_TOOL_ASSESSMENT_NAME_BEGIN',
  SAVE_TOOL_ASSESSMENT_NAME_SUCCESS: 'SAVE_TOOL_ASSESSMENT_NAME_SUCCESS',
  SAVE_TOOL_ASSESSMENT_NAME_ERR: 'SAVE_TOOL_ASSESSMENT_NAME_ERR',
  
  CLOSE_TOOL_ASSESSMENT_NAME_BEGIN: 'CLOSE_TOOL_ASSESSMENT_NAME_BEGIN',
  CLOSE_TOOL_ASSESSMENT_NAME_SUCCESS: 'CLOSE_TOOL_ASSESSMENT_NAME_SUCCESS',
  CLOSE_TOOL_ASSESSMENT_NAME_ERR: 'CLOSE_TOOL_ASSESSMENT_NAME_ERR',
  
  DELETE_TOOL_ASSESSMENT_NAME_BEGIN: 'DELETE_TOOL_ASSESSMENT_NAME_BEGIN',
  DELETE_TOOL_ASSESSMENT_NAME_SUCCESS: 'DELETE_TOOL_ASSESSMENT_NAME_SUCCESS',
  DELETE_TOOL_ASSESSMENT_NAME_ERR: 'DELETE_TOOL_ASSESSMENT_NAME_ERR',
  
  SAVE_RESISTANCE_AUDIENCES_KEY_REASON_DATA_BEGIN: 'SAVE_RESISTANCE_AUDIENCES_KEY_REASON_DATA_BEGIN',
  SAVE_RESISTANCE_AUDIENCES_KEY_REASON_DATA_SUCCESS: 'SAVE_RESISTANCE_AUDIENCES_KEY_REASON_DATA_SUCCESS',
  SAVE_RESISTANCE_AUDIENCES_KEY_REASON_DATA_ERR: 'SAVE_RESISTANCE_AUDIENCES_KEY_REASON_DATA_ERR',
  
  SAVE_TOOL_ENGAGEMENT_PLAN_BEGIN: 'SAVE_TOOL_ENGAGEMENT_PLAN_BEGIN',
  SAVE_TOOL_ENGAGEMENT_PLAN_SUCCESS: 'SAVE_TOOL_ENGAGEMENT_PLAN_SUCCESS',
  SAVE_TOOL_ENGAGEMENT_PLAN_ERR: 'SAVE_TOOL_ENGAGEMENT_PLAN_ERR',
  
  TOOLS_COPY_PROJECT_BEGIN: 'TOOLS_COPY_PROJECT_BEGIN',
  TOOLS_COPY_PROJECT_SUCCESS: 'TOOLS_COPY_PROJECT_SUCCESS',
  TOOLS_COPY_PROJECT_ERR: 'TOOLS_COPY_PROJECT_ERR',

  TOOLS_TASK_LIST_BEGIN: 'TOOLS_TASK_LIST_BEGIN',
  TOOLS_TASK_LIST_SUCCESS: 'TOOLS_TASK_LIST_SUCCESS',
  TOOLS_TASK_LIST_ERR: 'TOOLS_TASK_LIST_ERR',

  CHANGE_IMPECT_BEGIN: 'CHANGE_IMPECT_BEGIN',
  CHANGE_IMPECT_SUCCESS: 'CHANGE_IMPECT_SUCCESS',
  CHANGE_IMPECT_ERR: 'CHANGE_IMPECT_ERR',

  SAVE_PROJECT_ADOPTION_METRIC_BEGIN: 'SAVE_PROJECT_ADOPTION_METRIC_BEGIN',
  SAVE_PROJECT_ADOPTION_METRIC_SUCCESS: 'SAVE_PROJECT_ADOPTION_METRIC_SUCCESS',
  SAVE_PROJECT_ADOPTION_METRIC_ERR: 'SAVE_PROJECT_ADOPTION_METRIC_ERR',

  EDIT_PROJECT_ADOPTION_METRIC_BEGIN: 'EDIT_PROJECT_ADOPTION_METRIC_BEGIN',
  EDIT_PROJECT_ADOPTION_METRIC_SUCCESS: 'EDIT_PROJECT_ADOPTION_METRIC_SUCCESS',
  EDIT_PROJECT_ADOPTION_METRIC_ERR: 'EDIT_PROJECT_ADOPTION_METRIC_ERR',

  DELETE_PROJECT_ADOPTION_METRIC_BEGIN: 'DELETE_PROJECT_ADOPTION_METRIC_BEGIN',
  DELETE_PROJECT_ADOPTION_METRIC_SUCCESS: 'DELETE_PROJECT_ADOPTION_METRIC_SUCCESS',
  DELETE_PROJECT_ADOPTION_METRIC_ERR: 'DELETE_PROJECT_ADOPTION_METRIC_ERR',

  ChangeImpectBegin: () => {
    return {
      type: actions.CHANGE_IMPECT_BEGIN,
    };
  },

  ChangeImpectSuccess: (data) => {
    return {
      type: actions.CHANGE_IMPECT_SUCCESS,
      data,
    };
  },

  ChangeImpectErr: (err) => {
    return {
      type: actions.CHANGE_IMPECT_ERR,
      err,
    };
  },
  

  ToolConfigrationBegin: () => {
    return {
      type: actions.TOOLS_CONFIGURATION_BEGIN,
    };
  },

  ToolConfigrationSuccess: (data) => {
    return {
      type: actions.TOOLS_CONFIGURATION_SUCCESS,
      data,
    };
  },

  ToolConfigrationErr: (err) => {
    return {
      type: actions.TOOLS_CONFIGURATION_ERR,
      err,
    };
  },

  ToolsInstallerBegin: () => {
    return {
      type: actions.TOOLS_INSTALLER_BEGIN,
    };
  },

  ToolsInstallerSuccess: (data) => {
    return {
      type: actions.TOOLS_INSTALLER_SUCCESS,
      data,
    };
  },

  ToolsInstallerErr: (err) => {
    return {
      type: actions.TOOLS_INSTALLER_ERR,
      err,
    };
  },

  getToolsListDataBegin: () => {
    return {
      type: actions.GET_TOOLS_LIST_DATA_BEGIN,
    };
  },

  getToolsListDataSuccess: (data) => {
    return {
      type: actions.GET_TOOLS_LIST_DATA_SUCCESS,
      data,
    };
  },

  getToolsListDataErr: (err) => {
    return {
      type: actions.GET_TOOLS_LIST_DATA_ERR,
      err,
    };
  },

  getSingleImpactBegin: () => {
    return {
      type: actions.GET_SINGLE_IMPACT_BEGIN,
    };
  },

  getSingleImpactSuccess: (data) => {
    return {
      type: actions.GET_SINGLE_IMPACT_SUCCESS,
      data,
    };
  },

  getSingleImpactErr: (err) => {
    return {
      type: actions.GET_SINGLE_IMPACT_ERR,
      err,
    };
  },

  generateAudienceToolsBegin: () => {
    return {
      type: actions.GENERATE_AUDIENCE_TOOLS_BEGIN,
    };
  },

  generateAudienceToolsSuccess: (data) => {
    return {
      type: actions.GENERATE_AUDIENCE_TOOLS_SUCCESS,
      data,
    };
  },

  generateAudienceToolsErr: (err) => {
    return {
      type: actions.GENERATE_AUDIENCE_TOOLS_ERR,
      err,
    };
  },

  generateOCMPlanToolsBegin: () => {
    return {
      type: actions.GENERATE_OCM_PLAN_TOOLS_BEGIN,
    };
  },

  generateOCMPlanToolsSuccess: (data) => {
    return {
      type: actions.GENERATE_OCM_PLAN_TOOLS_SUCCESS,
      data,
    };
  },

  generateOCMPlanToolsErr: (err) => {
    return {
      type: actions.GENERATE_OCM_PLAN_TOOLS_ERR,
      err,
    };
  },

  saveProjectAdoptionMetricsBegin: () => {
    return {
      type: actions.SAVE_PROJECT_ADOPTION_METRIC_BEGIN,
    };
  },

  saveProjectAdoptionMetricsSuccess: (data) => {
    return {
      type: actions.SAVE_PROJECT_ADOPTION_METRIC_SUCCESS,
      data,
    };
  },

  saveProjectAdoptionMetricsErr: (err) => {
    return {
      type: actions.SAVE_PROJECT_ADOPTION_METRIC_ERR,
      err,
    };
  },

  editProjectAdoptionMetricsBegin: () => {
    return {
      type: actions.EDIT_PROJECT_ADOPTION_METRIC_BEGIN,
    };
  },

  editProjectAdoptionMetricsSuccess: (data) => {
    return {
      type: actions.EDIT_PROJECT_ADOPTION_METRIC_SUCCESS,
      data,
    };
  },

  editProjectAdoptionMetricsErr: (err) => {
    return {
      type: actions.EDIT_PROJECT_ADOPTION_METRIC_ERR,
      err,
    };
  },

  deleteProjectAdoptionMetricsBegin: () => {
    return {
      type: actions.DELETE_PROJECT_ADOPTION_METRIC_BEGIN,
    };
  },

  deleteProjectAdoptionMetricsSuccess: (data) => {
    return {
      type: actions.DELETE_PROJECT_ADOPTION_METRIC_SUCCESS,
      data,
    };
  },

  deleteProjectAdoptionMetricsErr: (err) => {
    return {
      type: actions.DELETE_PROJECT_ADOPTION_METRIC_ERR,
      err,
    };
  },

  generateResistanceRiskPlanBegin: () => {
    return {
      type: actions.GENERATE_RESISTANCE_RISK_PLAN_BEGIN,
    };
  },

  generateResistanceRiskPlanSuccess: (data) => {
    return {
      type: actions.GENERATE_RESISTANCE_RISK_PLAN_SUCCESS,
      data,
    };
  },

  generateResistanceRiskPlanErr: (err) => {
    return {
      type: actions.GENERATE_RESISTANCE_RISK_PLAN_ERR,
      err,
    };
  },

  getToolGeneratedDataBegin: () => {
    return {
      type: actions.GET_TOOL_GENERATED_DATA_BEGIN,
    };
  },

  getToolGeneratedDataSuccess: (data) => {
    return {
      type: actions.GET_TOOL_GENERATED_DATA_SUCCESS,
      data,
    };
  },

  getToolGeneratedDataErr: (err) => {
    return {
      type: actions.GET_TOOL_GENERATED_DATA_ERR,
      err,
    };
  },

  getToolsAudiencsDataBegin: () => {
    return {
      type: actions.GET_TOOLS_AUDIENCES_DATA_BEGIN,
    };
  },

  getToolsAudiencsDataSuccess: (data) => {
    return {
      type: actions.GET_TOOLS_AUDIENCES_DATA_SUCCESS,
      data,
    };
  },

  getToolsAudiencsDataErr: (err) => {
    return {
      type: actions.GET_TOOLS_AUDIENCES_DATA_ERR,
      err
    };
  },

  getToolsDefaultColumnsDataBegin: () => {
    return {
      type: actions.GET_TOOLS_DEFAULT_COLUMNS_DATA_BEGIN,
    };
  },

  getToolsDefaultColumnsDataSuccess: (data) => {
    return {
      type: actions.GET_TOOLS_DEFAULT_COLUMNS_DATA_SUCCESS,
      data,
    };
  },

  getToolsDefaultColumnsDataErr: (err, num) => {
    return {
      type: actions.GET_TOOLS_DEFAULT_COLUMNS_DATA_ERR,
      err,
      audienceCount: num
    };
  },

  getToolsExportColumnsDataBegin: () => {
    return {
      type: actions.GET_TOOLS_EXPORT_COLUMNS_DATA_BEGIN,
    };
  },

  getToolsExportColumnsDataSuccess: (data) => {
    return {
      type: actions.GET_TOOLS_EXPORT_COLUMNS_DATA_SUCCESS,
      data,
    };
  },

  getToolsExportColumnsDataErr: (err, num) => {
    return {
      type: actions.GET_TOOLS_EXPORT_COLUMNS_DATA_ERR,
      err,
      audienceCount: num
    };
  },

  importDataForToolsBegin: () => {
    return {
      type: actions.IMPORT_DATA_FOR_TOOLS_BEGIN,
    };
  },

  importDataForToolsSuccess: (data) => {
    return {
      type: actions.IMPORT_DATA_FOR_TOOLS_SUCCESS,
      data,
    };
  },

  importDataForToolsErr: (err, num) => {
    return {
      type: actions.IMPORT_DATA_FOR_TOOLS_ERR,
      err,
      audienceCount: num
    };
  },

  exportDataForToolsBegin: () => {
    return {
      type: actions.EXPORT_DATA_FOR_TOOLS_BEGIN,
    };
  },

  exportDataForToolsSuccess: (data) => {
    return {
      type: actions.EXPORT_DATA_FOR_TOOLS_SUCCESS,
      data,
    };
  },

  exportDataForToolsErr: (err, num) => {
    return {
      type: actions.EXPORT_DATA_FOR_TOOLS_ERR,
      err,
      audienceCount: num
    };
  },

  getResistanceKeyReasonBegin: () => {
    return {
      type: actions.GET_RESISTANCE_KEY_REASON_BEGIN,
    };
  },

  getResistanceKeyReasonSuccess: (data) => {
    return {
      type: actions.GET_RESISTANCE_KEY_REASON_SUCCESS,
      data,
    };
  },

  getResistanceKeyReasonErr: (err) => {
    return {
      type: actions.GET_RESISTANCE_KEY_REASON_ERR,
      err,
    };
  },

  saveResistanceKeyReasonBegin: () => {
    return {
      type: actions.SAVE_RESISTANCE_KEY_REASON_BEGIN,
    };
  },

  saveResistanceKeyReasonSuccess: (data) => {
    return {
      type: actions.SAVE_RESISTANCE_KEY_REASON_SUCCESS,
      data,
    };
  },

  saveResistanceKeyReasonErr: (err) => {
    return {
      type: actions.SAVE_RESISTANCE_KEY_REASON_ERR,
      err,
    };
  },

  saveToolRiskAudiencsDataBegin: () => {
    return {
      type: actions.SAVE_TOOL_RISK_AUDIENCES_DATA_BEGIN,
    };
  },

  saveToolRiskAudiencsDataSuccess: (data) => {
    return {
      type: actions.SAVE_TOOL_RISK_AUDIENCES_DATA_SUCCESS,
      data,
    };
  },

  saveToolRiskAudiencsDataErr: (err) => {
    return {
      type: actions.SAVE_TOOL_RISK_AUDIENCES_DATA_ERR,
      err,
    };
  },

  saveResistanceAudiencsKeyReasonDataBegin: () => {
    return {
      type: actions.SAVE_RESISTANCE_AUDIENCES_KEY_REASON_DATA_BEGIN,
    };
  },

  saveResistanceAudiencsKeyReasonDataSuccess: (data) => {
    return {
      type: actions.SAVE_RESISTANCE_AUDIENCES_KEY_REASON_DATA_SUCCESS,
      data,
    };
  },

  saveResistanceAudiencsKeyReasonDataErr: (err) => {
    return {
      type: actions.SAVE_RESISTANCE_AUDIENCES_KEY_REASON_DATA_ERR,
      err,
    };
  },

  saveToolEngagementPlanBegin: () => {
    return {
      type: actions.SAVE_TOOL_ENGAGEMENT_PLAN_BEGIN,
    };
  },

  saveToolEngagementPlanSuccess: (data) => {
    return {
      type: actions.SAVE_TOOL_ENGAGEMENT_PLAN_SUCCESS,
      data,
    };
  },

  saveToolEngagementPlanErr: (err) => {
    return {
      type: actions.SAVE_TOOL_ENGAGEMENT_PLAN_ERR,
      err,
    };
  },

  saveToolAssessmentNameBegin: () => {
    return {
      type: actions.SAVE_TOOL_ASSESSMENT_NAME_BEGIN,
    };
  },

  saveToolAssessmentNameSuccess: (data) => {
    return {
      type: actions.SAVE_TOOL_ASSESSMENT_NAME_SUCCESS,
      data,
    };
  },

  saveToolAssessmentNameErr: (err) => {
    return {
      type: actions.SAVE_TOOL_ASSESSMENT_NAME_ERR,
      err,
    };
  },

  closeToolAssessmentBegin: () => {
    return {
      type: actions.CLOSE_TOOL_ASSESSMENT_NAME_BEGIN,
    };
  },

  closeToolAssessmentSuccess: (data) => {
    return {
      type: actions.CLOSE_TOOL_ASSESSMENT_NAME_SUCCESS,
      data,
    };
  },

  closeToolAssessmentErr: (err) => {
    return {
      type: actions.CLOSE_TOOL_ASSESSMENT_NAME_ERR,
      err,
    };
  },

  deleteToolAssessmentBegin: () => {
    return {
      type: actions.DELETE_TOOL_ASSESSMENT_NAME_BEGIN,
    };
  },

  deleteToolAssessmentSuccess: (data) => {
    return {
      type: actions.DELETE_TOOL_ASSESSMENT_NAME_SUCCESS,
      data,
    };
  },

  deleteToolAssessmentErr: (err) => {
    return {
      type: actions.DELETE_TOOL_ASSESSMENT_NAME_ERR,
      err,
    };
  },

  ToolsCopyProjectBegin: () => {
    return {
      type: actions.TOOLS_COPY_PROJECT_BEGIN,
    };
  },

  ToolsCopyProjectSuccess: (data) => {
    return {
      type: actions.TOOLS_COPY_PROJECT_SUCCESS,
      data,
    };
  },

  ToolsCopyProjectErr: (err) => {
    return {
      type: actions.TOOLS_COPY_PROJECT_ERR,
      err,
    };
  },

  ToolsTaskListBegin: () => {
    return {
      type: actions.TOOLS_TASK_LIST_BEGIN,
    };
  },

  ToolsTaskListSuccess: (data) => {
    return {
      type: actions.TOOLS_TASK_LIST_SUCCESS,
      data,
    };
  },

  ToolsTaskListErr: (err) => {
    return {
      type: actions.TOOLS_TASK_LIST_ERR,
      err,
    };
  },
};

export default actions;
