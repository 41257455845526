import { useState, useEffect } from 'react';
import { Button, Form, Input, Popconfirm, Spin, Typography } from 'antd';
import Popup from '../../../../ui-elements/Popup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import useTargetAudience from '../../../hook/useTargetAudience';
import { useTargetAudienceDispatch, useTargetAudienceState } from '../../../context/targetAudience';
import styled from 'styled-components';
import { reducerTypes } from '../../../context/targetAudience/constants';
const { Title, Text } = Typography;

const EditJobRoleModal = () => {
  const [form] = Form.useForm();
  const [confirmLoadingDeleteButton, setConfirmLoadingDeleteButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    models: { editModal },
  } = useTargetAudienceState();
  const dispatch = useTargetAudienceDispatch();
  const { updateGroupLocationsAndJobs } = useTargetAudience();
  const { data: jobRoleData, groupLevelId, parentGroupLevelId } = editModal ?? {};

  useEffect(() => {
    if (jobRoleData !== undefined && jobRoleData !== null) {
      const formData = {
        jobRole: jobRoleData?.title,
      };

      form.setFieldsValue(formData);
    } else {
      form.resetFields();
    }
  }, [jobRoleData]);

  const onFinish = async (values) => {
    setLoading(true);
    const jobRole = {
      groupLevelId,
      groupId: jobRoleData?.parentGroupLevelId ?? parentGroupLevelId,
      id: jobRoleData?.jobRoleId,
      jobRole: values.jobRole,
    };

    await updateGroupLocationsAndJobs({
      jobs: [jobRole],
    });
    dispatch({
      type: reducerTypes.RELOAD,
      payload: 'all-view',
    });
    setLoading(false);
    close();
  };

  const jobDeletion = async () => {
    updateGroupLocationsAndJobs({
      jobs: [
        {
          id: jobRoleData.jobRoleId,
          deleted: true,
        },
      ],
    });

    dispatch({
      type: reducerTypes.RELOAD,
      payload: 'all-view',
    });

    setLoading(false);
    close();
  };

  const close = () => {
    dispatch({
      type: reducerTypes.SET_EDIT_MODAL,
      payload: null,
    });
  };

  return (
    <Popup
      open={editModal?.type && editModal?.type === 'jobRole'}
      close={close}
      title={`${jobRoleData !== undefined && jobRoleData !== null ? 'Edit ' : 'Add'} Job Role`}
      footer={null}
    >
      <Form form={form} onFinish={onFinish}>
        <Container>
          <Wrapper>
            <InputContainer>
              <label>Job Role Name</label>
              <Form.Item name="jobRole">
                <Input />
              </Form.Item>
              {!!jobRoleData && (
                <span>Note: This update will be applied to all connected job roles and/or individuals.</span>
              )}
            </InputContainer>
          </Wrapper>

          <ButtonContainer>
            <Button type={'text'} onClick={close}>
              Cancel
            </Button>
            <Button
              shape={'round'}
              style={{ background: loading ? 'gray' : '#0066B4', color: '#fff' }}
              type={'primary'}
              htmlType="submit"
              disabled={loading}
            >
              {loading ? <Spin /> : 'Save'}
            </Button>
            {jobRoleData !== undefined && jobRoleData !== null && (
              <div>
                <Popconfirm
                  overlayClassName="deletePopUp"
                  title={
                    <Title level={2} type={'danger'}>
                      Warning
                    </Title>
                  }
                  icon={
                    <FontAwesomeIcon
                      icon={duotone('circle-question')}
                      size="xl"
                      style={{
                        '--fa-primary-color': '#ff8080',
                        '--fa-secondary-color': '#ff8080',
                        marginRight: '20px',
                        paddingTop: '10px',
                      }}
                    />
                  }
                  description={
                    <div style={{ width: '100%' }}>
                      <p>
                        <Text style={{ fontSize: '23px', paddingBottom: '20px' }} italic>
                          You are about to delete your entire audience list which contains all groups, subgroups, and/or
                          individuals.
                        </Text>
                      </p>
                      <p>
                        <Text type="secondary" style={{ fontSize: '23px', paddingBottom: '20px' }}>
                          This action cannot be undone.
                        </Text>
                      </p>
                      <p>
                        <Text type="secondary" style={{ fontSize: '23px', paddingBottom: '20px' }}>
                          Are you sure you want to proceed?
                        </Text>
                      </p>
                    </div>
                  }
                  onConfirm={async () => {
                    setConfirmLoadingDeleteButton(true);
                    await jobDeletion();
                    setConfirmLoadingDeleteButton(false);
                  }}
                  onCancel={() => { }}
                  okText="Delete"
                  cancelText="Cancel"
                  okButtonProps={{
                    danger: true,
                    loading: confirmLoadingDeleteButton,
                    shape: 'round',
                    size: 'large',
                  }}
                  cancelButtonProps={{
                    danger: false,
                    shape: 'round',
                    size: 'large',
                    type: 'default',
                  }}
                >
                  <Text
                    type="danger"
                    strong
                    italic
                    underline
                    style={{
                      paddingLeft: '10px',
                      fontSize: '15px',
                      cursor: 'pointer',
                    }}
                  >
                    Delete
                  </Text>
                </Popconfirm>
              </div>
            )}
          </ButtonContainer>
        </Container>
      </Form>
    </Popup>
  );
};

const Container = styled.div`
  display: flex;
  width: 370px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 50px;
`;

const InputContainer = styled.div`
  width: 330px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  span {
    color: #a6a2a2;
  }
  input {
    border-radius: 6px !important;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  gap: 50px;
`;

export default EditJobRoleModal;
