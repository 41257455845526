import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Select, Row, Col, Button, Typography } from 'antd';
const {Title, Text} = Typography;
import { DataService } from '../../config/dataService/dataService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { OCMModal, OCMModalHeader } from '../tools/Style';
import '../tools/panel/widgets/style.css';
import { Link } from 'react-router-dom';
import useNotificationController from '../../utility/useNotificationController';
import OCMSelectFilter from '../../components/OCMSelect/OCMSelectFilter';

const CreateUserModal = ({
  isModalVisible,
  setIsModalVisible,
  handleSave,
  cancelData,
  userData,
  isEditing,
  hasUserLicenses,
  hasGuestLicenses,
  accountOwnerId,
  organizationName,
  setUserLiceses,
  setGuestLiceses
}) => {
  const {
    contextHolder,
    openError,
    openSuccess
  } = useNotificationController();
  
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectProject, setSelectProject] = useState([]);
  const [selectedAllProject, setSelectedAllProject] = useState(false);
  const [filterProject, setFilterProject] = useState("");
  const [projects, setProjects] = useState([]);
  const [showProject, setShowProject] = useState(userData && userData.userType > 2 ? true : false);
  const organizationalSlug = localStorage.getItem('organizationalSlug');

  const [createUserModalButton, setCreateUserModalButton] = useState({
    buttonText: "Create User",
    submittingFrom: false,
    isDisabled: false
  });

  const onChange = (selectedItems) => {
    setSelectProject(selectedItems);
  }

  const onFinish = async (values) => {
    values = { ...values, organizationName: organizationName, projects: selectProject, selectedAllProject: selectedAllProject, filterProject: filterProject };
    if (userData) {
      values = { ...values, id: userData.id };
    }
    if (userData)
    {
      setCreateUserModalButton({
        buttonText: "Updating User, please wait....",
        submittingFrom: true,
        isDisabled: true
      });
    }
    else
    {
      setCreateUserModalButton({
        buttonText: "Creating User, please wait....",
        submittingFrom: true,
        isDisabled: true
      });
    }
    
    const response = await DataService.post('/users/create-account-user', values);
    if (response.status == 200) {
      if (userData)
      {
        setCreateUserModalButton({
          buttonText: "Update User",
          submittingFrom: false,
          isDisabled: false
        });
      }
      else
      {
        setCreateUserModalButton({
          buttonText: "Create User",
          submittingFrom: false,
          isDisabled: false
        });
      }
      if (userData)
      {
        handleSave(response.data.accountUser, true);
      }
      else
      {
        handleSave(response.data.accountUser, false);
      }
      //rearrangeProject();
      let remainingUsers = response.data.remainingUser;
      let remainingGuest = response.data.remainingGuest;
      if (remainingUsers <= 0)
      {
        setUserLiceses(false);
      }
      else
      {
        setUserLiceses(true);
      }
      if (remainingGuest <= 0)
      {
        setGuestLiceses(false);
      }
      else
      {
        setGuestLiceses(true);
      }
      if (userData) 
      {
        openSuccess('bottomRight', "User Management",  `User updated successfully`);
      }
      else{
        openSuccess('bottomRight', "User Management",  `User created successfully`);
      } 
    }
    else
    {
      if (userData)
      {
        setCreateUserModalButton({
          buttonText: "Update User",
          submittingFrom: false,
          isDisabled: false
        });
      }
      else
      {
        setCreateUserModalButton({
          buttonText: "Create User",
          submittingFrom: false,
          isDisabled: false
        });
      }
      openError("topLeft", "Create User Error", response.data.message);
      return ;
    }
    cancelData();
    setIsModalVisible(false);
    form.resetFields();
    setLoading(false);
  };

  useEffect(() => {
    if (userData)
    {
      const retrieveData = async (value) => {
        if (value > 2)
        {
          //await getProjects();
          setShowProject(true);
        }
        else
        {
          setShowProject(false);
        }
      };
      retrieveData(userData.userType).catch(console.error);
      
      form.setFieldsValue({
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        userType: userData.userType,
        username: userData.username,
        jobTitle: userData.jobTitle,
        projects: [],
      });

      setCreateUserModalButton({
        buttonText: "Update User",
        submittingFrom: false,
        isDisabled: false
      });
    }
    else
    {
      setCreateUserModalButton({
        buttonText: "Create User",
        submittingFrom: false,
        isDisabled: false
      });
      setShowProject(false);
    }
  }, [userData]);

  const getProjects = async () => {
    const response = await DataService.post('/project/get-all-projects', {page: 'all'});
    if (response.status == 200) {
      setProjects(response.data.projects);
    }
  }

  const changeRole = async (value) => {
    if (value > 2)
    {
      //await getProjects();
      setShowProject(true);
    }
    else
    {
      setShowProject(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    cancelData();
    setIsModalVisible(false);
  };

  const isPaidAccount = localStorage.getItem('is_paid') === 'true';

  return (
    <OCMModal>
      {contextHolder}
      <Modal
        style={{
          margin: '20px 0px',
        }}
        className="add-change"
        title={
          <OCMModalHeader>
            <div className="user-info-header-panel">
              <h1 className="header-title">{userData ? 'Edit User' : 'Create User'}</h1>
              <div className="ribon none"></div>
            </div>
          </OCMModalHeader>
        }
        centered
        closeIcon={<FontAwesomeIcon size="2xl" icon={duotone('circle-xmark')} />}
        open={isModalVisible}
        wrapClassName={'ocm-modal'}
        onCancel={handleCancel}
        width={800}
        footer={[,]}
      >
        <Form layout="vertical" form={form} onFinish={onFinish} style={{ padding: '20px' }}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[{ required: true, message: 'Please input your first name!' }]}
              >
                <Input placeholder="First Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[{ required: true, message: 'Please input your last name!' }]}
              >
                <Input placeholder="Last Name" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: 'Please input your email!' },
                  { type: 'email', message: 'Please enter a valid email!' },
                  {
                    validator: async (_, value) => {
                      if (!value) {
                        return;
                      }
                      try {
                        let sendData = { email: value };
                        if (userData) {
                          sendData = { email: value, id: userData.id };
                        }
                        const response = await DataService.post('/users/check-email', sendData);
                        const data = await response.data;
                        if (data.exists) {
                          return Promise.reject(new Error('Email already exists!'));
                        }
                      } catch (err) {
                        console.error(err);
                        return Promise.reject(new Error('Validation failed!'));
                      }
                    },
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </Col>
            <Col span={12}>
              {userData == undefined || parseInt(userData.id) !== parseInt(accountOwnerId) ? (
                <Form.Item
                  label="User Role"
                  name="userType"
                  rules={[{ required: true, message: 'Please select a user role!' }]}
                >
                  <Select 
                    placeholder="Select Role"
                    onChange={changeRole}
                    className={'changeRoleUser'}
                  >
                    {!hasUserLicenses && hasGuestLicenses && (
                      <Select.Option
                        value={4}
                        key="4"
                      >
                        <Title level={5}>Guest</Title>
                        <Text type='secondary'>Has view access only</Text>
                      </Select.Option>
                    )}
                    <Select.Option
                      value={1}
                      key="1"
                      disabled={hasUserLicenses ? false : isEditing ? false : true}
                      // style={{
                      //   backgroundColor: '#DCF3F1',
                      // }}
                    >
                      <Title level={5}>Account Owner</Title>
                      {hasUserLicenses ? (
                        <Text type='secondary'>Can do everything, including manage subscriptions</Text>
                      ) : (
                        !isEditing && (
                          <Text type='danger' style={{whiteSpace: "normal"}}>
                            (You don't have any User licenses, to purchase more User licenses <Link to={`/${organizationalSlug}/${isPaidAccount ? 'subscription/update-subscription' : 'subscribe-now'}`}>click here</Link>)
                          </Text>
                        )
                      )}
                    </Select.Option>
                    <Select.Option
                      value={2}
                      key="2"
                      disabled={hasUserLicenses ? false : isEditing ? false : true}
                      style={{
                        backgroundColor: 'white',
                      }}
                    >
                      <Title level={5}>Admin</Title>
                      {hasUserLicenses ? (
                        <Text type='secondary'>Can do everything, except manage subscriptions</Text>
                      ) : (
                        !isEditing && (
                          <Text type='danger' style={{whiteSpace: "normal"}}>
                            (You don't have any User licenses, to purchase more User licenses <Link to={`/${organizationalSlug}/${isPaidAccount ? 'subscription/update-subscription' : 'subscribe-now'}`}>click here</Link>)
                          </Text>
                        )
                      )}
                    </Select.Option>
                    <Select.Option
                      value={3}
                      key="3"
                      disabled={hasUserLicenses ? false : isEditing ? false : true}
                      // style={{
                      //   backgroundColor: '#DCF3F1',
                      // }}
                    >
                      <Title level={5}>Member</Title>
                      {hasUserLicenses ? (
                        <Text type='secondary'>Can edit & work on assigned tasks and projects</Text>
                      ) : (
                        !isEditing && (
                          <Text type='danger' style={{whiteSpace: "normal"}}>
                            (You don't have any User licenses, to purchase more User licenses <Link to={`/${organizationalSlug}/${isPaidAccount ? 'subscription/update-subscription' : 'subscribe-now'}`}>click here</Link>)
                          </Text>
                        )
                      )}
                    </Select.Option>
                    {hasUserLicenses ? (
                      <Select.Option
                          value={4}
                          key="4"
                          disabled={hasGuestLicenses ? false : isEditing ? false : true}
                          // style={{
                          //   backgroundColor: '#DCF3F1',
                          // }}
                        >
                          <Title level={5} type={!hasGuestLicenses ? 'secondary' : ''}>Guest</Title>
                          {hasGuestLicenses ? (
                            <Text type='secondary'>Has view access only</Text>
                          ) : (
                            !isEditing && (
                              <Text type='danger' style={{whiteSpace: "normal"}}>
                                (You don't have any Guest licenses, to purchase more Guest licenses <Link to={`/${organizationalSlug}/subscription/update-subscription`}>click here</Link>)
                              </Text>
                            )
                          )}
                        </Select.Option>
                    ) : null}
                  </Select>
                </Form.Item>
              ) : (
                userData && userData.userType == 1 && <Form.Item label="User Role">Account Owner</Form.Item>
              )}
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  { required: true, message: 'Please input your username!' },
                  {
                    validator: async (_, value) => {
                      if (!value) {
                        return;
                      }
                      try {
                        let sendData = { username: value };
                        if (userData) {
                          sendData = { username: value, id: userData.id };
                        }
                        const response = await DataService.post('/users/check-username', sendData);
                        const data = await response.data;
                        if (data.exists) {
                          return Promise.reject(new Error('Username already exists!'));
                        }
                      } catch (err) {
                        return Promise.reject(new Error(err));
                      }
                    },
                  },
                ]}
              >
                <Input placeholder="Username" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Job Title" name="jobTitle">
                <Input placeholder="Job Title" />
              </Form.Item>
            </Col>
          </Row>

          {showProject && (
            <Row gutter={16}>
              <Col span={24}>
                <OCMSelectFilter
                  mode='multiple'
                  className='projectTag'
                  maxTagCount={3}
                  label="Projects"
                  placeholder="Select Projects"
                  name="projects"
                  size="large"
                  type="Project"
                  values={userData && userData.userType > 2 && userData.projects_ids !== null && userData.projects_ids.split(",").length > 0 ? userData.projects_ids.split(",").map(projectId => parseInt(projectId)) : []}
                  axiosURL={'/project/get-all-projects'}
                  axiosVariable={'projects'}
                  optionValue={'projectName'}
                  filterVariable={'projectName'}
                  hasMoreDataValue={'hasMore'}
                  onChange={onChange}
                  setSelectedAllProject={setSelectedAllProject}
                  setFilterProject={setFilterProject}
                  rules={[{ required: true, message: 'Please select at least one project!', type: 'array' }]}
                />
                {/* <Form.Item
                  label="Projects"
                  name="projects"
                  rules={[{ required: true, message: 'Please select at least one project!', type: 'array' }]}
                >
                  <Select 
                    mode="multiple" 
                    placeholder="Select Projects"
                    size="large"
                  >
                    {projects.map((project, index) => {
                      return (
                        <Select.Option
                          key={index}
                          style={{
                            backgroundColor: index % 2 === 0 ? '#DCF3F1' : 'white',
                          }}
                          value={project.id}
                        >
                          {project.projectName}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item> */}
              </Col>
            </Row>
          )}      
          
          <Row gutter={16} style={{ marginTop: '10px' }}>
            <Col span={24} style={{ textAlign: 'center', display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Button
                type="ocm-default"
                htmlType="submit"
                disabled={createUserModalButton.isDisabled}
                loading={createUserModalButton.submittingFrom}
                style={{ 
                  fontSize: '20px',             // Increase font size
                  padding: '5px 46px 5px 46px', // Increase padding
                  lineHeight: '50px',           // Center the text vertically
                  borderRadius: "15px",
                }}
              >
                {userData ? "Update User" : createUserModalButton.buttonText}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </OCMModal>
  );
};

export default CreateUserModal;
