import { Form, Input, Typography } from 'antd';

const { Title, Text } = Typography;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';

function AddNewOrganization(props) {
  const newGroupStartIndex = Object.keys(props.currentGroupNames).filter(key => !props.currentGroupNames[key].new).length;
  return (
    <Form.List name={props.listKey}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => {
            return (
              <Form.Item
                {...restField}
                key={`${props.listKey}_${props.fieldName}_${key}`}
                size="large"
                name={[name, props.fieldKey]}
                style={{ marginBottom: 10 }}
                extra={(
                  <div className='group-area'>
                    <div className='icon-area'>
                      {newGroupStartIndex + name == 0 && <FontAwesomeIcon size='8x' icon={duotone("city")} style={{ "--fa-primary-color": "#0077ce", "--fa-secondary-color": "#20a0ff", }} />}
                      {newGroupStartIndex + name == 1 && <FontAwesomeIcon size='5x' icon={duotone("buildings")} style={{ "--fa-primary-color": "#0077ce", "--fa-secondary-color": "#20a0ff", }} />}
                      {newGroupStartIndex + name > 1 && <FontAwesomeIcon size='4x' icon={duotone("building")} style={{ "--fa-primary-color": "#0077ce", "--fa-secondary-color": "#20a0ff", }} />}

                    </div>
                    <Title
                      level={4}
                      editable={{
                        icon: <FontAwesomeIcon icon={duotone("pencil")} style={{"--fa-primary-color": "#0077ce", "--fa-secondary-color": "#20a0ff", marginLeft: "10px"}} />,
                        triggerType: ['icon', 'text'],
                        tooltip: 'click to edit initial group name',
                        onChange: (value) => {
                          props.setCurrentGroupNames({ ...props.currentGroupNames, [newGroupStartIndex + name]: { name: value, new: true } });
                        },
                      }}
                      className='editable-text-field'>
                      {props.currentGroupNames[newGroupStartIndex + name]?.name}
                    </Title>
                    {name === 0 ? (
                      <Text type='secondary'>(e.g., Department, Business Unit)</Text>
                    ) : (
                      <Text type='secondary'>(e.g., Team)</Text>
                    )}

                    {props.currentGroup !== 0 && newGroupStartIndex !== name && <Text
                      type="danger"
                      underline
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        let keyCount = 0;
                        const reducedGroupNames = Object.keys(props.currentGroupNames).reduce((acc, key) => {
                          if (Number(key) >= newGroupStartIndex + name) {
                            remove(name)
                            return {
                              ...acc,
                            }
                          }

                          const newKey = keyCount;
                          keyCount += 1;

                          return {
                            ...acc,
                            [newKey]: props.currentGroupNames[key]
                          }
                        }, {})

                        let currentGroup = keyCount - 1
                        props.setCurrentGroup(currentGroup);
                        props.setCurrentGroupNames(reducedGroupNames);
                      }}
                    >Delete
                    </Text>}
                  </div>
                )
                }
              >
                <Input type={'hidden'} placeholder={props.fieldLabel} />
              </Form.Item>
            )
          })}
          <Form.Item
            key={`tooltip-${props.listKey}_${props.fieldName}`}
            extra={(
              <Title level={2} style={{ marginTop: "50px" }}>
                <FontAwesomeIcon icon={duotone("circle-question")} style={{ "--fa-primary-color": "#0077ce", "--fa-secondary-color": "#20a0ff", marginRight: "20px", cursor: "pointer" }} onClick={props.showHelp} />
                Add sub group levels
                <FontAwesomeIcon icon={duotone("circle-plus")} style={{ "--fa-primary-color": "#0077ce", "--fa-secondary-color": "#20a0ff", marginLeft: "20px", cursor: "pointer" }}
                  onClick={() => {
                    let currentGroup = props.currentGroup;
                    currentGroup++;
                    props.setCurrentGroup(currentGroup);
                    props.setCurrentGroupNames({ ...props.currentGroupNames, [props.currentGroup + 1]: { name: `Group Level ${currentGroup + 1}`, new: true } });
                    add();
                  }} />
              </Title>
            )}
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
          </Form.Item>
        </>
      )}
    </Form.List>
  );
}

export default AddNewOrganization;
