import styled from "styled-components";
import { Flex } from "antd";
import { Link } from "react-router-dom";
import { OCMLogo } from "../../static/logo/ocms-logo-white-text.js";

function PublicSignUpHeader({ noName }) {
  return (
    <>
      <Flex
        className="border-bottom"
        style={{ background: "#333B4A", color: '#FFFFFF' }}
        justify={"space-between"}
        align={"center"}
      >
        <Logo>
          <OCMLogo />
        </Logo>
        {!noName ? <Heading>Change Management Solution</Heading> : <h1></h1>}
        <StyledLink
          to={`/`}
          style={{ color: "#FFFFFF" }}
        >
          Login
        </StyledLink>
      </Flex>
    </>
  );
}

export default PublicSignUpHeader;

const Heading = styled.h1`
  font-size: 10px;
  text-align: center;
  margin: 0;
  font-weight: 700;

  @media (min-width: 600px) {
    font-size: 24px;
  }
`;

const StyledLink = styled(Link)`
  margin-right: 10px;
  align-items: center;
  display: flex;
  text-decoration: underline;
  font-size: 16px;
  &:hover {
    text-decoration: underline;
  }
  @media (min-width: 600px) {
    margin-right: 20px;
  }
`;

const Logo = styled.div`
  margin-left: 10px;

  @media (min-width: 480px) {
    margin-left: 20px;
  }
`;
