import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const getSize = (size) => {
  switch (size) {
    case 'small':
      return '20px';
    case 'medium':
      return '40px';
    case 'large':
      return '60px';
    default:
      return size || '40px';
  }
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ backgroundOverlay }) => (backgroundOverlay ? 'rgba(0, 0, 0, 0.5)' : 'transparent')};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999999999999;
`;

const SpinnerContainer = styled.div`
  width: max-content;
  height: max-content;
  font-size: ${({ size }) => getSize(size)};
  animation: ${spinAnimation} 2s linear infinite;
`;

const PopupContent = styled.div`
  background: #0085EA;
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 450px;
  max-width: max-content;
  width: 100%;
  height: 300px;
  gap: 50px;
`;

const PopupHeading = styled.h2`
  margin-bottom: 20px;
  font-size: xx-large;
  color: #fff !important;
`;

const LoadingSpinner = ({ size, color, open, backgroundOverlay, popupHeading }) => {
  if (!open) {
    return null;
  }

  return (
    <Overlay backgroundOverlay={backgroundOverlay}>
      <PopupContent>
        <SpinnerContainer size={size}>
          <FontAwesomeIcon icon={faSpinner} color={color || '#fff'} />
        </SpinnerContainer>
        <PopupHeading>{popupHeading}</PopupHeading>
      </PopupContent>
    </Overlay>
  );
};

export default LoadingSpinner;
