import Cookies from 'js-cookie';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { saveLoginUserCookie } from '../../utility/utility';

const { 
  loginBegin, 
  loginSuccess, 
  loginErr, 

  forgotPassBegin, 
  forgotPassSuccess, 
  forgotPassErr, 

  validatePricingBegin, 
  validatePricingSuccess, 
  validatePricingErr, 

  createCustomerBegin, 
  createCustomerSuccess, 
  createCustomerErr, 

  updateCustomerBegin, 
  updateCustomerSuccess, 
  updateCustomerErr, 

  updateCustomerSubscriptionBegin, 
  updateCustomerSubscriptionSuccess, 
  updateCustomerSubscriptionErr, 

  getCustomerPaymentMethodsBegin, 
  getCustomerPaymentMethodsSuccess, 
  getCustomerPaymentMethodsErr, 

  addCustomerPaymentMethodBegin, 
  addCustomerPaymentMethodSuccess, 
  addCustomerPaymentMethodErr, 

  setupCustomerPaymentBegin, 
  setupCustomerPaymentSuccess, 
  setupCustomerPaymentErr, 

  reSetupCustomerPaymentBegin, 
  reSetupCustomerPaymentSuccess, 
  reSetupCustomerPaymentErr, 

  setCardasPrimaryBegin, 
  setCardasPrimarySuccess, 
  setCardasPrimaryErr, 

  deleteCustomerCardBegin, 
  deleteCustomerCardSuccess, 
  deleteCustomerCardErr, 

  cancelCustomerSubscriptionBegin, 
  cancelCustomerSubscriptionSuccess, 
  cancelCustomerSubscriptionErr, 

  changeSubscriptionBillingCycleBegin, 
  changeSubscriptionBillingCycleSuccess, 
  changeSubscriptionBillingCycleErr, 

  pauseCustomerSubscriptionBegin, 
  pauseCustomerSubscriptionSuccess, 
  pauseCustomerSubscriptionErr, 

  stopSubscriptionCancellationBegin, 
  stopSubscriptionCancellationSuccess, 
  stopSubscriptionCancellationErr, 

  resumeCustomerSubscriptionBegin, 
  resumeCustomerSubscriptionSuccess, 
  resumeCustomerSubscriptionErr, 

  deleteAccountSubscriptionBegin, 
  deleteAccountSubscriptionSuccess, 
  deleteAccountSubscriptionErr, 

  restartCustomerSubscriptionAgainBegin, 
  restartCustomerSubscriptionAgainSuccess, 
  restartCustomerSubscriptionAgainErr, 

  cancelDeletionOfCustomerAccountBegin, 
  cancelDeletionOfCustomerAccountSuccess, 
  cancelDeletionOfCustomerAccountErr, 

  resetPasswordBegin, 
  resetPasswordSuccess, 
  resetPasswordErr, 

  verifyUserBegin, 
  verifyUserSuccess, 
  verifyUserErr, 

  convertToTrialBegin, 
  convertToTrialSuccess, 
  convertToTrialErr, 

  logoutBegin, 
  logoutSuccess, 
  logoutErr } = actions;

const login = (values, callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.post('/auth/users', values);
      if (response.status !== 200) {
        const messages = dispatch(loginErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        saveLoginUserCookie(response, values['rememberMe']);
        let userData = JSON.parse(response.data.userData);
        dispatch(loginSuccess(true));
        callback({
          status: true,
          userType: userData.account_users[0].userType,
          isFreeAccount: userData.isFreeAccount,
          isTrainingRegistration: userData.isTrainingRegistration,
          isTrialAccount: userData.isTrialAccount,
          isPaidAccount: userData.isPaidAccount,
          organizationalSlug: userData.organizationalSlug
        });
      }
    } catch (err) {
      const messages = dispatch(loginErr(err));
      callback(messages);
    }
  };
};

const forgotPass = (values, callback) => {
  return async (dispatch) => {
    dispatch(forgotPassBegin());
    try {
      const response = await DataService.post('/auth/code/users', values);
      if (response.status !== 200) {
        const messages = dispatch(forgotPassErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        const messages = dispatch(forgotPassSuccess(response.data.message));
        callback(messages);
      }
    } catch (err) {
      const messages = dispatch(forgotPassErr(err));
      callback(messages);
    }
  };
};

const verifyUser = (values, callback) => {
  return async (dispatch) => {
    dispatch(verifyUserBegin());
    try {
      let queryString = "";
      if (Object.entries(values).length > 0)
      {
        queryString = Object.entries(values)
          .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
          .join('&');
        queryString = `?${queryString}`;
      }
      const response = await DataService.get(`/auth/veify/users${queryString}`);
      if (response.status !== 200) {
        const messages = dispatch(verifyUserErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(verifyUserSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(verifyUserErr(err));
      callback(messages);
    }
  };
};

const validatePricing = (values, callback) => {
  return async (dispatch) => {
    dispatch(validatePricingBegin());
    try {
      const response = await DataService.post('/auth/validate-pricing-for-user/users', values);
      if (response.status !== 200) {
        const messages = dispatch(validatePricingErr(response.data.message));
        callback(response);
      }
      else if (response.status === 200) {
        dispatch(validatePricingSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(validatePricingErr(err));
      callback(messages);
    }
  };
};

const createCustomer = (values, callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.post('/auth/create-customer/users', values);
      if (response.status !== 200) {
        const messages = dispatch(loginErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        saveLoginUserCookie(response);
        dispatch(loginSuccess(true));
        callback(true);
      }
    } catch (err) {
      const messages = dispatch(loginErr(err));
      callback(messages);
    }
  };
};

const setupCustomerPayment = (values, callback) => {
  return async (dispatch) => {
    dispatch(setupCustomerPaymentBegin());
    try {
      const response = await DataService.post('/auth/setup-customer-payment-method/users', values);
      if (response.status !== 200) {
        const messages = dispatch(setupCustomerPaymentErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(setupCustomerPaymentSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(setupCustomerPaymentErr(err));
      callback(messages);
    }
  };
};

const reSetupCustomerPayment = (values, callback) => {
  return async (dispatch) => {
    dispatch(reSetupCustomerPaymentBegin());
    try {
      const response = await DataService.post('/auth/re-setup-customer-payment-method/users', values);
      if (response.status !== 200) {
        const messages = dispatch(reSetupCustomerPaymentErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(reSetupCustomerPaymentSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(reSetupCustomerPaymentErr(err));
      callback(messages);
    }
  };
};

const updateCustomer = (values, callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.post('/auth/update-customer-to-paid/users', values);
      if (response.status !== 200) {
        const messages = dispatch(loginErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        saveLoginUserCookie(response);
        dispatch(loginSuccess(true));
        callback(true);
      }
    } catch (err) {
      const messages = dispatch(loginErr(err));
      callback(messages);
    }
  };
};

const updateCustomerSubscription = (values, callback) => {
  return async (dispatch) => {
    dispatch(updateCustomerSubscriptionBegin());
    try {
      const response = await DataService.post('/auth/update-customer-subscription/users', values);
      if (response.status !== 200) {
        const messages = dispatch(updateCustomerSubscriptionErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(updateCustomerSubscriptionSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(updateCustomerSubscriptionErr(err));
      callback(messages);
    }
  };
};

const getCustomerPaymentMethods = (callback) => {
  return async (dispatch) => {
    dispatch(getCustomerPaymentMethodsBegin());
    try {
      const response = await DataService.get('/defaultData/get-customer-payment-methods');
      if (response.status !== 200) {
        const messages = dispatch(getCustomerPaymentMethodsErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(getCustomerPaymentMethodsSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(getCustomerPaymentMethodsErr(err));
      callback(messages);
    }
  };
};

const addCustomerPaymentMethod = (values, callback) => {
  return async (dispatch) => {
    dispatch(addCustomerPaymentMethodBegin());
    try {
      const response = await DataService.post('/auth/add-customer-card/users', values);
      if (response.status !== 200) {
        const messages = dispatch(addCustomerPaymentMethodErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(addCustomerPaymentMethodSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(addCustomerPaymentMethodErr(err));
      callback(messages);
    }
  };
};

const setCardasPrimary = (values, callback) => {
  return async (dispatch) => {
    dispatch(setCardasPrimaryBegin());
    try {
      const response = await DataService.post('/auth/set-customer-card-as-primary/users', values);
      if (response.status !== 200) {
        const messages = dispatch(setCardasPrimaryErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(setCardasPrimarySuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(setCardasPrimaryErr(err));
      callback(messages);
    }
  };
};

const deleteCustomerCard = (values, callback) => {
  return async (dispatch) => {
    dispatch(deleteCustomerCardBegin());
    try {
      const response = await DataService.post('/auth/delete-card-from-customer/users', values);
      if (response.status !== 200) {
        const messages = dispatch(deleteCustomerCardErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(deleteCustomerCardSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(deleteCustomerCardErr(err));
      callback(messages);
    }
  };
};

const cancelCustomerSubscription = (values, callback) => {
  return async (dispatch) => {
    dispatch(cancelCustomerSubscriptionBegin());
    try {
      const response = await DataService.post('/auth/cancel-subscription/users', values);
      if (response.status !== 200) {
        const messages = dispatch(cancelCustomerSubscriptionErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(cancelCustomerSubscriptionSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(cancelCustomerSubscriptionErr(err));
      callback(messages);
    }
  };
};

const changeSubscriptionBillingCycle = (values, callback) => {
  return async (dispatch) => {
    dispatch(changeSubscriptionBillingCycleBegin());
    try {
      const response = await DataService.post('/auth/change-billing-cycle/users', values);
      if (response.status !== 200) {
        const messages = dispatch(changeSubscriptionBillingCycleErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(changeSubscriptionBillingCycleSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(changeSubscriptionBillingCycleErr(err));
      callback(messages);
    }
  };
};

const pauseCustomerSubscription = (values, callback) => {
  return async (dispatch) => {
    dispatch(pauseCustomerSubscriptionBegin());
    try {
      const response = await DataService.post('/auth/pause-subscription/users', values);
      if (response.status !== 200) {
        const messages = dispatch(pauseCustomerSubscriptionErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(pauseCustomerSubscriptionSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(pauseCustomerSubscriptionErr(err));
      callback(messages);
    }
  };
};

const stopSubscriptionCancellation = (callback) => {
  return async (dispatch) => {
    dispatch(stopSubscriptionCancellationBegin());
    try {
      const response = await DataService.post('/auth/stop-subscription-cancellation/users');
      if (response.status !== 200) {
        const messages = dispatch(stopSubscriptionCancellationErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(stopSubscriptionCancellationSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(stopSubscriptionCancellationErr(err));
      callback(messages);
    }
  };
};

const resumeCustomerSubscription = (values, callback) => {
  return async (dispatch) => {
    dispatch(resumeCustomerSubscriptionBegin());
    try {
      const response = await DataService.post('/auth/resume-subscription/users', values);
      if (response.status !== 200) {
        const messages = dispatch(resumeCustomerSubscriptionErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(resumeCustomerSubscriptionSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(resumeCustomerSubscriptionErr(err));
      callback(messages);
    }
  };
};

const deleteAccountSubscription = (values, callback) => {
  return async (dispatch) => {
    dispatch(deleteAccountSubscriptionBegin());
    try {
      const response = await DataService.post('/auth/delete-account-subscription/users', values);
      if (response.status !== 200) {
        const messages = dispatch(deleteAccountSubscriptionErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(deleteAccountSubscriptionSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(deleteAccountSubscriptionErr(err));
      callback(messages);
    }
  };
};

const restartCustomerSubscriptionAgain = (callback) => {
  return async (dispatch) => {
    dispatch(restartCustomerSubscriptionAgainBegin());
    try {
      const response = await DataService.post('/auth/restart-customer-subscription/users');
      if (response.status !== 200) {
        const messages = dispatch(restartCustomerSubscriptionAgainErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(restartCustomerSubscriptionAgainSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(restartCustomerSubscriptionAgainErr(err));
      callback(messages);
    }
  };
};

const cancelDeletionOfCustomerAccount = (callback) => {
  return async (dispatch) => {
    dispatch(cancelDeletionOfCustomerAccountBegin());
    try {
      const response = await DataService.post('/auth/cancel-deletion-customer-account/users');
      if (response.status !== 200) {
        const messages = dispatch(cancelDeletionOfCustomerAccountErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(cancelDeletionOfCustomerAccountSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(cancelDeletionOfCustomerAccountErr(err));
      callback(messages);
    }
  };
};

const convertToTrial = (values, callback) => {
  return async (dispatch) => {
    dispatch(convertToTrialBegin());
    try {
      const response = await DataService.post('/auth/convert-to-trial/users', values);
      if (response.status !== 200) {
        const messages = dispatch(convertToTrialErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(convertToTrialSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(convertToTrialErr(err));
      callback(messages);
    }
  };
};

const resetPassword = (values, callback) => {
  return async (dispatch) => {
    dispatch(resetPasswordBegin());
    try {
      const response = await DataService.postResetPassword('/auth/reset/users', values);
      if (response.status !== 200) {
        const messages = dispatch(resetPasswordErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        const messages = dispatch(resetPasswordSuccess(response.data));
        callback(messages);
      }
    } catch (err) {
      const messages = dispatch(resetPasswordErr(err));
      callback(messages);
    }
  };
};

const register = (values, callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.post('/auth/register/users', values);
      if (response.status !== 200) {
        const messages = dispatch(loginErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        saveLoginUserCookie(response);
        dispatch(loginSuccess(true));
        callback(true);
      }
    } catch (err) {
      const messages = dispatch(loginErr(err));
      callback(messages);
    }
  };
};

const logOut = (callback) => {
  return async (dispatch) => {
    dispatch(logoutBegin());
    try {
      let userId = localStorage.getItem('user_id');
      Cookies.set(`showFeedback${userId}`, 'yes', { expires: 30 });
      await DataService.post('/auth/logout/users');
      localStorage.clear();
      Cookies.remove('logedIn');
      Cookies.remove('rememberMe');  
      Cookies.remove('access_token');
      dispatch(logoutSuccess(false));
      callback();
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { 
  login, 
  forgotPass, 
  verifyUser, 
  validatePricing, 
  setupCustomerPayment, 
  reSetupCustomerPayment, 
  createCustomer, 
  updateCustomer, 
  updateCustomerSubscription, 
  getCustomerPaymentMethods, 
  addCustomerPaymentMethod, 
  setCardasPrimary, 
  deleteCustomerCard, 
  cancelCustomerSubscription, 
  changeSubscriptionBillingCycle, 
  pauseCustomerSubscription, 
  stopSubscriptionCancellation, 
  resumeCustomerSubscription, 
  deleteAccountSubscription, 
  restartCustomerSubscriptionAgain, 
  cancelDeletionOfCustomerAccount, 
  convertToTrial,
  resetPassword, 
  logOut, 
  register 
};
