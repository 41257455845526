import React, { useEffect, useState } from 'react';

import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, Tooltip } from 'antd';
import styled from 'styled-components';
import { utils, writeFile } from 'xlsx';

const ReportTableData = ({ title, columns = [], data, excelData, divClassName = '', handleScroll, exportListData }) => {
  const downloadExcel = () => {
    if (exportListData) {
      exportListData();
    } else {
      const ws = utils.json_to_sheet(excelData || data, { header: columns.map(col => col.title) });
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, 'Sheet1');
      writeFile(wb, title + '.xlsx');
    }
  };

  // Modify columns to include custom render for arrays
  const modifiedColumns = columns.map(col => ({
    ...col,
    render: (text, record) => {
      if (Array.isArray(text)) {
        return text.join(', ');
      }
      return text;
    }
  }));

  return (
    <>
      <div
        className={`${divClassName}`}
        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}
      >
        <h2 style={{color: '#404040'}}>Report Detail</h2>
        <Tooltip title="Export to Excel">
          <FontAwesomeIcon
            onClick={() => downloadExcel()}
            style={{
              cursor: 'pointer',
              '--fa-primary-color': '#BFBFBF',
              '--fa-secondary-color': '#BFBFBF',
              width: '24px',
              height: 'auto',
            }}
            icon={duotone('file-excel')}
          />
        </Tooltip>
      </div>

      <StyledTable columns={modifiedColumns} scroll={{ y: 400 }} dataSource={data} pagination={false} onScroll={handleScroll} />
    </>
  );
};

const StyledTable = styled(Table)`
  height: 100%;
  max-height: 600px;
  overflow: auto;
  border-bottom: #d9d9d9 1px solid;
  border-radius: 0px;
  .ant-table-thead {
    background-color: #f2f2f2;
    color: #404040;
    position: sticky;
    top: 0;
    z-index: 10000;
  }

  .ant-table-thead th {
    border-right: 1px solid #d9d9d9;
    color: #404040;
  }

  .ant-table-thead th:last-child {
    border-right: none;
  }

  .ant-table-thead th:hover {
    background-color: #f2f2f2;
    cursor: pointer;
  }

  .ant-table-thead th:not(:hover) {
    background-color: inherit;
    color: #404040;
  }
  .ant-table-tbody {
    border-bottom: 1px solid #d9d9d9;
  }
  .ant-table-cell {
    padding: 0px;
  }

  .ant-table-cell {
    border: 1px solid #d9d9d9;
    border-right: 0px;
  }
  .ant-table-cell:last-child {
    border-right: 1px solid #d9d9d9;
  }
`;

export default ReportTableData;
