import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

const formatTrainingsTableData = (tabledata = []) => {
  const timezoneString = localStorage.getItem('timezone');
  const trainingsTableData = tabledata?.map(training => {
    const timeRegex = /^Session \d+ (End Time|Start Time)$/;
    const dateRegex = /^Session \d+ (Date)$/;
    const formattedTraining = training;
    Object.keys(training).forEach(key => {
      if(timeRegex.test(key) && training[key]) {
        formattedTraining[key] = dayjs(training[key]).format('hh:mm A')
        // formattedTraining[key] = dayjs(training[key]).tz(timezoneString).format('hh:mm A')
      } else if (dateRegex.test(key) && training[key]) {
        formattedTraining[key] = dayjs(training[key]).format('MMMM DD, YYYY')
      }
    })
    return formattedTraining
  })
  return trainingsTableData;
}

export { formatTrainingsTableData }