import { Col, Row, Tabs, Input, Divider, Modal, Typography, Button, List, Skeleton, Card } from 'antd';

const {Title} = Typography;

import Heading from '../../components/heading/heading';
import { Main } from '../styled';
import { SurveyDashboardPage } from './Style';
import { useEffect, useState } from 'react';
import { DataService } from '../../config/dataService/dataService';
import SurveyCards from './components/SurveyCards';
import NoSurvey from './components/NoSurvey';
import SurveysList from './components/SurveysList';
import styled from 'styled-components';
import AllSurveyTemplates from './components/AllSurveyTemplates/AllSurveyTemplates';
import Subscription from './components/Popup/Subscription';
const { theme } = require('../../config/theme/themeVariables');

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core';
import  * as ProIcons from '@fortawesome/pro-duotone-svg-icons'
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';

import { OCMModal, OCMModalHeader, VideoTutorialsStyle } from '../tools/Style';
import ReactPlayer from 'react-player';

const VideoContainer = ({video_tutorials}) => {
  const smallScreen = window.innerWidth <= 1400;
  const userTheme = JSON.parse(localStorage.getItem('userTheme'))
  theme['user-theme'] = userTheme;
  const [initLoading, setInitLoading] = useState(false);
  const [openVideo, setOpenVideo] = useState(false);
  const [videoVisible, setVideoVisible] = useState(false);
  const [folder, setFolder] = useState("amazon");
  const [videos, setVideos] = useState([]);

  const is_amazon = localStorage.getItem('is_amazon') === 'true';
  
  if (video_tutorials)
  {
    if (!Array.isArray(video_tutorials))
    {
      let icon = video_tutorials.faicon;
      library.add(ProIcons[icon]);
    }
    else
    {
      for(var i in video_tutorials)
      {
        let icon = video_tutorials[i].faicon;
        library.add(ProIcons[icon]);
      }
    }
  }

  useEffect(() => {
    if (!is_amazon)
    {
      setFolder("ocms");
    }
    let allVideos = [];
    if (!Array.isArray(video_tutorials))
    {
      if (video_tutorials.videos !== undefined && video_tutorials.videos.length > 0)
      {
        video_tutorials.videos.map(item => {
          if (item.video_for === "both")
          {
            allVideos.push(item);
          }
          else
          {
            if (!is_amazon && item.video_for === "ocms")
            {
              allVideos.push(item);
            }
            else if (is_amazon && item.video_for === "amazon")
            {
              allVideos.push(item);
            }
          }
        })
      }
    }
    else
    {
      for(var i in video_tutorials)
      {
        let video_tutorials_videos = video_tutorials[i];
        if (video_tutorials_videos.videos !== undefined && video_tutorials_videos.videos.length > 0)
        {
          video_tutorials_videos.videos.map(item => {
            if (item.video_for === "both")
            {
              allVideos.push(item);
            }
            else
            {
              if (!is_amazon && item.video_for === "ocms")
              {
                allVideos.push(item);
              }
              else if (is_amazon && item.video_for === "amazon")
              {
                allVideos.push(item);
              }
            }
          })
        }
      }
    }
    setVideos(allVideos);
  }, []);
  
  const [videoInformation, setvideoInformation] = useState({
    name: '',
    url: '',
    isVisible: false,
  });
  const [player, setPlayer] = useState(false);

  const showVideoModal = (videoInfo) => {
    setvideoInformation({
      name: videoInfo.name,
      url: videoInfo.video_link,
      isVisible: true,
    });
    setVideoVisible(true);
  };

  const hideVideoModal = async () => {
    setPlayer(false);
    setvideoInformation({
      name: '',
      url: '',
      isVisible: false,
    });
  };

  const loadVideo = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (videos !== undefined && videos.length > 0)
    {
      setOpenVideo(true);
    }
    return false;
  }
  
  return (
    <>
      {videos.length > 0 && (
        <div style={{
          position: "absolute",
          marginBottom: "10px",
          right: "5px",
          top: "-10px"
        }}>
          <Button 
            className={`video_tutorials`}
            size='large' 
            icon={
              <FontAwesomeIcon icon={`fa-${video_tutorials[0].icon_type} fa-${video_tutorials[0].icon}`} size="xl" style={{"--fa-primary-color": `${userTheme.iconColor}`, "--fa-secondary-color": `${userTheme.iconColor}`}}  />
            }
            onClick={(e) => loadVideo(e)}
          ><span className='buttonText'>{video_tutorials[0].label}</span></Button>
        </div>
      )}
      <OCMModal>
      {openVideo && (
        <Modal
          style={{
            margin: '20px 0px',
          }}
          className="add-change"
          title={
            <OCMModalHeader>
              <div className="user-info-header-panel">
                <h1 className="header-title">
                {video_tutorials[0].label}
                </h1>
                <div className="ribon none"></div>
              </div>
            </OCMModalHeader>
          }
          centered
          closeIcon={<FontAwesomeIcon size="2xl" icon={duotone('circle-xmark')} />}
          open={openVideo}
          onCancel={() => setOpenVideo(false)}
          onOk={() => setOpenVideo(false)}
          width={800}
          wrapClassName={'ocm-modal'}
          footer={[]}
        >
            <VideoTutorialsStyle theme={theme}>
              <List
                style={{ height: '250px' }}
                className="loadmore-list"
                loading={initLoading}
                itemLayout="horizontal"
                // loadMore={loadMore}
                grid={{ gutter: smallScreen ? 2 : 10, column: 4 }}
                dataSource={videos}
                renderItem={(video) => (
                  <List.Item>
                    <Skeleton avatar title={false} loading={video.loading} active>
                      <List.Item>
                        <Card
                          title={
                            <>
                              <Title level={5} onClick={() => showVideoModal(video)} style={{minHeight: "73px"}}>
                                {video.name}
                              </Title>
                              <FontAwesomeIcon
                                icon={duotone('film')}
                                size="xl"
                                style={{
                                  '--fa-primary-color': '#c0c0c0',
                                  '--fa-secondary-color': '#c0c0c0',
                                }}
                                className="title-header"
                              />
                            </>
                          }
                          styles={{
                            header: {
                              fontSize: '14px', 
                              whiteSpace: 'nowrap'
                            }
                          }}
                          className="video-card"
                        >
                          {video.isfaicon ? (
                            <FontAwesomeIcon
                              icon={duotone('person-dots-from-line')}
                              style={{
                                '--fa-primary-color': '#ff80c0',
                                '--fa-secondary-color': '#ff80c0',
                                cursor: 'pointer',
                              }}
                              className="big-icon"
                              onClick={() => showVideoModal(video)}
                            />
                          ) : (
                            <img
                              src={require(`../../static/images/${video.src}`)}
                              alt=""
                              style={{ width: '100%', cursor: 'pointer' }}
                              onClick={() => showVideoModal(video)}
                            />
                          )}
                        </Card>
                      </List.Item>
                    </Skeleton>
                  </List.Item>
                )}
              />
              <Modal
                centered
                title={
                  <>
                  <Title level={3}>{`${videoInformation.name}`}</Title>
                  <hr />
                  </>
                }
                width="680px"
                open={videoInformation.isVisible}
                footer={null}
                onCancel={hideVideoModal}
                styles={{
                  body: {padding: 0}
                }}
                destroyOnClose={true}
              >
                <ReactPlayer
                  controls={true}
                  playing={player}
                  url={`${process.env.REACT_APP_API_ENDPOINT}/videos/${folder}/${videoInformation.url}`}
                  onPlay={() => setPlayer(true)}
                  onPause={() => setPlayer(false)}
                />
              </Modal>
            </VideoTutorialsStyle>
        </Modal>
      )}
      </OCMModal>
    </>
  )
}

const SurveyDashboard = () => {
  const [surveyList, setSurveyList] = useState([]);
  const [filteredUserSurveyList, setFilteredUserSurveyList] = useState([]);
  const [videoTutorials, setVideoTutorials] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isUserSurveyListLoading, setUserSurveyListLoading] = useState(true);
  const [userSurveys, setUserSurveys] = useState([]);
  const [viewAllTemplates, setViewAllTemplates] = useState(false);
  const [isSubscriptionPopupOpen, setSubscriptionPopupOpen] = useState(false)


  const { Search } = Input;
  const fetchSurveyList = async () => {
    setUserSurveyListLoading(true);
    const response = await DataService.get('surveys/get-survey-list');
    setUserSurveys(response.data);
    setFilteredUserSurveyList(response.data);
    setUserSurveyListLoading(false);
  };

  useEffect(() => {
    const fetchDefaultSurveys = async () => {
      const response = await DataService.get('surveys/default-survey-templates');
      setSurveyList(response.data.surveys);
      setVideoTutorials([response.data.video_tutorials]);
    };
    fetchSurveyList();
    fetchDefaultSurveys();
  }, []);

  useEffect(() => {
    setFilteredUserSurveyList(
      userSurveys.filter((survey) => survey.survey_template.title.toLowerCase().includes(searchText.toLowerCase())),
    );
  }, [searchText]);

  const backtoAllSurveys = () => {
    setViewAllTemplates(false);
  };

  return (
    <Main>
      <SurveyDashboardPage>
        <Heading as={'h1'}> {viewAllTemplates ? "All Survey Templates" : "All Surveys"} </Heading>
        <Row justify="center" gutter={25} style={{ marginTop: '20px' }}>
          <SearchContainer>
            <Search placeholder="Search Survey" allowClear size="large" onSearch={(text) => setSearchText(text)} />
          </SearchContainer>

          <Col xxl={24} xl={24} md={24}>
            {viewAllTemplates ? (
              <AllSurveyTemplates onOpenSubscriptionModal={() => {
                setSubscriptionPopupOpen(true)
              }} surveyList={surveyList} backtoAllSurveys={backtoAllSurveys} />
            ) : (
              <Tabs
                type="card"
                size="large"
                items={[
                  {
                    key: '1',
                    label: 'All',
                    children: (
                      <>
                        <Row align="middle">
                          <Col xxl={12} xl={12} md={12}>
                            <Heading as={'h3'}>Survey Templates</Heading>
                          </Col>
                          <Col xxl={12} xl={12} md={12}>
                            <Heading className={'viewAllTemplates'} as={'h5'}>
                              <span onClick={() => setViewAllTemplates(true)}> View All Survey Templates</span>
                            </Heading>
                          </Col>
                        </Row>

                        <SurveyCards surveys={surveyList} onOpenSubscriptionModal={() => {
                          setSubscriptionPopupOpen(true)
                        }} />

                        <Divider />

                        <Row style={{position: "relative"}}>
                          <Heading as={'h3'}>
                            My Surveys
                          </Heading>
                          {/* <WatchTutorialButton /> */}
                          {videoTutorials.length > 0 && (
                            <VideoContainer video_tutorials={videoTutorials} />
                          )}

                        </Row>


                        {isUserSurveyListLoading || userSurveys.length ? (
                          <SurveysList
                            data={filteredUserSurveyList}
                            loading={isUserSurveyListLoading}
                            reloadData={fetchSurveyList}
                          />
                        ) : (
                          <NoSurvey />
                        )}
                      </>
                    ),
                  },
                ]}
              />
            )}
            {isSubscriptionPopupOpen && <Subscription onClose={() => setSubscriptionPopupOpen(false)} />}

          </Col>
        </Row>
      </SurveyDashboardPage>
    </Main>
  );
};

const SearchContainer = styled.div`
  position: absolute;
  left: auto;
  z-index: 1;
`;

export default SurveyDashboard;
