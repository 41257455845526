import { useState } from 'react';
import { Button, Modal } from 'antd';
import InputManually from './InputManually';
import ImportAudience from './ImportAudience';
import { dropRight, last } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTargetAudienceDispatch, useTargetAudienceState } from '../../context/targetAudience';
import { reducerTypes } from '../../context/targetAudience/constants';
import LoadingSpinner from '../../../ui-elements/LoadingSpinner';
import EditGroupModal from './components/EditGroupModal';
import EditJobRoleModal from './components/EditJobRoleModal';
import StakeholderModal from './components/StakeholderModal';
import EditIndividualModal from './EditIndividualModal';
import useTargetAudienceData from '../../hook/useTargetAudienceData';

export const modalSteps = {
  INPUT_MANUALLY: 'input_manually',
  IMPORT_SHEET: 'import_sheet',
};

const AddAudience = () => {
  useTargetAudienceData();
  const [step, setStep] = useState([modalSteps.INPUT_MANUALLY]);
  const [loading, setLoading] = useState(false);
  const {
    models: { hasUnsavedData, targetAudienceModal, editModal, stakeholdersEditModal },
  } = useTargetAudienceState();
  const dispatch = useTargetAudienceDispatch();

  const closeModal = () => {
    dispatch({
      type: reducerTypes.TARGETAUDIENCE_MODAL,
      payload: { show: false },
    });
    setStep([modalSteps.INPUT_MANUALLY]);
  };

  const close = () => {
    if (hasUnsavedData.data) {
      dispatch({
        type: reducerTypes.HAS_UNSAVED_DATA,
        payload: { show: true },
      });
    } else {
      closeModal();
      setStep([modalSteps.INPUT_MANUALLY]);
    }
  };

  const next = () => {
    setStep([...step, modalSteps.IMPORT_SHEET]);
  };
  const back = () => {
    setStep(dropRight(step));
  };

  const CancelHandler = () => {
    dispatch({
      type: reducerTypes.HAS_UNSAVED_DATA,
      payload: { show: false },
    });
  };
  const discardHandler = async () => {
    closeModal();
  };

  const closeStakeholderModal = () => {
    dispatch({
      type: reducerTypes.STAKEHOLDERS_EDIT_MODAL,
      payload: false,
    });
  };

  return (
    <>
      <LoadingSpinner
        size={'large'}
        popupHeading="Wait while system is importing your Target Audience."
        open={loading}
        backgroundOverlay={true}
      />
      <Modal
        closeIcon={<FontAwesomeIcon icon={duotone('circle-xmark')} size="2xl" color="#7AC4FF" />}
        width={last(step) === modalSteps.IMPORT_SHEET ? '60%' : '80%'}
        open={targetAudienceModal.show}
        onCancel={close}
        footer={null}
      >
        {last(step) === modalSteps.INPUT_MANUALLY && (
          <InputManually importAudience={next} setLoading={setLoading} loading={loading} />
        )}
        {last(step) === modalSteps.IMPORT_SHEET && (
          <ImportAudience discardHandler={discardHandler} setLoading={setLoading} loading={loading} back={back} />
        )}
        <div>{step.length > 1 && <Button onClick={back}>Back</Button>}</div>
      </Modal>
      <Modal
        zIndex={3000}
        onCancel={CancelHandler}
        open={!!hasUnsavedData.show}
        title={'Unsaved changes'}
        footer={
          <>
            <Button onClick={CancelHandler}>Cancel</Button>
            <Button onClick={discardHandler}>Discard</Button>
          </>
        }
      >
        <p>Are you sure want to leave this page?</p>
        <p>Changes you made will not be saved.</p>
      </Modal>

      {editModal?.type === 'group' && <EditGroupModal />}
      {editModal?.type === 'jobRole' && <EditJobRoleModal />}
      <StakeholderModal open={stakeholdersEditModal} close={closeStakeholderModal} />
      {editModal?.type === 'individual' && <EditIndividualModal />}
    </>
  );
};

export default AddAudience;