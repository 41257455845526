import { InputNumber, Space } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';

export default function CustomInputNumber({ label, onChange = () => {} }) {
  const StyledInputNumber = styled(InputNumber)`
    border-color: rgb(93, 211, 255);
    border-width: 2px;
  `;

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <span>{label}</span>
      <StyledInputNumber min={0} onChange={(val) => onChange(val)} />
    </div>
  );
}
