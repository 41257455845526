import { Button, Modal } from "antd";
import Cookies from "js-cookie";

/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
const ellipsis = (text, size) => {
  if (text.split(' ').length >= size) {
    return `${text}`;
  }
  return `${text.split(' ').slice(0, size).join(' ')}...`;
};

function truncateString(str, maxLength) {
  if (str.length > maxLength) {
    return str.substring(0, maxLength - 3) + '...';
  }
  return str;
}

const idGenerator = (events, length = 1) => {
  const arrayData = [];
  events.map((data) => {
    return arrayData.push(parseInt(data.id, 10));
  });
  const number = (Math.max(...arrayData) + 1).toString();
  return number.length < length ? `${'0'.repeat(length - number.length)}${number}` : number;
};

const saveLoginUserCookie = (response, rememberMe = undefined) => {
  let userData = JSON.parse(response.data.userData);
  let projectInfo = JSON.parse(response.data.projectInfo);
  let remember_me = false;
  if (rememberMe != undefined)
  {
    if (rememberMe)
      {
        remember_me = true;
      }
  }
  else if (rememberMe != null)
  {
    if (rememberMe)
      {
        remember_me = true;
      }
  }
  if (remember_me)
  {
    Cookies.set('logedIn', true, { expires: 365 });
    Cookies.set('rememberMe', rememberMe, { expires: 365 });  
    Cookies.set('access_token', response.data.access_token, { expires: 365 });
  }
  else
  {
    Cookies.set('logedIn', true);
    Cookies.set('rememberMe', false);  
    Cookies.set('access_token', response.data.access_token);
  }
  
  localStorage.setItem('access_token', response.data.access_token);
  localStorage.setItem('userTheme', response.data.userTheme);
  localStorage.setItem('user_id', userData.account_users[0].id);
  localStorage.setItem('user_type', userData.account_users[0].userType);
  localStorage.setItem('full_name', userData.account_users[0].fullName);
  localStorage.setItem('email', userData.account_users[0].email);
  localStorage.setItem('first_name', userData.account_users[0].firstName);
  localStorage.setItem('organizationalSlug', userData.organizationalSlug);
  localStorage.setItem('organizationalName', userData.organizationalName);
  localStorage.setItem('active_project_name', projectInfo.activeProjectName);
  localStorage.setItem('active_project_id', projectInfo.activeProjectId);
  localStorage.setItem('has_assigned_checklist', projectInfo.assignedChecklist);
  localStorage.setItem('has_ocm_plan_generated', projectInfo.ocmPlanGenerated);
  localStorage.setItem('timezone', userData.account_users[0].timezone);
  localStorage.setItem('date_time_format', userData.account_users[0].date_time_format);
  localStorage.setItem('initialColor', userData.account_users[0].initialColor);
  localStorage.setItem('initialName', userData.account_users[0].initialName);
  localStorage.setItem('profileImage', userData.account_users[0].profileImage);
  localStorage.setItem('accountLogo', userData.account_configurations.accountLogo);
  localStorage.setItem('is_amazon', response.data.is_amazon);
  localStorage.setItem('is_trial', userData.isTrialAccount);
  localStorage.setItem('is_free', userData.isFreeAccount);
  localStorage.setItem('is_paid', userData.isPaidAccount);
  localStorage.setItem('is_marked_as_cancel', userData.isMarkedAsCancel);
  localStorage.setItem('is_cancelled', userData.isCancelled);
  localStorage.setItem('cancellation_date', userData.cancellationDate);
  localStorage.setItem('cancellation_delete_data_date', userData.cancellationDeleteDataDate);
  localStorage.setItem('pauseForMonth', userData.pauseForMonth);
  localStorage.setItem('is_paused', userData.isPaused);
  localStorage.setItem('pause_start_date', userData.pauseStartDate);
  localStorage.setItem('pause_resume_date', userData.pauseResumeDate);
  localStorage.setItem('pause_request_date', userData.pauseRequestDate);
  localStorage.setItem('marked_for_delete', userData.markedForDelete);
  localStorage.setItem('marked_for_delete_by', userData.markedForDeleteBy);
  localStorage.setItem('marked_for_delete_at', userData.markedForDeleteAt);
  localStorage.setItem('deleted_requested_at', userData.deletedRequestedAt);
  localStorage.setItem('is_subscription_cancelled_for_delete', userData.isSubscriptionCancelledForDelete);
  localStorage.setItem('readonly_account', response.data.readOnlyAccount);
  localStorage.setItem('offline_account', response.data.isOfflineAccount);
  localStorage.setItem('has_target_audience', response.data.hasTA);
};

const showConfirm = (executeLeave, activeKey) => {
  const confirmed = Modal.confirm({
    title: 'Confirm',
    content: (
      <div style={{ textAlign: 'center' }}>
        <p style={{ fontSize: "18px" }}>You have unsaved changes. Are you sure you want to leave?</p>
        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
          <Button 
            type="default" 
            onClick={() => {
              confirmed.destroy();
            }} 
            style={{ 
              fontSize: "14px", 
              marginLeft: "10px", 
              padding: "8px 20px"
            }}
          >Cancel</Button>
          <Button 
            type="ocm-default" 
            onClick={async () => {
            if (typeof executeLeave === "function")
            {
              await executeLeave(activeKey);
            }
            localStorage.removeItem('unSaveChanges');
            confirmed.destroy();
          }} style={{ fontSize: "14px", marginLeft: "10px", padding: "8px 20px" }}>Leave</Button>
        </div>
      </div>
    ),
    footer: (_) => (
      <></>
    ),
  });
}

export {
  ellipsis,
  truncateString,
  idGenerator,
  saveLoginUserCookie,
  showConfirm
};
