import React, { useState, useEffect } from 'react';
import { FreeContainer, FreeSearchWrapper, FreeTopSection } from './FreeLibrarySection.styles';
import FreeSearchBar from './FreeSearchBar';
import FreeSortIcon from './FreeSortIcon';
import FreeOCMTrainingItem from './FreeOCMTrainingList';

const FreeSearchAndOCMTrainingContainer = ({category}) => {
    const [sortOrder, setSortOrder] = useState({
      field: "title",
      order: "ASC"
    });

    const [filterString, setFilterString] = useState("");

    const setSort = (sort) => {
      setSortOrder(sort);
    }

    const setFilter = (filter) => {
      setFilterString(filter);
    }

    useEffect(() => {
      if (Object.entries(category).length !== 0)
      {
        setSortOrder({
          field: "title",
          order: "ASC"
        });
        setFilterString("");
      }
    }, [category]);

    return (
        <FreeContainer>
          <FreeTopSection>
            <FreeSearchWrapper>
              <FreeSearchBar category={category} setFilter={setFilter} />
            </FreeSearchWrapper>
            <FreeSortIcon category={category} setSort={setSort} />
          </FreeTopSection>
          <FreeOCMTrainingItem category={category} sort={sortOrder} filter={filterString} />
        </FreeContainer>
    );
}

export default FreeSearchAndOCMTrainingContainer;