import React, { useReducer, useState, useCallback } from 'react';
import { Button, Typography, Alert, Modal } from 'antd';
const { Title, Text } = Typography;
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch } from 'react-redux';
import { stopSubscriptionCancellation } from '../../redux/authentication/actionCreator';
import useNotificationController from '../../utility/useNotificationController';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { OCMModal, OCMModalHeader } from '../../container/tools/Style';
import moment from 'moment';

const StopSubscriptionCancellation = ({createProject}) => {
  const {
    contextHolder,
    openError,
    openSuccess
  } = useNotificationController();

  const is_marked_as_cancel = localStorage.getItem('is_marked_as_cancel') === 'true';
  const is_cancelled = localStorage.getItem('is_cancelled') === 'true';
  const is_paused = localStorage.getItem('is_paused') === 'true';
  const cancellation_date = moment(localStorage.getItem('cancellation_date'));
  const current_date  = moment().utc();
  const organizationalSlug = localStorage.getItem('organizationalSlug');
  if (current_date < cancellation_date && !is_cancelled)
  {
    if (!is_marked_as_cancel)
    {
      is_marked_as_cancel = true;
    }
  }

  const [subscriptionModalButtonText, setSubscriptionModalButtonText] = useState({
    buttonText: "Stop Subscription Cancellation",
    submittingFrom: false,
    isDisabled: false
  });

  const [isModalVisible, setIsModalVisible] = useState(false);

  const initialState = {
      error: false,
      messages: ""
  };

  const reducer = (state, action) => {
      switch (action.type) {
        case 'STOP_SUBSCRIPTION_CANCELLATION':
          return { ...state, error: true,messages: action.err };
        case 'RESET':
          return state;
        default:
          return state;
      }
  };

  const [apiError, apiResponseCallback] = useReducer(reducer, initialState);

  const dispatch = useDispatch();

  const stopCancelUserSubscription = useCallback(async () => {
      apiResponseCallback({ type: 'RESET' });
      dispatch(
        stopSubscriptionCancellation((response) => {          
          if (response.status === 200) 
          {
            const responseData = response.data.userInfo;
            openSuccess('bottomRight', 'Stop Subscription Cancellation',  (
              <>
              <Title level={5}>Your subscription cancellation has been stopped</Title>
              <Text type={'secondary'}>Window will be reloaded in 5 seconds</Text>
              </>
            ));
            localStorage.setItem('is_marked_as_cancel', responseData.isMarkedAsCancel);
            localStorage.setItem('is_cancelled', responseData.isCancelled);
            localStorage.setItem('cancellation_date', responseData.cancellationDate);

            localStorage.setItem('pauseForMonth', responseData.pauseForMonth);
            localStorage.setItem('is_paused', responseData.isPaused);
            localStorage.setItem('pause_start_date', responseData.pauseStartDate);
            localStorage.setItem('pause_resume_date', responseData.pauseResumeDate);
            localStorage.setItem('pause_request_date', responseData.pauseRequestDate);
            setTimeout(() => {
              setSubscriptionModalButtonText({
                buttonText: "Stop Subscription Cancellation",
                submittingFrom: false,
                isDisabled: false
              });
              if (is_paused)
              {
                window.location.href = `/${organizationalSlug}/subscription/transaction-history`;
              }
              else
              {
                window.location.href = `/${organizationalSlug}/subscription/update-subscription`;
              }
            }, 5000)
          } 
          else 
          {
            setSubscriptionModalButtonText({
              buttonText: "Stop Subscription Cancellation",
              submittingFrom: false,
              isDisabled: false
            });
            if (response.data === undefined)
            {
              if (response.message !== undefined)
              {
                openError("topLeft", "Stop Subscription Cancellation", response.message)
                apiResponseCallback(response);
              }
              else
              {
                openError("topLeft", "Stop Subscription Cancellation", response.err)
                apiResponseCallback(response);
              }
            }
            else if (response.data !== undefined)
            {
              openError("topLeft", "Stop Subscription Cancellation", response.data.message)
              apiResponseCallback(response);
            }
            else
            {
              openError("topLeft", "Stop Subscription Cancellation", response.err)
              apiResponseCallback(response);
            }
          }
        }),
      );
  }, [dispatch]);

  const stopCancelCurrentSubscription = async () => {
    setSubscriptionModalButtonText({
      buttonText: "Stop cancellation, please wait....",
      submittingFrom: true,
      isDisabled: true
    });
    await stopCancelUserSubscription();
  }

  const stopCancellation = (val) => {
    setIsModalVisible(val);
  };

  return (
    <>
      {contextHolder}
      <Alert 
        message={
          <p style={{
            fontSize: "24px",
            marginBottom: 0,
            color: "#AE0002"
          }}>
            This subscription has been marked for cancellation effective on {cancellation_date.format("MMM DD, YYYY")}. Click here to:  
            <a 
              href={"#"} 
              onClick={(e) => stopCancellation(true)}
              style={{paddingLeft: "10px", textDecoration: "underline"}}
            >Stop Cancellation</a>
          </p>
        }
        icon={
          <FontAwesomeIcon size='3x' icon={duotone('circle-exclamation')} style={{"--fa-primary-color": "#ffc000", "--fa-secondary-color": "#ffc000",}} />
        }
        type="error" 
        showIcon 
        style={{
          marginLeft: "20px",
          marginTop: "1px"
        }}
    />
    <OCMModal>
      {isModalVisible && (
        <Modal
          style={{
            margin: '20px 0px',
          }}
          className="add-change"
          title={
            <OCMModalHeader>
              <div className="user-info-header-panel">
                <Title level={1} className="header-title">Stop Subscription Cancellation</Title>
                <div className="ribon none"></div>
              </div>
            </OCMModalHeader>
          }
          centered
          closeIcon={<FontAwesomeIcon size="2xl" icon={duotone('circle-xmark')} />}
          open={isModalVisible}
          onCancel={() => {
            stopCancellation(false);
          }}
          onOk={() => {
            stopCancellation(false);
          }}
          width={800}
          wrapClassName={'ocm-modal'}
          footer={[
            <Button 
              disabled={subscriptionModalButtonText.isDisabled}
              loading={subscriptionModalButtonText.submittingFrom}
              key="delete" 
              type="ocm-default-stop-cancellation" 
              size="large"
              style={{
                fontSize: '20px', // Increase font size
                padding: '5px 46px 5px 46px', // Increase padding
                lineHeight: '50px', // Center the text vertically
                borderRadius: "15px",
                height: "auto"
              }}
              onClick={async (e) => {
                await stopCancelCurrentSubscription();
              }}
            >
              {subscriptionModalButtonText.buttonText}
            </Button>
          ]}
        >
          <div style={{padding: "10px 30px", marginBottom: "30px"}}>
            <p style={{fontSize: "24px", lineHeight: "32px"}}>
              Click below to confirm and stop your subscription cancellation.
            </p>
          </div>
        </Modal>
      )}
    </OCMModal>
  </>
  );
};

export default StopSubscriptionCancellation;
