import React from "react";
import { FreeResourceContentWrapper } from "./FreeResources.styles";
import FreeSearchAndResourceContainer from "./FreeSearchAndResourceContainer";

const FreeResourceContent = ({category}) => {
  return (
    <FreeResourceContentWrapper>
      <FreeSearchAndResourceContainer category={category} />
    </FreeResourceContentWrapper>
  );
};

export default FreeResourceContent;