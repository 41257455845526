import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { notificationsReducer } from './reducer';
import { initialNotificationsState, notificationsReducerTypes } from './constants';
import { notification } from 'antd';



// Create context and provider for notifications
export const NotificationsStateContext = createContext();
export const NotificationsDispatchContext = createContext();

export const useNotificationsState = () => useContext(NotificationsStateContext);
export const useNotificationsDispatch = () => useContext(NotificationsDispatchContext);

const NotificationsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(notificationsReducer, initialNotificationsState);
  const [notificationType, contextHolder] = notification.useNotification();

  useEffect(() => {
    if (state.type) {

      notificationType?.[state.type]({
        message: state.message,
        description: state.description,
        placement: 'bottomRight'
      });

      setTimeout(() => {
        dispatch({
          type: notificationsReducerTypes.HIDE,
        })
      } , 3000)
    }
  }, [state.type])

  return (
    <NotificationsDispatchContext.Provider value={dispatch}>
      <NotificationsStateContext.Provider value={state}>
        {contextHolder}
        {children}
      </NotificationsStateContext.Provider>
    </NotificationsDispatchContext.Provider>
  );
};

export default NotificationsProvider;
