import React, {useEffect, useRef, useState} from 'react';
import { Button, Col, Form, Upload, Input, Modal, Row, Steps, Typography, Divider, List, Select, Switch } from 'antd';

const { Dragger } = Upload;
const {Title, Text} = Typography;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { duotone, regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import * as XLSX from 'xlsx'; // Library to handle Excel files
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { theme } from '../../../../../config/theme/themeVariables';
import { TargetAudienceStyle } from '../../../Style';

function TargetAudienceExcelMap(props) {
    const headerRowRef = useRef();
    const userTheme = JSON.parse(localStorage.getItem('userTheme'));
    theme['user-theme'] = userTheme;
    
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isImageModalVisible, setIsImageModalVisible] = useState(false);
    const [onlyIndividual, setOnlyIndividual] = useState(true);
    const [audienceColumns, setAudienceColumns] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [mapping, setMapping] = useState({});
    const [jsonData, setJsonData] = useState([]);
    const [currentStep, setCurrentStep] = useState(1);
    const [secondStepText, setSecondStepText] = useState("In Progress");
    const [thirdStepText, setThirdStepText] = useState("Waiting");
    const [defaultHeaderIndex, setDefaultHeaderIndex] = useState(0);
    const [defaultHeader, setDefaultHeader] = useState(0);
    const [firstRowHeader, setFirstRowHeader] = useState([]);
    const [showing, setShowing] = useState([]);
    const [currentlyShowing, setCurrentlyShowing] = useState([]);
    const [defaultHeaderRow, setDefaultHeaderRow] = useState(false);
    const [currentView, setCurrentView] = useState(0);
    const [selectedValues, setSelectedValues] = useState([]);
    const [selectedValuesInd, setSelectedValuesInd] = useState([]);

    const [fileData, setFileData] = useState(null);

    const handleFileUpload = (file) => {
      const reader = new FileReader();
  
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        
        // Assuming the first sheet is used for mapping
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        
        // Convert the worksheet to an array of objects
        const mappedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        setDefaultHeaderIndex(0);
        setDefaultHeader(mappedData[0]);
        setFirstRowHeader(mappedData[1]);
        let showingData = [];
        mappedData[0].map(dt => showingData[false])
        setShowing(showingData);
        setFileData(mappedData);
        setCurrentStep(2);
        setSecondStepText("Finish");
        setThirdStepText("In Progress");
        setIsModalVisible(true);

        let currentValue = 0;
        setDefaultHeaderIndex(currentValue);
        setDefaultHeader(mappedData[parseInt(currentValue)]);
        setFirstRowHeader(mappedData[parseInt(currentValue) + 1]);
        let selectedDatas = [];
        let selectedDatavalues = [];
        let showDatavalues = [];
        let selectedIndex = [];
        audienceColumns.map((item, o) => {
            let [val, ind] = getDefaultValue(item.title, mappedData[parseInt(currentValue)]);
            if (val.trim() === "")
            {
                showDatavalues.push("");
                selectedDatavalues.push(false)
                selectedIndex.push(-1);
            }
            else
            {
                showDatavalues.push(mappedData[parseInt(currentValue) + 1][ind]);
                selectedDatavalues.push(true);
                selectedIndex.push(0);
            }
            selectedDatas.push(val);
        });
        setCurrentlyShowing(showDatavalues);
        setSelectedValues(selectedDatas);
        setDefaultHeaderRow(true);
        setShowing(selectedDatavalues);
        setSelectedValuesInd(selectedIndex);
      };
  
      reader.readAsArrayBuffer(file);
    };

    const handleOk = async() => {
    }

    function levenshteinDistance(a, b) {
        const matrix = Array.from({ length: a.length + 1 }, () => new Array(b.length + 1));
      
        for (let i = 0; i <= a.length; i++) matrix[i][0] = i;
        for (let j = 0; j <= b.length; j++) matrix[0][j] = j;
      
        for (let i = 1; i <= a.length; i++) {
          for (let j = 1; j <= b.length; j++) {
            const cost = a[i - 1] === b[j - 1] ? 0 : 1;
            matrix[i][j] = Math.min(
              matrix[i - 1][j] + 1, // Deletion
              matrix[i][j - 1] + 1, // Insertion
              matrix[i - 1][j - 1] + cost // Substitution
            );
          }
        }
        return matrix[a.length][b.length];
    }
      
    function similarity(str1, str2) {
        const distance = levenshteinDistance(str1, str2);
        const length = Math.max(str1.length, str2.length);
        return ((length - distance) / length) * 100;
    }

    const getDefaultValue = (header, defaultHeaderData)=>{
        let selectedValue = ""
        let found = false;
        let defaultIndex = -1;
        defaultHeaderData.map((field, fieldIndex) =>{
          if(similarity(header,field) >= 100)
          {
            found = true;
            selectedValue = field;
            defaultIndex = fieldIndex;
          }
        });
        if (!found)
        {
            defaultHeaderData.map((field, fieldIndex) =>{
                if(similarity(header,field) >= 80)
                {
                    found = true;
                    selectedValue = field;
                    defaultIndex = fieldIndex;
                }
            });
        }
        
        if(selectedValue == "" && !found)
        {
            defaultHeaderData.map((field, fieldIndex) =>{
            if(similarity(header,field) >= 60)
            {
              selectedValue = field;
              defaultIndex = fieldIndex;
            }
          });
        }
        
        return [selectedValue, defaultIndex];
    }

    const handleDownload = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Target Audience List');
        
        // Styling for header row
        const headerRow = worksheet.addRow(props.dataExcelDataColumn);
        headerRow.font = { bold: true, color: { argb: '000000' } };
        headerRow.alignment = { horizontal: 'center' };
        headerRow.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BDE3FF' } }; // Blue background color
        
        worksheet.getRow(1).height = 20;
        // Set column widths
        worksheet.columns.forEach(column => {
            column.width = 40;
        });

        // Generate Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileName = 'Target Audiences.xlsx';
        saveAs(blob, fileName);
    };

    const saveAudiencesData = () => {
        let audienceData = [];
        for(let i in fileData)
        {
            if (parseInt(i) !== parseInt(defaultHeaderIndex))
            {
                let current_data = fileData[i];
                let tmp = {}
                audienceColumns.map((item, j) => {
                    let selectedValue = selectedValues[j];
                    let currentValue = "";
                    for(let k in defaultHeader)
                    {
                        if (defaultHeader[k] === selectedValue)
                        {
                            currentValue = current_data[k];
                            break;
                        }
                    }
                    tmp[item.dataIndex] = currentValue;
                });
                audienceData.push(tmp);
            }
        }
        props.generateAudienceDataFromMap(audienceData, onlyIndividual);
    }

    const setAudienceColumnsCells = (checked) => {
        let requiredFields = ['firstName','lastName', 'email'];
        if (checked)
        {
            requiredFields = [];   
        }
        const columns = [];
        const currentSelectedFields = [];
        const currentSelectedValues = [];
        props.defaultColumns.map((col) => {
          if (!requiredFields.includes(col.dataIndex))
          {
            let [val, ind] = getDefaultValue(col.title, defaultHeader);
            currentSelectedFields.push(val);
            currentSelectedValues.push(fileData[parseInt(defaultHeaderIndex)+1][ind]);
            if (!col.editable) {
              columns.push(col);
            }
            columns.push({
              ...col,
              onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
              }),
            });
          }
        });
        setSelectedValues(currentSelectedFields)
        setCurrentlyShowing(currentSelectedValues)
        setAudienceColumns(columns);
    }

    useEffect(() => {
        if (onlyIndividual) {
          const columns = props.defaultColumns.map((col) => {
            if (!col.editable) {
              return col;
            }
            return {
              ...col,
              onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
              }),
            };
          });
          setAudienceColumns(columns);
        }
    }, [onlyIndividual]);

    return (
        <>
        <div className='title-input'>
            <Title level={2}>Import a spreadsheet</Title>
        </div>
        <div className='title-group-start' style={{textAlign: "center", paddingTop: "20px"}}>
            <Title level={3} style={{marginBottom: "0px"}}>Use our template or map your own spreadsheet.</Title>
            <div className='audienceInputForm' style={{paddingTop: "40px"}}>
                <Row style={{marginTop: '10px'}}>
                    <Col xxl={12} xl={12} lg={12} md={12}>
                    <Button
                        className="btn-create"
                        htmlType="submit"
                        type="default-big"
                        size="large"
                        onClick={async () => {
                            await props.createColumns();
                            handleDownload();
                            await props.createColumns();
                            props.setIsEditMode(true)
                            await props.setEditType('Import Excel Map');
                        }}
                        style={{
                            width: "340px",
                            borderRadius: "60px !important",
                            whiteSpace: "normal"
                        }}
                    >
                        Download our template
                    </Button>
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12}>
                        <Button
                            className="btn-create"
                            htmlType="submit"
                            type="ocm-default-big"
                            size="large"
                            onClick={async () => {
                                //importExcel();
                            }}
                        >
                            Map your own spreadsheet
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col xxl={24} xl={24} md={24}
                        style={{
                            paddingTop: "45px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginLeft: "50px"
                        }}
                    >
                    <Steps
                        className="ocm-steps"
                        direction="vertical"
                        current={currentStep}
                        items={[
                        {
                            title: <Title level={5} type='secondary'>Finished</Title>,
                            description: <Title level={4} type='secondary'>Save the downloaded template</Title>,
                        },
                        {
                            title: <Title level={5} type={currentStep === 1 ? 'success' : 'secondary'}>{secondStepText}</Title>,
                            description: <Title level={4}>Transfer your data to the template</Title>,
                        },
                        {
                            title: <Title level={5} type={currentStep === 2 ? 'success' : 'secondary'}>{thirdStepText}</Title>,
                            description: "Upload the completed template",
                        },
                        ]}
                    />
                    </Col>
                </Row>
                <Row>
                    <Col xxl={24} xl={24} md={24}
                    >
                        
                    <Dragger 
                        name={'file'}
                        showUploadList={false}
                        multiple = {false}
                        accept=".xls,.xlsx,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        beforeUpload={(file) => {
                            handleFileUpload(file);
                            return false; // Prevent default upload behavior
                        }}
                    >
                        <p className="ant-upload-drag-icon">
                            <FontAwesomeIcon icon={regular("inbox")} size="3x" />
                        </p>
                        <Text type='secondary' className="ant-upload-text">Click or drag file to this area to upload</Text>
                    </Dragger>
                    </Col>
                </Row>
            </div>
        </div>

        <Modal
            title={
                <>
                <Title level={3} type='secondary'>
                    Excel Import - Target Audience
                </Title>
                <Divider />
                </>
            }
            open={isModalVisible}
            onOk={saveAudiencesData}
            okText="Import"
            okButtonProps={{
                className: "ant-btn-ocm-default",
                size: 'large',
                style: {display: defaultHeaderRow ? "block" : "none"},
            }}
            cancelButtonProps={{
                className: "ant-btn-default",
                size: 'large',
                style: {display: defaultHeaderRow ? "block" : "none"},
            }}
            onCancel={() => setIsModalVisible(false)}
            width="1200px"
        >
            <Row
                key={0}
                style={{
                    alignItems: 'center'
                }}
            >
                <Col xxl={24} xl={24} md={24}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column"
                    }}
                >
                    <Button
                        className="btn-create"
                        htmlType="submit"
                        type="default-big-border"
                        size="large"
                        onClick={async () => {
                            return false;
                        }}
                    >
                        <div className='Number-two'>2</div>
                        Which row has the column titles?
                    </Button>
                    <Form.Item
                        key={`header_row_0`}
                        size="large"
                        label=""
                        name="header_row"
                        initialValue={1}
                        style={{marginBottom: 10, marginTop: "20px", textAlign: "center"}}
                    >
                    <Input 
                        ref={headerRowRef}
                        placeholder={'Header Row'} 
                        defaultValue={1}
                        onPressEnter={(e) => {
                            let currentValue = parseInt(e.target.value) - 1;
                            if (parseInt(e.target.value) === 0)
                            {
                                currentValue = 0;
                            }
                            setDefaultHeaderIndex(currentValue);
                            setDefaultHeader(fileData[parseInt(currentValue)]);
                            setFirstRowHeader(fileData[parseInt(currentValue) + 1]);
                            let selectedDatas = [];
                            let selectedDatavalues = [];
                            let showDatavalues = [];
                            let selectedIndex = [];
                            audienceColumns.map((item, o) => {
                                let [val, ind] = getDefaultValue(item.title, fileData[parseInt(currentValue)]);
                                if (val.trim() === "")
                                {
                                    showDatavalues.push("");
                                    selectedDatavalues.push(false)
                                    selectedIndex.push(-1);
                                }
                                else
                                {
                                    showDatavalues.push(fileData[parseInt(currentValue) + 1][ind]);
                                    selectedDatavalues.push(true);
                                    selectedIndex.push(0);
                                }
                                selectedDatas.push(val);
                            });
                            setCurrentlyShowing(showDatavalues);
                            setSelectedValues(selectedDatas);
                            setDefaultHeaderRow(true);
                            setShowing(selectedDatavalues);
                            setSelectedValuesInd(selectedIndex);
                        }}
                        style={{textAlign: 'center', width: "160px"}}
                    />
                    <FontAwesomeIcon 
                        size='2xl' 
                        icon={duotone("circle-question")} 
                        style={{"--fa-primary-color": "#0077ce", "--fa-secondary-color": "#20a0ff",paddingLeft: "10px", marginTop: "5px"}} 
                        onClick={() => setIsImageModalVisible(true)}
                    />
                    </Form.Item>
                </Col>
            </Row>
            <Row justify={'center'} gutter={[40,25]}
                style={{
                    marginBottom: '16px',
                    alignItems: 'center'
                }}
            >
                <Col xxl={24} xl={24} lg={24} style={{display: "flex", justifyContent: "center", paddingLeft: "0"}}>
                    <Button
                        className="btn-create"
                        type="ocm-default"
                        size="large"
                        onClick={() => {
                            let currentValue = parseInt(headerRowRef.current.input.value) - 1;
                            if (parseInt(headerRowRef.current.input.value) === 0)
                            {
                                currentValue = 0;
                            }
                            setDefaultHeaderIndex(currentValue);
                            setDefaultHeader(fileData[parseInt(currentValue)]);
                            setFirstRowHeader(fileData[parseInt(currentValue) + 1]);
                            let selectedDatas = [];
                            let selectedDatavalues = [];
                            let showDatavalues = [];
                            let selectedIndex = [];
                            audienceColumns.map((item, o) => {
                                let [val, ind] = getDefaultValue(item.title, fileData[parseInt(currentValue)]);
                                if (val.trim() === "")
                                {
                                    showDatavalues.push("");
                                    selectedDatavalues.push(false)
                                    selectedIndex.push(-1);
                                }
                                else
                                {
                                    showDatavalues.push(fileData[parseInt(currentValue) + 1][ind]);
                                    selectedDatavalues.push(true);
                                    selectedIndex.push(0);
                                }
                                selectedDatas.push(val);
                            });
                            setCurrentlyShowing(showDatavalues);
                            setSelectedValues(selectedDatas);
                            setDefaultHeaderRow(true);
                            setShowing(selectedDatavalues);
                            setSelectedValuesInd(selectedIndex);
                        }}
                    >
                        Continue
                    </Button>
                </Col>
            </Row>
            {defaultHeaderRow && (
                <>
                    <Row
                        key={1}
                        style={{
                            marginBottom: '16px',
                            alignItems: 'center'
                        }}
                    >
                        
                        <Col xxl={24} xl={24} md={24}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column"
                            }}
                        >
                            <Button
                                className="btn-create"
                                htmlType="submit"
                                type="default-big-border"
                                size="large"
                                onClick={async () => {
                                    return false;
                                }}
                            >
                                <div className='Number-three'>3</div>
                                &nbsp;&nbsp;Map your columns to the target audience database
                            </Button>
                            <Text type='secondary' italic style={{marginBottom: "20px", marginTop: "10px"}}>Want to add more fields? You can do that after you upload. </Text>
                            </Col>
                        </Row>
                        <Row>
                            <TargetAudienceStyle theme={theme}>
                                <Col xxl={24} xl={24} md={24}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginBottom: "50px"
                                    }}
                                >
                                    <div className='individual-switch-relative'>
                                        <Switch
                                        className="switch-class" 
                                        checkedChildren="Individuals" 
                                        unCheckedChildren="Group" 
                                        defaultChecked 
                                        onChange={(checked, e) => {
                                            setOnlyIndividual(checked);
                                            setAudienceColumnsCells(checked);
                                        }}
                                        />
                                    </div>
                                </Col>
                            </TargetAudienceStyle>
                        </Row>
                        <Row>
                            <Col xxl={24} xl={24} md={24}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column"
                                }}
                            >
                            <List
                                style={{width: "100%"}}
                                size="large"
                                header={
                                    <Row gutter={20} justify="space-evenly">
                                        <Col span={7} style={{border: `2px solid ${userTheme.sidebarColor}`, textAlign: "center", padding: "20px"}}>
                                            <Title level={4}>Audience Database</Title>
                                        </Col>
                                        <Col span={7} style={{border: `2px solid ${userTheme.sidebarColor}`, textAlign: "center", padding: "20px"}}>
                                            <Title level={4}>Your Spreadsheet</Title>
                                        </Col>
                                        <Col span={7} style={{border: `2px solid ${userTheme.sidebarColor}`, textAlign: "center", padding: "20px"}}>
                                            <Title level={4}>Preview of 1st Row</Title>
                                        </Col>
                                    </Row>
                                }
                                bordered
                                dataSource={audienceColumns}
                                renderItem={(item, i) => 
                                    <List.Item key={item.dataIndex  } style={{width: "100%"}}>
                                        <Row gutter={20} justify="space-evenly" style={{maxWidth: "100%", minWidth: "100%"}}>
                                            <Col span={7} style={{textAlign: "left"}}>
                                                <Title level={4}>{item.title}</Title>
                                            </Col>
                                            <Col span={7} style={{textAlign: "center"}}>
                                                <Select
                                                    allowClear={true}
                                                    placeholder="Select"
                                                    style={{ width: '100%' }}
                                                    value={selectedValues[i]}
                                                    onChange={(value) => {
                                                        let cView = 0;
                                                        let cData = "";
                                                        defaultHeader.map((item, i) => {
                                                            if (item === value)
                                                            {
                                                                cView = i;
                                                                cData = firstRowHeader[i];
                                                            }
                                                            setCurrentView(cView);
                                                        })
                                                        let currentSelected = selectedValuesInd;
                                                        currentSelected[cView] = cView;
                                                        setShowing({ ...showing, [i]: true })
                                                        setSelectedValues({ ...selectedValues, [i]: value })
                                                        setCurrentlyShowing({ ...currentlyShowing, [i]: cData })
                                                        setSelectedValuesInd(currentSelected);
                                                    }}
                                                    options={
                                                        defaultHeader.map((field) => 
                                                            ({
                                                                value: field,
                                                                label: field,
                                                            })
                                                        )
                                                    }
                                                >
                                                </Select>
                                            </Col>
                                            <Col span={7} style={{textAlign: "left"}}>
                                                <Text >{showing[i] && currentlyShowing[i]}</Text>
                                            </Col>
                                        </Row>
                                    </List.Item>
                                }
                            />
                        </Col>
                    </Row>
                </>
            )}
            </Modal>

        <Modal
            title={
                <>
                <Title level={3} type='secondary'>
                    Example Header Row - Target Audience
                </Title>
                <Divider />
                </>
            }
            open={isImageModalVisible}
            onCancel={() => setIsImageModalVisible(false)}
            width="800px"
            onOk={() => setIsImageModalVisible(false)}
            okButtonProps={{
                style: {display: "none"},
            }}
            cancelButtonProps={{
                style: {display: "none"},
            }}
        >
            <Row
                key={2}
                style={{
                    marginBottom: '16px',
                    alignItems: 'center'
                }}
            >
            <div className="image-people">
                <img 
                    className='' 
                    src={require('../../../../../static/images/front-row-examples.png')} 
                    style={{height: "30%"}}
                />
            </div>
            </Row>
            </Modal>
        </>
    )
}

export default TargetAudienceExcelMap;
