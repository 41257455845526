import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/pro-duotone-svg-icons";
import { HeaderBreadcrumb, RightArrowIcon, TitleBreadcrumb, TitleBreadcrumbLink } from "./LibrarySection.styles";

const CMSbreadcrumb = ({type, title}) => {
    const organizationalSlug = localStorage.getItem('organizationalSlug');

    return (
      <HeaderBreadcrumb>
        <TitleBreadcrumbLink to={`/${organizationalSlug}/${type === "cms" ? "change-management-library" : "ocm-training"}`}>{type === 'cms' ? "Change Management Library" : "OCM Training"}</TitleBreadcrumbLink>
        <RightArrowIcon icon={faCaretRight} />
        <TitleBreadcrumb>{title}</TitleBreadcrumb>
      </HeaderBreadcrumb>
    );
};

export default CMSbreadcrumb;