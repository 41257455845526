import React from 'react';
import { DatePicker, Space } from 'antd';
import styled from 'styled-components';

const StyledDatePicker = styled(DatePicker)`
 &.ant-picker{

 
  min-width: 140px;
  width: 140px;
  border-color: rgb(93, 211, 255);
  border-width: 2px;
 }
`;

const CustomDatePicker = ({ label, onChange }) => (
  <Space>
    <div>{label}</div>
    <StyledDatePicker onChange={(date, dateString)=> onChange(date.toISOString())} format="DD-MM-YYYY" />
  </Space>
);
export default CustomDatePicker;
