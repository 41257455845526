import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { Row, Col, Alert } from 'antd';
import { Main } from '../styled';
import { useDispatch } from 'react-redux';
import useNotificationController from '../../utility/useNotificationController';
import { 
  FreeBackgroundImage, 
  FreeContentAreaWrapper, 
  FreeLibraryContainer, 
  FreeLibraryImageWrapper, 
  FreeLibraryTitle, 
  FreeOverlay } 
from './FreeLibrarySection.styles';
import FreeContentArea from './FreeContentArea';
import { verifyUser } from '../../redux/authentication/actionCreator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useNavigate } from 'react-router-dom';

const FreeLibrarySection = ({setOpenKeys}) => {
  const {
    contextHolder,
    openError,
  } = useNotificationController();

  const navigate = useNavigate();

  const [loaded, setLoaded] = useState(false);
  const [loadedWithError, setLoadedWithError] = useState(false);
  const [defaultImage, setDefaultImage] = useState('cover-change-management-library.png');

  const initialState = {
      error: false,
      messages: ""
  };
  const reducer = (state, action) => {
      switch (action.type) {
        case 'VERIFY_USER_ERR':
          return { ...state, error: true,messages: action.err };
        case 'RESET':
          return state;
        default:
          return state;
      }
  };
  const [apiError, apiResponseCallback] = useReducer(reducer, initialState);
  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    apiResponseCallback({ type: 'RESET' });
      dispatch(
          verifyUser({checkLibrary: true, typeOfLibrary: 'free'},(response) => {
            if (response.status === 200) {
              setDefaultImage(response.data.featuredImage);
              setLoaded(true);
            } 
            else 
            {
              const organizationalSlug = localStorage.getItem('organizationalSlug');
              navigate(`/${organizationalSlug}/main-dashboard`);
              apiResponseCallback(response);
            }
          }),
        );
  }, [
    dispatch, 
    defaultImage,
    loaded,
    loadedWithError
  ]);

  useEffect(() => {
    const retrieveData = async () => {
      await fetchData();
    };
    if (!loaded)
    {
      retrieveData().catch(console.error);
    }
    if (typeof setOpenKeys === 'function')
    {
      setOpenKeys(['ocm-training']);
    }
    const currentPath = localStorage.getItem("currentPath");
    if (currentPath !== "ocm-training")
    {
      localStorage.removeItem('currentTrainingCategory');
    }
    if (window.location.pathname.indexOf("change-management-library"))
    {
      localStorage.setItem('currentPath', "ocm-training");
    }
  }, []);

  const setFeaturedImage = (img) => {
    setDefaultImage(img);
  }

  const userTheme = JSON.parse(localStorage.getItem('userTheme'));
  
  return (
    <Main>
      {contextHolder}
      {!loaded ? (
        <div className="loader" key={0} style={{ padding: "10px", display: "flex", height: "200px", alignItems: "center", justifyContent: "center" }}>
          <FontAwesomeIcon icon={duotone("spinner-third")} spin size="2xl" style={{ "--fa-primary-color": `${userTheme.mainHighlightColor}`, "--fa-secondary-color": `${userTheme.mainHighlightColor}`, marginRight: "20px", fontSize: "100px" }} />
          <span style={{fontSize: "40px", color: `${userTheme.mainHighlightColor}`}}>Loading OCM Training...</span>
        </div>
      ) : (
        <Row gutter={[16, 16]} style={{marginRight: "0px"}}>
          <Col span={24} style={{paddingLeft: "0px"}}>
            <FreeLibraryContainer>
              <FreeLibraryImageWrapper>
                <FreeBackgroundImage src={`${process.env.REACT_APP_API_ENDPOINT}/file/cms/category_images/${defaultImage}`} alt="OCM Training Background"/>
                <FreeOverlay />
                <FreeLibraryTitle>OCM Training</FreeLibraryTitle>
              </FreeLibraryImageWrapper>
              <FreeContentAreaWrapper>
                  <Row gutter={[16, 16]} style={{marginRight: "0px", marginLeft: 0}}>
                    <Col span={24}>
                      <FreeContentArea setFeaturedImage={setFeaturedImage} />
                    </Col>
                  </Row>
              </FreeContentAreaWrapper>
            </FreeLibraryContainer>
          </Col>
        </Row>
      )}
    </Main>
  );
}

export default FreeLibrarySection;
