import { Carousel } from 'antd';
import styled from 'styled-components';
import SurveyCard from '../SurveyCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import SubscribePackage from '../SubscribePackage';
import useVerifyUser from '../../hooks/useVerifyUser';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

const CarouselContainer = styled.div`
  margin: 0px auto;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;

  .ant-carousel {
    width: 90%;
  }
  .slick-slide > div > div {
    background-color: transparent;
  }
`;

const CarouselItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 8px;
`;

const Button = styled.button`
  height: 50px;
  width: 50px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: #39aaff;
  cursor: pointer;
`;

const SurveyCards = ({ surveys = [], onOpenSubscriptionModal }) => {
  const { isFreeAccount, freeSrveys } = useVerifyUser()
  const navigate = useNavigate();
  const carouselRef = useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2, // Number of slides to show on screens larger than 768px
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3, // Number of slides to show on screens larger than 1024px
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1740,
        settings: {
          slidesToShow: 4, // Number of slides to show on screens larger than 1024px
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 2560,
        settings: {
          slidesToShow: 5, // Number of slides to show on screens larger than 1024px
          slidesToScroll: 1,
        },
      },
    ],
  };

  const next = () => {
    carouselRef.current.next();
  };
  const prev = () => {
    carouselRef.current.prev();
  };

  return (
    <CarouselContainer>
      <Button onClick={prev}>
        <FontAwesomeIcon icon={regular("angle-left")} size="2x" color="#fff" />
      </Button>

      <Carousel ref={carouselRef} {...settings}>
        {surveys.map((survey) => (
          <CarouselItem key={survey.key} onClick={() => {
            if(isFreeAccount && !freeSrveys.includes(survey.key)) {
              onOpenSubscriptionModal()
            } else {
              navigate(`create/${survey.id}`)
            }
          }}>
            <SurveyCard key={survey.key} title={survey.key.replace(/_/g, ' ')} />
            {isFreeAccount && !freeSrveys.includes(survey.key) && <SubscribePackage onClickSubscribe={onOpenSubscriptionModal} />}
          </CarouselItem>
        ))}
      </Carousel>
      <Button onClick={next}>
        <FontAwesomeIcon icon={regular("angle-right")} size="2x" color="#fff" />
      </Button>
    </CarouselContainer>
  );
};

export default SurveyCards;
