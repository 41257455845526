import { Button, Divider, Form, Input, Popconfirm, Select, Typography } from 'antd';
import { useState } from 'react';
import { clone, slice, compact } from 'lodash';
import { useTargetAudienceDispatch, useTargetAudienceState } from '../../context/targetAudience';
import useTargetAudience from '../../hook/useTargetAudience';
import Popup from '../../../ui-elements/Popup';
import FormField from './FormField';
import EditListModel from './components/EditListModel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/pro-duotone-svg-icons';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
import { reducerTypes } from '../../context/targetAudience/constants';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import LoadingSpinner from '../../../ui-elements/LoadingSpinner';
import dayjs from 'dayjs';

const { Title, Text } = Typography;

function getTypeAndIdArray(data) {
  if (data === undefined) {
    return {};
  }
  const keys = Object?.keys(data);
  const array = Object?.values(data)
    ?.map((obj, index) => {
      if (obj.type === 'individual') {
        return;
      } else {
        return { type: obj?.type, id: data[keys[index + 1]]?.id };
      }
    })
    .filter(Boolean);

  const groups = array.filter((i) => i.type === 'group')?.map((i) => i?.id);
  const jobRole = array.find((i) => i.type === 'jobRole')?.id;

  return { groups, jobRole };
}

const EditIndividualModal = () => {
  const [form] = Form.useForm();
  const [confirmLoadingDeleteButton, setConfirmLoadingDeleteButton] = useState(false);
  const {
    stakeholders,
    jobs,
    levels,
    columns,
    groups,
    locations,
    models: { editModal },
  } = useTargetAudienceState();
  const dispatch = useTargetAudienceDispatch();
  const { data: audience, selectedNodes } = editModal ?? {};
  const selected = getTypeAndIdArray(!audience ? selectedNodes : {});
  const { getGroups, getLocations, saveAudience, editIndividual, deleteIndividual, formatMetaInfo } = useTargetAudience();
  const [editListModelOpen, setEditListModelOpen] = useState({ editListTitle: '', state: false });
  const [selectOption, setSelectOption] = useState({});
  const [loading, setLoading] = useState(false);

  const [groupsState, setGroupsState] = useState(
    audience?.target_audience_groups
      ?.sort((a, b) => a?.group?.level?.levelNumber - b?.group?.level?.levelNumber)
      ?.map((tag) => tag.group.id) ||
      selected?.groups ||
      [],
  );
  const [locationState, setLocationState] = useState(
    audience?.target_audience_locations
      ?.sort((a, b) => a?.location_level_data?.level?.levelNumber - b?.location_level_data?.level?.levelNumber)
      ?.map((tag) => tag.location_level_data.id) || [],
  );

  const metaInfo = columns
    ?.filter((i) => i.typeKey === 'employeeId' || (i.typeKey === 'other' && i?.isIndividual))
    ?.map((column) => ({
      ...column,
      initialValue: formatMetaInfo(column, null, audience?.metaInfo?.[column?.id]),
    }));

  const deleteIndividualHandler = async () => {
    await deleteIndividual({
      audienceId: audience?.id,
    });

    dispatch({
      type: reducerTypes.EDIT_INDIVIDUAL,
      payload: { type: 'delete', id: audience?.id},
    });

    close();
  };

  function transformKeys(inputObj) {
    const transformedData = {};
    const remainingData = {};

    for (const [key, value] of Object.entries(inputObj)) {
      if (key.startsWith('location')) {
        const index = parseInt(key.replace('location', ''), 10);
        transformedData[`location_level_${index}`] = locations.find((j) => j.id === value)?.locationName;
      } else if (key.startsWith('group')) {
        const index = parseInt(key.replace('group', ''), 10);
        transformedData[`group_level_${index}`] = groups.find((j) => j.id === value)?.groupName;
      } else if (key.startsWith('jobRole')) {
        transformedData[key] = jobs.find((j) => j.id === value)?.jobRole;
      } else {
        remainingData[key] = value;
      }
    }

    return { ...transformedData, ...remainingData };
  }

  function getValuesBySubstring(data, substring) {
    const valuesArray = [];

    for (const key in data) {
      if (key.includes(substring)) {
        valuesArray.push(data[key]);
      }
    }

    if (substring === 'group') {
      return valuesArray.map((i) => groups.find((j) => j?.id === i)?.level?.groupLevelName);
    }

    if (substring === 'location') {
      return valuesArray.map((i) => locations.find((j) => j?.id === i)?.level?.locationLevelName);
    }
  }

  function refactorForEditIndividual(inputObject) {
    const metaInfo = {};
    const rest = {};
    for (const key in inputObject) {
      if (/^\d+$/.test(key)) {
        if (inputObject[key] instanceof dayjs) {
          const val = inputObject[key].format()
          metaInfo[key] = val;
        } else {
          metaInfo[key] = inputObject[key];
        } 
      } else {
        rest[key] = inputObject[key];
      }
    }

    return { ...rest, metaInfo };
  }

  const onFinish = async (values) => {
    setLoading(true);
    if (!!audience) {
      await editIndividual(
        audience.id,
        {
          ...refactorForEditIndividual(values),
          groups: groupsState,
          locations: locationState,
        }
      );
    } else {
      const audienceData = transformKeys(values);
      const target_audiences = refactorForEditIndividual(audienceData);

      saveAudience(
        {
          groups: getValuesBySubstring(values, 'group'),
          locations: getValuesBySubstring(values, 'location'),
          target_audiences: compact([target_audiences]),
        },
        'all-view',
      );
    }

    setLoading(false);
    close();
  };

  const close = () => {
    dispatch({
      type: reducerTypes.SET_EDIT_MODAL,
      payload: null,
    });
  };

  const columnTitle = (key) => {
    return columns?.find((i) => i?.key === key)?.title;
  };
  const stackholderModalHandler = () => {
    dispatch({
      type: reducerTypes.STAKEHOLDERS_EDIT_MODAL,
      payload: true,
    });
  };

  return (
    <Popup
      width="60%"
      open={editModal?.type === 'individual'}
      close={close}
      footer={null}
      title={`${!!audience ? 'Edit' : 'Add'} Individual`}
    >
      <StyldForm
        form={form}
        layout="vertical"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 16 }}
        style={{ width: '80%' }}
        onFinish={onFinish}
      >
        <InputContainer>
          <Form.Item name="firstName" label={columnTitle('firstName')} initialValue={audience?.firstName}>
            <Input size="large" />
          </Form.Item>
        </InputContainer>
        <InputContainer>
          <Form.Item name="lastName" label={columnTitle('lastName')} initialValue={audience?.lastName}>
            <Input size="large" />
          </Form.Item>
        </InputContainer>
        <InputContainer>
          <Form.Item name="email" label={columnTitle('email')} initialValue={audience?.email}>
            <Input size="large" />
          </Form.Item>
        </InputContainer>
        <InputContainer>
          <Form.Item
            name="jobRole"
            label={columnTitle('jobRole')}
            initialValue={audience?.job_role?.id || selected.jobRole}
          >
            <Select
              dropdownRender={(menu) => (
                <div style={{ background: '#fff', padding: '5px 0px' }}>
                  {menu}
                  <>
                    <StyledDivider />
                    <Wrapper>
                      <FontAwesomeIcon
                        icon={faPencil}
                        style={{ cursor: 'pointer', marginRight: '20px' }}
                        onClick={() => {
                          setEditListModelOpen({ editListTitle: 'Job Role', state: true, type: 'jobRole' });
                        }}
                      />
                    </Wrapper>
                  </>
                </div>
              )}
            >
              {jobs?.map((jobRole) => (
                <Select.Option key={jobRole.id} value={jobRole.id}>
                  {jobRole.jobRole}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </InputContainer>

        {levels.groupLevels?.map((groupLevel, index) => (
          <InputContainer key={groupLevel.id}>
            <Form.Item
              name={`group${index}`}
              label={groupLevel.groupLevelName}
              initialValue={
                !!audience
                  ? audience?.target_audience_groups?.find((tag) => tag.group.level.id === groupLevel.id)?.group?.id
                  : selected?.groups[index]
              }
            >
              {index === 0 ? (
                <Select
                  onSelect={(v) => {
                    setGroupsState([v]);
                    form.setFieldValue('group1', undefined);
                    form.setFieldValue('group2', undefined);
                  }}
                  dropdownRender={(menu) => (
                    <div style={{ background: '#fff', padding: '5px 0px' }}>
                      {menu}
                      <>
                        <StyledDivider />
                        <Wrapper>
                          <FontAwesomeIcon
                            icon={faPencil}
                            style={{ cursor: 'pointer', marginRight: '20px' }}
                            onClick={() => {
                              setEditListModelOpen({
                                editListTitle: groupLevel.groupLevelName,
                                state: true,
                                levelId: groupLevel.id,
                                type: 'group',
                                options: getGroups()?.map((grp) => ({
                                  key: grp.id,
                                  value: grp.id,
                                  label: grp.groupName,
                                })),
                              });
                            }}
                          />
                        </Wrapper>
                      </>
                    </div>
                  )}
                >
                  {getGroups()?.map((group) => (
                    <Select.Option key={group.id} value={group.id}>
                      {group.groupName}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <Select
                  onSelect={(v) => {
                    const temp = clone(groupsState);
                    temp[index] = v;
                    setGroupsState(slice(temp, 0, index + 1));
                  }}
                  dropdownRender={(menu) => (
                    <div style={{ background: '#fff', padding: '5px 0px' }}>
                      {menu}
                      {groupsState[index - 1] && (
                        <>
                          <StyledDivider />
                          <Wrapper>
                            <FontAwesomeIcon
                              icon={faPencil}
                              style={{ cursor: 'pointer', marginRight: '20px' }}
                              onClick={() => {
                                setEditListModelOpen({
                                  editListTitle: groupLevel.groupLevelName,
                                  state: true,
                                  type: 'group',
                                  levelId: groupLevel.id,
                                  parentName: groups?.find((grp) => grp.id === groupsState[index - 1])?.groupName,
                                  parentId: groups?.find((grp) => grp.id === groupsState[index - 1])?.id,
                                  options: getGroups(groupsState[index - 1])?.map((grp) => ({
                                    key: grp.id,
                                    value: grp.id,
                                    label: grp.groupName,
                                  })),
                                });
                              }}
                            />
                          </Wrapper>
                        </>
                      )}
                    </div>
                  )}
                >
                  {(groupsState[index - 1] ? getGroups(groupsState[index - 1]) : [])?.map((group) => (
                    <Select.Option key={group.id} value={group.id}>
                      {group.groupName}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </InputContainer>
        ))}

        {levels.locationLevels?.map((locationLevel, index) => (
          <InputContainer key={locationLevel.id}>
            <Form.Item
              name={`location${index}`}
              label={locationLevel.locationLevelName}
              initialValue={
                audience?.target_audience_locations?.find(
                  (tal) => tal.location_level_data.level.id === locationLevel.id,
                )?.location_level_data?.id
              }
            >
              {index === 0 ? (
                <Select
                  onSelect={(v) => {
                    setLocationState([v]);
                    form.setFieldValue('location1', undefined);
                    form.setFieldValue('location2', undefined);
                  }}
                  dropdownRender={(menu) => (
                    <div style={{ background: '#fff', padding: '5px 0px' }}>
                      {menu}
                      <>
                        <StyledDivider />
                        <Wrapper>
                          <FontAwesomeIcon
                            icon={faPencil}
                            style={{ cursor: 'pointer', marginRight: '20px' }}
                            onClick={() => {
                              setEditListModelOpen({
                                editListTitle: locationLevel.locationLevelName,
                                levelId: locationLevel.id,
                                state: true,
                                type: 'location',
                                options: getLocations()?.map((loc) => ({
                                  key: loc.id,
                                  value: loc.id,
                                  label: loc?.locationName,
                                })),
                              });
                            }}
                          />
                        </Wrapper>
                      </>
                    </div>
                  )}
                >
                  {getLocations()?.map((location) => (
                    <Select.Option key={location.id} value={location.id}>
                      {location?.locationName}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <Select
                  onSelect={(v) => {
                    const temp = clone(locationState);
                    temp[index] = v;
                    setLocationState(slice(temp, 0, index + 1));
                  }}
                  dropdownRender={(menu) => (
                    <div style={{ background: '#fff', padding: '5px 0px' }}>
                      {menu}
                      <>
                        <StyledDivider />
                        <Wrapper>
                          <FontAwesomeIcon
                            icon={faPencil}
                            style={{ cursor: 'pointer', marginRight: '20px' }}
                            onClick={() => {
                              setEditListModelOpen({
                                editListTitle: locationLevel.locationLevelName,
                                state: true,
                                levelId: locationLevel.id,
                                type: 'location',
                                parentName: locations.find((loc) => loc.id === locationState[index - 1])?.locationName,
                                parentId: locations.find((loc) => loc.id === locationState[index - 1])?.id,
                                options: getLocations(locationState[index - 1])?.map((loc) => ({
                                  key: loc.id,
                                  value: loc.id,
                                  label: loc?.locationName,
                                })),
                              });
                            }}
                          />
                        </Wrapper>
                      </>
                    </div>
                  )}
                >
                  {(locationState[index - 1] ? getLocations(locationState[index - 1]) : [])?.map((location) => (
                    <Select.Option key={location.id} value={location.id}>
                      {location?.locationName}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </InputContainer>
        ))}

        {metaInfo?.map((column) => (
          <InputContainer key={column.id}>
            <label style={{ fontSize: '14px', fontWeight: '500', paddingBottom: '8px' }}>{column.title}</label>
            <div className="input">
              <FormField
                key={column?.id}
                name={column?.id}
                col={column}
                selectOption={selectOption}
                setSelectOption={setSelectOption}
                setEditListModelOpen={setEditListModelOpen}
              />
            </div>
          </InputContainer>
        ))}

        <InputContainer>
          <div className="input">
            <Form.Item
              name="stakeholders"
              label="Stakeholder Type"
              initialValue={audience?.audience_stakeholders?.map((stakeholder) => stakeholder.stakeholder.id)}
            >
              <Select
                mode="multiple"
                style={{ height: 'auto' }}
                showSearch={false}
                dropdownRender={(menu) => (
                  <>
                    {' '}
                    {menu}{' '}
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'end',
                        padding: '10px',
                        borderTop: '1px solid #e3e6ef',
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faPencil}
                        onClick={stackholderModalHandler}
                        style={{ cursor: 'pointer' }}
                      />
                    </div>{' '}
                  </>
                )}
              >
                {stakeholders?.map((stakeholder) => (
                  <Select.Option key={stakeholder.id} value={stakeholder.id}>
                    {stakeholder.stakeholderName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </InputContainer>

        <ButtonContainer>
          <Button
            type="primary"
            style={{ background: 'gray', padding: '5px 30px' }}
            shape="round"
            size={'large'}
            onClick={() => close()}
            htmlType="button"
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            style={{ background: '#0066B2', padding: '5px 40px' }}
            shape="round"
            size={'large'}
            htmlType="submit"
            disabled={loading}
          >
            {loading ? <LoadingOutlined style={{ fontSize: 24 }} spin /> : !!audience ? 'Update' : 'Save'}
          </Button>
          {!!audience && (
            <div>
              <Popconfirm
                overlayClassName="deletePopUp"
                title={
                  <Title level={2} type={'danger'}>
                    Warning
                  </Title>
                }
                icon={
                  <FontAwesomeIcon
                    icon={duotone('circle-question')}
                    size="xl"
                    style={{
                      '--fa-primary-color': '#ff8080',
                      '--fa-secondary-color': '#ff8080',
                      marginRight: '20px',
                      paddingTop: '10px',
                    }}
                  />
                }
                description={
                  <div style={{ width: '100%' }}>
                    <p>
                      <Text style={{ fontSize: '23px', paddingBottom: '20px' }} italic>
                        You are about to delete this individual and their data.
                      </Text>
                    </p>
                    <p>
                      <Text type="secondary" style={{ fontSize: '23px', paddingBottom: '20px' }}>
                        Do you want to continue?
                      </Text>
                    </p>
                  </div>
                }
                onConfirm={async () => {
                  setConfirmLoadingDeleteButton(true);
                  await deleteIndividualHandler();
                  setConfirmLoadingDeleteButton(false);
                }}
                onCancel={() => {}}
                okText="Delete"
                cancelText="Cancel"
                okButtonProps={{
                  danger: true,
                  loading: confirmLoadingDeleteButton,
                  shape: 'round',
                  size: 'large',
                }}
                cancelButtonProps={{
                  danger: false,
                  shape: 'round',
                  size: 'large',
                  type: 'default',
                }}
              >
                <Text
                  type="danger"
                  strong
                  italic
                  underline
                  style={{
                    paddingLeft: '10px',
                    fontSize: '15px',
                    cursor: 'pointer',
                  }}
                >
                  Delete
                </Text>
              </Popconfirm>
            </div>
          )}
        </ButtonContainer>
      </StyldForm>

      {editListModelOpen?.state && (
        <EditListModel
          title="Edit List"
          type={editListModelOpen?.type}
          open={editListModelOpen?.state}
          editListTitle={editListModelOpen?.editListTitle}
          parentName={editListModelOpen.parentName}
          parentId={editListModelOpen.parentId}
          options={editListModelOpen.options}
          levelId={editListModelOpen?.levelId}
          editMode
          close={() => {
            setEditListModelOpen({ editListTitle: '', state: false });
          }}
        />
      )}

      <LoadingSpinner
        size={'large'}
        popupHeading={
          !!audience
            ? 'Wait while system is updating your Target Audience.'
            : 'Wait while system is importing your Target Audience.'
        }
        open={loading}
        backgroundOverlay={true}
      />
    </Popup>
  );
};

const StyldForm = styled(Form)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
const InputContainer = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
  > div {
    .ant-col.ant-form-item-control {
      max-width: 100%;
    }
  }

  div.input .ant-input {
    border-radius: 6px !important;
    padding: 7px 11px;
  }
  div.input .ant-form-item {
    margin-bottom: 24px !important;
  }

  div.input .ant-select {
    height: 38.2px;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 5px 0px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: end;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: end;
  gap: 50px;
  padding-top: 25px;
  > div {
    position: absolute;
    right: 30px;
    bottom: 30px;
  }
`;

export default EditIndividualModal;
