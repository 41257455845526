import React, { useCallback, useEffect, useReducer, useState, useRef } from 'react';
import { Dropdown, Space, Divider, Button, Modal, Form, Input, Flex } from 'antd';

import { DataService } from "../../config/dataService/dataService";
import { clone } from 'lodash';

import { useLocation, useNavigate } from 'react-router-dom';
import AddNewProject from './AddNewProject';
import { useDispatch } from 'react-redux';
import { getAccountUserProjects } from '../../redux/project/actionCreator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faCircleXmark, faFloppyDisk, faPencil } from '@fortawesome/pro-duotone-svg-icons';
import { truncateString } from '../../utility/utility';
import { logOut } from '../../redux/authentication/actionCreator';
import useNotificationController from '../../utility/useNotificationController';
import { makeActive } from '../../utility/commonFunction';

const { theme } = require('../../config/theme/themeVariables');
const { Search } = Input;

const ProjectDropdown = ({userInfo = {}}) => {
  const dropdownRef = useRef(null);
  const dropdowULRef = useRef(null);
  const dropdownContainerRef = useRef(null);
  const { contextHolder, openSuccess, openError } = useNotificationController();
  
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const location = useLocation()

  const userTheme = JSON.parse(localStorage.getItem('userTheme'));
  theme['user-theme'] = userTheme;
  
  const [loaded, setLoaded] = useState(false);
  const [projects, setProjects] = useState([]);

  const projectName = localStorage.getItem('active_project_name');
  
  const [currentActiveName, setCurrentActiveName] = useState(truncateString(projectName, 25));
  const [activeName, setActiveName] = useState(projectName);
  const [currentActiveProjectId, setCurrentActiveProjectId] = useState(localStorage.getItem('active_project_id'));
  const [newProjectName, setNewProjectName] = useState('');
  const [isEditable, setIsEditable] = useState(false);
  const [open, setOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [isSearchable, setIsSearchable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isInSearchMode, setIsInSearchMode] = useState(false);
  const [userType, setUserType] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [isSearchInputActive, setIsSearchingInputActive] = useState(false);
  const [page, setPage] = useState(1);

  const initialState = {
      error: false,
      messages: ""
  };

  const reducer = (state, action) => {
      switch (action.type) {
        case 'GET_ACCOUNT_USER_PROJECT_ERR':
          return { ...state, error: true,messages: action.err };
        case 'RESET':
          return state;
        default:
          return state;
      }
  };

  const [apiError, apiResponseCallback] = useReducer(reducer, initialState);

  const dispatch = useDispatch();
    
  const fetchData = useCallback(async (page) => {
    apiResponseCallback({ type: 'RESET' });
    const params = {
      page: page
    }
    if (isInSearchMode)
    {
      params['projectName'] = searchValue;
    }
    dispatch(
        getAccountUserProjects(params, (response) => {
          if (response.status === 200) {
            const userType = response.data.userType;
            const projects = response.data.projects;
            if (projects.length === 0)
            {
              dispatch(logOut(() => navigate('/')));
            }
            let currentProjectName = currentActiveName;
            let currentProjectId = currentActiveProjectId;
            const projectInfo = [];
            projects.map((project, i) => {
              let tmp = {};
              tmp = {
                key: project.id,
                label: project.projectName,
                projectname: project.projectName
              };
              projectInfo.push(tmp);
              if (project.id === response.data.activeProjectId)
              {
                currentProjectId = project.id;
                currentProjectName = project.projectName;
              }
            });
            setCurrentActiveProjectId(currentProjectId);
            setCurrentActiveName(truncateString(currentProjectName, 25));
            localStorage.setItem('active_project_id', currentProjectId);
            localStorage.setItem('active_project_name', currentProjectName);

            setLoaded(true);
            setProjects(projectInfo);
            setUserType(userType);

            setIsSearchable(response.data.isSearchable);
            setHasMore(response.data.hasMore);
          } 
          else 
          {
            setLoaded(true);
            apiResponseCallback(response);
          }
        }),
      );
  }, [
    dispatch, 
    loaded,
    isInSearchMode,
    searchValue,
    isSearchable,
    projects, 
    hasMore
  ]);
    
  const fetchMoreProjects = useCallback(async (page, isInSearch = false, searchString = '') => {
    apiResponseCallback({ type: 'RESET' });
    const params = {
      page: page
    }
    if (isInSearch)
    {
      if (searchString.toString().trim() !== '')
      {
        params['projectName'] = searchString;
      }
    }
    else if (isInSearchMode)
    {
      params['projectName'] = searchString.toString().trim() === '' ? searchValue : searchString;
    }
    dispatch(
        getAccountUserProjects(params, (response) => {
          if (response.status === 200) {
            const projects = response.data.projects;
            const projectInfo = [];
            projects.map((project, i) => {
              let tmp = {};
              tmp = {
                key: project.id,
                label: project.projectName,
                projectname: project.projectName
              };
              projectInfo.push(tmp);
            });
            if (parseInt(page) === 1)
            {
              setProjects(projectInfo);
            }
            else
            {
              setProjects((prevProjects) => [...prevProjects, ...projectInfo]);
            }
            setLoading(false);

            setHasMore(response.data.hasMore);
          } 
          else 
          {
            apiResponseCallback(response);
          }
        }),
      );
  }, [
    dispatch, 
    loading,
    searchValue,
    isInSearchMode,
    projects, 
    hasMore
  ]);

  const loadMoreProjects = async () => {
    setLoading(true);
    const currentPage = page + 1;
    if (projects.length === 0)
    {
      currentPage = 1;
    }
    setPage(currentPage);
    setTimeout(async () => {
      await fetchMoreProjects(currentPage);
    }, 1000);
  };

  const handleScroll = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      if (!loading && hasMore) {
        await loadMoreProjects();
      }
    }
  };

  const updateProjectName = async () => {
    const response = await DataService.post("/project/update-project-name",
      {
        projectName: newProjectName,
        projectId: currentActiveProjectId,
      }
    );

    if (response.status === 200){
      const currentProjects = clone(projects)
      const index = currentProjects.findIndex(project => project.key === currentActiveProjectId);
      if(index !== -1) {
        const newProject = {
          key: currentActiveProjectId,
          label: newProjectName,
          projectname: newProjectName
        }
        setProjects((prevProjects) => 
          prevProjects.map((project, i) => 
            i === index ? newProject : project
          )
        );
      }
      
      localStorage.setItem('active_project_name', newProjectName);
      localStorage.setItem('active_project_id', currentActiveProjectId);
      setCurrentActiveName(truncateString(newProjectName, 25));
      setActiveName(newProjectName);
      setNewProjectName('');
      setIsEditable(false);
    }
  }

  useEffect(() => {
    const retrieveData = async (page) => {
        fetchData(page);
    }
    retrieveData(page).catch(console.error);

    window.addEventListener('resize', updateText);

    return () => {
      window.removeEventListener('resize', updateText);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isEditable && dropdownRef.current !== null && !dropdownRef.current.contains(event.target))
      {
        if (dropdownContainerRef.current !== null && dropdownContainerRef.current.contains(event.target))
        {
          setIsEditable(false);
          setOpen(false);
        }
        else
        {
          setIsEditable(false);
          setOpen(false);
        }
      }
      else if (isSearchInputActive && dropdownRef.current !== null && !dropdownRef.current.contains(event.target))
      {
        if (dropdownContainerRef.current !== null && dropdownContainerRef.current.contains(event.target))
        {
          if (!isInSearchMode)
          {
            setSearchValue('');
          }
          setIsSearchingInputActive(false);
          setOpen(false);
        }
        else
        {
          if (!isInSearchMode)
          {
            setSearchValue('');
          }
          setIsSearchingInputActive(false);
          setOpen(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, isEditable, isSearchInputActive, isInSearchMode]);

  function updateText() 
  {
      let tmpActiveName = activeName;
      if (window.innerWidth > 600 && window.innerWidth <= 991)
      {
        tmpActiveName = truncateString(tmpActiveName, 25);
      }
      else if (window.innerWidth > 450 && window.innerWidth <= 600)
      {
        tmpActiveName = truncateString(tmpActiveName, 15);
      }
      else if (window.innerWidth <= 450)
      {
        tmpActiveName = truncateString(tmpActiveName, 10);
      }
      else if (tmpActiveName.length > 25)
      {
        tmpActiveName = truncateString(tmpActiveName, 25);
      }
      setCurrentActiveName(tmpActiveName);
  }

  const createProject = async (response) => {
    const project = response;
    setCurrentActiveName(truncateString(project.projectName, 25));
    setActiveName(project.projectName);
    setCurrentActiveProjectId(project.id);
    
    localStorage.setItem('active_project_name', project.id);
    localStorage.setItem('active_project_id', project.projectName);   
    setIsModalVisible(false);
    await makeActive({key:project.id}, openSuccess, openError, reloadDropdown);
  }

  const contentStyle = {
      backgroundColor: userTheme.backgroundColor,
      borderRadius: "0px",
      color: userTheme.Color
  };

  const onChangeSearch = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value.toString().trim() !== "")
    {
      setIsSearchingInputActive(true);
    }
    else if (e.target.value.toString().trim() === "")
    {
      setIsSearchingInputActive(false);
    }
    if (e.target.value.toString().trim().length > 2)
    {
      setTimeout(async () => {
        setIsInSearchMode(true);
        const currentPage = 1;
        setPage(1);
        setProjects([]);
        await fetchMoreProjects(currentPage, true, e.target.value.toString().trim());
      }, 10);
    }
    else if (isInSearchMode)
    {
      if (e.target.value.toString().trim() === '')
      {
        setTimeout(async () => {
          setIsInSearchMode(false);
          const currentPage = 1;
          setPage(1);
          setProjects([]);
          await fetchMoreProjects(currentPage, true);
        }, 10);
      }
      else
      {
        setTimeout(async () => {
          setIsInSearchMode(true);
          const currentPage = 1;
          setPage(1);
          setProjects([]);
          await fetchMoreProjects(currentPage, true, e.target.value.toString().trim());
        }, 10);
      }
    }
  }

  const searchProject = async () => {
    setTimeout(async () => {
      setIsInSearchMode(true);
      const currentPage = 1;
      setPage(1);
      setProjects([]);
      await fetchMoreProjects(currentPage, true);
    }, 10);
  }

  const closeEditProject = async () => {
    setIsEditable(false);
  }

  const dropdown = (menu) => {
    return (
      <div ref={dropdownRef}>
        {isSearchable && 
          <ul className="project-dropdown-all">
            <li key="searching" className={'inputLI'}>
              <Search placeholder="search project" onSearch={searchProject} value={searchValue} onChange={onChangeSearch} loading={isSearching} />
            </li>
          </ul>
        }
        <ul ref={dropdowULRef} className="project-dropdown-all" onScroll={handleScroll}>
          {menu.props.items.map((item) => (
            <li
              className={currentActiveProjectId === item.key ? !isEditable ? menu.props.items.length > 3 ? 'active-project' : '' : 'editable-project' : ''}
              key={item.key}
              onClick={() => item.key !== currentActiveProjectId ? makeActive(item, openSuccess, openError, reloadDropdown) : null}
            >
              {currentActiveProjectId === item.key ? (
                <>
                {
                  !isEditable ? (
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                      <span style={{ marginRight: 'auto' }}>{item.label}</span>
                      {currentActiveProjectId === item.key && (
                        <FontAwesomeIcon icon={faPencil} onClick={() => {
                          setIsEditable(true)
                          setNewProjectName(item.projectname)
                        }} />
                      )}
                    </div>
                  ) : (
                    <Input
                      value={newProjectName}
                      initialState={currentActiveName}
                      suffix={
                        <>
                          <FontAwesomeIcon className='saveProject' icon={faFloppyDisk} onClick={updateProjectName} />
                          <FontAwesomeIcon className='closeEditProject' icon={faCircleXmark} onClick={closeEditProject} />
                        </>
                      }
                      onChange={(e) => setNewProjectName(e.target.value)}
                    />
                  )
                }
                </>) : ( 
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <span style={{ marginRight: 'auto' }}>{item.label}</span>
                  </div>
                )}
            </li>
          ))}
          {loading && <li key="loading" className='loaderMenu'>
            <FontAwesomeIcon icon={duotone("spinner-third")} spin style={{"--fa-primary-color": `${userTheme.mainHighlightColor}`, "--fa-secondary-color": `${userTheme.mainHighlightColor}`, marginRight: "10px"}} />
            Loading more projects...
            </li>
          }
        </ul>
      </div>
    );
  };

  const reloadDropdown = async (response) => {
    setCurrentActiveProjectId(response.data.projectId);
    setCurrentActiveName(truncateString(response.data.projectName, 25));
    setActiveName(response.data.projectName);
    localStorage.setItem('active_project_name', response.data.projectName);
    localStorage.setItem('active_project_id', response.data.projectId);
    navigate(0);
  } 
    
  const isAmazon = localStorage.getItem('is_amazon') === 'true';
  
  return (
    <Flex className={'project-dropdown-menu project-panel'}>
        {contextHolder}
        {!loaded ? (
            <FontAwesomeIcon icon={duotone("spinner-third")} spin size="2xl" style={{"--fa-primary-color": `${userTheme.mainHighlightColor}`, "--fa-secondary-color": `${userTheme.mainHighlightColor}`, "padding": "20px 10px"}} />
        ) : (
          <Dropdown
            open={open}
            onOpenChange={() => {
              if (isEditable === false && isSearchInputActive === false)
              {
                setOpen(!open);
              }
            }}
            menu={{ 
              items: projects,
              multiple: false,
              selectable: true,
              defaultSelectedKeys: [currentActiveProjectId.toString()],
              onClick: (project) => {
                makeActive(project, openSuccess, openError, reloadDropdown);
              }
            }}
            overlayClassName='project-dropdown'
            dropdownRender={(menu) => (
              <div style={contentStyle}>
                {React.cloneElement(dropdown(menu), { style: {borderRadius: "0px"} })}
                <Divider style={{ margin: 0 }} />
                {parseInt(userType) < 3 ? (
                  <Space className='buttonAddNew' style={{ padding: 18, display: "flex", justifyContent: "center" }}>
                      <Button type="ocm-default" onClick={()=>{setIsModalVisible(true); setOpen(false);}}><FontAwesomeIcon icon={regular("plus")} /> Add New Project</Button>
                  </Space>
                ) : (
                  parseInt(userType) <= 3 && isAmazon && (
                    <Space className='buttonAddNew' style={{ padding: 18, display: "flex", justifyContent: "center" }}>
                        <Button type="ocm-default" onClick={()=>{setIsModalVisible(true); setOpen(false);}}><FontAwesomeIcon icon={regular("plus")} /> Add New Project</Button>
                    </Space>
                  )
                )}
              </div>
            )} 
          >
            <a onClick={(e) => e.preventDefault()}>
                <Space ref={dropdownContainerRef} style={{color: userTheme.Color}}>
                  {`${currentActiveName}`}
                </Space>
                <FontAwesomeIcon icon={duotone("caret-down")} style={{"--fa-primary-color": "#FFFFFF", "--fa-secondary-color": "#FFFFFF", position: "absolute", top: "35%", right: "10px"}} onClick={() => setOpen(!open)} />
            </a>
          </Dropdown>
        )}
        <Modal
          title=""
          wrapClassName={'ocm-modal'}
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          width={1000}
          zIndex={99999}
          footer={[]}
        >
          <AddNewProject createProject={(response) => createProject(response)}/>
        </Modal>
      </Flex>
  );
};

export default ProjectDropdown;
