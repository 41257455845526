import React from "react";
import {
  InfoColumn,
  InfoContent,
  InfoText,
} from "./LibrarySection.styles";

const LicenseDetails = () => {
  return (
    <InfoColumn>
      <InfoContent>
        <InfoText>
            <p>
                Purchase a license to access our popular Change Management Library for
                enhanced skills and success.
            </p>
            <p>Gain exclusive resources and guides for mastering change management.</p>
        </InfoText>
      </InfoContent>
    </InfoColumn>
  );
}

export default LicenseDetails;