import React, { useRef, useEffect, useState } from 'react';

import { Chart, BarController, BarElement, LinearScale, TimeScale, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-moment';
import { CategoryScale } from 'chart.js';
// Import utilities
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Import the datalabels plugin
import 'chart.js/auto';
Chart.register(ChartDataLabels, CategoryScale, BarController, BarElement, LinearScale, TimeScale, Tooltip, Legend);

function BarChart01({ yAxisTitle, data }) {
  const canvas1 = useRef(null);

  useEffect(() => {
    const ctx = canvas1?.current;

    const newChart = new Chart(ctx, {
      type: 'bar',
      data: data,
      options: {
        scales: {
          x: {
            stacked: true,

            autoSkipPadding: 48,
            border: {
              display: false,
            },
            ticks: {
              color: 'black',
              maxRotation: 40,
              minRotation: 40,
              stepSize: 1, // Set the step size to 1 for whole numbers
            },
            grid: {
              display: false,
            },
            gridLines: {
              drawBorder: true,
            },
          },
          y: {
            stacked: true,
            title: {
              display: yAxisTitle ? true : false,
              text: yAxisTitle ?? '',
              color: '#7F7F7F',
            },
            border: {
              display: false,
            },
            ticks: { stepSize: 1, color: '#7F7F7F', brginAtZero: true },
            grid: {
              color: '#D9D9D9',
              drawTicks: true,
            },
            gridLines: {
              drawBorder: false,
            },
          },
        },
        autoPadding: true,
        layout: {
          padding: {
            top: 30,
            bottom: 16,
            left: 20,
          },
        },
        interaction: {
          intersect: false,
          mode: 'nearest',
        },
        animation: {
          duration: 500,
        },
        responsive: true,
        maintainAspectRatio: false,
        resizeDelay: 200,

        plugins: {
          legend: { display: false },
          datalabels: {
            anchor: 'center',
            align: 'center',
            color: 'white',
            font: {
              weight: 'bold',
            },
            formatter: (value) => {
              return value?.y > 0 ? value?.y : '';
            },
          },
        },
      },
    });
    return () => newChart.destroy();
  }, [data]);

  return (
    <React.Fragment>
      <canvas ref={canvas1}></canvas>
    </React.Fragment>
  );
}

export default BarChart01;
