import React from "react";
import styled from "styled-components";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CategoryItem = ({ key = '', title = '', isActive = false }) => {
    if (isActive) 
    {
      return (
        <Item key={key}>
          <span className="activeTitle">{title}</span>
          <FontAwesomeIcon icon={faChevronRight} color="#BFBFBF" height={21} width={20} />
        </Item>
      );
    }
  
    return <Item key={key}>{title}</Item>;
}

const Item = styled.button`
    font-family: Inter, sans-serif;
    background: none;
    border: none;
    text-align: left;
    cursor: pointer;
    padding: 10px 0;
    margin-top: 27px;
    font-size: 18px;
    color: inherit;
    &:first-child {
        margin-top: 0;
    }
`;

export default CategoryItem;