import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const {
  fetchCMSCategoriesBegin,
  fetchCMSCategoriesSuccess,
  fetchCMSCategoriesErr,

  fetchTrainingCategoriesBegin,
  fetchTrainingCategoriesSuccess,
  fetchTrainingCategoriesErr,

  fetchResourceCategoriesBegin,
  fetchResourceCategoriesSuccess,
  fetchResourceCategoriesErr,

  fetchCMSPagesBegin,
  fetchCMSPagesSuccess,
  fetchCMSPagesErr,

  fetchTrainingPagesBegin,
  fetchTrainingPagesSuccess,
  fetchTrainingPagesErr,

  fetchResourcePagesBegin,
  fetchResourcePagesSuccess,
  fetchResourcePagesErr,

  fetchCMSPageContentBegin,
  fetchCMSPageContentSuccess,
  fetchCMSPageContentErr,

  fetchPublicCMSPageContentBegin,
  fetchPublicCMSPageContentSuccess,
  fetchPublicCMSPageContentErr
} = actions;

const fetchCMSCategories = (values, callback) => {
  return async (dispatch) => {
    dispatch(fetchCMSCategoriesBegin());
    try {
      const response = await DataService.post('/cms/get-cms-categories', values);
      if (response.status !== 200) {
        const messages = dispatch(fetchCMSCategoriesErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(fetchCMSCategoriesSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(fetchCMSCategoriesErr(err));
      callback(messages);
    }
  };
};

const fetchTrainingCategories = (values, callback) => {
  return async (dispatch) => {
    dispatch(fetchTrainingCategoriesBegin());
    try {
      const response = await DataService.post('/cms/get-training-categories', values);
      if (response.status !== 200) {
        const messages = dispatch(fetchTrainingCategoriesErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(fetchTrainingCategoriesSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(fetchTrainingCategoriesErr(err));
      callback(messages);
    }
  };
};

const fetchResourceCategories = (values, callback) => {
  return async (dispatch) => {
    dispatch(fetchResourceCategoriesBegin());
    try {
      const response = await DataService.get('/cms/get-resources-categories', values);
      if (response.status !== 200) {
        const messages = dispatch(fetchResourceCategoriesErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(fetchResourceCategoriesSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(fetchResourceCategoriesErr(err));
      callback(messages);
    }
  };
};

const fetchCMSPages = (values, callback) => {
  return async (dispatch) => {
    dispatch(fetchCMSPagesBegin());
    try {
      const response = await DataService.post('/cms/get-cms-pages', values);
      if (response.status !== 200) {
        const messages = dispatch(fetchCMSPagesErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(fetchCMSPagesSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(fetchCMSPagesErr(err));
      callback(messages);
    }
  };
};

const fetchTrainingPages = (values, callback) => {
  return async (dispatch) => {
    dispatch(fetchTrainingPagesBegin());
    try {
      const response = await DataService.post('/cms/get-training-pages', values);
      if (response.status !== 200) {
        const messages = dispatch(fetchTrainingPagesErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(fetchTrainingPagesSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(fetchTrainingPagesErr(err));
      callback(messages);
    }
  };
};

const fetchResourcePages = (values, callback) => {
  return async (dispatch) => {
    dispatch(fetchResourcePagesBegin());
    try {
      const response = await DataService.post('/cms/get-resources-pages', values);
      if (response.status !== 200) {
        const messages = dispatch(fetchResourcePagesErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(fetchResourcePagesSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(fetchResourcePagesErr(err));
      callback(messages);
    }
  };
};

const fetchCMSPageContent = (values, callback) => {
  return async (dispatch) => {
    dispatch(fetchCMSPageContentBegin());
    try {
      const response = await DataService.post('/cms/get-cms-page', values);
      if (response.status !== 200) {
        const messages = dispatch(fetchCMSPageContentErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(fetchCMSPageContentSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(fetchCMSPageContentErr(err));
      callback(messages);
    }
  };
};

const fetchPublicCMSPageContent = (values, callback) => {
  return async (dispatch) => {
    dispatch(fetchPublicCMSPageContentBegin());
    try {
      const response = await DataService.post('/cms/free/get-public-cms-page', values);
      if (response.status !== 200) {
        const messages = dispatch(fetchPublicCMSPageContentErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(fetchPublicCMSPageContentSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(fetchPublicCMSPageContentErr(err));
      callback(messages);
    }
  };
};


export { 
  fetchCMSCategories,
  fetchTrainingCategories,
  fetchResourceCategories,
  fetchCMSPages,
  fetchTrainingPages,
  fetchResourcePages,
  fetchCMSPageContent,
  fetchPublicCMSPageContent,
};
