import { audienceColumnsTypes, defaultColumn } from '../context/targetAudience/constants';
import { uniqBy } from 'lodash';
import dayjs from 'dayjs'

const checkLocationAndGroup = (columns) => {
  const array = defaultColumn?.filter(
    (item) => !columns?.some((column) => column.key === item.key && column?.id === item?.id),
  );
  return array;
};

export const detectDateFormat = (dateString) => {
  const formats = [
    'YYYY-MM-DD',
    'DD-MM-YYYY',
    'MM-DD-YYYY',
    'YYYY/MM/DD',
    'DD/MM/YYYY',
    'MM/DD/YYYY',
    'YYYY.MM.DD',
    'DD.MM.YYYY',
    'MM.DD.YYYY',
  ];

  for (const format of formats) {
    if (dayjs(dateString, format, true).isValid()) {
      return format;
    }
  }

  return null; // Return null if format is not detected
}

export const reFactor = (columnsList, getGroups, getLocations, jobRoles) => {
  let groupLevel = 1;
  let locationLevel = 1;

  const locationAndGroup = checkLocationAndGroup(columnsList);
  const columns = columnsList?.map((i) => {
    if (
      i.type === 'core' ||
      i.typeKey === 'firstName' ||
      i.typeKey === 'lastName' ||
      i.typeKey === 'jobRole' ||
      i.typeKey === 'email'
    ) {
      let column = defaultColumn.find((c) => c.dataIndex === i.key);

      if (column?.dataIndex === 'jobRole') {
        const options = jobRoles.map((i, index) => ({ value: i.jobRole, label: i.jobRole, key: i.id }));
        column = { ...column, options };
      }
      return { ...column, title: i.name || i.title };
    } else {
      const column = audienceColumnsTypes.find((c) => c.key === i.type || c.key === i.typeKey);
      let options = [];
      let dataIndex = '';
      if (i.typeKey === 'group' || i.type === 'group') {
        options =
          uniqBy(
            getGroups(null, groupLevel)
              ?.map((l) => ({
                ...l,
                value: l.groupName,
                label: l.groupName,
                key: l.id,
                levelId: l.level?.id,
              }))
              .filter((i) => i.label),
            'label',
          ) || [];
        groupLevel++;
      }
      if (i.typeKey === 'location' || i.type === 'location') {
        options = uniqBy(
          getLocations(null, locationLevel)
            ?.map((l) => ({
              ...l,
              value: l.locationName,
              label: l.locationName,
              key: l.id,
              levelId: l.level?.id,
            }))
            .filter((i) => i.label),
          'label',
        );
        locationLevel++;
      }
      if (i.typeKey === 'other' || i.type === 'other') {
        options = i.options
      }
      dataIndex = i.dataIndex || i.key;

      return {
        id: i?.id,
        key: i.key,
        title: i.title || i.name,
        typeKey: i?.typeKey || i.type,
        dropdown: column?.formats?.find((f) => f.key === i.format)?.dropdown || column?.dropdown,
        editable: true,
        hide: false,
        format: i?.format,
        dataIndex: dataIndex || i.type,
        options: options ? uniqBy(options, 'label').filter((i) => i.label).map((i) => ({label: i.label, value: i.id, id: i.id}) ) : null,
        isIndividual: i?.isIndividual,
      };
    }
  });
  return [...(columns || []), ...locationAndGroup];
};
