import { DataService } from '../../../config/dataService/dataService';
import { useState, useEffect } from 'react';
import { useReportsState } from '../context/reports';
import { apiPostBlob } from '../../../config/dataService/useApi';
import dayjs from 'dayjs';

const useOverview = () => {
  const {
    filter: {
      projects: selectedProject,
      groups: selectedGroupItems,
      locations: selectedLocationItems,
      jobs: selectedJobRoles,
      stakeholders: selectedStakeHolderItems,
      changeTypes: selectedChangeTypes
    },
  } = useReportsState();
  const date_time_format = localStorage.getItem('date_time_format');
  const [noOfProjects, setnoOfProjecta] = useState({ loading: true, data: 0 });
  const [changeProjects, setChangeProjects] = useState({ loading: true, data: 0 });
  const [changeImpactProjects, setChangeImpactProjects] = useState({ loading: true, data: 0 });
  const [projectRediness, setProjectRediness] = useState({ loading: true, data: [] });
  const [adoptionRisk, setAdoptionRisk] = useState({ loading: true, data: 0 });
  const [stakeholderSupportByProject, setStakeholderSupportByAudience] = useState({ loading: true, data: [] });
  const [stats, setStats] = useState({ loading: true, data: {} });
  const [adoptionBooleanMetrics, setAdoptionBooleanMetrics] = useState({ loading: true, data: [] });
  const [adoptionPercentageMetrics, setAdoptionPercentageMetrics] = useState({ loading: true, data: [] });
  const [noOfIndividualsPerProject, setNoOfIndividualsPerProject] = useState({ loading: true, data: {} });
  const [projectTimelineData, setProjectTimelineData] = useState({ loading: true, data: [] });

  const getAccounts = async () => {
    const response = await DataService.get('/users/get-account-information');
    let data = { loading: false, data: response.data.totalProjects };
    setnoOfProjecta(data);
  };
  const getChangeInProjects = async () => {
    const {
      data: { data, tabledata },
    } = await DataService.post('/reports/get-change-in-projects', {
      filters: {
        projects: selectedProject,
        groups: selectedGroupItems,
        audiences: [],
        locations: selectedLocationItems,
        jobs: selectedJobRoles,
        stakeholders: selectedStakeHolderItems,
        changeTypes: selectedChangeTypes,
      },
    });
    let data2 = {
      loading: true,
      data: {
        data,
        tabledata,
      },
    };
    setChangeProjects(data2);
  };
  const getChangeInImapctProjects = async () => {
    const { data } = await DataService.post('/reports/get-change-impact-in-projects', {
      filters: {
        projects: selectedProject,
        groups: selectedGroupItems,
        audiences: [],
        locations: selectedLocationItems,
        jobs: selectedJobRoles,
        stakeholders: selectedStakeHolderItems,
        changeTypes: selectedChangeTypes,
      },
    });
    let data2 = {
      loading: true,
      data: data
    };
    setChangeImpactProjects(data2);
  };
  const getProjectRediness = async () => {
    const {
      data: { rediness },
    } = await DataService.post('/reports/get-project-rediness-level', {
      filters: {
        projects: selectedProject,
        groups: selectedGroupItems,
        audiences: [],
        locations: selectedLocationItems,
        jobs: selectedJobRoles,
        stakeholders: selectedStakeHolderItems,
        changeTypes: selectedChangeTypes,
      },
    });
    let data2 = {
      loading: true,
      data: rediness,
    };

    setProjectRediness(data2);
  };
  const getAdoptionRisk = async () => {
    setAdoptionRisk((pre) => ({ ...pre, loading: true }));
    const {
      data: { adoptopnRisk },
    } = await DataService.post('/reports/get-adoption-risk', {
      filters: {
        projects: selectedProject,
      },
    });
    setAdoptionRisk({ loading: false, data: adoptopnRisk === 'Mid' ? 'Medium' : adoptopnRisk });
  };

  const getStakeHolderSupportByProject = async () => {
    const { data } = await DataService.post('/reports/get-stakeholder-support-by-project', {
      filters: {
        projects: selectedProject,
        groups: selectedGroupItems,
        audiences: [],
        locations: selectedLocationItems,
        jobs: selectedJobRoles,
        stakeholders: selectedStakeHolderItems,
      },
    });
    setStakeholderSupportByAudience({ loading: false, data });
  };

  const getOverviewStats = async () => {
    const { data } = await DataService.post('/reports/get-overview-stats', {
      filters: {
        projects: selectedProject,
      },
    });
    setStats({ loading: false, data: data });
  };

  const getAdoptionBooleanMetrics = async () => {
    const { data } = await DataService.post('/reports/get-adoption-boolean-metrics', {
      filters: {
        projects: selectedProject,
        groups: selectedGroupItems,
        audiences: [],
        locations: selectedLocationItems,
        jobs: selectedJobRoles,
        stakeholders: selectedStakeHolderItems,
        changeTypes: selectedChangeTypes,
      },
    });
    setAdoptionBooleanMetrics({ loading: false, data });
  };

  const getAdoptionPercentageMetrics = async () => {
    const { data } = await DataService.post('/reports/get-adoption-percentage-metrics', {
      filters: {
        projects: selectedProject,
        groups: selectedGroupItems,
        audiences: [],
        locations: selectedLocationItems,
        jobs: selectedJobRoles,
        stakeholders: selectedStakeHolderItems,
        changeTypes: selectedChangeTypes,
      },
    });
    setAdoptionPercentageMetrics({ loading: false, data: data });
  };

  const getNoOfIndividualsPerProject = async () => {
    setNoOfIndividualsPerProject({ loading: true, data: {} });

    const { data } = await DataService.post('/reports/get-individuals-per-projects', {
      filters: {
        projects: selectedProject,
        groups: selectedGroupItems,
        audiences: [],
        locations: selectedLocationItems,
        jobs: selectedJobRoles,
        stakeholders: selectedStakeHolderItems,
        changeTypes: selectedChangeTypes,
      },
    });
    setNoOfIndividualsPerProject({ loading: false, data: data });
  };

  const getListNoOfIndividualsPerProject = async (page, exportInExcel) => {
    const { data } = await DataService.post('/reports/get-individuals-list-per-projects', {
      pagination: {
        page: page,
        pageSize: 10,
      },
      exportInExcel: exportInExcel,
      filters: {
        projects: selectedProject,
        groups: selectedGroupItems,
        audiences: [],
        locations: selectedLocationItems,
        jobs: selectedJobRoles,
        stakeholders: selectedStakeHolderItems,
        changeTypes: selectedChangeTypes,
      },
    });
    return data;
  };

  const exportListAudiencesPerProject = async () => apiPostBlob(`/reports/get-individuals-list-per-projects`, {
    filters: {
      projects: selectedProject,
      groups: selectedGroupItems,
      audiences: [],
      locations: selectedLocationItems,
      jobs: selectedJobRoles,
      stakeholders: selectedStakeHolderItems,
      changeTypes: selectedChangeTypes,
    },
    exportInExcel: true
  }, 'Audiences Per Project');

  const getListOfProjectsTimelines = async () => {
    const { data } = await DataService.post('/reports/get-projects-timeline', {
      filters: {
        projects: selectedProject,
        groups: selectedGroupItems,
        audiences: [],
        locations: selectedLocationItems,
        jobs: selectedJobRoles,
        stakeholders: selectedStakeHolderItems,
        changeTypes: selectedChangeTypes,
      },
    });

    setProjectTimelineData({
      loading: false, data: {
        ...data,
        communicationsDates: data.communicationsDates.map(d => d.map(date => date ? dayjs(date).valueOf() : null)),
        projectDates: data.projectDates.map(d => d.map(date => date ? dayjs(date).valueOf() : null)),
        trainingsDates: data.trainingsDates.map(d => d.map(date => date ? dayjs(date).valueOf() : null)),
        tableData: data.tableData.map(item => ({
          ...item,
          Date: dayjs(item.Date).format(date_time_format.toUpperCase())
        }))
      }
    });
  };

  useEffect(() => {
    getAccounts();
    getProjectRediness();
    getAdoptionRisk();
    getStakeHolderSupportByProject();
    getOverviewStats();
    getAdoptionBooleanMetrics();
    getAdoptionPercentageMetrics();
    getNoOfIndividualsPerProject();
    getListOfProjectsTimelines();
    getChangeInProjects();
    getChangeInImapctProjects();
  }, [
    selectedProject,
    selectedGroupItems,
    selectedJobRoles,
    selectedLocationItems,
    selectedStakeHolderItems,
  ]);

  useEffect(() => {
    getChangeInProjects();
    getChangeInImapctProjects();
  }, [selectedChangeTypes])

  return {
    noOfProjects,
    changeProjects,
    changeImpactProjects,
    projectRediness,
    adoptionRisk,
    stakeholderSupportByProject,
    stats,
    adoptionBooleanMetrics,
    adoptionPercentageMetrics,
    noOfIndividualsPerProject,
    getListNoOfIndividualsPerProject,
    exportListAudiencesPerProject,
    projectTimelineData,
  };
};

export default useOverview;
