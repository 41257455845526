import styled from 'styled-components';
import { Row, Col, Form, Button, Space, InputNumber } from 'antd';
import CheckboxGroup from './components/checkbox-group';
import RadioGroup from './components/StarRadioGroup';
import StarRadioGrop from './components/StarRadioGroup/StarRadioGroup';
import { DataService } from '../../config/dataService/dataService';
import { useParams } from 'react-router-dom';
import { forwardRef, useState } from 'react';
import RadioButtonsGroup from './components/RadioButtonsGroup';
import CustomDatePicker from './components/DatePicker';
import SurveyFooter from './components/SurveyFooter';
import UploadLogo from './components/UploadLogo';
import { decode } from './utils/utils';
import useNotificationController from '../../utility/useNotificationController';

const StyledTemplate = styled.div`
  border: solid 1px;
  border-radius: 10px;
  overflow: hidden;
  width: 992px;
  background-color: white;
`;

const TemplateHeader = styled.div`
  background-color: #5dd3ff;
  padding: 20px 0 20px 0;
`;

const Input = styled.input`
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  background: transparent;
  width: 100%;
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  height: 150px;
  border: 2px solid #5dd3ff;
  border-radius: 4px;
  padding: 8px;
  font-size: 16px;
  resize: none;
`;

const SurveyTemplateForm = forwardRef(({ templateData, settingsData, isPreviewMode, onSubmit }, ref) => {
  const {
    contextHolder,
    openError,
  } = useNotificationController();
  
  const [isSubmitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();
  const params = useParams();

  const onFinish = async (values) => {
    setSubmitting(true);
    const { projectName, emailAddress, departmentOrGroup, ...rest } = values;

    try {
      const response = await DataService.post('/surveys/survey-response', {
        projectName,
        emailAddress,
        departmentOrGroup,
        userSurveyId: decode(params.surveyId),
        answers: rest,
      });

      if (response.status === 200) {
        onSubmit();
      } else {
        openError('topLeft', 'Survey Error', "Something went wrong");
      }

      setSubmitting(false);
    } catch (error) {
      openError('topLeft', 'Survey Error', "Something went wrong");
    }
  };

  return (
    <StyledTemplate className="template" ref={ref}>
      {contextHolder}
      <TemplateHeader>
        <Row>
          <Col offset={3} span={4}>
            <UploadLogo url={templateData.logo} isEditing={false} isAmazon={templateData.isAmazon} />
          </Col>
          <Col span={17} style={{ display: 'flex', alignItems: 'center' }}>
            <span dangerouslySetInnerHTML={{ __html: templateData.title }} />
          </Col>
        </Row>
      </TemplateHeader>

      <div className="body">
        <Form form={form} onFinish={onFinish} onFinishFailed={(e) => console.warn('error', e)}>
          <Row>
            <Col span={20} offset={2}>
              <div style={{ marginTop: 15 }}>
                <p className="survey-text" style={{ display: 'flex', gap: 10, whiteSpace: 'nowrap' }}>
                  {templateData.descriptionLine1?.split('{{input}}').map((stringPart, index) => {
                    if (index === 1) {
                      return (
                        <>
                          <Form.Item name="projectName" initialValue={templateData.projectName}>
                            <Input disabled style={{ width: 200, textAlign: 'center' }} />
                          </Form.Item>

                          <span>{stringPart}</span>
                        </>
                      );
                    }
                    return <span>{stringPart}</span>;
                  })}
                </p>
                <p className="survey-text" dangerouslySetInnerHTML={{ __html: templateData.descriptionLine2 }} />

                <div style={{ marginTop: 30, marginBottom: 30 }}>
                  {templateData.trainingId && (
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
                      <label style={{ flex: '0 0 auto', marginRight: 10 }}>Training Course:</label>
                      <label>{templateData?.training?.course_name}</label>
                    </div>
                  )}

                  {settingsData && settingsData.setShowSurveyEmail ? (
                    <div style={{ marginBottom: 20, display: 'flex' }}>
                      <span style={{ flex: '0 0 auto', marginRight: 10 }}>Email address:</span>
                      <Form.Item
                        name="emailAddress"
                        rules={[
                          {
                            required: templateData.emailAddress.required,
                            message: 'Please input your email address!',
                          },
                        ]}
                        style={{ width: '100%' }}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  ) : !settingsData && (
                    <div style={{ marginBottom: 20, display: 'flex' }}>
                      <span style={{ flex: '0 0 auto', marginRight: 10 }}>Email address:</span>
                      <Form.Item
                        name="emailAddress"
                        rules={[
                          {
                            required: templateData.emailAddress.required,
                            message: 'Please input your email address!',
                          },
                        ]}
                        style={{ width: '100%' }}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  )}
                  {settingsData && settingsData.setShowSurveyDepartment ? (
                    <div style={{ display: 'flex' }}>
                      <span style={{ flex: '0 0 auto', marginRight: 10 }}>Your department/group:</span>
                      <Form.Item
                        name="departmentOrGroup"
                        rules={[
                          {
                            required: templateData.departmentOrGroup.required,
                            message: 'Please input your department / group!',
                          },
                        ]}
                        style={{ width: '100%' }}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  ) : !settingsData && (
                    <div style={{ display: 'flex' }}>
                      <span style={{ flex: '0 0 auto', marginRight: 10 }}>Your department/group:</span>
                      <Form.Item
                        name="departmentOrGroup"
                        rules={[
                          {
                            required: templateData.departmentOrGroup.required,
                            message: 'Please input your department / group!',
                          },
                        ]}
                        style={{ width: '100%' }}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  )}
                </div>
                <p className="survey-text" dangerouslySetInnerHTML={{ __html: templateData.descriptionLine3 }} />

                {templateData.questions.map((question) => (
                  <div>
                    <p style={{ fontWeight: 'bold' }}>{question.text}</p>
                    <small style={{ display: 'block' }}>{question.description}</small>

                    {question.answerType === 'checkbox' && (
                      <div style={{ display: 'flex', gap: 15 }}>
                        <Form.Item
                          name={`${question.key || question.text}`}
                          rules={[{ required: true, message: 'Please select atleast 1 option' }]}
                        >
                          <CheckboxGroup options={question.options} />
                        </Form.Item>
                      </div>
                    )}

                    {question.answerType === 'radio' && (
                      <div style={{ display: 'flex', gap: 15 }}>
                        <Form.Item
                          name={`${question.key || question.text}`}
                          rules={[{ required: true, message: 'Please select 1 option' }]}
                        >
                          <RadioButtonsGroup options={question.options} />
                        </Form.Item>
                      </div>
                    )}

                    {question.answerType === 'date' && (
                      <Space size={'large'}>
                        {question.options.map((option) => (
                          <Form.Item
                            key={option.key}
                            name={option.key}
                            rules={[{ required: true, message: 'Please select a valid date' }]}
                          >
                            <CustomDatePicker label={option.text} />
                          </Form.Item>
                        ))}
                      </Space>
                    )}

                    {question.answerType === 'number' &&
                      question.options.map((option) => (
                        <Form.Item
                          key={option.key}
                          name={option.key}
                          rules={[{ required: true, message: 'Please enter a valid number' }]}
                        >
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>{option.text}</span>
                            <InputNumber 
                              style={{ borderColor: 'rgb(93, 211, 255)', borderWidth: '2px' }} 
                              min={0} 
                              onChange={(value) => form.setFieldsValue({ [option.key]: value })}
                            />
                          </div>
                        </Form.Item>
                      ))}


                    {(question.answerType === 'number_rating' || question.answerType === 'star_rating') && (
                      <Form.Item
                        name={`${question.key || question.text}`}
                        rules={[{ required: true, message: 'Please select rating' }]}
                      >
                        {question.answerType === 'number_rating' && (
                          <RadioGroup
                            options={question.options.map((option) => ({
                              label: option.toString(),
                              value: option.toString(),
                            }))}
                            optionType="button"
                          />
                        )}

                        {question.answerType === 'star_rating' && (
                          <StarRadioGrop
                            numberOfStar={question.ratingProps.starCount}
                            title={question.ratingProps.heading}
                          />
                        )}
                      </Form.Item>
                    )}
                    {question.answerType === 'textarea' && (
                      <Form.Item name={`${question.key || question.text}`}>
                        <StyledTextArea />
                      </Form.Item>
                    )}
                  </div>
                ))}
                <SurveyFooter />
              </div>
              {!isPreviewMode && (
                <Form.Item style={{ textAlign: 'center' }}>
                  <Button type="primary" htmlType="submit" loading={isSubmitting}>
                    Submit
                  </Button>
                </Form.Item>
              )}
            </Col>
          </Row>
        </Form>
      </div>
    </StyledTemplate>
  );
});

export default SurveyTemplateForm;
