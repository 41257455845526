import styled from 'styled-components'
const SurveyFooter = ({ disclaimer }) => {

  return (
    <Text>
      {disclaimer ? disclaimer : `This content is created by the owner of the form. The data you submit will be sent to the form owner.
      OCM
      Solution
      is not responsible for the privacy or security practices of its customers, including those of this form owner.`}
    </Text>
  )

}

export default SurveyFooter


const Text = styled.p`
margin:  10px;
padding: 10px;
text-align: center;
color: gray;
font-size: 15px;

`