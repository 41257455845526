export const targetAudienceInitialState = {
  columns: [],
  locations: [],
  groups: [],
  jobs: [],
  stakeholders: [],
  audience: [],
  isEditing: false,
  columnRef: null,
  activeProject: {},
  reload: 'all',
  reloadAudience: false,
  reloadStats: false,
  nextTab: null,
  setView: false,
  editIndividual: {type: ''},
  models: {
    targetAudienceModal: {
      show: false,
    },
    hasUnsavedData: {
      show: false,
      data: false,
    },
    editModal: null,
    viewModal: null,
    stakeholdersEditModal: false,
  },
  levels: {
    groupLevels: [],
    locationLevels: [],
  },
  data: {
    locationLevels: [],
    groupLevels: [],
    jobRoles: [],
    stakeholders: [],
    individals: [],
    jobroles: [],
  },
  generatingTool: false
};

export const reducerTypes = {
  TARGETAUDIENCE_MODAL: 'TARGETAUDIENCE_MODAL',
  INIT: 'INIT',
  GROUPS: 'GROUPS',
  LOCATIONS: 'LOCATIONS',
  JOB_ROLES: 'JOB_ROLES',
  STAKEHOLDER_TYPES: 'STAKEHOLDER_TYPES',
  AUDIENCE: 'AUDIENCE',
  FILTERS: 'FILTERS',
  LEVELS: 'LEVELS',
  START: 'START',
  COLUMNS: 'COLUMNS',
  DATA: 'DATA',
  CURRENTFILTER: 'CURRENTFILTER',
  IS_EDITING: 'IS_EDITING',
  SET_REF: 'SET_REF',
  HAS_UNSAVED_DATA: 'HAS_UNSAVED_DATA',
  RELOAD: 'RELOAD',
  RELOAD_AUDIENCE: 'RELOAD_AUDIENCE',
  RELOAD_STATS: 'RELOAD_STATS',
  SET_EDIT_MODAL: 'SET_EDIT_MODAL',
  SET_VIEW_MODAL: 'SET_VIEW_MODAL',
  SET_NEXT_TAB: 'NEXT_TAB',
  SET_STATS: 'SET_STATS',
  SET_VIEW: 'SET_VIEW',
  STAKEHOLDERS_EDIT_MODAL: 'STAKEHOLDERS_EDIT_MODAL',
  SET_GENERATING_TOOL: "SET_GENERATING_TOOL",
  ACTIVE_PROJECT: "ACTIVE_PROJECT",
  EDIT_INDIVIDUAL: "EDIT_INDIVIDUAL"
};

export const defaultColumn = [
  {
    key: 'firstName',
    title: 'First Name',
    dataIndex: 'firstName',
    editable: false,
    hide: false,
    typeKey: 'firstName',
    dropdown: false,
  },
  {
    key: 'lastName',
    title: 'Last Name',
    dataIndex: 'lastName',
    editable: false,
    hide: false,
    typeKey: 'lastName',
    dropdown: false,
  },
  {
    key: 'email',
    title: 'Email',
    dataIndex: 'email',
    editable: false,
    hide: false,
    typeKey: 'email',
    dropdown: false,
  },
  {
    key: 'jobRole',
    title: 'Job Role',
    dataIndex: 'jobRole',
    editable: false,
    hide: false,
    typeKey: 'jobRole',
    dropdown: true,
  },
  {
    key: 'group_level_0',
    title: 'Department',
    dataIndex: 'group_level_0',
    editable: true,
    hide: false,
    typeKey: 'group',
    dropdown: true,
  },
  {
    key: 'location_level_0',
    title: 'City',
    dataIndex: 'location_level_0',
    editable: true,
    hide: false,
    typeKey: 'location',
    dropdown: true,
  },
];

export const audienceColumnsTypes = [
  {
    key: 'firstName',
    label: 'firstName',
    value: 'firstName',
    icon: 'faUser',
    dropdown: false,
    hide: true,
  },
  {
    key: 'lastName',
    label: 'Last Name',
    value: 'lastName',
    icon: 'faUser',
    dropdown: false,
    hide: true,
  },
  {
    key: 'email',
    label: 'Email',
    value: 'email',
    icon: 'faEnvelope',
    dropdown: false,
    hide: true,
  },
  {
    key: 'other',
    label: 'Other',
    value: 'other',
    icon: 'faBars',
    dropdown: false,
    formats: [
      {
        key: 'text',
        label: 'Text',
        value: 'text',
        icon: 'faBars',
        dropdown: false,
        hide: false,
      },
      {
        key: 'date',
        label: 'Date',
        value: 'date',
        icon: 'faCalendarDays',
        dropdown: false,
        hide: false,
      },
      {
        key: 'number',
        label: 'Numeric',
        value: 'number',
        icon: 'faInputNumeric',
        dropdown: false,
        hide: false,
      },
      {
        key: 'dropdown',
        label: 'Dropdown',
        value: 'dropdown',
        icon: 'faSquareCheck',
        dropdown: true,
        hide: false,
      },
      {
        key: 'radio',
        label: 'RadioButton',
        value: 'radio',
        icon: 'faCircleDot',
        dropdown: true,
        hide: false,
      },
    ],
    hide: false,
    tooltipText: 'Use for additional information related to an individual or group (such as "Mobile number."',
  },
  {
    key: 'group',
    label: 'Group',
    value: 'group',
    icon: 'faBuildingColumns',
    dropdown: true,
    hide: false,
    tooltipText:
      'Use for listing an additional organizational group, such as a "Division. " This is used in reports, filters, and assessments.',
  },
  {
    key: 'location',
    label: 'Location',
    value: 'location',
    icon: 'faLocationDot',
    dropdown: true,
    hide: false,
    tooltipText:
      'Use for listing an additional location, such as a "Country. " This is used in reports, filters, and assessments.',
  },
  {
    key: 'employeeId',
    label: 'Employee ID',
    value: 'employeeId',
    icon: 'faUser',
    dropdown: false,
    hide: false,
    tooltipText: 'Use this as a unique identifier for an individual.',
  },
  {
    key: 'jobRole',
    label: 'Job Role',
    value: 'jobRole',
    icon: 'faUserGear',
    dropdown: true,
    hide: true,
  },
];
