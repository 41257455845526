import styled from 'styled-components';
import { Col, Row } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SurveyCard from '../SurveyCard';
import Heading from '../../../../components/heading/heading'
import { useNavigate } from 'react-router-dom';
import useVerifyUser from '../../hooks/useVerifyUser';
import SubscribePackage from '../SubscribePackage';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

const AllSurveyTemplates = ({ surveyList, backtoAllSurveys, onOpenSubscriptionModal }) => {
  const { isFreeAccount, freeSrveys } = useVerifyUser()
  const navigate = useNavigate();
  return (
    <Row>
      <Col xxl={24} xl={24} md={24}>
        <Button onClick={backtoAllSurveys}>
          <FontAwesomeIcon icon={regular("chevron-left")} color="#fff" /> All Surveys
        </Button>
      </Col>
      <Row style={{ marginTop: '16px' }}>
        <Col xxl={24} xl={24} md={24}>
          <Heading as={'h3'}>All Survey Templates</Heading>
        </Col>
      </Row>
      <Col xxl={24} xl={24} md={24}>
        <Row style={{ gap: '30px', padding: '20px' }}>
          {surveyList.map((survey) => (
            <Col flex={'18%'}>
              <CarouselItem onClick={() => {
                if (isFreeAccount && !freeSrveys.includes(survey.key)) {
                  onOpenSubscriptionModal()
                } else {
                  navigate(`create/${survey.id}`)
                }
              }}>
                <SurveyCard key={survey.key} title={survey.key.replace(/_/g, ' ')} />
                {isFreeAccount && !freeSrveys.includes(survey.key) && <SubscribePackage onClickSubscribe={onOpenSubscriptionModal} />}
              </CarouselItem>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

const Button = styled.button`
  padding: 12px 30px;
  text-align: center;
  border-radius: 20px;
  border: none;
  color: #fff;
  background-color: #39aaff;
  cursor: pointer;
`;

const CarouselItem = styled.div`
  justify-content: center;
  align-items: center;
  height: 210px;
  border-radius: 8px;
`;

export default AllSurveyTemplates;
