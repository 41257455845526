const actions = {
  USER_PROJECT_BEGIN: 'USER_PROJECT_BEGIN',
  USER_PROJECT_SUCCESS: 'USER_PROJECT_SUCCESS',
  USER_PROJECT_ERR: 'USER_PROJECT_ERR',

  GET_PROJECT_ASSESSMENT_BEGIN: 'GET_PROJECT_ASSESSMENT_BEGIN',
  GET_PROJECT_ASSESSMENT_SUCCESS: 'GET_PROJECT_ASSESSMENT_SUCCESS',
  GET_PROJECT_ASSESSMENT_ERR: 'GET_PROJECT_ASSESSMENT_ERR',

  GET_ACCOUNT_USER_PROJECT_BEGIN: 'GET_ACCOUNT_USER_PROJECT_BEGIN',
  GET_ACCOUNT_USER_PROJECT_SUCCESS: 'GET_ACCOUNT_USER_PROJECT_SUCCESS',
  GET_ACCOUNT_USER_PROJECT_ERR: 'GET_ACCOUNT_USER_PROJECT_ERR',

  SAVE_PROJECT_ASSESSMENT_BEGIN: 'SAVE_PROJECT_ASSESSMENT_BEGIN',
  SAVE_PROJECT_ASSESSMENT_SUCCESS: 'SAVE_PROJECT_ASSESSMENT_SUCCESS',
  SAVE_PROJECT_ASSESSMENT_ERR: 'SAVE_PROJECT_ASSESSMENT_ERR',

  SET_CLOSE_PROJECT_BEGIN: 'SET_CLOSE_PROJECT_BEGIN',
  SET_CLOSE_PROJECT_SUCCESS: 'SET_CLOSE_PROJECT_SUCCESS',
  SET_CLOSE_PROJECT_ERR: 'SET_CLOSE_PROJECT_ERR',

  SAVE_REOPEN_PROJECT_BEGIN: 'SAVE_REOPEN_PROJECT_BEGIN',
  SAVE_REOPEN_PROJECT_SUCCESS: 'SAVE_REOPEN_PROJECT_SUCCESS',
  SAVE_REOPEN_PROJECT_ERR: 'SAVE_REOPEN_PROJECT_ERR',

  userProjectBegin: () => {
    return {
      type: actions.USER_PROJECT_BEGIN,
    };
  },

  userProjectSuccess: (data) => {
    return {
      type: actions.USER_PROJECT_SUCCESS,
      data,
    };
  },

  userProjectErr: (err) => {
    return {
      type: actions.USER_PROJECT_ERR,
      err,
    };
  },

  getAccountUserProjectsBegin: () => {
    return {
      type: actions.GET_ACCOUNT_USER_PROJECT_BEGIN,
    };
  },

  getAccountUserProjectsSuccess: (data) => {
    return {
      type: actions.GET_ACCOUNT_USER_PROJECT_SUCCESS,
      data,
    };
  },

  getAccountUserProjectsErr: (err) => {
    return {
      type: actions.GET_ACCOUNT_USER_PROJECT_ERR,
      err,
    };
  },

  getProjectAssessmentBegin: () => {
    return {
      type: actions.GET_PROJECT_ASSESSMENT_BEGIN,
    };
  },

  getProjectAssessmentSuccess: (data) => {
    return {
      type: actions.GET_PROJECT_ASSESSMENT_SUCCESS,
      data,
    };
  },

  getProjectAssessmentErr: (err) => {
    return {
      type: actions.GET_PROJECT_ASSESSMENT_ERR,
      err,
    };
  },

  saveProjectAssessmentBegin: () => {
    return {
      type: actions.SAVE_PROJECT_ASSESSMENT_BEGIN,
    };
  },

  saveProjectAssessmentSuccess: (data) => {
    return {
      type: actions.SAVE_PROJECT_ASSESSMENT_SUCCESS,
      data,
    };
  },

  saveProjectAssessmentErr: (err) => {
    return {
      type: actions.SAVE_PROJECT_ASSESSMENT_ERR,
      err,
    };
  },

  setReopenProjectBegin: () => {
    return {
      type: actions.SAVE_REOPEN_PROJECT_BEGIN,
    };
  },

  setReopenProjectSuccess: (data) => {
    return {
      type: actions.SAVE_REOPEN_PROJECT_SUCCESS,
      data,
    };
  },

  setReopenProjectErr: (err) => {
    return {
      type: actions.SAVE_REOPEN_PROJECT_ERR,
      err,
    };
  },

  setCloseProjectBegin: () => {
    return {
      type: actions.SET_CLOSE_PROJECT_BEGIN,
    };
  },

  setCloseProjectSuccess: (data) => {
    return {
      type: actions.SET_CLOSE_PROJECT_SUCCESS,
      data,
    };
  },

  setCloseProjectErr: (err) => {
    return {
      type: actions.SET_CLOSE_PROJECT_ERR,
      err,
    };
  },
};

export default actions;
