import styled from 'styled-components'
import { Modal, Typography, List, Skeleton, Card } from 'antd';
const {Title} = Typography;

import { faFilm, faPlay, faSquareList } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {library} from '@fortawesome/fontawesome-svg-core';
import  * as ProIcons from '@fortawesome/pro-duotone-svg-icons'
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';

import ReactPlayer from 'react-player';
import { useEffect, useState } from 'react';
import { OCMModal, OCMModalHeader, VideoTutorialsStyle } from '../../../tools/Style';
import { DataService } from '../../../../config/dataService/dataService';
const { theme } = require('../../../../config/theme/themeVariables');


const NoSurvey = () => {
  const smallScreen = window.innerWidth <= 1400;
  const userTheme = JSON.parse(localStorage.getItem('userTheme'))
  theme['user-theme'] = userTheme;
  const [initLoading, setInitLoading] = useState(false);
  const [openVideo, setOpenVideo] = useState(false);
  const [videoVisible, setVideoVisible] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [folder, setFolder] = useState("amazon");
  const [videos, setVideos] = useState([]);
  const [videosTutorials, setVideosTutorials] = useState([]);

  const is_amazon = localStorage.getItem('is_amazon') === 'true';

  useEffect(() => {
    let video_tutorials = [];
    if (!is_amazon)
    {
      setFolder("ocms");
    }
    const fetchVideos = async () => {
      const response = await DataService.get('surveys/default-survey-templates');
      video_tutorials = [response.data.video_tutorials];
      setVideosTutorials(video_tutorials);
      setLoaded(true);
      if (video_tutorials)
      {
        if (!Array.isArray(video_tutorials))
        {
          let icon = video_tutorials.faicon;
          library.add(ProIcons[icon]);
        }
        else
        {
          for(var i in video_tutorials)
          {
            let icon = video_tutorials[i].faicon;
            library.add(ProIcons[icon]);
          }
        }
      }

      let allVideos = [];
      if (!Array.isArray(video_tutorials))
      {
        if (video_tutorials.videos !== undefined && video_tutorials.videos.length > 0)
        {
          video_tutorials.videos.map(item => {
            if (item.video_for === "both")
            {
              allVideos.push(item);
            }
            else
            {
              if (!is_amazon && item.video_for === "ocms")
              {
                allVideos.push(item);
              }
              else if (is_amazon && item.video_for === "amazon")
              {
                allVideos.push(item);
              }
            }
          })
        }
      }
      else
      {
        for(var i in video_tutorials)
        {
          let video_tutorials_videos = video_tutorials[i];
          if (video_tutorials_videos.videos !== undefined && video_tutorials_videos.videos.length > 0)
          {
            video_tutorials_videos.videos.map(item => {
              if (item.video_for === "both")
              {
                allVideos.push(item);
              }
              else
              {
                if (!is_amazon && item.video_for === "ocms")
                {
                  allVideos.push(item);
                }
                else if (is_amazon && item.video_for === "amazon")
                {
                  allVideos.push(item);
                }
              }
            })
          }
        }
      }
      setVideos(allVideos);
    };

    fetchVideos();
  }, []);

  const [videoInformation, setvideoInformation] = useState({
    name: '',
    url: '',
    isVisible: false,
  });
  const [player, setPlayer] = useState(false);

  const showVideoModal = (videoInfo) => {
    setvideoInformation({
      name: videoInfo.name,
      url: videoInfo.video_link,
      isVisible: true,
    });
    setVideoVisible(true);
  };

  const hideVideoModal = async () => {
    setPlayer(false);
    setvideoInformation({
      name: '',
      url: '',
      isVisible: false,
    });
  };

  const loadVideo = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (videos !== undefined && videos.length > 0)
    {
      setOpenVideo(true);
    }
    return false;
  }

  return (
    <Container>

      <FontAwesomeIcon icon={faSquareList} size='10x' color='#493782' />
      <Description>
        You have no surveys yet.
      </Description>
      <Description>
        Watch our tutorial video below to learn how to get started:
      </Description>

      {loaded && (
        <>
          <VideoContainer 
            style={{cursor: "pointer"}}
            onClick={(e) => {
              if (videos.length > 0)
              {
                loadVideo(e);
              }
            }}
          >
            <Warpper>
              <FontAwesomeIcon icon={faPlay} size='5x' color='#003359' />

              <p>OCMS Surveys Overview</p>
            </Warpper>
            <IconBox>
              <FontAwesomeIcon icon={faFilm} size='3x' color='#fff' />

            </IconBox>
          </VideoContainer>

          <OCMModal>
          {openVideo && (
            <Modal
              style={{
                margin: '20px 0px',
              }}
              className="add-change"
              title={
                <OCMModalHeader>
                  <div className="user-info-header-panel">
                    <h1 className="header-title">
                    {videosTutorials[0].label}
                    </h1>
                    <div className="ribon none"></div>
                  </div>
                </OCMModalHeader>
              }
              centered
              closeIcon={<FontAwesomeIcon size="2xl" icon={duotone('circle-xmark')} />}
              open={openVideo}
              onCancel={() => setOpenVideo(false)}
              onOk={() => setOpenVideo(false)}
              width={800}
              wrapClassName={'ocm-modal'}
              footer={[]}
            >
                <VideoTutorialsStyle theme={theme}>
                  <List
                    style={{ height: '250px' }}
                    className="loadmore-list"
                    loading={initLoading}
                    itemLayout="horizontal"
                    // loadMore={loadMore}
                    grid={{ gutter: smallScreen ? 2 : 10, column: 4 }}
                    dataSource={videos}
                    renderItem={(video) => (
                      <List.Item>
                        <Skeleton avatar title={false} loading={video.loading} active>
                          <List.Item>
                            <Card
                              title={
                                <>
                                  <Title level={5} onClick={() => showVideoModal(video)} style={{minHeight: "73px"}}>
                                    {video.name}
                                  </Title>
                                  <FontAwesomeIcon
                                    icon={duotone('film')}
                                    size="xl"
                                    style={{
                                      '--fa-primary-color': '#c0c0c0',
                                      '--fa-secondary-color': '#c0c0c0',
                                    }}
                                    className="title-header"
                                  />
                                </>
                              }
                              styles={{
                                header: {
                                  fontSize: '14px', 
                                  whiteSpace: 'nowrap'
                                }
                              }}
                              className="video-card"
                            >
                              {video.isfaicon ? (
                                <FontAwesomeIcon
                                  icon={duotone('person-dots-from-line')}
                                  style={{
                                    '--fa-primary-color': '#ff80c0',
                                    '--fa-secondary-color': '#ff80c0',
                                    cursor: 'pointer',
                                  }}
                                  className="big-icon"
                                  onClick={() => showVideoModal(video)}
                                />
                              ) : (
                                <img
                                  src={require(`../../../../static/images/${video.src}`)}
                                  alt=""
                                  style={{ width: '100%', cursor: 'pointer' }}
                                  onClick={() => showVideoModal(video)}
                                />
                              )}
                            </Card>
                          </List.Item>
                        </Skeleton>
                      </List.Item>
                    )}
                  />
                  <Modal
                    centered
                    title={
                      <>
                      <Title level={3}>{`${videoInformation.name}`}</Title>
                      <hr />
                      </>
                    }
                    width="680px"
                    open={videoInformation.isVisible}
                    footer={null}
                    onCancel={hideVideoModal}
                    styles={{
                      body: {padding: 0}
                    }}
                    destroyOnClose={true}
                  >
                    <ReactPlayer
                      controls={true}
                      playing={player}
                      url={`${process.env.REACT_APP_API_ENDPOINT}/videos/${folder}/${videoInformation.url}`}
                      onPlay={() => setPlayer(true)}
                      onPause={() => setPlayer(false)}
                    />
                  </Modal>
                </VideoTutorialsStyle>
            </Modal>
          )}
          </OCMModal>
        </>
      )}
        
    </Container>
  )

}


const Container = styled.div`
  padding: 20px;
  border: 1px solid #A9ADBD;
  border-radius: 15px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 100px;
  `

const Description = styled.p`

font-size: 24px;
color: #B6B6B6;
margin:  20px 0px;


`

const VideoContainer = styled.div`
width: 410px;
height: 210px;
border-radius: 20px;
background-color: #39AAFF;
display: flex;
justify-content: center;
align-items: center;
margin-top: 30px;
position: relative;

`

const Warpper = styled.div`
  background-color: #fff;
  border-radius: 15px;
  height: 170px;
  width: 370px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;

  p{
    font-size: 20px;

  }
`

const IconBox = styled.div`
position: absolute;
left: -10px;
top: -10px;
background-color: #003359;
height: 80px;
width: 80px;
border-radius: 100px;
display: flex;
align-items: center;
justify-content: center;
`

const VideoText = styled.p`
margin-top: 10px;
font-size: 18px;
color: #909090  ;
`



export default NoSurvey
