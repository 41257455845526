import React from "react";
import { CMSContentWrapper } from "./LibrarySection.styles";
import SearchAndCMSContainer from "./SearchAndCMSContainer";

const CMSContent = ({category}) => {
  return (
    <CMSContentWrapper>
      <SearchAndCMSContainer category={category} />
    </CMSContentWrapper>
  );
};

export default CMSContent;