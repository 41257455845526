import React, { useCallback, useReducer, useState, useEffect } from 'react';
import styled from 'styled-components'
import useNotificationController from '../../../../utility/useNotificationController';
import { useDispatch } from 'react-redux';
import { verifyUser } from '../../../../redux/authentication/actionCreator';

const SubscribePackage = ({onClickSubscribe}) => {
  const {
    contextHolder,
    openError,
    openSuccess,
  } = useNotificationController();

  const [isFreeAccount, setIsFreeAccount] = useState(false);
  const [isCancelledAccount, setIsCancelledAccount] = useState(false);
  const [cancellationDate, setCancellationDate] = useState("");
  const [currentSlug, setCurrentSlug] = useState(localStorage.getItem('organizationalSlug'));

  const initialState = {
    error: false,
    messages: ""
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'VERIFY_USER_ERR':
        return { ...state, error: true, messages: action.err };
      case 'CONVERT_TO_TRIAL_ERR':
        return { ...state, error: true, messages: action.err };
      case 'RESET':
        return state;
      default:
        return state;
    }
  };

  const [apiError, apiResponseCallback] = useReducer(reducer, initialState);

  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    apiResponseCallback({ type: 'RESET' });
    dispatch(
        verifyUser({}, async (response) => {
          if (response.status === 200) {
            let organizationalSlug = response.data.organizationalSlug;
            let cancelledAccount = response.data.isCancelled;
            let cancellationDate = response.data.cancellationDate;
            let freeAccount = response.data.isFreeAccount;
            setCurrentSlug(organizationalSlug);
            setIsCancelledAccount(cancelledAccount);
            setCancellationDate(cancellationDate);
            setIsFreeAccount(freeAccount);
          } 
          else 
          {
            openError('topLeft', 'Error', response.err);
            apiResponseCallback(response);
          }
        }),
      );
  }, [dispatch, 
    isFreeAccount,
    isCancelledAccount,
    cancellationDate
  ]);

  useEffect(() => {
    const retrieveData = async () => {
       await fetchData();
     }
     retrieveData().catch(console.error);
   }, []);

  return(
    <Container>
      {isFreeAccount ? (
        isCancelledAccount ? (
          <>
            <NotSubscribe>Subscription Status</NotSubscribe>
            <Subcsribe onClick={onClickSubscribe} >Cancelled</Subcsribe>
          </>
        ) : (
          <>
            <NotSubscribe>Not Subscribed</NotSubscribe>
            <Subcsribe onClick={onClickSubscribe} >Subscribe</Subcsribe>
          </>
        )
      ) : ''}
    </Container>
  )
}


export default SubscribePackage

const Container = styled.div`
display: flex;
flex-direction: row;
text-align: center;
justify-content: center;
gap: 10px;
`

const NotSubscribe = styled.h1`
font-size: 12px;
color: red;
`

const Subcsribe = styled.h1`
font-size: 12px;
color: #5FAEEF;
text-decoration: underline;
cursor: pointer;
`