import React, { useRef, useEffect, useLayoutEffect } from 'react';
import { Chart, BarController, BarElement, LinearScale, TimeScale, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-moment';
import 'chart.js/auto';
import moment from 'moment';
Chart.register(BarController, BarElement, LinearScale, TimeScale, Tooltip, Legend);

const projectRiskColor = { 'high': '#FF5D5F', 'mid': '#FFC125', 'low': '#00B85C', 'pending': '#D9D9D9' };

const TimelineChart = ({ data, variant, filterType }) => {
  const canvas5 = useRef(null);
  const canvasYAxis = useRef(null);
  const canvasContainerRef = useRef(null);
  const scrollContainerRef = useRef(null);

  const projectDatesRanges = data?.data?.length === 0 ? [] :
    data?.projectDates?.flat().filter(value => value !== null) || [];

  const trainingsDatesRanges = data?.data?.length === 0 ? [] :
    data?.trainingsDates?.flat().filter(value => value !== null) || [];

  const communicationsDatesRanges = data?.data?.length === 0 ? [] :
    data?.communicationsDates?.flat().filter(value => value !== null) || [];

  const datesRanges = [...projectDatesRanges, ...trainingsDatesRanges, ...communicationsDatesRanges];

  let min = Math.min(...datesRanges) || 0;

  if (variant === 'week') {
    const date = moment(min);
    if (date.day() === 0) {
      date.subtract(1, 'week').startOf('week');
    } else {
      date.startOf('week');
    }
    min = date.valueOf();
  } else if (variant === 'month') {
    const date = moment(min);
    const firstDayOfMonth = date.startOf('month');
    min = firstDayOfMonth.valueOf();
  } else if (variant === 'year') {
    const date = moment(min);
    const firstDayOfYear = date.startOf('year');
    min = firstDayOfYear.valueOf();
  }

  let max = Math.max(...datesRanges) || 0;
  const days = variant === 'week' ? 7 : variant === 'month' ? 30 : 365;

  if (datesRanges.length) {
    const minRange = moment(min);
    const temp = minRange.add(days, 'days').valueOf();
    max = max > temp ? max : temp;
  }

  // Ensure max is set to end of the current year
  if (variant === 'year') {
    max = moment(max).endOf('year').valueOf();
  }

  const customTicks = [];
  let currentDate = moment(min);

  // Add extra period to the max date for extra week or month
  let extraPeriod = variant === 'week' ? 1 : variant === 'month' ? 1 : 0;
  max = moment(max).add(extraPeriod, variant).valueOf();

  while (currentDate.isSameOrBefore(max)) {
    customTicks.push({
      value: currentDate.valueOf(),
      label: currentDate.format(variant === 'week' ? 'DD-MM-YYYY' : variant === 'month' ? 'MMM - YYYY' : 'MMM YYYY'),
    });
    currentDate.add(1, variant === 'year' ? 'month' : variant);
    if (variant !== 'year') {
      currentDate.startOf(variant);
    }
  }

  useLayoutEffect(() => {
    if (scrollContainerRef.current) {
      const parentWidth = scrollContainerRef.current.parentElement.clientWidth;
      const width = parentWidth === 0 ? '100%' : `${parentWidth}px`;
      scrollContainerRef.current.style.width = width;
    }

    let totalWidth = '100%';
    if (canvasContainerRef.current) {
      if (customTicks.length > 7) {
        totalWidth = variant === 'week' ? `${customTicks.length * 100}px` : `${customTicks.length * 80}px`;
      }
      canvasContainerRef.current.style.width = totalWidth;
    }

    // Calculate minimum height based on number of labels
    if (canvasYAxis.current && data.labels.length > 3) {
      const yAxisHeight = data.labels.length * 80;
      canvasYAxis.current.style.height = `${yAxisHeight}px`;
      canvasContainerRef.current.style.height = `${yAxisHeight}px`;
    }
  }, [customTicks.length, scrollContainerRef, canvasContainerRef]);

  const screenWidth = window.innerWidth;

  useEffect(() => {
    const ctx = canvas5.current;

    const datasets = [
      {
        type: 'bar',
        backgroundColor: filterType?.projectRisk ? data.projectRisk?.map((risk) => projectRiskColor[risk?.toLowerCase()]) : '#00B0F0',
        maxBarThickness: 18,
        minBarThickness: 18,
        borderRadius: 6,
        data: data.projectDates,
        borderSkipped: false,
        datalabels: {
          anchor: 'center',
          align: 'center',
          color: 'white',
          font: {
            weight: 400,
            size: 10,
          },
          formatter: function (value, context) {
            return filterType?.projectRisk ? data.projectRisk[context.dataIndex] : '';
          },
        },
      }
    ];

    if (filterType?.communications) {
      datasets.push(
        {
          type: 'bar',
          backgroundColor: '#00B85C',
          maxBarThickness: 18,
          minBarThickness: 18,
          borderRadius: 6,
          data: data.communicationsDates,
          borderSkipped: false,
          datalabels: {
            anchor: 'center',
            align: 'center',
            color: 'white',
            font: {
              weight: 400,
              size: 10,
            },
            formatter: function (value, context) {
              return `C ${data.communications[context.dataIndex]}`;
            },
          },
        }
      );
    }

    if (filterType?.training) {
      datasets.push(
        {
          type: 'bar',
          backgroundColor: '#B17DE5',
          maxBarThickness: 18,
          minBarThickness: 18,
          borderRadius: 6,
          data: data.trainingsDates,
          borderSkipped: false,
          datalabels: {
            anchor: 'center',
            align: 'center',
            color: 'white',
            font: {
              weight: 400,
              size: 10,
            },
            formatter: function (value, context) {
              return `T ${data.trainings[context.dataIndex]}`;
            },
          },
        }
      );
    }

    const chartData = {
      labels: data.labels,
      datasets: datasets
    };

    const options = {
      maintainAspectRatio: false,
      indexAxis: 'y',
      layout: {
        padding: {
          bottom: 3.5,
        },
      },
      scales: {
        y: {
          ticks: {
            display: false,
          },
          grid: {
            drawTicks: false,
          },
        },
        x: {
          afterTickToLabelConversion: (ctx) => {
            ctx.ticks = [];
            ctx.ticks = customTicks;
          },
          min,
          max,
          position: 'top',
          border: {
            display: false,
          },
          grid: {
            color: '#C9F1FF',
            drawBorder: true,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
          callbacks: {
            title: () => false,
            label: (context) => {
              return `${moment(context.parsed._custom.barStart).format('ddd DD MMM YYYY hh:mm A')} to ${moment(
                context.parsed._custom.barEnd,
              ).format('ddd DD MMM YYYY hh:mm A')}`;
            },
          },
        },
      },
    };

    const newChart = new Chart(ctx, {
      type: 'bar',
      data: chartData,
      options,
    });

    const ctxYAxis = canvasYAxis.current;

    const yAxisData = {
      labels: data.labels,
      datasets: [{
        data: data.data,
      }],
    };

    const yAxisOptions = {
      maintainAspectRatio: false,
      indexAxis: 'y',
      scales: {
        x: {
          ticks: {
            display: false,
          },
          grid: {
            drawTicks: false,
          },
        },
        y: {
          beginAtZero: true,
          ticks: {
            callback: function (value, index, values) {
              const lines = [];
              let line = '';
              for (let i = 0; i < data.labels[index].length; i++) {
                line += (data.labels[index])[i];
                if (line.length >= 25 && (data.labels[index])[i + 1] === ' ') {
                  lines.push(line.trim());
                  line = '';
                }
              }
              if (line.length > 0) {
                lines.push(line.trim());
              }
              return lines;
            },
          },
          afterFit: (ctx) => {
            ctx.width = screenWidth > 1780 ? 208 : 200;
          },
        },
      },
      plugins: {
        datalabels: false,
        legend: {
          display: false,
        },
      },
    };

    const yAxisChart = new Chart(ctxYAxis, {
      type: 'bar',
      data: yAxisData,
      options: yAxisOptions,
    });

    return () => {
      newChart.destroy();
      yAxisChart.destroy();
    };
  }, [data, filterType, variant, min, max, customTicks]);

  return (
    <div style={{ width: '100%', height: '350px', overflowY: 'auto' }}>
      <div style={{ width: '100%', height: '100%', display: 'flex', }}>
        <div style={{
          width: '230px',
          height: data.labels.length > 3 ? `${data.labels.length * 80}px` : '100%',
          overflowY: 'hidden'
        }}>
          <canvas ref={canvasYAxis}></canvas>
        </div>
        <div ref={scrollContainerRef} style={{ height: data.labels.length > 3 ? `${data.labels.length * 80}px` : '100%', overflowX: 'auto', overflowY: 'hidden' }}>
          <div ref={canvasContainerRef} style={{ height: '100%', minWidth: '100%' }}>
            <canvas ref={canvas5}></canvas>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimelineChart;