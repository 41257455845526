import React, { useRef, useEffect, useState } from 'react';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Button, Form, Table, Space, Dropdown, Typography, Tooltip, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-duotone-svg-icons';
import styled from 'styled-components';
import { size, clone } from 'lodash';
import { LoadingOutlined } from '@ant-design/icons';
import ColumnHeader from './ColumnHeader';
import useTargetAudience from '../../hook/useTargetAudience';
import { faEyeSlash } from '@fortawesome/pro-duotone-svg-icons';
import { useTargetAudienceDispatch, useTargetAudienceState } from '../../context/targetAudience';
import EditListModel from './components/EditListModel';
import { reducerTypes, audienceColumnsTypes } from '../../context/targetAudience/constants';
import FormField from './FormField';
import { useNotificationsDispatch } from '../../context/notifaction';
import { notificationsReducerTypes } from '../../context/notifaction/constants';
import dayjs from 'dayjs';
import ReactPlayer from 'react-player';

const { Title } = Typography;

const InputManually = ({ importAudience, setLoading, loading }) => {
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [selectOption, setSelectOption] = useState({});
  const [hideColumns, setHideColumns] = useState([]);
  const notificationDispatch = useNotificationsDispatch();
  const [showHide, setShowHide] = useState(false);
  const [videoVisible, setVideoVisible] = useState(false);
  const [folder, setFolder] = useState("amazon");
  const [player, setPlayer] = useState(false);
  const [videoInformation, setVideoInformation] = useState({
    name: "Upload an Audience",
    isVisible: false,
    url: "Upload Audience.mp4",
  });

  const {
    columns,
    models: { targetAudienceModal, hasUnsavedData },
    activeProject,
  } = useTargetAudienceState();
  const dispatch = useTargetAudienceDispatch();
  const { saveAudience, saveTargetAudiencesColumns } = useTargetAudience();
  const [editListModelOpen, setEditListModelOpen] = useState({ editListTitle: '', state: false });
  const formValues = form.getFieldsValue();

  useEffect(() => {
    if (dataSource.length === 0 && targetAudienceModal.show) {
      setDataSource(Array.from({ length: 12 }, (_, index) => ({ key: index + 1 })));
    }
    setHideColumns(columns?.filter((column) => column?.hide));

    if (!targetAudienceModal.show && dataSource.length !== 0) {
      clearAllData();
    }
  }, [dataSource, columns, targetAudienceModal.show]);

  const handleAddRow = () => {
    setDataSource([...dataSource, { key: size(dataSource) }]);
  };

  const showModal = () => {
    setVideoInformation({ ...videoInformation, isVisible: true });
    setVideoVisible(true);
  };

  const hideModal = async () => {
    setPlayer(false);
    setVideoVisible(false);
    setVideoInformation({ ...videoInformation, isVisible: false });
  };

  const formatColumns = (columnList) => {
    const tempList = clone(columnList);
    let isGroupColumnsAdded = false;
    let isLocationCoumnAdded = false;
    const cols = tempList.reduce((acc, curr, index) => {
      if (curr.typeKey === 'group') {
        if (isGroupColumnsAdded) {
          return acc;
        }
        const groups = [];
        for (let i = index; i < tempList.length; i++) {
          if (tempList[i].typeKey === 'group') {
            groups.push(tempList[i]);
          }
        }
        isGroupColumnsAdded = true;
        return [...acc, ...groups];
      }
      if (curr.typeKey === 'location') {
        if (isLocationCoumnAdded) {
          return acc;
        }
        const locations = [];
        for (let i = index; i < tempList.length; i++) {
          if (tempList[i].typeKey === 'location') {
            locations.push(tempList[i]);
          }
        }
        isLocationCoumnAdded = true;
        return [...acc, ...locations];
      }
      return [...acc, curr];
    }, []);
    return cols;
  };
  function generateUniqueKey(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let key = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      key += characters.charAt(randomIndex);
    }

    return key;
  }
  const handleAddColumn = () => {
    const newColumnKey = `newColumn-${generateUniqueKey(9)}`;
    saveTargetAudiencesColumns([
      ...columns,
      {
        title: `New Column`,
        key: newColumnKey,
        typeKey: 'Choose the field Type',
        editable: true,
        hide: false,
        dropdown: false,
      },
    ]);
  };

  const clearAllData = () => {
    setDataSource([]);
    form.resetFields();
    dispatch({
      type: reducerTypes.HAS_UNSAVED_DATA,
      payload: { data: false },
    });
  };

  const definedColumns = () => {
    const rowNumber = {
      title: ' ',
      dataIndex: 'rowNo',
      key: 'rowNo',
      typeKey: 'rowNumber',
      fixed: 'left',
      render: (text, record, index) => <div style={{ textAlign: 'center', padding: '5px 10px' }}>{index + 1}</div>,
    };

    const Cols = columns
      ?.filter((col) => !col?.hide)
      .map((col, index) => ({
        title: <ColumnHeader key={col.key} col={col} formatColumns={formatColumns} />,
        dataIndex: col.typeKey,
        typeKey: col.typeKey,
        render: (_, record, index) => {
          if (col.typeKey === 'Choose the field Type') {
            return <></>;
          }
          return (
            <FormField
              form={form}
              key={col.key}
              name={[index, col.key]}
              selectOption={selectOption}
              setSelectOption={setSelectOption}
              setEditListModelOpen={setEditListModelOpen}
              col={col}
              isEditing={false}
              addOnType
            />
          );
        },
      }));

    return [rowNumber, ...Cols];
  };

  const unHide = (key, e) => {
    setShowHide(true);
    const columnOfTable = columns.map((col) => ({
      ...col,
      hide: col.key === key ? false : col?.hide,
    }));
    saveTargetAudiencesColumns(columnOfTable);
  };

  const hideMenuItems = hideColumns.map((column) => ({
    key: column.key,
    label: (
      <Space style={{ display: 'flex' }} key={column.key} onClick={() => setShowHide(true)}>
        <FontAwesomeIcon
          icon={faEyeSlash}
          style={{ color: column.hide ? '' : 'blue' }}
          onClick={(e) => {
            unHide(column.key);
          }}
        />
        <span>{column.title}</span>
      </Space>
    ),
  }));

  const playerRef = useRef(null);
  const date_time_format = localStorage.getItem('date_time_format');
  const is_amazon = localStorage.getItem('is_amazon') === 'true';

  function mergeArrays(dataArray, columnArray) {
    return dataArray
      ?.filter((item) => Object.values(item).some((value) => value !== undefined))
      .map((dataItem) => {
        const newItem = {};

        columnArray.forEach((column) => {
          const { key } = column;
          const dataIndex = column.dataIndex;

          if (column.typeKey === 'other') {
            if (column?.format === 'date') {
              newItem[dataIndex] = dayjs(dataItem[key]).format(date_time_format.toUpperCase());
            } else if (column?.format === 'dropdown') {
              if (Array.isArray(dataItem[key])) {
                newItem[dataIndex] = dataItem[key];
              } else {
                newItem[dataIndex] = [dataItem[key]];
              }
            } else {
              newItem[dataIndex] = dataItem[key];
            }
          } else {
            newItem[dataIndex] = dataItem[key];
          }
        });

        return newItem;
      });
  }

  function getObjectByType(type) {
    const filteredTitles = columns
      .filter((column) => column.typeKey === type)
      .map((column, index) => ({ [index]: column.title }))
      .reduce((acc, item) => Object.assign(acc, item), {});

    return filteredTitles;
  }

  function findUnmatchedColumns(columns, audienceColumnsTypes) {
    const unmatchedColumns = columns.filter((column) => {
      return !audienceColumnsTypes.some((type) => type.key === column.typeKey);
    });

    return unmatchedColumns;
  }

  const handleSave = () => {
    const unmatchedColumns = findUnmatchedColumns(columns, audienceColumnsTypes);

    if (unmatchedColumns?.length > 0) {
      return;
    }

    form.validateFields().then(async (values) => {
      const dataArray = Object.keys(values).map((key) => {
        return values[key];
      });
      const target_audiences = mergeArrays(dataArray, columns);

      setLoading(true);
      await saveAudience({
        groups: getObjectByType('group'),
        locations: getObjectByType('location'),
        target_audiences,
      });
      setLoading(false);
      notificationDispatch({
        type: notificationsReducerTypes.SUCCESS,
        payload: {
          message: 'Add Target Audience',
          description: 'Target Audience added successfully.',
        },
      });
      clearAllData();
    });
  };

  const formValueChange = (formData) => {
    let updatedValue = false;
    Object.keys(formData).forEach((key) => {
      const values = formData[key];
      Object.values(values).forEach((value) => {
        if ((value && typeof value === 'string' && value.trim() !== '') || (value && Array.isArray(value))) {
          updatedValue = true;
        }
      });
    });

    if (hasUnsavedData.data !== updatedValue) {
      dispatch({
        type: reducerTypes.HAS_UNSAVED_DATA,
        payload: { data: updatedValue },
      });
    }
  };

  useEffect(() => {
    formValueChange(formValues);
  }, [formValues]);

  useEffect(() => {
    if (!is_amazon) {
      setFolder("ocms");
    }
  }, []);


  return (
    <div>
      {editListModelOpen?.state && (
        <EditListModel
          title="Edit List"
          type={editListModelOpen?.type}
          open={editListModelOpen?.state}
          editListTitle={editListModelOpen?.editListTitle}
          close={() => {
            setEditListModelOpen({ editListTitle: '', state: false });
          }}
          saveCallback={() => console.log('Saved')}
        />
      )}

      <ModalHeader>
        <FontAwesomeIcon icon={duotone('users-viewfinder')} size="5x" color="#0066B2" />
        <Title level={1} color="#000">
          {activeProject.project?.targetAudienceColumns ? 'Customize' : 'Add'} Your Target Audience
        </Title>
      </ModalHeader>
      <div style={{ display: 'flex', justifyContent: 'end', paddingTop: 15, gap: 100 }}>
        <Button type="text" icon={<FontAwesomeIcon icon={duotone('plus')} />} onClick={importAudience}>
          Bulk Upload
        </Button>

        <WatchTutorial onClick={() => showModal()} style={{ cursor: "pointer" }}>
          <span>
            <FontAwesomeIcon icon={duotone('film')} size="xl" color="#5FAEF0" />
          </span>

          <p>Watch Tutorial</p>
        </WatchTutorial>
      </div>

      {/* {columnRef && <NewColumn parent={columnRef} />} */}

      <Title style={{ fontWeight: 400, margin: '0px 0px 20px 0px' }} level={4} color="gray">
        Your Target Audience reflects how you'd like to manage and track impacted groups and people.
      </Title>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Dropdown
          open={showHide}
          trigger={'click'}
          onOpenChange={() => setShowHide(!showHide)}
          disabled={hideColumns.length !== 0 ? false : true}
          menu={{
            items: hideMenuItems,
          }}
          dropdownRender={(menu) => (hideColumns.length !== 0 ? <div>{React.cloneElement(menu)}</div> : <div></div>)}
        >
          <Button style={{ marginBottom: 10 }} type="text">
            Hidden Columns
          </Button>
        </Dropdown>
        <Button onClick={clearAllData}>Clear All Data</Button>
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <Form style={{ width: 'calc(100% - 32px)', overflow: 'hidden' }} form={form} onFinish={handleSave}>
          {(fields, { add }) => (
            <>
              <StyledTable
                dataSource={dataSource}
                columns={definedColumns()}
                rowKey={(record) => record.key || record.id}
                pagination={false}
              />

              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                  <Button
                    shape={'text'}
                    size="mid"
                    onClick={() => {
                      add();
                      handleAddRow();
                    }}
                    icon={<FontAwesomeIcon icon={faPlus} style={{ color: `gray` }} />}
                    style={{
                      marginLeft: 2,
                      marginTop: 20,
                      marginBottom: 16,
                    }}
                  >
                    {' '}
                    Row
                  </Button>

                  <Button
                    style={{ marginLeft: '40px' }}
                    type="text"
                    icon={<FontAwesomeIcon icon={duotone('plus')} />}
                    onClick={importAudience}
                  >
                    Bulk Upload
                  </Button>
                </div>

                <Button
                  shape="round"
                  type="primary"
                  disabled={loading}
                  htmlType="submit"
                  style={{ backgroundColor: '#0066b3' }}
                >
                  {loading ? <LoadingOutlined style={{ fontSize: 24 }} spin /> : 'Save'}
                </Button>
              </div>
            </>
          )}
        </Form>
        <Button
          icon={<FontAwesomeIcon icon={faPlus} style={{ color: `gray` }} />}
          size="mid"
          onClick={() => {
            handleAddColumn();
          }}
          style={{
            marginLeft: 5,
            marginTop: 0,
            marginBottom: 16,
          }}
        />
      </div>
      {videoVisible && (
        <Modal
          centered
          title={
            <>
              <Title level={3}>{`${videoInformation.name}`}</Title>
              <hr />
            </>
          }
          width="680px"
          open={videoInformation.isVisible}
          footer={null}
          onCancel={hideModal}
          styles={{
            body: {
              padding: 0
            }
          }}
        >
          <ReactPlayer
            ref={playerRef}
            controls={true}
            playing={player}
            volume={1}
            url={`${process.env.REACT_APP_API_ENDPOINT}/videos/${folder}/${videoInformation.url}`}
            onPlay={() => setPlayer(true)}
            onPause={() => setPlayer(false)}
          />
        </Modal>
      )}
    </div>
  );
};

const ModalHeader = styled.div`
  display: flex;
  gap: 30px;
  border-bottom: 2px solid #a99bd5;
  align-items: center;
  padding: 20px;
`;
const StyledTable = styled(Table)`
  border: 1px solid #d9d9d9;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  overflow: hidden;
  th,
  td {
    padding: 0 !important;
  }
  .ant-table-container {
    height: 100%;
    max-height: 500px;
    overflow: auto;

    .ant-table-content {
      height: 100%;

      table {
        height: 100%;

        thead {
          position: sticky;
          top: 0;
          z-index: 111;
        }
      }
    }
  }
  .ant-table-cell {
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border: solid 1px #d9d9d9 !important;
    border-bottom: 1px solid #d9d9d9;
  }

  .ant-select {
    .ant-select-selector {
      height: 32px !important;
      border-color: #fff;
      border-radius: 0 !important;
    }
  }
  .ant-input {
    border-width: 1px;
    border-style: solid;
    border-color: #fff;
    &:focus {
      border-color: #4096ff;
    }
  }
`;
const WatchTutorial = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #bce3ff;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  p {
    padding: 3px 15px;
    height: 15px;
  }

  span {
    height: 40px;
    width: 40px;
    background: #bce3ff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default InputManually;
