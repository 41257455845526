import { useState, useEffect } from 'react';
import { DataService } from './dataService';
import endpoints from './endpoints';

const useApi = (endpointName, body) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = async (data) => {
    const endpoint = endpoints[endpointName];
    if (!endpoint) {
      return;
    }
    setLoading(true);
    const { method, url } = endpoint;
    try {
      const response = await DataService[method](url, data || body);

      if (response.status === 200) {
        setData(response.data);
      } else {
        setError(response.data?.error);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [endpointName]);

  return { data, loading, error, fetchData };
};

export const api = async (endpoint, method = 'post', values = {}) => {
  try {
    const response = await DataService[method](endpoint, values);

    if (response.status === 200) {
      return response; // Assuming that the actual data is stored in the `data` property
    } else {
      throw new Error(`Request failed with status ${response.status}. ${response.data?.error || ''}`);
    }
  } catch (error) {
    throw new Error(error?.message || error);
  }
};

export const apiBlob = async (endpoint, fileName) => {
  try {
    const downloadURL = await DataService.getBlob(endpoint);
    const link = document.createElement('a')
    link.href = downloadURL
    link.target = '_self'
    link.download = `${fileName}.xlsx`
    link.click()
  } catch (error) {
    throw new Error(error?.message || error);
  }
}

export const apiPostBlob = async (endpoint, values = {}, fileName) => {
  try {
    const downloadURL = await DataService.postBlob(endpoint, values);
    const link = document.createElement('a')
    link.href = downloadURL
    link.target = '_self'
    link.download = `${fileName}.xlsx`
    link.click()
  } catch (error) {
    throw new Error(error?.message || error);
  }
}

export default useApi;
