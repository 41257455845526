import actions from './actions';

const {
  TOOLS_CONFIGURATION_BEGIN,
  TOOLS_CONFIGURATION_SUCCESS,
  TOOLS_CONFIGURATION_ERR,

  TOOLS_INSTALLER_BEGIN,
  TOOLS_INSTALLER_SUCCESS,
  TOOLS_INSTALLER_ERR,

  GET_TOOLS_LIST_DATA_BEGIN,
  GET_TOOLS_LIST_DATA_SUCCESS,
  GET_TOOLS_LIST_DATA_ERR,

  GET_SINGLE_IMPACT_BEGIN,
  GET_SINGLE_IMPACT_SUCCESS,
  GET_SINGLE_IMPACT_ERR,

  GENERATE_AUDIENCE_TOOLS_BEGIN,
  GENERATE_AUDIENCE_TOOLS_SUCCESS,
  GENERATE_AUDIENCE_TOOLS_ERR,

  SAVE_PROJECT_ADOPTION_METRIC_BEGIN,
  SAVE_PROJECT_ADOPTION_METRIC_SUCCESS,
  SAVE_PROJECT_ADOPTION_METRIC_ERR,

  EDIT_PROJECT_ADOPTION_METRIC_BEGIN,
  EDIT_PROJECT_ADOPTION_METRIC_SUCCESS,
  EDIT_PROJECT_ADOPTION_METRIC_ERR,

  DELETE_PROJECT_ADOPTION_METRIC_BEGIN,
  DELETE_PROJECT_ADOPTION_METRIC_SUCCESS,
  DELETE_PROJECT_ADOPTION_METRIC_ERR,

  GENERATE_OCM_PLAN_TOOLS_BEGIN,
  GENERATE_OCM_PLAN_TOOLS_SUCCESS,
  GENERATE_OCM_PLAN_TOOLS_ERR,

  GENERATE_RESISTANCE_RISK_PLAN_BEGIN,
  GENERATE_RESISTANCE_RISK_PLAN_SUCCESS,
  GENERATE_RESISTANCE_RISK_PLAN_ERR,

  GET_TOOL_GENERATED_DATA_BEGIN,
  GET_TOOL_GENERATED_DATA_SUCCESS,
  GET_TOOL_GENERATED_DATA_ERR,

  GET_TOOLS_AUDIENCES_DATA_BEGIN,
  GET_TOOLS_AUDIENCES_DATA_SUCCESS,
  GET_TOOLS_AUDIENCES_DATA_ERR,

  GET_TOOLS_DEFAULT_COLUMNS_DATA_BEGIN,
  GET_TOOLS_DEFAULT_COLUMNS_DATA_SUCCESS,
  GET_TOOLS_DEFAULT_COLUMNS_DATA_ERR,

  GET_TOOLS_EXPORT_COLUMNS_DATA_BEGIN,
  GET_TOOLS_EXPORT_COLUMNS_DATA_SUCCESS,
  GET_TOOLS_EXPORT_COLUMNS_DATA_ERR,

  IMPORT_DATA_FOR_TOOLS_BEGIN,
  IMPORT_DATA_FOR_TOOLS_SUCCESS,
  IMPORT_DATA_FOR_TOOLS_ERR,

  EXPORT_DATA_FOR_TOOLS_BEGIN,
  EXPORT_DATA_FOR_TOOLS_SUCCESS,
  EXPORT_DATA_FOR_TOOLS_ERR,

  GET_RESISTANCE_KEY_REASON_BEGIN,
  GET_RESISTANCE_KEY_REASON_SUCCESS,
  GET_RESISTANCE_KEY_REASON_ERR,

  SAVE_RESISTANCE_KEY_REASON_BEGIN,
  SAVE_RESISTANCE_KEY_REASON_SUCCESS,
  SAVE_RESISTANCE_KEY_REASON_ERR,

  SAVE_TOOL_RISK_AUDIENCES_DATA_BEGIN,
  SAVE_TOOL_RISK_AUDIENCES_DATA_SUCCESS,
  SAVE_TOOL_RISK_AUDIENCES_DATA_ERR,

  SAVE_RESISTANCE_AUDIENCES_KEY_REASON_DATA_BEGIN,
  SAVE_RESISTANCE_AUDIENCES_KEY_REASON_DATA_SUCCESS,
  SAVE_RESISTANCE_AUDIENCES_KEY_REASON_DATA_ERR,

  SAVE_TOOL_ENGAGEMENT_PLAN_BEGIN,
  SAVE_TOOL_ENGAGEMENT_PLAN_SUCCESS,
  SAVE_TOOL_ENGAGEMENT_PLAN_ERR,

  SAVE_TOOL_ASSESSMENT_NAME_BEGIN,
  SAVE_TOOL_ASSESSMENT_NAME_SUCCESS,
  SAVE_TOOL_ASSESSMENT_NAME_ERR,

  CLOSE_TOOL_ASSESSMENT_NAME_BEGIN,
  CLOSE_TOOL_ASSESSMENT_NAME_SUCCESS,
  CLOSE_TOOL_ASSESSMENT_NAME_ERR,

  DELETE_TOOL_ASSESSMENT_NAME_BEGIN,
  DELETE_TOOL_ASSESSMENT_NAME_SUCCESS,
  DELETE_TOOL_ASSESSMENT_NAME_ERR,

  TOOLS_COPY_PROJECT_BEGIN,
  TOOLS_COPY_PROJECT_SUCCESS,
  TOOLS_COPY_PROJECT_ERR,
} = actions;

const toolInitialState = {
  data: [],
  loading: false,
  error: null,
};

const toolsReducer = (state = toolInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case TOOLS_CONFIGURATION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case TOOLS_CONFIGURATION_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case TOOLS_CONFIGURATION_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case TOOLS_INSTALLER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case TOOLS_INSTALLER_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case TOOLS_INSTALLER_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_TOOLS_LIST_DATA_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_TOOLS_LIST_DATA_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case GET_TOOLS_LIST_DATA_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_SINGLE_IMPACT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_SINGLE_IMPACT_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case GET_SINGLE_IMPACT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GENERATE_AUDIENCE_TOOLS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GENERATE_AUDIENCE_TOOLS_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case GENERATE_AUDIENCE_TOOLS_ERR:
      return {
        ...state,
        data,
        error: err,
        loading: false,
      };
    case GENERATE_OCM_PLAN_TOOLS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GENERATE_OCM_PLAN_TOOLS_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case GENERATE_OCM_PLAN_TOOLS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case SAVE_PROJECT_ADOPTION_METRIC_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case SAVE_PROJECT_ADOPTION_METRIC_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case SAVE_PROJECT_ADOPTION_METRIC_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case EDIT_PROJECT_ADOPTION_METRIC_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case EDIT_PROJECT_ADOPTION_METRIC_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case EDIT_PROJECT_ADOPTION_METRIC_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_PROJECT_ADOPTION_METRIC_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PROJECT_ADOPTION_METRIC_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case DELETE_PROJECT_ADOPTION_METRIC_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case SAVE_TOOL_ASSESSMENT_NAME_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case SAVE_TOOL_ASSESSMENT_NAME_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case SAVE_TOOL_ASSESSMENT_NAME_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case CLOSE_TOOL_ASSESSMENT_NAME_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CLOSE_TOOL_ASSESSMENT_NAME_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case CLOSE_TOOL_ASSESSMENT_NAME_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_TOOL_ASSESSMENT_NAME_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_TOOL_ASSESSMENT_NAME_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case DELETE_TOOL_ASSESSMENT_NAME_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GENERATE_RESISTANCE_RISK_PLAN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GENERATE_RESISTANCE_RISK_PLAN_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case GENERATE_RESISTANCE_RISK_PLAN_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_TOOL_GENERATED_DATA_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_TOOL_GENERATED_DATA_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case GET_TOOL_GENERATED_DATA_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_TOOLS_AUDIENCES_DATA_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_TOOLS_AUDIENCES_DATA_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case GET_TOOLS_AUDIENCES_DATA_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_TOOLS_DEFAULT_COLUMNS_DATA_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_TOOLS_DEFAULT_COLUMNS_DATA_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case GET_TOOLS_DEFAULT_COLUMNS_DATA_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_TOOLS_EXPORT_COLUMNS_DATA_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_TOOLS_EXPORT_COLUMNS_DATA_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case GET_TOOLS_EXPORT_COLUMNS_DATA_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case IMPORT_DATA_FOR_TOOLS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case IMPORT_DATA_FOR_TOOLS_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case IMPORT_DATA_FOR_TOOLS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case EXPORT_DATA_FOR_TOOLS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case EXPORT_DATA_FOR_TOOLS_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case EXPORT_DATA_FOR_TOOLS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_RESISTANCE_KEY_REASON_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_RESISTANCE_KEY_REASON_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case GET_RESISTANCE_KEY_REASON_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case SAVE_RESISTANCE_KEY_REASON_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case SAVE_RESISTANCE_KEY_REASON_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case SAVE_RESISTANCE_KEY_REASON_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case SAVE_TOOL_RISK_AUDIENCES_DATA_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case SAVE_TOOL_RISK_AUDIENCES_DATA_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case SAVE_TOOL_RISK_AUDIENCES_DATA_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case SAVE_RESISTANCE_AUDIENCES_KEY_REASON_DATA_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case SAVE_RESISTANCE_AUDIENCES_KEY_REASON_DATA_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case SAVE_RESISTANCE_AUDIENCES_KEY_REASON_DATA_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case SAVE_TOOL_ENGAGEMENT_PLAN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case SAVE_TOOL_ENGAGEMENT_PLAN_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case SAVE_TOOL_ENGAGEMENT_PLAN_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case TOOLS_COPY_PROJECT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case TOOLS_COPY_PROJECT_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case TOOLS_COPY_PROJECT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export default toolsReducer;
