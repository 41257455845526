import actions from './actions';

const {
  USER_PROJECT_BEGIN,
  USER_PROJECT_SUCCESS,
  USER_PROJECT_ERR,

  GET_PROJECT_ASSESSMENT_BEGIN,
  GET_PROJECT_ASSESSMENT_SUCCESS,
  GET_PROJECT_ASSESSMENT_ERR,

  GET_ACCOUNT_USER_PROJECT_BEGIN,
  GET_ACCOUNT_USER_PROJECT_SUCCESS,
  GET_ACCOUNT_USER_PROJECT_ERR,

  SAVE_PROJECT_ASSESSMENT_BEGIN,
  SAVE_PROJECT_ASSESSMENT_SUCCESS,
  SAVE_PROJECT_ASSESSMENT_ERR,
} = actions;

const initialStateFilter = {
  data: [],
  loading: false,
  error: null,
};

const projectReducer = (state = initialStateFilter, action) => {
  const { type, data, err } = action;
  switch (type) {
    case USER_PROJECT_BEGIN:
      return {
        ...initialStateFilter,
        loading: true,
      };
    case USER_PROJECT_SUCCESS:
      return {
        ...initialStateFilter,
        data,
        loading: false,
      };
    case USER_PROJECT_ERR:
      return {
        ...initialStateFilter,
        error: err,
        loading: false,
      };
    case GET_PROJECT_ASSESSMENT_BEGIN:
      return {
        ...initialStateFilter,
        loading: true,
      };
    case GET_PROJECT_ASSESSMENT_SUCCESS:
      return {
        ...initialStateFilter,
        data,
        loading: false,
      };
    case GET_PROJECT_ASSESSMENT_ERR:
      return {
        ...initialStateFilter,
        error: err,
        loading: false,
      };
    case GET_ACCOUNT_USER_PROJECT_BEGIN:
      return {
        ...initialStateFilter,
        loading: true,
      };
    case GET_ACCOUNT_USER_PROJECT_SUCCESS:
      return {
        ...initialStateFilter,
        data,
        loading: false,
      };
    case GET_ACCOUNT_USER_PROJECT_ERR:
      return {
        ...initialStateFilter,
        error: err,
        loading: false,
      };
    case SAVE_PROJECT_ASSESSMENT_BEGIN:
      return {
        ...initialStateFilter,
        loading: true,
      };
    case SAVE_PROJECT_ASSESSMENT_SUCCESS:
      return {
        ...initialStateFilter,
        data,
        loading: false,
      };
    case SAVE_PROJECT_ASSESSMENT_ERR:
      return {
        ...initialStateFilter,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export { projectReducer };
