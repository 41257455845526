import PropTypes from 'prop-types';
import React from 'react';
import * as headings from './style';

function Heading(props) {
  const { as, children, className, id } = props;
  const StyledHeading = as ? headings[as.toUpperCase()] : headings.H1;

  return (
    <StyledHeading className={className} id={id}>
      {children}
    </StyledHeading>
  );
}

export default Heading;
