import React, { useState, useEffect } from 'react';
import { StyledSearch } from './LibrarySection.styles';

const SearchBar = ({category, setFilter}) => {
    const [searchString, setSearchString] = useState("");

    const handleSearch = (value) => {
        setFilter(value);
    };

    const handleChanged = (e) => {
        const value = e.target.value;
        setSearchString(value);
        if (value.toString().trim() === '')
        {
            setFilter("");
        }
        else if (value.length > 3)
        {
            setFilter(value);
        }
    };

    useEffect(() => {
        if (Object.entries(category).length !== 0)
        {
            setSearchString("");
        }
    }, [category]);

    return (
        <StyledSearch
          placeholder="Search..."
          onSearch={handleSearch}
          onChange={handleChanged}
          style={{ width: 300 }}
          value={searchString}
        />
    
    );
}

export default SearchBar;