import React, { useState, useReducer, useEffect, useCallback, useRef } from "react";
import { List } from "antd";
import { CategoryContainer, CategoryListWrapper, MainContentWrapper } from "./LibrarySection.styles";
import CategoryHeader from "./CategoryHeader";
import CategoryItem from "./CategoryItem";
import { useDispatch } from "react-redux";
import { fetchCMSCategories } from "../../redux/cms/actionCreator";
import useNotificationController from "../../utility/useNotificationController";
import CMSContent from "./CMSContent";

const ContentArea = ({setFeaturedImage}) => {
  const {
    contextHolder,
    openError,
  } = useNotificationController();
  const contentList = useRef(null);
  const [categories, setCategories] = useState([])
  const [category, setCategory] = useState({});

  const initialState = {
      error: false,
      messages: ""
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'FETCH_CMS_CATEGORIES_ERR':
        return { ...state, error: true,messages: action.err };
      case 'RESET':
        return state;
      default:
        return state;
    }
  };

  const [apiError, apiResponseCallback] = useReducer(reducer, initialState);
  const dispatch = useDispatch();

  const fetchCategories = useCallback(async () => {
    apiResponseCallback({ type: 'RESET' });
      dispatch(
          fetchCMSCategories({},(response) => {
            if (response.status === 200) {
              let categories = response.data.categories;
              const tmpCategories = [];
              const currentCategoryId = localStorage.getItem("currentCategory");
              categories.map((item, index) => {
                let isActive = false;
                if (currentCategoryId === null && index === 0)
                {
                  isActive = true;
                }
                else if (parseInt(currentCategoryId) === item.id)
                {
                  isActive = true;
                }
                tmpCategories.push({
                  id: item.id,
                  name: item.name,
                  featuredImage: item.featuredImage,
                  isActive: isActive,
                });
                if (currentCategoryId === null && index === 0)
                {
                  setCategory({
                    id: item.id,
                    name: item.name,
                    featuredImage: item.featuredImage
                  });
                }
                else if (parseInt(currentCategoryId) === item.id)
                {
                  setCategory({
                    id: item.id,
                    name: item.name,
                    featuredImage: item.featuredImage
                  });
                }
              });
              setCategories(tmpCategories);
            } 
            else 
            {
              openError('topLeft', 'Error', response.err);
              apiResponseCallback(response);
            }
          }),
        );
  }, [dispatch, categories, category]);

  useEffect(() => {
    const retrieveData = async () => {
      await fetchCategories();
    };
    retrieveData().catch(console.error);
  }, []);

  const handleCategoryClick = (id) => {
    setCategories(prevCategories => 
      prevCategories.map(category => 
        category.id === id
          ? { ...category, isActive: true }
          : { ...category, isActive: false }
      )
    );
    categories.map((item, index) => {
      if (item.id === id)
      {
        localStorage.setItem("currentCategory", item.id);
        setCategory(item);
        if (item.featuredImage === null && item.featuredImage === undefined)
        {
          //setFeaturedImage('cover-change-management-library.png');
        }
        else
        {
          //setFeaturedImage(item.featuredImage);
        }
        return ;
      }
    });
  };
  
  return (
    <MainContentWrapper>
      {contextHolder}
      <CategoryContainer>
        <CategoryHeader />
        <CategoryListWrapper>
          <List
            dataSource={categories}
            renderItem={(category, index) => (
              <List.Item 
                className={category.isActive ? "active" : ""}
                onClick={() => handleCategoryClick(category.id)}
              >
                <CategoryItem 
                  key={`${category.id}-${index}`} 
                  title={category.name} 
                  isActive={category.isActive} 
                />
              </List.Item>
            )}
          />
        </CategoryListWrapper>
      </CategoryContainer>
      <CMSContent category={category} />
    </MainContentWrapper>
  );
};

export default ContentArea;