import { useState } from 'react';
import styled from 'styled-components';
import Popup from '../surveyReports/components/Popup';
import ReportModel from '../../ui-elements/ReportModel';

const ScoreCalculationPopup = ({ open, close }) => {
  return (
    <ReportModel
      title={'Score Calculation | Level of Project Risk/Effort'}
      footer={null}
      open={open}
      close={close}
      width="80%"
    >
      <Containr>
        <Description>
          This score is calculated based on a holistic view of change management metrics across the project.{' '}
        </Description>
        <Description>It only takes those metrics which have been scored into consideration.</Description>

        <TableContainer>
          <Table>
            <TableRow>
              <TableHeading>Matrix</TableHeading>
              <TableHeading>Level of Impact</TableHeading>
              <TableHeading>Score</TableHeading>
              <TableHeading>Project Score</TableHeading>
            </TableRow>
            <TableRow>
              <TableData>
                <span>Project Scope</span>
              </TableData>
              <TableData>
                <div>
                  <p>
                    <span>less than 34% of org grops impacted</span>
                  </p>
                  <p>
                    <span>34%-67% or org grops impacted</span>
                  </p>
                  <p>
                    <span>68%-100% of org grops impacted</span>
                  </p>
                </div>
              </TableData>
              <TableData>
                <div>
                  <p>
                    <center>1</center>
                  </p>
                  <p>
                    <center>2</center>
                  </p>
                  <p>
                    <center>3</center>
                  </p>
                </div>
              </TableData>

              <TableData>
                <center>3</center>
              </TableData>
            </TableRow>
            <TableRow>
              <TableData>
                <span>Customer Group Impacted?</span>
              </TableData>
              <TableData>
                <div>
                  <p>
                    <span>Not Impacted</span>
                  </p>
                  <p>
                    <span>Very Low - Low Level Impacts</span>
                  </p>
                  <p>
                    <span>Mid - High Level Impacts</span>
                  </p>
                </div>
              </TableData>
              <TableData>
                <div>
                  <p>
                    <center>1</center>
                  </p>
                  <p>
                    <center>2</center>
                  </p>
                  <p>
                    <center>3</center>
                  </p>
                </div>
              </TableData>
              <TableData>
                <center>3</center>
              </TableData>
            </TableRow>
            <TableRow>
              <TableData>
                <span># of different types of changes</span>
              </TableData>
              <TableData>
                <div>
                  <p>
                    <span>less than 10</span>
                  </p>
                  <p>
                    <span>10-20</span>
                  </p>
                  <p>
                    <span>21+</span>
                  </p>
                </div>
              </TableData>
              <TableData>
                <div>
                  <p>
                    <center>1</center>
                  </p>
                  <p>
                    <center>2</center>
                  </p>
                  <p>
                    <center>3</center>
                  </p>
                </div>
              </TableData>
              <TableData>
                <center>1</center>
              </TableData>
            </TableRow>
            <TableRow>
              <TableData>
                <span>Severity of Changes</span>
              </TableData>
              <TableData>
                <div>
                  <p>
                    <span>Most changes are low severity</span>
                  </p>
                  <p>
                    <span>Most changes are mid severity</span>
                  </p>
                  <p>
                    <span>Most changes are high severity</span>
                  </p>
                </div>
              </TableData>
              <TableData>
                <div>
                  <p>
                    <center>1</center>
                  </p>
                  <p>
                    <center>2</center>
                  </p>
                  <p>
                    <center>3</center>
                  </p>
                </div>
              </TableData>
              <TableData>
                <center>3</center>
              </TableData>
            </TableRow>
            <TableRow>
              <TableData>
                <span>Average Stakeholder Risk Score</span>
              </TableData>
              <TableData>
                <div>
                  <p>
                    <span>Low</span>
                  </p>
                  <p>
                    <span>Mid</span>
                  </p>
                  <p>
                    <span>High</span>
                  </p>
                </div>
              </TableData>
              <TableData>
                <div>
                  <p>
                    <center>1</center>
                  </p>
                  <p>
                    <center>2</center>
                  </p>
                  <p>
                    <center>3</center>
                  </p>
                </div>
              </TableData>
              <TableData>
                <center>2</center>
              </TableData>
            </TableRow>
            <TableRow>
              <TableData>
                <span>Average Readiness Score</span>
              </TableData>
              <TableData>
                <div>
                  <p>
                    <span>Low</span>
                  </p>
                  <p>
                    <span>Mid</span>
                  </p>
                  <p>
                    <span>High</span>
                  </p>
                </div>
              </TableData>
              <TableData>
                <div>
                  <p>
                    <center>1</center>
                  </p>
                  <p>
                    <center>2</center>
                  </p>
                  <p>
                    <center>3</center>
                  </p>
                </div>
              </TableData>
              <TableData>
                <center>2</center>
              </TableData>
            </TableRow>
            <TableRow>
              <TableData>
                <span>Average Level of Training Needed </span>
              </TableData>
              <TableData>
                <div>
                  <p>
                    <span>Low/None</span>
                  </p>
                  <p>
                    <span>Mid</span>
                  </p>
                  <p>
                    <span>High</span>
                  </p>
                </div>
              </TableData>
              <TableData>
                <div>
                  <p>
                    <center>1</center>
                  </p>
                  <p>
                    <center>2</center>
                  </p>
                  <p>
                    <center>3</center>
                  </p>
                </div>
              </TableData>
              <TableData>
                <center>2</center>
              </TableData>
            </TableRow>
          </Table>
          <TableRow>
            <div className="total">
              <span>TOTAL (Project Score)</span>
              <span>16</span>
            </div>
          </TableRow>
        </TableContainer>

        <Wrapper>
          <ScoreLogicHeading>Scoring Logic</ScoreLogicHeading>
          <ScoreLogicContainer>
            <ScoreLogicCard>
              <div>Project Score</div>
              <p>16</p>
            </ScoreLogicCard>
            <ScoreLogicCard>
              <div>Divided by </div>
              <p> / </p>
            </ScoreLogicCard>
            <ScoreLogicCard>
              <div>
                3x Number of Scoring
                <br /> Matrix Selected
              </div>
              <p>21</p>
            </ScoreLogicCard>
            <ScoreLogicCard>
              <p></p>
              <p>=</p>
            </ScoreLogicCard>
            <ScoreLogicCard>
              <div>% of total</div>
              <p>76.19%</p>
            </ScoreLogicCard>
            <ScoreLogicCard>
              <div>Level of Effort/Risk</div>
              <button>High</button>
            </ScoreLogicCard>
          </ScoreLogicContainer>

          <ScoringBasedContainer>
            <p>Scoring based on percentage range</p>

            <div className="warpper">
              <div className="card">
                <span>Low Range</span>
                <div style={{ background: '#00CC66' }}>Low Risk/Effort</div>
              </div>
              <div className="card">
                <span>Mid Range</span>
                <div style={{ background: '#FFC125' }}>Mid Risk/Effort</div>
              </div>
              <div className="card">
                <span>High Range</span>
                <div style={{ background: '#FF626E' }}>High Risk/Effort</div>
              </div>
            </div>
          </ScoringBasedContainer>
        </Wrapper>
      </Containr>
    </ReportModel>
  );
};

const Containr = styled.div`
  padding: 30px;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Description = styled.p`
  width: 100%;
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 400;
  color: #404040;
`;

const Table = styled.table`
  width: 100%;
  font-size: 20px;
  margin-top: 30px;
  border: 1px solid #d9d9d9;
  span {
    padding: 0px 20px;
    font-size: 14px;
    font-weight: 400;
    color: #404040;
  }
`;

const TableRow = styled.tr`
  width: 100%;
  &:nth-child(odd) {
    background: #eae9e9;
  }
  &:nth-child(even) {
    background: #f2f2f2;
  }
  > td:nth-child(1) {
    background-color: #fff;
  }

  > td:nth-child(4) {
    background-color: #fff;
  }

  .total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    font-size: 16px;
    font-weight: 600;
    span {
      margin-right: 60px;
    }
  }
`;

const TableHeading = styled.th`
  width: max-content;
  border: 1px solid #d9d9d9;
  padding: 10px 15px;
  text-align: left;
  font-weight: 400;
`;

const TableData = styled.td`
  > div {
    width: max-content;
    width: 100%;
    flex-direction: column;
  }
  p {
    border: 1px solid #d9d9d9;
    margin: 0;
  }
  center {
    font-size: 14px;
    font-weight: 400;
    color: #404040;
    padding: 5px 0px;
  }
  border: 1px solid #d9d9d9;
  padding: 0;
  width: fit-content;
  background: ${({ background }) => background};
`;

const Wrapper = styled.div`
  width: 64%;
  border-radius: 5px;
  background: #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  padding: 50px 10px;
  font-size: 14px;
  font-weight: 400;
`;

const ScoreLogicHeading = styled.p`
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #404040;
`;

const ScoreLogicContainer = styled.div`
  display: flex;
  margin: auto;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 15px;
`;

const ScoreLogicCard = styled.div`
  width: max-content;
  padding: 0px 30px;
  text-align: center;
  color: rgb(64, 64, 64);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  p {
    vertical-align: middle;
  }

  div {
    padding: 10px 0px;
  }
  button {
    background: #ff5d5f;
    width: 96px;
    height: 34px;
    border-radius: 5px;
    border: #ff5d5f 1px solid;
    text-align: center;
    color: #ffffff;
  }
`;

const ScoringBasedContainer = styled.div`
  p {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  }

  .warpper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2px;

    .card {
      width: 100%;
      background: #ffffff;
      color: #404040;
      border-radius: 5px;
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      align-items: center;
      padding-left: 10px;
      > div {
        padding: 10px;
        color: #ffffff;
      }
    }
  }
`;

export default ScoreCalculationPopup;
