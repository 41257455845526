import { useEffect, useRef, useState } from 'react';
import { Button, Input, Select, Space, Dropdown, Typography, Tooltip, Radio, Form } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as ProIcons from '@fortawesome/pro-duotone-svg-icons';
import styled from 'styled-components';
import { useTargetAudienceDispatch, useTargetAudienceState } from '../../context/targetAudience';
import useTargetAudience from '../../hook/useTargetAudience';
import { audienceColumnsTypes, defaultColumn } from '../../context/targetAudience/constants';

const { Text } = Typography;

const ColumnHeader = ({ col, formatColumns }) => {
  const [showInput, setShowInput] = useState(false);
  const [columnEditing, setColumnEditing] = useState(false);
  const [title, setTitle] = useState(col.title);
  const [typeKey, setTypeKey] = useState(col.typeKey);
  const [format, setFormat] = useState(col?.format);
  const [selectedType, setSelectedType] = useState(null);
  const [error, setError] = useState('');
  const [isIndividual, setIsIndividual] = useState( col?.isIndividual !== undefined  ? col.isIndividual : true );
  const { saveTargetAudiencesColumns } = useTargetAudience();
  const { columns } = useTargetAudienceState();
  const [options, setOptions] = useState(col.options || [{ key: 'option-0' }]);
  const dispatch = useTargetAudienceDispatch();
  const hasInDefaultColumns = defaultColumn.some((i) => i.typeKey === col.typeKey && i.key === col.key);
  const ref = useRef(null);
  const type = audienceColumnsTypes.find((i) => i.key === col.typeKey);
  const filterHiddenColumns = columns.filter((i) => i?.hide !== true);
  const bottomRef = useRef(null);

  useEffect(() => {
    if (bottomRef.current && (format === 'dropdown' || format === 'radio')) {
      bottomRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
    if (error !== '') {
      setTimeout(() => {
        setError('');
      }, 3000);
    }
  }, [options.length, format, error]);

  useEffect(() => {
    if (!type) {
      setShowInput(true);
      dispatch({
        type: 'SET_REF',
        payload: ref,
      });
    }
  }, [type]);
  useEffect(() => {
    const selected = audienceColumnsTypes.find((i) => i.key === typeKey);
    setSelectedType(selected);
  }, [typeKey]);
  const deleteHandler = () => {
    saveTargetAudiencesColumns(columns.filter((i) => i.key !== col.key));
  };
  const hideColumn = () => {
    const filteredColumns = columns.map((i) => (i.key === col.key ? { ...i, hide: true } : i));
    saveTargetAudiencesColumns(filteredColumns);
  };

  const typeHandler = (e) => {
    setTypeKey(e);
  };
  const formatHandler = (e) => {
    setFormat(e);
  };
  const fieldHandler = (e) => {
    setIsIndividual(e.target.value === 'true');
  };

  function checkColumnName(newTitle) {
    let count = 0;
    let title = newTitle;

    while (columns.some((column) => column.title === title)) {
      count++;
      title = `${newTitle} (${count})`;
    }

    return title;
  }

  const save = (e) => {
    e.preventDefault();
    const isType = audienceColumnsTypes.find((item) => item.key === typeKey);
    const dropdown = isType?.formats?.find((i) => i.key === format)?.dropdown || isType?.dropdown;
    const hasUndefinedOrEmpty = options.some((i) => i?.value === undefined || i?.value === '');

    if (options > 1 && !!hasUndefinedOrEmpty) {
      setError('You must fill the all fields.');
      return;
    }
    if (title?.trim() === '' || !isType) {
      setError('You must fill the all fields.');
      return;
    }

    if (!!title && !!isType) {
      let dataIndex = typeKey;
      if (typeKey === 'group' || typeKey === 'location') {
        const getLevel = columns.filter((i) => i.typeKey === typeKey).length;
        dataIndex = columnEditing ? col.dataIndex : `${typeKey}_level_${getLevel}`;
      }
      if (typeKey === 'other') {
        dataIndex = title;
      }

      setError('');
      saveTargetAudiencesColumns(
        formatColumns(
          columns.map((i) =>
            i.key === col.key
              ? {
                ...i,
                title: checkColumnName(title),
                typeKey,
                dataIndex: dataIndex,
                dropdown: dropdown,
                format,
                isIndividual: typeKey === 'other' ? isIndividual : undefined,
                options: dropdown && typeKey === 'other' ? options : null,
              }
              : i,
          ),
        ),
      );
      setShowInput(false);
    }
  };

  const closeHandler = () => {
    if (!type) {
      deleteHandler();
    }
    setShowInput(false);
    dispatch({
      type: 'SET_REF',
      payload: null,
    });
  };

  const items = [
    {
      key: '1',
      label: (
        <a
          onClick={(e) => {
            setColumnEditing(true);
            setShowInput(true);
          }}
        >
          <FontAwesomeIcon icon={ProIcons['faPencil']} /> Change Column Name
        </a>
      ),
    },
    col.editable &&
    !hasInDefaultColumns && {
      key: '2',
      label: (
        <a onClick={deleteHandler}>
          <FontAwesomeIcon icon={ProIcons['faTrash']} color="red" /> Delete Column
        </a>
      ),
    },
    {
      key: '3',
      label: (
        <a onClick={hideColumn}>
          {' '}
          <FontAwesomeIcon icon={ProIcons['faEyeSlash']} /> Hide Column
        </a>
      ),
    },
  ];

  const fieldTypeToolTipContent = (
    <div style={{ fontSize: 12 }}>
      <div style={{ background: '#004C85', color: '#fff', fontSize: '23px', padding: '5px 10px', marginBottom: 10 }}>
        <h3 style={{ marginBottom: 0 }}> Field type</h3>
      </div>
      <p>
        Field type impacts whether that column's data will show on your reporting dashboard, assessments, and filters.
        It's important to choose the right field type so that the platform knows how to categorize your data.{' '}
      </p>

      <p>
        {' '}
        Note: If you do not see columns for First Name, Last Name, Email or Job Role, check for hidden columns at the
        top, left of the table, and unhide these.
      </p>
    </div>
  );
  const columnTypeToolTipContent = (
    <div style={{ fontSize: 14 }}>
      <p>Fields related to individuals are things like mobile number and immediate supervisor.</p>

      <p>Fields related to Groups are things like group contact or group communication preference.</p>
    </div>
  );

  const inputValueHandler = (value, key) => {
    const filter = options.map((i) =>
      i.key === key
        ? {
          key,
          value,
          label: value,
        }
        : i,
    );
    setOptions(filter);
  };

  const removeOption = (key) => {
    const filterOptions = options.filter((i) => i.key !== key);
    setOptions(filterOptions);
  };

  return (
    <div ref={ref} style={{ padding: '16px 16px', cursor: 'pointer', width: '100%', minWidth: 'fit-content' }}>
      <div>
        <Dropdown style={{ minWidth: '200px' }} trigger="click" menu={{ items }}>
          <Space
            wrap={false}
            style={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexWrap: 'nowrap' }}
          >
            <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <FontAwesomeIcon
                style={{ marginRight: '5px' }}
                icon={ProIcons[type?.formats?.find((i) => i.key === format)?.icon || type?.icon || 'faBars']}
              />
              <span style={{ textWrap: 'nowrap' }}>{col.title}</span>
            </div>
            <DownOutlined />
          </Space>
        </Dropdown>
        {showInput && (
          <Form onFinish={save}>
            <Wrapper position={filterHiddenColumns[0]?.key === col.key ? 'left' : 'right'}>
              <Container>
                <label>Column name</label>
                <Input
                  style={{ border: '1px solid gray' }}
                  autoFocus
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                {!type && (
                  <>
                    {col.editable && !hasInDefaultColumns && (
                      <>
                        <div>
                          <label>Field type</label>
                          <Tooltip
                            style={{ maxWidth: '400px !important' }}
                            placement="right"
                            title={fieldTypeToolTipContent}
                          >
                            <FontAwesomeIcon
                              size="lg"
                              style={{
                                marginLeft: '10px',
                                '--fa-secondary-color': '#0066B2',
                                '--fa-primary-color': '#fff',
                              }}
                              icon={ProIcons['faCircleQuestion']}
                              color="#0066B2"
                            />
                          </Tooltip>
                        </div>

                        <Select
                          style={{ border: '1px solid gray', borderRadius: '5px', overflow: 'hidden' }}
                          placeholder="Select a Type"
                          onChange={typeHandler}
                          options={audienceColumnsTypes
                            .filter((i) => {
                              if (i.key === 'employeeId' && columns.some((col) => col.typeKey === 'employeeId')) {
                                return;
                              }
                              if (!i.hide) {
                                return i;
                              }
                            })
                            .map((item) => ({
                              value: item.key,
                              label: (
                                <SelectOption>
                                  <FontAwesomeIcon icon={ProIcons[item.icon]} />
                                  <span>{item.label}</span>
                                  {item.key !== typeKey && (
                                    <Tooltip placement="right" title={item?.tooltipText}>
                                      <FontAwesomeIcon
                                        size="lg"
                                        style={{
                                          marginLeft: '10px',
                                          '--fa-secondary-color': '#0066B2',
                                          '--fa-primary-color': '#fff',
                                        }}
                                        icon={ProIcons['faCircleQuestion']}
                                        color="#0066B2"
                                      />
                                    </Tooltip>
                                  )}
                                </SelectOption>
                              ),
                            }))}
                          value={typeKey}
                        />
                      </>
                    )}
                    {selectedType?.formats && (
                      <div className="formatContainer">
                        <p>
                          Is this field related to Individuals or to Groups?{' '}
                          <Tooltip placement="right" title={columnTypeToolTipContent}>
                            <FontAwesomeIcon
                              size="lg"
                              style={{
                                marginLeft: '10px',
                                '--fa-secondary-color': '#0066B2',
                                '--fa-primary-color': '#fff',
                              }}
                              icon={ProIcons['faCircleQuestion']}
                              color="#0066B2"
                            />
                          </Tooltip>
                        </p>{' '}
                        <Radio.Group onChange={fieldHandler} value={String(isIndividual) || 'true'}>
                          <Radio value={'true'}>Individuals</Radio>
                          <Radio value={'false'}>Groups</Radio>
                        </Radio.Group>
                        <p>Format</p>
                        <Select
                          style={{ border: '1px solid gray', borderRadius: '10px', overflow: 'hidden' }}
                          placeholder="Select a Type"
                          onChange={formatHandler}
                          options={selectedType?.formats
                            .filter((i) => i.hide === false)
                            .map((item) => ({
                              value: item.key,
                              label: (
                                <SelectOption key={item.label}>
                                  <FontAwesomeIcon icon={ProIcons[item.icon]} />
                                  <span>{item.label}</span>
                                </SelectOption>
                              ),
                            }))}
                          value={format}
                        />
                        {(format?.includes('radio') || format?.includes('dropdown')) && (
                          <OptionsContainer>
                            <p>Add Selection Options</p>

                            {options.map((i, index) => (
                              <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <Input onChange={(e) => inputValueHandler(e.target.value, i.key)} />
                                <FontAwesomeIcon
                                  style={{ cursor: options.length > 1 ? 'pointer' : 'not-allowed' }}
                                  disable
                                  onClick={options.length > 1 ? () => removeOption(i.key) : undefined}
                                  icon={ProIcons.faXmark}
                                />
                              </div>
                            ))}
                            <FontAwesomeIcon
                              icon={ProIcons.faCirclePlus}
                              color="#0066B2"
                              size="lg"
                              onClick={() => setOptions([...options, { key: `option-${Math.random()}` }])}
                            />
                          </OptionsContainer>
                        )}
                      </div>
                    )}
                  </>
                )}

                {!!error && (
                  <Text style={{ marginLeft: 'auto' }} type="danger">
                    {error}
                  </Text>
                )}
                <div ref={bottomRef} />
              </Container>
              <Space style={{ justifyContent: 'end', padding: '5px 0px' }}>
                <Button type="text" shape="round" onClick={closeHandler}>
                  Cancel
                </Button>
                <Button htmlType="submit" type="primary" shape="round" onClick={save}>
                  Save
                </Button>
              </Space>
            </Wrapper>
          </Form>
        )}
      </div>
    </div>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  max-height: 350px;
  overflow: auto;
  padding-right: 15px;

  .formatContainer {
    padding: 0px 30px;
    display: flex;
    flex-direction: column;
    p {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
`;

const Wrapper = styled.div`
  position: absolute;
  background: white;
  right: ${(p) => (p.position === 'right' ? 0 : '')};
  left: ${(p) => (p.position === 'left' ? 0 : '')};
  width: 420px;
  min-height: 100px;
  z-index: 99999;
  border: 1px solid #ada8a8;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 15px 15px 30px;
  max-height: 420px;
  input {
    width: 100%;
  }
  .ant-radio-inner {
    border-width: 1px !important;
  }
  .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    background-color: black !important;
    border-color: black !important;
  }
  .ant-select {
    min-height: 32px;
  }
  .ant-select .ant-select-selector {
    border: none !important;
  }
`;

const SelectOption = styled.div`
  width: 100%;
  height: 26px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const OptionsContainer = styled.div`
  .ant-input {
    border-color: gray !important;
    margin-bottom: 10px;
  }
`;

export default ColumnHeader;
