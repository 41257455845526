import React, { useState } from 'react';
import { Avatar, Tooltip } from 'antd';


const AccountUserProfileAvatar = (props) => {
    return (
      <>
          {
            (props.audience.profileImage !== undefined && props.audience.profileImage !== null && props.audience.profileImage.trim() !== "") ? (
              <Tooltip title={props.audience.fullName} placement="top">
                <Avatar src={`${process.env.REACT_APP_API_ENDPOINT}/images/user_images/${props.audience.profileImage}`} style={{verticalAlign: 'middle', marginRight: props.margin || "7px", border: props.border || "none" }} size={props.size || 'large'} gap={6} />
              </Tooltip>
            ) : (
              <Tooltip title={props.audience.fullName} placement="top">
                <Avatar style={{ backgroundColor: `${props.audience.initialColor || "#2197c2"}`, verticalAlign: 'middle', marginRight: props.margin || "2px", border: props.border || "none" }} size={props.size || 'large'} gap={6}>
                  {props.audience.initialName}
                </Avatar>
              </Tooltip>
          )}
      </>
    );
};

export default AccountUserProfileAvatar;