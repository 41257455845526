
import {createContext, useContext, useReducer } from 'react';
import { reportsReducer } from './reducer'; 
import { reportsInitialState } from './constants'

export const ReportsState = createContext(reportsInitialState);
export const ReportsDispatch = createContext();
export const useReportsState = () => useContext(ReportsState);
export const useReportsDispatch = () => useContext(ReportsDispatch);


const ReportsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reportsReducer, reportsInitialState, () => {
    return reportsInitialState;
  });

  return (
    <ReportsDispatch.Provider value={dispatch}>
      <ReportsState.Provider value={state}>{children}</ReportsState.Provider>
    </ReportsDispatch.Provider>
  );
};

export default ReportsProvider;
