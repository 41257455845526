import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DescriptionModel from './DescriptionModel';
import { faPen, faPlus } from '@fortawesome/pro-duotone-svg-icons';

const AddDescriptionButton = ({ description, defaultDescription, handleSave, reportSection, reportKey, reportInfoId }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className={'hideforPDF'}>
      <DescriptionModel
        title="Add Report Description"
        open={open}
        handleModelClose={() => {
          setOpen(false);
        }}
        description={description}
        defaultDescription={defaultDescription}
        reportSection={reportSection}
        reportKey={reportKey}
        handleSave={handleSave}
        reportInfoId={reportInfoId}
      />
      <span
        style={{
          marginTop: '8px',
          cursor: 'pointer',
          width: 'max-content',
        }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <FontAwesomeIcon icon={!description ? faPlus : faPen} />
        <span
          style={{
            marginLeft: '8px',
            fontSize: '12px',
            fontWeight: 400,
            color: '#A6A6A6',
          }}
        >
          {description ? 'Edit report description' : 'Add a report description'}
        </span>
      </span>
    </div>
  );
};

export default AddDescriptionButton;
