import { useState } from 'react';
import Checkbox from '../checkbox';
import { Col, Row } from 'antd';

const CheckboxGroup = ({ options, value, onChange = () => {} }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleCheckboxChange = (option) => {
    const updatedSelection = [...selectedOptions];

    if (selectedOptions.includes(option)) {
      updatedSelection.splice(updatedSelection.indexOf(option), 1);
    } else {
      updatedSelection.push(option);
    }
    setSelectedOptions(updatedSelection);
    onChange(updatedSelection);
  };
  return (
    <Row gutter={[16, 16]}>
      {options.map((option) => (
        <Col span={8}>
          <Checkbox
            key={option}
            value={option}
            label={option}
            checked={selectedOptions.indexOf(option) > -1}
            onChange={() => handleCheckboxChange(option)}
          />
        </Col>
      ))}
    </Row>
  );
};

export default CheckboxGroup;
