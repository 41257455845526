import { Editor } from '@tinymce/tinymce-react';

const DEFAULT_TOOLBAR =
  'undo redo | bold italic | styleselect | forecolor |  alignleft aligncenter alignright alignjustify | bullist numlist outdent indent';

const TextEditor = (props) => {
  const {
    toolbar_location = 'bottom',
    toolbar = DEFAULT_TOOLBAR,
    statusbar = true,
    onBlur = () => {},
    onFocus = () => {},
    onEditorChange = () => {},
    onInit = () => {},
  } = props;

  return (
    <Editor
      apiKey={process.env.REACT_APP_TINYMCE_KEY}
      initialValue={props.content}
      onInit={(evt) => {
        onInit;
      }}
      init={{
        statusbar,
        branding: false,
        skin: 'outside',
        body_class: 'body-assessment-results-content',
        height: props.height || 1000,
        width: '100%',
        menubar: false,
        format_empty_lines: true,
        schema: 'html5',
        table_default_styles: {
          width: '100%',
        },
        toolbar_location: toolbar_location,
        link_context_toolbar: true,
        relative_urls: false,
        remove_script_host: false,
        font_size_formats:
          '8px 9px 11px 12px 14px 18px 24px 30px 36px 48px 60px 72px 96px',
        plugins:
          'preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount',
        toolbar,
        image_advtab: true,
        noneditable_class: 'noneEditableButton',
      }}
      onChange={props.onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      onEditorChange={onEditorChange}
      value={props.value}
    />
  );
};

export default TextEditor;
