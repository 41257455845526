import { useCallback, useEffect, useReducer, useState } from 'react';
import { Row, Col, Button, Typography, Modal, Image, Form } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core';
import  * as ProIcons from '@fortawesome/pro-duotone-svg-icons'

import { theme } from '../../../../../config/theme/themeVariables';
import { useDispatch } from 'react-redux';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import AudienceOranizations from '../../widgets/target_audiences/AudienceOranizations';
import AudienceLocations from '../../widgets/target_audiences/AudienceLocations';
import { TargetAudienceStyle } from '../../../Style';
import AddTargetAudiencePlaceholder from '../../widgets/target_audiences/AddTargetAudiencePlaceholder';
import TargetAudienceManual from '../../widgets/target_audiences/TargetAudienceManual';
import { getLocationAndGroupLevels, getTargetAudienceStat, saveAudience } from '../../../../../redux/audiences/actionCreator';
import TargetAudienceExcel from '../../widgets/target_audiences/TargetAudienceExcel';
import TargetAudienceExcelMap from '../../widgets/target_audiences/TargetAudienceExcelMap';
import { useNavigate } from 'react-router-dom';
import useNotificationController from '../../../../../utility/useNotificationController';

const {Title} = Typography;

function AddAudienceModel(props) {
    const navigate = useNavigate();
    const userTheme = JSON.parse(localStorage.getItem('userTheme'));
    theme['user-theme'] = userTheme;

    const [onlyIndividual, setOnlyIndividual] = useState(true);
    const [currentGroup, setCurrentGroup] = useState(0);
    const [currentGroupNames, setCurrentGroupNames] = useState({0: {name: "Group Level 1", new: true}});

    const [currentLocation, setCurrentLocation] = useState(0);
    const [currentLocationNames, setCurrentLocationNames] = useState({0: {name: "Location Level 1", new: true}});

    const {
        contextHolder,
        openError,
        openSuccess
    } = useNotificationController();

    const formattedCurrentGroupNames = Object.keys(currentGroupNames).reduce((acc, key)=>({
        ...acc,
        [key]: currentGroupNames[key].name
    }),{})

    const formattedCurrentLocationNames = Object.keys(currentLocationNames).reduce((acc, key)=>({
        ...acc,
        [key]: currentLocationNames[key].name
    }),{})
    
    theme['user-theme'] = JSON.parse(localStorage.getItem('userTheme'));

    const [step, setStep] = useState(1);
    const [isEditMode, setIsEditMode] = useState(false);
    const [editType, setEditType] = useState("");
    
    const [defaultDataKeys, setDefaultDataKeys] = useState([]);
    const [defaultColumns, setDefaultColumns] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [dataExcelDataColumn, setDataExcelDataColumn] = useState([]);
    const [currentDoneTheme, setCurrentDoneTheme] = useState('default');
    const [hasDone, setHasDone] = useState(false);
    const [loading, setLoading] = useState(true);
    
    const icons = ['faCloudArrowUp','faCloudArrowDown','faPrint'];
    icons.map((icon, i) => {
        library.add(ProIcons[icon]);
    });

    const initialState = {
        error: false,
        messages: ""
    };

    const reducer = (state, action) => {
        switch (action.type) {
          case 'GET_AUDIENCE_LIST_ERR':
            return { ...state, error: true,messages: action.err };
          case 'GET_TARGET_AUDIENCE_STAT_ERR':
            return { ...state, error: true,messages: action.err };
          case 'RESET':
            return state;
          default:
            return state;
        }
    };

    const [apiError, apiResponseCallback] = useReducer(reducer, initialState);

    const dispatch = useDispatch();

    const saveAudienceData = useCallback(async (data) => {
        apiResponseCallback({ type: 'RESET' });
        dispatch(
            saveAudience(data, (response) => {
            if (response.status === 200) {
                openSuccess('bottomRight', 'Save Audiences', `Your Audience List Saved Succesfully, Please wait, the page will be reloaded in 5 seconds..`);
                setTimeout(() => {
                    window.location.reload();
                }, 5000);
            } else {
                openSuccess('bottomRight', 'Save Audiences', response.err);
                apiResponseCallback(response);
            }
          }),
        );
    }, [dispatch, props]);

    const fetchData = useCallback(async () => {
        apiResponseCallback({ type: 'RESET' });
        dispatch(
            getTargetAudienceStat((response) => {
              if (response.status === 200) {
                const groupCount = response.data.groupCount;
                const locationCount = response.data.locationCount;
                const jobCount = response.data.jobCount;
                const audiencesCount = response.data.audiencesCount;
                setTargetAudienceCount({
                    groups      : groupCount,
                    jobs        : jobCount,
                    locations   : locationCount,
                    individuals : audiencesCount
                });
              } else {
                  apiResponseCallback(response);
              }
            }),
          );
    }, [dispatch]);

    const fetchGroupsAndLocationLevels = useCallback(async () => {
        apiResponseCallback({ type: 'RESET' });
        dispatch(
            getLocationAndGroupLevels((response) => {
              if (response.status === 200) {
                const groupLevels = response.data.groupLevels;
                const locationLevels = response.data.locationLevels;

                if(!groupLevels.length) {
                    let formGroups = props.form.getFieldValue('group_level_key') || []; 
                    formGroups.push({ groupLevelName: "" })
                    props.form.setFieldsValue({ group_level_key: formGroups });
                }

                if(!locationLevels.length) {
                    let formLocations = props.form.getFieldValue('location_level_key') || []; 
                    formLocations.push({ locationLevelName: "" })
                    props.form.setFieldsValue({ location_level_key: formLocations });
                }

                if(groupLevels.length){

                    const groupName = groupLevels.reduce((acc, item, index) => {
                        acc[index] = {name: item.groupLevelName, new: false};
                        return acc;
                      }, {})

                    setCurrentGroupNames(groupName)
                    setCurrentGroup(groupLevels.length - 1)
                    
                   

                }
                if(locationLevels.length){

                    const locations = locationLevels.reduce((acc, item, index) => {
                        acc[index] = {name: item.locationLevelName, new: false };
                        return acc;
                      }, {})

                    setCurrentLocationNames(locations)
                    setCurrentLocation(locationLevels.length - 1)

                }
              } else {
                  apiResponseCallback(response);
              }
            }),
          );
    }, [dispatch]);

    const generateAudienceData = async () => {
        let tmpAudiences = [];
        let dataCount = 0;
        dataSource.map(item => {
            let foundData = false;
            Object.entries(item).map(([k, val]) => {
                if (k !== 'key' && val.trim() !== "")
                {
                    foundData = true;
                }
            })
            if (foundData)
            {
                dataCount++;
                tmpAudiences.push(item);
            }
        });
        if (dataCount === 0)
        {
            openError('top', 'Save Audiences', "Nothing To Save");
            return ;
        }
        openSuccess('bottomRight', 'Saving', `${dataCount} Audience${(dataCount > 1 ? 's' : '')} Data will be Saved..`);
        saveAudienceData({
            type: "save-audiences", 
            target_audiences: tmpAudiences,
            groups: formattedCurrentGroupNames,
            locations: formattedCurrentLocationNames,
            onlyIndividual: onlyIndividual
        });
    }

    const generateAudienceDataFromMap = async (audienceData, onlyIndividualData) => {
        saveAudienceData({
            type: "save-audiences", 
            target_audiences: audienceData,
            groups: formattedCurrentGroupNames,
            locations: formattedCurrentLocationNames,
            onlyIndividual: onlyIndividualData
        });
    }

    useEffect(() => {
        const retrieveData = async () => {
            await Promise.all([
                fetchGroupsAndLocationLevels(),
                fetchData()
            ])
            setLoading(false)
        }
        props.form.resetFields();
        retrieveData()
        .catch(console.error);
    }, []);

    /*
    * Get All Headers data from API
    */

    const tableColumns = [
        {
            label: 'First Name',
            key: 'firstName'
        }, 
        {
            label: 'Last Name',
            key: 'lastName'
        }, 
        {
            label: 'Email',
            key: 'email'
        }, 
        {
            label: 'Job Role',
            key: 'jobRole'
        }
    ];
    
    const createColumns = async () => {
        let defaultColumns = [];
        let defaultDataKeys = [];
        let defaultDataNames = [];
        let defaultData = [];

        Promise.all(
            tableColumns.map(item => {
                const tmp = {
                        title: item.label,
                        dataIndex: item.key,
                        width: "200px",
                        editable: true
                }; 
                defaultColumns.push(tmp);
                defaultDataKeys.push(item.key);
                defaultDataNames.push(item.label);
            }),
    
            Object.entries(formattedCurrentGroupNames).map(([k, item], i) => {
                const tmp = {
                    title: item,
                    dataIndex: `group_level_${i}`,
                    width: "200px",
                    editable: true
                }; 
                defaultColumns.push(tmp);
                defaultDataKeys.push(`group_level_${i}`);
                defaultDataNames.push(item);
            }),
        
            Object.entries(formattedCurrentLocationNames).map(([k, item], i) => {
                const tmp = {
                    title: item,
                    dataIndex: `location_level_${i}`,
                    width: "200px",
                    editable: true
                }; 
                defaultColumns.push(tmp);
                defaultDataKeys.push(`location_level_${i}`);
                defaultDataNames.push(item);
            }),

            [0,1,2].map(i => {
                let tmp = {};
                tmp['key'] = i;
                defaultDataKeys.map(item => {
                    tmp[item] = '';
                });
                defaultData.push(tmp);
            })
        );
        
        setDefaultColumns(defaultColumns);    
        setDefaultDataKeys(defaultDataKeys);
        if (dataSource.length === 0)
        {
            setDataSource(defaultData);    
        }
        
        setDataExcelDataColumn(defaultDataNames);    
    }
    
    const createExcelColumns = async () => {
        let defaultExcelColumns = [];
        let defaultExcelDataKeys = [];
        let defaultExcelData = [];

        Promise.all(
            tableColumns.map(item => {
                const tmp = {
                        title: item.label,
                        dataIndex: item.key,
                        width: "120px",
                        editable: true
                }; 
                defaultExcelColumns.push(tmp);
            }),
    
            Object.entries(formattedCurrentGroupNames).map(([k, item], i) => {
                const tmp = {
                    title: item,
                    dataIndex: `group_level_${i}`,
                    width: "140px",
                    editable: true
                }; 
                defaultExcelColumns.push(tmp);
                defaultExcelDataKeys.push(`group_level_${i}`);
            }),
        
            Object.entries(formattedCurrentLocationNames).map(([k, item], i) => {
                const tmp = {
                    title: item,
                    dataIndex: `location_level_${i}`,
                    width: "150px",
                    editable: true
                }; 
                defaultExcelColumns.push(tmp);
                defaultExcelDataKeys.push(`location_level_${i}`);
            }),

            [0,1,2].map(i => {
                let tmp = {};
                tmp['key'] = i;
                defaultExcelDataKeys.map(item => {
                    tmp[item] = '';
                });
                defaultExcelData.push(tmp);
            })
        ); 
        return defaultExcelData;
    }

    const resetModal = () => {
        setStep(1);
        setCurrentGroup(0);
        setCurrentGroupNames({0: "Group Level 1"});
        setCurrentLocation(0);
        setCurrentLocationNames({0: "Location Level 1"});
        setIsEditMode(false);
        setEditType("");
    }
    
    return (
        <>
        {contextHolder}
        <Modal
            centered
            closeIcon={(<FontAwesomeIcon size='2xl' icon={duotone("circle-xmark")} />)}
            open={props.initiateModalOpen}
            onOk={() => {}}
            confirmLoading={() => {}}
            wrapClassName={'ocm-modal'}
            onCancel={() => {
                props.setAddTargetAudienceModalOpen(false);
                props.setInitiateModalOpen(false);
                if (props.reloadOnClose)
                {
                    navigate(0);
                }
            }}
            okButtonProps={{
            style: {display: "none"},
            }}
            cancelButtonProps={{
            style: {display: "none"},
            }}
            width={1400}
            footer={null}
        >
        {!loading && <TargetAudienceStyle theme={theme}>
            <Form
                layout={'vertical'}
                size='large'
                form={props.form}
                onFinish={() => {}}
                style={{width: "100%"}}
            >
                <Row gutter={[25,25]}>
                    <Col xxl={14} xl={14} md={12}>
                        {step === 1 && (
                            <AudienceOranizations
                                currentGroup={currentGroup}
                                setCurrentGroup={(value) => setCurrentGroup(value)}
                                currentGroupNames={currentGroupNames}
                                setCurrentGroupNames={(value) => setCurrentGroupNames(value)}
                            />
                        )}
                        {step === 2 && (
                            <AudienceLocations 
                                currentLocation={currentLocation}
                                setCurrentLocation={(value) => setCurrentLocation(value)}
                                currentLocationNames={currentLocationNames}
                                setCurrentLocationNames={(value) => setCurrentLocationNames(value)}
                            />
                        )}
                        {step === 3 && !isEditMode && (
                            <AddTargetAudiencePlaceholder 
                                setIsEditMode={(val) => setIsEditMode(val)}
                                setEditType={(val) => setEditType(val)}
                                createColumns={createColumns}
                                currentGroupNames={formattedCurrentGroupNames}
                                currentLocationNames={formattedCurrentLocationNames}
                            />
                        )}
                        {step === 3 && isEditMode && editType === "Manual" && (
                            <TargetAudienceManual 
                                {...props}
                                setIsEditMode={(val) => setIsEditMode(val)}
                                setEditType={(val) => setEditType(val)}
                                currentGroupNames={formattedCurrentGroupNames}
                                currentLocationNames={formattedCurrentLocationNames}
                                defaultColumns={defaultColumns}
                                setOnlyIndividual={(val) => setOnlyIndividual(val)}
                                dataSource={dataSource}
                                setDataSource={(newData) => setDataSource(newData)}
                                defaultDataKeys={defaultDataKeys}
                                setDoneTheme={(isDone) => {
                                    setCurrentDoneTheme('ocm-default');
                                    setHasDone(isDone);
                                }}
                            />
                        )}
                        {step === 3 && isEditMode && editType === "Import Excel" && (
                            <TargetAudienceExcel 
                                {...props}
                                setIsEditMode={(val) => setIsEditMode(val)}
                                setEditType={(val) => setEditType(val)}
                                currentGroupNames={formattedCurrentGroupNames}
                                currentLocationNames={formattedCurrentLocationNames}
                                createColumns={createColumns}
                                defaultColumns={defaultColumns}
                                dataExcelDataColumn={dataExcelDataColumn}
                                setDataSource={(newData) => setDataSource(newData)}
                                defaultDataKeys={defaultDataKeys}
                            />
                        )}
                        {step === 3 && isEditMode && editType === "Import Excel Map" && (
                            <TargetAudienceExcelMap 
                                {...props}
                                setIsEditMode={(val) => setIsEditMode(val)}
                                setEditType={(val) => setEditType(val)}
                                currentGroupNames={formattedCurrentGroupNames}
                                currentLocationNames={formattedCurrentLocationNames}
                                createColumns={createColumns}
                                defaultColumns={defaultColumns}
                                dataExcelDataColumn={dataExcelDataColumn}
                                setDataSource={(newData) => setDataSource(newData)}
                                defaultDataKeys={defaultDataKeys}
                                generateAudienceDataFromMap={(audienceData, onlyIndividualData) => generateAudienceDataFromMap(audienceData, onlyIndividualData)}
                            />
                        )}
                    </Col>
                    <Col xxl={10} xl={10} md={12} className='Image-grid'>
                        {(step < 3 || (isEditMode && editType !== "Import Excel Map") ) ? (
                            <div className="image-people">
                                <img 
                                    src={require('../../../../../static/images/people-new.svg').default} 
                                />
                            </div>
                        ) : (
                            (step === 3 && isEditMode && editType === "Import Excel Map") ? (
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                }}>
                                    <img 
                                        className='image-people' 
                                        src={require('../../../../../static/images/video.png')} 
                                        style={{height: "30%"}}
                                    />
                                <Title level={4} type='secondary' style={{paddingTop: "20px"}}>(This video is unavailable till go-live)</Title>
                                </div>
                            ) : (
                                <Row>
                                    <Col span={24} style={{padding: "40px 20px"}}>
                                        <Title level={2}>You can add both groups and individuals together or add just your groups. Here are some examples:</Title>
                                        <div style={{textAlign: "center", paddingTop: "40px"}}>
                                            <Title level={4} style={{paddingBottom: "10px"}}>Groups and Individuals Example</Title>
                                            <Image
                                                width={500}
                                                src={`${process.env.REACT_APP_BASE_URL}individual_examples.png`}
                                            />
                                        </div>
                                        <div style={{textAlign: "center", paddingTop: "40px"}}>
                                            <Title level={4} style={{paddingBottom: "10px"}}>Groups Example</Title>
                                            <Image
                                                width={500}
                                                src={`${process.env.REACT_APP_BASE_URL}group_examples.png`}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            )
                        )}
                    </Col>
                    <Col xxl={14} xl={14} md={12} className='footer-audience' style={{position: "absolute", bottom: "10px"}}>
                        <Row gutter={[25,25]} justify={'center'}>
                            <Col xxl={8} lg={8} md={8} style={{textAlign: "left", paddingLeft: "30px"}}>
                                {step > 1 && (
                                    <Title 
                                        level={3} 
                                        underline 
                                        type='secondary'
                                        onClick={() => {
                                            if (!isEditMode)
                                            {
                                                let currentStep = step;
                                                setStep(--currentStep);
                                            }
                                            setIsEditMode(false);
                                            setEditType("");
                                        }}
                                        style={{cursor: "pointer"}}
                                    >
                                            Back
                                    </Title>
                                )}
                            </Col>
                            <Col xxl={9} lg={8} md={8} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                {step < 3 && (
                                <>
                                <Title level={3} 
                                    underline 
                                    className='ocm-color' 
                                    style={{cursor: "pointer"}}
                                    onClick={() => {
                                        let currentStep = step;
                                        setStep(++currentStep)
                                    }}
                                >
                                Skip this step
                                </Title>
                                <FontAwesomeIcon size='2xl' icon={duotone("arrow-right-long-to-line")} style={{"--fa-primary-color": "#0077ce", "--fa-secondary-color": "#20a0ff",paddingLeft: "10px"}} />
                                </>
                                )}
                            </Col>
                            <Col xxl={7} lg={8} md={8} style={{textAlign: "end"}}>
                                {step < 3 && !isEditMode ? (
                                    <Button
                                        className="btn-create"
                                        htmlType="submit"
                                        type="ocm-default"
                                        size="large"
                                        onClick={() => {
                                            let currentStep = step;
                                            setStep(++currentStep)
                                        }}
                                    >
                                        Next
                                    </Button>
                                ) : (
                                    parseInt(step) === 3 && !isEditMode ? (
                                        <div></div>
                                    ) : (
                                        isEditMode && editType === "Import Excel Map"
                                            && editType === "Import Excel" ? (
                                                <></>
                                            ) : (
                                            step === 3 && isEditMode && editType === "Manual" &&
                                            <Button
                                                className="btn-create"
                                                htmlType="submit"
                                                type={currentDoneTheme}
                                                size="large"
                                                onClick={() => {
                                                    if (hasDone)
                                                    {
                                                        generateAudienceData();
                                                    }
                                                    else
                                                    {
                                                        openError('top', 'Save Audiences', "Required fields are missing!");
                                                    }
                                                }}
                                            >
                                                Done 
                                            </Button>
                                        )   
                                    )
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
            </TargetAudienceStyle>}
        </Modal>
        </>
    );
}

export default AddAudienceModel;
