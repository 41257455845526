import React, { useState, useEffect } from 'react';
import { FreeResourceContentContainer, FreeResourceSearchWrapper, FreeResourceTopSection } from './FreeResources.styles';
import FreeResourceSearchBar from './FreeResourceSearchBar';
import FreeResourceSortIcon from './FreeResourceSortIcon';
import FreeResourceList from './FreeResourceList';

const FreeSearchAndResourceContainer = ({category}) => {
    const [sortOrder, setSortOrder] = useState({
      field: "title",
      order: "ASC"
    });

    const [filterString, setFilterString] = useState("");

    const setSort = (sort) => {
      setSortOrder(sort);
    }

    const setFilter = (filter) => {
      setFilterString(filter);
    }

    useEffect(() => {
      if (Object.entries(category).length !== 0)
      {
        setSortOrder({
          field: "title",
          order: "ASC"
        });
        setFilterString("");
      }
    }, [category]);

    return (
        <FreeResourceContentContainer>
          <FreeResourceTopSection>
            <FreeResourceSearchWrapper>
              <FreeResourceSearchBar category={category} setFilter={setFilter} />
            </FreeResourceSearchWrapper>
            <FreeResourceSortIcon category={category} setSort={setSort} />
          </FreeResourceTopSection>
          <FreeResourceList category={category} sort={sortOrder} filter={filterString} />
        </FreeResourceContentContainer>
    );
}

export default FreeSearchAndResourceContainer;