import React, { useCallback, useEffect, useReducer } from 'react';
import { Row, Col, Skeleton, Card, Space } from 'antd';

import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { getToolsConfigurations } from "../../../redux/tools/actionCreator";
import { ToolsHomePage, ToolsConfigurationPanel } from '../Style';
import { Main } from '../../styled';
import ToolInfoHeader from './ToolInfoHeader';
const { theme } = require('../../../config/theme/themeVariables');

const ToolInfo = (props) => {
  const [toolConfigData, setToolConfigData] = useState({
    loaded: false,
    toolInfo: [],
  });


  const [isFreeAccount, setIsFreeAccount] = useState(false);

  const reducer = (state, action) => {
    switch (action.type) {
      case 'TOOLS_CONFIGURATION_ERR':
        return { ...state, error: true, ocmToolsMessage: action.err };
      case 'RESET':
        return state;
      default:
        return state;
    }
  };

  const ocmToolsResponse = {
    error: false,
    ocmToolsMessage: '',
  };

  const [userToolsError, ocmToolsResponses] = useReducer(reducer, ocmToolsResponse);
  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    ocmToolsResponses({ type: 'RESET' });
    dispatch(
      getToolsConfigurations({ tools_key: props.tool.tools_key }, (response) => {
        if (response.status === 200) {
          setIsFreeAccount(response.data.isFreeAccount);
          setToolConfigData({
            loaded: true,
            toolInfo: response.data.tools,
          });
        } else {
          ocmToolsResponses(response);
        }
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    setToolConfigData({
      loaded: false,
      toolInfo: []
    });
    // if (props.setOpenKeys !== undefined && typeof props.setOpenKeys === 'function')
    // {
    //   if (defaultActiveKey === "target-audience")
    //   {
    //     props.setOpenKeys(['tools-dashboard-audience']);
    //   }
    //   else
    //   {
    //     props.setOpenKeys(['tools-dashboard-ocm-tools']);
    //   }
    // }
    const retrieveData = async () => {
      fetchData();
    }
    retrieveData()
      .catch(console.error);
  }, []);

  return (
    <>
      <Main>
        <ToolsHomePage>
          {!toolConfigData.loaded ? (
            <Row gutter={25}>
              <Col xxl={24} xl={24} md={24} className={`item-tools`}>
                <Card className='loadmoretool-list' style={{ minHeight: "600px" }}>
                  <Row align={'middle'} justify={'center'} gutter={[25, 25]} style={{ marginTop: "20px" }}>
                    <Col xxl={8}>
                      <Skeleton.Input loading active size={'large'} block={Card} />
                    </Col>
                  </Row>
                  <Row gutter={[25, 25]} style={{ marginTop: "20px" }}>
                    {(() => {
                      let skeletons = [];
                      for (let i = 1; i <= 8; i++) {
                        skeletons.push(
                          <Col key={`skeletons-${i}`} xxl={3} md={6} className={`item-tools`}>
                            <Card className='loadmoretool-list'>
                              <Skeleton active loading round>
                                <Card className='tools-card'></Card>
                              </Skeleton>
                            </Card>
                          </Col>
                        );
                      }
                      return skeletons;
                    })()}
                  </Row>
                  <Row gutter={[25, 25]}>
                    <Col xxl={24} xl={24} md={24} className={`item-tools`} style={{ marginTop: "10px" }}>
                      <Card className='loadmoretool-list'>
                        <Space>
                          <Skeleton.Button loading active size={'small'} shape={'square'} block={Card} />
                          <Skeleton.Avatar loading active size={'small'} shape={'circle'} />
                          <Skeleton.Input loading active size={'small'} />
                        </Space>
                        <br />
                        <br />
                        <Skeleton.Button loading active size={'small'} shape={'square'} block={Card} />
                        <br />
                        <br />
                        <Skeleton.Input loading active size={'small'} block={Card} />
                      </Card>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          ) : (
            <ToolsConfigurationPanel theme={theme}>
              <Row gutter={[25, 25]}>
                <Col span={24}>
                  <ToolInfoHeader
                    info={toolConfigData.toolInfo}
                    isFreeAccount={isFreeAccount}
                    {...props}
                  />
                </Col>
              </Row>
            </ToolsConfigurationPanel>
          )}
        </ToolsHomePage>
      </Main>
    </>
  );
}

export default ToolInfo;
