import { useState } from 'react';
import { Input, Form, Tag, Button, Popconfirm, Typography, Popover, Spin } from 'antd';
import Popup from '../../../../ui-elements/Popup';
import { useTargetAudienceDispatch, useTargetAudienceState } from '../../../context/targetAudience';
import styled from 'styled-components';
import { faArrowTurnDownLeft, faMinusCircle, faPencil, faPlusCircle, faQuestionCircle } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { api } from '../../../../../config/dataService/useApi';
import { reducerTypes } from '../../../context/targetAudience/constants';

const { Title, Text } = Typography;

const StakeholderModal = ({ open, close }) => {
  const [loading, setLoading] = useState(false);
  const { stakeholders } = useTargetAudienceState();
  const [addStakeholderModal, setAddStakeholderModal] = useState(false);
  const [form] = Form.useForm();
  const [input, setInput] = useState({});
  const [editableInputs, setEditableInputs] = useState(Array(stakeholders.length).fill(false));
  const [confirmLoadingDeleteButton, setConfirmLoadingDeleteButton] = useState(false);
  const dispatch = useTargetAudienceDispatch();
  const handleInputClick = (index) => {
    setEditableInputs((prevEditableInputs) => {
      const newEditableInputs = [...prevEditableInputs];
      newEditableInputs[index] = true;
      return newEditableInputs;
    });
  };

  const handleClose = () => {
    handleOpenChange(false);
    setTimeout(() => close(), 100)
  };
  const handleOpenChange = (newOpen) => {
    setAddStakeholderModal(newOpen);
  };

  const updatedStakeholderTypes = async (index, stakeholderTypeId) => {
    setEditableInputs((prevEditableInputs) => {
      const newEditableInputs = [...prevEditableInputs];
      newEditableInputs[index] = false;
      return newEditableInputs;
    });

    const isValueUpdated =
      stakeholders.find((i) => i.id === stakeholderTypeId).stakeholderName !== input[stakeholderTypeId] &&
      input[stakeholderTypeId] !== '' &&
      input[stakeholderTypeId] !== undefined;

    if (isValueUpdated) {
      setLoading(true);
      const res = await api('/audiences/update-stakeholder-types', 'post', {
        newStakeholderTypeName: input[stakeholderTypeId],
        stakeholderTypeId,
      });

      if (res.status === 200) {
        const filterted = stakeholders.map((i) =>
          i.id === stakeholderTypeId ? { ...i, stakeholderName: res.data.stakeholderTypeData.stakeholderName } : i,
        );

        dispatch({
          type: reducerTypes.STAKEHOLDER_TYPES,
          payload: filterted,
        });

        setInput({ ...input, [stakeholderTypeId]: '' });
      }

      setLoading(false);
    }
  };

  const deleteStakeholder = async (stakeholderTypeId) => {
    const res = await api('/audiences/delete-stakeholder-types', 'post', { stakeholderTypeId });
    if (res.status === 200) {
      const filterted = stakeholders.filter((i) => i.id !== stakeholderTypeId);

      dispatch({
        type: reducerTypes.STAKEHOLDER_TYPES,
        payload: filterted,
      });
    }
  };
  const onFinish = async (values) => {
    const response = await api('/audiences/save-stakeholder-types', 'post', values);

    dispatch({
      type: reducerTypes.STAKEHOLDER_TYPES,
      payload: [...stakeholders, ...response.data.stakeholderTypeData],
    });

    setAddStakeholderModal(false);

    form.resetFields();
  };

  const InputForm = (
    <Form form={form} name={`stackholder`} layout="vertical" onFinish={onFinish}>
      <StyledFormItem labelCol={{ style: { color: '#fff' } }} label="Stakeholder Name" name="stakeholderName">
        <Input style={{ width: '250px' }} />
      </StyledFormItem>
      <div style={{ display: 'flex', justifyContent: 'end', gap: '10px', width: '100%' }}>
        <Button onClick={() => handleOpenChange(false)} htmlType="button">
          Cancel
        </Button>
        <Button style={{ backgroundColor: '#00B0F0' }} type="primary" htmlType="submit">
          Save
        </Button>
      </div>
    </Form>
  );

  return (
    <Popup zIndex={1001} width="60%" open={open} close={handleClose} footer={null} title={'Stakeholder Types'}>
      <Container>
        <StyledFrom>
          {stakeholders.map((stakeholder, index) => (
            <FormItem key={index}>
              {editableInputs[index] ? (
                <Input
                  defaultValue={stakeholder.stakeholderName}
                  autoFocus
                  onFocus={() => handleInputClick(index)}
                  onBlur={() => updatedStakeholderTypes(index, stakeholder.id)}
                  onChange={(e) => setInput({ ...input, [stakeholder.id]: e.target.value })}
                  onPressEnter={() => updatedStakeholderTypes(index, stakeholder.id)}
                />
              ) : (
                <StyledTag onClick={() => handleInputClick(index)}>{stakeholder.stakeholderName}</StyledTag>
              )}
              <DeleteButton>
                <Popconfirm
                  overlayClassName="deletePopUp"
                  title={
                    <Title level={2} type={'danger'}>
                      Delete
                    </Title>
                  }
                  icon={
                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      size="2xl"
                      style={{
                        '--fa-primary-color': '#ff8080',
                        '--fa-secondary-color': '#ff8080',
                        marginRight: '20px',
                        paddingTop: '10px',
                        cursor: 'pointer',
                      }}
                    />
                  }
                  description={
                    <div style={{ width: '100%' }}>
                      <p>
                        <Text style={{ fontSize: '23px', paddingBottom: '20px' }} italic>
                          When deleting this Stakeholder Type, it will delete from all individuals.
                        </Text>
                      </p>
                      <p>
                        <Text type="secondary" style={{ fontSize: '23px', paddingBottom: '20px' }}>
                          Are you sure you want to proceed?
                        </Text>
                      </p>
                    </div>
                  }
                  onConfirm={() => deleteStakeholder(stakeholder.id)}
                  onCancel={() => setConfirmLoadingDeleteButton(false)}
                  okText="Delete"
                  cancelText="Cancel"
                  okButtonProps={{
                    danger: true,
                    loading: confirmLoadingDeleteButton,
                    shape: 'round',
                    size: 'large',
                  }}
                  cancelButtonProps={{
                    danger: false,
                    shape: 'round',
                    size: 'large',
                    type: 'default',
                  }}
                >
                  <FontAwesomeIcon
                    icon={faMinusCircle}
                    style={{
                      '--fa-primary-color': '#fff',
                      '--fa-secondary-color': '#ff8080',
                      '--fa-secondary-opacity': 1,
                      cursor: 'pointer',
                    }}
                    color="#FF5D5F"
                  />
                </Popconfirm>
              </DeleteButton>
              <EditButton>
                {!editableInputs[index] ? <FontAwesomeIcon
                  style={{
                    '--fa-primary-color': ' #0080ff',
                    '--fa-secondary-color': '#0080c0',
                    '--fa-secondary-opacity': 1,
                    cursor: 'pointer',
                    zIndex: 11111,
                  }}
                  onClick={() => handleInputClick(index)}
                  icon={faPencil}
                  color="#FF5D5F"
                /> :
                  <FontAwesomeIcon style={{
                    '--fa-primary-color': ' #0080ff',
                    '--fa-secondary-color': '#0080c0',
                    '--fa-secondary-opacity': 1,
                    cursor: 'pointer',
                    zIndex: 11111,
                  }} icon={faArrowTurnDownLeft}
                    onClick={() => updatedStakeholderTypes(index)}
                  />}
              </EditButton>
            </FormItem>
          ))}
          <Popover
            content={InputForm}
            color="#0066B3"
            title=""
            trigger="click"
            open={addStakeholderModal}
            onOpenChange={() => handleOpenChange(true)}
            style={{ zIndex: 99999999 }}
          >
            <FontAwesomeIcon
              style={{
                '--fa-primary-color': `#fff`,
                '--fa-secondary-color': `#0066B3`,
                '--fa-secondary-opacity': '1',
                cursor: 'pointer',
              }}
              icon={faPlusCircle}
              size="2xl"
            />
          </Popover>
        </StyledFrom>
      </Container>
      {loading && (
        <LoadingContainer>
          <Spin spinning={loading} fullscreen={true} />
        </LoadingContainer>
      )}
    </Popup>
  );
};

const Container = styled.div`
  width: 100%;
  min-height: 200px;
`;

const StyledFrom = styled.div`
  display: flex;
  width: 100%;
  gap: 1%;
  row-gap: 12px;
  flex-wrap: wrap;
`;

const StyledTag = styled(Tag)`
  padding: 5px;
  width: 100%;
  text-align: center;
`;

const FormItem = styled.div`
  width: 24%;
  position: relative;
`;

const DeleteButton = styled.div`
  position: absolute;
  right: -5px;
  top: -7px;
`;
const EditButton = styled.div`
  position: absolute;
  top: 8px;
  right: 2px;
`;

const StyledFormItem = styled(Form.Item)`
  .ant-form-item-label label {
    color: #fff;
  }
`;
const LoadingContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -57%;
  height: 157%;
  width: 100%;
  background-color: #8080804a;
`;

export default StakeholderModal;
