
import { Typography, notification } from 'antd'
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import React, { useCallback, useReducer, useState, useEffect } from 'react';
import Cookies from "js-cookie";

import { convertToTrial, verifyUser } from '../../../../redux/authentication/actionCreator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { saveLoginUserCookie } from '../../../../utility/utility';
import useNotificationController from '../../../../utility/useNotificationController';
const { Title } = Typography;

const organizationalSlug = localStorage.getItem('organizationalSlug');

const Subscription = ({ onClose }) => {
  const {
    contextHolder,
    openError,
    openSuccess,
  } = useNotificationController();

  const [isFreeAccount, setIsFreeAccount] = useState(false);
  const [isCancelledAccount, setIsCancelledAccount] = useState(false);
  const [cancellationDate, setCancellationDate] = useState("");
  const [currentSlug, setCurrentSlug] = useState(localStorage.getItem('organizationalSlug'));

  const initialState = {
    error: false,
    messages: ""
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'VERIFY_USER_ERR':
        return { ...state, error: true, messages: action.err };
      case 'CONVERT_TO_TRIAL_ERR':
        return { ...state, error: true, messages: action.err };
      case 'RESET':
        return state;
      default:
        return state;
    }
  };

  const [apiError, apiResponseCallback] = useReducer(reducer, initialState);

  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    apiResponseCallback({ type: 'RESET' });
    dispatch(
        verifyUser({}, async (response) => {
          if (response.status === 200) {
            let organizationalSlug = response.data.organizationalSlug;
            let cancelledAccount = response.data.isCancelled;
            let cancellationDate = response.data.cancellationDate;
            let freeAccount = response.data.isFreeAccount;
            setCurrentSlug(organizationalSlug);
            setIsCancelledAccount(cancelledAccount);
            setCancellationDate(cancellationDate);
            setIsFreeAccount(freeAccount);
          } 
          else 
          {
            openError('topLeft', 'Error', response.err);
            apiResponseCallback(response);
          }
        }),
      );
  }, [dispatch, 
    isFreeAccount,
    isCancelledAccount,
    cancellationDate]);

  const convertToTrialAccount = useCallback(async () => {
    const rememberMe = Cookies.get('rememberMe');
    apiResponseCallback({ type: 'RESET' });
    dispatch(
      convertToTrial({rememberMe: rememberMe},(response) => {
        if (response.status === 200) {
          localStorage.clear();
          saveLoginUserCookie(response);
          openSuccess('bottomRight', "Convert to Free user to Trial User", `Your Account is successfully converted to Trial Account`);
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else {
          openError("topLeft", "Convert to Free user to Trial User", response.err)
          apiResponseCallback(response);
        }
      }),
    );
  }, [dispatch]);

  const convertTrial = () => {
    convertToTrialAccount();
  }

  useEffect(() => {
   const retrieveData = async () => {
      await fetchData();
    }
    retrieveData().catch(console.error);
  }, []);

  const is_amazon = localStorage.getItem('is_amazon') === 'true';
  return (
    <div className='overlay-free'>
      {contextHolder}
      <div style={{ background: `${is_amazon ? 'rgb(255, 164, 141)' : '#00C4FA'}`, margin: "0 auto", width: "50%", maxHeight: "300px", display: "flex", position: "absolute", justifyContent: "center", alignItems: "center", fontSize: "23px", boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.75)" }}>
        <div style={{ width: "100%", textAlign: "center", color: `${is_amazon ? 'rgb(218, 77, 41)' : '#FFFFFF'}`, padding: "50px" }}>
          {isFreeAccount ? (
            isCancelledAccount ? (
              <div style={{ color: `${is_amazon ? 'rgb(218, 77, 41)' : '#FFFFFF'}`, fontSize: "25px" }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", lineHeight: "40px" }}>
                  <FontAwesomeIcon icon={duotone('xmark')} style={{ position: 'absolute', top: '15px', right: '15px', cursor: 'pointer' }} onClick={onClose} />
                  <div style={{color: `${is_amazon ? 'rgb(218, 77, 41)' : '#FFFFFF'}`, fontSize: "25px"}}>Your subscription was cancelled on <strong>{cancellationDate}</strong>. <br />Click here to. 
                    <Title level={3} style={{color: `${is_amazon ? 'rgb(218, 77, 41)' : '#FFFFFF'}`}} underline italic>
                      <Link to={`/${organizationalSlug}/subscription/restart-subscription`} style={{color: `${is_amazon ? 'rgb(218, 77, 41)' : '#FFFFFF'}`}}>
                          Restart your subscription again
                      </Link>
                    </Title>
                  </div>
                </div>
              </div>
            ) : (
              !isFreeAccount ? '' :
              <>
                <div style={{ color: `${is_amazon ? 'rgb(218, 77, 41)' : '#FFFFFF'}`, fontSize: "25px" }}>You're not a subscribed user.
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <FontAwesomeIcon icon={duotone('xmark')} style={{ position: 'absolute', top: '5px', right: '5px', cursor: 'pointer' }} onClick={onClose} />
                    <Title
                      level={3}
                      style={
                        {
                          color: `${is_amazon ? 'rgb(218, 77, 41)' : '#FFFFFF'}`,
                          margin: 0,
                          paddingLeft: "30px",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          cursor: "pointer"
                        }
                      } underline italic
                      onClick={convertTrial}>Start your free trial today!</Title>
                  </div>
                </div>
                <Title level={2} style={{ fontSize: "45px", color: `${is_amazon ? 'rgb(218, 77, 41)' : '#FFFFFF'}`, marginTop: 0 }}>or</Title>
                <Link 
                  to={`/${organizationalSlug}/tools-dashboard/free-resources`}
                >
                  <Title level={3} style={{ color: `${is_amazon ? 'rgb(218, 77, 41)' : '#FFFFFF'}` }} underline>visit the Free Tools Dashboard.</Title>
                </Link>
              </>
            )
          ) : ''}
        </div>
      </div>
    </div>
  )
}

export default Subscription
