import Styled from 'styled-components';

const InfoWraper = Styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    ul {
        display: flex;
        flex-direction: row;
        li {
            padding: ${window.innerWidth > 1490 ? '15px 15px' : '12px 12px'};
            list-style: none;
            display: flex;
            align-items: center;
            svg {
                font-size: ${window.innerWidth > 1490 ? '1.5rem' : '1.2rem'};
            }
            .ant-avatar-lg {
                width: ${window.innerWidth > 1490 ? '40px' : '30px'};
                height: ${window.innerWidth > 1490 ? '40px' : '30px'};
                line-height: ${window.innerWidth > 1490 ? '38px' : '28px'};
            }
        }
    }
    .ocmsolution-nav-action-link{
        text-decoration: none;
        color: ${({ theme }) => theme[theme.mainContent].secondary};
        box-shadow: none;
        padding: 0px 8px;
    }
    
    .ocmsolution-nav-actions__item{
        .ant-badge{
            .ant-badge-dot{
                top: 4px;
                ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 11px !important;
            }
        }
        &.ocmsolution-nav-actions__message{
            .ant-badge{
                .ant-badge-dot{
                    background: ${({ theme }) => theme[theme.mainContent].success};
                }
            }
        }
        svg{
            color: ${({ theme }) => theme[theme.mainContent]['gray-text']};
        }
    }
    .ocmsolution-nav-actions__message, 
    .ocmsolution-nav-actions__notification, 
    .ocmsolution-nav-actions__settings, 
    .ocmsolution-nav-actions__support, 
    .ocmsolution-nav-actions__flag-select,
    .ocmsolution-nav-actions__language,
    .ocmsolution-nav-actions__nav-author{
        display: flex;
        margin: 0 5px;
        span, a{
            display: block;
            line-height: normal;
        }
    }
    .ocmsolution-nav-actions__nav-author{
        a.ant-dropdown-trigger{
            img{
                max-width: 20px;
            }
        }
    }

    .flag-select{
        padding-bottom: 0;
        .flag-select__option{
            margin: 0;
            img{
                top: 0;
            }
        }
        .flag-select__btn{
            line-height: 0;
            padding-right: 0;
            cursor: pointer;
        }
        .flag-select__btn:after{
            content: none;
        }
        .flag-select__options{
            width: 120px;
            padding-top: 0;
            margin: 0;
            right: 0;
            top: 30px;
            display: block;
            .flag-select__option{
                line-height: normal;
                display: block;
                padding: 5px 10px;
                span{
                    width: auto !important;
                    height: auto !important;
                    display: block;
                }
            }
        }
    }

    .flag-select {
        ul{
            width: 170px !important;
            padding: 12px 0;
            background: #fff;
            border: 0 none;
            box-shadow: 0 5px 30px ${({ theme }) => theme[theme.mainContent]['gray-text']}15;
            li{
                &:first-child{
                    margin-top: 12px;
                }
                &:hover{
                    background: ${({ theme }) => theme['primary-color']}05;
                }
                span{
                    display: flex !important;
                    align-items: center;
                    padding: 2px 10px;
                    img{
                        border-radius: 50%;
                    }
                    span{
                        font-weight: 500;
                        color: ${({ theme }) => theme[theme.mainContent]['gray-text']};
                        padding: 0;
                        margin-left: 10px;
                    }
                }
            }
        }
    }
`;

const PopoverStyle = Styled.div`
    backgroundColor: ${({ theme }) =>
      theme['user-theme']['backgroundColor'] === undefined ? '#CDCDCD' : theme['user-theme']['backgroundColor']};
`;

const UserDropDown = Styled.div`
    .user-dropdown{
        max-width: 680px;
        .user-info-panel {
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
            position: relative;
            border-bottom: 3px solid #fff;
            h1 {
                padding-left: 10px;
                color: #fff;
                margin-top: 45px;
            }
            span.ant-tag {
                position: absolute;
                top: 10px;
                right: 0;
                background: #fff;
            }
            p {
                font-size: 14px;
                font-weight: 400;
                line-height: 2;
            }
            .ant-avatar {
                margin-top: 45px;
            }
        }
        .user-dropdown__info{
            display: flex;
            align-items: flex-start;
            padding: 20px 25px;
            border-radius: 8px;
            margin-bottom: 12px;
            background: transparent;
            img{
                ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 15px;
            }
            figcaption{
                h1{
                    font-size: 14px;
                    margin-bottom: 2px;
                    color:  ${({ theme }) => theme[theme.mainContent]['dark-text']};
                }
                p{
                    margin-bottom: 0px;
                    font-size: 13px;
                    color: ${({ theme }) => theme[theme.mainContent]['gray-text']};
                }
            }
        }
        .user-dropdown__links{
            a{
                width: 100%;
                left: 0;
                right: 0;
                display: inline-flex;
                align-items: center;
                padding: 10px 12px;
                font-size: 14px;
                transition: .3s;
                color: #FFF;
                &:hover{
                    background: ${({ theme }) => theme['primary-color']}05;
                    color: #FFF;
                    ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 22px;
                }
                svg{
                    width: 16px;
                    transform: ${({ theme }) => (theme.rtl ? 'rotateY(180deg)' : 'rotateY(0deg)')};
                    ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 14px;
                }
            }
        }
        .user-dropdown__bottomAction{
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-weight: 500;
            text-align: center;
            position: relative;
            width: calc(100% + 30px);
            left: -15px;
            right: -15px;
            height: calc(100% + 15px);
            bottom: -15px;
            border-radius: 0 0 6px 6px;
            padding: 15px 0;
            background: ${({ theme }) => theme[theme.mainContent]['general-background']};
            color: #FFF;
            svg{
                width: 34px;
                height: 24px;
                transition: color 0.3s;
            }
            &:hover{
                color: ${({ theme }) => theme['primary-color']};
                svg{
                    color: ${({ theme }) => theme['primary-color']};
                }
            }
        }
    }
`;

const FeedBackStyle = Styled.div`
    background:white !important;
    padding: 30px;
    color:#63BFB4;

   
    .ocmsolution-top-dropdown__title .title-text {        
        ${({ theme }) => (!theme.rtl ? 'margin-right' : 'margin-left')}: 10px;
    }
    .ocmsolution-top-dropdown__nav {
        a:hover {
            h1 {
                color: #3A3540;
            }
        }
    }
    .ocmsolution-top-dropdown__content {
        figcaption{
            .ocmsolution-top-dropdownText{
                min-width: 216px;
                ${({ theme }) => (!theme.rtl ? 'margin-right' : 'margin-left')}: 15px;
            }
            span{
                ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 0;
            }
        }
        .notification-icon{
            width: 39.2px;
            height: 32px;
            ${({ theme }) => (!theme.rtl ? 'margin-right' : 'margin-left')}: 15px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            &.bg-primary{
                background: ${({ theme }) => theme['primary-color']}15;
                color: ${({ theme }) => theme['primary-color']};
            }
            &.bg-secondary{
                background: ${({ theme }) => theme[theme.mainContent].secondary}15;
                color: ${({ theme }) => theme[theme.mainContent].secondary};
            }
            svg{
                width: 18px;
                height: 18px; 
            }
        }
        .notification-content{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .notification-text h1 {
        font-size: 14px;
        font-weight: 400;
        // color: #5A5F7D;
        color: #FFF;
        margin-bottom: 4px;
    }

    .notification-text h1 span {
        color: #5F63F2;
        //color: #FFFFFF;
        font-weight: 500;
        ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 0;
    }

    .notification-text p {
        font-size: 12px;
        color: #ADB4D2;
        //color: #FFFFFF;
        margin-bottom: 0;
        text-align: ${({ theme }) => (!theme.rtl ? 'left' : 'right')}
    }
`;

export {
  InfoWraper,
  FeedBackStyle,
  UserDropDown,
  PopoverStyle,
};
