import { reducerTypes } from './constants';

export const reportsReducer = (state, action) => {
  switch (action.type) {
    case reducerTypes.INIT:
      return {
        ...state,
        ...action.payload,
      };

    case reducerTypes.FILTER:
      return {
        ...state,
        filter: action.payload,
      };

    case reducerTypes.OPTIONS:
      return {
        ...state,
        options: action.payload,
      };
    case reducerTypes.SELECTED_FILTERS:
      return {
        ...state,
        selectedFilters: action.payload,
      };

    case reducerTypes.RELOAD:
      return {
        ...state,
        reload: action.payload,
      };

    case reducerTypes.REPORTS_DESCRIPTIONS:
      return {
        ...state,
        reportsDescriptions: action.payload,
      };
    
    case reducerTypes.REPORT_PDF_EXPORT:
      return {
        ...state,
        reportsPDFExport: action.payload,
      };

    default:
      throw new Error(`Can't get type.`);
  }
};
