import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import App from './App';
import './i18n/config';
import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>,
);

const originalConsoleError = console.error; // Save the original console.error method

console.error = (message) => {
  if (message.indexOf('defaultProps will be removed') !== -1) {
    return; // If the message matches the warning we want to suppress, return without calling console.error
  }
  if (message.indexOf('Warning: findDOMNode') !== -1) {
    return; // If the message matches the warning we want to suppress, return without calling console.error
  }
  if (message.indexOf('incorrect casing') !== -1) {
    return; // If the message matches the warning we want to suppress, return without calling console.error
  }
  originalConsoleError(message); // Call the original console.error method for other messages
};
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
