import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Modal } from 'antd';
import styled from 'styled-components';
import { ReportsIcon } from '../../components/reportsIcon/ReportsIcon';

const Popup = ({ title, children, close, open, footer, width = 'auto', showIcon , zIndex}) => {
  const handleOk = () => {
    close();
  };

  const handleCancel = () => {
    close();
  };

  return (
    <SurveyPopup>
    <Modal
    style={{
      margin: '20px 0px',
    }}
      closeIcon={<FontAwesomeIcon size="2xl" icon={duotone('circle-xmark')  } />}
      centered
      footer={footer}
      okType=""
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      width={width}
      title={
        <OCMModalHeader>
           <div className="user-info-header-panel">
              <h1 className="header-title">
               {showIcon ?<ReportsIcon style={{ border: '3px solid #004D86', borderRadius: '7px' }} /> : ''} {title}
              </h1>
              <div className="ribon none"></div>
            </div>
        </OCMModalHeader>
      }
      zIndex={zIndex}
      wrapClassName='ocm-modal'
    >
      <PopupContainer>
        
        {children}
      </PopupContainer>
    </Modal>
    </SurveyPopup>
  );
};


const PopupContainer = styled.div`
  background-color: #fff;
  width: auto;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 20px;
`;

const Heading = styled.div`
  background-color: #166FBE;
  padding: 15px 30px;
  width: 100%;
  h1 {
    color: #fff;
    margin: 0;
  }
`;



const OCMModalHeader = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    .user-info-header-panel {
        background: ${({ theme }) => theme['user-theme'] === undefined ? "#004D86" : theme['user-theme']['sidebarColor'] === undefined ? "#004D86" : theme['user-theme']['sidebarColor'] } !important;
        margin-top: 40px;
        margin-left: 10px;
        width: 99%;
        padding: 20px;
        display: flex;
        justify-content: left;
        flex-direction: column;
        align-items: left;
        position: relative;
        margin-right: -10px;
        h1.header-title {
            font-weight: 500;
            font-size: 32px;
            padding-left: 20px;
            margin-bottom: 0;
            color: ${({ theme }) => theme['user-theme'] === undefined ? "#FFFFFF" : theme['user-theme']['sidebarTextColor'] === undefined ? "#FFFFFF" : theme['user-theme']['sidebarTextColor'] } !important;
            display: flex;
            align-items: center;
            gap: 15px;
        }
        h1.header-audience-name-title {
            font-weight: 500;
            font-size: 40px;
            padding-left: 20px;
            margin-bottom: 0;
            color: ${({ theme }) => theme['user-theme'] === undefined ? "#004D86" : theme['user-theme']['EngagementPlanColor'] === undefined ? "#004D86" : theme['user-theme']['EngagementPlanColor'] } !important;
        }
        h3.header-audience-info {
            font-weight: 500;
            font-size: 20px;
            margin-bottom: 0;
            color: ${({ theme }) => theme['user-theme'] === undefined ? "#004D86" : theme['user-theme']['EngagementPlanColor'] === undefined ? "#004D86" : theme['user-theme']['EngagementPlanColor'] } !important;
        }
    }
`;

const SurveyPopup = styled.div`

.ocm-modal .ant-modal-content {
    padding: 0;
}
.ocm-modal-images .ant-modal {
    padding: 0;
    background-image: url('../../../../static/images/trarget_audience_left.png');
}
.ocm-modal .ant-modal-close {
    position: absolute;
    top: -14px;
    background: #0066B2;
    right: -15px;
    opacity: 1;
    width: 35px;
    color: #FFF;
    height: 35px;
    border-radius: 50%;
    cursor: pointer;
}
.ocm-modal .ant-modal-footer {
    padding-bottom: 30px;
}
.ant-modal-footer button[buttonSize='exta-large']{
    padding: 80px !important;
}
.default-icon {
    background: #0066B2;
    opacity: 1;
    width: 35px;
    color: #FFF;
    height: 35px;
    border-radius: 50%;
    cursor: pointer;
}
.ocm-modal .ant-modal-close:hover {
    background: rgb(0, 77, 134) !important;
    color: #FFF;
}
.ocm-modal .ribon {
    inset-inline-end: 0;
    border-inline-end-color: transparent;
    border-block-end-color: transparent;
    position: absolute;
    top: 100%;
    width: 8px;
    height: 8px;
    color: currentcolor;
    border: 4px solid;
    transform: scaleY(.75);
    transform-origin: top;
    filter: brightness(75%);
}

.ocm-modal-all-blue .ant-modal-content {
    padding: 0;
    background-color: #0066B2;
}

.ocm-modal-all-blue .button-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ocm-modal-all-blue .ant-btn-ocm-blue-modal-button {
    background-color: #0066B2;
    border-radius: 50px !important;
    padding-left: 30px;
    padding-right: 30px;
    font-weight: 600;
    font-size: 20px;
    color: #fff;
    height: auto;
}

.ocm-modal-all-blue p {
    color: #FFFFFF;
}

.ocm-modal-all-blue p .champion-color{
    color: #7AC6FF;
}

.ocm-modal-all-blue .ant-modal-close {
    top: -10px;
    background: #fff;
    right: -12px;
    opacity: 1;
    width: 35px;
    height: 35px;
    border-radius: 50%;
}
`
export default Popup;
