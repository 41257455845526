import React from "react";
import { BackToLisPublictWrapper, ButtonContent } from "./LibrarySection.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowUp } from "@fortawesome/pro-regular-svg-icons";

const BackToListButtonPublic = ({backtoButton}) => {
    const goToPosition = (e) => {
        e.preventDefault();
        e.stopPropagation();
        window.scrollTo({
        behavior: 'smooth',
        top:
            document.querySelector(`#${backtoButton}`).getBoundingClientRect().top -
            document.body.getBoundingClientRect().top - 100,
        });
    }

    return (
        <BackToLisPublictWrapper onClick={goToPosition}>
            <ButtonContent>
                <FontAwesomeIcon icon={faCircleArrowUp} />
                <span>Back to List</span>
            </ButtonContent>
        </BackToLisPublictWrapper>
    );
};


export default BackToListButtonPublic;