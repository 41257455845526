import { useEffect, useState } from 'react';
import { DataService } from '../../../config/dataService/dataService';
import { formatTrainingsTableData } from '../utils/utils';
import { useReportsState } from '../context/reports';

const useMetricsData = () => {
  const {
    filter: {
      projects: selectedProject,
      groups: selectedGroupItems,
      locations: selectedLocationItems,
      jobs: selectedJobRoles,
      stakeholders: selectedStakeHolderItems,
      audiences: selectedAudience,
      changeTypes: selectedChangeTypes,
    },
  } = useReportsState();
  const [sponsorEnthusiasm, setSponsorEnthusiasm] = useState({ loading: true, data: {} });
  const [adoptionRisk, setAdoptionRisk] = useState({ loading: true, data: 0 });
  const [stats, setStats] = useState({ loading: true, data: {} });
  const [impactAudienceChanges, setImpaceAUdienceChanges] = useState({ loading: true, data: [] });
  const [stakeholderReceptiveness, setStakeholderReceptiveness] = useState({ loading: true, data: {} });
  const [trainingTimeline, setTrainingTimeling] = useState({ loading: true, data: {} });
  const [trainingCompletion, setTrainingCompletion] = useState({ loading: true, data: {} });
  const [noOfCommunicationByType, setNoOfCommunicationByType] = useState({ loading: true, data: [] });
  const [readinessLevelPerAudience, setReadinessLevelPerAudience] = useState({ loading: true, data: [] });
  const [adoptionScorePerRediness, setAdoptionScorePerAudience] = useState({ loading: true, data: [] });
  const [overallReadinessLevel, setOverallReadinessLevel] = useState({ loading: true, data: [] });
  const [coachingEffortsNeeded, setCoachingEffortsNeeded] = useState({ loading: true, data: [] });

  const getSponsorEnthusiasm = async () => {
    const {
      data: { sponsorRoleEnthusiasm },
    } = await DataService.post('/reports/get-metrices-sponsor-enthusiasm', {
      filters: {
        projects: selectedProject,
        groups: selectedGroupItems,
        audiences: selectedAudience,
        locations: selectedLocationItems,
        jobs: selectedJobRoles,
        stakeholders: selectedStakeHolderItems,
        changeTypes: selectedChangeTypes,
      },
    });
    setSponsorEnthusiasm({ loading: false, data: sponsorRoleEnthusiasm });
  };
  const getAdbptionRisk = async () => {
    const {
      data: { adoptopnRisk, tabledata },
    } = await DataService.post('/reports/get-metrices-adoption-risk', {
      selectedProject,
    });
    setAdoptionRisk({
      loading: false,
      data: {
        tabledata,
        riskLevel:
          !adoptopnRisk || adoptopnRisk == 'N/A'
            ? 0
            : adoptopnRisk == 'Low'
              ? 333 - 166
              : adoptopnRisk == 'Mid'
                ? 666 - 166
                : 1000 - 166,
      },
    });
  };

  const getStats = async () => {
    const {
      data: {
        impactedGroups,
        impactIndividuals,
        changeChampions,
        highImpactChanges,
        lowImpactChanges,
        midImpactChanges,
      },
    } = await DataService.post('/reports/get-metrices-stats', {
      filters: {
        projects: selectedProject,
        groups: selectedGroupItems,
        audiences: selectedAudience,
        locations: selectedLocationItems,
        jobs: selectedJobRoles,
        stakeholders: selectedStakeHolderItems,
        changeTypes: selectedChangeTypes,
      },
    });
    setStats({
      loading: false,
      data: {
        impactedGroups,
        impactIndividuals,
        changeChampions,
        highImpactChanges,
        lowImpactChanges,
        midImpactChanges,
      },
    });
  };

  const getImpactAudienceChanges = async () => {
    const { data } = await DataService.post('/reports/get-metrices-impace-audience-changes', {
      filters: {
        projects: selectedProject,
        groups: selectedGroupItems,
        audiences: selectedAudience,
        locations: selectedLocationItems,
        jobs: selectedJobRoles,
        stakeholders: selectedStakeHolderItems,
        changeTypes: selectedChangeTypes,
      },
    });

    setImpaceAUdienceChanges({ loading: false, data });
  };

  const getStakeholderReceptiveness = async () => {
    const response = await DataService.post('/reports/get-metrices-stakeholder-receptiveness', {
      filters: {
        projects: selectedProject,
        groups: selectedGroupItems,
        audiences: selectedAudience,
        locations: selectedLocationItems,
        jobs: selectedJobRoles,
        stakeholders: selectedStakeHolderItems,
        changeTypes: selectedChangeTypes,
      },
    });

    setStakeholderReceptiveness({ loading: false, data: response.data });
  };

  const getTrainingCompletion = async () => {
    const {
      data: { trainingCompletionData },
    } = await DataService.post('/reports/get-metrices-training-completion', {
      filters: {
        projects: selectedProject,
        groups: selectedGroupItems,
        audiences: selectedAudience,
        locations: selectedLocationItems,
        jobs: selectedJobRoles,
        stakeholders: selectedStakeHolderItems,
        changeTypes: selectedChangeTypes,
      },
    });

    setTrainingCompletion({ loading: false, data: trainingCompletionData });
  };

  const getNumberOfCommunication = async () => {
    const {
      data: { chartData, tableData },
    } = await DataService.post('/reports/get-number-of-communication', {
      filters: {
        projects: selectedProject,
        groups: selectedGroupItems,
        audiences: selectedAudience,
        locations: selectedLocationItems,
        jobs: selectedJobRoles,
        stakeholders: selectedStakeHolderItems,
        changeTypes: selectedChangeTypes,
      },
    });
    setNoOfCommunicationByType({ loading: false, data: { chartData, tableData } });
  };

  const getTrainingTimeline = async () => {
    const { data } = await DataService.post('/reports/get-training-timeline', {
      filters: {
        projects: selectedProject,
        groups: selectedGroupItems,
        audiences: selectedAudience,
        locations: selectedLocationItems,
        jobs: selectedJobRoles,
        stakeholders: selectedStakeHolderItems,
        changeTypes: selectedChangeTypes,
      },
    });

    setTrainingTimeling({ loading: false, data: { ...data, tabledata: formatTrainingsTableData(data?.tabledata) } });
  };

  const getAdoptionScorePerAudience = async () => {
    const {
      data: { adoptionRediness },
    } = await DataService.post('/reports/get-adoption-score-per-audience', {
      filters: {
        projects: selectedProject,
        groups: selectedGroupItems,
        audiences: selectedAudience,
        locations: selectedLocationItems,
        jobs: selectedJobRoles,
        stakeholders: selectedStakeHolderItems,
        changeTypes: selectedChangeTypes,
      },
    });
    setAdoptionScorePerAudience({ loading: false, data: adoptionRediness });
  };

  const getRedinessLevelPerAudience = async () => {
    const {
      data: { readinessLevelPerAudience },
    } = await DataService.post('/reports/get-metrices-readiness-level-per-audience', {
      filters: {
        projects: selectedProject,
        groups: selectedGroupItems,
        audiences: selectedAudience,
        locations: selectedLocationItems,
        jobs: selectedJobRoles,
        stakeholders: selectedStakeHolderItems,
        changeTypes: selectedChangeTypes,
      },
    });
    setReadinessLevelPerAudience({ loading: false, data: readinessLevelPerAudience });
  };

  const getOverallReadinessLevel = async () => {
    const {
      data: { data },
    } = await DataService.post('/reports/get-overall-readiness-level', {
      filters: {
        projects: selectedProject,
        groups: selectedGroupItems,
        audiences: selectedAudience,
        locations: selectedLocationItems,
        jobs: selectedJobRoles,
        stakeholders: selectedStakeHolderItems,
        changeTypes: selectedChangeTypes,
      },
    });

    setOverallReadinessLevel({ loading: false, data });
  };

  const getCoachingEffortNeeded = async () => {
    const {
      data: { coachingEffortsNeeded },
    } = await DataService.post('/reports/get-metrices-coaching-effort-needed', {
      filters: {
        projects: selectedProject,
        groups: selectedGroupItems,
        audiences: selectedAudience,
        locations: selectedLocationItems,
        jobs: selectedJobRoles,
        stakeholders: selectedStakeHolderItems,
        changeTypes: selectedChangeTypes,
      },
    });

    setCoachingEffortsNeeded({ loading: false, data: coachingEffortsNeeded });
  };

  useEffect(() => {
    getCoachingEffortNeeded();
    getOverallReadinessLevel();
    getRedinessLevelPerAudience();
    getAdoptionScorePerAudience();
    getTrainingTimeline();
    getNumberOfCommunication();
    getTrainingCompletion();
    getStakeholderReceptiveness();
    getImpactAudienceChanges();
    getStats();
    getSponsorEnthusiasm();

    if (selectedProject?.length === 1) {
      getAdbptionRisk();
    }
  }, [
    // selectedAudience?.length,
    selectedGroupItems,
    selectedJobRoles,
    selectedLocationItems,
    selectedProject,
    selectedStakeHolderItems,
    selectedChangeTypes,
  ]);

  return {
    sponsorEnthusiasm,
    adoptionRisk,
    stats,
    impactAudienceChanges,
    stakeholderReceptiveness,
    trainingTimeline,
    trainingCompletion,
    noOfCommunicationByType,
    readinessLevelPerAudience,
    adoptionScorePerRediness,
    overallReadinessLevel,
    coachingEffortsNeeded,
  };
};

export default useMetricsData;
