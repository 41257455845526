import React, { useState, useReducer, useEffect, useCallback, useRef } from 'react';
import { FreeOCMTrainingDivider, FreeOCMTrainingListTitle, FreeOCMTrainingListWrapper, FreeEmptyMessage, FreeScrollableListContainer } from './FreeLibrarySection.styles';
import FreeOCMTrainingItem from './FreeOCMTrainingItem';
import useNotificationController from '../../utility/useNotificationController';
import { useDispatch } from 'react-redux';
import { fetchTrainingPages } from '../../redux/cms/actionCreator';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { List } from 'antd';

const FreeOCMTrainingList = ({category, sort, filter}) => {
    const {
      contextHolder,
      openError,
    } = useNotificationController();

    const scrollContainerRef = useRef(null);

    const [pageNo, setPageNo] = useState(1);
    const [OCMTrainingPages, setOCMTrainingPages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [initLoading, setInitLoading] = useState(false);
    const [sortOrder, setSortOrder] = useState();
    const [filterString, setFilterString] = useState("");

    const initialState = {
        error: false,
        messages: ""
    };

    const reducer = (state, action) => {
      switch (action.type) {
        case 'FETCH_OCMTraining_PAGES_ERR':
          return { ...state, error: true,messages: action.err };
        case 'RESET':
          return state;
        default:
          return state;
      }
    };

    const [apiError, apiResponseCallback] = useReducer(reducer, initialState);
    const dispatch = useDispatch();

    const fetchPages = useCallback(async (category, page, sortField, filterData) => {
      setLoading(true);
      setInitLoading(true);
      if (page === undefined)
      {
        page = pageNo;
      }
      if (sortField === undefined)
      {
        sortField = sortOrder;
      }
      if (filterData === undefined)
      {
        filterData = filterString;
      }
      apiResponseCallback({ type: 'RESET' });
        dispatch(
            fetchTrainingPages({categoryId: category.id, page: page, sortOrder: sortField, filter: filterData},(response) => {
              if (response.status === 200) {
                setLoading(false);
                setInitLoading(false);
                setSortOrder(sortField);
                setFilterString(filterData);
                let pages = response.data.pages;
                let hasMore = response.data.hasMore;
                setHasMore(hasMore);
                if (parseInt(page) === 1)
                {
                  setPageNo(parseInt(page) + 1);
                  const tmpPages = [];
                  pages.map((item, index) => {
                    let thumbnailImage = "OCMS-logo-blue-text.svg";
                    if (item.thumbnailImage.toString().trim() === '')
                    {
                      if (item.featuredImage.toString().trim() === '')
                      {
                        thumbnailImage = "OCMS-logo-blue-text.svg";
                      }
                      else
                      {
                        thumbnailImage = item.featuredImage;
                      }
                    }
                    else
                    {
                      thumbnailImage = item.thumbnailImage;
                    }
                    tmpPages.push({
                      id: item.id,
                      title: item.title,
                      featuredImage: item.featuredImage,
                      slug: item.slug,
                      thumbnailImage: thumbnailImage,
                    })
                  });
                  setOCMTrainingPages(tmpPages);
                  if (scrollContainerRef.current) {
                    scrollContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
                    window.scrollTo(0, 0);
                  }
                }
                else
                {
                  setPageNo(parseInt(page) + 1);
                  const tmpPages = [];
                  pages.map((item, index) => {
                    let thumbnailImage = "OCMS-logo-blue-text.svg";
                    if (item.thumbnailImage.toString().trim() === '')
                    {
                      if (item.featuredImage.toString().trim() === '')
                      {
                        thumbnailImage = "OCMS-logo-blue-text.svg";
                      }
                      else
                      {
                        thumbnailImage = item.featuredImage;
                      }
                    }
                    else
                    {
                      thumbnailImage = item.thumbnailImage;
                    }
                    tmpPages.push({
                      id: item.id,
                      title: item.title,
                      featuredImage: item.featuredImage,
                      slug: item.slug,
                      thumbnailImage: thumbnailImage,
                    })
                  });
                  setOCMTrainingPages([...OCMTrainingPages, ...tmpPages]);
                }
              } 
              else 
              {
                setHasMore(false);
                openError('topLeft', 'Error', response.err);
                apiResponseCallback(response);
              }
            }),
          );
    }, [
      dispatch, 
      OCMTrainingPages, 
      loading, 
      hasMore, 
      sortOrder,
      initLoading,
      filterString,
      pageNo
    ]);

    useEffect(() => {
      const retrieveData = async (category, page, sort) => {
        await fetchPages(category, page, sort);
      };
      if (Object.entries(category).length !== 0)
      {
        setPageNo(1);
        setOCMTrainingPages([]);
        setSortOrder(sort);
        retrieveData(category, 1, sort).catch(console.error);
      }
    }, [category]);

    useEffect(() => {
      const retrieveData = async (category, page, sort) => {
        await fetchPages(category, page, sort);
      };
      if (Object.entries(category).length !== 0)
      {
        setPageNo(1);
        setOCMTrainingPages([]);
        setSortOrder(sort);
        retrieveData(category, 1, sort).catch(console.error);
      }
    }, [sort]);

    useEffect(() => {
      const retrieveData = async (category, page, sort, filter) => {
        await fetchPages(category, page, sort, filter);
      };
      if (Object.entries(category).length !== 0)
      {
        setPageNo(1);
        setOCMTrainingPages([]);
        setFilterString(filter);
        retrieveData(category, 1, sortOrder, filter).catch(console.error);
      }
    }, [filter]);

    const loadMoreData = async () => {
      setLoading(true);
      setTimeout(async () => await fetchPages(category), 1000);
    }

    const userTheme = JSON.parse(localStorage.getItem('userTheme'));

    return (
      <FreeOCMTrainingListWrapper>
        {filterString.toString().trim() === "" ? (
          <FreeOCMTrainingListTitle>{category.name}</FreeOCMTrainingListTitle>
        ) : (
          <FreeOCMTrainingListTitle>{`Search for "${filterString}"`}</FreeOCMTrainingListTitle>
        )}
        <FreeScrollableListContainer ref={scrollContainerRef} id="scrollableDiv">
          <InfiniteScroll
            dataLength={OCMTrainingPages.length}
            next={loadMoreData}
            hasMore={hasMore}
            loader={
              <div className="loader" key={0} style={{ padding: "10px", display: "flex", alignItems: "center", justifyContent: "start" }}>
                <FontAwesomeIcon icon={duotone("spinner-third")} spin size="2xl" style={{ "--fa-primary-color": `${userTheme.mainHighlightColor}`, "--fa-secondary-color": `${userTheme.mainHighlightColor}`, marginRight: "20px", fontSize: "30px" }} />
                <span style={{fontSize: "20px", marginTop: "-3px", color: `${userTheme.mainHighlightColor}`}}>Loading Post...</span>
              </div>
            }
            scrollableTarget="scrollableDiv"
          >
            <List
              dataSource={OCMTrainingPages}
              renderItem={(OCMTrainingPage, index) => (
                <List.Item key={index}>
                  <FreeOCMTrainingItem content={OCMTrainingPage} />
                  {index < OCMTrainingPages.length - 1 && <FreeOCMTrainingDivider />}
                </List.Item>
              )}
              split={true}
              locale={{
                emptyText: <FreeEmptyMessage>No Posts for this Category</FreeEmptyMessage>
              }}
            />
          </InfiniteScroll>
        </FreeScrollableListContainer>
      </FreeOCMTrainingListWrapper>
    );
}
    
export default FreeOCMTrainingList;