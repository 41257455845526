import Styled from 'styled-components';

const PhaseCardStyle = Styled.article`
    ${({ theme, type }) => `
        box-shadow: 0px 5px 20px ${theme['extra-light-color']}05;
        .ant-card-head-title {
            color: rgb(255, 255, 255);
            font-weight: 700;
        }
        .low-width {
            width: 24%;
            position: absolute;
            top: 136px;
            right: 0;
            flex-direction: column;
            justify-content: start !important;
            align-items: start !important;
            padding: 15px !important;
            cursor: pointer;
        }
        .resource-box {
            cursor: pointer;
            border: 1px solid #ccc;
            border-radius: 25px;
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: start;
            padding-left: 20px;
            .count {
                font-size: 20px;
            }
            .icon-panel {
                width: 56px;
                height: 56px;
                padding: 5px;
                border: 1px solid #ccc;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 25px;
                margin-right: 10px;
            }
            .icon-panel-right {
                width: 56px;
                height: 56px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 25px;
                margin-right: 10px;
                position: absolute;
                right: 10px;
            }
            .icon-panel-left {
                width: 56px;
                height: 56px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 25px;
                margin-right: 10px;
                position: absolute;
                left: 10px;
            }
            .resource-name {
                padding-left: 10px;
                justify-content: start;
                display: flex;
                align-items: center;
                h3 {
                    text-align: left;
                }
            }
        }
        .guides-box {
            cursor: pointer;
            border: 1px solid #ccc;
            border-radius: 25px;
            display: flex;
            align-items: center;
            justify-content: start;
            flex-direction: column;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            padding: 0;
            .guides-box-bottom {
                width: 100%;
                padding: 10px !important;
            }
            .count {
                font-size: 20px;
            }
            .guide-name {
                padding: 5px;
                border-bottom: 1px solid #ccc;
                justify-content: start;
                display: flex;
                align-items: center;
                max-height: 70px;
                min-height: 70px;
                h3 {
                    text-align: left;
                }
            }
        }
        .file-box {
            flex-direction: column;
            border: 1px solid #ccc;
            border-radius: 25px;
            display: flex;
            align-items: center;
            justify-content: start;
            .ant-list {
                width: 100%;
                padding-left: 20px;
            }
            .file-list {
                width: 100%;
                display: flex;
                align-items: center;
                .file-name {
                    padding-top: 5px;
                }
                .ant-list-item-action {
                    padding-right: 20px;
                }
                .file-icon-panel {
                    width: 54px;
                    height: 54px;
                    padding: 10px;
                    border: 1px solid #ccc;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 30px;
                    margin-right: 20px;
                }
            }
            .title {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: start;
                padding: 20px;
                border-top-left-radius: 25px;
                border-top-right-radius: 25px;
            }
            .icon-panel {
                width: 72px;
                height: 72px;
                padding: 15px;
                border: 1px solid #ccc;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 35px;
                margin-right: 10px;
            }
            .resource-name {
                padding-left: 10px;
                justify-content: start;
                display: flex;
                align-items: center;
                h3 {
                    text-align: left;
                }
            }
        }
        .item-tools {
            .ant-card-head-title {
                color: rgb(66, 66, 66);
                font-weight: 400;
            }
            .thumb-horizontal {
                background-color: #493682;
            }
            .thumb-vertical {
                background-color: #493682;
            }
        }
        .guide-list {
            .ant-list-item {
                padding: 0 !important;
            }
        }
        .loadmore-list {
            padding: 0px;
            .ant-card {
                border-radius: ${theme['border-global-radius'] === undefined ? "18px" : theme['border-global-radius']};
                background-color: #F2F2F2;
                .ant-card-body {
                    min-height: auto;
                    height: auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .ant-card-head {
                    position: relative;
                }
                .ant-card-head-wrapper {
                    white-space: nowrap;
                }
                .title-header {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                }
                .ant-card-head-title {
                    padding-right: 20px;
                    white-space: normal;
                    font-size: 12px;
                    font-weight: 600;
                }
                .big-icon {
                    font-size: 60px;
                }
            }    
        }
        .loadmoretool-list {
            padding: 0px;
            -webkit-user-select: none; /* Safari */
            -ms-user-select: none; /* IE 10 and IE 11 */
            user-select: none; /* Standard syntax */
            .ant-card-bordered {
                border-color: rgb(221, 222, 226) !important;
                opacity: 1 !important;
            }
            .tools-card {
                position: relative;
                min-height: 220px;
                a {
                    text-align: center;
                    color: #666;
                }
                .number {
                    width: 50px;
                    height: 50px;
                    font-size: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid rgb(221, 222, 226);
                    border-radius: 50%;
                    position: absolute;
                    top: -2px;
                    left: -2px;
                    background: #fff;
                }
            }
            .ant-card {
                border-radius: ${theme['border-global-radius'] === undefined ? "18px" : theme['border-global-radius']};
                .ant-card-body {
                    min-height: auto;
                    height: auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                }
                .ant-card-head {
                    position: relative;
                }
                .ant-card-head-wrapper {
                    white-space: nowrap;
                }
                .title-header {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                }
                .ant-card-head-title {
                    padding-right: 20px;
                    white-space: normal;
                    font-size: 12px;
                    font-weight: 600;
                }
                .big-icon {
                    font-size: 60px;
                }
                .big-icon-tools {
                    font-size: 70px;
                }
                .productTitle{
                    margin-top: 20px;
                    font-weight: 600;
                    font-size: 18px;
                    width: 100%;
                    text-align: center;
                }
            }    
        }
        .ant-card {
            border-radius: ${theme['border-global-radius'] === undefined ? "18px" : theme['border-global-radius']};
        }
        .ant-card-head {
            border-top-left-radius: ${theme['border-global-radius'] === undefined ? "18px" : theme['border-global-radius']};
            border-top-right-radius: ${theme['border-global-radius'] === undefined ? "18px" : theme['border-global-radius']};
        }

        .ocmsolution-infocard-icon {
            width: 58px;
            height: 58px;
            background-color: ${theme[`${type}-color`]}20;
            color: ${theme[`${type}-color`]};
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 28px;
            margin: 2px auto 10px;
            border-radius: 14px;
            svg{
                width: 34px;
                height: 32px;
            }

        }

        .ocmsolution-infocard-text {
            font-size: 16px;
            margin-bottom: 0;
            color: ${theme[theme.mainContent]['gray-text']};
        }
        .ocmsolution-infocard-label {
            font-size: 30px;
            font-weight: 500;
            margin-bottom: 4px;
            color: ${theme[theme.mainContent]['dark-text']};
        }
        .visited-pages-list {
            margin: 0;
            padding: 0;
          }
          
          .visited-page-item {
            display: flex;
            flex-direction: column;
            padding: 10px;
            color: #666;
            border-bottom: 1px dashed #ccc;
            transition: all 0.3s ease;
          }
          
          .visited-page-item:hover {
            background-color: #f2f2f2;
          }
          
          .visited-page-name {
            white-space: normal;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          
          .visited-page-time {
            font-size: 0.8em;
            color: #aaa;
          }
          
    `}
`;

export {
  PhaseCardStyle
};
