import { useState } from 'react';
import { toPng } from 'html-to-image';
import jsPDF from 'jspdf';

const useDownloadPDF = ({ beforeDownload, afterDownload, templateRef, orientation = 'p', fileName, title }) => {
  const [downloadingSurvey, setDownloadingSurvey] = useState(false);

  const downloadPDF = async () => {
    setDownloadingSurvey(true);

    try {
      if (beforeDownload && typeof beforeDownload === 'function') {
        await beforeDownload(); // Execute the beforeDownload function
      }

      const dataUrl = await toPng(templateRef.current);

      const img = new Image();
      img.src = dataUrl;

      img.onload = () => {
        const imgWidth = img.width;
        const imgHeight = img.height;

        const pdf = new jsPDF(orientation, 'mm', 'a4', true);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgX = (pdfWidth - imgWidth * ratio) / 2;
        const imgY = (pdfHeight - imgHeight * ratio) / 2;

        if (title) {
          const titleX = pdfWidth / 2;
          const titleY = 15;

          pdf.text(title, titleX, titleY, { align: "center" });
        }

        pdf.addImage(dataUrl, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
        pdf.save(fileName || 'survey-form.pdf');

        if (afterDownload && typeof afterDownload === 'function') {
          afterDownload(); // Execute the afterDownload function
        }
      };
    } catch (error) {
      console.error('Error converting HTML to image:', error);
    } finally {
      setDownloadingSurvey(false);
    }
  };

  return { downloadingSurvey, downloadPDF };
};

export default useDownloadPDF;
