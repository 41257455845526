import { api, apiBlob } from '../../../config/dataService/useApi';
import { useTargetAudienceDispatch, useTargetAudienceState } from '../context/targetAudience';
import { reducerTypes, defaultColumn } from '../context/targetAudience/constants';
import endpoints from '../../../config/dataService/endpoints';
import { useNotificationsDispatch } from '../context/notifaction';
import { notificationsReducerTypes } from '../context/notifaction/constants';
import { uniqBy } from 'lodash';
import { detectDateFormat } from './utils';
import dayjs from 'dayjs';

const getStakeholders = async (values) => api(endpoints.getStakeholders.url, endpoints.getStakeholders.method, values);
const getGroupsByLevels = async (values) =>
  api(endpoints.getGroupsByLevels.url, endpoints.getGroupsByLevels.method, values);
const getGroupsLocationsLevels = async () => api('/audiences/get-locations-groups-levels', 'get');
const getActiveProject = async () => api(endpoints.getActiveProject.url, endpoints.getActiveProject.method);
const getJobRole = async (values) => api('/audiences/get-job-roles', 'post', values);
const getLocationsByLevels = async (values) => api('/audiences/get-location-by-location-level', 'post', values);
const saveAudience = async (values) => api('/audiences/save-audience', 'post', values);
const getAudience = async (values) => api('/list/get-audience', 'post', values);
const saveTargetAudiencesColumns = async (values) => api('/project/target-audience-columns', 'post', values);
const saveAudienceStakeholders = async (values) => api('/audiences/save-audience-stakeholder', 'post', values);
const getTargetAudienceStats = async () => api('/audiences/get-target-audience-statistics', 'get');
const deleteAllAudience = async (values) => api('/audiences/delete-all-audiences', 'post', values);
const postDictionarySuggestion = async (values) => api('/dictionary/insert', 'post', values);
const updateGroupLocationsAndJobs = async (value) => api(`/audiences/update-groups-locations-jobs`, 'put', value);
const getToolsData = async (values) => api(`/audiences/get-tools-data`, 'post', values);
const exportIndividuals = async () => apiBlob(`/audiences/export-individuals`, 'Target Audiences Individuals');
const exportGroups = async () => apiBlob(`/audiences/export-groups`, 'Target Audiences Groups');
const editIndividual = async (audienceId, values) => api(`/audiences/edit-individual/${audienceId}`, 'put', values);
const deleteIndividual = async (values) => api('/audiences/delete-individual', 'post', values);

const useTargetAudience = () => {
  const {
    columns,
    groups,
    locations,
    activeProject,
    models: { hasUnsavedData },
  } = useTargetAudienceState();
  const dispatch = useTargetAudienceDispatch();
  const notificationDispatch = useNotificationsDispatch();

  const createChunks = (array, chunkSize) => {
    return Array.from({ length: Math.ceil(array.length / chunkSize) }, (_, index) =>
      array.slice(index * chunkSize, (index + 1) * chunkSize),
    );
  };

  const saveColumns = async (updateColumns) => {
    const columnNames = updateColumns?.map((i) => {
      return {
        name: i.title,
        type: defaultColumn
          .filter((item) => item.typeKey !== 'group' && item.typeKey !== 'location')
          ?.find((c) => c.typeKey === i.typeKey)
          ? 'core'
          : i.typeKey,
        key: i.dataIndex,
        format: i?.format,
        isIndividual: i?.isIndividual,
        id: i?.id,
        options: i.typeKey === 'other' ? i?.options?.map((i) => ({ label: i.label, id: i?.id })) : undefined,
      };
    });
    await saveTargetAudiencesColumns({ targetAudienceColumns: columnNames });
    const res = await getActiveProject()

    if (res.status === 200) {
      saveTargetAudiencesColumnsHandler([])
      dispatch({
        type: reducerTypes.RELOAD,
        payload: 'project'
      })
    }
  }

  const saveAudienceHandler = async (aud, reloadString) => {
    saveColumns(columns)
    if (aud.target_audiences?.length) {
      const chunks = createChunks(aud.target_audiences, 1000);
      let res;
      for (const auds of chunks) {
        res = await saveAudience({
          ...aud,
          target_audiences: auds,
        });
      }

      if (res.status === 200) {
        notificationDispatch({
          type: notificationsReducerTypes.SUCCESS,
          payload: {
            message: 'Save Audience',
            description: res.data.message,
          },
        });
      }
    }

    dispatch({
      type: reducerTypes.RELOAD,
      payload: reloadString || 'all',
    });
    dispatch({
      type: reducerTypes.COLUMNS,
      payload: [],
    });
    dispatch({
      type: reducerTypes.TARGETAUDIENCE_MODAL,
      payload: { show: false },
    });
  };

  const saveTargetAudiencesColumnsHandler = async (columns) => {
    // const col = columns.map((i) => ({ name: i.title, type: i.type, key: i.key }));

    dispatch({
      type: reducerTypes.COLUMNS,
      payload: columns,
    });

    // const res = await saveTargetAudiencesColumns();
  };

  const deleteAudience = async () => {
    const res = await deleteAllAudience();
    dispatch({
      type: reducerTypes.RELOAD,
      payload: 'all-view',
    });
    dispatch({
      type: reducerTypes.COLUMNS,
      payload: [],
    });

    notificationDispatch({
      type: notificationsReducerTypes.SUCCESS,
      payload: {
        message: 'All Audience delete.',
        description: `${res.data.message}.` || 'All Audience deleted successfully.',
      },
    });
  };

  const hasNextGroup = (id) => {
    const hasNextGroup = groups.some((group) => group.parentGroupLevelId === id);
    const hasJobRoles = !!groups.find((group) => group.id === id).jobRoles?.length;
    return hasNextGroup || hasJobRoles;
  };

  const getGroups = (id, levelNumber) => {
    if (id) {
      return groups
        .filter((group) => group.parentGroupLevelId === id)
        .map((group) => ({
          ...group,
          hasNext: hasNextGroup(group.id),
        }));
    }
    if (levelNumber) {
      return groups
        .filter((group) => group.level.levelNumber === levelNumber)
        .map((group) => ({
          ...group,
          hasNext: hasNextGroup(group.id),
        }));
    }
    return groups
      .filter((group) => !group.parentGroupLevelId)
      .map((group) => ({
        ...group,
        hasNext: hasNextGroup(group.id),
      }));
  };

  const getLocations = (id, levelNumber) => {
    if (id) {
      return locations.filter((location) => location.parentLocationLevelId === id);
    }
    if (levelNumber) {
      return locations.filter((location) => location.level.levelNumber === levelNumber);
    }
    return locations.filter((location) => !location.parentLocationLevelId);
  };



  const groupsLocationsJobsCRUD = async (body, { type, title, parentId, levelId }) => {
    const postBody = {};
    switch (type) {
      case 'jobRole': {
        postBody.jobs = [
          ...body.new.map((item) => ({
            jobRole: item.value,
          })),
          ...body.editing.map((item) => ({
            id: item.id,
            jobRole: item.value,
          })),
          ...body.deleting.map((item) => ({
            id: item.id,
            deleted: true,
          })),
        ];
        await updateGroupLocationsAndJobs(postBody);
        dispatch({
          type: reducerTypes.RELOAD,
          payload: 'job',
        });
        break;
      }
      case 'group': {
        postBody.groups = [
          ...body.new.map((item) => ({
            groupLevelId: levelId,
            groupName: item.value,
            parentGroupLevelId: parentId,
          })),
          ...body.editing.map((item) => ({
            id: item.id,
            groupName: item.value,
          })),
          ...body.deleting.map((item) => ({
            id: item.id,
            deleted: true,
          })),
        ];
        await updateGroupLocationsAndJobs(postBody);
        dispatch({
          type: reducerTypes.RELOAD,
          payload: 'group',
        });
        break;
      }
      case 'location': {
        postBody.locations = [
          ...body.new.map((item) => ({
            locationLevelId: levelId,
            locationName: item.value,
            parentLocationLevelId: parentId,
          })),
          ...body.editing.map((item) => ({
            id: item.id,
            locationName: item.value,
          })),
          ...body.deleting.map((item) => ({
            id: item.id,
            deleted: true,
          })),
        ];
        await updateGroupLocationsAndJobs(postBody);
        dispatch({
          type: reducerTypes.RELOAD,
          payload: 'location',
        });
        break;
      }
      default: {
        postBody.collections = [
          ...body.new.map((item) => ({
            key: title,
            value: item.value,
          })),
          ...body.editing.map((item) => ({
            key: title,
            value: item.value,
          })),
        ];
        await postDictionarySuggestion(postBody);
        break;
      }
    }
  };

  const groupsLocationsJobsTemporaryCRUD = async (body, { type, title }) => {
    const updateColumns = columns.map((col) =>
      col.title === title
        ? {
          ...col,
          options: uniqBy(
            [
              ...(body.new?.map((item) => ({
                label: item.value,
                value: item.value,
                key: `Option-${(col.options || []).length}-${Math.random()}`,
              })) || []),
              ...(col.options || []).map((option) => {
                const editOption = body.editing.find((item) => item.id === option.key || item.id === option.id);
                return editOption ? { ...option, label: editOption.value } : option;
              }),
            ],
            'label',
          ).filter((option) => !body.deleting.some((item) => item.id === option.key)),
        }
        : col,
    );

    dispatch({
      type: reducerTypes.COLUMNS,
      payload: updateColumns,
    });
  };

  const deleteGroup = async (groupId) => {
    const groups = {
      deleted: true,
      id: groupId,
    };

    await updateGroupLocationsAndJobs({
      groups: [groups],
    });

    dispatch({
      type: reducerTypes.RELOAD,
      payload: 'group',
    });
  };

  const editIndividualHandler = async (audienceId, values, reloadString) => {
    const responce = await editIndividual(audienceId, values);

    if (responce.status === 200) {
      notificationDispatch({
        type: notificationsReducerTypes.SUCCESS,
        payload: {
          message: 'Individual update',
          description: `${responce.data.message}.` || 'Individual updated successfully.',
        },
      });
      if (reloadString) {
        dispatch({
          type: reducerTypes.RELOAD,
          payload: reloadString,
        });
      }
      dispatch({
        type: reducerTypes.EDIT_INDIVIDUAL,
        payload: {type: 'edit',  data: responce.data.targetAudience},
      })
      
    }
  };

  const getFormatedDateValue = (value) => {
    const dateValue = value ? value.split('T')[0] : value
    const date_time_format = (localStorage.getItem('date_time_format') || 'MM-DD-YYYY').toUpperCase();
    const format = detectDateFormat(dateValue);
    return format ? dayjs(dateValue, format).format(date_time_format) : '';
  }

  const formatMetaInfo = (column, groupValue, audienceValue, type = 'id') => {
    let initialValue
    if (column?.format === 'date') {
      initialValue = !!groupValue || !!audienceValue
        ? getFormatedDateValue(groupValue || audienceValue)
        : ''
    } else if (column?.format === 'dropdown' || column.format === 'radio') {
      const projectColumns = activeProject?.project?.targetAudienceColumns
      if (projectColumns) {
        const matchedColumn = projectColumns.find((i) => i?.id === column?.id)
        const metaColValue = groupValue || audienceValue
        if (Array.isArray(metaColValue)) {
          initialValue = (metaColValue).map(i => matchedColumn?.options?.find(j => j?.id === i)?.[type]).filter(Boolean)
        } else {
          initialValue = matchedColumn?.options?.find(j => j?.id === metaColValue)?.[type]
        }
      }
    } else {
      initialValue = groupValue || audienceValue
    }
    return initialValue
  }

  const deleteIndividualHandler = async (values) => {
    const res = await deleteIndividual(values);

    if (res.status === 200) {
      notificationDispatch({
        type: notificationsReducerTypes.SUCCESS,
        payload: {
          message: 'Individual delete',
          description: `${res.data.message}.` || 'Individual deleted successfully.',
        },
      });
      dispatch({
        type: reducerTypes.RELOAD_STATS,
        payload: true,
      });
    }
  };

  return {
    saveAudience: saveAudienceHandler,
    saveTargetAudiencesColumns: saveTargetAudiencesColumnsHandler,
    getTargetAudienceStats,
    getAudience,
    getStakeholders,
    saveAudienceStakeholders,
    getGroupsByLevels,
    getActiveProject,
    deleteAllAudience: deleteAudience,
    getJobRole,
    getGroupsLocationsLevels,
    getLocationsByLevels,
    getGroups,
    getLocations,
    postDictionarySuggestion,
    updateGroupLocationsAndJobs,
    deleteGroup,
    groupsLocationsJobsCRUD,
    groupsLocationsJobsTemporaryCRUD,
    getToolsData,
    exportIndividuals,
    exportGroups,
    editIndividual: editIndividualHandler,
    deleteIndividual: deleteIndividualHandler,
    saveColumns,
    formatMetaInfo: formatMetaInfo
  };
};

export default useTargetAudience;
