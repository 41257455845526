import React, { useState, useEffect, Suspense, useReducer, useCallback, useRef } from 'react';
import { Row, Col, Button, Skeleton, Modal, Tabs, Table, Space, Avatar, Typography, Alert, Pagination, Flex, Divider, Input, Spin } from 'antd';
const {Title, Text} = Typography;
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as ProIcons from '@fortawesome/pro-duotone-svg-icons';
import { UserStyle } from './Style';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Main } from '../styled';
import { DataService } from '../../config/dataService/dataService';
import { OCMModal, OCMModalHeader } from '../tools/Style';
import '../tools/panel/widgets/style.css';
import CreateUserModal from './CreateUserModal';
import { useDispatch } from 'react-redux';
import { verifyUser } from '../../redux/authentication/actionCreator';
import { Link, useNavigate } from 'react-router-dom';
import AccountUserProfileAvatar from '../tools/panel/widgets/AccountUserProfileAvatar';
import useNotificationController from '../../utility/useNotificationController';
import { getAccountUserProjects } from '../../redux/project/actionCreator';
import { makeActive } from '../../utility/commonFunction';
import OCMSelectFilter from '../../components/OCMSelect/OCMSelectFilter';

const toolsTab = [
  {
    tab_name: 'User Management',
    tab_key: 'team-members',
    tab_index: '1',
  },
];
const icons = ['faUsers', 'faFilePlus', 'faFolderPlus', 'faNotebook'];
icons.map((icon, i) => {
  library.add(ProIcons[icon]);
});
function UserManagement(props) {
  const {
    contextHolder,
    openError,
    openSuccess
  } = useNotificationController();

  const navigate = useNavigate();
  const searchInput = useRef(null);
  
  const userTheme = JSON.parse(localStorage.getItem('userTheme'))
  const organizationalSlug = localStorage.getItem('organizationalSlug')
  const [accountUserData, setAccountUserData] = useState({
    loaded: false,
    accountUsers: [],
    totalUser: 0,
    totalProjects: 0
  });
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0, currentlyShowing: 0 });
  const [initialFilters, setInitialFilters] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [isLoadingProject, setIsLoadingProject] = useState(false);
  const [projectData, setProjectData] = useState({
    loaded: false,
    projects: [],
    hasMore: false,
    userId: 0,
    userInfo: 0,
    activeProjectId: 0,
    count: 0
  });
  const [pageLoading, setPageLoading] = useState(false);
  const [selectProject, setSelectProject] = useState([]);
  const [isMarkAddProject, setIsMarkAddProject] = useState(false);
  const [selectedAllProject, setSelectedAllProject] = useState(false);
  const [filterProject, setFilterProject] = useState("");
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState(null);
  const [userData, setUserData] = useState();
  const [resetTableFilters, setResetTableFilters] = useState(false);
  const [page, setPage] = useState(1);
  const [projectPage, setProjectPage] = useState(1);
  const [showLoader, setShowLoader] = useState({});
  const [loadingProject, setLoadingProject] = useState(false);
  const [loadingProjectData, setLoadingProjectData] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [hasUserLicenses, setHasUserLicenses] = useState(true);
  const [hasGuestLicenses, setHasGuestLicenses] = useState(true);
  const [currentSort, setCurrentSort] = useState({});
  const [currentFilter, setCurrentFilter] = useState({});
  const [selectedValue, setSelectedValue] = useState({});

  const [isModalEditVisible, setIsModalEditVisible] = useState(false);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  
  const handleChange = async (paginationData, filters, sorter) => {
    if (pageLoading) {
      return;
    }
    setFilteredInfo(filters);

    const tmp = {};
    const tableFilters = Object.entries(filters);
    let foundFilter = false;
    if (tableFilters.length > 0)
    {
      for(var filterKey in tableFilters)
      {
        if (tableFilters[filterKey][1] !== null)
        {
          foundFilter = true;
          setResetTableFilters(true);
          break;
        }
      }  
    }
    if (!foundFilter)
    {
      setResetTableFilters(false);
    }
    setCurrentFilter(filters);

    if (sorter.order === undefined)
    {
      setPagination((prevPagingation) => ({
        ...prevPagingation,
        current: 1
      }));
      const sort = {
        column: "id",
        field: "id",
        order: "asc"
      }
      setCurrentSort(sort);
      setPageLoading(true);
      await getUsers(1, pagination.pageSize, sort, filters);
    }
    else if (sorter.columnKey === undefined)
    {
      setPagination((prevPagingation) => ({
        ...prevPagingation,
        current: 1
      }));
      const sort = {
        column: "id",
        field: "id",
        order: "asc"
      }
      setCurrentSort(sort);
      setPageLoading(true);
      await getUsers(1, pagination.pageSize, sort, filters);
    }
    else
    {
      const sort = {
        column: sorter.columnKey,
        field: sorter.field,
        order: sorter.order === "ascend" ? "asc" : "desc"
      }
      setCurrentSort(sort);
      setPagination((prevPagingation) => ({
        ...prevPagingation,
        current: 1
      }));
      await getUsers(1, pagination.pageSize, sort, filters);
    }
  };

  const clearFilters = () => {
    setFilteredInfo({});
    setSortedInfo({});
  };

  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
  };

  const getRandomColor = () => {
    const letters = '0123456789ABC';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  };

  const RandomColorAvatar = ({ text, record }) => {
    const randomColor = getRandomColor();
    return (
      <>
        <Avatar size={44} style={{ backgroundColor: randomColor }}>
          {text.slice(0, 2)}
        </Avatar>
        <span style={{ marginLeft: '10px' }}>{text}</span>
      </>
    );
  };

  const handleSave = async (accountUser, isUpdated = false) => {
    if (isUpdated)
    {
      setAccountUserData((prevUsers) => ({
        ...prevUsers,
        accountUsers: prevUsers.accountUsers.map(user => 
          parseInt(user.id) === parseInt(accountUser.id) ? accountUser : user
        ),
      }));
    }
    else
    {
      const newTotal = pagination.total + 1;
      const currentlyShowing = pagination.currentlyShowing + 1;
      setPagination((prevPagingation) => ({
        ...prevPagingation,
        currentlyShowing: currentlyShowing,
        total: newTotal,
      }));
      const preAccountUsers = accountUserData.accountUsers;
      preAccountUsers.unshift(accountUser);
      setAccountUserData((prevUsers) => ({
        ...prevUsers,
        totalUser: newTotal,
        accountUsers: preAccountUsers,
      }));
    }
    setShowUserModal(false);
  };

  const cancelData = () => {
    setUserData();
    setShowUserModal(false);
  };

  const showModal = (e) => {
    if (!hasUserLicenses && !hasGuestLicenses)
    {
      scrollToElement(e, "consumed_licenses");
    }
    else if (hasUserLicenses || hasGuestLicenses)
    {
      setIsModalEditVisible(true);
      setShowUserModal(true);
    }
  };

  const scrollToElement = (e, target) => {
      e.preventDefault();
      e.stopPropagation();
      window.scrollTo({
          behavior: 'smooth',
          top:
            document.querySelector(`#${target}`).getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            100,
      });
      // document.querySelector(`#${target}`).scrollIntoView({
      //     block: "start", 
      //     behavior: "smooth"
      // });
  }

  const showDeleteModal = (record) => {
    setToBeDeleted(record);
    setIsModalVisible(true);
  };

  const editRecord = (value) => {
    setUserData(value);
    setIsModalEditVisible(true);
    setShowUserModal(true);
  };

  const handleDelete = async () => {
    const response = await DataService.post('/users/delete-account-user', { id: toBeDeleted.id });
    if (response.status == 200) {
      setIsModalVisible(false);
      if (parseInt(response.data.remainingUser) === 0)
      {
        setHasUserLicenses(false);
      }
      else if (parseInt(response.data.remainingUser) > 0)
      {
        setHasUserLicenses(true);
      }
      
      if (parseInt(response.data.remainingGuest) === 0)
      {
        setHasGuestLicenses(false);
      }
      else if (parseInt(response.data.remainingGuest) > 0)
      {
        setHasGuestLicenses(true);
      }
      openSuccess('bottomRight', "User Management",  `Account user deleted successfully`);
      
      const newData = accountUserData.accountUsers.filter((user) => user.id !== toBeDeleted.id);
      const dataIndex = accountUserData.accountUsers.findIndex((user) => user.id === toBeDeleted.id);
      let currentlyShowing = parseInt(pagination.currentlyShowing);
      
      let newCurrent = pagination.current;
      if (newData.length === 0 && pagination.current > 1) {
        newCurrent = pagination.current - 1;
  
        //Get to the previous page 
        setPageLoading(true);
        await getUsers(newCurrent, pagination.pageSize);
      }
      else
      {
        if (currentlyShowing  === parseInt(parseInt(pagination.current - 1) * pagination.pageSize) + dataIndex)
        {
          currentlyShowing = currentlyShowing - 1;
          const newTotal = pagination.total - 1;
          setPagination((prevPagingation) => ({
            ...prevPagingation,
            currentlyShowing: currentlyShowing,
            total: newTotal,
          }));
          setAccountUserData((prevUsers) => ({
            ...prevUsers,
            totalUser: pagination.total - 1,
            accountUsers: newData,
          }));
        }
        else
        {
          const nextPageData = await fetchNextDataItem(currentlyShowing, 1);
          if (nextPageData.length === 0)
          {
            const newTotal = pagination.total - 1;
            currentlyShowing = newTotal;
            setPagination((prevPagingation) => ({
              ...prevPagingation,
              currentlyShowing: currentlyShowing,
              total: newTotal,
            }));
            setAccountUserData((prevUsers) => ({
              ...prevUsers,
              totalUser: pagination.total - 1,
              accountUsers: newData,
            }));
          }
          else
          {
            const newTotal = pagination.total - 1;
            const updatedData = [...newData, ...nextPageData];
            setPagination((prevPagingation) => ({
              ...prevPagingation,
              currentlyShowing: currentlyShowing,
              total: newTotal,
            }));
            setAccountUserData((prevUsers) => ({
              ...prevUsers,
              totalUser: pagination.total - 1,
              accountUsers: updatedData,
            }));
          }
        }
      }
    }
    setToBeDeleted(null);
  };

  const fetchNextDataItem = async (currentPage, pageSize) => {
    const response = await DataService.post('/users/get-account-all-user', {page: currentPage, pageSize: pageSize});
    if (response.status == 200) {
      let accountUsers = response.data.accountUsers;
      return accountUsers;
    }
  };

  const getUniqueProjects = (projects) => {
    if (!projects) return [];

    const uniqueProjectsMap = {};

    projects.forEach((val) => {
      const projectName = val.project_access?.projectName;
      const projectId = val.project_access?.id;

      if (projectId && projectName && !uniqueProjectsMap[projectId]) {
        uniqueProjectsMap[projectId] = projectName;
      }
    });

    return Object.keys(uniqueProjectsMap).map((id) => {
      return {
        text: uniqueProjectsMap[id],
        value: id,
      };
    });
  };

  const handlePageChange = async (page, pageSize) => {
    setPageLoading(true);
    await getUsers(page, pageSize, currentSort, currentFilter);
  };

  const handleCopy = async (page, pageSize) => {
    openSuccess('bottomRight', "Email Copy",  `Account user email copied successfully`);
  };

  const getUsers = async (currentPage, pageSize, sort, filters = {}) => {
    if (sort !== undefined && Object.entries(sort).length === 0)
    {
      sort = {
        column: "id",
        field: "id",
        order: "asc"
      };
      setCurrentSort(sort);
    }
    const response = await DataService.post('/users/get-account-all-user', {page: currentPage, pageSize: pageSize, sort : sort, ...filters});
    if (response.status == 200) {
      let accountUsers = response.data.accountUsers;
      accountUsers = accountUsers.map(item => {return {...item, 'key': item.id}})
      setAccountUserData({
        loaded: true,
        accountUsers: accountUsers,
        totalUser: response.data.accountUserCount,
        totalProjects: response.data.totalProjects
      });

      let currentlyShowing = currentPage * pageSize;
      setPagination((prevPagingation) => ({
        ...prevPagingation,
        current: currentPage,
        pageSize: pageSize,
        total: response.data.accountUserCount,
        currentlyShowing: currentlyShowing
      }));
      const filters = {};
      accountUsers.forEach((item) => {
        Object.keys(item).forEach((key) => {
          filters[key] = Array.from(new Set((filters[key] || []).concat(item[key])));
        });
      });
      setInitialFilters(filters);
      setPageLoading(false);
    }
  };

  const onChange = (selectedItems) => {
    setSelectProject(selectedItems);
  }

  const saveUserProject = async () => {
    let userId = projectData.userId;
    const values = { id: userId, projects: selectProject, selectedAllProject: selectedAllProject, filterProject: filterProject };
    const response = await DataService.post('/users/add-project-to-user', values);
    if (response.status == 200) 
    {
      openSuccess('bottomRight', "User Management",  `Project${selectProject.length > 1 ? 's' : 's'} has been added to user account successfully`);
      setAccountUserData((prevUsers) => ({
        ...prevUsers,
        accountUsers: prevUsers.accountUsers.map(user => 
          parseInt(user.id) === parseInt(userId) ? response.data.accountUser : user
        ),
      }));

      const currentPage = 1;
      setProjectPage(1);

      setIsLoadingProject(true);
      setLoadingProject(true);
      setShowLoader((prevShowLoader) => ({
        ...prevShowLoader,
        [userId] : true,
      }));

      setProjectData((prevProjectData) => ({
        ...prevProjectData,
        loaded: true,
        projects: [],
        hasMore: true,
        userId: userId,
        userInfo: prevProjectData.userInfo,
        activeProjectId: 0,
        count: 0
      }));
      await fetchUserProjects(userId, currentPage);
    }
    else
    {
      openError('topLeft', 'Error adding projects', response.data.message);
    }
  }

  const clearAllFilters = async () => {
    const tmp = {};
    const filters = Object.entries(currentFilter);
    if (filters.length > 0)
    {
      for(var filterKey in filters)
      {
        const key = filters[filterKey][0];
        tmp[key] = null;
      }  
    }
    setCurrentFilter(tmp);
    setPagination((prevPagingation) => ({
      ...prevPagingation,
      current: 1
    }));
    const sort = {
      column: "id",
      field: "id",
      order: "asc"
    }
    setCurrentSort(sort);
    setPageLoading(true);
    await getUsers(1, pagination.pageSize, sort, {});
    setResetTableFilters(false); 
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    const filterData = [];
    const tmp = {};
    tmp[dataIndex] = selectedKeys[0];
    confirm();
  };

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    setSelectedValue({
      ...selectedValue,
      [dataIndex]: undefined
    });
  };

  const handleChangeFilter = (value, dataIndex) => {
    setSelectedValue({
      ...selectedValue,
      [dataIndex]: value
    });
  };

  const getFilterDowndown = (type, setSelectedKeys, selectedKeys, confirm, clearFilters, close, dataIndex, title, dropdownValue = [], dynamicDropdown = false) => {
    return (
      type === 'input' ? (
        <div
          className={`selectContainer`}
          style={{
            padding: 15,
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            backgroundColor: 'white',
            border: "1px solid #ccc"
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Input
            ref={searchInput}
            placeholder={`Search ${title}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: 'block',
            }}
          />
          <Space>
            <Button
              type="ocm-default"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<FontAwesomeIcon
                icon={regular("magnifying-glass")}
                style={{
                  color: `#FFF`,
                  fontSize: `13px`,
                  paddingTop: "6px"
                }}
              />}
              size="small"
              style={{
                padding: "0px 13px 0px 10px",
                borderRadius: "6px",
                border: "1px solid #0066B2",
                height: "27px"
              }}
            >
              Search
            </Button>
            <Button
              type="ocm-default"
              onClick={() => {
                if (typeof clearFilters === "function")
                {
                  handleReset(clearFilters, dataIndex);
                  handleSearch(selectedKeys, confirm, dataIndex);
                }
              }}
              size="small"
              style={{
                padding: "0px 13px 0px 13px",
                borderRadius: "6px",
                border: "1px solid #0066B2",
                height: "27px"
              }}
            >
              Reset
            </Button>
            <Button
              type="ocm-default"
              size="small"
              onClick={() => {
                close();
              }}
              style={{
                padding: "0px 13px 0px 13px",
                borderRadius: "6px",
                border: "1px solid #0066B2",
                height: "27px"
              }}
            >
              close
            </Button>
          </Space>
        </div>
      ) : (
        dropdownValue.length > 0 && !dynamicDropdown ? (
          <div
            className={`dropdownContainer`}
            style={{
              padding: 15,
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              backgroundColor: 'white',
              border: "1px solid #ccc"
            }}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <OCMSelectFilter
              mode='single'
              label={dataIndex}
              initialOptions={dropdownValue}
              placeholder={`Select ${dataIndex}`}
              name={dataIndex}
              size="large"
              className='tableSingleSelectDropDown'
              type={dataIndex}
              values={[]}
              onChange={(value) => {
                const selectedValues = [];
                handleChangeFilter(value, dataIndex);
                selectedValues.push(value);
                setSelectedKeys(selectedValues);
              }}
              allowClear={true}
              showSearch={false}
            />
            <Space>
              <Button
                type="ocm-default"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<FontAwesomeIcon
                  icon={regular("magnifying-glass")}
                  style={{
                    color: `#FFF`,
                    fontSize: `13px`,
                    paddingTop: "6px"
                  }}
                />}
                size="small"
                style={{
                  padding: "0px 13px 0px 10px",
                  borderRadius: "6px",
                  border: "1px solid #0066B2",
                  height: "27px"
                }}
              >
                Search
              </Button>
              <Button
                type="ocm-default"
                onClick={() => {
                  if (typeof clearFilters === "function")
                  {
                    handleReset(clearFilters, dataIndex);
                    handleSearch(selectedKeys, confirm, dataIndex);
                  }
                }}
                size="small"
                style={{
                  padding: "0px 13px 0px 13px",
                  borderRadius: "6px",
                  border: "1px solid #0066B2",
                  height: "27px"
                }}
              >
                Reset
              </Button>
              <Button
                type="ocm-default"
                size="small"
                onClick={() => {
                  close();
                }}
                style={{
                  padding: "0px 13px 0px 13px",
                  borderRadius: "6px",
                  border: "1px solid #0066B2",
                  height: "27px"
                }}
              >
                close
              </Button>
            </Space>
          </div>
        ) : dynamicDropdown && (
          <div
            className={`dropdownContainer`}
            style={{
              padding: 15,
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              backgroundColor: 'white',
              border: "1px solid #ccc"
            }}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <OCMSelectFilter
              mode='multiple'
              className='projectTag'
              maxTagCount={3}
              label="Projects"
              placeholder="Select Projects"
              name="projects"
              size="large"
              type="Project"
              values={projectData.projects.map((item) => item.key)}
              axiosURL={'/project/get-all-projects'}
              axiosVariable={'projects'}
              optionValue={'projectName'}
              filterVariable={'projectName'}
              hasMoreDataValue={'hasMore'}
              onChange={(value) => {
                const selectedValues = [];
                handleChangeFilter(value, dataIndex);
                selectedValues.push(value);
                setSelectedKeys(selectedValues);
              }}
              rules={[{ required: true, message: 'Please select at least one project!', type: 'array' }]}
            />
            <Space>
              <Button
                type="ocm-default"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<FontAwesomeIcon
                  icon={regular("magnifying-glass")}
                  style={{
                    color: `#FFF`,
                    fontSize: `13px`,
                    paddingTop: "6px"
                  }}
                />}
                size="small"
                style={{
                  padding: "0px 13px 0px 10px",
                  borderRadius: "6px",
                  border: "1px solid #0066B2",
                  height: "27px"
                }}
              >
                Search
              </Button>
              <Button
                type="ocm-default"
                onClick={() => {
                  if (typeof clearFilters === "function")
                  {
                    handleReset(clearFilters, dataIndex);
                    handleSearch(selectedKeys, confirm, dataIndex);
                  }
                }}
                size="small"
                style={{
                  padding: "0px 13px 0px 13px",
                  borderRadius: "6px",
                  border: "1px solid #0066B2",
                  height: "27px"
                }}
              >
                Reset
              </Button>
              <Button
                type="ocm-default"
                size="small"
                onClick={() => {
                  close();
                }}
                style={{
                  padding: "0px 13px 0px 13px",
                  borderRadius: "6px",
                  border: "1px solid #0066B2",
                  height: "27px"
                }}
              >
                close
              </Button>
            </Space>
          </div>
        )
      )
    )
  }

  const columns = [
    {
      key: 'fullName',
      title: 'User Full name',
      dataIndex: 'fullName',
      sorter: true,
      filteredValue: filteredInfo.fullName || null,
      filterIcon: (filtered) => {
        return (
          <>
          {filtered && (
            <FontAwesomeIcon
              icon={regular("filter-list")}
              onClick={(e) => {
                e.stopPropagation(); 
                setSelectedValue({
                  ...selectedValue,
                  fullName: null
                });
                let currentFilterData = currentFilter;
                currentFilterData['fullName'] = null;
                handleChange(pagination, currentFilterData, currentSort);
              }}
              style={{
                color: `#CCC`,
                fontSize: `24px`,
                marginRight: "5px",
                cursor: 'pointer'
              }}
            />  
          )}
          <FontAwesomeIcon
            icon={regular("magnifying-glass")}
            style={{
              color: `#FFF`,
              fontSize: `13px`,
              marginLeft: "5px"
            }}
          />
          </>
        )
      },
      onCell: (record, index) => {
        let colData = record.fullName;
        return {
          className: 'custom-padding-cell',
          onClick: () => {
            // Handle cell click event
          },
        }; 
      },
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => {
        return getFilterDowndown('input', setSelectedKeys, selectedKeys, confirm, clearFilters, close, "fullName", "User Full name");
      },
      // render: (text, record) => <RandomColorAvatar text={text} record={record} />,
      render: (text, record) => <><AccountUserProfileAvatar {...props} audience={record} /><span style={{ marginLeft: '10px' }}>{text}</span></>,
      // filters: initialFilters.fullName?.map((val) => ({ text: val, value: val })),
      // onFilter: (value, record) => record.fullName === value,
      width: 100,
      align: "left",
      style: {
        padding: 10
      }
    },
    {
      key: 'email',
      title: 'Email',
      dataIndex: 'email',
      render: (emailText, record) => (emailText.toString().trim().length > 0 ? <Typography.Text copyable={{text: emailText, onCopy: handleCopy}}><Link to={`mailto:${emailText}`}>{emailText}</Link></Typography.Text> : ``),
      sorter: true,
      filteredValue: filteredInfo.email || null,
      filterIcon: (filtered) => {
        return (
          <>
          {filtered && (
            <FontAwesomeIcon
              icon={regular("filter-list")}
              onClick={(e) => {
                e.stopPropagation(); 
                setSelectedValue({
                  ...selectedValue,
                  email: null
                });
                let currentFilterData = currentFilter;
                currentFilterData['email'] = null;
                handleChange(pagination, currentFilterData, currentSort);
              }}
              style={{
                color: `#CCC`,
                fontSize: `24px`,
                marginRight: "5px",
                cursor: 'pointer'
              }}
            />  
          )}
          <FontAwesomeIcon
            icon={regular("magnifying-glass")}
            style={{
              color: `#FFF`,
              fontSize: `13px`,
              marginLeft: "5px"
            }}
          />
          </>
        )
      },
      onCell: (record, index) => {
        let colData = record.fullName;
        return {
          className: 'custom-padding-cell',
          onClick: () => {
            // Handle cell click event
          },
        }; 
      },
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => {
        return getFilterDowndown('input', setSelectedKeys, selectedKeys, confirm, clearFilters, close, "email", "Email");
      },
      // filters: initialFilters.email?.map((val) => ({ text: val, value: val })),
      // onFilter: (value, record) => record.email === value,
      width: 100,
    },
    {
      key: 'userType',
      title: 'User Role',
      dataIndex: 'userType',
      sorter: true,
      filteredValue: filteredInfo.userType || null,
      filterIcon: (filtered) => {
        return (
          <>
          {filtered && (
            <FontAwesomeIcon
              icon={regular("filter-list")}
              onClick={(e) => {
                e.stopPropagation(); 
                setSelectedValue({
                  ...selectedValue,
                  userType: null
                });
                let currentFilterData = currentFilter;
                currentFilterData['userType'] = null;
                handleChange(pagination, currentFilterData, currentSort);
              }}
              style={{
                color: `#CCC`,
                fontSize: `24px`,
                marginRight: "5px",
                cursor: 'pointer'
              }}
            />  
          )}
          <FontAwesomeIcon
            icon={regular("magnifying-glass")}
            style={{
              color: `#FFF`,
              fontSize: `13px`,
              marginLeft: "5px"
            }}
          />
          </>
        )
      },
      onCell: (record, index) => {
        let colData = record.fullName;
        return {
          className: 'custom-padding-cell',
          onClick: () => {
            // Handle cell click event
          },
        }; 
      },
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => {
        const userTypeDropDown = [
          {
            value: 1,
            label: <Title level={5}>Account Owner</Title>
          },
          {
            value: 2,
            label: <Title level={5}>Admin</Title>
          },
          {
            value: 3,
            label: <Title level={5}>Member</Title>
          },
          {
            value: 4,
            label: <Title level={5}>Guest</Title>
          },
        ];
        return getFilterDowndown('dropdown', setSelectedKeys, selectedKeys, confirm, clearFilters, close, "User Role", "userType", userTypeDropDown);
      },
      render: (text, record) => (text == 1 ? 'Account Owner' : text == 2 ? 'Admin' : text == 3 ? 'Member' : 'Guest'),
      // filters: initialFilters.userType?.map((val) => ({
      //   text: val == 1 ? 'Account Owner' : val == 2 ? 'Admin' : val == 3 ? 'Member' : 'Guest',
      //   value: val,
      // })),
      // onFilter: (value, record) => record.userType === value,
      width: 100,
      align: 'center'
    },
    {
      key: 'userProject',
      title: 'Projects',
      dataIndex: 'count_projects',
      render: (user, item) => {
        return (
          item.userType < 3 ? (
            <div style={{fontSize: "18px", letterSpacing: "1.2px"}}>
              {/* Total Project{parseInt(item.count_all_projects) > 1 ? 's' : ''}: {parseInt(item.count_all_projects)}<br /> */}
              {parseInt(item.count_all_projects) > 0 ? (
                <Button
                  type={`${parseInt(localStorage.getItem('user_type')) < 3 ? 'link' : ''}`}
                  onClick={async () => {
                    if (!loadingProject)
                    {
                      if (parseInt(localStorage.getItem('user_type')) < 3)
                      {
                        const currentPage = 1;
                        setProjectPage(1);

                        setLoadingProject(true);
                        setShowLoader((prevShowLoader) => ({
                          ...prevShowLoader,
                          [item.id] : true,
                        }));

                        setProjectData((prevProjectData) => ({
                          ...prevProjectData,
                          loaded: true,
                          projects: [],
                          hasMore: true,
                          userId: item.id,
                          userInfo: item,
                          activeProjectId: 0,
                          count: 0
                        }));
                        await fetchUserProjects(item.id, currentPage);
                      }
                      else if (localStorage.getItem("user_id") === item.id)
                      {
                        const currentPage = 1;
                        setProjectPage(1);

                        setLoadingProject(true);
                        setShowLoader((prevShowLoader) => ({
                          ...prevShowLoader,
                          [item.id] : true,
                        }));

                        setProjectData((prevProjectData) => ({
                          ...prevProjectData,
                          loaded: true,
                          projects: [],
                          hasMore: true,
                          userId: item.id,
                          userInfo: item,
                          activeProjectId: 0,
                          count: 0
                        }));
                        await fetchUserProjects(item.id, currentPage);
                      }
                    }
                  }}
                  style={{padding: 0, fontSize: "18px"}}
                >
                  {parseInt(localStorage.getItem('user_type')) < 3 ? 'Show All Projects' : 'All Projects'}
                </Button>
              ) : (
                item.project_names.split(",").map((projectName) => projectName).filter((projectName) => projectName !== "").join(", ")
              )}
              <FontAwesomeIcon
                  icon={duotone('spinner-third')}
                  spin
                  size="2x"
                  style={{ 'display': `${showLoader[item.id] ? 'inline-flex' : 'none'}`, '--fa-primary-color': '#0066B2', '--fa-secondary-color': '#0066B2', fontSize: 16, marginLeft: 10 }}
              />
            </div>
          ) : (
            <div style={{fontSize: "16px", letterSpacing: "1.2px"}}>
              {item.project_names.split(",").map((projectName) => projectName).filter((projectName) => projectName !== "").join(", ")}<br />
              {(parseInt(item.count_projects) - parseInt(item.project_names.split(",").length)) > 0 && (
                <Button
                  type="link"
                  onClick={async () => {
                    if (!loadingProject)
                    {
                      if (parseInt(localStorage.getItem('user_type')) < 3)
                      {
                        const currentPage = 1;
                        setProjectPage(1);

                        setLoadingProject(true);
                        setShowLoader((prevShowLoader) => ({
                          ...prevShowLoader,
                          [item.id] : true,
                        }));

                        setProjectData((prevProjectData) => ({
                          ...prevProjectData,
                          loaded: true,
                          projects: [],
                          hasMore: true,
                          userId: item.id,
                          userInfo: item,
                          activeProjectId: 0,
                          count: 0
                        }));
                        await fetchUserProjects(item.id, currentPage);
                      }
                      else if (localStorage.getItem("user_id") === item.id)
                      {
                        const currentPage = 1;
                        setProjectPage(1);

                        setLoadingProject(true);
                        setShowLoader((prevShowLoader) => ({
                          ...prevShowLoader,
                          [item.id] : true,
                        }));

                        setProjectData((prevProjectData) => ({
                          ...prevProjectData,
                          loaded: true,
                          projects: [],
                          hasMore: true,
                          userId: item.id,
                          userInfo: item,
                          activeProjectId: 0,
                          count: 0
                        }));
                        await fetchUserProjects(item.id, currentPage);
                      }
                    }
                  }}
                  style={{padding: "20px 0", fontSize: "18px"}}
                >
                  + {parseInt(item.count_projects) - parseInt(item.project_names.split(",").length)} more project{(parseInt(item.count_projects) - parseInt(item.project_names.split(",").length)) > 1 ? 's' : ''}
                </Button>
              )}
              <FontAwesomeIcon
                  icon={duotone('spinner-third')}
                  spin
                  size="2x"
                  style={{ 'display': `${showLoader[item.id] ? 'inline-flex' : 'none'}`, '--fa-primary-color': '#0066B2', '--fa-secondary-color': '#0066B2', fontSize: 16, marginLeft: 10 }}
              />
            </div>
          )
        );
      },
      filteredValue: filteredInfo.project_names || null,
      filterIcon: (filtered) => {
        return (
          <>
          {filtered && (
            <FontAwesomeIcon
              icon={regular("filter-list")}
              onClick={(e) => {
                e.stopPropagation(); 
                setSelectedValue({
                  ...selectedValue,
                  userProject: null
                });
                let currentFilterData = currentFilter;
                currentFilterData['userProject'] = null;
                handleChange(pagination, currentFilterData, currentSort);
              }}
              style={{
                color: `#CCC`,
                fontSize: `24px`,
                marginRight: "5px",
                cursor: 'pointer'
              }}
            />  
          )}
          <FontAwesomeIcon
            icon={regular("magnifying-glass")}
            style={{
              color: `#FFF`,
              fontSize: `13px`,
              marginLeft: "5px"
            }}
          />
          </>
        )
      },
      onCell: (record, index) => {
        let colData = record.fullName;
        return {
          className: 'custom-padding-cell',
          onClick: () => {
            // Handle cell click event
          },
        }; 
      },
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => {
        return getFilterDowndown('dropdown', setSelectedKeys, selectedKeys, confirm, clearFilters, close, "Projects", "project_names", [], true);
      },
      width: 200,
    },
    {
      key: 'user-action',
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => (
        <Space>
          <Button style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }} onClick={() => editRecord(record)}>
            <FontAwesomeIcon icon={regular("pencil")} style={{ color: '#C9CDD6', fontSize: '20px' }} />
          </Button>
          {(record.userType != 1 && record.userType > parseInt(localStorage.getItem('user_type')) && record.id !== parseInt(localStorage.getItem('user_id'))) && (
            <Button style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }} onClick={() => showDeleteModal(record)}>
              <FontAwesomeIcon icon={regular("trash-alt")} style={{ color: 'red', fontSize: '20px' }} />
            </Button>
          )}
        </Space>
      ),
      width: 50,
    },
  ];

  const initialState = {
      error: false,
      messages: ""
  };

  const reducer = (state, action) => {
      switch (action.type) {
        case 'VERIFY_USER_ERR':
          return { ...state, error: true,messages: action.err };
        case 'GET_ACCOUNT_USER_PROJECT_ERR':
            return { ...state, error: true,messages: action.err };
        case 'CONVERT_TO_TRIAL_ERR':
          return { ...state, error: true,messages: action.err };
        case 'RESET':
          return state;
        default:
          return state;
      }
  };

  const [apiError, apiResponseCallback] = useReducer(reducer, initialState);

  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    apiResponseCallback({ type: 'RESET' });
      dispatch(
          verifyUser({}, (response) => {
            if (response.status === 200) {
              if (parseInt(response.data.remainingUser) > 0)
              {
                setHasUserLicenses(true);
              }
              else
              {
                setHasUserLicenses(false);
              }
              if (parseInt(response.data.remainingGuest) > 0)
              {
                setHasGuestLicenses(true);
              }
              else
              {
                setHasGuestLicenses(false);
              }
            } 
            else 
            {
              openError('topLeft', 'Error', response.err);
              apiResponseCallback(response);
            }
          }),
        );
  }, [dispatch, hasUserLicenses]);

  const onChangeSearch = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value.toString().trim() === "")
    {
      setTimeout(async () => {
        setIsSearching(true);
        const currentPage = 1;
        setProjectPage(1);
        setProjectData((prevProjectData) => ({
          ...prevProjectData,
          loaded: true,
          projects: [],
          hasMore: true,
          userId: prevProjectData.userId,
          userInfo: prevProjectData.userInfo,
          activeProjectId: 0,
          count: 0
        }));
        await fetchUserProjects(projectData.userId, currentPage);
      }, 10);
    }
  }

  const loadMoreProjects = async () => {
    setLoadingProjectData(true);
    const currentPage = projectPage + 1;
    if (projectData.projects.length === 0)
    {
      currentPage = 1;
    }
    setProjectPage(currentPage);
    setTimeout(async () => {
      await fetchUserProjects(projectData.userId, currentPage);
    }, 1000);
  };

  const markActiveProject = async (projectId) => {
    await makeActive({key: projectId, userId: projectData.userId});
    setProjectData((prevProjectData) => ({
      ...prevProjectData,
      activeProjectId: projectId,
    }));
    if (parseInt(projectData.userId) === parseInt(localStorage.getItem("user_id")))
    {
      props.rearrangeProject(); 
    }
  }

  const searchProject = async () => {
    setTimeout(async () => {
      const currentPage = 1;
      setIsSearching(true);
      setProjectPage(1);
      setProjectData((prevProjectData) => ({
        ...prevProjectData,
        loaded: true,
        projects: [],
        hasMore: true,
        userId: prevProjectData.userId,
        userInfo: prevProjectData.userInfo,
        activeProjectId: 0,
        count: 0
      }));
      await fetchUserProjects(projectData.userId, currentPage, searchValue);
    }, 10);
  }

  const handleScroll = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      if (!loadingProjectData && projectData.hasMore) {
        await loadMoreProjects();
      }
    }
  };

  const fetchUserProjects = useCallback(async (userId, pageProject = 1, filterValue = '') => {
    apiResponseCallback({ type: 'RESET' });
    const params = {
      userId: userId,
      page: pageProject,
      projectName: filterValue
    }
    dispatch(
        getAccountUserProjects(params, (response) => {
          if (response.status === 200) {
            const projects = response.data.projects;
            const projectInfo = [];
            projects.map((project, i) => {
              let tmp = {};
              tmp = {
                key: project.id,
                label: project.projectName,
                projectname: project.projectName
              };
              projectInfo.push(tmp);
            });
            
            setProjectData((prevProjectData) => ({
              ...prevProjectData,
              loaded: true,
              projects: [...prevProjectData.projects, ...projectInfo],
              hasMore: response.data.hasMore,
              userId: userId,
              activeProjectId: response.data.activeProjectId,
              count: response.data.count
            }));

            setIsLoadingProject(false);
            setIsSearching(false);
            setShowProjectModal(true);
            setShowLoader((prevShowLoader) => ({
              ...prevShowLoader,
              [userId] : false,
            }));
            setLoadingProject(false);
            setLoadingProjectData(false);
          } 
          else 
          {
            setLoadingProjectData(false);
            setLoadingProject(false);
            setLoaded(true);
            apiResponseCallback(response);
          }
        }),
      );
  }, [
    dispatch, 
    projectData,
    showLoader,
    projectPage,
    loadingProject,
    isLoadingProject,
    isSearching,
    loadingProjectData,
    showProjectModal
  ]);

  useEffect(() => {
    const retrieveData = async () => {
      await fetchData();
      if (!accountUserData.loaded && accountUserData.accountUsers.length === 0)
      {
        const sort = {
          column: "id",
          field: "id",
          order: "asc"
        }
        setCurrentSort(sort);
        setPageLoading(true);
        await getUsers(pagination.current, pagination.pageSize, sort, {});
      }
    };
    props.setOpenKeys(['user-management']);
    retrieveData().catch(console.error);
  }, [pagination.current, pagination.pageSize]);

  const isPaidAccount = localStorage.getItem('is_paid') === 'true';

  const noFoundContent = (
    <Flex style={{minHeight: 200, alignItems: "center"}}>
      <Flex class="ant-empty ant-empty-normal ant-empty-small" style={{width: "100%"}}>
        <Flex class="ant-empty-image" style={{width: "100%", margin: "auto", textAlign: "center"}}>
          <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg"><title>Simple Empty</title><g transform="translate(0 1)" fill="none" fill-rule="evenodd"><ellipse fill="#f5f5f5" cx="32" cy="33" rx="32" ry="7"></ellipse><g fill-rule="nonzero" stroke="#d9d9d9"><path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#fafafa"></path></g></g></svg>
        </Flex>
        <Flex class="ant-empty-description" style={{textAlign: "center"}}>No users found for this account</Flex>
      </Flex>
    </Flex>
  )

  return (
    <>
      {contextHolder}
      <Main>
        <UserStyle>
          <Row justify="center" gutter={25} style={{width: "100%"}}>
            <Col xxl={24} xl={24} md={24}>
              <Suspense
                fallback={
                  <Cards headless>
                    <Skeleton active />
                  </Cards>
                }
              >
                <Tabs
                  className="toolTabStyle"
                  centered
                  size={'large'}
                  tabBarGutter={25}
                  items={toolsTab.map((item, i) => {
                    return {
                      label: (
                        <div className="ant-page-header-heading-title">
                          {item.tab_name}
                        </div>
                      ),
                      key: item.tab_key,
                      children:
                        <div style={{ padding: '60px' }}>
                          <Row gutter={30}>
                            <Col span={10}>
                              <Row className="box-all">
                                <Col className="box-icon" span={8}>
                                  <FontAwesomeIcon
                                    icon="fa-duotone fa-users"
                                    style={{
                                      fontSize: '3.5rem',
                                      '--fa-primary-color': `#2B64AC`,
                                      '--fa-secondary-color': `#A5BEDC`,
                                      cursor: 'pointer',
                                    }}
                                  />
                                </Col>
                                <Col span={16} className="countDiv">
                                    {accountUserData.loaded ? (
                                      <>{accountUserData.totalUser} User{accountUserData.totalUser > 1 && 's'}</>
                                    ) : (
                                      <><FontAwesomeIcon icon={duotone("spinner-third")} spin style={{"--fa-primary-color": `${userTheme.mainHighlightColor}`, "--fa-secondary-color": `${userTheme.mainHighlightColor}`, fontSize: 25, marginRight: 10}} /> User</>
                                    )}                                    
                                </Col>
                              </Row>
                            </Col>
                            <Col span={4}></Col>
                            <Col span={10}>
                              <Row className="box-all">
                                <Col className="box-icon" span={8}>
                                  <FontAwesomeIcon
                                    icon="fa-duotone fa-folder-plus"
                                    style={{
                                      fontSize: '3.5rem',
                                      '--fa-primary-color': `#2B64AC`,
                                      '--fa-secondary-color': `#A5BDDC`,
                                      cursor: 'pointer',
                                    }}
                                  />
                                </Col>
                                <Col span={16} className="countDiv">
                                    {accountUserData.loaded ? (
                                      <>{accountUserData.totalProjects} Project{accountUserData.totalProjects > 1 && 's'}</>
                                    ) : (
                                      <><FontAwesomeIcon icon={duotone("spinner-third")} spin style={{"--fa-primary-color": `${userTheme.mainHighlightColor}`, "--fa-secondary-color": `${userTheme.mainHighlightColor}`, fontSize: 25, marginRight: 10}} /> Project</>
                                    )}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <div className="container-create-button">
                            <Button
                              style={{
                                backgroundColor: '#4C4755',
                                height: '40px',
                                fontSize: '18px',
                                color: 'white',
                                borderRadius: '10px',
                              }}
                              onClick={showModal}
                            >
                              <FontAwesomeIcon icon={regular("plus")} style={{ marginRight: '8px' }} />
                              Create User
                            </Button>
                          </div>
                          <>
                          {resetTableFilters && (
                              <div className={'clear-filter'} style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "end"
                              }}>
                                <Button
                                  type="ocm-default"
                                  onClick={() => clearAllFilters()}
                                  size="small"
                                  style={{
                                    padding: "0px 15px",
                                    borderRadius: "6px",
                                    border: "1px solid #0066B2",
                                    height: "auto", 
                                    fontSize: "16px"
                                  }}
                                >
                                  Clear Filters
                                </Button>
                              </div>
                            )}  
                            <Spin spinning={pageLoading}>
                              <Table
                                id="tool-container-table-user"
                                style={{ marginTop: '20px' }}
                                columns={columns}
                                dataSource={accountUserData.accountUsers || []}
                                rowKey={(record) => record.id}
                                onChange={handleChange}
                                scroll={{
                                  x: 1400,
                                  y: 600,
                                }}
                                className="tool-table"
                                pagination={false}
                                locale={{
                                  emptyText: pageLoading ? <Flex style={{minHeight: 150, alignItems: "center", fontSize: 20, justifyContent: "center"}}>Loading users...</Flex> : noFoundContent, 
                                }}
                              />
                            </Spin>
                            {accountUserData.loaded && (
                              <Pagination
                                className="custom-pagination"
                                current={pagination.current}
                                pageSize={pagination.pageSize}
                                total={pagination.total}
                                showTotal={(total, range) => <Typography.Text className="pagination-total">Showing {range[0]} to {pagination.currentlyShowing > total ? total : pagination.currentlyShowing} out of {total} users</Typography.Text>}
                                onChange={handlePageChange}
                                showSizeChanger
                              />
                            )}
                          </>
                         
                          {(!hasUserLicenses && !hasGuestLicenses) && (
                            <div id="consumed_licenses" style={{paddingTop: "15px"}}>
                              <Alert
                                id=""
                                message={<Title level={4} type='danger'>You have consumed all your user licenses.</Title>}
                                description={
                                  <Title level={5}>To acquire additional User Licenses, <Link to={`/${organizationalSlug}/${isPaidAccount ? 'subscription/update-subscription' : 'subscribe-now'}`}>click here</Link> to add them to your account</Title>
                                }
                                type="error"
                                showIcon
                              />      
                            </div>
                          )}
                        </div>
                    };
                  })}
                />
              </Suspense> 
            </Col>
          </Row>
        </UserStyle>
        <OCMModal>
          <Modal
            style={{
              margin: '20px 0px',
            }}
            className="add-change"
            title={
              <OCMModalHeader>
                <div className="user-info-header-panel">
                  <h1 className="header-title">Delete User</h1>
                  <div className="ribon none"></div>
                </div>
              </OCMModalHeader>
            }
            centered
            closeIcon={<FontAwesomeIcon size="2xl" icon={duotone('circle-xmark')} />}
            open={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            onOk={() => setIsModalVisible(false)}
            wrapClassName={'ocm-modal'}
            footer={[
              <Button key="cancel" onClick={() => setIsModalVisible(false)}>
                Cancel
              </Button>,
              <Button key="delete" type="primary" danger onClick={handleDelete}>
                Delete
              </Button>,
            ]}
          >
            <div style={{ padding: '20px 30px' }}>
              <p style={{ fontSize: '22px' }}>Are you sure you want to delete {toBeDeleted?.fullName}'s account?</p>
              <Text type={'secondary'} level={2} style={{
                display: "inline-block",
                fontSize: 17,
                textAlign: "center",
                width: "100%"
              }}>Note: This action cannot be undone.</Text>
            </div>
          </Modal>
        </OCMModal>
        {showProjectModal && (
          <OCMModal>
            <Modal
              style={{
                margin: '20px 0px',
              }}
              className="add-change"
              title={
                <OCMModalHeader>
                  <div className="user-info-header-panel">
                    <h1 className="header-title">User Projects</h1>
                    <div className="ribon none"></div>
                  </div>
                </OCMModalHeader>
              }
              centered
              width={600}
              closeIcon={<FontAwesomeIcon size="2xl" icon={duotone('circle-xmark')} />}
              open={showProjectModal}
              onCancel={() => setShowProjectModal(false)}
              onOk={() => setShowProjectModal(false)}
              wrapClassName={'ocm-modal'}
              footer={[]}
            >
              <Flex className="project-dropdown" style={{ padding: '0px 5px', width: "100%", flexDirection: "column" }}>
                <Typography.Title level={3} style={{paddingLeft: "10px"}}>
                  {!isLoadingProject && !isSearching ? (
                    <>Total: {projectData.count} Project{projectData.count > 1 ? 's' : ''}</>
                  ) : (
                    <>Total: <FontAwesomeIcon icon={duotone("spinner-third")} spin style={{"--fa-primary-color": `${userTheme.mainHighlightColor}`, "--fa-secondary-color": `${userTheme.mainHighlightColor}`, fontSize: 18}} /> Project</>
                  )}
                </Typography.Title>
                <Divider style={{margin: 0}} />
                <ul style={{width: "100%"}} onScroll={handleScroll}>
                  <li key="searching" className={'inputUserProject'}>
                    <Input.Search className="searchUserProject" placeholder="search project" onSearch={searchProject} value={searchValue} onChange={onChangeSearch} loading={isSearching} />
                  </li>
                </ul>
                {isSearching ? (
                  <Flex style={{minHeight: 200, alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
                      <Spin size="large" />
                      <Typography.Text style={{paddingTop: 10, fontSize: 20}}>{isSearching ? 'Searching' : 'Loading'} Project</Typography.Text>
                  </Flex>
                ) : isLoadingProject ? (
                  <Flex style={{minHeight: 200, alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
                      <Spin size="large" />
                      <Typography.Text style={{paddingTop: 10, fontSize: 20}}>Loading Project</Typography.Text>
                  </Flex>
                ) : (
                  <ul className="project-dropdown-all" style={{width: "100%"}} onScroll={handleScroll}>
                    {projectData.projects.map((project) => (
                      projectData.activeProjectId === project.key ? (
                        <li key={project.key} className="active-project" style={{width: "100%", maxWidth: "100%"}}>
                          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                            <span style={{ marginRight: 'auto' }}>{project.label}</span>
                            <div style={{color: "#0066B2", fontWeight: "bold"}}><FontAwesomeIcon icon={duotone("check")} style={{"--fa-primary-color": `#0066B2`, "--fa-secondary-color": `#0066B2`, marginRight: "10px", fontSize: 18}} />Active Project</div>
                          </div>
                        </li>
                      ) : (
                        <li key={project.key} style={{width: "100%", maxWidth: "100%"}}>
                          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                            <span style={{ marginRight: 'auto' }}>{project.label}</span>
                            <Button type={'ocm-default-amber'} onClick={() => markActiveProject(project.key)}>Mark As Active</Button>
                          </div>
                        </li>
                      )
                    ))}
                    {loadingProjectData && <li key="loading" className='loaderMenu' style={{color: "#FFFFFF", fontSize: 18}}>
                      <FontAwesomeIcon icon={duotone("spinner-third")} spin style={{"--fa-primary-color": `${userTheme.mainHighlightColor}`, "--fa-secondary-color": `${userTheme.mainHighlightColor}`, marginRight: "10px", fontSize: 18}} />
                      Loading more projects...
                      </li>
                    }
                  </ul>
                )}
                <Divider style={{margin: 0}} />
                {!isMarkAddProject && parseInt(projectData.userInfo.userType) > 2 && parseInt(projectData.userId) !== parseInt(localStorage.getItem("user_id")) && (
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: "center", marginTop: 20 }}>
                    <Button type={'ocm-default-user'} onClick={() => {
                      setIsMarkAddProject(true);
                    }}>Add Project</Button>
                  </div>
                )}
                {isMarkAddProject && parseInt(projectData.userId) !== parseInt(localStorage.getItem("user_id")) && (
                  <Flex className="setProjectPanel" style={{ width: '100%', justifyContent: 'center', alignItems: "center", marginTop: 1, flexDirection: "column" }}>
                    <OCMSelectFilter
                      mode='multiple'
                      className='projectTag'
                      maxTagCount={3}
                      label="Projects"
                      placeholder="Select Projects"
                      name="projects"
                      size="large"
                      type="Project"
                      values={projectData.projects.map((item) => item.key)}
                      axiosURL={'/project/get-all-projects'}
                      axiosVariable={'projects'}
                      optionValue={'projectName'}
                      filterVariable={'projectName'}
                      hasMoreDataValue={'hasMore'}
                      onChange={onChange}
                      setSelectedAllProject={setSelectedAllProject}
                      setFilterProject={setFilterProject}
                      rules={[{ required: true, message: 'Please select at least one project!', type: 'array' }]}
                    />
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: "center", marginTop: 4 }}>
                      <Button type={'ocm-default-user'} style={{marginRight: 20}} onClick={async () => {
                        await saveUserProject();
                        setIsMarkAddProject(false);
                      }}>Save Project</Button>
                      <Button danger type={'ocm-user-danger'} onClick={() => {
                        setIsMarkAddProject(false);
                      }}>Cancel</Button>
                    </div>
                  </Flex>
                )}
              </Flex>
            </Modal>
          </OCMModal>
        )}
        {showUserModal && (
          <CreateUserModal
            isModalVisible={isModalEditVisible}
            setIsModalVisible={setIsModalEditVisible}
            handleSave={handleSave}
            cancelData={cancelData}
            userData={userData}
            isEditing={userData ? true : false}
            hasUserLicenses={hasUserLicenses}
            hasGuestLicenses={hasGuestLicenses}
            accountOwnerId={localStorage.getItem('user_id')}
            organizationName={localStorage.getItem('organizationalName')}
            setUserLiceses={(val) => setHasUserLicenses(val)}
            setGuestLiceses={(val) => setHasGuestLicenses(val)}
          />
        )}
      </Main>
    </>
  );
}

export default UserManagement;
