import { useState } from 'react';
import { Button, Divider, Form, Input, Popconfirm, Spin, Typography } from 'antd';
import Popup from '../../../../ui-elements/Popup';
import styled from 'styled-components';
import FormField from '../FormField';
import { useTargetAudienceDispatch, useTargetAudienceState } from '../../../context/targetAudience';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import useTargetAudience from '../../../hook/useTargetAudience';
import { reducerTypes } from '../../../context/targetAudience/constants';
import dayjs from 'dayjs';

const { Text, Title } = Typography;

const EditGroupModal = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [confirmLoadingDeleteButton, setConfirmLoadingDeleteButton] = useState(false);
  const {
    columns,
    models: { editModal },
  } = useTargetAudienceState();
  const dispatch = useTargetAudienceDispatch();
  const { deleteGroup, updateGroupLocationsAndJobs, formatMetaInfo } = useTargetAudience();
  const { data: groupData, groupLevelId, parentGroupLevelId, count, disabled } = editModal ?? {};

  const groupColumns = columns
    .filter((i) => i.typeKey === 'other' && !i.isIndividual)
    .map((g) => ({ ...g, initialValue: formatMetaInfo(g, groupData?.metaInfo[g.id], null)   ?? '' }));

  useEffect(() => {
    if (groupData !== undefined && groupData !== null) {
      const formData = {};
      formData['groupName'] = groupData?.groupName;
      formData['count'] = count;

      form.setFieldsValue(formData);
    } else {
      const formData = {};
      formData['count'] = 1;
      form.setFieldsValue(formData);
    }
  }, [groupData]);

  const onFinish = async (values) => {
    setLoading(true);
    const numberObject = {};
    for (const key in values) {
      if (!isNaN(key)) {
        if (values[key] instanceof dayjs) {
          const val = values[key].format()
          numberObject[key] = val;
        } else {
          numberObject[key] = values[key];
        }
      }
    }

    const groups = {
      groupLevelId,
      parentGroupLevelId: groupData?.parentGroupLevelId ?? parentGroupLevelId,
      id: groupData?.id,
      groupName: values.groupName,
      childCount: values?.count ? values?.count : 1,
      metaInfo: numberObject,
    };

    await updateGroupLocationsAndJobs({
      groups: [groups],
    });
    dispatch({
      type: reducerTypes.RELOAD,
      payload: 'group',
    });
    setLoading(false);
    close();
  };

  const groupDeletion = async () => {
    setLoading(true);
    deleteGroup(groupData?.id);
    setLoading(false);
    close();
  };

  const close = () => {
    dispatch({
      type: reducerTypes.SET_EDIT_MODAL,
      payload: null,
    });
  };

  return (
    <Popup
      open={editModal?.type && editModal?.type === 'group'}
      close={close}
      title={`${groupData !== undefined && groupData !== null ? 'Edit ' : 'Add'} ${editModal?.listName}`}
      footer={null}
    >
      <Form form={form} onFinish={onFinish}>
        <Container>
          <Wrapper>
            <InputContainer width={groupData !== undefined ? 'max-content' : ''}>
              <label style={{ marginTop: '22px', color: '#000000', fontWeight: 500 }}>Group Name</label>
              <Form.Item
                name="groupName"
                rules={[
                  {
                    required: true,
                    message: 'Group name is required.',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <span>
                {' '}
                {!!groupData && <>Note: This update will be applied to all connected groups and/or individuals. </>}
              </span>
            </InputContainer>
            <InputContainer width="150px">
              <label style={{ color: '#000000', fontWeight: 500 }}># of individals in this Group</label>
              <Form.Item
                name="count"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value >= 1) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('# of individals cannot be less than 1.'));
                    },
                  }),
                ]}
              >
                <Input type={'number'} min={1} disabled={disabled} />
              </Form.Item>
            </InputContainer>
          </Wrapper>
          {groupColumns.length > 0 && (
            <>
              <Divider style={{ borderColor: '#004D86', color: '#004d86' }}>Custom Fields </Divider>
              <Wrapper>
                {groupColumns.map((i) => (
                  <InputContainer key={i.id} width={'48%'}>
                    <label>{i.title}</label>
                    <FormField selectOption={{}} name={i.id} col={i} />
                  </InputContainer>
                ))}
              </Wrapper>
            </>
          )}

          <ButtonContainer>
            <Button type={'text'} onClick={close} disabled={loading}>
              Cancel
            </Button>
            <Button
              shape={'round'}
              style={{ background: loading ? 'gray' : '#0066B4', color: '#fff' }}
              type={'primary'}
              htmlType="submit"
            >
              {loading ? <Spin /> : 'Save'}
            </Button>
            {groupData !== undefined && groupData !== null && (
              <div>
                <Popconfirm
                  overlayClassName="deletePopUp"
                  title={
                    <Title level={2} type={'danger'}>
                      Warning
                    </Title>
                  }
                  icon={
                    <FontAwesomeIcon
                      icon={duotone('circle-question')}
                      size="xl"
                      style={{
                        '--fa-primary-color': '#ff8080',
                        '--fa-secondary-color': '#ff8080',
                        marginRight: '20px',
                        paddingTop: '10px',
                      }}
                    />
                  }
                  description={
                    <div style={{ width: '100%' }}>
                      <p>
                        <Text style={{ fontSize: '23px', paddingBottom: '20px' }} italic>
                          You are about to delete your entire audience list which contains all groups, subgroups, and/or
                          individuals.
                        </Text>
                      </p>
                      <p>
                        <Text type="secondary" style={{ fontSize: '23px', paddingBottom: '20px' }}>
                          This action cannot be undone.
                        </Text>
                      </p>
                      <p>
                        <Text type="secondary" style={{ fontSize: '23px', paddingBottom: '20px' }}>
                          Are you sure you want to proceed?
                        </Text>
                      </p>
                    </div>
                  }
                  onConfirm={async () => {
                    setConfirmLoadingDeleteButton(true);
                    await groupDeletion();
                    setConfirmLoadingDeleteButton(false);
                  }}
                  onCancel={() => {}}
                  okText="Delete"
                  cancelText="Cancel"
                  okButtonProps={{
                    danger: true,
                    loading: confirmLoadingDeleteButton,
                    shape: 'round',
                    size: 'large',
                  }}
                  cancelButtonProps={{
                    danger: false,
                    shape: 'round',
                    size: 'large',
                    type: 'default',
                  }}
                >
                  <Text
                    type="danger"
                    strong
                    italic
                    underline
                    style={{
                      paddingLeft: '10px',
                      fontSize: '15px',
                      cursor: 'pointer',
                    }}
                  >
                    Delete
                  </Text>
                </Popconfirm>
              </div>
            )}
          </ButtonContainer>
        </Container>
      </Form>
    </Popup>
  );
};

const Container = styled.div`
  display: flex;
  width: 800px;
  flex-direction: column;
  padding: 20px 10px 0px 20px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 50px;
`;

const InputContainer = styled.div`
  width: ${(p) => (p.width ? p.width : '310px')};
  display: flex;
  flex-direction: column;
  gap: 10px;
  .ant-form-item.css-dev-only-do-not-override-1uweeqc.ant-form-item-has-success {
    margin-bottom: 0px !important;
  }
  span {
    color: #a6a2a2;
  }
  input {
    border-radius: 6px !important;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: end;
  gap: 50px;
  > div {
    position: absolute;
    right: 30px;
    bottom: 25px;
  }
`;

export default EditGroupModal;
