import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { Cascader, Row, Col, Form } from 'antd';
import { useDispatch } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'

import { 
  getGroupLevels, 
  getGroupNamesByLevel, 
  getStakeholderTypes,
  getAudienceList,
  getLocationLevels,
  getLocationNamesByLevel,
  getTypeofChanges  } from "../../../../redux/audiences/actionCreator";

import './style.css';
import './cascader.css';

const MultiCascader = (props) => {
    const [loaded, setLoaded] = useState(true);
    const [defaultGroups, setDefaultGroup] = useState(props.defaultData); //['Key Stakeholder'],['Influencer'],['Champion'],['Huffas']
    let values = [
      {
        key : 'stakeholder_type',
        value : 'stakeholder_type',
        label : 'Stakeholder Type',
        isLeaf: false
      },
      {
        key   : 'group',
        value : 'group',
        label : 'Group',
        isLeaf: false
      },
      {
        key   : 'location',
        value : 'location',
        label : 'Location',
        isLeaf: false
      },
      {
        key   : 'individual',
        value : 'individual',
        label : 'Individual',
        isLeaf: false
      },
      {
        key   : 'type_of_change',
        value : 'type_of_change',
        label : 'Type of Change',
        isLeaf: false
      }
    ];
    
    if (props.isGroup)
    {
      values = [
        {
          key   : 'group',
          value : 'group',
          label : 'Group',
          isLeaf: false
        },
        {
          key   : 'type_of_change',
          value : 'type_of_change',
          label : 'Type of Change',
          isLeaf: false
        }
      ];
    }
    const [groups, setGroup] = useState(values);

    const initialState = {
        error: false,
        messages: ""
    };

    const reducer = (state, action) => {
        switch (action.type) {
          case 'GET_GROUP_LEVELS_ERR':
            return { ...state, error: true, messages: action.err };
          case 'RESET':
            return state;
          default:
            return state;
        }
    };

    const [apiError, apiResponseCallback] = useReducer(reducer, initialState);

    const dispatch = useDispatch();

    const onChange = (value, selectedOptions) => {
      props.onSet(value);
    };

    const loadData = useCallback(async (selectedOptions, execute_more, data_id) => {
      if (selectedOptions.length > 0)
      {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        apiResponseCallback({ type: 'RESET' });
        switch (targetOption.key)
        {
          case 'stakeholder_type': 
              dispatch(
                getStakeholderTypes({return: 'all'},(response) => {
                if (response.status === 200) {
                  const stakeholder_types = response.data.stakeholderTypes;
                  let stakeholder_lists = [];
                  stakeholder_types.map((item, i) => {
                      let tmp = {
                        value : item.id,
                        label : item.stakeholderName,
                        isLeaf: true
                      };
                      stakeholder_lists.push(tmp);
                  });
                  targetOption.children = stakeholder_lists;
                  setGroup([...groups]);
                } else {
                    apiResponseCallback(response);
                }
              }),
            );
            break;
          case 'type_of_change': 
              dispatch(
                getTypeofChanges((response) => {
                if (response.status === 200) {
                  const type_of_changes = response.data.typeofChanges;
                  let type_of_changes_data = [];
                  type_of_changes.map((item, i) => {
                      let tmp = {
                        value : item.id,
                        label : item.changeName,
                        isLeaf: true
                      };
                      type_of_changes_data.push(tmp);
                  });
                  targetOption.children = type_of_changes_data;
                  setGroup([...groups]);
                } else {
                    apiResponseCallback(response);
                }
              }),
            );
            break;
          case 'group': 
              dispatch(
                getGroupLevels({}, async (response) => {
                if (response.status === 200) {
                  const all_group_levels = response.data.groupLevels;
                  let group_levels = [];
                  all_group_levels.map(async (item, i) => {
                      let tmp = {
                        key : 'group_level',
                        value : item.id,
                        label : item.groupLevelName,
                        isLeaf: false
                      };
                      group_levels.push(tmp);
                      if (execute_more) 
                      {
                        if (item.id === data_id)
                        {
                            await loadData([tmp]);
                        }
                      }
                      else
                      {
                        for(var c in props.defaultData)
                        {
                            let selectedData = [];
                            const optionType = props.defaultData[c][0];
                            if (optionType === "group")
                            {
                              if (item.id === props.defaultData[c][1])
                              {
                                  await loadData([tmp]);
                              }
                            }
                        }
                      }
                  });
                  targetOption.children = group_levels;
                  setGroup([...groups]);
                } else {
                    apiResponseCallback(response);
                }
              }),
            );
            break;
          case 'group_level': 
              dispatch(
                getGroupNamesByLevel({levelId: targetOption.value}, (response) => {
                if (response.status === 200) {
                  const all_group_level_names = response.data.group_level_names;
                  let group_level_names = [];
                  all_group_level_names.map((item, i) => {
                      let tmp = {
                        value : item.id,
                        label : item.groupName,
                        isLeaf: true
                      };
                      group_level_names.push(tmp);
                  });
                  targetOption.children = group_level_names;
                  setGroup([...groups]);
                } else {
                    apiResponseCallback(response);
                }
              }),
            );
            break;
          case 'location': 
              dispatch(
                getLocationLevels(async (response) => {
                if (response.status === 200) {
                  const all_location_levels = response.data.locationLevels;
                  let location_levels = [];
                  all_location_levels.map(async (item, i) => {
                      let tmp = {
                        key : 'location_level',
                        value : item.id,
                        label : item.locationLevelName,
                        isLeaf: false
                      };
                      location_levels.push(tmp);
                      if (execute_more) 
                      {
                        if (item.id === data_id)
                        {
                            await loadData([tmp]);
                        }
                      }
                      else
                      {
                        for(var c in props.defaultData)
                        {
                            let selectedData = [];
                            const optionType = props.defaultData[c][0];
                            if (optionType === "location")
                            {
                              if (item.id === props.defaultData[c][1])
                              {
                                  await loadData([tmp]);
                              }
                            }
                        }
                      }
                  });
                  targetOption.children = location_levels;
                  setGroup([...groups]);
                } else {
                    apiResponseCallback(response);
                }
              }),
            );
            break;
          case 'location_level': 
              dispatch(
                getLocationNamesByLevel({levelId: targetOption.value}, (response) => {
                if (response.status === 200) {
                  const all_location_levels = response.data.locations;
                  let locations = [];
                  all_location_levels.map((item, i) => {
                      let tmp = {
                        value : item.id,
                        label : item.locationName,
                        isLeaf: true
                      };
                      locations.push(tmp);
                  });
                  targetOption.children = locations;
                  setGroup([...groups]);
                } else {
                    apiResponseCallback(response);
                }
              }),
            );
            break;
          case 'individual': 
              dispatch(
                getAudienceList({return: 'all'}, (response) => {
                if (response.status === 200) {
                  const audiences = response.data.audienceData;
                  let audience_data = [];
                  audiences.map((item, i) => {
                      let tmp = {
                        value : item.id,
                        label : item.fullName,
                        isLeaf: true
                      };
                      audience_data.push(tmp);
                  });
                  targetOption.children = audience_data;
                  setGroup([...groups]);
                } else {
                    apiResponseCallback(response);
                }
              }),
            );
            break;
        }
      }
    }, [dispatch, groups]);

    const loadMoreData = (selectedOptions, execute_more = false, data_id = 0) => {
      const retrieveData = async (selectedOptions, execute_more, data_id) => {
        
        loadData(selectedOptions, execute_more, data_id);
      }
      retrieveData(selectedOptions, execute_more, data_id)
      .catch(console.error);
    }

    const getSelectedValues = async() => {
      if (props.defaultData !== undefined)
      {
        if (props.defaultData.length > 0)
        {
            for(var i in props.defaultData)
            {
                let selectedData = [];
                const optionType = props.defaultData[i][0];
                for(var k in values)
                {
                    if (values[k].key === optionType)
                    {
                      selectedData.push(values[k]);
                    }
                    loadMoreData(selectedData, true, props.defaultData[i][1]);
                }
            }
        }
      }
    }

    useEffect(() => {
      const retrieveData = async () => {
        await getSelectedValues();
      }
      retrieveData().catch(console.error);
    }, []);
    
    const userTheme = JSON.parse(localStorage.getItem('userTheme'))
    
    return (
      <Row id={props.id}>
        <Col span={24} className='group_level_data add_more_btn_panel'>
        {!loaded ? (
            <FontAwesomeIcon icon={duotone("spinner-third")} spin size="2xl" style={{"--fa-primary-color": `${userTheme.mainHighlightColor}`, "--fa-secondary-color": `${userTheme.mainHighlightColor}`, "padding": "20px 10px"}} />
        ) : (
            <Cascader 
              size='large'
              options={groups} 
              loadData={loadMoreData} 
              onChange={onChange} 
              changeOnSelect 
              multiple
              defaultValue={defaultGroups}
              maxTagPlaceholder={['Multiple Selection']}
              popupClassName='ant-select-customize-input'
              expandIcon={
                <FontAwesomeIcon icon={duotone("caret-right")} style={{"--fa-primary-color": "#400040", "--fa-secondary-color": "#000000", "--fa-secondary-opacity": "0.8"}} />
              }
              suffixIcon={
                <FontAwesomeIcon icon={duotone("caret-down")} style={{"--fa-primary-color": "#400040", "--fa-secondary-color": "#000000", "--fa-secondary-opacity": "0.8"}} />
              }
              maxTagCount="responsive"
              placeholder="Select Target Audiences"
              className="ant-select-customize-input"
            />
        )}
        </Col>
    </Row>
    );
};
export default MultiCascader;