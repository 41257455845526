import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Divider, Form, Input, List, Modal, Row, Select, Typography } from 'antd';
import { useRef, useState } from 'react';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const MapAudienceModal = ({
  open,
  saveAudiencesData,
  loading,
  defaultHeaderRow,
  setDefaultHeaderRow,
  currentView,
  audienceColumns,
  disabledFields,
  selectedValues,
  setSelectedValues,
  defaultHeader,
  setDefaultHeader,
  showing,
  setShowing,
  currentlyShowing,
  setCurrentlyShowing,
  setSelectedValuesInd,
  selectedValuesInd,
  setDefaultHeaderIndex,
  setFirstRowHeader,
  firstRowHeader,
  close,
}) => {
  const [isImageModalVisible, setIsImageModalVisible] = useState(false);
  const headerRowRef = useRef();


  return (
    <div>
      <Modal
        title={
          <>
            <Title level={2}>Bulk Upload</Title>
            <Divider />
          </>
        }
        open={open}
        onOk={saveAudiencesData}
        okText={!loading ? 'Import' : ' '}
        okButtonProps={{
          size: 'middle',
          shape: 'round',
          type: 'primary',
          style: { backgroundColor: '#0066b4' }, //display: defaultHeaderRow ? 'block' : 'none', 
          icon: loading ? <LoadingOutlined style={{ fontSize: 24 }} spin /> : '',
        }}
        cancelButtonProps={{
          size: 'large',
          type: 'text',
          style: { marginLeft: '85.2%' }, //display: defaultHeaderRow ? 'block' : 'none', 
        }}
        onCancel={close}
        width="1200px"
      >
        {currentView === 'ownSheet' && (
          <Row
            key={0}
            style={{
              width: '50%',
              alignItems: 'center',
              margin: 'auto',
            }}
          >
            <Col
              xxl={24}
              xl={24}
              md={24}
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'column',
              }}
            >
              <span style={{ fontSize: '20px', textAlign: 'left' }}>Below is the preview of your mapped data.</span>
            </Col>
            <Col
              xxl={24}
              xl={24}
              md={24}
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Form.Item
                key={`header_row_0`}
                size="large"
                label=""
                name="header_row"
                initialValue={1}
                style={{ marginBottom: 10, marginTop: '20px' }}
              >
                <span style={{ fontSize: '20px' }}>1. The spreadsheet's column titles are on this row. </span>
                <Input
                  ref={headerRowRef}
                  placeholder={'Header Row'}
                  defaultValue={1}
                  onPressEnter={(e) => {
                    let currentValue = parseInt(e.target.value) - 1;
                    if (parseInt(e.target.value) === 0) {
                      currentValue = 0;
                    }
                    setDefaultHeaderIndex(currentValue);
                    setDefaultHeader(fileData[parseInt(currentValue)]);
                    setFirstRowHeader(fileData[parseInt(currentValue) + 1]);
                    let selectedDatas = [];
                    let selectedDatavalues = [];
                    let showDatavalues = [];
                    let selectedIndex = [];
                    audienceColumns?.map((item, o) => {
                      let [val, ind] = getDefaultValue(item.title, fileData[parseInt(currentValue)]);
                      if (val.trim() === '') {
                        showDatavalues.push('');
                        selectedDatavalues.push(false);
                        selectedIndex.push(-1);
                      } else {
                        showDatavalues.push(fileData[parseInt(currentValue) + 1][ind]);
                        selectedDatavalues.push(true);
                        selectedIndex.push(0);
                      }
                      selectedDatas.push(val);
                    });
                    setCurrentlyShowing(showDatavalues);
                    setSelectedValues(selectedDatas);
                    setDefaultHeaderRow(true);
                    setShowing(selectedDatavalues);
                    setSelectedValuesInd(selectedIndex);
                  }}
                  style={{ textAlign: 'center', width: '60px', marginLeft: '10px' }}
                />
                <FontAwesomeIcon
                  size="2xl"
                  icon={duotone('circle-question')}
                  style={{
                    '--fa-primary-color': '#0077ce',
                    '--fa-secondary-color': '#20a0ff',
                    paddingLeft: '10px',
                    marginTop: '5px',
                  }}
                  onClick={() => setIsImageModalVisible(true)}
                />
              </Form.Item>
            </Col>
            <Col
              xxl={24}
              xl={24}
              md={24}
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'column',
              }}
            >
              <span style={{ fontSize: '20px', textAlign: 'left' }}>2. Map columns where needed.</span>
            </Col>
          </Row>
        )}
        {currentView === 'download' && (
          <Row
            key={0}
            style={{
              width: '50%',
              alignItems: 'center',
              margin: 'auto',
            }}
          >
            <Col
              xxl={24}
              xl={24}
              md={24}
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'column',
              }}
            >
              <span style={{ fontSize: '20px', textAlign: 'left' }}>
                Below is a preview of your mapped data. Click Import to continue.
              </span>
            </Col>
          </Row>
        )}

        <Row
          justify={'center'}
          gutter={[40, 25]}
          style={{
            marginBottom: '16px',
            alignItems: 'center',
          }}
        ></Row>
        {defaultHeaderRow && (
          <>
            <Row>
              <Col
                xxl={24}
                xl={24}
                md={24}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <List
                  style={{ width: '100%' }}
                  size="large"
                  header={
                    <Row gutter={20} justify="space-evenly">
                      <Col span={7}>
                        <Warpper>
                          <Title level={4}>Target Audience Columns</Title>
                        </Warpper>
                      </Col>
                      <Col span={7}>
                        <Warpper>
                          <Title level={4}>Your Spreadsheet</Title>
                        </Warpper>
                      </Col>
                      <Col span={7}>
                        <Warpper>
                          <Title level={4}>Preview of 1st Row</Title>
                        </Warpper>
                      </Col>
                    </Row>
                  }
                  bordered
                  dataSource={audienceColumns}
                  renderItem={(item, i) => (
                    <List.Item key={item.dataIndex} style={{ width: '100%' }}>
                      <Row gutter={20} justify="space-evenly" style={{ maxWidth: '100%', minWidth: '100%' }}>
                        <Col span={7} style={{ textAlign: 'left' }}>
                          <Title level={4}>{item}</Title>
                        </Col>
                        <Col span={7} style={{ textAlign: 'center' }}>
                          <Select
                            disabled={disabledFields}
                            allowClear={true}
                            placeholder="Select"
                            style={{ width: '100%' }}
                            value={selectedValues[i]}
                            onChange={(value) => {
                              let cView = 0;
                              let cData = '';
                              defaultHeader?.map((item, i) => {
                                if (item === value) {
                                  cView = i;
                                  cData = firstRowHeader[i];
                                }
                              });
                              let currentSelected = selectedValuesInd;
                              currentSelected[cView] = cView;
                              setShowing({ ...showing, [i]: true })
                              setSelectedValues({ ...selectedValues, [i]: value })
                              setCurrentlyShowing({ ...currentlyShowing, [i]: cData })
                              setSelectedValuesInd(currentSelected);
                            }}
                            options={defaultHeader?.map((field) => ({
                              value: field,
                              label: field,
                            }))}
                          ></Select>
                        </Col>
                        <Col span={7} style={{ textAlign: 'left' }}>
                          <Text>{showing[i] && currentlyShowing[i]}</Text>
                        </Col>
                      </Row>
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          </>
        )}
      </Modal>

      <Modal
        title={
          <>
            <Title level={3} type="secondary">
              Example Header Row - Target Audience
            </Title>
            <Divider />
          </>
        }
        open={isImageModalVisible}
        onCancel={() => setIsImageModalVisible(false)}
        width="800px"
        onOk={() => setIsImageModalVisible(false)}
        okButtonProps={{
          style: { display: 'none' },
        }}
        cancelButtonProps={{
          style: { display: 'none' },
        }}
      >
        <Row
          key={2}
          style={{
            marginBottom: '16px',
            alignItems: 'center',
          }}
        >
          <img
            className="image-people"
            src={require('../../../../static/images/front-row-examples.png')}
            style={{ height: '30%' }}
          />
        </Row>
      </Modal>
    </div>
  );
};

const Warpper = styled.div`
  padding: 20px;
  background-color: #0066b3;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  h4 {
    color: #fff;
    margin: 0;
  }
`;

export default MapAudienceModal;
