import React from "react";
import { HeaderContent, HeaderWrapper, PageTitle } from "./LibrarySection.styles";

const CMSPageHeader = ({contentTitle}) => {
    return (
      <HeaderWrapper>
        <HeaderContent>
          <PageTitle>
            {contentTitle}
          </PageTitle>
        </HeaderContent>
      </HeaderWrapper>
    );
}

export default CMSPageHeader;