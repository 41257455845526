import RadioButton from '../RadioButton';

const RadioButtonsGroup = ({ options, value, onChange = () => { } }) => {
  return (
    <div style={{ display: 'flex', gap: 15 }}>
      {options.map((option) => (
        <RadioButton
          key={option}
          value={option}
          label={option}
          checked={value === option}
          onChange={() => onChange(option)}
        />
      ))}
    </div>
  );
};

export default RadioButtonsGroup;
