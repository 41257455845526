import { Form, Input, Modal, Typography } from 'antd';
const { Title, Text } = Typography;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import AddNewLocation from './AddNewLocation';
import { ChampionModalBodyStyle } from '../../../Style';

function AudienceLocations(props) {
  const [form] = Form.useForm();

  const showHelp = () => {
    form.resetFields()
    event.stopPropagation();
    const model = Modal.info();

    const closeModal = () => model.destroy();

    model.update({
      icon: (
        <FontAwesomeIcon size='2xl' icon={duotone("circle-xmark")} className='ant-modal-close' onClick={closeModal} />
      ),
      key: 'modal-edit-location',
      centered: true,
      closeIcon: (
        <FontAwesomeIcon size='2xl' icon={duotone("circle-xmark")} className='ant-modal-close' onClick={closeModal} />
      ),
      onCancel: { closeModal },
      wrapClassName: 'ocm-modal',
      okButtonProps: {
        style: { display: "none" },
      },
      width: 1200,
      content: (
        <ChampionModalBodyStyle>
          <div className='body-top-panel'>
            <img src={require(`../../../../../static/images/location_level.png`)} alt="" style={{ width: "100%" }} />
          </div>
        </ChampionModalBodyStyle>
      ),
    });
  }

  return (
    <>
      <div className='title-input'>
        <Title level={2}>Let's add your audience locations!</Title>
      </div>
      <div className='title-group-start'>
        <Text level={4} className='text-group-start'>Choose location levels</Text>
        <div className='organizationalForm'>

          {Object.keys(props.currentLocationNames).filter(key => !props.currentLocationNames[key].new).map(key => (
            <Form.Item
              size="large"
              label=""
              name={`location_levels_${key}`}
              initialValue={`${props.currentLocationNames[key]}`}
              style={{ marginBottom: 10 }}
              extra={(
                <div className='group-area'>
                  <div className='icon-area'>
                    {key == 0 && <FontAwesomeIcon size='8x' icon={duotone("map-location")} style={{ "--fa-primary-color": "#0077ce", "--fa-secondary-color": "#20a0ff", }} />}
                    {key == 1 && <FontAwesomeIcon size='5x' icon={duotone("map-location")} style={{ "--fa-primary-color": "#0077ce", "--fa-secondary-color": "#20a0ff", }} />}
                    {key > 1 && <FontAwesomeIcon size='4x' icon={duotone("map-location")} style={{ "--fa-primary-color": "#0077ce", "--fa-secondary-color": "#20a0ff", }} />}


                  </div>
                  <Title
                    level={4}
                    editable={{
                      icon: <FontAwesomeIcon icon={duotone("pencil")} style={{"--fa-primary-color": "#0077ce", "--fa-secondary-color": "#20a0ff", marginLeft: "10px"}} />,
                      triggerType: ['icon', 'text'],
                      tooltip: 'click to edit initial location name',
                      onChange: (value) => {
                        props.setCurrentLocationNames({ ...props.currentLocationNames, [key]: { name: value } });
                      },
                    }}
                    className='editable-text-field'>
                    {props.currentLocationNames[key].name}
                  </Title>
                  <Text type='secondary'>(e.g., Country, Campus)</Text>
                </div>
              )
              }
            >
              <Input type={'hidden'} placeholder='Location Level Name' />
            </Form.Item>
          ))}


          <AddNewLocation {...props}
            showHelp={showHelp}
            setCurrentLocation={props.setCurrentLocation}
            currentLocation={props.currentLocation}
            currentLocationNames={props.currentLocationNames}
            setCurrentLocationNames={props.setCurrentLocationNames}
            form={form}
            listKey="location_level_key"
            fieldKey={'locationLevelName'}
            fieldName="org_location_levels"
            fieldLabel="Location Level"
          />
        </div>
      </div>
    </>
  )
}

export default AudienceLocations;
