import React, { useState, useEffect, useReducer, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/pro-duotone-svg-icons";
import { 
    InfoColumn, 
    InfoContent,
    InfoIcon,
    InfoText,
    SubscriptionLink
} from "./LibrarySection.styles";
import { Link } from "react-router-dom";
import { Typography, Modal, Button } from "antd";
const { Title, Text } = Typography;
import { OCMModal, OCMModalHeader } from "../tools/Style";
import { useDispatch } from "react-redux";
import moment from "moment";
import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { cancelDeletionOfCustomerAccount } from "../../redux/authentication/actionCreator";
import useNotificationController from "../../utility/useNotificationController";


const LicenseInfo = ({userType = 1, organizationalSlug = '', isPaidAccount = false}) => {
    const {
      contextHolder,
      openError,
      openSuccess
    } = useNotificationController();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const marked_for_delete = localStorage.getItem('marked_for_delete') === 'true';
    const marked_for_delete_at = moment(localStorage.getItem('marked_for_delete_at'));
    const is_cancelled = localStorage.getItem('is_cancelled') === 'true';

    const [subscriptionModalButtonText, setSubscriptionModalButtonText] = useState({
        buttonText: "Stop Deletion",
        submittingFrom: false,
        isDisabled: false
    });

    const initialState = {
        error: false,
        messages: ""
    };
  
    const reducer = (state, action) => {
        switch (action.type) {
          case 'CANCEL_DELETION_OF_CUSTOMER_ACCOUNT_ERR':
            return { ...state, error: true,messages: action.err };
          case 'RESET':
            return state;
          default:
            return state;
        }
    };

    const [apiError, apiResponseCallback] = useReducer(reducer, initialState);

    const dispatch = useDispatch();

    const cancelDeletionOfAccount = useCallback(async () => {
        apiResponseCallback({ type: 'RESET' });
        dispatch(
          cancelDeletionOfCustomerAccount((response) => {          
            if (response.status === 200) 
            {
              const responseData = response.data.userInfo;
              openSuccess('bottomRight', 'Restart Subscription',  (
                <>
                <Title level={5}>Your account deletion request has been cancelled successfully</Title>
                <Text type={'secondary'}>Window will be reloaded in 5 seconds</Text>
                </>
              ));
              localStorage.setItem('is_marked_as_cancel', responseData.isMarkedAsCancel);
              localStorage.setItem('is_cancelled', responseData.isCancelled);
              localStorage.setItem('cancellation_date', responseData.cancellationDate);
  
              localStorage.setItem('pauseForMonth', responseData.pauseForMonth);
              localStorage.setItem('is_paused', responseData.isPaused);
              localStorage.setItem('pause_start_date', responseData.pauseStartDate);
              localStorage.setItem('pause_resume_date', responseData.pauseResumeDate);
              localStorage.setItem('pause_request_date', responseData.pauseRequestDate);
  
              localStorage.setItem('marked_for_delete', responseData.markedForDelete);
              localStorage.setItem('marked_for_delete_by', responseData.markedForDeleteBy);
              localStorage.setItem('marked_for_delete_at', responseData.markedForDeleteAt);
              localStorage.setItem('deleted_requested_at', responseData.deletedRequestedAt);
              localStorage.setItem('is_subscription_cancelled_for_delete', responseData.isSubscriptionCancelledForDelete);
              setTimeout(() => {
                if (is_cancelled && isPaidAccount)
                {
                  setSubscriptionModalButtonText({
                    buttonText: "Restart Subscription",
                    submittingFrom: false,
                    isDisabled: false
                  });
                }
                else
                {
                  setSubscriptionModalButtonText({
                    buttonText: "Stop Deletion",
                    submittingFrom: false,
                    isDisabled: false
                  });
                }
                if (isPaidAccount)
                {
                  window.location.href = `/${organizationalSlug}/subscription/update-subscription`;
                }
                else
                {
                  window.location.href = `/${organizationalSlug}/subscription/subscribe-now`;
                }
              }, 5000)
            } 
            else 
            {
              if (is_cancelled && isPaidAccount)
              {
                setSubscriptionModalButtonText({
                  buttonText: "Restart Subscription",
                  submittingFrom: false,
                  isDisabled: false
                });
              }
              else
              {
                setSubscriptionModalButtonText({
                  buttonText: "Stop Deletion",
                  submittingFrom: false,
                  isDisabled: false
                });
              }
              if (response.data === undefined)
              {
                if (response.message !== undefined)
                {
                  openError("topLeft", "Restart Subscription", response.message)
                  apiResponseCallback(response);
                }
                else
                {
                  openError("topLeft", "Restart Subscription", response.err)
                  apiResponseCallback(response);
                }
              }
              else if (response.data !== undefined)
              {
                openError("topLeft", "Restart Subscription", response.data.message)
                apiResponseCallback(response);
              }
              else
              {
                openError("topLeft", "Restart Subscription", response.err)
                apiResponseCallback(response);
              }
            }
          }),
        );
    }, [dispatch, subscriptionModalButtonText, is_cancelled, isPaidAccount]);
  
    const cancelDeletion = async () => {
      if (is_cancelled && isPaidAccount)
      {
        setSubscriptionModalButtonText({
          buttonText: "Restarting subscription, please wait....",
          submittingFrom: true,
          isDisabled: true
        });
      }
      else
      {
        setSubscriptionModalButtonText({
          buttonText: "Stop deletion, please wait....",
          submittingFrom: true,
          isDisabled: true
        });
      }
      await cancelDeletionOfAccount();
    }

    useEffect(() => {
        if (marked_for_delete)
        {
          if (isPaidAccount)
          {
            if (is_cancelled)
            {
              setSubscriptionModalButtonText({
                buttonText: "Stop Deletion & Restart Account",
                submittingFrom: false,
                isDisabled: false
              });
              setSubscriptionModalButtonText({
                buttonText: "Stop Deletion & Restart Account",
                submittingFrom: false,
                isDisabled: false
              });
            }
            else
            {
              setSubscriptionModalButtonText({
                buttonText: "Stop Deletion",
                submittingFrom: false,
                isDisabled: false
              });
              setSubscriptionModalButtonText({
                buttonText: "Stop Deletion",
                submittingFrom: false,
                isDisabled: false
              });
            }
          }
          else
          {
            setSubscriptionModalButtonText({
              buttonText: "Stop Deletion",
              submittingFrom: false,
              isDisabled: false
            });
            setSubscriptionModalButtonText({
              buttonText: "Stop Deletion",
              submittingFrom: false,
              isDisabled: false
            });
          }
        }
    }, []);

    const stopCancellation = (val) => {
        setIsModalVisible(val);
    };

    return (
        <InfoColumn>
          {contextHolder}
          <InfoContent>
              <InfoIcon>
              <FontAwesomeIcon icon={faCircleExclamation} />
              </InfoIcon>
              <InfoText>
                <p>This account is not subscribed to a Change Management Library license.</p>
                {userType === 1 && !marked_for_delete ? (
                    <p>
                        Kindly proceed to your{" "}
                        <Link to={`/${organizationalSlug}/${isPaidAccount ? 'subscription/update-subscription' : 'subscribe-now'}`}>
                            subscription&nbsp;
                        </Link>
                        page and add a license.
                    </p>
                ) : 
                [2,3,4].includes(userType) && !marked_for_delete ? (
                    <p>Please contact your account owner to add Change Management Library license for this account.</p>
                ) : marked_for_delete && (
                    <>
                        <p style={{
                            fontSize: "24px",
                            marginBottom: 0,
                            color: "#AE0002"
                            }}
                        >
                            This account is scheduled to be deleted on {marked_for_delete_at.format("MMM DD, YYYY")}.  Click here to:
                            <a 
                              href={"#"} 
                              onClick={(e) => stopCancellation(true)}
                              style={{paddingLeft: "10px", textDecoration: "underline"}}
                            >{is_cancelled && isPaidAccount ? (
                              `Restart Subscription`
                            ) : (
                              `Stop Deletion`
                            )}</a>
                        </p>
                        <OCMModal>
                          {isModalVisible && (
                            <Modal
                              style={{
                                margin: '20px 0px',
                              }}
                              className="add-change"
                              title={
                                <OCMModalHeader>
                                  <div className="user-info-header-panel">
                                    <Title level={1} className="header-title">{is_cancelled ? `Restart Subscription` : `Stop Deletion`}</Title>
                                    <div className="ribon none"></div>
                                  </div>
                                </OCMModalHeader>
                              }
                              centered
                              closeIcon={<FontAwesomeIcon size="2xl" icon={duotone('circle-xmark')} />}
                              open={isModalVisible}
                              onCancel={() => {
                                stopCancellation(false);
                              }}
                              onOk={() => {
                                stopCancellation(false);
                              }}
                              width={800}
                              wrapClassName={'ocm-modal'}
                              footer={[
                                <Button 
                                  disabled={subscriptionModalButtonText.isDisabled}
                                  loading={subscriptionModalButtonText.submittingFrom}
                                  key="delete" 
                                  type="ocm-default-stop-cancellation" 
                                  size="large"
                                  style={{
                                    fontSize: '20px', // Increase font size
                                    padding: '5px 46px 5px 46px', // Increase padding
                                    lineHeight: '50px', // Center the text vertically
                                    borderRadius: "15px",
                                    height: "auto",
                                  }}
                                  onClick={async (e) => {
                                    await cancelDeletion();
                                  }}
                                >
                                  {subscriptionModalButtonText.buttonText}
                                </Button>
                              ]}
                            >
                              <div style={{padding: "10px 30px", marginBottom: "30px"}}>
                                <p style={{fontSize: "24px", lineHeight: "32px"}}>
                                  {is_cancelled ? `Click below to confirm and restart your subscription.` : `Click below to confirm and cancel your account deletion request.`}
                                </p>
                              </div>
                            </Modal>
                          )}
                        </OCMModal>
                    </>
                )}
              </InfoText>
          </InfoContent>
        </InfoColumn>
    );
}


export default LicenseInfo;