import { Spin } from 'antd';
import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

const Dashboard = lazy(() => import('../../container/dashboard'));
const NotFound = lazy(() => import('../../container/pages/404'));

function DashboardRoutes(props) {
  return (
    <Routes>
      <Route index element={
        <Suspense
          fallback={
            <div className="spin">
              <Spin />
            </div>
          }
        ><Dashboard {...props} userTheme={JSON.parse(localStorage.getItem('userTheme'))} /></Suspense>} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default DashboardRoutes;
