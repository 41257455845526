import { faCheck, faChevronDown, faCircle, faCircleDot, faPencil } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input, Select, Form, Divider, DatePicker } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import useTargetAudience from '../../hook/useTargetAudience';
import { useTargetAudienceState } from '../../context/targetAudience';
import { api } from '../../../../config/dataService/useApi';
import { faSquare } from '@fortawesome/pro-regular-svg-icons';
import dayjs from 'dayjs';

const FormField = ({ form, col, selectOption = {}, setSelectOption, name, setEditListModelOpen, inputRef, style, isEditing = true, addOnType = false }) => {
  const [suggestionData, setSuggestionData] = useState([]);
  const [value, setValue] = useState();
  const { saveTargetAudiencesColumns } = useTargetAudience();
  const { columns } = useTargetAudienceState();
  const postDictionarySuggestion = async (values) => api('/dictionary/insert', 'post', values);

  const date_time_format = localStorage.getItem('date_time_format');
  const dateFormatPattern = /^(DD|MM|YYYY)([-/\\])(DD|MM|YYYY)([-/\\])(DD|MM|YYYY)$/;
  const isValidFormat = date_time_format && dateFormatPattern.test(date_time_format);
  const formattedFormat = isValidFormat ? date_time_format : 'MM-DD-YYYY';

  const dateFormat = formattedFormat.toString().toUpperCase();

  const getDictionarySuggestion = async (values) => {
    if (values?.key) {
      const { data } = await api('/dictionary/', 'post', values);
      setSuggestionData(data || []);
    }
  };

  useEffect(() => {
    if (suggestionData.length === 0) {
      getDictionarySuggestion();
    }

    if (!!form && !!col?.initialValue) {
      setValue(col.initialValue);
    }
  }, []);

  const checkOption = (newOption = '', colOptions) => {
    return (
      newOption?.trim() !== '' &&
      !colOptions?.some((i) => i.label?.toLowerCase() === newOption?.toLowerCase()) &&
      !suggestionData.some((i) => i?.document?.value?.toLowerCase() === newOption?.toLowerCase())
    );
  };

  const searchDictionary = async (key, text, typeKey, title) => {
    if (typeKey === 'other') {
      if (text) {
        setTimeout(async () => {
          getDictionarySuggestion({
            key: title,
            value: text,
          });
        }, 400);
      } else {
        getDictionarySuggestion();
      }
    }
    !!setSelectOption && setSelectOption({ ...selectOption, [key]: { options: text } });
  };

  const addOptions = async (key, selectedValue, title, typeKey) => {
    if (title && typeKey == 'other') {
      let option = selectOption[key]?.options;
      if (option) {
        await postDictionarySuggestion({
          collections: [
            {
              key: title,
              value: option,
            },
          ],
        });
      }
    }

    const updatedColumns = () => {
      const updated = columns.map((col) => {
        const existingOptions = col.options || [];
        const newOption = {
          label: selectedValue || selectOption[col.key]?.options,
          key: `New-${existingOptions.length}-${Math.random()}`,
        };

        if ((!!selectedValue || !!selectOption[col.key]?.options) && col.key === key) {
          const updatedOptions = [...existingOptions, newOption];
          setValue(selectedValue || selectOption[col.key]?.options);
          return { ...col, options: updatedOptions };
        }

        return col;
      });

      saveTargetAudiencesColumns(updated);
      !!setSelectOption && setSelectOption({});
    };

    const alreadyInOption = columns.find((i) => i.key === key)?.options?.find((o) => typeKey == 'other' && isEditing ? o.value === selectedValue : o.label === selectedValue);
    if (!alreadyInOption) {
      updatedColumns();
    }
  };

  useEffect(() => {
    if (form) {
      if (!!value && !!form?._init) {
        form?.setFieldValue(name, value);
      }
    }
  }, [value, form]);

  const filteredSuggestions =
    suggestionData?.filter((s) => !col?.options?.some((option) => option?.label === s.document.value)) || [];

  const renderDateField = () => {
    if (col.format === 'date') {
      if (col.initialValue) {
        const parsedInitialValue = dayjs(col.initialValue, dateFormat);
        const defaultValue = parsedInitialValue.isValid() ? parsedInitialValue : null
        return (
          <DatePicker
            format={dateFormat}
            style={{ width: '100%' }}
            defaultValue={defaultValue}
          />
        )
      }
      return (<DatePicker format={dateFormat} style={{ width: '100%' }} />)
    }
    return null
  }

  return (
    <Form.Item
      style={{ margin: 0, minWidth: 200, ...style }}
      name={name}
      key={col.key}
      {...(col.format === "date" ? {} : { initialValue: col.initialValue ?? (col.format === "dropdown" ? [] : '') })}
    >
      {renderDateField()}
      {col?.dropdown && (
        <StyledSelect
          mode={col?.format === 'dropdown' ? 'multiple' : col?.format}
          showSearch={addOnType}
          ref={!!inputRef && inputRef}
          autoClearSearchValue
          allowClear
          onKeyDown={(e) => (e.key === 'Enter' ? addOptions(col.key, null, col?.title, col?.typeKey) : null)}
          notFoundContent={addOnType ? <div></div> : undefined}
          onSelect={(e) => {
            addOptions(col.key, e, null, col?.typeKey);
          }}
          onSearch={(e) => {
            searchDictionary(col?.key, e, col?.typeKey, col?.title);
          }}
          menuItemSelectedIcon={<FontAwesomeIcon icon={col?.format === 'radio' ? faCircleDot : faCheck} />}
          dropdownRender={(menu) => (
            <div style={{ background: '#fff', padding: '5px 0px' }}>
              {menu}
              {addOnType && checkOption(selectOption[col?.key]?.options, col?.options) && (
                <Button onClick={() => addOptions(col?.key, null, col?.title, col?.typeKey)} type="text">
                  Add new {selectOption[col?.key]?.options}
                </Button>
              )}

              {!!setEditListModelOpen && col && col?.options
                ? col?.options?.length > 0 && (
                  <>
                    <Divider style={{ margin: 0 }} />
                    <EditIconOpenModel>
                      <FontAwesomeIcon
                        icon={faPencil}
                        style={{ cursor: 'pointer', marginRight: '20px' }}
                        onClick={() => {
                          setEditListModelOpen({ editListTitle: col?.title, type: col?.typeKey, state: true });
                        }}
                      />
                    </EditIconOpenModel>
                  </>
                )
                : ''}
            </div>
          )}
          suffixIcon={<FontAwesomeIcon icon={faChevronDown} />}
        >
          {col?.options?.map((i) => {
            return (
              <Select.Option label={i.label} key={i.label} value={isEditing ? i.value || i.label : i.label}>
                <OptionContainer type={col.format}>
                  <span>{i.label}</span>
                  {col.format === 'radio' ? (
                    <FontAwesomeIcon icon={faCircle} color="#000" />
                  ) : (
                    <FontAwesomeIcon icon={faSquare} color="gray" style={{ fontSize: '20px' }} />
                  )}
                </OptionContainer>
              </Select.Option>
            );
          })}
          {filteredSuggestions > 0 && col.typeKey === 'other' && (
            <Select.OptGroup label="Suggested" key="Status">
              {suggestionData?.map((item, index) => (
                <Select.Option key={index} value={item?.document.value}>
                  <OptionContainer key={item?.document.id}>
                    <span>{item?.document.value}</span>
                    {col.format === 'radio' ? (
                      <FontAwesomeIcon icon={faCircle} color="#000" />
                    ) : (
                      <FontAwesomeIcon icon={faSquare} color="#000" style={{ fontSize: '20px' }} />
                    )}
                  </OptionContainer>
                </Select.Option>
              ))}
            </Select.OptGroup>
          )}
        </StyledSelect>
      )}
      {(!col?.dropdown && col?.format !== 'date') && (
        <Input type={col?.format || 'text'} style={{ borderRadius: '0px' }} ref={!!inputRef && inputRef} />
      )}
    </Form.Item>
  );
};

export default FormField;

export const StyledSelect = styled(Select)`
  .ant-select-selection-overflow-item {
    .ant-select-selection-item-content {
      svg {
        display: none;
      }
    }
  }
  .ant-select-selection-item {
    svg {
      display: none;
    }
  }
`;
export const OptionContainer = styled.div`
  display: flex;
  justify-content: space-between;

  svg {
    position: absolute;
    right: ${(p) => (p.type === 'radio' ? '12px' : '10px')};
    bottom: ${(p) => (p.type === 'radio' ? '14px' : '11px')};
  }
`;
const EditIconOpenModel = styled.div`
  display: flex;
  height: 33px;
  padding: 10px;
  width: 100%;
  background: #fff;
  font-size: 18px;
  font-weight: 400;
  align-items: center;
  justify-content: flex-end;
`;
