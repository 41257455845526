import React, { Suspense, lazy, useCallback, useEffect, useReducer, useState } from 'react';
import { Row, Col, Button, Skeleton, Modal, Typography, Card, List } from 'antd';
const { Title, Text } = Typography;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';
import * as ProIcons from '@fortawesome/pro-duotone-svg-icons'
import Cookies from "js-cookie";

import { useDispatch } from 'react-redux';
import { Link, Navigate, useLocation } from 'react-router-dom';

import { getToolsInstaller } from "../../../redux/tools/actionCreator";
import { DefaultDataPopUp, OCMModal, OCMModalHeader, ToolHeaderStyle, VideoTutorialsStyle } from '../Style';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { convertToTrial, verifyUser } from '../../../redux/authentication/actionCreator';
import ReactPlayer from 'react-player';
import { saveLoginUserCookie } from '../../../utility/utility';
import useNotificationController from '../../../utility/useNotificationController';
const { theme } = require('../../../config/theme/themeVariables');


const SurveySendButton = ({ survey, className, userTheme }) => {
  const { pathname } = useLocation();
  const organizationalSlug = localStorage.getItem('organizationalSlug')
  const [navigate, setNavigate] = useState(false);
  
  return (
    <>
      {navigate && <Navigate to={`/${organizationalSlug}/surveys/create/${survey.template_id}?redirect=${pathname}`} />}
      <Button
        onClick={() => {
          setNavigate(true);
        }}
        className={className}
        size="large"
        icon={
          <FontAwesomeIcon
            icon={`fa-${survey.icon_type} fa-${survey.icon}`}
            size="xl"
            style={{
              '--fa-primary-color': `${survey.primary_color}`,
              '--fa-secondary-color': `${survey.secondary_color}`,
            }}
          />
        }
      >
        <span className="buttonText">{survey.label}</span>
      </Button>
    </>
  );
}

const VideoContainer = ({ video_name, video_tutorials }) => {
  const smallScreen = window.innerWidth <= 1400;
  const userTheme = JSON.parse(localStorage.getItem('userTheme'))
  theme['user-theme'] = userTheme;
  const [initLoading, setInitLoading] = useState(false);
  const [openVideo, setOpenVideo] = useState(false);
  const [videoVisible, setVideoVisible] = useState(false);
  const [folder, setFolder] = useState("amazon");
  const [videos, setVideos] = useState([]);

  const is_amazon = localStorage.getItem('is_amazon') === 'true';

  if (video_tutorials) {
    if (!Array.isArray(video_tutorials)) {
      let icon = video_tutorials.faicon;
      library.add(ProIcons[icon]);
    }
    else {
      for (var i in video_tutorials) {
        let icon = video_tutorials[i].faicon;
        library.add(ProIcons[icon]);
      }
    }
  }

  useEffect(() => {
    if (!is_amazon) {
      setFolder("ocms");
    }
    let allVideos = [];
    if (video_tutorials.videos !== undefined && video_tutorials.videos.length > 0) {
      video_tutorials.videos.map(item => {
        if (item.video_for === "both") {
          allVideos.push(item);
        }
        else {
          if (!is_amazon && item.video_for === "ocms") {
            allVideos.push(item);
          }
          else if (is_amazon && item.video_for === "amazon") {
            allVideos.push(item);
          }
        }
      })
    }
    setVideos(allVideos);
  }, []);

  const [videoInformation, setvideoInformation] = useState({
    name: '',
    url: '',
    isVisible: false,
  });
  const [player, setPlayer] = useState(false);

  const showVideoModal = (videoInfo) => {
    setvideoInformation({
      name: videoInfo.name,
      label: video_name,
      url: videoInfo.video_link,
      isVisible: true,
    });
    setVideoVisible(true);
  };

  const hideVideoModal = async () => {
    setPlayer(false);
    setvideoInformation({
      name: '',
      url: '',
      isVisible: false,
    });
  };

  const loadVideo = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (videos !== undefined && videos.length > 0) {
      setOpenVideo(true);
    }
    return false;
  }

  return (
    <>
      {videos.length > 0 && (
        <Button
          className={`video_tutorials`}
          size='large'
          icon={
            <FontAwesomeIcon icon={`fa-${video_tutorials.icon_type} fa-${video_tutorials.icon}`} size="xl" style={{
              '--fa-primary-color': `${video_tutorials.primary_color}`,
              '--fa-secondary-color': `${video_tutorials.secondary_color}`,
            }} />
          }
          onClick={(e) => loadVideo(e)}
        ><span className='buttonText'>{video_tutorials.label}</span></Button>
      )}
      <OCMModal>
        {openVideo && (
          <Modal
            style={{
              margin: '20px 0px',
            }}
            className="add-change"
            title={
              <OCMModalHeader>
                <div className="user-info-header-panel">
                  <h1 className="header-title">
                    {video_tutorials.label}
                  </h1>
                  <div className="ribon none"></div>
                </div>
              </OCMModalHeader>
            }
            centered
            closeIcon={<FontAwesomeIcon size="2xl" icon={duotone('circle-xmark')} />}
            open={openVideo}
            onCancel={() => setOpenVideo(false)}
            onOk={() => setOpenVideo(false)}
            width={800}
            wrapClassName={'ocm-modal'}
            footer={[]}
          >
            <VideoTutorialsStyle theme={theme}>
              <List
                style={{ height: '250px' }}
                className="loadmore-list"
                loading={initLoading}
                itemLayout="horizontal"
                // loadMore={loadMore}
                grid={{ gutter: smallScreen ? 2 : 10, column: 4 }}
                dataSource={videos}
                renderItem={(video) => (
                  <List.Item>
                    <Skeleton avatar title={false} loading={video.loading} active>
                      <List.Item>
                        <Card
                          title={
                            <>
                              <Title level={5} onClick={() => showVideoModal(video)} style={{ minHeight: "73px" }}>
                                {/* {video.name} */}
                                {video_name}
                              </Title>
                              <FontAwesomeIcon
                                icon={duotone('film')}
                                size="xl"
                                style={{
                                  '--fa-primary-color': '#c0c0c0',
                                  '--fa-secondary-color': '#c0c0c0',
                                }}
                                className="title-header"
                              />
                            </>
                          }
                          styles={{
                            header: {
                              fontSize: "20px",
                              color: "#fff",
                              background: userTheme.sidebarColor
                            }
                          }}
                          className="video-card"
                        >
                          {video.isfaicon ? (
                            <FontAwesomeIcon
                              icon={duotone('person-dots-from-line')}
                              style={{
                                '--fa-primary-color': '#ff80c0',
                                '--fa-secondary-color': '#ff80c0',
                                cursor: 'pointer',
                              }}
                              className="big-icon"
                              onClick={() => showVideoModal(video)}
                            />
                          ) : (
                            <img
                              src={require(`../../../static/images/${video.src}`)}
                              alt=""
                              style={{ width: '100%', cursor: 'pointer' }}
                              onClick={() => showVideoModal(video)}
                            />
                          )}
                        </Card>
                      </List.Item>
                    </Skeleton>
                  </List.Item>
                )}
              />
            </VideoTutorialsStyle>
          </Modal>
        )}
      </OCMModal>
      <Modal
        centered
        destroyOnClose={true}
        title={
          <>
            <Title level={3}>{`${videoInformation.name}`}</Title>
            <hr />
          </>
        }
        width="680px"
        open={videoInformation.isVisible}
        footer={null}
        onCancel={hideVideoModal}
        styles={{
          body: { padding: 0 }
        }}
      >
        <ReactPlayer
          controls={true}
          playing={player}
          url={`${process.env.REACT_APP_API_ENDPOINT}/videos/${folder}/${videoInformation.url}`}
          onPlay={() => setPlayer(true)}
          onPause={() => setPlayer(false)}
        />
      </Modal>
    </>
  )
}

const ToolInfoHeader = (props) => {
  const {
    contextHolder,
    openError,
    openSuccess,
  } = useNotificationController();

  const openDefaultModal = (toolName, stakeholder, populate_by_message, messages) => {
    const modal = Modal.info();
    const closeModal = () => modal.destroy();
    let currentMessage = `We've populated your <span style="color: #EFD46A;">${toolName}</span> with target audience members labeled as "<span className='champion-color' style="color: #EFD46A; font-style: italic;">${stakeholder}.</span>"`;
    if (messages !== undefined) {
      currentMessage = messages;
    }
    modal.update({
      icon: (
        <></>
      ),
      style: {
        top: 0,
      },
      closeIcon: (
        <FontAwesomeIcon size='2xl' icon={duotone("circle-xmark")} />
      ),
      onCancel: { closeModal },
      wrapClassName: 'ocm-modal-all-blue',
      okButtonProps: {
        style: { display: "none" },
      },
      cancelButtonProps: {
        style: { display: "none" },
      },
      width: 850,
      content: (
        <DefaultDataPopUp>
          <FontAwesomeIcon
            size='2xl'
            icon={duotone("circle-xmark")}
            onClick={closeModal}
            className='ant-modal-close'
          />
          <Row gutter={[40, 25]}>
            <Col span={6} style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <img src={require('../../../static/images/default-data.svg').default} alt="" style={{ width: "80px" }} />
            </Col>
            <Col span={18} style={{
              display: 'flex',
              width: '100%',
              flexDirection: "column",
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <p style={{ paddingTop: "20px", paddingRight: "40px", fontWeight: 600, fontSize: '22px' }}>
                <span dangerouslySetInnerHTML={{ __html: currentMessage }} />
              </p>
              <p style={{ paddingTop: "10px", paddingBottom: "20px", paddingRight: "40px", fontWeight: 600, fontSize: '22px' }}>You can choose a different selection under "{populate_by_message}" at the top right.</p>
            </Col>
          </Row>
          <Row>
            <Col span={24} className='button-container'>
              <Button
                className="btn-blue-modal"
                type={'default'}
                size="large"
                onClick={closeModal}
              >
                Close
              </Button>
            </Col>
          </Row>
        </DefaultDataPopUp>
      ),
    });
  };

  const [isFreeAccount, setIsFreeAccount] = useState(false);
  const [isCancelledAccount, setIsCancelledAccount] = useState(false);
  const [cancellationDate, setCancellationDate] = useState("");
  const [currentSlug, setCurrentSlug] = useState(localStorage.getItem('organizationalSlug'));

  const is_amazon = localStorage.getItem('is_amazon') === 'true';
  const organizationalSlug = localStorage.getItem('organizationalSlug')
  const camelCaseTool = props.info.tools_key.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); });
  const makeComponent = (string) => { return string.charAt(0).toUpperCase() + string.slice(1) };
  const ToolComponent = makeComponent(camelCaseTool);
  const DynamicComponent = lazy(() => import(`../panel/${ToolComponent}`));

  const initialState = {
    error: false,
    messages: ""
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'TOOLS_INSTALLER_ERR':
        return { ...state, error: true, ocmToolsMessage: action.err };
      case 'VERIFY_USER_ERR':
        return { ...state, error: true, messages: action.err };
      case 'CONVERT_TO_TRIAL_ERR':
        return { ...state, error: true, messages: action.err };
      case 'RESET':
        return state;
      default:
        return state;
    }
  };

  const [apiError, apiResponseCallback] = useReducer(reducer, initialState);

  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    apiResponseCallback({ type: 'RESET' });
    dispatch(
        verifyUser({}, async (response) => {
          if (response.status === 200) {
            let organizationalSlug = response.data.organizationalSlug;
            let cancelledAccount = response.data.isCancelled;
            let cancellationDate = response.data.cancellationDate;
            let freeAccount = response.data.isFreeAccount;
            setCurrentSlug(organizationalSlug);
            setIsCancelledAccount(cancelledAccount);
            setCancellationDate(cancellationDate);
            setIsFreeAccount(freeAccount);
          } 
          else 
          {
            openError('topLeft', 'Error', response.err);
            apiResponseCallback(response);
          }
        }),
      );
  }, [dispatch, 
    isFreeAccount,
    isCancelledAccount,
    cancellationDate
  ]);

  if (props.info.has_installer) {
    const installer_routes = props.info.installer_routes;
    const installTool = useCallback(async () => {
      apiResponseCallback({ type: 'RESET' });
      dispatch(
        getToolsInstaller(installer_routes, (response) => {
          if (response.status !== 200) {
            apiResponseCallback(response);
          }
        }),
      );
    }, [dispatch]);

    useEffect(() => {

      const installToolData = async () => {
        installTool();
      }
      installToolData()
        .catch(console.error);
    }, []);
  }

  if (is_amazon)
  {
      if (props.info.resources.resource_for === "both")
      {
        props.info.has_resources = true;
      }
      else if (props.info.resources.resource_for === "amazon")
      {
        props.info.has_resources = true;
      }
      else
      {
        props.info.has_resources = false;
      }
  }
  else
  {
    if (props.info.resources.resource_for === "both")
    {
      props.info.has_resources = true;
    }
    else if (props.info.resources.resource_for === "amazon")
    {
      props.info.has_resources = false;
    }
    else
    {
      props.info.has_resources = true;
    }
  }

  if (props.info.has_resources) {
    if (!Array.isArray(props.info.resources)) {
      let icon = props.info.resources.faicon;
      library.add(ProIcons[icon]);
    }
    else {
      for (var i in props.info.resources) {
        let icon = props.info.resources[i].faicon;
        library.add(ProIcons[icon]);
      }
    }
  }
  if (props.info.has_survey) {
    if (!Array.isArray(props.info.survey)) {
      let icon = props.info.survey.faicon;
      library.add(ProIcons[icon]);
    }
    else {
      for (var i in props.info.survey) {
        let icon = props.info.survey[i].faicon;
        library.add(ProIcons[icon]);
      }
    }
  }

  const convertToTrialAccount = useCallback(async (data) => {
    const rememberMe = Cookies.get('rememberMe');
    apiResponseCallback({ type: 'RESET' });
    dispatch(
      convertToTrial({rememberMe: rememberMe},(response) => {
        if (response.status === 200) {
          localStorage.clear();
          saveLoginUserCookie(response);
          openSuccess('bottomRight', "Convert to Free user to Trial User", `Your Account is successfully converted to Trial Account`);
          setTimeout(() => {
            window.location.reload();
            //navigate(0);
          }, 1500);
        } else {
          openError("topLeft", "Convert to Free user to Trial User", response.err)
          apiResponseCallback(response);
        }
      }),
    );
  }, [dispatch]);

  const convertTrial = () => {
    convertToTrialAccount();
  }

  useEffect(() => {
    const retrieveData = async () => {
       await fetchData();
     }
     retrieveData().catch(console.error);
  }, []);

  const userTheme = JSON.parse(localStorage.getItem('userTheme'))
  theme['user-theme'] = userTheme;
  theme['tool-info-theme'] = props.info;

  return (
    <ToolHeaderStyle theme={theme}>
      {contextHolder}
      <Row style={{ marginTop: '10px', width: '100%' }}>
        <Col style={{ width: '50%' }}>
          {/* <h1>{props.info.tag_line_title === undefined ? props.info.tools_name : props.info.tag_line_title}</h1> */}
        </Col>
        <Col style={{ width: '50%' }}>
          <Row style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', gap: '10px' }}>
            {props.info.has_video_tutorials && (
              <Col style={{ width: '21%' }} className='video_tutorials'>
                {!Array.isArray(props.info.video_tutorials) ? (
                  <VideoContainer video_name={props.tool.display_name} video_tutorials={props.info.video_tutorials} />
                ) : (
                  (props.info.survey.map((item, i) => {
                    return (
                      <Button
                        className={`video_tutorials_${i}`}
                        size='large'
                        icon={
                          <FontAwesomeIcon icon={`fa-${item.icon_type} fa-${item.icon}`} size="xl" style={{ "--fa-primary-color": `${userTheme.iconColor}`, "--fa-secondary-color": `${userTheme.iconColor}` }} />
                        }
                      >
                        <span className='buttonText'>{item.label}</span>
                      </Button>
                    )
                  }))
                )}
                {(props.info.video_tutorials.videos !== undefined && props.info.video_tutorials.videos.length > 0) ? '' : (
                  <div style={{ display: "none" }}>
                    <Text
                      type='secondary'
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingTop: 10
                      }}>The videos are unavailable till go-live</Text>
                  </div>
                )}
              </Col>
            )}
            {props.info.has_resources && (
              <Col style={{ width: '21%' }} className='resources'>
                {!Array.isArray(props.info.resources) ? (
                  <Button
                    className={`resources`}
                    size='large'
                    icon={
                      <>
                        <Link to={`/${organizationalSlug}/guides`}>
                          <FontAwesomeIcon icon={`fa-${props.info.resources.icon_type} fa-${props.info.resources.icon}`} size="xl" style={{
                            '--fa-primary-color': `${props.info.resources.primary_color}`,
                            '--fa-secondary-color': `${props.info.resources.secondary_color}`,
                          }} />
                        </Link>
                      </>
                    }
                  >
                    <Link to={`/${organizationalSlug}/guides`}><span className='buttonText'>{props.info.resources.label}</span></Link>
                  </Button>
                ) : (
                  (props.info.survey.map((item, i) => {
                    return (
                      <Button
                        className={`resources_${i}`}
                        size='large'
                        icon={
                          <FontAwesomeIcon icon={`fa-${item.icon_type} fa-${item.icon}`} size="xl" style={{ "--fa-primary-color": `${userTheme.iconColor}`, "--fa-secondary-color": `${userTheme.iconColor}` }} />
                        }
                      ><span className='buttonText'>{item.label}</span></Button>
                    )
                  }))
                )}
              </Col>
            )}
            {props.info.has_survey && (
              <Col style={{ width: '25%' }} className='survey'>
                {!Array.isArray(props.info.survey) ? (
                  <SurveySendButton className={'survey'} survey={props.info.survey} userTheme={userTheme} />
                ) : (
                  props.info.survey.map((item, i) => (
                    <SurveySendButton
                      className={`survey_${i}`}
                      key={item.template_id}
                      survey={item}
                      userTheme={userTheme}
                    />
                  ))
                )}
              </Col>
            )}

            {/* {props.info.enabled_copy_from_project && (
              <Col xxl={8} xl={8} lg={12} md={12} sm={24} xs={12} className='enabled_copy_from_project'>
                <CopyDropDown info={props.info} />
              </Col>
            )} */}
          </Row>
        </Col>
      </Row>
      <Suspense fallback={
        <Cards headless>
          <Skeleton avatar active paragraph={{ rows: 3 }} />
        </Cards>
      }>
        {isFreeAccount ? (
          isCancelledAccount ? (
            <div className='overlay-free'>
              <div style={{background: `${is_amazon ? 'rgb(255, 164, 141)' : '#00C4FA'}`, margin: "100px auto", width: "50%", maxHeight: "300px", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "23px", boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.75)"}}>
                  <div style={{width: "100%", textAlign: "center", color: `${is_amazon ? 'rgb(218, 77, 41)' : '#FFFFFF'}`, padding: "50px", lineHeight: "50px"}}>
                    <div style={{color: `${is_amazon ? 'rgb(218, 77, 41)' : '#FFFFFF'}`, fontSize: "25px"}}>Your subscription was cancelled on <strong>{cancellationDate}</strong>. <br />Click here to. 
                      <Title level={3} style={{color: `${is_amazon ? 'rgb(218, 77, 41)' : '#FFFFFF'}`}} underline italic>
                        <Link to={`/${organizationalSlug}/subscription/restart-subscription`} style={{color: `${is_amazon ? 'rgb(218, 77, 41)' : '#FFFFFF'}`}}>
                            Restart your subscription again
                        </Link>
                      </Title>
                    </div>
                  </div>
              </div>
            </div>
          ) : (
            isFreeAccount && props !== undefined && props.info.is_free === 1  ? `` :
            <div className='overlay-free'>
              <div style={{ background: `${is_amazon ? 'rgb(255, 164, 141)' : '#00C4FA'}`, margin: "0 auto", width: "50%", maxHeight: "300px", display: "flex", position: "fixed", justifyContent: "center", alignItems: "center", fontSize: "23px", boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.75)" }}>
                <div style={{ width: "100%", textAlign: "center", color: `${is_amazon ? 'rgb(218, 77, 41)' : '#FFFFFF'}`, padding: "50px" }}>
                  <div style={{ color: `${is_amazon ? 'rgb(218, 77, 41)' : '#FFFFFF'}`, fontSize: "25px" }}>You're not a subscribed user.
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Title
                        level={3}
                        style={
                          {
                            color: `${is_amazon ? 'rgb(218, 77, 41)' : '#FFFFFF'}`,
                            margin: 0,
                            paddingLeft: "30px",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            cursor: "pointer"
                          }
                        } underline italic
                        onClick={convertTrial}>Start your free trial today!</Title>
                    </div>
                  </div>
                  <Title level={2} style={{ fontSize: "45px", color: `${is_amazon ? 'rgb(218, 77, 41)' : '#FFFFFF'}`, marginTop: 0 }}>or</Title>
                  <Link
                    to={`/${organizationalSlug}/tools-dashboard/free-resources`}><Title level={3} style={{ color: `${is_amazon ? 'rgb(218, 77, 41)' : '#FFFFFF'}` }} underline
                    >
                      visit the Free Tools Dashboard.</Title>
                  </Link>
                </div>
              </div>
            </div>
          )
        ) : ''}

        <DynamicComponent
          {...props}
          openDefaultModal={(toolName, stakeholder, populate_by_message, messages) => openDefaultModal(toolName, stakeholder, populate_by_message, messages)}
        />
      </Suspense>
    </ToolHeaderStyle>
  );
}

export default ToolInfoHeader;
