import styled from 'styled-components';

const FormControl = styled.label`
  font-family: system-ui, sans-serif;
  font-size: 2rem !important;
  font-weight: bold;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
  color: ${(props) => (props.disabled ? '#959495' : 'black')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const StyledInput = styled.input`
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--form-background);
  margin: 0;
  font: inherit;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid #5dd3ff;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;

  ${
    '' /* &:focus {
    outline: max(2px, 0.15em) solid currentColor;
    outline-offset: max(2px, 0.15em);
  } */
  }

  &:checked::before {
    transform: scale(1);
  }

  &:disabled {
    color: var(--form-control-disabled);
    cursor: not-allowed;
  }

  &:before {
    content: '';
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    background-color: CanvasText;
  }
`;

const Checkbox = ({ label, value, checked, disabled, onChange, onClick }) => {
  return (
    <FormControl disabled={disabled}>
      <StyledInput
        type="checkbox"
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        onClick={onClick}
      />
      <span style={{ fontSize: 14, alignSelf: 'center' }}>{label}</span>
    </FormControl>
  );
};

export default Checkbox;
