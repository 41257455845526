import styled from 'styled-components';

export const SurveyDashboardPage = styled.div`
  background-color: #f8fbfe;
  padding: 20px 10px;
  > h1 {
    text-align: center;
  }
  .ant-tabs-nav {
    &::before {
      border: none;
    }
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        gap: 10px;
        .ant-tabs-tab {
          border-radius: 100px;
          padding: 8px 25px 6px;
          background: #fff;
          border: 1px solid #8e8e8e;
          .ant-tabs-tab-btn {
            color: #8e8e8e;
          }
        }
        .ant-tabs-tab.ant-tabs-tab-active {
          background: #39aaff;
          border: 1px solid #39aaff;

          .ant-tabs-tab-btn {
            color: #fff;
          }
        }
      }
    }
  }
  .viewAllTemplates {
    text-align: end;
    text-decoration: underline;
    padding-bottom: 3px;
    width: fit-content;
    margin-left: auto;
    cursor: pointer;
  }
`;
