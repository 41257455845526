import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const {
  ToolConfigrationBegin,
  ToolConfigrationSuccess,
  ToolConfigrationErr,

  ToolsInstallerBegin,
  ToolsInstallerSuccess,
  ToolsInstallerErr,

  getToolsListDataBegin,
  getToolsListDataSuccess,
  getToolsListDataErr,

  generateOCMPlanToolsBegin,
  generateOCMPlanToolsSuccess,
  generateOCMPlanToolsErr,

  generateAudienceToolsBegin,
  generateAudienceToolsSuccess,
  generateAudienceToolsErr,

  generateResistanceRiskPlanBegin,
  generateResistanceRiskPlanSuccess,
  generateResistanceRiskPlanErr,

  getToolGeneratedDataBegin,
  getToolGeneratedDataSuccess,
  getToolGeneratedDataErr,

  getToolsAudiencsDataBegin,
  getToolsAudiencsDataSuccess,
  getToolsAudiencsDataErr,

  getToolsDefaultColumnsDataBegin,
  getToolsDefaultColumnsDataSuccess,
  getToolsDefaultColumnsDataErr,

  getToolsExportColumnsDataBegin,
  getToolsExportColumnsDataSuccess,
  getToolsExportColumnsDataErr,

  importDataForToolsBegin,
  importDataForToolsSuccess,
  importDataForToolsErr,

  exportDataForToolsBegin,
  exportDataForToolsSuccess,
  exportDataForToolsErr,

  saveToolRiskAudiencsDataBegin,
  saveToolRiskAudiencsDataSuccess,
  saveToolRiskAudiencsDataErr,

  saveProjectAdoptionMetricsBegin,
  saveProjectAdoptionMetricsSuccess,
  saveProjectAdoptionMetricsErr,

  editProjectAdoptionMetricsBegin,
  editProjectAdoptionMetricsSuccess,
  editProjectAdoptionMetricsErr,

  deleteProjectAdoptionMetricsBegin,
  deleteProjectAdoptionMetricsSuccess,
  deleteProjectAdoptionMetricsErr,

  saveResistanceAudiencsKeyReasonDataBegin,
  saveResistanceAudiencsKeyReasonDataSuccess,
  saveResistanceAudiencsKeyReasonDataErr,

  saveToolEngagementPlanBegin,
  saveToolEngagementPlanSuccess,
  saveToolEngagementPlanErr,

  saveToolAssessmentNameBegin,
  saveToolAssessmentNameSuccess,
  saveToolAssessmentNameErr,

  closeToolAssessmentBegin,
  closeToolAssessmentSuccess,
  closeToolAssessmentErr,

  deleteToolAssessmentBegin,
  deleteToolAssessmentSuccess,
  deleteToolAssessmentErr,

  ToolsCopyProjectBegin,
  ToolsCopyProjectSuccess,
  ToolsCopyProjectErr,

  getResistanceKeyReasonBegin,
  getResistanceKeyReasonSuccess,
  getResistanceKeyReasonErr,

  saveResistanceKeyReasonBegin,
  saveResistanceKeyReasonSuccess,
  saveResistanceKeyReasonErr,

  getSingleImpactBegin,
  getSingleImpactSuccess,
  getSingleImpactErr,
} = actions;

const getSingleImpact = (values, callback) => {
  return async (dispatch) => {
    dispatch(getSingleImpactBegin());
    try {
      const response = await DataService.post('/tools/get-single-impact', values);
      if (response.status !== 200) {
        const messages = dispatch(getSingleImpactSuccess(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(getSingleImpactSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(getSingleImpactErr(err));
      callback(messages);
    }
  };
};

const getToolsConfigurations = (values, callback) => {
  return async (dispatch) => {
    dispatch(ToolConfigrationBegin());
    try {
      let queryString = "";
      if (Object.entries(values).length > 0)
      {
        queryString = Object.entries(values)
          .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
          .join('&');
        queryString = `?${queryString}`;
      }
      const response = await DataService.get(`/defaultData/tools-configurations${queryString}`);
      if (response.status !== 200) {
        const messages = dispatch(ToolConfigrationSuccess(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(ToolConfigrationSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(ToolConfigrationErr(err));
      callback(messages);
    }
  };
};

const getToolsInstaller = (routes, callback) => {
  return async (dispatch) => {
    dispatch(ToolsInstallerBegin());
    try {
      const response = await DataService.post(`/free-tools/${routes}`, {});
      if (response.status !== 200) {
        const messages = dispatch(ToolsInstallerErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(ToolsInstallerSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(ToolsInstallerErr(err));
      callback(messages);
    }
  };
};

const getToolsListData = (values, callback) => {
  return async (dispatch) => {
    dispatch(getToolsListDataBegin());
    try {
      const response = await DataService.post(`/tools/get-list-view-data`, values);
      if (response.status !== 200) {
        const messages = dispatch(getToolsListDataErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(getToolsListDataSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(getToolsListDataErr(err));
      callback(messages);
    }
  };
};

const generateAudienceTools = (values, callback, isFree = false) => {
  return async (dispatch) => {
    dispatch(generateAudienceToolsBegin());
    try {
      const response = await DataService.post(`/${isFree ? 'free-tools' : 'tools'}/generate-audiences-tools${isFree ? '-free' : ''}`, values);
      if (response.status !== 200) {
        const messages = dispatch(generateAudienceToolsErr(response.data));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(generateAudienceToolsSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(generateAudienceToolsErr(err));
      callback(messages);
    }
  };
};

const generateOCMPlanTools = (callback) => {
  return async (dispatch) => {
    dispatch(generateOCMPlanToolsBegin());
    try {
      const response = await DataService.post(`/project-info/generate-amazon-plan-for-projects`);
      if (response.status !== 200) {
        const messages = dispatch(generateOCMPlanToolsErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(generateOCMPlanToolsSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(generateOCMPlanToolsErr(err));
      callback(messages);
    }
  };
};

const generateMainOCMPlanTools = (callback) => {
  return async (dispatch) => {
    dispatch(generateOCMPlanToolsBegin());
    try {
      const response = await DataService.post(`/project-info/generate-ocm-plan-for-projects`);
      if (response.status !== 200) {
        const messages = dispatch(generateOCMPlanToolsErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(generateOCMPlanToolsSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(generateOCMPlanToolsErr(err));
      callback(messages);
    }
  };
};

const generateResistanceRiskPlan = (values, callback) => {
  return async (dispatch) => {
    dispatch(generateResistanceRiskPlanBegin());
    try {
      const response = await DataService.post(`/tools/generate-resistance-risk-plan`, values);
      if (response.status !== 200) {
        const messages = dispatch(generateResistanceRiskPlanErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(generateResistanceRiskPlanSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(generateResistanceRiskPlanErr(err));
      callback(messages);
    }
  };
};

const getToolGeneratedData = (values, callback) => {
  return async (dispatch) => {
    dispatch(getToolGeneratedDataBegin());
    try {
      const response = await DataService.post(`/free-tools/get-generate-tools-data`, values);
      if (response.status !== 200) {
        const messages = dispatch(getToolGeneratedDataErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(getToolGeneratedDataSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(getToolGeneratedDataErr(err));
      callback(messages);
    }
  };
};

const saveToolRiskAudiencsData = (values, callback, isFree = false) => {
  return async (dispatch) => {
    dispatch(saveToolRiskAudiencsDataBegin());
    try {
      const response = await DataService.post(`/${isFree ? 'free-tools' : 'tools'}/save-tool-risk-audiences-data${isFree ? '-free' : ''}`, values);
      if (response.status !== 200) {
        const messages = dispatch(saveToolRiskAudiencsDataErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(saveToolRiskAudiencsDataSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(saveToolRiskAudiencsDataErr(err));
      callback(messages);
    }
  };
};

const saveProjectAdoptionMetrics = (values, callback) => {
  return async (dispatch) => {
    dispatch(saveProjectAdoptionMetricsBegin());
    try {
      const response = await DataService.post(`/tools/save-adoption-metric-data`, values);
      if (response.status !== 200) {
        const messages = dispatch(saveProjectAdoptionMetricsErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(saveProjectAdoptionMetricsSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(saveProjectAdoptionMetricsErr(err));
      callback(messages);
    }
  };
};

const editProjectAdoptionMetrics = (values, callback) => {
  return async (dispatch) => {
    dispatch(editProjectAdoptionMetricsBegin());
    try {
      const response = await DataService.post(`/tools/edit-adoption-metric-data`, values);
      if (response.status !== 200) {
        const messages = dispatch(editProjectAdoptionMetricsErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(editProjectAdoptionMetricsSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(editProjectAdoptionMetricsErr(err));
      callback(messages);
    }
  };
};

const deleteProjectAdoptionMetrics = (values, callback) => {
  return async (dispatch) => {
    dispatch(deleteProjectAdoptionMetricsBegin());
    try {
      const response = await DataService.post(`/tools/delete-adoption-metric-data`, values);
      if (response.status !== 200) {
        const messages = dispatch(deleteProjectAdoptionMetricsErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(deleteProjectAdoptionMetricsSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(deleteProjectAdoptionMetricsErr(err));
      callback(messages);
    }
  };
};

const saveResistanceAudiencsKeyReasonData = (values, callback) => {
  return async (dispatch) => {
    dispatch(saveResistanceAudiencsKeyReasonDataBegin());
    try {
      const response = await DataService.post(`/tools/save-resistance-audiences-key-reason-data`, values);
      if (response.status !== 200) {
        const messages = dispatch(saveResistanceAudiencsKeyReasonDataErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(saveResistanceAudiencsKeyReasonDataSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(saveResistanceAudiencsKeyReasonDataErr(err));
      callback(messages);
    }
  };
};

const saveToolEngagementPlan = (values, callback, isFree = false) => {
  return async (dispatch) => {
    dispatch(saveToolEngagementPlanBegin());
    try {
      const response = await DataService.post(`/${isFree ? 'free-tools' : 'tools'}/save-engagement-plan${isFree ? '-free' : ''}`, values);
      if (response.status !== 200) {
        const messages = dispatch(saveToolEngagementPlanErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(saveToolEngagementPlanSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(saveToolEngagementPlanErr(err));
      callback(messages);
    }
  };
};

const saveToolAssessmentName = (values, callback) => {
  return async (dispatch) => {
    dispatch(saveToolAssessmentNameBegin());
    try {
      const response = await DataService.post(`/tools/save-tool-assessment-name`, values);
      if (response.status !== 200) {
        const messages = dispatch(saveToolAssessmentNameErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(saveToolAssessmentNameSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(saveToolAssessmentNameErr(err));
      callback(messages);
    }
  };
};

const closeToolAssessment = (values, callback) => {
  return async (dispatch) => {
    dispatch(closeToolAssessmentBegin());
    try {
      const response = await DataService.post(`/tools/close-tool-assessment`, values);
      if (response.status !== 200) {
        const messages = dispatch(closeToolAssessmentErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(closeToolAssessmentSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(closeToolAssessmentErr(err));
      callback(messages);
    }
  };
};

const deleteToolAssessment = (values, callback) => {
  return async (dispatch) => {
    dispatch(deleteToolAssessmentBegin());
    try {
      const response = await DataService.post(`/tools/delete-tool-assessment`, values);
      if (response.status !== 200) {
        const messages = dispatch(deleteToolAssessmentErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(deleteToolAssessmentSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(deleteToolAssessmentErr(err));
      callback(messages);
    }
  };
};

const saveResistanceAudiencsEngagementPlan = (values, callback) => {
  return async (dispatch) => {
    dispatch(saveResistanceAudiencsEngagementPlanBegin());
    try {
      const response = await DataService.post(`/tools/save-resistance-audiences-engagement-plan`, values);
      if (response.status !== 200) {
        const messages = dispatch(saveResistanceAudiencsEngagementPlanErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(saveResistanceAudiencsEngagementPlanSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(saveResistanceAudiencsEngagementPlanErr(err));
      callback(messages);
    }
  };
};

const getToolsAudiencsData = (values, callback) => {
  return async (dispatch) => {
    dispatch(getToolsAudiencsDataBegin());
    try {
      const response = await DataService.post(`/free-tools/get-tools-audiences-data`, values);
      if (response.status !== 200) {
        const messages = dispatch(getToolsAudiencsDataErr(response.data));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(getToolsAudiencsDataSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(getToolsAudiencsDataErr(err, 0));
      callback(messages);
    }
  };
};

const getResistanceKeyReason = (callback) => {
  return async (dispatch) => {
    dispatch(getResistanceKeyReasonBegin());
    try {
      const response = await DataService.get(`/free-tools/get-resistance-key-reasons`);
      if (response.status !== 200) {
        const messages = dispatch(getResistanceKeyReasonErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(getResistanceKeyReasonSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(getResistanceKeyReasonErr(err));
      callback(messages);
    }
  };
};

const saveResistanceKeyReason = (values, callback) => {
  return async (dispatch) => {
    dispatch(saveResistanceKeyReasonBegin());
    try {
      const response = await DataService.post(`/tools/save-resistance-key-reasons`, values);
      if (response.status !== 200) {
        const messages = dispatch(saveResistanceKeyReasonErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(saveResistanceKeyReasonSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(saveResistanceKeyReasonErr(err));
      callback(messages);
    }
  };
};

const getSponsorAudiencsData = (values, callback) => {
  return async (dispatch) => {
    dispatch(getSponsorAudiencsDataBegin());
    try {
      const response = await DataService.post(`/free-tools/get-sponsor-audiences-data`, values);
      if (response.status !== 200) {
        const messages = dispatch(getSponsorAudiencsDataErr(response.data.message, response.data.audienceCount));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(getSponsorAudiencsDataSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(getSponsorAudiencsDataErr(err, 0));
      callback(messages);
    }
  };
};

const getToolsProjects = (values, callback) => {
  return async (dispatch) => {
    dispatch(ToolsCopyProjectBegin());
    try {
      const response = await DataService.post(`/tools/get-projects-by-tools`, values);
      if (response.status !== 200) {
        const messages = dispatch(ToolsCopyProjectErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(ToolsCopyProjectSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(ToolsCopyProjectErr(err));
      callback(messages);
    }
  };
};

const getToolsDefaultColumnsData = (values, callback) => {
  return async (dispatch) => {
    dispatch(getToolsDefaultColumnsDataBegin());
    try {
      const response = await DataService.post(`/free-tools/get-tools-default-columns-data`, values);
      if (response.status !== 200) {
        const messages = dispatch(getToolsDefaultColumnsDataErr(response.data.message, response.data.audienceCount));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(getToolsDefaultColumnsDataSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(getToolsDefaultColumnsDataErr(err, 0));
      callback(messages);
    }
  };
};

const getToolsExportColumnsData = (values, callback) => {
  return async (dispatch) => {
    dispatch(getToolsExportColumnsDataBegin());
    try {
      const response = await DataService.post(`/free-tools/get-tools-export-columns-data`, values);
      if (response.status !== 200) {
        const messages = dispatch(getToolsExportColumnsDataErr(response.data.message, response.data.audienceCount));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(getToolsExportColumnsDataSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(getToolsExportColumnsDataErr(err, 0));
      callback(messages);
    }
  };
};

const importDataForTools = (values, callback) => {
  return async (dispatch) => {
    dispatch(importDataForToolsBegin());
    try {
      const response = await DataService.post(`/free-tools/import-data-for-tools`, values);
      if (response.status !== 200) {
        const messages = dispatch(importDataForToolsErr(response.data.message, response.data.audienceCount));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(importDataForToolsSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(importDataForToolsErr(err, 0));
      callback(messages);
    }
  };
};

const exportDataForTools = (values, callback) => {
  return async (dispatch) => {
    dispatch(exportDataForToolsBegin());
    try {
      const response = await DataService.post(`/free-tools/export-data-for-tools`, values);
      if (response.status !== 200) {
        const messages = dispatch(exportDataForToolsErr(response.data.message, response.data.audienceCount));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(exportDataForToolsSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(exportDataForToolsErr(err, 0));
      callback(messages);
    }
  };
};


export {
  getToolsConfigurations,
  getToolsInstaller,
  getToolsListData,
  generateAudienceTools,
  generateOCMPlanTools,
  generateResistanceRiskPlan,
  getToolGeneratedData,
  getToolsAudiencsData,
  getToolsDefaultColumnsData,
  getToolsExportColumnsData,
  importDataForTools,
  exportDataForTools,
  getSponsorAudiencsData,
  saveToolRiskAudiencsData,
  saveResistanceAudiencsKeyReasonData,
  saveToolEngagementPlan,
  saveResistanceAudiencsEngagementPlan,
  getResistanceKeyReason,
  saveResistanceKeyReason,
  saveToolAssessmentName,
  closeToolAssessment,
  deleteToolAssessment,
  getToolsProjects,
  saveProjectAdoptionMetrics,
  editProjectAdoptionMetrics,
  deleteProjectAdoptionMetrics,
  generateMainOCMPlanTools,
  getSingleImpact
};
